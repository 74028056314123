import { useMemo } from 'react';
import { DEVICE } from 'ua-parser-js';
import { useUserAgent } from './useUserAgent';

export const useIsMobile = () => {
    const { device } = useUserAgent();
    const isMobile = useMemo(
        () => device.type === DEVICE.MOBILE || device.type === DEVICE.TABLET,
        [device]
    );
    return isMobile;
};
