import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpTicketResult implements ICommand
{

	public Ticket : Array<string>;

	public constructor()
	{
		this.Ticket = new Array<string>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let TicketSize:number = ba.readUint8();
		while (TicketSize-- > 0)
		{
			let TicketChild:string = ba.readString();
			this.Ticket.push(TicketChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Ticket.length);
		for (const TicketChild of this.Ticket)
		{
			ba.writeString(TicketChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpTicketResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Ticket_size(B)='+this.Ticket.length + ' ';
		str += 'Ticket(S)=[';
		for (let TicketChild in this.Ticket)
		{
			if (<number><unknown>TicketChild > 0) str += ', ';
			str += this.Ticket[TicketChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50038;
	}

	public getCmdName() : string
	{
		return 'SpTicketResult';
	}

}
