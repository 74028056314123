import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsTokenUpdate implements ICommand
{


	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();


		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsTokenUpdate();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		return str;
	}

	public getCmdID() : number
	{
		return 55016;
	}

	public getCmdName() : string
	{
		return 'PsTokenUpdate';
	}

}
