import { useState } from 'react';

export const useEnterCount = () => {
    const [enterCount, setEnterCount] = useState(1);
    const addCount = () => {
        const c = enterCount + 1;
        setEnterCount(c);
    };
    return { enterCount, addCount };
};
export type useEnterCountState = ReturnType<typeof useEnterCount>;
