import {
    ROUTE_FAVORITE,
    ROUTE_FEATURED,
    ROUTE_GAME,
    ROUTE_LOBBY,
} from '@/models/Route';
import { getHostById } from '@/modules/host/slice';
import { routeSliceActions } from '@/modules/routeController/slice';
import store from '@/store/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, useLocalStorage } from './storage/useLocalStorage';
const isValidLobbyPath = (path: string) => {
    switch (path) {
        case ROUTE_FEATURED:
        case ROUTE_FAVORITE:
        case ROUTE_LOBBY:
            return true;
        default:
            return false;
    }
};
export const useEnterGame = () => {
    const [lastLobby, setLastLobby] = useLocalStorage(
        LocalStorageKey.LastLobby,
        ROUTE_FEATURED
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tryEnterGame = (hostId: number) => {
        const state = store.getState();
        const host = getHostById(state, hostId);
        if (host !== undefined) {
            if (host.IsRest) return;
            else {
                const ll = window.location.pathname;
                if (isValidLobbyPath(ll)) setLastLobby(ll);
                navigate(`${ROUTE_GAME}/${hostId}`);
            }
        }
    };
    const backToLobby = () => {
        let path = ROUTE_FEATURED;
        if (isValidLobbyPath(lastLobby)) path = lastLobby;
        dispatch(routeSliceActions.goto(path));
        // navigate(path);
    };
    return {
        tryEnterGame,
        backToLobby,
    };
};
export type useEnterGameState = ReturnType<typeof useEnterGame>;
