import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLoginFail implements ICommand
{

	public Reason : number = 0;
	public CountryCode : string = "";
	public IP : string = "";
	public CurrencyName : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Reason = ba.readUint8();
		this.CountryCode = ba.readString();
		this.IP = ba.readString();
		this.CurrencyName = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Reason);
		ba.writeString(this.CountryCode);
		ba.writeString(this.IP);
		ba.writeString(this.CurrencyName);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLoginFail();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Reason(B)='+this.Reason+' ';
		str += 'CountryCode(S)='+this.CountryCode+' ';
		str += 'IP(S)='+this.IP+' ';
		str += 'CurrencyName(S)='+this.CurrencyName+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50002;
	}

	public getCmdName() : string
	{
		return 'SpLoginFail';
	}

}
