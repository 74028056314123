import { addBetEpic } from '@/epics/addBetEpic';
import { clearPendingEpic } from '@/epics/clearPendingEpic';
import { initBetEpic } from '@/epics/initBetEpic';
import { undoBetEpic } from '@/epics/undoBetEpic';
import { combineEpics } from 'redux-observable';
import { roadmapEpic } from '../epics/roadmapEpic';
export const rootEpic = combineEpics(
    roadmapEpic,
    initBetEpic,
    addBetEpic,
    undoBetEpic,
    clearPendingEpic
);
