import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { Count, countsSliceActions } from '../../../modules/count/slice';
import { resultProgressSliceActions } from '../../../modules/progress/slice/result';
import { Progress } from '../../games/Progress';
import { CMDScPlayerCount, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScPlayerCountHandler extends AbstractHandler {
    _command: CMDScPlayerCount;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScPlayerCount;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const counts = new Array<Count>();
        // counts.push({
        //     HostId: -1,
        //     Count: this._command.ServerPlayerCount,
        // });
        for (const c of this._command.HostPlayerCount) {
            counts.push({
                HostId: c.HostID,
                Count: c.PlayerCount,
            });
        }
        batch(() => {
            dispatch(countsSliceActions.updateAll(counts));
            dispatch(
                resultProgressSliceActions.endLoad(Progress.SERVER_PLAYER_COUNT)
            );
        });
    }
}
