import { GameFeature as GameFeatureEnum } from '@/models/games/enums/GameFeature';
import { GameType } from '@/models/games/enums/GameType';
import {
    getTableBetByHostId,
    TableBet,
    tableBetSliceActions,
} from '@/modules/betTable/slice';
import { gameSliceActions } from '@/modules/games/slice';
import { getLobbyStateHallGameTypeMap } from '@/modules/lobby/selector';
import { getMainPlayerState } from '@/modules/main/selector';
import { RootState } from '@/store/store';
import { isBitActive } from '@/utils/bitwiseUtil';
import { calcLowHigh, getWithHold } from '@/utils/commonFunc';
import { Epic } from 'redux-observable';
import { concat, filter, switchMap } from 'rxjs';

export const initBetEpic: Epic = (action$, store$) =>
    action$.pipe(
        filter(tableBetSliceActions.initBets.match), //For ABC only
        switchMap(action => {
            const outs = [];
            const hostId = action.payload.hostId;
            const gameId = action.payload.gameId;
            const store = store$.value as unknown as RootState;
            const hosts = getLobbyStateHallGameTypeMap(store);
            const host = hosts?.find(v => v.hostId === hostId);
            const withHold = host ? getWithHold(host.gameType) : undefined;
            let _totalWithHoldAmount = 0;
            let _pendingWithHoldAmount = 0;
            const storeTB = store
                ? getTableBetByHostId(store, hostId)
                : undefined;
            const tb: TableBet = {
                HostId: hostId,
                GameId: gameId,
                BetSeqId: storeTB?.GameId === gameId ? storeTB.BetSeqId : 0,
                PlayerBets:
                    storeTB?.GameId === gameId ? storeTB?.PlayerBets : [],
                BetsSummary: [],
                PendingBets: [],
                ConfirmedBets:
                    storeTB?.GameId === gameId && storeTB?.ConfirmedBets
                        ? storeTB.ConfirmedBets
                        : [],
                TableTotalBet: 0,
                PendingTotalBet: 0,
                isWaitingBet: false,
            };

            let betsum = new Array<number>();
            tb.PlayerBets.forEach(betGroup => {
                for (let i = 0; i < betGroup.bets.length; i++) {
                    if (!betsum[betGroup.bets[i].Type]) {
                        betsum[betGroup.bets[i].Type] = betGroup.bets[i].Amount;
                    } else {
                        betsum[betGroup.bets[i].Type] +=
                            betGroup.bets[i].Amount;
                    }
                }
            });

            tb.BetsSummary = betsum;
            tb.PendingBets = [...betsum];
            let minAmount = 0;
            betsum.forEach(amount => {
                if (amount) {
                    if (minAmount == 0 || amount < minAmount) {
                        minAmount = amount;
                    }
                }
            });
            tb.minAmount = minAmount;
            tb.BetsSummary.forEach((amount, index) => {
                if (amount) {
                    tb.TableTotalBet += amount;
                    if (withHold) {
                        const tmp = withHold.find(v => v.Type == index);
                        if (tmp) {
                            _totalWithHoldAmount += amount * tmp.Amount;
                        }
                    }
                }
            });
            tb.ConfirmedBets.forEach((amount, index) => {
                if (amount) {
                    tb.TableTotalBet += amount;
                    if (withHold) {
                        const tmp = withHold.find(v => v.Type == index);
                        if (tmp) {
                            _totalWithHoldAmount += amount * tmp.Amount;
                        }
                    }
                }
            });
            tb.PendingBets.forEach((amount, index) => {
                if (amount) {
                    tb.PendingTotalBet += amount;
                    if (withHold) {
                        const tmp = withHold.find(v => v.Type == index);
                        if (tmp) {
                            _pendingWithHoldAmount += amount * tmp.Amount;
                        }
                    }
                }
            });
            tb.BetSeqId += 1;

            outs.push(tableBetSliceActions.update(tb));
            outs.push(
                gameSliceActions.updateHostBetInfo({
                    hostId: hostId,
                    totalBetAmount: tb.TableTotalBet,
                    pendingBetAmount: tb.PendingTotalBet,
                    totalWithHoldAmount: _totalWithHoldAmount,
                    pendingWithHoldAmount: _pendingWithHoldAmount,
                })
            );
            if (host && host.gameType === GameType.Baccarat) {
                const { GameFeature } = getMainPlayerState(store);
                const curGameFeature = calcLowHigh(GameFeature);
                const isAutoConfirmEnabled = isBitActive(
                    curGameFeature,
                    GameFeatureEnum.AutoBetConfirmation
                );
                outs.push(
                    gameSliceActions.updateBaccaratBetHistory({
                        hostId: hostId,
                        gameId: gameId,
                        betsSummary: isAutoConfirmEnabled
                            ? tb.BetsSummary
                            : tb.ConfirmedBets,
                    })
                );
            }
            return concat(outs);
        })
    );
