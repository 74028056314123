import { MAX_BLACKJACK_HAND } from './Constant';
import { DealtTurn } from './DealtTurn';

export enum DealtLoop {
    WaitSeatPlayerLoopDecision = Number(DealtTurn.LoopStart),
    WaitLastCard = WaitSeatPlayerLoopDecision + MAX_BLACKJACK_HAND * 2 - 1,
    LastTurnState = WaitLastCard + 1,
}
export const isWaitDecision = (s: number) =>
    s < DealtLoop.LastTurnState &&
    s >= DealtLoop.WaitSeatPlayerLoopDecision &&
    (s - DealtLoop.WaitSeatPlayerLoopDecision) % 2 === 0;
export const isWaitCard = (s: number) =>
    s < DealtLoop.LastTurnState &&
    s >= DealtLoop.WaitSeatPlayerLoopDecision &&
    (s - DealtLoop.WaitSeatPlayerLoopDecision) % 2 !== 0;
export const getSeatPlayerLoopDecisionValue = (t: number) =>
    DealtLoop.WaitSeatPlayerLoopDecision + (t - 1) * 2;
export const getWaitCardValue = (t: number) =>
    getSeatPlayerLoopDecisionValue(t) + 1;
