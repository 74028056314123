import { getPromotionToolState } from '@/modules/main/selector';
import { getDisplayTimeZone } from '@/modules/popup/promotiontool/PromotionUtil';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

export const usePromotionTool = () => {
    const { EndTimestamp, Timezone } = useSelector((state: RootState) =>
        getPromotionToolState(state)
    );
    const displayTimezone = getDisplayTimeZone(Timezone);

    const eventEnded = (): boolean => {
        const timezone = -displayTimezone;
        const timeDifference =
            EndTimestamp * 1000 + timezone * 60 * 60 * 1000 - Date.now();
        return timeDifference < 0;
    };
    return { eventEnded };
};
