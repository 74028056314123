import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { AndarBaharStatistics } from '../../games/andarBahar'; 
import { CMDSpAndarBaharStatistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpAndarBaharStatisticsHandler extends AbstractHandler {
    _command: CMDSpAndarBaharStatistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpAndarBaharStatistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as AndarBaharStatistics;
        const hostId = this._command.HostID;
        statistics.TotalAndarCount = this._command.Statistics.TotalAndarCount;
        statistics.TotalBaharCount = this._command.Statistics.TotalBaharCount;
        
        statistics.Andar1Count = this._command.Statistics.Andar1Count;
        statistics.Bahar1Count = this._command.Statistics.Bahar1Count;

        statistics.Andar2Count = this._command.Statistics.Andar2Count;
        statistics.Bahar2Count = this._command.Statistics.Bahar2Count;

        statistics.Andar3Count = this._command.Statistics.Andar3Count;
        statistics.Bahar3Count = this._command.Statistics.Bahar3Count;

        statistics.Andar4Count = this._command.Statistics.Andar4Count;
        statistics.Bahar4Count = this._command.Statistics.Bahar4Count;

        statistics.Andar5Count = this._command.Statistics.Andar5Count;
        statistics.Bahar5Count = this._command.Statistics.Bahar5Count;

        statistics.Andar6Count = this._command.Statistics.Andar6Count;
        statistics.Bahar6Count = this._command.Statistics.Bahar6Count;

        statistics.Andar7Count = this._command.Statistics.Andar7Count;
        statistics.Bahar7Count = this._command.Statistics.Bahar7Count;

        statistics.Andar8Count = this._command.Statistics.Andar8Count;
        statistics.Bahar8Count = this._command.Statistics.Bahar8Count;

        statistics.Andar9Count = this._command.Statistics.Andar9Count;
        statistics.Bahar9Count = this._command.Statistics.Bahar9Count;

        statistics.Andar10Count = this._command.Statistics.Andar10Count;
        statistics.Bahar10Count = this._command.Statistics.Bahar10Count;

        statistics.AndarJCount = this._command.Statistics.AndarJCount;
        statistics.BaharJCount = this._command.Statistics.BaharJCount;

        statistics.AndarQCount = this._command.Statistics.AndarQCount;
        statistics.BaharQCount = this._command.Statistics.BaharQCount;

        statistics.AndarKCount = this._command.Statistics.AndarKCount;
        statistics.BaharKCount = this._command.Statistics.BaharKCount;

        statistics.PokerCount1To5 = this._command.Statistics.PokerCount1To5;
        statistics.PokerCount6To10 = this._command.Statistics.PokerCount6To10;
        statistics.PokerCount11To15 = this._command.Statistics.PokerCount11To15;
        statistics.PokerCount16To20 = this._command.Statistics.PokerCount16To20;
        statistics.PokerCount21To25 = this._command.Statistics.PokerCount21To25;
        statistics.PokerCount26To30 = this._command.Statistics.PokerCount26To30;
        statistics.PokerCount31To35 = this._command.Statistics.PokerCount31To35;
        statistics.PokerCount36To40 = this._command.Statistics.PokerCount36To40;
        statistics.PokerCount41To45 = this._command.Statistics.PokerCount41To45;
        statistics.PokerCount46To49 = this._command.Statistics.PokerCount46To49;
        statistics.PokerCountFirst3 = this._command.Statistics.PokerCountFirst3;
        statistics.PokerCountFirstAndar = this._command.Statistics.PokerCountFirstAndar;
        statistics.PokerCountFirstBahar = this._command.Statistics.PokerCountFirstBahar;

        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}