import { CMDBaseInformation } from "./CMDBaseInformation"
import { CMDBlackjack } from "./CMDBlackjack"
import { CMDBlackjackCountDown } from "./CMDBlackjackCountDown"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScInitBlackjack implements ICommand
{

	public CountDownDelay : number = 0;
	public BJMultiplier : number = 0;
	public CountDownSetting : CMDBlackjackCountDown;
	public Information : CMDBaseInformation;
	public OldResult : Array<CMDBlackjack>;
	public CurrentState : number = 0;
	public CurrentResult : CMDBlackjack;

	public constructor()
	{
		this.OldResult = new Array<CMDBlackjack>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.CountDownDelay = ba.readUint32();
		this.BJMultiplier = ba.readUint32();
		this.CountDownSetting = new CMDBlackjackCountDown();
		this.CountDownSetting.fromBytes(ba);
		this.Information = new CMDBaseInformation();
		this.Information.fromBytes(ba);
		let OldResultSize:number = ba.readUint8();
		while (OldResultSize-- > 0)
		{
			let OldResultChild:CMDBlackjack = new CMDBlackjack();
			OldResultChild.fromBytes(ba);
			this.OldResult.push(OldResultChild);
		}
		this.CurrentState = ba.readUint16();
		this.CurrentResult = new CMDBlackjack();
		this.CurrentResult.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.CountDownDelay);
		ba.writeUint32(this.BJMultiplier);
		let tempBuf2 = this.CountDownSetting.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Information.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		ba.writeUint8(this.OldResult.length);
		for (const OldResultChild of this.OldResult)
		{
			let tempBuf = OldResultChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint16(this.CurrentState);
		let tempBuf7 = this.CurrentResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScInitBlackjack();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'CountDownDelay(D)='+this.CountDownDelay+' ';
		str += 'BJMultiplier(D)='+this.BJMultiplier+' ';
		str += 'CountDownSetting(CMDBlackjackCountDown)='+this.CountDownSetting+' ';
		str += 'Information(CMDBaseInformation)='+this.Information+' ';
		str += 'OldResult_size(B)='+this.OldResult.length + ' ';
		str += 'OldResult(CMDBlackjack)=[';
		for (let OldResultChild in this.OldResult)
		{
			if (<number><unknown>OldResultChild > 0) str += ', ';
			str += this.OldResult[OldResultChild].toString();
		}
		str += '] ';
		str += 'CurrentState(W)='+this.CurrentState+' ';
		str += 'CurrentResult(CMDBlackjack)='+this.CurrentResult+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30507;
	}

	public getCmdName() : string
	{
		return 'ScInitBlackjack';
	}

}
