import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsEgameDebugJackpot implements ICommand
{

	public JackpotType : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.JackpotType = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.JackpotType);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsEgameDebugJackpot();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'JackpotType(B)='+this.JackpotType+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55027;
	}

	public getCmdName() : string
	{
		return 'PsEgameDebugJackpot';
	}

}
