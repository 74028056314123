import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpXocdiaStatistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { SedieStatistics } from '../../games/sedie';
import { hostsSliceActions } from '../../../modules/host/slice';

export class CMDSpSedieStatisticsHandler extends AbstractHandler {
    _command: CMDSpXocdiaStatistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpXocdiaStatistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as SedieStatistics;
        const hostId = this._command.HostID;
        statistics.FourRedCount = this._command.Statistics.FourRedCount;
        statistics.ThreeRedCount = this._command.Statistics.ThreeRedCount;
        statistics.TwoRedTwoWhiteCount =
            this._command.Statistics.TwoRedTwoWhiteCount;
        statistics.ThreeWhiteCount = this._command.Statistics.ThreeWhiteCount;
        statistics.FourWhiteCount = this._command.Statistics.FourWhiteCount;
        statistics.FourRedOrWhiteCount =
            this._command.Statistics.FourRedOrWhiteCount;
        statistics.BigCount = this._command.Statistics.BigCount;
        statistics.SmallCount = this._command.Statistics.SmallCount;
        statistics.OddCount = this._command.Statistics.OddCount;
        statistics.EvenCount = this._command.Statistics.EvenCount;

        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
