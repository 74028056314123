import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScServer implements ICommand
{

	public IP : string = "";
	public Port : number = 0;
	public UTCOffset : number = 0;
	public System : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.IP = ba.readString();
		this.Port = ba.readUint32();
		this.UTCOffset = ba.readInt53();
		this.System = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.IP);
		ba.writeUint32(this.Port);
		ba.writeUint53(this.UTCOffset);
		ba.writeUint53(this.System);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScServer();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'IP(S)='+this.IP+' ';
		str += 'Port(D)='+this.Port+' ';
		str += 'UTCOffset(Q)='+this.UTCOffset+' ';
		str += 'System(Q)='+this.System+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30002;
	}

	public getCmdName() : string
	{
		return 'ScServer';
	}

}
