import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { betRecordSliceActions } from '../../../modules/report/slice/betRecord';
import { CMDSpBetLogQueryTotal, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpBetLogQueryTotalHandler extends AbstractHandler {
    _command: CMDSpBetLogQueryTotal;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBetLogQueryTotal;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        dispatch(betRecordSliceActions.updateTotal(this._command.TotalRow));
    }
}
