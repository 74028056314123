import { PromotionToolEnum } from '@/components/images/base64/PromotionTool';

export const promotionMoneyFigure: { [key: string]: PromotionToolEnum } = {
    event_comma: PromotionToolEnum.event_comma,
    event_dot: PromotionToolEnum.event_dot,
    event_0: PromotionToolEnum.event_0,
    event_1: PromotionToolEnum.event_1,
    event_2: PromotionToolEnum.event_2,
    event_3: PromotionToolEnum.event_3,
    event_4: PromotionToolEnum.event_4,
    event_5: PromotionToolEnum.event_5,
    event_6: PromotionToolEnum.event_6,
    event_7: PromotionToolEnum.event_7,
    event_8: PromotionToolEnum.event_8,
    event_9: PromotionToolEnum.event_9,
};

export const promotionTimeFigure: { [key: string]: PromotionToolEnum } = {
    event_clock_colon: PromotionToolEnum.event_clock_colon,
    event_clock_0: PromotionToolEnum.event_clock_0,
    event_clock_1: PromotionToolEnum.event_clock_1,
    event_clock_2: PromotionToolEnum.event_clock_2,
    event_clock_3: PromotionToolEnum.event_clock_3,
    event_clock_4: PromotionToolEnum.event_clock_4,
    event_clock_5: PromotionToolEnum.event_clock_5,
    event_clock_6: PromotionToolEnum.event_clock_6,
    event_clock_7: PromotionToolEnum.event_clock_7,
    event_clock_8: PromotionToolEnum.event_clock_8,
    event_clock_9: PromotionToolEnum.event_clock_9,
};

const promotionTitle: { [key: string]: PromotionToolEnum } = {
    event_title_en_US: PromotionToolEnum.event_title_en_US,
    event_title_es_ES: PromotionToolEnum.event_title_es_ES,
    event_title_id_ID: PromotionToolEnum.event_title_id_ID,
    event_title_ja_JP: PromotionToolEnum.event_title_ja_JP,
    event_title_ko_KR: PromotionToolEnum.event_title_ko_KR,
    event_title_th_TH: PromotionToolEnum.event_title_th_TH,
    event_title_vi_VN: PromotionToolEnum.event_title_vi_VN,
    event_title_zh_CN: PromotionToolEnum.event_title_zh_CN,
    event_title_zh_TW: PromotionToolEnum.event_title_zh_TW,
};
export const getPromotionTitle = (key: string) => {
    const value = promotionTitle[key];
    return value ? value : PromotionToolEnum.event_title_en_US;
};

export const promotionPrizeGSB: { [key: string]: PromotionToolEnum } = {
    event_1st_selected: PromotionToolEnum.event_1st_selected,
    event_1st_unselected: PromotionToolEnum.event_1st_unselected,
    event_2nd_selected: PromotionToolEnum.event_2nd_selected,
    event_2nd_unselected: PromotionToolEnum.event_2nd_unselected,
    event_3rd_selected: PromotionToolEnum.event_3rd_selected,
    event_3rd_unselected: PromotionToolEnum.event_3rd_unselected,
};

export const promotionScrachCard: { [key: string]: PromotionToolEnum } = {
    event_1st_scratch_card_bg: PromotionToolEnum.event_1st_scratch_card_bg,
    event_2nd_scratch_card_bg: PromotionToolEnum.event_2nd_scratch_card_bg,
    event_3rd_scratch_card_bg: PromotionToolEnum.event_3rd_scratch_card_bg,
    event_invalid_scratch_card_bg:
        PromotionToolEnum.event_invalid_scratch_card_bg,
};

export const promotionScratchAnimation: { [key: string]: PromotionToolEnum } = {
    event_scratch_card_scratch_1:
        PromotionToolEnum.event_scratch_card_scratch_1,
    event_scratch_card_scratch_2:
        PromotionToolEnum.event_scratch_card_scratch_2,
    event_scratch_card_scratch_3:
        PromotionToolEnum.event_scratch_card_scratch_3,
    event_scratch_card_scratch_4:
        PromotionToolEnum.event_scratch_card_scratch_4,
    event_scratch_card_scratch_5:
        PromotionToolEnum.event_scratch_card_scratch_5,
    event_scratch_card_scratch_6:
        PromotionToolEnum.event_scratch_card_scratch_6,
    event_scratch_card_scratch_7:
        PromotionToolEnum.event_scratch_card_scratch_7,
    event_scratch_card_scratch_8:
        PromotionToolEnum.event_scratch_card_scratch_8,
    event_scratch_card_scratch_9:
        PromotionToolEnum.event_scratch_card_scratch_9,
    event_scratch_card_scratch_10:
        PromotionToolEnum.event_scratch_card_scratch_10,
};

export const promotionRanking: { [key: string]: PromotionToolEnum } = {
    event_no_1: PromotionToolEnum.event_no_1,
    event_no_2: PromotionToolEnum.event_no_2,
    event_no_3: PromotionToolEnum.event_no_3,
};
