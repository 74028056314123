import moment from 'moment';
import { useEffect } from 'react';
import { lt, valid } from 'semver';
import packageJson from '../../package.json';
import { ROUTE_ROOT } from '../models/Route';
import { useDocumentVisible } from './useDocumentVisible';
export const LVT_KEY = 'lvt';
const minute = 60 * 1000;
export const useVersion = () => {
    const isActive = useDocumentVisible();
    useEffect(() => {
        const version = packageJson.version;
        const pathName = window.location.pathname;
        if (isActive && document.readyState === 'complete') {
            fetch(
                `${process.env.PUBLIC_URL}/version.dat?v=${moment().valueOf()}`
            )
                .then(resp => {
                    resp.text().then(buildVersion => {
                        // check version.dat is a valid version format
                        const isValidVersion = valid(buildVersion) !== null;
                        if (isValidVersion) {
                            // if outdated
                            if (lt(version, buildVersion)) {
                                // do refresh in a minute
                                const last = Number(
                                    window.localStorage.getItem(LVT_KEY)
                                );
                                if (!isNaN(last)) {
                                    const lvt = moment().valueOf();
                                    const canRefresh = lvt - last > minute;
                                    console.log('hook::version', lvt - last);
                                    if (
                                        canRefresh &&
                                        !pathName.endsWith(ROUTE_ROOT)
                                    ) {
                                        console.log(
                                            'hook::version::outdated',
                                            version,
                                            buildVersion
                                        );
                                        window.localStorage.setItem(
                                            LVT_KEY,
                                            lvt.toString()
                                        );
                                        window.location.reload();
                                    }
                                }
                            }
                        }
                    });
                })
                .catch(err => {
                    console.error('hook::version::error', err);
                });
        }
    }, [isActive, window.location.pathname]);
};
