import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetXocdia implements ICommand
{

	public Total : CMDTotalBet;
	public FourRed : CMDBetSummary;
	public ThreeRed : CMDBetSummary;
	public TwoRedTwoWhite : CMDBetSummary;
	public ThreeWhite : CMDBetSummary;
	public FourWhite : CMDBetSummary;
	public FourRedOrWhite : CMDBetSummary;
	public Big : CMDBetSummary;
	public Small : CMDBetSummary;
	public Odd : CMDBetSummary;
	public Even : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.FourRed = new CMDBetSummary();
		this.FourRed.fromBytes(ba);
		this.ThreeRed = new CMDBetSummary();
		this.ThreeRed.fromBytes(ba);
		this.TwoRedTwoWhite = new CMDBetSummary();
		this.TwoRedTwoWhite.fromBytes(ba);
		this.ThreeWhite = new CMDBetSummary();
		this.ThreeWhite.fromBytes(ba);
		this.FourWhite = new CMDBetSummary();
		this.FourWhite.fromBytes(ba);
		this.FourRedOrWhite = new CMDBetSummary();
		this.FourRedOrWhite.fromBytes(ba);
		this.Big = new CMDBetSummary();
		this.Big.fromBytes(ba);
		this.Small = new CMDBetSummary();
		this.Small.fromBytes(ba);
		this.Odd = new CMDBetSummary();
		this.Odd.fromBytes(ba);
		this.Even = new CMDBetSummary();
		this.Even.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.FourRed.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.ThreeRed.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.TwoRedTwoWhite.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.ThreeWhite.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.FourWhite.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.FourRedOrWhite.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Big.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.Small.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)
		let tempBuf9 = this.Odd.toBytes().toBuffer();
		ba.appendBuf(tempBuf9)
		let tempBuf10 = this.Even.toBytes().toBuffer();
		ba.appendBuf(tempBuf10)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetXocdia();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'FourRed(CMDBetSummary)='+this.FourRed+' ';
		str += 'ThreeRed(CMDBetSummary)='+this.ThreeRed+' ';
		str += 'TwoRedTwoWhite(CMDBetSummary)='+this.TwoRedTwoWhite+' ';
		str += 'ThreeWhite(CMDBetSummary)='+this.ThreeWhite+' ';
		str += 'FourWhite(CMDBetSummary)='+this.FourWhite+' ';
		str += 'FourRedOrWhite(CMDBetSummary)='+this.FourRedOrWhite+' ';
		str += 'Big(CMDBetSummary)='+this.Big+' ';
		str += 'Small(CMDBetSummary)='+this.Small+' ';
		str += 'Odd(CMDBetSummary)='+this.Odd+' ';
		str += 'Even(CMDBetSummary)='+this.Even+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30771;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetXocdia';
	}

}
