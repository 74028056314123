import chipJson from '@/components/common/chips/chip/chip.json';
import { getGoodRoadRefresh } from '@/modules/lobby/selector';
import moment from 'moment';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BannerConfigType,
    CommonConfigType,
    GameConfigHostsType,
    GameConfigType,
    dafaBetPermission,
    disableLogoutLobby,
    hideItem,
    isCowCowShow,
    isShowGiftLobby,
    productionTestPermission,
} from '../hooks/useConfig';
import { Progress } from '../models/games/Progress';
import { getLandingState, getMainUserState } from '../modules/main/selector';
import { loadingProgressSliceActions } from '../modules/progress/slice/loading';
import { resultProgressSliceActions } from '../modules/progress/slice/result';
import { RootState } from '../store/store';

export const CommonConfigContext = createContext({} as CommonConfigType);
export const GameConfigContext = createContext({} as GameConfigType);
export const BannerConfigContext = createContext({} as BannerConfigType);

type ConfigProviderProps = {
    children: JSX.Element;
};

export const ConfigProvider = (props: ConfigProviderProps) => {
    const { chipSet } = chipJson;
    const { children } = props;
    const { LobbyCode: serLobbyCode, Username: serUserName } = useSelector(
        (state: RootState) => getMainUserState(state)
    );
    const { lobby: paramLobbyCode } = useSelector((state: RootState) =>
        getLandingState(state)
    );
    const refresh = useSelector(getGoodRoadRefresh);
    const [commonConfig, setCommonConfig] = useState<CommonConfigType>(
        {} as CommonConfigType
    );
    const [gameConfig, setGameConfig] = useState<GameConfigType>(
        {} as GameConfigType
    );
    const [bannerConfig, setBannerConfig] = useState<BannerConfigType>(
        {} as BannerConfigType
    );
    const [commonConfigJson, setCommonConfigJson] =
        useState<CommonConfigType>();
    const [gameConfigJson, setGameConfigJson] = useState<GameConfigType>();
    const [bannerConfigJson, setBannerConfigJson] =
        useState<BannerConfigType>();

    const dispatch = useDispatch();

    const lobbyCode = serLobbyCode
        ? serLobbyCode?.toLowerCase()
        : paramLobbyCode?.toLowerCase();

    const setTitleIcon = (CommonConfigSite: CommonConfigType['site']) => {
        let title = 'SA Gaming';
        let icon = 'resource/assets/blank_favicon.ico?';

        try {
            CommonConfigSite.map(v => {
                const siteUrl = v.url;
                const localHref = window.location.href;
                if (siteUrl.map(u => localHref.includes(u)).includes(true)) {
                    title = v.title;
                    icon = v.icon;
                }
            });

            const dateTime = new Date();
            const linkIcon = icon + dateTime.getTime();
            document.title = title;
            const link = document.getElementById('favicon') as HTMLLinkElement;
            if (link) {
                link.href = linkIcon;
            } else {
                const newLink = document.createElement('link');
                newLink.id = 'favicon';
                newLink.rel = 'icon';
                newLink.href = linkIcon;
                document.head.appendChild(link);
            }
        } catch (e) {
            console.log('fav icon error ' + e);
        }
    };

    const getGameConfig = (lobbyCode: string | undefined) => {
        const rData = {} as GameConfigType;
        if (!lobbyCode) return rData;
        if (gameConfigJson) {
            const jsonHostList = new Array<GameConfigHostsType>();
            for (const host of gameConfigJson.hosts.hostList)
                jsonHostList.push(host);

            rData.hosts = { hostList: jsonHostList };
            rData.chipSet = chipSet.sort((a, b) => b.value - a.value);
        }

        return rData;
    };

    const getCommonConfig = (
        lobbyCode: string | undefined,
        serLobbyCode: string | undefined,
        serUserName: string | undefined
    ) => {
        const rData = {} as CommonConfigType;
        if (!lobbyCode || !serLobbyCode || !serUserName) return rData;
        if (commonConfigJson) {
            rData.site = commonConfigJson.site as CommonConfigType['site'];
            rData.productionTestPermission = productionTestPermission(
                commonConfigJson.permission?.pTest as string[],
                lobbyCode
            );
            rData.dafaBetPermission = dafaBetPermission(
                commonConfigJson.permission?.dafa as string[],
                lobbyCode
            );
            rData.isCowCowShow = isCowCowShow(
                commonConfigJson.permission?.cd as string[],
                lobbyCode
            );
            rData.isShowGiftLobby = isShowGiftLobby(
                commonConfigJson.permission?.giftHide as string[],
                lobbyCode
            );
            rData.disableLogoutLobby = disableLogoutLobby(
                commonConfigJson.permission?.logoutHide as string[],
                lobbyCode
            );

            rData.iconRoller =
                commonConfigJson.iconRoller as CommonConfigType['iconRoller'];

            rData.hideFeedback = hideItem(
                commonConfigJson.hide?.feedback as string[],
                lobbyCode
            );

            rData.hideFullScreen = hideItem(
                commonConfigJson.hide?.fullScreen as string[],
                lobbyCode
            );

            rData.hideBackToLobby = hideItem(
                commonConfigJson.hide?.backToLobby as string[],
                lobbyCode
            );

            rData.hideMultiBet = hideItem(
                commonConfigJson.hide?.multiBet as string[],
                lobbyCode
            );

            rData.hideSwitchTable = hideItem(
                commonConfigJson.hide?.switchTable as string[],
                lobbyCode
            );

            rData.langOnly =
                commonConfigJson.langOnly as CommonConfigType['langOnly'];
            rData.langExclude =
                commonConfigJson.langExclude as CommonConfigType['langExclude'];

            rData.msg = commonConfigJson.msg;

            rData.showCurrencyName =
                commonConfigJson.showCurrencyName as CommonConfigType['showCurrencyName'];

            rData.headerBackground =
                commonConfigJson.headerBackground as CommonConfigType['headerBackground'];
        }
        return rData;
    };

    const getBannerConfig = (lobbyCode: string | undefined) => {
        const rData = {} as BannerConfigType;
        if (!lobbyCode) return rData;
        if (bannerConfigJson) {
            rData.banners =
                bannerConfigJson.banners as BannerConfigType['banners'];
        }
        return rData;
    };

    useEffect(() => {
        if (lobbyCode && serLobbyCode && serUserName) {
            const commonConfigData = getCommonConfig(
                lobbyCode,
                serLobbyCode,
                serUserName
            );
            setTitleIcon(commonConfigData.site);
            setCommonConfig(commonConfigData);
        }
    }, [lobbyCode, serLobbyCode, serUserName, commonConfigJson]);

    useEffect(() => {
        if (lobbyCode) {
            const gameConfigData = getGameConfig(lobbyCode);
            setGameConfig(gameConfigData);
        }
    }, [lobbyCode, gameConfigJson]);

    useEffect(() => {
        if (lobbyCode) {
            const bannerConfigJson = getBannerConfig(lobbyCode);
            setBannerConfig(bannerConfigJson);
        }
    }, [lobbyCode, bannerConfigJson]);

    const loadCommonConfig = async () => {
        dispatch(
            loadingProgressSliceActions.startLoad(
                Progress.CDN_COMMON_CONFIG_DATA
            )
        );
        const url = process.env.REACT_APP_CDN_API;
        const timestamp = moment().valueOf();
        fetch(`${url}/common_config.dat?v=${timestamp}`)
            .then(response => {
                if (response.ok) {
                    response
                        .text()
                        .then(raw => {
                            const j = atob(raw);
                            setCommonConfigJson(JSON.parse(j));
                        })
                        .then(() => {
                            dispatch(
                                resultProgressSliceActions.endLoad(
                                    Progress.CDN_COMMON_CONFIG_DATA
                                )
                            );
                        });
                }
            })
            .catch(err => {
                console.log(
                    'app::context::resource',
                    'can not load common_config.dat',
                    err,
                    url
                );
            });
    };

    const loadGameConfig = async () => {
        dispatch(
            loadingProgressSliceActions.startLoad(Progress.CDN_GAME_CONFIG_DATA)
        );

        const url = process.env.REACT_APP_CDN_API;
        const timestamp = moment().valueOf();
        fetch(`${url}/game_config.dat?v=${timestamp}`)
            .then(response => {
                if (response.ok) {
                    response
                        .text()
                        .then(raw => {
                            const j = atob(raw);
                            setGameConfigJson(JSON.parse(j));
                        })
                        .then(() => {
                            dispatch(
                                resultProgressSliceActions.endLoad(
                                    Progress.CDN_GAME_CONFIG_DATA
                                )
                            );
                        });
                }
            })
            .catch(err => {
                console.log(
                    'app::context::resource',
                    'can not load game_config.json',
                    err,
                    url
                );
            });
    };

    const loadBannerConfig = async () => {
        const url = process.env.REACT_APP_CDN_API;
        const timestamp = moment().valueOf();
        fetch(`${url}/banner_config.dat?v=${timestamp}`)
            .then(response => {
                if (response.ok) {
                    response
                        .text()
                        .then(raw => {
                            const j = atob(raw);
                            setBannerConfigJson(JSON.parse(j));
                        })
                        .catch(() => {})
                        .then(() => {
                            // dispatch(
                            //     resultProgressSliceActions.endLoad(
                            //         Progress.CDN_GAME_CONFIG_DATA
                            //     )
                            // );
                        });
                }
            })
            .catch(err => {
                console.log(
                    'app::context::resource',
                    'can not load game_config.json',
                    err,
                    url
                );
            });
    };

    useEffect(() => {
        if (refresh) {
            loadBannerConfig();
        }
    }, [refresh]);

    useEffect(() => {
        loadCommonConfig();
        loadGameConfig();
        loadBannerConfig();
    }, []);

    return (
        <>
            <CommonConfigContext.Provider value={commonConfig}>
                <GameConfigContext.Provider value={gameConfig}>
                    <BannerConfigContext.Provider value={bannerConfig}>
                        {children}
                    </BannerConfigContext.Provider>
                </GameConfigContext.Provider>
            </CommonConfigContext.Provider>
        </>
    );
};
