import { BetPayload } from '@/models/games/BetRoom';
import { RootState } from '@/store/store';
import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit';

export const BET_GAME_KEY = 'app::bet::game';
export type BetGame = {
    id: number; //game id
    bets: Array<BetPayload>;
};
export const betGameAdapter = createEntityAdapter<BetGame, number>({
    selectId: g => g.id,
    sortComparer: (a, b) => a.id - b.id,
});
const initialBetGameState = betGameAdapter.getInitialState();
type AddBetGamePayload = {
    id: number;
    bet: BetPayload;
};
const addBetGame = (
    state: EntityState<BetGame, number>,
    action: PayloadAction<AddBetGamePayload>
) => {
    const gameId = action.payload.id;
    const newBets = new Array<BetPayload>();

    const oldGame = state.entities[gameId];
    if (oldGame !== undefined && oldGame.bets.length > 0) {
        for (const oldBet of oldGame.bets) {
            newBets.push(oldBet);
        }
        newBets.push(action.payload.bet);
        return betGameAdapter.updateOne(state, {
            id: gameId,
            changes: {
                bets: newBets,
            },
        });
    } else
        return betGameAdapter.addOne(state, {
            id: gameId,
            bets: [action.payload.bet],
        });
};
const clearBetGame = (
    state: EntityState<BetGame, number>,
    action: PayloadAction<number>
) => betGameAdapter.removeOne(state, action.payload);
const betGameSlice = createSlice({
    name: BET_GAME_KEY,
    initialState: initialBetGameState,
    reducers: {
        addBetGame,
        clearBetGame,
    },
});
export const betGameSliceReducer = betGameSlice.reducer;
export const betGameSliceActions = betGameSlice.actions;

const getBetGameSelectors = betGameAdapter.getSelectors<RootState>(
    state => state[BET_GAME_KEY]
);
export const getBetGame = getBetGameSelectors.selectById;
