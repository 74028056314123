import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpRedeemResult implements ICommand
{

	public Result : number = 0;
	public ResultAmount : number = 0;
	public TotalRedeemAmount : number = 0;
	public RemainDayLimit : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Result = ba.readUint8();
		this.ResultAmount = ba.readUint53();
		this.TotalRedeemAmount = ba.readUint53();
		this.RemainDayLimit = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Result);
		ba.writeUint53(this.ResultAmount);
		ba.writeUint53(this.TotalRedeemAmount);
		ba.writeUint32(this.RemainDayLimit);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpRedeemResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Result(B)='+this.Result+' ';
		str += 'ResultAmount(Q)='+this.ResultAmount+' ';
		str += 'TotalRedeemAmount(Q)='+this.TotalRedeemAmount+' ';
		str += 'RemainDayLimit(D)='+this.RemainDayLimit+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50120;
	}

	public getCmdName() : string
	{
		return 'SpRedeemResult';
	}

}
