import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const BET_SUMMARY_KEY = 'app::report::summary';
export type Summary = {
    ReportDate : string;
	ResultAmount : number;
    RollingAmount : number;
    TurnOverAmount : number;
}
export type BetSummaryState = {
    summaries: Array<Summary>;
};

const updateSummary = (state: BetSummaryState, actions: PayloadAction<BetSummaryState>) => {
    state.summaries = actions.payload.summaries;
};
const reset = (state: BetSummaryState) => {
    state.summaries = initialBetSummary.summaries;
};
const initialBetSummary = {} as BetSummaryState;
const betSummarySlice = createSlice({
    name: BET_SUMMARY_KEY,
    initialState: initialBetSummary,
    reducers: {
        updateSummary,
        reset
    },
});
export const betSummarySliceReducer = betSummarySlice.reducer;
export const betSummarySliceActions = betSummarySlice.actions;
