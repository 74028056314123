import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetRoulette implements ICommand
{

	public Total : CMDTotalBet;
	public Red : CMDBetSummary;
	public Black : CMDBetSummary;
	public Big : CMDBetSummary;
	public Small : CMDBetSummary;
	public Odd : CMDBetSummary;
	public Even : CMDBetSummary;
	public Zero : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Red = new CMDBetSummary();
		this.Red.fromBytes(ba);
		this.Black = new CMDBetSummary();
		this.Black.fromBytes(ba);
		this.Big = new CMDBetSummary();
		this.Big.fromBytes(ba);
		this.Small = new CMDBetSummary();
		this.Small.fromBytes(ba);
		this.Odd = new CMDBetSummary();
		this.Odd.fromBytes(ba);
		this.Even = new CMDBetSummary();
		this.Even.fromBytes(ba);
		this.Zero = new CMDBetSummary();
		this.Zero.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Red.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Black.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Big.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Small.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Odd.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Even.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Zero.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetRoulette();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Red(CMDBetSummary)='+this.Red+' ';
		str += 'Black(CMDBetSummary)='+this.Black+' ';
		str += 'Big(CMDBetSummary)='+this.Big+' ';
		str += 'Small(CMDBetSummary)='+this.Small+' ';
		str += 'Odd(CMDBetSummary)='+this.Odd+' ';
		str += 'Even(CMDBetSummary)='+this.Even+' ';
		str += 'Zero(CMDBetSummary)='+this.Zero+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30755;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetRoulette';
	}

}
