export type BetAmount = {
    [key: string]: BetPlayerAmount;
};
export type BetPlayerAmount = {
    Amount: number;
    BetPlayerCount: number;
};

export type Bet = {
    GameId: number;
    Type: number;
    Amount: number;
    ContainNum?: Array<number>;
    isMultiBet?: boolean;
};

export type BetGroup = {
    index: number;
    bets: Array<Bet>;
};

export enum BetFormat {
    MANUAL_BET,
    AUTO_BET,
    UNDO_BET,
    AUTO_BET_CONFIRMED,
}
export enum BetState {
    ADDED,
    SYNCED,
    CONFIRMED,
    CANCELLED,
    FAILED,
}
