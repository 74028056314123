import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsChangePassword implements ICommand
{

	public type : number = 0;
	public OldPassword : string = "";
	public NewPassword : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.type = ba.readUint16();
		this.OldPassword = ba.readString();
		this.NewPassword = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.type);
		ba.writeString(this.OldPassword);
		ba.writeString(this.NewPassword);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsChangePassword();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'type(W)='+this.type+' ';
		str += 'OldPassword(S)='+this.OldPassword+' ';
		str += 'NewPassword(S)='+this.NewPassword+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55090;
	}

	public getCmdName() : string
	{
		return 'PsChangePassword';
	}

}
