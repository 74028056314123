import { CMDEgameConfig } from "./CMDEgameConfig"
import { CMDLiveGameConfig } from "./CMDLiveGameConfig"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpClientConfig implements ICommand
{

	public LiveGame : Array<CMDLiveGameConfig>;
	public EGame : Array<CMDEgameConfig>;

	public constructor()
	{
		this.LiveGame = new Array<CMDLiveGameConfig>();
		this.EGame = new Array<CMDEgameConfig>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let LiveGameSize:number = ba.readUint8();
		while (LiveGameSize-- > 0)
		{
			let LiveGameChild:CMDLiveGameConfig = new CMDLiveGameConfig();
			LiveGameChild.fromBytes(ba);
			this.LiveGame.push(LiveGameChild);
		}
		let EGameSize:number = ba.readUint8();
		while (EGameSize-- > 0)
		{
			let EGameChild:CMDEgameConfig = new CMDEgameConfig();
			EGameChild.fromBytes(ba);
			this.EGame.push(EGameChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.LiveGame.length);
		for (const LiveGameChild of this.LiveGame)
		{
			let tempBuf = LiveGameChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint8(this.EGame.length);
		for (const EGameChild of this.EGame)
		{
			let tempBuf = EGameChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpClientConfig();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'LiveGame_size(B)='+this.LiveGame.length + ' ';
		str += 'LiveGame(CMDLiveGameConfig)=[';
		for (let LiveGameChild in this.LiveGame)
		{
			if (<number><unknown>LiveGameChild > 0) str += ', ';
			str += this.LiveGame[LiveGameChild].toString();
		}
		str += '] ';
		str += 'EGame_size(B)='+this.EGame.length + ' ';
		str += 'EGame(CMDEgameConfig)=[';
		for (let EGameChild in this.EGame)
		{
			if (<number><unknown>EGameChild > 0) str += ', ';
			str += this.EGame[EGameChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50073;
	}

	public getCmdName() : string
	{
		return 'SpClientConfig';
	}

}
