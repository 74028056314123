import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetBlackjack implements ICommand
{

	public Total : CMDTotalBet;
	public Seat1 : CMDBetSummary;
	public Seat2 : CMDBetSummary;
	public Seat3 : CMDBetSummary;
	public Seat4 : CMDBetSummary;
	public Seat5 : CMDBetSummary;
	public Seat6 : CMDBetSummary;
	public Seat7 : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Seat1 = new CMDBetSummary();
		this.Seat1.fromBytes(ba);
		this.Seat2 = new CMDBetSummary();
		this.Seat2.fromBytes(ba);
		this.Seat3 = new CMDBetSummary();
		this.Seat3.fromBytes(ba);
		this.Seat4 = new CMDBetSummary();
		this.Seat4.fromBytes(ba);
		this.Seat5 = new CMDBetSummary();
		this.Seat5.fromBytes(ba);
		this.Seat6 = new CMDBetSummary();
		this.Seat6.fromBytes(ba);
		this.Seat7 = new CMDBetSummary();
		this.Seat7.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Seat1.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Seat2.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Seat3.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Seat4.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Seat5.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Seat6.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Seat7.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetBlackjack();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Seat1(CMDBetSummary)='+this.Seat1+' ';
		str += 'Seat2(CMDBetSummary)='+this.Seat2+' ';
		str += 'Seat3(CMDBetSummary)='+this.Seat3+' ';
		str += 'Seat4(CMDBetSummary)='+this.Seat4+' ';
		str += 'Seat5(CMDBetSummary)='+this.Seat5+' ';
		str += 'Seat6(CMDBetSummary)='+this.Seat6+' ';
		str += 'Seat7(CMDBetSummary)='+this.Seat7+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30756;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetBlackjack';
	}

}
