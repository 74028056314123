import { BetGroup } from '@/models/host/BetAmount';
import { RootState } from '@/store/store';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { tableBetReducers } from './reducers';
export type TableBet = {
    HostId: number;
    GameId: number;
    BetSeqId: number;
    PlayerBets: Array<BetGroup>; //For ABC
    PendingBets: Array<number>; //For non-ABC
    ConfirmedBets: Array<number>;
    BetsSummary: Array<number>; //All bets per game id
    TableTotalBet: number; //For display
    PendingTotalBet: number; //amount not confirmed For check balance
    isWaitingBet: boolean; //For non-ABC
    minAmount?: number; //For ABC
    doConfirm?: boolean; //For non-ABC multibet
    doCancel?: boolean; //For non-ABC multibet
};
export const emptyTableBet = {
    GameId: 0,
    HostId: 0,
    BetSeqId: 0,
    PlayerBets: [],
    PendingBets: [],
    ConfirmedBets: [],
    BetsSummary: [],
    TableTotalBet: 0,
    PendingTotalBet: 0,
    isWaitingBet: false,
} as TableBet;

export const tableBetsAdapter = createEntityAdapter<TableBet, number>({
    selectId: host => host.HostId,
});
const initialTableBetState = tableBetsAdapter.getInitialState();
// slice

export const BET_TABLE_KEY = 'app::bet::table';
const tableBetSlice = createSlice({
    name: BET_TABLE_KEY,
    initialState: initialTableBetState,
    reducers: {
        setAll: tableBetsAdapter.setAll,
        update: tableBetsAdapter.upsertOne,
        ...tableBetReducers,
    },
});
export const tableBetSliceReducer = tableBetSlice.reducer;
export const tableBetSliceActions = tableBetSlice.actions;
// selector
export const getTableBetsSelectors = tableBetsAdapter.getSelectors<RootState>(
    state => state[BET_TABLE_KEY]
);
export const getTableBetByHostId = (state: RootState, hostId: number) =>
    getTableBetsSelectors.selectById(state, hostId) ?? emptyTableBet;
export const getTableBetByGameId = (state: RootState, gameId: number) =>
    getTableBetsSelectors.selectAll(state).find(h => h.GameId == gameId) ??
    emptyTableBet;
