import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsSetLimitRedToken implements ICommand
{

	public GameType : number = 0;
	public RuleID : number = 0;
	public Token1 : number = 0;
	public Token2 : number = 0;
	public Token3 : number = 0;
	public Token4 : number = 0;
	public Token5 : number = 0;
	public Token6 : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameType = ba.readInt53();
		this.RuleID = ba.readInt53();
		this.Token1 = ba.readInt53();
		this.Token2 = ba.readInt53();
		this.Token3 = ba.readInt53();
		this.Token4 = ba.readInt53();
		this.Token5 = ba.readInt53();
		this.Token6 = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameType);
		ba.writeUint53(this.RuleID);
		ba.writeUint53(this.Token1);
		ba.writeUint53(this.Token2);
		ba.writeUint53(this.Token3);
		ba.writeUint53(this.Token4);
		ba.writeUint53(this.Token5);
		ba.writeUint53(this.Token6);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsSetLimitRedToken();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameType(Q)='+this.GameType+' ';
		str += 'RuleID(Q)='+this.RuleID+' ';
		str += 'Token1(Q)='+this.Token1+' ';
		str += 'Token2(Q)='+this.Token2+' ';
		str += 'Token3(Q)='+this.Token3+' ';
		str += 'Token4(Q)='+this.Token4+' ';
		str += 'Token5(Q)='+this.Token5+' ';
		str += 'Token6(Q)='+this.Token6+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55064;
	}

	public getCmdName() : string
	{
		return 'PsSetLimitRedToken';
	}

}
