import { WebSocketContext } from '@/contexts/WebSocketContext';
import { CMDBet } from '@/models/cmd/live/CMDBet';
import { CMDPsBet } from '@/models/cmd/live/CMDPsBet';
import { CMDPsRoomSeat } from '@/models/cmd/live/CMDPsRoomSeat';
import { Bet } from '@/models/host/BetAmount';
import {
    getTableBetByHostId,
    tableBetSliceActions,
} from '@/modules/betTable/slice';
import { getGameState } from '@/modules/games/selector';
import { useContext, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AudioPlayContext } from '../contexts/AudioPlayContext';
import { BetChipContext } from '../contexts/BetChipContext';
import { StateMsgType } from '../models/Popup';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';
import {
    isOppositeBetting as andarbaharIsOppositeBetting,
    getAndarBaharCurrentTotal,
    getAndarBaharHedgeBettingBets,
    getShareLimitBetTypes as getAndarBaharShareLimitBetTypes,
} from '../modules/game/andarbahar/utils';
import {
    isOppositeBetting as baccaratIsOppositeBetting,
    getBaccaratCurrentTotal,
    getBaccaratHedgeBettingBets,
    getShareLimitBetTypes as getBaccaratShareLimitBetTypes,
} from '../modules/game/baccarat/utils';
import { isOppositeBetting as dragonTigerIsOppositeBetting } from '../modules/game/dragonTiger/utils';
import { isOppositeBetting as rouletteIsOppositeBetting } from '../modules/game/roulette/utils';
import {
    getSeDieCurrentTotal,
    getSeDieHedgeBettingBets,
    isOppositeBetting as sedieIsOppositeBetting,
} from '../modules/game/sedie/utils';
import { isOppositeBetting as sicboIsOppositeBetting } from '../modules/game/sicbo/utils';
import {
    getTeenPatti2020HedgeBettingBets,
    getTeenPattiCurrentTotal,
    isOppositeBetting as teenpattiIsOppositeBetting,
} from '../modules/game/teenpatti/utils';
import { getHostById } from '../modules/host/slice';
import { inGamePopupSliceActions } from '../modules/popup/inGameSlice';
import { RootState } from '../store/store';
import { checkBetsAvailed } from '../utils/commonFunc';
import { useBalance } from './useBalance';
import { useBetSource } from './useBetSource';
import { useGameFeature } from './useGameFeature';
import { useIsMultiBet } from './useIsMultiBet';
// import { useBetSource } from './useBetSource';

export const useNBSBet = (hostId: number) => {
    const isMultiBet = useIsMultiBet();
    const betSource = useBetSource(isMultiBet);
    const { sendCommand } = useContext(WebSocketContext);
    const { GameType, MaxBet } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const {
        GameId,
        BetsSummary,
        PendingBets,
        ConfirmedBets,
        // TableTotalBet,
        // PendingTotalBet,
        isWaitingBet,
        doConfirm,
        doCancel,
        PlayerBets,
        BetSeqId,
    } = useSelector((state: RootState) => getTableBetByHostId(state, hostId));
    const { balance } = useBalance();
    // const [unconfirmedTotalBet, setUnconfirmedTotalBet] = useState<number>(0);
    // const { BetFormat } = useSelector(getMainUserState);
    const { isHedgeBettingEnabled, isAutoConfirmEnabled } = useGameFeature(
        GameType === GameTypeEnum.Blackjack
    );
    const { totalHostsBetInfo } = useSelector(getGameState);
    const { selectedBetRule } = useContext(BetChipContext);
    const dispatch = useDispatch();
    const { playChipBetAudio } = useContext(AudioPlayContext);
    const { setPendingBetState } = useContext(BetChipContext);
    // useEffect(() => {
    //     if (isAutoConfirmEnabled) {
    //         setUnconfirmedTotalBet(TableTotalBet);
    //     } else {
    //         setUnconfirmedTotalBet(PendingTotalBet);
    //     }
    // }, [TableTotalBet, PendingTotalBet]);
    useEffect(() => {
        if (isAutoConfirmEnabled !== undefined) {
            //not ready
            if (isAutoConfirmEnabled === false) {
                //only non-ABC clear pending on init
                if (!isWaitingBet) {
                    dispatch(
                        tableBetSliceActions.clearPendingBets({
                            hostId: hostId,
                            gameId: GameId,
                            startGame: false,
                        })
                    );
                    setPendingBetState(hostId, false);
                }
            }
        }
    }, [isWaitingBet, isAutoConfirmEnabled]);
    useEffect(() => {
        if (doConfirm) {
            //Multibet
            const seatCMD = new CMDPsRoomSeat();
            seatCMD.HostID = hostId;
            seatCMD.Seat = 100; //stand
            seatCMD.IsMultiBet = 1;
            sendCommand(seatCMD);

            _doConfirmBet(GameId);
            dispatch(
                tableBetSliceActions.doConfirmBets({
                    hostId: hostId,
                    gameId: 0,
                    value: false,
                })
            );
        }
    }, [doConfirm]);
    useEffect(() => {
        if (doCancel) {
            _doCancelBet(GameId);
            dispatch(
                tableBetSliceActions.doCancelBets({
                    hostId: hostId,
                    gameId: 0,
                    value: false,
                })
            );
        }
    }, [doCancel]);

    const _doCancelBet = (gameId: number) => {
        dispatch(
            tableBetSliceActions.clearPendingBets({
                hostId: hostId,
                gameId: gameId,
                betSeqId: isAutoConfirmEnabled ? BetSeqId + 1 : undefined,
                startGame: false,
            })
        );
        setPendingBetState(hostId, false);
    };
    const _doConfirmBet = (gameId: number): boolean => {
        if (isAutoConfirmEnabled) return false;
        if (!selectedBetRule) return false;

        const cmd = new CMDPsBet();
        cmd.gameID = gameId;
        cmd.BetSource = betSource;
        let belowBetLimit = false;
        let isValidBet = false;
        for (let i = 0; i < PendingBets.length; i++) {
            if (PendingBets[i]) {
                let cmdBet = new CMDBet();
                cmdBet.betType = i;
                cmdBet.betAmount = PendingBets[i];
                if (cmdBet.betAmount < selectedBetRule.MinBet) {
                    belowBetLimit = true;
                    if (isMultiBet) {
                        belowBetLimit = false;
                    } else if (ConfirmedBets && ConfirmedBets[cmdBet.betType]) {
                        belowBetLimit = false;
                    }
                }
                cmd.bets.push(cmdBet);
            }
        }

        if (belowBetLimit) {
            // dispatch(
            //     inGamePopupSliceActions.open(
            //         'system.bet_below_win_bet',
            //         GameType,
            //         StateMsgType.betInfo_Fail,
            //         gameId
            //     )
            // );
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: hostId,
                        isOpen: true,
                    })
                );
                dispatch(
                    inGamePopupSliceActions.updateDataSet({
                        hostId: hostId,
                        key: 'system.bet_below_win_bet',
                        gameType: GameType,
                        stateMsgType: StateMsgType.betInfo_Fail,
                        gameId: gameId,
                    })
                );
            });
        } else {
            isValidBet = true;
            sendCommand(cmd);
            batch(() => {
                dispatch(
                    tableBetSliceActions.setWaitingBetResult({
                        hostId: hostId,
                        gameId: gameId,
                        value: true,
                    })
                );
                // dispatch(gameSliceActions.setWaitingBetResult(true));
            });
        }
        return isValidBet;
    };

    const _doUndoBet = (gameId: number) => {
        // if (!isAutoConfirmEnabled) return;
        const undo =
            PlayerBets && PlayerBets.length > 0
                ? PlayerBets[PlayerBets.length - 1].bets
                : undefined;

        if (undo) {
            dispatch(
                tableBetSliceActions.undoBet({
                    hostId: hostId,
                    gameId: gameId,
                    bets: undo,
                })
            );
        }
    };
    const _doPendingBet = (bet: Bet) => {
        if (bet && !isHedgeBettingEnabled && isOppositeBetting(bet.Type)) {
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: hostId,
                        isOpen: true,
                    })
                );
                dispatch(
                    inGamePopupSliceActions.updateDataSet({
                        hostId: hostId,
                        key: 'system.bet_opposite',
                        gameType: GameType,
                        stateMsgType: StateMsgType.betInfo_Fail,
                        gameId: bet.GameId,
                    })
                );
            });
            return;
        }

        let unconfirmedTotalBet = 0;
        let unconfirmedTotalBetWithHold = 0;
        if (totalHostsBetInfo) {
            if (isAutoConfirmEnabled) {
                unconfirmedTotalBet = totalHostsBetInfo.totalBetAmount;
                unconfirmedTotalBetWithHold =
                    totalHostsBetInfo.totalWithHoldAmount;
            } else {
                unconfirmedTotalBet = totalHostsBetInfo.pendingBetAmount;
                unconfirmedTotalBetWithHold =
                    totalHostsBetInfo.pendingWithHoldAmount;
            }
        }
        const availableBets = checkBetsAvailed({
            hostId: hostId,
            gameType: GameType,
            bets: [bet],
            amountOverBetLimit: amountOverBetLimit,
            totalPendingBetAmount: unconfirmedTotalBet,
            totalPendingWithHoldAmount: unconfirmedTotalBetWithHold,
            availableBalance: balance,
        });

        if (availableBets.isNotEnoughMoney) {
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: hostId,
                        isOpen: true,
                    })
                );
                dispatch(
                    inGamePopupSliceActions.updateDataSet({
                        hostId: hostId,
                        key: 'system.not_enough_money',
                        gameType: GameType,
                        stateMsgType: StateMsgType.betInfo_Fail,
                        gameId: bet.GameId,
                    })
                );
            });
        } else if (availableBets.isOutOfBetLimit) {
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: hostId,
                        isOpen: true,
                    })
                );
                dispatch(
                    inGamePopupSliceActions.updateDataSet({
                        hostId: hostId,
                        key: 'system.bet_out_of_limit_red',
                        gameType: GameType,
                        stateMsgType: StateMsgType.betInfo_Fail,
                        gameId: bet.GameId,
                    })
                );
            });
        } else {
            if (availableBets.haveDisabledBetType) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.have_cannot_rebet_zone',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Warning,
                            gameId: bet.GameId,
                        })
                    );
                });
            } else if (
                availableBets.haveBetLimitAllIn &&
                !availableBets.isAllIn
            ) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.bet_exceed_limit_allin',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Warning,
                            gameId: bet.GameId,
                        })
                    );
                });
            } else if (availableBets.isAllIn) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.all_in',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Warning,
                            gameId: bet.GameId,
                        })
                    );
                });
            }
            // if (isMultiBet) {
            //     setCurrentTable(hostId);
            // }
            batch(() => {
                // dispatch(gameSliceActions.onPendingBet(availableBets.bets[0]));

                dispatch(
                    tableBetSliceActions.addBet({
                        hostId: hostId,
                        gameId: bet.GameId,
                        bets: availableBets.bets,
                    })
                );
                setPendingBetState(hostId, true);
                playChipBetAudio();
            });
            if (isAutoConfirmEnabled) {
                //TODO
            }
        }
    };

    const getShareLimitBetTypesByGameType = (betType: number): number[] => {
        switch (GameType) {
            case GameTypeEnum.Baccarat:
                return getBaccaratShareLimitBetTypes(betType);
            case GameTypeEnum.AndarBahar:
                return getAndarBaharShareLimitBetTypes(betType);
        }
        return [betType];
    };

    const getHedgeBettingBets = (bets: Array<Bet>) => {
        const newBets = new Array<Bet>();
        switch (GameType) {
            case GameTypeEnum.Baccarat:
                return getBaccaratHedgeBettingBets(bets);
            case GameTypeEnum.SeDie:
                return getSeDieHedgeBettingBets(bets);
            case GameTypeEnum.AndarBahar:
                return getAndarBaharHedgeBettingBets(bets);
            case GameTypeEnum.TeenPatti2020:
                return getTeenPatti2020HedgeBettingBets(bets);
            default:
                newBets.push(...bets);
        }
        return newBets;
    };

    const getCurrentTotal = (betType: number): number => {
        const finalBet = isAutoConfirmEnabled ? [] : ConfirmedBets;
        switch (GameType) {
            case GameTypeEnum.Baccarat:
                return getBaccaratCurrentTotal(betType, BetsSummary, finalBet);
            case GameTypeEnum.AndarBahar:
                return getAndarBaharCurrentTotal(
                    betType,
                    BetsSummary,
                    finalBet
                );
            case GameTypeEnum.SeDie:
                return getSeDieCurrentTotal(betType, BetsSummary, finalBet);
            case GameTypeEnum.TeenPatti2020:
                return getTeenPattiCurrentTotal(betType, BetsSummary, finalBet);
        }
        let amount = 0;
        const matchBetTypes = getShareLimitBetTypesByGameType(betType);
        for (let i = 0; i < matchBetTypes.length; i++) {
            if (BetsSummary[matchBetTypes[i]]) {
                amount += BetsSummary[matchBetTypes[i]];
            }
            if (finalBet[matchBetTypes[i]]) {
                amount += finalBet[matchBetTypes[i]];
            }
        }
        return amount;
    };

    const amountOverBetLimit = (
        betType: number,
        chipAmount: number
    ): number => {
        const current = getCurrentTotal(betType); // BetsSummary[betType] ? BetsSummary[betType] : 0;
        const maxBet = MaxBet?.find(
            mb => Number(mb.BetType) === Number(betType)
        );
        switch (GameType) {
            // case GameTypeEnum.Baccarat:
            // case GameTypeEnum.AndarBahar:
            //     //TODO
            //     return chipAmount + current;
            default:
                if (maxBet) {
                    return chipAmount + current - maxBet.MaxBet;
                } else {
                    return chipAmount + current;
                }
        }
    };

    const isOppositeBetting = (betType: number): boolean => {
        // TODO: add other game checking
        switch (GameType) {
            case GameTypeEnum.Baccarat:
                return baccaratIsOppositeBetting(betType, BetsSummary);
            case GameTypeEnum.SeDie:
                return sedieIsOppositeBetting(betType, BetsSummary);
            case GameTypeEnum.SicBo:
                return sicboIsOppositeBetting(betType, BetsSummary);
            case GameTypeEnum.Dragon:
                return dragonTigerIsOppositeBetting(betType, BetsSummary);
            case GameTypeEnum.TeenPatti2020:
                return teenpattiIsOppositeBetting(betType, BetsSummary);
            case GameTypeEnum.AndarBahar:
                return andarbaharIsOppositeBetting(betType, BetsSummary);
            case GameTypeEnum.Roulette:
                return rouletteIsOppositeBetting(betType, BetsSummary);
            default:
                return false;
        }
    };

    return {
        GameType,
        MaxBet,
        ConfirmedBets,
        isHedgeBettingEnabled,
        isAutoConfirmEnabled,
        _doPendingBet,
        _doConfirmBet,
        _doUndoBet,
        _doCancelBet,
        amountOverBetLimit,
        getHedgeBettingBets,
    };
};
