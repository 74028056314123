import { useMemo } from 'react';
import { UAParser } from 'ua-parser-js';
export const useUserAgent = () => {
    const ua = useMemo(() => {
        const parser = new UAParser(window.navigator.userAgent);
        const result = parser.getResult();
        return result;
    }, [window.navigator.userAgent]);
    return ua;
};
