export type BetResult = {
    //Banker
    BJR_BankerPoint: number; //22 = Bush, 255 = Blackjack
    //Player1
    BJR1_S1: number; //BJSPlayer
    BJR1_S1_BJ: boolean;
    BJR1_S1_Refund: boolean;
    BJR1_S2: number;
    BJR1_S2_BJ: boolean;
    BJR1_S2_Refund: boolean;
    //Player2
    BJR2_S1: number;
    BJR2_S1_BJ: boolean;
    BJR2_S1_Refund: boolean;
    BJR2_S2: number;
    BJR2_S2_BJ: boolean;
    BJR2_S2_Refund: boolean;
    //Player3
    BJR3_S1: number;
    BJR3_S1_BJ: boolean;
    BJR3_S1_Refund: boolean;
    BJR3_S2: number;
    BJR3_S2_BJ: boolean;
    BJR3_S2_Refund: boolean;
    //Player4
    BJR4_S1: number;
    BJR4_S1_BJ: boolean;
    BJR4_S1_Refund: boolean;
    BJR4_S2: number;
    BJR4_S2_BJ: boolean;
    BJR4_S2_Refund: boolean;
    //Player5
    BJR5_S1: number;
    BJR5_S1_BJ: boolean;
    BJR5_S1_Refund: boolean;
    BJR5_S2: number;
    BJR5_S2_BJ: boolean;
    BJR5_S2_Refund: boolean;
    //Player6
    BJR6_S1: number;
    BJR6_S1_BJ: boolean;
    BJR6_S1_Refund: boolean;
    BJR6_S2: number;
    BJR6_S2_BJ: boolean;
    BJR6_S2_Refund: boolean;
    //Player7
    BJR7_S1: number;
    BJR7_S1_BJ: boolean;
    BJR7_S1_Refund: boolean;
    BJR7_S2: number;
    BJR7_S2_BJ: boolean;
    BJR7_S2_Refund: boolean;
};
export type BetResultKey = keyof BetResult;
export const createBetResult = (): BetResult => ({
    //Banker
    BJR_BankerPoint: 0, //22 : Bush, 255 : Blackjack
    //Player1
    BJR1_S1: 0, //BJSPlayer
    BJR1_S1_BJ: false,
    BJR1_S1_Refund: false,
    BJR1_S2: 0,
    BJR1_S2_BJ: false,
    BJR1_S2_Refund: false,
    //Player2
    BJR2_S1: 0,
    BJR2_S1_BJ: false,
    BJR2_S1_Refund: false,
    BJR2_S2: 0,
    BJR2_S2_BJ: false,
    BJR2_S2_Refund: false,
    //Player3
    BJR3_S1: 0,
    BJR3_S1_BJ: false,
    BJR3_S1_Refund: false,
    BJR3_S2: 0,
    BJR3_S2_BJ: false,
    BJR3_S2_Refund: false,
    //Player4
    BJR4_S1: 0,
    BJR4_S1_BJ: false,
    BJR4_S1_Refund: false,
    BJR4_S2: 0,
    BJR4_S2_BJ: false,
    BJR4_S2_Refund: false,
    //Player5
    BJR5_S1: 0,
    BJR5_S1_BJ: false,
    BJR5_S1_Refund: false,
    BJR5_S2: 0,
    BJR5_S2_BJ: false,
    BJR5_S2_Refund: false,
    //Player6
    BJR6_S1: 0,
    BJR6_S1_BJ: false,
    BJR6_S1_Refund: false,
    BJR6_S2: 0,
    BJR6_S2_BJ: false,
    BJR6_S2_Refund: false,
    //Player7
    BJR7_S1: 0,
    BJR7_S1_BJ: false,
    BJR7_S1_Refund: false,
    BJR7_S2: 0,
    BJR7_S2_BJ: false,
    BJR7_S2_Refund: false,
});
