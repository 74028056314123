import { DragonTigerRecord } from '../../../models/games/dragonTiger';
import { SupportedRecord } from '../../host/models';

export const getDTSummaryList = (records: SupportedRecord[] | undefined) => {
    let tigerTotal = 0;
    let dragonTotal = 0;
    let tieTotal = 0;

    records &&
        records.forEach(record => {
            const r = record as DragonTigerRecord;
            if (r.tigerWin) tigerTotal++;
            if (r.dragonWin) dragonTotal++;
            if (r.tie) tieTotal++;
        });

    return {
        tigerTotal,
        dragonTotal,
        tieTotal,
    };
};
