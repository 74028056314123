import { SVGProps } from 'react';

export const TickButton = (props: SVGProps<SVGSVGElement>) => {
    const tickButtonId = Math.floor(Math.random() * 99999999);
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 50.5C0 22.8858 22.3858 0.5 50 0.5C77.6142 0.5 100 22.8858 100 50.5C100 78.1142 77.6142 100.5 50 100.5C22.3858 100.5 0 78.1142 0 50.5Z"
                fill={`url(#${tickButtonId}paint0_linear_6524_250028)`}
                fillOpacity="0.85"
            />
            <path
                d="M1 50.5C1 23.438 22.938 1.5 50 1.5C77.062 1.5 99 23.438 99 50.5C99 77.562 77.062 99.5 50 99.5C22.938 99.5 1 77.562 1 50.5Z"
                stroke={`url(#${tickButtonId}paint1_linear_6524_250028)`}
                strokeOpacity="0.6"
                strokeWidth="2"
            />
            <path
                d="M68.0287 32.6957L42.1537 59.3624L30.3412 47.4041C29.1328 46.1749 27.1537 46.1541 25.9245 47.3832C24.6953 48.5916 24.6953 50.5707 25.9037 51.7999L42.2162 68.2999L72.5078 37.0499C73.7162 35.8207 73.6745 33.8416 72.4453 32.6332C71.2162 31.4249 69.237 31.4666 68.0287 32.6957Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id={`${tickButtonId}paint0_linear_6524_250028`}
                    x1="0"
                    y1="0.5"
                    x2="100"
                    y2="100.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#12110F" stopOpacity="0.2" />
                </linearGradient>
                <linearGradient
                    id={`${tickButtonId}paint1_linear_6524_250028`}
                    x1="50"
                    y1="0.5"
                    x2="50"
                    y2="100.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                </linearGradient>
            </defs>
        </svg>
    );
};
