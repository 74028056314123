import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetLogSummary implements ICommand
{

	public ReportDate : string = "";
	public ResultAmount : number = 0;
	public RollingAmount : number = 0;
	public TurnOverAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ReportDate = ba.readString();
		this.ResultAmount = ba.readInt53();
		this.RollingAmount = ba.readInt53();
		this.TurnOverAmount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.ReportDate);
		ba.writeUint53(this.ResultAmount);
		ba.writeUint53(this.RollingAmount);
		ba.writeUint53(this.TurnOverAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetLogSummary();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ReportDate(S)='+this.ReportDate+' ';
		str += 'ResultAmount(Q)='+this.ResultAmount+' ';
		str += 'RollingAmount(Q)='+this.RollingAmount+' ';
		str += 'TurnOverAmount(Q)='+this.TurnOverAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetLogSummary';
	}

}
