import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLivePlayerCount implements ICommand
{

	public PlayerCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerCount = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.PlayerCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLivePlayerCount();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerCount(D)='+this.PlayerCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50086;
	}

	public getCmdName() : string
	{
		return 'SpLivePlayerCount';
	}

}
