import { CMDLotteryResult } from "./CMDLotteryResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLotteryOldResult implements ICommand
{

	public Result : Array<CMDLotteryResult>;

	public constructor()
	{
		this.Result = new Array<CMDLotteryResult>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let ResultSize:number = ba.readUint8();
		while (ResultSize-- > 0)
		{
			let ResultChild:CMDLotteryResult = new CMDLotteryResult();
			ResultChild.fromBytes(ba);
			this.Result.push(ResultChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Result.length);
		for (const ResultChild of this.Result)
		{
			let tempBuf = ResultChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLotteryOldResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Result_size(B)='+this.Result.length + ' ';
		str += 'Result(CMDLotteryResult)=[';
		for (let ResultChild in this.Result)
		{
			if (<number><unknown>ResultChild > 0) str += ', ';
			str += this.Result[ResultChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50034;
	}

	public getCmdName() : string
	{
		return 'SpLotteryOldResult';
	}

}
