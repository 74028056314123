import { styled } from '@mui/material';
import IconButton, {
    IconButtonProps,
} from '@mui/material/IconButton/IconButton';
import { useEffect, useState } from 'react';

const StyledButtonBase = styled(IconButton)({
    padding: '0px',
});
export type StyledButtonProps = IconButtonProps;
export const SvgButton = (props: StyledButtonProps) => {
    const { children } = props;
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        setDisplay(<StyledButtonBase {...props}>{children}</StyledButtonBase>);
    }, [children]);
    return display;
};
