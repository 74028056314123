import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDHostWithSetting implements ICommand
{

	public HostID : number = 0;
	public Setting : number = 0;
	public GameType : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Setting = ba.readUint16();
		this.GameType = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint16(this.Setting);
		ba.writeUint53(this.GameType);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDHostWithSetting();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Setting(W)='+this.Setting+' ';
		str += 'GameType(Q)='+this.GameType+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'HostWithSetting';
	}

}
