// which page
export enum GA_EVENT_LABEL {
    LOBBY_PAGE = 'lobby_page',
    FAVORITE_PAGE = 'favorite_page',
    MULTIBET_PAGE = 'multibet_page',
    FEATURED_PAGE = 'featured_page',
    SETTING = 'setting_page',
    UNKNOWN_PAGE = 'unknown_page',
}
export enum GA_EVENT_CATEGORY {
    ENGAGEMENT = 'engagement',
}
export enum GA_LOBBY_EVENT_NAME {
    LIST_MODE = 'list_mode',
    GRID_MODE = 'grid_mode',
    MINI_MODE = 'mini_mode',
    FEATURED = 'featured',
    FAVORITE = 'favorite',
    LIVE_GAMES = 'live_games',
    MULTI_BET = 'multi_bet',
    ADD_TO_FAV = 'add_to_fav',
    REMOVE_FROM_FAV = 'remove_from_fav',
    REFRESH_BAL = 'refresh_bal',
    MENU = 'menu',
    EXPAND_CATEGORY = 'expand_category',
    BACK_TO_CLASSIC = 'back_to_classic',
}
export enum GA_SETTING_EVENT_NAME {
    VIDEO_ON = 'video_on',
    VIDEO_OFF = 'video_off',
    BACK_TO_PRIOR = 'back_to_prior',
    LITE_ON = 'lite_on',
    LITE_OFF = 'lite_off',
    BET_AREA_UP = 'bet_area_up',
    BET_AREA_DOWN = 'bet_area_down',
    EXPLORE_REWARDS = 'explore_rewards',
}
