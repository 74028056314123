import { CMDField } from "./CMDField"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScSyncData implements ICommand
{

	public PlayerID : number = 0;
	public Field : Array<CMDField>;

	public constructor()
	{
		this.Field = new Array<CMDField>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerID = ba.readInt53();
		let FieldSize:number = ba.readUint16();
		while (FieldSize-- > 0)
		{
			let FieldChild:CMDField = new CMDField();
			FieldChild.fromBytes(ba);
			this.Field.push(FieldChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.PlayerID);
		ba.writeUint16(this.Field.length);
		for (const FieldChild of this.Field)
		{
			let tempBuf = FieldChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScSyncData();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'Field_size(W)='+this.Field.length + ' ';
		str += 'Field(CMDField)=[';
		for (let FieldChild in this.Field)
		{
			if (<number><unknown>FieldChild > 0) str += ', ';
			str += this.Field[FieldChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30008;
	}

	public getCmdName() : string
	{
		return 'ScSyncData';
	}

}
