import { CMDBetLog } from "./CMDBetLog"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBetLogQuery implements ICommand
{

	public Bet : Array<CMDBetLog>;

	public constructor()
	{
		this.Bet = new Array<CMDBetLog>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let BetSize:number = ba.readUint32();
		while (BetSize-- > 0)
		{
			let BetChild:CMDBetLog = new CMDBetLog();
			BetChild.fromBytes(ba);
			this.Bet.push(BetChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.Bet.length);
		for (const BetChild of this.Bet)
		{
			let tempBuf = BetChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBetLogQuery();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Bet_size(D)='+this.Bet.length + ' ';
		str += 'Bet(CMDBetLog)=[';
		for (let BetChild in this.Bet)
		{
			if (<number><unknown>BetChild > 0) str += ', ';
			str += this.Bet[BetChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50010;
	}

	public getCmdName() : string
	{
		return 'SpBetLogQuery';
	}

}
