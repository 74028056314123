import { CMDBetTypeMaxBet } from "./CMDBetTypeMaxBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpInitMaxBet implements ICommand
{

	public GameType : number = 0;
	public MaxBetList : Array<CMDBetTypeMaxBet>;

	public constructor()
	{
		this.MaxBetList = new Array<CMDBetTypeMaxBet>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameType = ba.readUint32();
		let MaxBetListSize:number = ba.readUint8();
		while (MaxBetListSize-- > 0)
		{
			let MaxBetListChild:CMDBetTypeMaxBet = new CMDBetTypeMaxBet();
			MaxBetListChild.fromBytes(ba);
			this.MaxBetList.push(MaxBetListChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.GameType);
		ba.writeUint8(this.MaxBetList.length);
		for (const MaxBetListChild of this.MaxBetList)
		{
			let tempBuf = MaxBetListChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpInitMaxBet();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameType(D)='+this.GameType+' ';
		str += 'MaxBetList_size(B)='+this.MaxBetList.length + ' ';
		str += 'MaxBetList(CMDBetTypeMaxBet)=[';
		for (let MaxBetListChild in this.MaxBetList)
		{
			if (<number><unknown>MaxBetListChild > 0) str += ', ';
			str += this.MaxBetList[MaxBetListChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50020;
	}

	public getCmdName() : string
	{
		return 'SpInitMaxBet';
	}

}
