import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetFantan implements ICommand
{

	public Total : CMDTotalBet;
	public Even : CMDBetSummary;
	public Odd : CMDBetSummary;
	public Fan : CMDBetSummary;
	public Nim : CMDBetSummary;
	public Tong : CMDBetSummary;
	public Chun : CMDBetSummary;
	public Zheng : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Even = new CMDBetSummary();
		this.Even.fromBytes(ba);
		this.Odd = new CMDBetSummary();
		this.Odd.fromBytes(ba);
		this.Fan = new CMDBetSummary();
		this.Fan.fromBytes(ba);
		this.Nim = new CMDBetSummary();
		this.Nim.fromBytes(ba);
		this.Tong = new CMDBetSummary();
		this.Tong.fromBytes(ba);
		this.Chun = new CMDBetSummary();
		this.Chun.fromBytes(ba);
		this.Zheng = new CMDBetSummary();
		this.Zheng.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Even.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Odd.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Fan.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Nim.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Tong.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Chun.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Zheng.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetFantan();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Even(CMDBetSummary)='+this.Even+' ';
		str += 'Odd(CMDBetSummary)='+this.Odd+' ';
		str += 'Fan(CMDBetSummary)='+this.Fan+' ';
		str += 'Nim(CMDBetSummary)='+this.Nim+' ';
		str += 'Tong(CMDBetSummary)='+this.Tong+' ';
		str += 'Chun(CMDBetSummary)='+this.Chun+' ';
		str += 'Zheng(CMDBetSummary)='+this.Zheng+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30754;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetFantan';
	}

}
