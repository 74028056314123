import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDMoneywheelStatistics implements ICommand
{

	public Main1Count : number = 0;
	public Main2Count : number = 0;
	public Main3Count : number = 0;
	public Main4Count : number = 0;
	public Main5Count : number = 0;
	public Main6Count : number = 0;
	public Main7Count : number = 0;
	public Side1Count : number = 0;
	public Side2Count : number = 0;
	public Side3Count : number = 0;
	public Side4Count : number = 0;
	public Side5Count : number = 0;
	public Side6Count : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Main1Count = ba.readUint8();
		this.Main2Count = ba.readUint8();
		this.Main3Count = ba.readUint8();
		this.Main4Count = ba.readUint8();
		this.Main5Count = ba.readUint8();
		this.Main6Count = ba.readUint8();
		this.Main7Count = ba.readUint8();
		this.Side1Count = ba.readUint8();
		this.Side2Count = ba.readUint8();
		this.Side3Count = ba.readUint8();
		this.Side4Count = ba.readUint8();
		this.Side5Count = ba.readUint8();
		this.Side6Count = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Main1Count);
		ba.writeUint8(this.Main2Count);
		ba.writeUint8(this.Main3Count);
		ba.writeUint8(this.Main4Count);
		ba.writeUint8(this.Main5Count);
		ba.writeUint8(this.Main6Count);
		ba.writeUint8(this.Main7Count);
		ba.writeUint8(this.Side1Count);
		ba.writeUint8(this.Side2Count);
		ba.writeUint8(this.Side3Count);
		ba.writeUint8(this.Side4Count);
		ba.writeUint8(this.Side5Count);
		ba.writeUint8(this.Side6Count);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDMoneywheelStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Main1Count(B)='+this.Main1Count+' ';
		str += 'Main2Count(B)='+this.Main2Count+' ';
		str += 'Main3Count(B)='+this.Main3Count+' ';
		str += 'Main4Count(B)='+this.Main4Count+' ';
		str += 'Main5Count(B)='+this.Main5Count+' ';
		str += 'Main6Count(B)='+this.Main6Count+' ';
		str += 'Main7Count(B)='+this.Main7Count+' ';
		str += 'Side1Count(B)='+this.Side1Count+' ';
		str += 'Side2Count(B)='+this.Side2Count+' ';
		str += 'Side3Count(B)='+this.Side3Count+' ';
		str += 'Side4Count(B)='+this.Side4Count+' ';
		str += 'Side5Count(B)='+this.Side5Count+' ';
		str += 'Side6Count(B)='+this.Side6Count+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'MoneywheelStatistics';
	}

}
