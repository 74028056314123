import { betGameSliceActions } from '@/modules/bet/game/slice';
import {
    getTableBetByGameId,
    tableBetSliceActions,
} from '@/modules/betTable/slice';
import { batch } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import {
    betRoomSliceActions,
    getBetByHostPlayer,
    getBetRoom,
} from '../../../modules/bet/room/slice';
import { getHostByGameId } from '../../../modules/host/slice';
import { getMainUserState } from '../../../modules/main/selector';
import store from '../../../store/store';
import { BetPayload, BetRoomSeatIndex } from '../../games/BetRoom';
import { GameType } from '../../games/enums/GameType';
import { Bet } from '../../host/BetAmount';
import { CMDScPlayerBet, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScPlayerBetHandler extends AbstractHandler {
    _command: CMDScPlayerBet;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScPlayerBet;
    }
    handle(dispatch: Dispatch<Action>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        const { PlayerID } = getMainUserState(state);
        if (host && host.GameType === GameType.Blackjack) {
            const playerId = this._command.PlayerID;
            const seat = (this._command.Seat % 100) as BetRoomSeatIndex;
            const hostId = host.HostId;
            if (!seat && playerId !== PlayerID) {
                return;
            }

            let bets = new Array<Bet>();
            if (seat) {
                const currentBets = getBetByHostPlayer(state, hostId, seat);
                bets = bets.concat(currentBets);
            } else if (seat === 0 && playerId === PlayerID) {
                const currentBets = getBetRoom(state, hostId)?.Info[0].Bet;
                if (currentBets) bets = bets.concat(currentBets);
            }
            const newBets = this._command.Bet.map(
                b =>
                    ({
                        Type: b.betType,
                        Amount: b.betAmount,
                    }) as Bet
            );
            bets = bets.concat(newBets);
            const payload: BetPayload = {
                HostId: hostId,
                Seat: this._command.Seat,
                PlayerId: playerId,
                Bets: bets,
            };
            batch(() => {
                dispatch(betRoomSliceActions.bet(payload));
                // skip bet behind
                if (this._command.Seat !== 100)
                    dispatch(
                        betGameSliceActions.addBetGame({
                            id: this._command.GameID,
                            bet: payload,
                        })
                    );
            });

            if (playerId === PlayerID) {
                const { ConfirmedBets } = getTableBetByGameId(
                    state,
                    this._command.GameID
                );
                let newConfirmedBets = [...ConfirmedBets];
                bets.forEach(bet => {
                    newConfirmedBets[bet.Type] = bet.Amount;
                });
                batch(() => {
                    // dispatch(
                    //     gameSliceActions.onConfirmedBetResult({
                    //         gameId: this._command.GameID,
                    //         bet: bets,
                    //     })
                    // );
                    dispatch(
                        tableBetSliceActions.updateConfirmedBets({
                            hostId: hostId,
                            gameId: this._command.GameID,
                            bets: newConfirmedBets,
                        })
                    );
                });
            }
        }
    }
}
