import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPvpGame implements ICommand
{

	public PvPGameID : Array<number>;

	public constructor()
	{
		this.PvPGameID = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let PvPGameIDSize:number = ba.readUint8();
		while (PvPGameIDSize-- > 0)
		{
			let PvPGameIDChild:number = ba.readInt53();
			this.PvPGameID.push(PvPGameIDChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.PvPGameID.length);
		for (const PvPGameIDChild of this.PvPGameID)
		{
			ba.writeUint53(PvPGameIDChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPvpGame();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PvPGameID_size(B)='+this.PvPGameID.length + ' ';
		str += 'PvPGameID(Q)=[';
		for (let PvPGameIDChild in this.PvPGameID)
		{
			if (<number><unknown>PvPGameIDChild > 0) str += ', ';
			str += this.PvPGameID[PvPGameIDChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50065;
	}

	public getCmdName() : string
	{
		return 'SpPvpGame';
	}

}
