import { combineReducers } from 'redux';
import { BET_RECORD_KEY, betRecordSliceReducer } from './betRecord';
import { BET_STATISTICS_KEY, betStatisticsSliceReducer } from './betStatistics';
import { BET_SUMMARY_KEY, betSummarySliceReducer } from './betSummary';
const reportReducer = combineReducers({
    [BET_SUMMARY_KEY]: betSummarySliceReducer,
    [BET_RECORD_KEY]: betRecordSliceReducer,
    [BET_STATISTICS_KEY]: betStatisticsSliceReducer,
});
export default reportReducer;
export type ReportState = ReturnType<typeof reportReducer>;
export const REPORT_KEY = 'app::report';
