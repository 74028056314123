import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { WebSocketContext } from './contexts/WebSocketContext';
import { ROUTE_ROOT } from './models/Route';
type ProtectedRouteProps = {
    children: JSX.Element | Array<JSX.Element>;
};
export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const {
        hasLogin: { current },
    } = useContext(WebSocketContext);
    if (!current) return <Navigate to={ROUTE_ROOT} />;
    return children;
};
