import { AnyAction, Dispatch } from 'redux';
import {
    betRoomSliceActions,
    getBetRoom,
} from '../../../modules/bet/room/slice';
import store from '../../../store/store';
import {
    BetRoomSeatIndex,
    SeatPayload,
    StandPayload,
} from '../../games/BetRoom';
import { CMDScRoomSeat, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScRoomSeatHandler extends AbstractHandler {
    _command: CMDScRoomSeat;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScRoomSeat;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const hostId = this._command.HostID;
        if (this._command.Seat % 100 === 0) {
            const state = store.getState();
            const betRoom = getBetRoom(state, hostId);
            if (betRoom) {
                for (let i = 1; i <= 7; i++) {
                    const s = i as BetRoomSeatIndex;
                    const playerId = betRoom.Info[s].PlayerId;
                    if (playerId === this._command.PlayerID) {
                        const payload: StandPayload = {
                            HostId: hostId,
                            Seat: s,
                        };
                        dispatch(betRoomSliceActions.stand(payload));
                    }
                }
            }
        } else {
            const payload: SeatPayload = {
                HostId: hostId,
                Seat: this._command.Seat,
                PlayerId: this._command.PlayerID,
            };
            dispatch(betRoomSliceActions.seat(payload));
        }
    }
}
