import BScroll from '@better-scroll/core';
import MouseWheel from '@better-scroll/mouse-wheel';
import PullDown from '@better-scroll/pull-down';
import { useWindowSize } from '@react-hook/window-size';
import moment from 'moment';
import { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { ProtectedRoute } from './ProtectedRoute';
import { EnterCountProvider } from './contexts/EnterCountContext';
import { EnterGameProvider } from './contexts/EnterGameContext';
import { GameInfoButtonControllerProvider } from './contexts/GameInfoButtonControllerContext';
import { useIframeMessage } from './hooks/useIframeMessage';
import { useLogin } from './hooks/useLogin';
import { useStartTime } from './hooks/useStartTime';
import { LVT_KEY, useVersion } from './hooks/useVersion';
import {
    ROUTE_FAVORITE,
    ROUTE_FEATURED,
    ROUTE_GAME,
    ROUTE_LOBBY,
    ROUTE_MULTIBET,
    ROUTE_RELOAD_LOBBY,
    ROUTE_ROOT,
    ROUTE_THANKS4PLAY,
} from './models/Route';
import { Landing } from './modules/landing';
import { Lobbyw } from './modules/lobbyw';
import { Popup } from './modules/popup';
import ReconnectLayer from './modules/reconnectLayer';
import { RouteController } from './modules/routeController';
const PromotionDialog = lazy(() =>
    import('./modules/popup/promotiontool/components/PromotionDialog').then(
        ({ PromotionDialog }) => ({
            default: PromotionDialog,
        })
    )
);
const GameScene = lazy(() =>
    import('./modules/game').then(({ GameScene }) => ({
        default: GameScene,
    }))
);
const ThanksForPlaying = lazy(() =>
    import('./modules/thanksForPlaying').then(({ ThanksForPlaying }) => ({
        default: ThanksForPlaying,
    }))
);
const Lobby = lazy(() =>
    import('./modules/lobby').then(({ Lobby }) => ({
        default: Lobby,
    }))
);
const Featured = lazy(() =>
    import('./modules/featured').then(({ FeaturedGames }) => ({
        default: FeaturedGames,
    }))
);
const MultiBetScene = lazy(() =>
    import('./modules/multibet').then(({ MultiBetScene }) => ({
        default: MultiBetScene,
    }))
);
const Favorite = lazy(() =>
    import('./modules/favorite').then(({ Favorite }) => ({
        default: Favorite,
    }))
);
const App = () => {
    useStartTime();
    useLogin();
    useVersion();
    useIframeMessage();
    const { i18n } = useTranslation();
    // For Right to Left layout (e.g.Arabic)
    useEffect(() => {
        // document.body.dir = i18n.dir();
        document.documentElement.style.setProperty(
            '--text-direction',
            `${i18n.dir()}`
        );
    }, [i18n, i18n.language]);
    useEffect(() => {
        const current = moment().valueOf().toString();
        window.localStorage.setItem(LVT_KEY, current);

        BScroll.use(MouseWheel);
        BScroll.use(PullDown);
    }, []);

    const [width, height] = useWindowSize();
    useEffect(() => {
        let vh = height * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vhNumber', `${vh}`);
    }, [height]);
    useEffect(() => {
        let vw = width * 0.01;
        if (width > 3840) {
            vw = 38.4;
        }
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    }, [width]);

    return (
        <EnterCountProvider>
            <BrowserRouter>
                <EnterGameProvider>
                    <GameInfoButtonControllerProvider>
                        <Routes>
                            <Route
                                index
                                path={ROUTE_ROOT}
                                element={<Landing />}
                            />
                            <Route
                                path={ROUTE_THANKS4PLAY}
                                element={<ThanksForPlaying />}
                            />
                            <Route
                                path={ROUTE_LOBBY}
                                element={
                                    <ProtectedRoute>
                                        <Lobby />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ROUTE_FEATURED}
                                element={
                                    <ProtectedRoute>
                                        <Featured />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ROUTE_GAME + '/:hostId'}
                                element={
                                    <ProtectedRoute>
                                        <GameScene />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ROUTE_MULTIBET}
                                element={
                                    <ProtectedRoute>
                                        <MultiBetScene />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ROUTE_RELOAD_LOBBY}
                                element={
                                    <ProtectedRoute>
                                        <Lobbyw />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ROUTE_FAVORITE}
                                element={
                                    <ProtectedRoute>
                                        <Favorite />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                        <PromotionDialog />
                        <Popup />
                        {/* <NewUIPopup /> */}
                        <RouteController />
                        <ReconnectLayer />
                    </GameInfoButtonControllerProvider>
                </EnterGameProvider>
            </BrowserRouter>
        </EnterCountProvider>
    );
};

export default App;
