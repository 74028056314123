import { CMDBet } from "./CMDBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScPlayerBet implements ICommand
{

	public GameID : number = 0;
	public Seat : number = 0;
	public PlayerID : number = 0;
	public BetAmount : number = 0;
	public Bet : Array<CMDBet>;

	public constructor()
	{
		this.Bet = new Array<CMDBet>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.Seat = ba.readUint16();
		this.PlayerID = ba.readInt53();
		this.BetAmount = ba.readInt53();
		let BetSize:number = ba.readUint8();
		while (BetSize-- > 0)
		{
			let BetChild:CMDBet = new CMDBet();
			BetChild.fromBytes(ba);
			this.Bet.push(BetChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint16(this.Seat);
		ba.writeUint53(this.PlayerID);
		ba.writeUint53(this.BetAmount);
		ba.writeUint8(this.Bet.length);
		for (const BetChild of this.Bet)
		{
			let tempBuf = BetChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScPlayerBet();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'Seat(W)='+this.Seat+' ';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'BetAmount(Q)='+this.BetAmount+' ';
		str += 'Bet_Size(B)='+this.Bet.length + ' ';
		str += 'Bet(CMDBet)=[';
		for (let BetChild in this.Bet)
		{
			if (<number><unknown>BetChild > 0) str += ', ';
			str += this.Bet[BetChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30032;
	}

	public getCmdName() : string
	{
		return 'ScPlayerBet';
	}

}
