import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { SicboTotalBet } from '../../games/sicbo';
import { CMDScTotalBetSicbo, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetSicboHandler extends AbstractHandler {
    _command: CMDScTotalBetSicbo;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetSicbo;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as SicboTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;
        TotalBet.Big = this.convertBetAmount(this._command.Big);
        TotalBet.Small = this.convertBetAmount(this._command.Small);
        TotalBet.Odd = this.convertBetAmount(this._command.Odd);
        TotalBet.Even = this.convertBetAmount(this._command.Even);
        TotalBet.AllTriple = this.convertBetAmount(this._command.AllTriple);
        TotalBet.TripleOne = this.convertBetAmount(this._command.TripleOne);
        TotalBet.TripleTwo = this.convertBetAmount(this._command.TripleTwo);
        TotalBet.TripleThree = this.convertBetAmount(this._command.TripleThree);
        TotalBet.TripleFour = this.convertBetAmount(this._command.TripleFour);
        TotalBet.TripleFive = this.convertBetAmount(this._command.TripleFive);
        TotalBet.TripleSix = this.convertBetAmount(this._command.TripleSix);
        TotalBet.ShortOne = this.convertBetAmount(this._command.ShortOne);
        TotalBet.ShortTwo = this.convertBetAmount(this._command.ShortTwo);
        TotalBet.ShortThree = this.convertBetAmount(this._command.ShortThree);
        TotalBet.ShortFour = this.convertBetAmount(this._command.ShortFour);
        TotalBet.ShortFive = this.convertBetAmount(this._command.ShortFive);
        TotalBet.ShortSix = this.convertBetAmount(this._command.ShortSix);
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };
        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
