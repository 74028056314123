import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDDragontigerStatistics implements ICommand
{

	public TieCount : number = 0;
	public DragonWinCount : number = 0;
	public TigerWinCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.TieCount = ba.readUint8();
		this.DragonWinCount = ba.readUint8();
		this.TigerWinCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.TieCount);
		ba.writeUint8(this.DragonWinCount);
		ba.writeUint8(this.TigerWinCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDDragontigerStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'TieCount(B)='+this.TieCount+' ';
		str += 'DragonWinCount(B)='+this.DragonWinCount+' ';
		str += 'TigerWinCount(B)='+this.TigerWinCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'DragontigerStatistics';
	}

}
