import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const GIFT_KEY = 'app::main::gift';
export type GiftState = {
    Gift: Array<Gift>;
};
export type Gift = {
    ID: number;
    Price: number;
};
const initialGiftState = {} as GiftState;
const updateData = (state: GiftState, action: PayloadAction<Array<Gift>>) => {
    state.Gift = action.payload;
};
const giftSlice = createSlice({
    name: GIFT_KEY,
    initialState: initialGiftState,
    reducers: {
        updateData,
    },
});
export const giftSliceReducer = giftSlice.reducer;
export const giftSliceActions = giftSlice.actions;
