import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    Progress,
    ProgressLoadingState,
    getInitialProgressState,
} from '../../../models/games/Progress';

export const RESULT_PROGRESS_KEY = 'app::progress::result';
const endLoad = (
    state: ProgressLoadingState,
    action: PayloadAction<Progress>
) => {
    state[action.payload] = true;
};
const loginReset = (state: ProgressLoadingState) => {
    state[Progress.SERVER_BALANCE] = false;
    state[Progress.SERVER_PLAYER_COUNT] = false;
    state[Progress.SERVER_COUNT] = false;
    state[Progress.SERVER_USER_BET_LIMIT] = false;
    state[Progress.SERVER_USER_INFO_RESULT] = false;
    state[Progress.SERVER_HOST_LIST] = false;
};
const reset = (state: ProgressLoadingState): void => {
    Object.keys(Progress)
        .filter(p => isNaN(Number(p)))
        .forEach(k => {
            const key = Number(k) as Progress;
            state[key] = false;
        });
};
const initialProgressResultState: ProgressLoadingState =
    getInitialProgressState();
const resultProgressSlice = createSlice({
    name: RESULT_PROGRESS_KEY,
    initialState: initialProgressResultState,
    reducers: {
        endLoad,
        reset,
        loginReset,
    },
});
export const resultProgressSliceReducer = resultProgressSlice.reducer;
export const resultProgressSliceActions = resultProgressSlice.actions;
