import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AudioPlayContext } from '../contexts/AudioPlayContext';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';
import { getMainLimitRedState } from '../modules/main/selector';
import { LimitRed } from '../modules/main/slice/limitRed';
import { SessionStorageKey, useSession } from './storage/useSession';
export const useBetChip = (gameType: GameTypeEnum) => {
    const [selectedChip, setSelectedChip] = useSession<number>(
        SessionStorageKey.ChipIdx,
        1
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const { playButtonAudio } = useContext(AudioPlayContext);

    const [selectedBetRule, setSelectedBetRule] = useState<LimitRed>();
    const [chipList, setChipList] = useState<Array<number>>([]);
    const [selectedBetAmount, setSelectedBetAmount] = useState(0);
    const [currentGameType, setCurrentGameType] = useState(gameType);

    //Pending Bet
    // const [currentTable, setCurrentTable] = useState(0);
    // const [currentGameId, setCurrentGameId] = useState(0);
    // const allPendingBets = useSelector(getPendingBets);
    // const withHold = useSelector(getWithHold);
    // const [allPendingBetsAmount, setAllPendingBetAmount] = useState<number>(0);
    // const [allPendingWithHoldAmount, setAllPendingWithHoldAmount] =
    //     useState<number>(0);
    const [haveNonConfirmedBetTables, sethaveNonConfirmedBetTables] = useState<
        Array<number>
    >([]); //For Multibet
    useEffect(() => {
        if (gameType === GameTypeEnum.MultiBet) {
            setCurrentGameType(GameTypeEnum.Baccarat);
        } else {
            setCurrentGameType(gameType);
        }
    }, [gameType]);
    // useEffect(() => {
    //     if (allPendingBets && allPendingBets.length > 0) {
    //         let amount = 0;
    //         let withHoldAmount = 0;
    //         allPendingBets.forEach(bet => {
    //             setCurrentGameId(bet.GameId);
    //             if (withHold) {
    //                 const tmp = withHold.find(lr => lr.Type === bet.Type);
    //                 if (tmp) {
    //                     withHoldAmount += bet.Amount * tmp.Amount;
    //                 }
    //             }
    //             amount += bet.Amount;
    //         });
    //         setAllPendingBetAmount(amount);
    //         setAllPendingWithHoldAmount(withHoldAmount);
    //     } else {
    //         setCurrentGameId(0);
    //         setAllPendingBetAmount(0);
    //         setAllPendingWithHoldAmount(0);
    //     }
    // }, [allPendingBets, withHold]);

    const handleChipClick = (v: number) => {
        setSelectedChip(v);
        playButtonAudio();
    };
    const setSelectedChipAmount = () => {
        const amount = chipList[selectedChip - 1] | 0;
        setSelectedBetAmount(amount);
    };
    useEffect(() => {
        setSelectedChipAmount();
    }, [selectedChip, chipList]);
    useEffect(() => {
        const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
            lr => lr.GameType === currentGameType
        );
        if (limitRedByGame) {
            const limitRed = limitRedByGame.BetRule;
            if (limitRed.length > 0) {
                // if the are not selected limit, set the first limit in limitReds
                let selectedLimitRed =
                    limitRed.find(lr => lr.Selected === 1) || limitRed[0];
                if (!selectedLimitRed && limitRedByGame.RuleToSelect) {
                    selectedLimitRed = limitRedByGame.RuleToSelect;
                }
                setChipList(selectedLimitRed.Token);
                setSelectedBetRule(selectedLimitRed);
            }
        }
    }, [limitRedByGameType, currentGameType]);
    // default select first chip logic
    const setPendingBetState = (hostId: number, value: boolean) => {
        let havePending = haveNonConfirmedBetTables;
        if (value) {
            if (havePending.indexOf(hostId) < 0) {
                havePending.push(hostId);
                sethaveNonConfirmedBetTables([...havePending]);
            }
        } else {
            if (havePending.indexOf(hostId) >= 0) {
                havePending.splice(havePending.indexOf(hostId), 1);
                sethaveNonConfirmedBetTables([...havePending]);
            }
        }
    };
    return {
        chipList,
        selectedBetAmount,
        selectedBetRule,
        selectedChip,
        // currentTable,
        // currentGameId,
        // withHold,
        // allPendingBets,
        // allPendingBetsAmount,
        // allPendingWithHoldAmount,
        haveNonConfirmedBetTables,
        setPendingBetState,
        handleChipClick,
        // setCurrentTable,
    };
};
export type useBetChipState = ReturnType<typeof useBetChip>;
