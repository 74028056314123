export const GetPokerNumber = (n: number) => {
    if (n < 0) return 0;
    const c = (n + 1) % 13;
    return c === 0 ? 13 : c;
};
export const GetBaccaratPoint = (n: number) => Math.min(10, GetPokerNumber(n));
export const GetTotalBaccaratPoint = (ns: Array<number>) =>
    ns.length > 0
        ? ns.map(c => GetBaccaratPoint(c ?? 0)).reduce((p, c) => p + c) % 10
        : 0;
