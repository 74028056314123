export enum BetType {
    RB_0,
    RB_1,
    RB_2,
    RB_3,
    RB_4,
    RB_5,
    RB_6,
    RB_7,
    RB_8,
    RB_9,
    RB_10,
    RB_11,
    RB_12,
    RB_13,
    RB_14,
    RB_15,
    RB_16,
    RB_17,
    RB_18,
    RB_19,
    RB_20,
    RB_21,
    RB_22,
    RB_23,
    RB_24,
    RB_25,
    RB_26,
    RB_27,
    RB_28,
    RB_29,
    RB_30,
    RB_31,
    RB_32,
    RB_33,
    RB_34,
    RB_35,
    RB_36,

    //Split
    RB_0_1,
    RB_0_2,
    RB_0_3,
    RB_1_2,
    RB_1_4,
    RB_2_3,
    RB_2_5,
    RB_3_6,
    RB_4_5,
    RB_4_7,
    RB_5_6,
    RB_5_8,
    RB_6_9,
    RB_7_8,
    RB_7_10,
    RB_8_9,
    RB_8_11,
    RB_9_12,
    RB_10_11,
    RB_10_13,
    RB_11_12,
    RB_11_14,
    RB_12_15,
    RB_13_14,
    RB_13_16,
    RB_14_15,
    RB_14_17,
    RB_15_18,
    RB_16_17,
    RB_16_19,
    RB_17_18,
    RB_17_20,
    RB_18_21,
    RB_19_20,
    RB_19_22,
    RB_20_21,
    RB_20_23,
    RB_21_24,
    RB_22_23,
    RB_22_25,
    RB_23_24,
    RB_23_26,
    RB_24_27,
    RB_25_26,
    RB_25_28,
    RB_26_27,
    RB_26_29,
    RB_27_30,
    RB_28_29,
    RB_28_31,
    RB_29_30,
    RB_29_32,
    RB_30_33,
    RB_31_32,
    RB_31_34,
    RB_32_33,
    RB_32_35,
    RB_33_36,
    RB_34_35,
    RB_35_36,

    //Trio
    RB_0_1_2,
    RB_0_2_3,

    //Line
    RB_1_2_3,
    RB_4_5_6,
    RB_7_8_9,
    RB_10_11_12,
    RB_13_14_15,
    RB_16_17_18,
    RB_19_20_21,
    RB_22_23_24,
    RB_25_26_27,
    RB_28_29_30,
    RB_31_32_33,
    RB_34_35_36,

    //Corner
    RB_1_2_4_5,
    RB_2_3_5_6,
    RB_4_5_7_8,
    RB_5_6_8_9,
    RB_7_8_10_11,
    RB_8_9_11_12,
    RB_10_11_13_14,
    RB_11_12_14_15,
    RB_13_14_16_17,
    RB_14_15_17_18,
    RB_16_17_19_20,
    RB_17_18_20_21,
    RB_19_20_22_23,
    RB_20_21_23_24,
    RB_22_23_25_26,
    RB_23_24_26_27,
    RB_25_26_28_29,
    RB_26_27_29_30,
    RB_28_29_31_32,
    RB_29_30_32_33,
    RB_31_32_34_35,
    RB_32_33_35_36,

    //2 line
    RB_1_2_3_4_5_6,
    RB_4_5_6_7_8_9,
    RB_7_8_9_10_11_12,
    RB_10_11_12_13_14_15,
    RB_13_14_15_16_17_18,
    RB_16_17_18_19_20_21,
    RB_19_20_21_22_23_24,
    RB_22_23_24_25_26_27,
    RB_25_26_27_28_29_30,
    RB_28_29_30_31_32_33,
    RB_31_32_33_34_35_36,

    //Outside
    RBSet1,
    RBSet2,
    RBSet3,
    RBRow1,
    RBRow2,
    RBRow3,
    RB1To18,
    RB19To36,
    RBOdd,
    RBEven,
    RBRed,
    RBBlack,

    RB_0_1_2_3,

    RBMaxBet,
}

export enum RouletteResultEnum {
    RRZero,
    RROne,
    RRTwo,
    RRThree,
    RRFour,
    RRFive,
    RRSix,
    RRSeven,
    RREight,
    RRNine,
    RRTen,
    RREleven,
    RRTwelve,
    RRThirteen,
    RRForteen,
    RRFifthteen,
    RRSixteen,
    RRSeventeen,
    RREighteen,
    RRNineteen,
    RRTwenty,
    RRTwentyOne,
    RRTwentyTwo,
    RRTwentyThree,
    RRTwentyFour,
    RRTwentyFive,
    RRTwentySix,
    RRTwentySeven,
    RRTwentyEight,
    RRTwentyNine,
    RRThirty,
    RRThirtyOne,
    RRThirtyTwo,
    RRThirtyThree,
    RRThirtyFour,
    RRThirtyFive,
    RRThirtySix,

    //Special
    RRSet1,
    RRSet2,
    RRSet3,
    RRRow1,
    RRRow2,
    RRRow3,
    RR1To18,
    RR19To36,
    RROdd,
    RREven,
    RRRed,
    RRBlack,
}
