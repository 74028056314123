import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScLotteryBallErase implements ICommand
{


	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();


		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScLotteryBallErase();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		return str;
	}

	public getCmdID() : number
	{
		return 30026;
	}

	public getCmdName() : string
	{
		return 'ScLotteryBallErase';
	}

}
