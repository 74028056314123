import { Navigate } from 'react-router-dom';
import { ROUTE_LOBBY } from '../../models/Route';

export const Lobbyw = () => {
    return (
        <>
            <Navigate to={ROUTE_LOBBY} />
        </>
    );
};
