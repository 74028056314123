import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { CMDSpSicboStatistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { SicBoStatistics } from '../../games/sicbo';
import { hostsSliceActions } from '../../../modules/host/slice';

export class CMDSpSicboStatisticsHandler extends AbstractHandler {
    _command: CMDSpSicboStatistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpSicboStatistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as SicBoStatistics;
        const hostId = this._command.HostID;
        const val = this._command.Statistics;
        const totalNum: number =
            val.Number1Count +
            val.Number2Count +
            val.Number3Count +
            val.Number4Count +
            val.Number5Count +
            val.Number6Count;
        statistics.Dice1Rate = val.Number1Count / totalNum;
        statistics.Dice2Rate = val.Number2Count / totalNum;
        statistics.Dice3Rate = val.Number3Count / totalNum;
        statistics.Dice4Rate = val.Number4Count / totalNum;
        statistics.Dice5Rate = val.Number5Count / totalNum;
        statistics.Dice6Rate = val.Number6Count / totalNum;

        const total = val.BigCount + val.SmallCount + val.TripleCount;
        statistics.BigRate = val.BigCount / total;
        statistics.SmallRate = val.SmallCount / total;
        statistics.TripleRate = val.TripleCount / total;
        statistics.OddRate = val.OddCount / total;
        statistics.EvenRate = val.EvenCount / total;

        const pcs: Array<number> = [
            0,
            1,
            2,
            3,
            val.Point4Count,
            val.Point5Count,
            val.Point6Count,
            val.Point7Count,
            val.Point8Count,
            val.Point9Count,
            val.Point10Count,
            val.Point11Count,
            val.Point12Count,
            val.Point13Count,
            val.Point14Count,
            val.Point15Count,
            val.Point16Count,
            val.Point17Count,
        ];

        const keys: Array<number> = new Array<number>();
        for (let i = 0; i < 14; i++) {
            keys.push(i + 4);
        }
        statistics.ColdNums = keys.sort((a, b) => pcs[a] - pcs[b]).slice(0);
        statistics.ColdNum0 = keys[0];
        statistics.ColdNum1 = keys[1];
        statistics.ColdNum2 = keys[2];
        statistics.ColdCount0 = pcs[keys[0]];
        statistics.ColdCount1 = pcs[keys[1]];
        statistics.ColdCount2 = pcs[keys[2]];

        statistics.HotNums = keys.sort((a, b) => pcs[b] - pcs[a])
		statistics.HotNum0 = keys[0];
		statistics.HotNum1 = keys[1];
		statistics.HotNum2 = keys[2];
		statistics.HotCount0 = pcs[keys[0]];
		statistics.HotCount1 = pcs[keys[1]];
		statistics.HotCount2 = pcs[keys[2]];

        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
