import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsSetPrefs implements ICommand
{

	public FPrefs : bigint = 0n; 
	public Prefs : number = 0;
	public FPrefs2 : bigint = 0n; 
	public Prefs2 : number = 0;
	public FPrefs3 : bigint = 0n; 
	public Prefs3 : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.FPrefs = ba.readUint64();
		this.Prefs = Number(this.FPrefs);
		this.FPrefs2 = ba.readUint64();
		this.Prefs2 = Number(this.FPrefs2);
		this.FPrefs3 = ba.readUint64();
		this.Prefs3 = Number(this.FPrefs3);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint64(this.FPrefs);
		ba.writeUint64(this.FPrefs2);
		ba.writeUint64(this.FPrefs3);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsSetPrefs();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Prefs(F)='+this.Prefs+' ';
		str += 'Prefs2(F)='+this.Prefs2+' ';
		str += 'Prefs3(F)='+this.Prefs3+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55015;
	}

	public getCmdName() : string
	{
		return 'PsSetPrefs';
	}

}
