import { useEffect, useMemo, useState } from 'react';
import { LocalStorageKey } from './storage/useLocalStorage';
export const useQueryParams = () => {
    const [search, setSearch] = useState<string>();
    const values = useMemo(
        () => new URLSearchParams(search as string),
        [search]
    );
    const [ready, setReady] = useState<boolean>();
    const [supportLocalStorage, setSupportLocalStorage] =
        useState<boolean>(true);

    useEffect(() => {
        setReady(true);
    }, [search]);
    useEffect(() => {
        const str = 'test';
        window.localStorage.setItem(str, str);
        const support = window.localStorage.getItem(str) === str;
        setSupportLocalStorage(support);
        window.localStorage.removeItem(str);

        const urlSearch = window.sessionStorage.getItem('private_search');
        window.sessionStorage.removeItem('private_search');
        if (urlSearch) {
            // remove:
            // 1. login fail TokenExpire
            // 2. onDuplicateLogin
            // 3. onScMaintenanceStart
            // 4. onPlayerDisabled
            // 5. logout

            window.localStorage.setItem(
                LocalStorageKey.FullParameter,
                urlSearch
            );
            setSearch(urlSearch + '&isReconnect=0');
            if (!support) {
                window.sessionStorage.setItem(
                    LocalStorageKey.FullParameter,
                    urlSearch
                );
            }
        } else {
            const local = window.localStorage.getItem(
                LocalStorageKey.FullParameter
            ) as string;
            if (local != null) {
                setSearch(local + '&isReconnect=1');
            } else {
                setSearch('');
            }
        }
    }, []);

    return { values, ready, supportLocalStorage };
};
