import { RootState } from '../../store/store';
import { REPORT_KEY, ReportState } from './slice';
import { BET_RECORD_KEY, BetRecordState } from './slice/betRecord';
import { BET_STATISTICS_KEY, BetStatisticsState } from './slice/betStatistics';
import { BET_SUMMARY_KEY, BetSummaryState } from './slice/betSummary';

export const getReportState = (state: RootState): ReportState =>
    state[REPORT_KEY];
export const getBetStatisticsReportState = (state: RootState): BetStatisticsState => getReportState(state)[BET_STATISTICS_KEY];
export const getBetRecordReportState = (state: RootState): BetRecordState => getReportState(state)[BET_RECORD_KEY];
export const getBetSummaryReportState = (state: RootState): BetSummaryState => getReportState(state)[BET_SUMMARY_KEY];
