import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetMoneywheel implements ICommand
{

	public Total : CMDTotalBet;
	public Main1 : CMDBetSummary;
	public Main2 : CMDBetSummary;
	public Main3 : CMDBetSummary;
	public Main4 : CMDBetSummary;
	public Main5 : CMDBetSummary;
	public Main6 : CMDBetSummary;
	public Main7 : CMDBetSummary;
	public Side1 : CMDBetSummary;
	public Side2 : CMDBetSummary;
	public Side3 : CMDBetSummary;
	public Side4 : CMDBetSummary;
	public Side5 : CMDBetSummary;
	public Side6 : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Main1 = new CMDBetSummary();
		this.Main1.fromBytes(ba);
		this.Main2 = new CMDBetSummary();
		this.Main2.fromBytes(ba);
		this.Main3 = new CMDBetSummary();
		this.Main3.fromBytes(ba);
		this.Main4 = new CMDBetSummary();
		this.Main4.fromBytes(ba);
		this.Main5 = new CMDBetSummary();
		this.Main5.fromBytes(ba);
		this.Main6 = new CMDBetSummary();
		this.Main6.fromBytes(ba);
		this.Main7 = new CMDBetSummary();
		this.Main7.fromBytes(ba);
		this.Side1 = new CMDBetSummary();
		this.Side1.fromBytes(ba);
		this.Side2 = new CMDBetSummary();
		this.Side2.fromBytes(ba);
		this.Side3 = new CMDBetSummary();
		this.Side3.fromBytes(ba);
		this.Side4 = new CMDBetSummary();
		this.Side4.fromBytes(ba);
		this.Side5 = new CMDBetSummary();
		this.Side5.fromBytes(ba);
		this.Side6 = new CMDBetSummary();
		this.Side6.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Main1.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Main2.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Main3.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Main4.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Main5.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Main6.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Main7.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.Side1.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)
		let tempBuf9 = this.Side2.toBytes().toBuffer();
		ba.appendBuf(tempBuf9)
		let tempBuf10 = this.Side3.toBytes().toBuffer();
		ba.appendBuf(tempBuf10)
		let tempBuf11 = this.Side4.toBytes().toBuffer();
		ba.appendBuf(tempBuf11)
		let tempBuf12 = this.Side5.toBytes().toBuffer();
		ba.appendBuf(tempBuf12)
		let tempBuf13 = this.Side6.toBytes().toBuffer();
		ba.appendBuf(tempBuf13)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetMoneywheel();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Main1(CMDBetSummary)='+this.Main1+' ';
		str += 'Main2(CMDBetSummary)='+this.Main2+' ';
		str += 'Main3(CMDBetSummary)='+this.Main3+' ';
		str += 'Main4(CMDBetSummary)='+this.Main4+' ';
		str += 'Main5(CMDBetSummary)='+this.Main5+' ';
		str += 'Main6(CMDBetSummary)='+this.Main6+' ';
		str += 'Main7(CMDBetSummary)='+this.Main7+' ';
		str += 'Side1(CMDBetSummary)='+this.Side1+' ';
		str += 'Side2(CMDBetSummary)='+this.Side2+' ';
		str += 'Side3(CMDBetSummary)='+this.Side3+' ';
		str += 'Side4(CMDBetSummary)='+this.Side4+' ';
		str += 'Side5(CMDBetSummary)='+this.Side5+' ';
		str += 'Side6(CMDBetSummary)='+this.Side6+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30757;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetMoneywheel';
	}

}
