import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDRoom implements ICommand
{

	public RoomNumber : number = 0;
	public PlayerID : Array<number>;

	public constructor()
	{
		this.PlayerID = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.RoomNumber = ba.readUint16();
		let PlayerIDSize:number = ba.readUint8();
		while (PlayerIDSize-- > 0)
		{
			let PlayerIDChild:number = ba.readInt53();
			this.PlayerID.push(PlayerIDChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.RoomNumber);
		ba.writeUint8(this.PlayerID.length);
		for (const PlayerIDChild of this.PlayerID)
		{
			ba.writeUint53(PlayerIDChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDRoom();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'RoomNumber(W)='+this.RoomNumber+' ';
		str += 'PlayerID_Size(B)='+this.PlayerID.length + ' ';
		str += 'PlayerID(Q)=[';
		for (let PlayerIDChild in this.PlayerID)
		{
			if (<number><unknown>PlayerIDChild > 0) str += ', ';
			str += this.PlayerID[PlayerIDChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Room';
	}

}
