import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { AndarBaharTotalBet } from '../../games/andarBahar';
import { CMDScTotalBetAndarBahar, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetAndarBaharHandler extends AbstractHandler {
    _command: CMDScTotalBetAndarBahar;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetAndarBahar;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as AndarBaharTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;

        TotalBet.Andar = this.convertBetAmount(this._command.Andar);
        TotalBet.Bahar = this.convertBetAmount(this._command.Bahar);
        TotalBet.Win1To5 = this.convertBetAmount(this._command.Win1To5);
        TotalBet.Win6To10 = this.convertBetAmount(this._command.Win6To10);
        TotalBet.Win11To15 = this.convertBetAmount(this._command.Win11To15);
        TotalBet.Win16To20 = this.convertBetAmount(this._command.Win16To20);
        TotalBet.Win21To25 = this.convertBetAmount(this._command.Win21To25);
        TotalBet.Win26To30 = this.convertBetAmount(this._command.Win26To30);
        TotalBet.Win31To35 = this.convertBetAmount(this._command.Win31To35);
        TotalBet.Win36To40 = this.convertBetAmount(this._command.Win36To40);
        TotalBet.Win41To45 = this.convertBetAmount(this._command.Win41To45);
        TotalBet.Win46To49 = this.convertBetAmount(this._command.Win46To49);
        TotalBet.WinFirst3 = this.convertBetAmount(this._command.WinFirst3);
        TotalBet.WinFirstAndar = this.convertBetAmount(
            this._command.WinFirstAndar
        );
        TotalBet.WinFirstBahar = this.convertBetAmount(
            this._command.WinFirstBahar
        );
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };

        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
