import { CMDBaseResult } from "./CMDBaseResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDAndarBahar implements ICommand
{

	public BaseResult : CMDBaseResult;
	public Joker : number = 0;
	public AndarBahar : Array<number>;

	public constructor()
	{
		this.AndarBahar = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseResult = new CMDBaseResult();
		this.BaseResult.fromBytes(ba);
		this.Joker = ba.readUint8();
		let AndarBaharSize:number = ba.readUint8();
		while (AndarBaharSize-- > 0)
		{
			let AndarBaharChild:number = ba.readUint8();
			this.AndarBahar.push(AndarBaharChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.Joker);
		ba.writeUint8(this.AndarBahar.length);
		for (const AndarBaharChild of this.AndarBahar)
		{
			ba.writeUint8(AndarBaharChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDAndarBahar();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseResult(CMDBaseResult)='+this.BaseResult+' ';
		str += 'Joker(B)='+this.Joker+' ';
		str += 'AndarBahar_size(B)='+this.AndarBahar.length + ' ';
		str += 'AndarBahar(B)=[';
		for (let AndarBaharChild in this.AndarBahar)
		{
			if (<number><unknown>AndarBaharChild > 0) str += ', ';
			str += this.AndarBahar[AndarBaharChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'AndarBahar';
	}

}
