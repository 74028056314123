import { CMDBaseResult } from "./CMDBaseResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDTeenPatti2020 implements ICommand
{

	public BaseResult : CMDBaseResult;
	public PlayerACard : Array<number>;
	public PlayerBCard : Array<number>;

	public constructor()
	{
		this.PlayerACard = new Array<number>();
		this.PlayerBCard = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseResult = new CMDBaseResult();
		this.BaseResult.fromBytes(ba);
		let PlayerACardSize:number = ba.readUint8();
		while (PlayerACardSize-- > 0)
		{
			let PlayerACardChild:number = ba.readUint8();
			this.PlayerACard.push(PlayerACardChild);
		}
		let PlayerBCardSize:number = ba.readUint8();
		while (PlayerBCardSize-- > 0)
		{
			let PlayerBCardChild:number = ba.readUint8();
			this.PlayerBCard.push(PlayerBCardChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.PlayerACard.length);
		for (const PlayerACardChild of this.PlayerACard)
		{
			ba.writeUint8(PlayerACardChild);
		}
		ba.writeUint8(this.PlayerBCard.length);
		for (const PlayerBCardChild of this.PlayerBCard)
		{
			ba.writeUint8(PlayerBCardChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDTeenPatti2020();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseResult(CMDBaseResult)='+this.BaseResult+' ';
		str += 'PlayerACard_size(B)='+this.PlayerACard.length + ' ';
		str += 'PlayerACard(B)=[';
		for (let PlayerACardChild in this.PlayerACard)
		{
			if (<number><unknown>PlayerACardChild > 0) str += ', ';
			str += this.PlayerACard[PlayerACardChild];
		}
		str += '] ';
		str += 'PlayerBCard_size(B)='+this.PlayerBCard.length + ' ';
		str += 'PlayerBCard(B)=[';
		for (let PlayerBCardChild in this.PlayerBCard)
		{
			if (<number><unknown>PlayerBCardChild > 0) str += ', ';
			str += this.PlayerBCard[PlayerBCardChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'TeenPatti2020';
	}

}
