import { getEmptyLobbyRoomInfo } from '@/models/lobby/LobbyRoom';
import { lobbyRoomSliceActions } from '@/modules/lobby/room/slice';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { CMDScBlackjackSeatInfo, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScBlackjackSeatInfoHandler extends AbstractHandler {
    _command: CMDScBlackjackSeatInfo;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScBlackjackSeatInfo;
    }
    handle(dispatch: Dispatch<Action>): void {
        const hostId = this._command.HostID;
        const lobbyRoom = getEmptyLobbyRoomInfo(hostId);
        lobbyRoom.Info[1] = this._command.Seat1PlayerID;
        lobbyRoom.Info[2] = this._command.Seat2PlayerID;
        lobbyRoom.Info[3] = this._command.Seat3PlayerID;
        lobbyRoom.Info[4] = this._command.Seat4PlayerID;
        lobbyRoom.Info[5] = this._command.Seat5PlayerID;
        lobbyRoom.Info[6] = this._command.Seat6PlayerID;
        lobbyRoom.Info[7] = this._command.Seat7PlayerID;
        dispatch(lobbyRoomSliceActions.init(lobbyRoom));
    }
}
