import { CMDPartnerCompany } from "./CMDPartnerCompany"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPartnerGame implements ICommand
{

	public Company : Array<CMDPartnerCompany>;

	public constructor()
	{
		this.Company = new Array<CMDPartnerCompany>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let CompanySize:number = ba.readUint8();
		while (CompanySize-- > 0)
		{
			let CompanyChild:CMDPartnerCompany = new CMDPartnerCompany();
			CompanyChild.fromBytes(ba);
			this.Company.push(CompanyChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Company.length);
		for (const CompanyChild of this.Company)
		{
			let tempBuf = CompanyChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPartnerGame();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Company_size(B)='+this.Company.length + ' ';
		str += 'Company(CMDPartnerCompany)=[';
		for (let CompanyChild in this.Company)
		{
			if (<number><unknown>CompanyChild > 0) str += ', ';
			str += this.Company[CompanyChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50050;
	}

	public getCmdName() : string
	{
		return 'SpPartnerGame';
	}

}
