import { AudioPlayContext } from '@/contexts/AudioPlayContext';
import { GridType } from '@/contexts/LobbyUiContext';
import { GameType as CommonGameType } from '@/models/games/enums/GameType';
import {
    GA_EVENT_CATEGORY,
    GA_LOBBY_EVENT_NAME,
    getNavTab,
    sendGA,
} from '@/utils/ga';
import { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from './storage/useLocalStorage';
export type FilterType = CommonGameType | -1;
export const useLobbyUI = () => {
    const [grid, setGrid] = useLocalStorage<GridType>('gridType', 0);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const [gameFilter, setGameFilter] = useState<FilterType>(-1);
    // const [gameFilter, setGameFilter] = useLocalStorage<FilterType>(
    //     'game_filter',
    //     -1
    // );
    const [isShowGridToggle, setIsShowGridToggle] = useState(false);
    const [isShowGameFilter, setIsShowGameFilter] = useState(false);
    const [isSupportAspectRatio, setIsSupportAspectRatio] = useState(true);

    useEffect(() => {
        const isar = CSS.supports('aspect-ratio: 1');
        setIsSupportAspectRatio(isar);
    }, []);

    const toggleGrid = () => {
        playButtonAudio();
        const type = ((grid + 1) % 3) as GridType;
        setGrid(type);
        const currentPage = getNavTab();
        switch (grid) {
            case 0:
                sendGA(
                    GA_LOBBY_EVENT_NAME.MINI_MODE,
                    GA_EVENT_CATEGORY.ENGAGEMENT,
                    currentPage
                );
                break;
            case 1:
                sendGA(
                    GA_LOBBY_EVENT_NAME.LIST_MODE,
                    GA_EVENT_CATEGORY.ENGAGEMENT,
                    currentPage
                );
                break;
            case 2:
                sendGA(
                    GA_LOBBY_EVENT_NAME.GRID_MODE,
                    GA_EVENT_CATEGORY.ENGAGEMENT,
                    currentPage
                );
                break;
        }
    };
    return {
        grid,
        toggleGrid,
        gameFilter,
        setGameFilter,
        isShowGridToggle,
        setIsShowGridToggle,
        isShowGameFilter,
        setIsShowGameFilter,
        isSupportAspectRatio,
    };
};
export type useLobbyUIState = ReturnType<typeof useLobbyUI>;
