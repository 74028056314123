import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsRequestInitClient implements ICommand
{

	public Host : Array<number>;

	public constructor()
	{
		this.Host = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let HostSize:number = ba.readUint8();
		while (HostSize-- > 0)
		{
			let HostChild:number = ba.readUint16();
			this.Host.push(HostChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Host.length);
		for (const HostChild of this.Host)
		{
			ba.writeUint16(HostChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsRequestInitClient();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Host_size(B)='+this.Host.length + ' ';
		str += 'Host(W)=[';
		for (let HostChild in this.Host)
		{
			if (<number><unknown>HostChild > 0) str += ', ';
			str += this.Host[HostChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 55010;
	}

	public getCmdName() : string
	{
		return 'PsRequestInitClient';
	}

}
