import { CMDRoom } from "./CMDRoom"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScInitRoom implements ICommand
{

	public HostID : number = 0;
	public Room : Array<CMDRoom>;

	public constructor()
	{
		this.Room = new Array<CMDRoom>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		let RoomSize:number = ba.readUint16();
		while (RoomSize-- > 0)
		{
			let RoomChild:CMDRoom = new CMDRoom();
			RoomChild.fromBytes(ba);
			this.Room.push(RoomChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint16(this.Room.length);
		for (const RoomChild of this.Room)
		{
			let tempBuf = RoomChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScInitRoom();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Room_Size(W)='+this.Room.length + ' ';
		str += 'Room(CMDRoom)=[';
		for (let RoomChild in this.Room)
		{
			if (<number><unknown>RoomChild > 0) str += ', ';
			str += this.Room[RoomChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30033;
	}

	public getCmdName() : string
	{
		return 'ScInitRoom';
	}

}
