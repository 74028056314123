export type Roadmap<T> = {
    HostId: number;
    Other: T[][];
    BeadRoad: Array<T>;
    BigRoad: Array<T>;
    BigEyeRoad: Array<T>;
    SmallRoad: Array<T>;
    CockroachRoad: Array<T>;
    Summary: Record<string, number>;
    NextBigEye: number;
    NextSmall: number;
    NextCockroachRoad: number;
};

export const getEmptyRoadMap = <T>(hostId: number): Roadmap<T> => ({
    HostId: hostId,
    Other: [],
    BigRoad: [],
    BigEyeRoad: [],
    SmallRoad: [],
    BeadRoad: [],
    CockroachRoad: [],
    Summary: {},
    NextBigEye: -1,
    NextSmall: -1,
    NextCockroachRoad: -1,
});
