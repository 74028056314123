import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDCurrency implements ICommand
{

	public CurrencyType : number = 0;
	public ExchangeRate : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.CurrencyType = ba.readInt53();
		this.ExchangeRate = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.CurrencyType);
		ba.writeUint53(this.ExchangeRate);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDCurrency();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'CurrencyType(Q)='+this.CurrencyType+' ';
		str += 'ExchangeRate(Q)='+this.ExchangeRate+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Currency';
	}

}
