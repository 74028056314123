import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDLiveGameConfig implements ICommand
{

	public HostID : number = 0;
	public GameType : number = 0;
	public Setting : number = 0;
	public HallID : number = 0;
	public ShortcutGroup : string = "";
	public AppName : string = "";
	public FLVSD : string = "";
	public FLVHD : string = "";
	public HLSSD : string = "";
	public HLSHD : string = "";
	public NANOSD : string = "";
	public NANOHD : string = "";
	public Audio : number = 0;
	public DelayDraw : number = 0;
	public DelayResult : number = 0;
	public Label : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.GameType = ba.readInt53();
		this.Setting = ba.readUint16();
		this.HallID = ba.readInt53();
		this.ShortcutGroup = ba.readString();
		this.AppName = ba.readString();
		this.FLVSD = ba.readString();
		this.FLVHD = ba.readString();
		this.HLSSD = ba.readString();
		this.HLSHD = ba.readString();
		this.NANOSD = ba.readString();
		this.NANOHD = ba.readString();
		this.Audio = ba.readUint8();
		this.DelayDraw = ba.readUint32();
		this.DelayResult = ba.readUint32();
		this.Label = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint53(this.GameType);
		ba.writeUint16(this.Setting);
		ba.writeUint53(this.HallID);
		ba.writeString(this.ShortcutGroup);
		ba.writeString(this.AppName);
		ba.writeString(this.FLVSD);
		ba.writeString(this.FLVHD);
		ba.writeString(this.HLSSD);
		ba.writeString(this.HLSHD);
		ba.writeString(this.NANOSD);
		ba.writeString(this.NANOHD);
		ba.writeUint8(this.Audio);
		ba.writeUint32(this.DelayDraw);
		ba.writeUint32(this.DelayResult);
		ba.writeString(this.Label);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDLiveGameConfig();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'GameType(Q)='+this.GameType+' ';
		str += 'Setting(W)='+this.Setting+' ';
		str += 'HallID(Q)='+this.HallID+' ';
		str += 'ShortcutGroup(S)='+this.ShortcutGroup+' ';
		str += 'AppName(S)='+this.AppName+' ';
		str += 'FLVSD(S)='+this.FLVSD+' ';
		str += 'FLVHD(S)='+this.FLVHD+' ';
		str += 'HLSSD(S)='+this.HLSSD+' ';
		str += 'HLSHD(S)='+this.HLSHD+' ';
		str += 'NANOSD(S)='+this.NANOSD+' ';
		str += 'NANOHD(S)='+this.NANOHD+' ';
		str += 'Audio(B)='+this.Audio+' ';
		str += 'DelayDraw(D)='+this.DelayDraw+' ';
		str += 'DelayResult(D)='+this.DelayResult+' ';
		str += 'Label(S)='+this.Label+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'LiveGameConfig';
	}

}
