import { CMDBaseInformation } from "./CMDBaseInformation"
import { CMDTicketPrice } from "./CMDTicketPrice"
import { CMDLottery } from "./CMDLottery"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScInitLottery implements ICommand
{

	public TicketPrice : Array<CMDTicketPrice>;
	public Information : CMDBaseInformation;
	public OldResult : Array<CMDLottery>;
	public CurrentState : number = 0;
	public CurrentResult : CMDLottery;

	public constructor()
	{
		this.TicketPrice = new Array<CMDTicketPrice>();
		this.OldResult = new Array<CMDLottery>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let TicketPriceSize:number = ba.readUint8();
		while (TicketPriceSize-- > 0)
		{
			let TicketPriceChild:CMDTicketPrice = new CMDTicketPrice();
			TicketPriceChild.fromBytes(ba);
			this.TicketPrice.push(TicketPriceChild);
		}
		this.Information = new CMDBaseInformation();
		this.Information.fromBytes(ba);
		let OldResultSize:number = ba.readUint8();
		while (OldResultSize-- > 0)
		{
			let OldResultChild:CMDLottery = new CMDLottery();
			OldResultChild.fromBytes(ba);
			this.OldResult.push(OldResultChild);
		}
		this.CurrentState = ba.readUint16();
		this.CurrentResult = new CMDLottery();
		this.CurrentResult.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.TicketPrice.length);
		for (const TicketPriceChild of this.TicketPrice)
		{
			let tempBuf = TicketPriceChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		let tempBuf2 = this.Information.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		ba.writeUint8(this.OldResult.length);
		for (const OldResultChild of this.OldResult)
		{
			let tempBuf = OldResultChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint16(this.CurrentState);
		let tempBuf6 = this.CurrentResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScInitLottery();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'TicketPrice_size(B)='+this.TicketPrice.length + ' ';
		str += 'TicketPrice(CMDTicketPrice)=[';
		for (let TicketPriceChild in this.TicketPrice)
		{
			if (<number><unknown>TicketPriceChild > 0) str += ', ';
			str += this.TicketPrice[TicketPriceChild].toString();
		}
		str += '] ';
		str += 'Information(CMDBaseInformation)='+this.Information+' ';
		str += 'OldResult_size(B)='+this.OldResult.length + ' ';
		str += 'OldResult(CMDLottery)=[';
		for (let OldResultChild in this.OldResult)
		{
			if (<number><unknown>OldResultChild > 0) str += ', ';
			str += this.OldResult[OldResultChild].toString();
		}
		str += '] ';
		str += 'CurrentState(W)='+this.CurrentState+' ';
		str += 'CurrentResult(CMDLottery)='+this.CurrentResult+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30506;
	}

	public getCmdName() : string
	{
		return 'ScInitLottery';
	}

}
