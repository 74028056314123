import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetThaihilo implements ICommand
{

	public Total : CMDTotalBet;
	public Hi : CMDBetSummary;
	public Lo : CMDBetSummary;
	public ElevenHiLo : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Hi = new CMDBetSummary();
		this.Hi.fromBytes(ba);
		this.Lo = new CMDBetSummary();
		this.Lo.fromBytes(ba);
		this.ElevenHiLo = new CMDBetSummary();
		this.ElevenHiLo.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Hi.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Lo.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.ElevenHiLo.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetThaihilo();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Hi(CMDBetSummary)='+this.Hi+' ';
		str += 'Lo(CMDBetSummary)='+this.Lo+' ';
		str += 'ElevenHiLo(CMDBetSummary)='+this.ElevenHiLo+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30773;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetThaihilo';
	}

}
