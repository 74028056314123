import { SvgButton } from '@/components/common/svgButton/SvgButton';
import { ScreenContext } from '@/contexts/ScreenContext';
import { useGameFeature } from '@/hooks/useGameFeature';
import { getVHByRem } from '@/utils/commonFunc';
import Box from '@mui/material/Box/Box';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { LocalStorageKey } from '../../hooks/storage/useLocalStorage';
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from '../../models/Popup';
import { ROUTE_THANKS4PLAY } from '../../models/Route';
import { GameError } from '../../models/games/enums/GameError';
import { gameSliceActions } from '../games/slice';
import { getMainLimitRedState } from '../main/selector';
import { limitRedSliceActions } from '../main/slice/limitRed';
import { routeSliceActions } from '../routeController/slice';
import './index.scss';
import { getPopupState } from './selector';
import { popupSliceActions } from './slice';
import { CancelButton } from './svg';
import { TickButton } from './svg/TickButton';

export const Popup = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [message, setMessage] = useState<string>('');
    const { isLandscape } = useContext(ScreenContext);
    const { ChangePopup } = useSelector(getMainLimitRedState);
    const timer = useRef<NodeJS.Timeout | null>();
    const { disconnect } = useContext(WebSocketContext);
    const popupData = useSelector(getPopupState);
    const { isOpen, key, type, dismiss, confirmKey, params } = popupData;
    const { isAutoConfirmEnabled } = useGameFeature(false);
    useEffect(() => {
        if (ChangePopup) {
            if (isAutoConfirmEnabled) {
                dispatch(popupSliceActions.open('abc.change_bet_limit_bo'));
            } else {
                dispatch(popupSliceActions.open('popup.server_limit_changed'));
            }
            dispatch(limitRedSliceActions.clearPopup());
        }
    }, [ChangePopup]);
    const handleConfirm = () => {
        // playButtonAudio();
        if (
            type === POPUP_TYPE.LEAVE_ALERT ||
            type === POPUP_TYPE.THANK_ALERT ||
            type === POPUP_TYPE.LOGOUT_CONFIRM
        ) {
            if (type === POPUP_TYPE.LOGOUT_CONFIRM) {
                window.localStorage.removeItem(LocalStorageKey.FullParameter);
                window.localStorage.removeItem(LocalStorageKey.ReconnectKey);
                dispatch(
                    gameSliceActions.setNetworkState({
                        notToReconnect: true,
                    })
                );
            }
            dispatch(
                gameSliceActions.setNetworkState({
                    needUserConfirm: false,
                })
            );
            dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
            disconnect(true);
            if (!window.localStorage.getItem(LocalStorageKey.ClearReason)) {
                window.localStorage.setItem(
                    LocalStorageKey.ClearReason,
                    GameError.LOGOUTTED.toString()
                );
            }
        } else if (confirmKey && confirmKey > POPUP_CONFIRM_ACTION.NONE) {
            //TODO: popupSliceActions function?
            //string -> function
            //const func = eval('const func = ' + confirmFunc);
            // const func = new Function('return ' + confirmFunc)();
            // if (func && typeof func === 'function') {
            //     func();
            // }
            dispatch(popupSliceActions.triggerConfirm(confirmKey));
        }
        dispatch(popupSliceActions.close());
    };
    const handleCancel = () => {
        // playButtonAudio();
        dispatch(popupSliceActions.close());
    };
    useEffect(() => {
        if (key) {
            let msg: string[] = [];
            if (typeof key === 'string') {
                msg.push(key);
            } else {
                msg = key;
            }
            const transMsg = msg.map(m => {
                if (params) {
                    return t(m, params);
                } else {
                    return t(m);
                }
            });
            setMessage(transMsg.join(' '));
        }
    }, [key, i18n.language]);
    useEffect(() => {
        if (isOpen) {
            if (
                type === POPUP_TYPE.LEAVE_ALERT ||
                type === POPUP_TYPE.THANK_ALERT
            ) {
                if (type === POPUP_TYPE.LEAVE_ALERT) {
                    // dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
                    // disconnect();
                    window.localStorage.removeItem(
                        LocalStorageKey.FullParameter
                    );
                    window.localStorage.removeItem(
                        LocalStorageKey.ReconnectKey
                    );
                }
                dispatch(
                    gameSliceActions.setNetworkState({
                        notToReconnect: true,
                    })
                );
            }
        }
        if (dismiss && isOpen) {
            timer.current = setTimeout(() => {
                dispatch(popupSliceActions.close());
            }, dismiss);
        } else {
            if (timer.current) clearTimeout(timer.current);
        }
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [isOpen, dismiss]);
    return message !== '' ? (
        <Dialog open={isOpen} className={`PopupDialog`} disableEscapeKeyDown>
            <Box className={`box`}>
                <DialogContentText
                    textAlign={'center'}
                    sx={{
                        fontSize: isLandscape ? getVHByRem(1) : '1rem',
                        color: theme => theme.palette.text.primary,
                        whiteSpace: 'pre-line',
                    }}
                >
                    {message}
                </DialogContentText>

                {type !== POPUP_TYPE.POPUP && (
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        {(type === POPUP_TYPE.CONFIRM ||
                            type === POPUP_TYPE.LOGOUT_CONFIRM) && (
                            <SvgButton onClick={handleCancel}>
                                <CancelButton />
                            </SvgButton>
                        )}
                        <SvgButton onClick={handleConfirm}>
                            <TickButton />
                        </SvgButton>
                    </DialogActions>
                )}
            </Box>
        </Dialog>
    ) : (
        <></>
    );
};
