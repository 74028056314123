import { BetGroup } from '@/models/host/BetAmount';
import { EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
    BasePayload,
    BetPayload,
    BooleanPayload,
    ClearPendingPayload,
    ConfirmedBetsPayload,
    InitBetPayload,
} from './payload';
import { TableBet, tableBetsAdapter } from './slice';

const initTable = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BasePayload>
) => {
    const gameId = action.payload.gameId;
    return tableBetsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            GameId: gameId,
        },
    });
};

const initBets = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<InitBetPayload>
) => {
    return tableBetsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            PlayerBets: action.payload.bets,
            BetSeqId: action.payload.betSeqId,
        },
    });
};

const startBet = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BasePayload>
) => {
    const gameId = action.payload.gameId;
    return tableBetsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            GameId: gameId,
            BetSeqId: 0,
            PlayerBets: [],
            BetsSummary: [],
            PendingBets: [],
            ConfirmedBets: [],
            TableTotalBet: 0,
            PendingTotalBet: 0,
            isWaitingBet: false,
        },
    });
};

const addBet = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BetPayload>
) => {
    const gameId = action.payload.gameId;
    const host = state.entities[action.payload.hostId];
    if (host && (host.GameId === gameId || gameId === 0)) {
        let betGroup = {
            index: host.PlayerBets.length,
            bets: action.payload.bets,
        } as BetGroup;
        let pendingBets = host.PlayerBets.concat(betGroup);
        return tableBetsAdapter.updateOne(state, {
            id: action.payload.hostId,
            changes: {
                PlayerBets: pendingBets,
            },
        });
    }
};
const undoBet = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BetPayload>
) => {
    const gameId = action.payload.gameId;
    const host = state.entities[action.payload.hostId];
    if (host && (host.GameId === gameId || gameId === 0)) {
        let undoBet = [...host.PlayerBets];
        undoBet.pop();
        return tableBetsAdapter.updateOne(state, {
            id: action.payload.hostId,
            changes: {
                PlayerBets: undoBet,
            },
        });
    }
};

const clearPendingBets = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<ClearPendingPayload>
) => {
    const gameId = action.payload.gameId;
    const host = state.entities[action.payload.hostId];
    if (host && (host.GameId === gameId || gameId === 0)) {
        if (action.payload.betSeqId !== undefined) {
            return tableBetsAdapter.updateOne(state, {
                id: action.payload.hostId,
                changes: {
                    PendingBets: [],
                    BetSeqId: action.payload.betSeqId,
                },
            });
        } else {
            return tableBetsAdapter.updateOne(state, {
                id: action.payload.hostId,
                changes: {
                    PendingBets: [],
                },
            });
        }
    }
};

const clearBets = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BasePayload>
) => {
    const gameId = action.payload.gameId;
    const host = state.entities[action.payload.hostId];
    if (host && (host.GameId === gameId || gameId === 0)) {
        return tableBetsAdapter.updateOne(state, {
            id: action.payload.hostId,
            changes: {
                GameId: host.GameId,
                PendingBets: [],
                PlayerBets: [],
                BetsSummary: [],
                ConfirmedBets: [],
                TableTotalBet: 0,
                PendingTotalBet: 0,
            },
        });
    }
};

const setWaitingBetResult = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BooleanPayload>
) => {
    const gameId = action.payload.gameId;
    const host = state.entities[action.payload.hostId];
    if (host && (host.GameId === gameId || gameId === 0)) {
        return tableBetsAdapter.updateOne(state, {
            id: action.payload.hostId,
            changes: {
                isWaitingBet: action.payload.value,
            },
        });
    }
};

const updateConfirmedBets = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<ConfirmedBetsPayload>
) => {
    const gameId = action.payload.gameId;
    const host = state.entities[action.payload.hostId];
    if (host && (host.GameId === gameId || gameId === 0)) {
        let total = 0;
        action.payload.bets.forEach(bet => {
            if (bet) {
                total += bet;
            }
        });
        return tableBetsAdapter.updateOne(state, {
            id: action.payload.hostId,
            changes: {
                ConfirmedBets: action.payload.bets,
                isWaitingBet: false,
                TableTotalBet: total,
                PendingTotalBet: 0,
            },
        });
    }
};

const doConfirmBets = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BooleanPayload>
) => {
    return tableBetsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            doConfirm: action.payload.value,
        },
    });
};

const doCancelBets = (
    state: EntityState<TableBet, number>,
    action: PayloadAction<BooleanPayload>
) => {
    return tableBetsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            doCancel: action.payload.value,
        },
    });
};

export const tableBetReducers = {
    initTable,
    initBets,
    startBet,
    addBet,
    undoBet,
    clearBets,
    clearPendingBets,
    setWaitingBetResult,
    updateConfirmedBets,
    doConfirmBets, //For non-ABC multibet
    doCancelBets, //For non-ABC multibet
};
