import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { betRoomSliceActions } from '../../../modules/bet/room/slice';
import { StandsPayload } from '../../games/BetRoom';
import { CMDScRoomLeft, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDScRoomLeftHandler extends AbstractHandler {
    _command: CMDScRoomLeft;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScRoomLeft;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
         const payload: StandsPayload = {
             HostId: this._command.HostID,
             Seats: this._command.Seat,
         };
         dispatch(betRoomSliceActions.stands(payload));
    }
}
