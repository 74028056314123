import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const SessionStorageKey = {
    ChipIdx: 'chip_index',
};

const getSessionValue = <T>(key: string, defaultValue: T): T => {
    const savedValue = sessionStorage.getItem(key);
    try {
        if (savedValue) {
            const initialValue = JSON.parse(savedValue);
            return (initialValue as T) || defaultValue;
        }
    } catch {
        console.warn(
            'app::hook::sessionstorage',
            'use default value',
            key,
            defaultValue
        );
    }
    return null || defaultValue;
};
const setSessionValue = <T>(key: string, value: T): void => {
    if (value == undefined) return;
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const useSession = <T>(
    key: string,
    defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(() =>
        getSessionValue<T>(key, defaultValue)
    );
    useEffect(() => {
        setSessionValue(key, value);
    }, [key, value]);
    return [value, setValue];
};
