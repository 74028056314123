import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDCsReportAgent implements ICommand
{

	public ReportType : number = 0;
	public Token : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ReportType = ba.readUint8();
		this.Token = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.ReportType);
		ba.writeString(this.Token);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDCsReportAgent();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ReportType(B)='+this.ReportType+' ';
		str += 'Token[33](C)='+this.Token+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 35003;
	}

	public getCmdName() : string
	{
		return 'CsReportAgent';
	}

}
