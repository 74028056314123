import {
    GamingCuracaoLogo,
    GamingLabLogo,
    // SaLoadingLogo,
    SaLoadingOverlayLogo,
} from '@/components/images';
import { useProgress } from '@/hooks/useProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NetState } from '../lobby/components/NetState';
import './index.scss';
// import './logo.scss';

export const LoadingProgressOverlay = () => (
    <div className="progress loading overlay">
        <SaLoadingOverlayLogo />
    </div>
);

export const LoadingProgress = () => {
    return (
        <Grid
            container
            width="100%"
            height="100%"
            justifyContent={'center'}
            className="self-align-center"
        >
            <LoadingLogo />
            <CertLogoGroup />
            <VersionLabel />
        </Grid>
    );
};
const LoadingLogo = () => (
    <div className="progress loading">
        {/* <SaLogo /> */}
        {/* <SaLoadingLogo /> */}
    </div>
);

const VersionLabel = () => {
    const { loadingCount, resultCount } = useProgress();
    return (
        <Grid container justifyContent={'center'} gap={'3px'}>
            <NetState fontSize={'18px'} color={'white'} />
            <Typography fontSize={'18px'} color={'white'}>
                ({loadingCount}.{resultCount})
            </Typography>
        </Grid>
    );
};

const CertLogoGroup = () => (
    <Grid container justifyContent={'space-evenly'} className="cert-logo">
        <Grid item>
            <GamingCuracaoLogo className="gaming-logo" />
        </Grid>
        <Grid item>
            <GamingLabLogo className="gaming-logo" />
        </Grid>
    </Grid>
);
