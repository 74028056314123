import { useEffect, useState } from 'react';
import {
    BET_SOURCE_ANDROID,
    BET_SOURCE_IOS,
    BET_SOURCE_MOBILE,
    BET_SOURCE_WEBSITE,
    BetSourceTypeId,
} from '../models/BetSource';
import { useUserAgent } from './useUserAgent';

const getBetSourceByOS = (isMultiBet: boolean, name: string = '') => {
    switch (name) {
        case 'PC':
            if (isMultiBet) {
                return BET_SOURCE_WEBSITE | BetSourceTypeId.BSIsMultiBet;
            }
            return BET_SOURCE_WEBSITE;
        case 'Android':
            if (isMultiBet) {
                return BET_SOURCE_ANDROID | BetSourceTypeId.BSIsMultiBet;
            }
            return BET_SOURCE_ANDROID;
        case 'iOS':
            if (isMultiBet) {
                return BET_SOURCE_IOS | BetSourceTypeId.BSIsMultiBet;
            }
            return BET_SOURCE_IOS;
        default:
            if (isMultiBet) {
                return BET_SOURCE_MOBILE | BetSourceTypeId.BSIsMultiBet;
            }
            return BET_SOURCE_MOBILE;
    }
};

export const useBetSource = (isMultiBet: boolean = false) => {
    /**
     * Possible 'os.name'
     * AIX, Amiga OS, Android, Arch, Bada, BeOS, BlackBerry, CentOS, Chromium OS, Contiki,
     * Fedora, Firefox OS, FreeBSD, Debian, DragonFly, Gentoo, GNU, Haiku, Hurd, iOS,
     * Joli, Linpus, Linux, Mac OS, Mageia, Mandriva, MeeGo, Minix, Mint, Morph OS, NetBSD,
     * Nintendo, OpenBSD, OpenVMS, OS/2, Palm, PCLinuxOS, Plan9, Playstation, QNX, RedHat,
     * RIM Tablet OS, RISC OS, Sailfish, Series40, Slackware, Solaris, SUSE, Symbian, Tizen,
     * Ubuntu, UNIX, VectorLinux, WebOS, Windows [Phone/Mobile], Zenwalk
     */

    /**
     * The ua-parse-js(v1.0.38) doesn't support the device.type === 'desktop'
     * so need to check isDesktop by ourselves
     *
     * https://github.com/faisalman/ua-parser-js/issues/182
     */
    const { os, device } = useUserAgent();
    const [bs, setBs] = useState(BET_SOURCE_MOBILE);
    useEffect(() => {
        const isDesktop =
            device.type === undefined ||
            !['wearable', 'mobile'].includes(device.type);
        const bsos = getBetSourceByOS(isMultiBet, isDesktop ? 'PC' : os.name);
        setBs(bsos);
    }, [os.name, device]);
    return bs;
};
