import { MAX_HAND, MAX_SPLIT } from './Constant';

export enum Phase {
    InitCard = 2,
    PlayerHand,
    BankerHand = PlayerHand + MAX_HAND,
}
export const isPlayerHand = (n: number) =>
    n >= Phase.PlayerHand && n < Phase.BankerHand;
export const isHand = (n: number) => isPlayerHand(n) || n == Phase.BankerHand;
export const getSeatNo = (p: number) =>
    isPlayerHand(p)
        ? Math.floor((p - Phase.PlayerHand) / MAX_SPLIT) + 1
        : undefined;
export const getSplit = (p: number) =>
    isPlayerHand(p) ? ((p - Phase.PlayerHand) % MAX_SPLIT) + 1 : 1;
export const isPlaying = (p: number) =>
    p >= Phase.InitCard && p <= Phase.BankerHand;
