import { createContext } from "react";
import { useGlobalData, useGlobalDataState } from "../hooks/useGlobalData";

//暫定放冇save server & local storage, re-login game 就會 del data
export const GlobalDataContext = createContext({} as useGlobalDataState);

type GlobalDataProviderProps = {
    children: JSX.Element;
};

export const GlobalDataProvider = (props: GlobalDataProviderProps) => {
    const { children } = props;
    const globalData = useGlobalData();
    return (
        <GlobalDataContext.Provider value={globalData}>
            {children}
        </GlobalDataContext.Provider>
    );
};
