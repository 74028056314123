import { AnyAction, Dispatch } from 'redux';
import { betRoomSliceActions } from '../../../modules/bet/room/slice';
import { BetRoomSeatIndex } from '../../games/BetRoom';
import { CMDScInitRoom, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitRoomHandler extends AbstractHandler {
    _command: CMDScInitRoom;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitRoom;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const hostId = this._command.HostID;
        const room = this._command.Room.at(0);
        if (room) {
            const players = room.PlayerID;
            players.forEach((id, index) => {
                const sn = (index + 1) as BetRoomSeatIndex;
                if (id !== 0)
                    dispatch(
                        betRoomSliceActions.seat({
                            HostId: hostId,
                            Seat: sn,
                            PlayerId: id,
                        })
                    );
                else
                    dispatch(
                        betRoomSliceActions.stand({ HostId: hostId, Seat: sn })
                    );
            });
        }
    }
}
