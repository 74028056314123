import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Bench } from 'tinybench';
import { progressTimeActions } from '../modules/progress/slice/time';

const data = [...Array(1000).keys()];
const bench = new Bench({ time: 200, warmupTime: 100 });

export const useBenchmark = () => {
    const dispatch = useDispatch();
    const doBenchmark = useCallback(async () => {
        bench.add('find 999', () => {
            data.find(d => d === 999);
        });
        await bench.warmup(); // make results more reliable, ref: https://github.com/tinylibs/tinybench/pull/50
        await bench.run();
    }, []);
    useEffect(() => {
        doBenchmark().then(() => {
            const benchmarks = bench.table()[0];
            if (benchmarks !== null) {
                const opssec = Number(
                    benchmarks['ops/sec'].toString().replace(/[","]/g, '')
                );
                console.log('app::benchmark', opssec);
                console.table(benchmarks);
                localStorage.setItem('benchmark', JSON.stringify(benchmarks));

                if (!isNaN(opssec))
                    dispatch(progressTimeActions.benchmark(opssec));
            }
        });
    }, []);
};
