import { CMDDealer } from "./CMDDealer"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScAnchorLogin implements ICommand
{

	public HostID : number = 0;
	public Anchor : Array<CMDDealer>;

	public constructor()
	{
		this.Anchor = new Array<CMDDealer>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		let AnchorSize:number = ba.readUint8();
		while (AnchorSize-- > 0)
		{
			let AnchorChild:CMDDealer = new CMDDealer();
			AnchorChild.fromBytes(ba);
			this.Anchor.push(AnchorChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint8(this.Anchor.length);
		for (const AnchorChild of this.Anchor)
		{
			let tempBuf = AnchorChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScAnchorLogin();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Anchor_size(B)='+this.Anchor.length + ' ';
		str += 'Anchor(CMDDealer)=[';
		for (let AnchorChild in this.Anchor)
		{
			if (<number><unknown>AnchorChild > 0) str += ', ';
			str += this.Anchor[AnchorChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30012;
	}

	public getCmdName() : string
	{
		return 'ScAnchorLogin';
	}

}
