import { AnyAction, Dispatch } from 'redux';
import { hostsSliceActions } from '../../../modules/host/slice';
import { BetTypeMaxBet } from '../../host/MaxBet';
import { CMDSpInitMaxBet, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpInitMaxBetHandler extends AbstractHandler {
    _command: CMDSpInitMaxBet;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpInitMaxBet;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const gameType = this._command.GameType;
        const maxBetList = this._command.MaxBetList.map(
            mbl =>
                ({
                    BetType: mbl.BetType,
                    MaxBet: mbl.MaxBet,
                }) as BetTypeMaxBet
        );

        dispatch(
            hostsSliceActions.updateMaxBetByGameType({
                gameType: gameType,
                maxBet: maxBetList,
            })
        );
    }
}
