import { GlobalDataContext } from '@/contexts/GlobalDataContext';
import { WebSocketContext } from '@/contexts/WebSocketContext';
import { CMDPsNbsBet, CMDPsRuleChange } from '@/models/cmd/live';
import { CMDBet } from '@/models/cmd/live/CMDBet';
import { GameType as GameTypeEnum } from '@/models/games/enums/GameType';
import { getHostById } from '@/modules/host/slice';
import {
    getMainLimitRedState,
    getMainUserState,
} from '@/modules/main/selector';
import { LimitRed } from '@/modules/main/slice/limitRed';
import { popupSliceActions } from '@/modules/popup/slice';
import { RootState } from '@/store/store';
import { limitRedFormatter } from '@/utils/formatter';
import { useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

type BetLimitOption = {
    index: number;
    limitRed: LimitRed;
    text: string;
    isChecked: boolean;
};
export const useBetLimit = () => {
    // const { hostId: videoID } = useContext(VideoPlayerContext);
    // const isMultiBet = useIsMultiBet();
    const [onChange, setOnChange] = useState<boolean>(false);
    const [isMultiBet, setIsMultiBet] = useState<boolean>(false);
    const [hostId, setHostId] = useState<number>(0);
    const { multiBetHostID, syncCMDAction, setSyncCMDAction } =
        useContext(GlobalDataContext);
    const { GameType, MaxBet } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [limitRed, setLimitRed] = useState<Array<LimitRed>>([]);
    const { sendCommand } = useContext(WebSocketContext);
    const [selectedLimitRed, setSelectedLimitRed] = useState<LimitRed>();
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const { CurrencyName: currencyName } = useSelector(getMainUserState);
    const [options, setOptions] = useState<Array<BetLimitOption>>([]);
    const isWaitingLimit = useRef<number>(-1);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isMultiBet) {
            setHostId(multiBetHostID);
        }
    }, [isMultiBet]);
    useEffect(() => {
        if (syncCMDAction) {
            console.log('????', syncCMDAction);
            // setHostId(syncCMDAction.hostId);
        }
    }, [syncCMDAction]);
    useEffect(() => {
        const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
            l => l.GameType === (isMultiBet ? GameTypeEnum.Baccarat : GameType)
        );
        const BetRule = limitRedByGame?.BetRule;
        let haveSet = false;
        if (limitRedByGame && BetRule) {
            let selBetRule: LimitRed;
            if (limitRedByGame.RuleToSelect) {
                selBetRule = limitRedByGame.RuleToSelect;
                haveSet = true;
                isWaitingLimit.current = -1;
                submitBetRule(selBetRule);
            } else {
                const last = BetRule.find(r => r.Selected === 1);
                if (last) {
                    selBetRule = last;
                    if (!MaxBet) {
                        haveSet = true;
                        isWaitingLimit.current = -1;
                        submitBetRule(selBetRule);
                    }
                } else {
                    selBetRule = BetRule[0];
                    haveSet = true;
                    submitBetRule(selBetRule);
                }
            }
            batch(() => {
                setSelectedLimitRed(selBetRule);
                setLimitRed(BetRule);
            });
        }
        if (!haveSet) {
            doSync();
        }
    }, [hostId, limitRedByGameType]); //handle switch table
    useEffect(() => {
        const blo = new Array<BetLimitOption>();
        let index = 0;
        for (const lr of limitRed) {
            const o: BetLimitOption = {
                index,
                text: limitRedFormatter(lr, currencyName),
                limitRed: lr,
                isChecked: selectedLimitRed === lr,
            };
            blo.push(o);
            index++;
        }
        setOptions(blo);
    }, [limitRed, currencyName, selectedLimitRed]);

    // useEffect(() => {
    //     isWaitingLimit.current = -1;
    // }, [GameType]);
    const submitBetRule = (lr: LimitRed) => {
        if (isWaitingLimit.current !== lr.RuleID) {
            const cmd = new CMDPsRuleChange();
            const gameType = isMultiBet ? GameTypeEnum.Baccarat : GameType;
            cmd.GameType = gameType;
            cmd.HostID = hostId;
            cmd.RuleIndex = lr.RuleID;
            sendCommand(cmd);
            isWaitingLimit.current = lr.RuleID;
        }
        doSync();
    };
    const doSync = () => {
        if (syncCMDAction && syncCMDAction.type === 'CMDPsNbsBet') {
            const data = syncCMDAction.cmd as CMDPsNbsBet;
            let cmd = new CMDPsNbsBet();
            cmd.BetSource = data.BetSource;
            cmd.betSeqID = data.betSeqID;
            cmd.betFormat = data.betFormat;
            cmd.gameID = data.gameID;
            cmd.bets = data.bets.map(v => {
                //missing function if not create
                let bet = new CMDBet();
                bet.betType = v.betType;
                bet.betAmount = v.betAmount;
                return bet;
            });
            sendCommand(cmd);
            setSyncCMDAction(undefined);
        }
    };
    const handleChangeLimitRed = (lr: LimitRed) => {
        if (hostId) {
            setSelectedLimitRed(lr);
            submitBetRule(lr);
            // extra change limit red handler
            // if (onChange !== undefined) onChange();
            setOnChange(true);

            dispatch(popupSliceActions.open('abc.change_bet_limit_client'));
        }
    };
    return {
        limitRed,
        selectedLimitRed,
        currencyName,
        options,
        onChange,
        setHostId,
        setIsMultiBet,
        handleChangeLimitRed,
        setOnChange,
    };
};

export type useBetLimitState = ReturnType<typeof useBetLimit>;
