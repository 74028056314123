
import { createContext } from 'react';
import useAudioPlay, { useAudioPlayState } from '../hooks/useAudioPlay';

export const AudioPlayContext = createContext({} as useAudioPlayState);
const { Provider } = AudioPlayContext;
type AudioPlayProviderOwnProps = {
    children: JSX.Element;
};
type AudioPlayProviderProps = AudioPlayProviderOwnProps;

export const AudioPlayProvider = (props: AudioPlayProviderProps) => {
    const { children } = props;
    const vp = useAudioPlay();
    return <Provider value={vp}>{children}</Provider>;
};