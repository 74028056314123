import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from './PromotionDialog';
import { useContext, useEffect, useRef, useState } from 'react';
import { PromotionScratchCard } from './PromotionScratchCard';
import { RootState } from '../../../../store/store';
import { getPromotionToolState } from '../../../main/selector';
import { useTranslation } from 'react-i18next';
import { calcLowHigh, numberFormat } from '../../../../utils/commonFunc';
import { dateToTimeLeftArray, getDisplayTimeZone } from '../PromotionUtil';
import {
    CMDPsPrizeRedeem,
    CMDPsQueryPromotionState,
} from '../../../../models/cmd/live';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { PromotionButton } from './PromotionButton';
import { useBalance } from '../../../../hooks/useBalance';
import { BaseTypography } from '../../../../components/common/baseText/BaseTypography';
import {
    getPromotionTool,
    PromotionToolEnum,
} from '@/components/images/base64/PromotionTool';
import { IcoBalance } from '@/modules/common/svg';
import { promotionPrizeGSB, getPromotionTitle } from '../PromotionObjectMapper';
import { ScreenContext } from '@/contexts/ScreenContext';
import { PromotionBubble } from './PromotionBubble';
import { popupSliceActions } from '../../slice';
import { usePromotionTool } from '@/hooks/popup/promotiontool/usePromotionTool';

export enum Prizes {
    Gold = 1,
    Silver,
    Bronze,
    AllDisabled,
}

export enum ScratchCardError {
    NotEnoughMoney = 6,
    InsertError = 39,
    UpdateError = 40,
    AmountZero = 68,
    OverMax = 128,
    NotInCorrectState = 181,
    InvalidPrize = 182,
    TrialPlayerLimitFunction = 190,
    EventEnded = 210,
    OverBudget = 211,
    InsufficientPoints = 212,
    PromotionPointAssignNotAllowed = 213,
    NotEventLobby = 214,
    EventNotFound = 215,
}

type GachaState = {
    gold: boolean;
    silver: boolean;
    bronze: boolean;
    curPrize: Prizes;
    list: PrizeListData;
    remainDayLimit: number;
    btnText: string;
    btnTextStyle: string;
    btn: string;
    onClick: () => void;
    scratchState: 'ready' | 'scratching' | 'scratched' | 'error';
};

export type PrizeListData = {
    PrizeID: number;
    PrizeRank: number;
    PointPerPlay: number;
    TopPrize: number;
};

export const PromotionGacha = () => {
    const { eventEnded } = usePromotionTool();
    const { isLandscape } = useContext(ScreenContext);
    const { t, i18n } = useTranslation();
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { balance, currency } = useBalance();
    const [time, setTime] = useState<string[]>(['']);
    const {
        Timezone,
        Point,
        PrizeList,
        RemainDays,
        MaxDayLimit,
        EndTimestamp,
        Result,
        IsOverBudget,
    } = useSelector((state: RootState) => getPromotionToolState(state));
    const displayTimezone = getDisplayTimeZone(Timezone);
    const curPoint = numberFormat(calcLowHigh(Point) / 100);
    const list: PrizeListData[] | undefined = PrizeList?.map(value =>
        JSON.parse(value)
    );
    const { sendCommand } = useContext(WebSocketContext);
    const [isBlock, setIsBlock] = useState(false);
    const [balanceEffect, setBalanceEffect] = useState(false);
    let listCopy: PrizeListData[] = [];
    if (list) listCopy = [...list];
    const SUCCESS_CODE = 0xff;
    const animDuration = 1430;
    const curPrizeID = useRef<number>(listCopy[2]?.PrizeID);
    const [eventEnd, setEventEnd] = useState(false);
    const [overBudget, setOverBudget] = useState(false);
    const effectTimeout = useRef<NodeJS.Timeout | undefined>();

    const btnScratch = {
        disabled: {
            btnText: t('promotion_tool.start'),
            btnTextStyle: 'btn_disabled',
            onClick: () => {},
            btn: 'btn_gold btn_invalid',
        },
        ready: {
            btnText: t('promotion_tool.start'),
            btnTextStyle: 'btn_scratch',
            btn: 'btn_gold',
            onClick: () => onScratch(),
        },
        scratching: {
            btnText: t('promotion_tool.start'),
            btnTextStyle: 'btn_disabled',
            btn: 'btn_gold btn_invalid',
            onClick: () => {},
        },
        scratched: {
            btnText: t('promotion_tool.rescratch'),
            btnTextStyle: 'btn_scratched',
            // btn: 'btn_black',
            btn: 'btn_gold',
            onClick: () => {
                updateState('ready');
                clearTimeout(effectTimeout.current);
                setBalanceEffect(false);
                effectTimeout.current = undefined;
            },
        },
        error: {
            general: {
                btnText: t('promotion_tool.try_again'),
                btnTextStyle: 'btn_scratched',
                btn: 'btn_black',
                onClick: () => updateState('ready'),
            },
            betNow: {
                btnText: t('promotion_tool.bet_now'),
                btnTextStyle: 'btn_scratch',
                btn: 'btn_gold',
                // btn: 'btn_black',
                onClick: () =>
                    dispatch(promotionToolSliceActions.isOpenDialog(false)),
            },
            limitReached: {
                btnText: t('promotion_tool.start'),
                btnTextStyle: 'btn_disabled',
                btn: 'btn_gold btn_invalid',
                onClick: () => {},
                curPrize: Prizes.AllDisabled,
            },
            eventEnd: {
                onClick: () => {},
                curPrize: Prizes.AllDisabled,
            },
        },
    };

    const [state, setState] = useState<GachaState>({
        gold: false,
        silver: false,
        bronze: true,
        curPrize: Prizes.Bronze,
        list: {
            ...listCopy[2],
        },
        remainDayLimit: RemainDays,
        ...btnScratch.ready,
        scratchState: 'ready',
    });

    const handleClick = (curState: Prizes, init?: boolean) => {
        if (!init && curState === state.curPrize) return;
        switch (curState) {
            case Prizes.Gold:
                curPrizeID.current = listCopy[0].PrizeID;
                clearBalanceEffect();
                setState(prev => ({
                    ...prev,
                    gold: true,
                    silver: false,
                    bronze: false,
                    curPrize: curState,
                    list: { ...listCopy[0] },
                    ...btnScratch.ready,
                    scratchState: 'ready',
                }));
                break;
            case Prizes.Silver:
                curPrizeID.current = listCopy[1].PrizeID;
                clearBalanceEffect();
                setState(prev => ({
                    ...prev,
                    gold: false,
                    silver: true,
                    bronze: false,
                    curPrize: curState,
                    list: { ...listCopy[1] },
                    ...btnScratch.ready,
                    scratchState: 'ready',
                }));
                break;
            case Prizes.Bronze:
                curPrizeID.current = listCopy[2].PrizeID;
                clearBalanceEffect();
                setState(prev => ({
                    ...prev,
                    gold: false,
                    silver: false,
                    bronze: true,
                    curPrize: curState,
                    list: { ...listCopy[2] },
                    ...btnScratch.ready,
                    scratchState: 'ready',
                }));
                break;
            case Prizes.AllDisabled:
                setState(prev => ({
                    ...prev,
                    gold: false,
                    silver: false,
                    bronze: false,
                    curPrize: curState,
                    ...btnScratch.disabled,
                    scratchState: 'error',
                }));
                break;
            default:
                break;
        }
    };

    const prizeInfo = (): string[] => {
        if (PrizeList !== undefined) {
            return PrizeList.map(value =>
                numberFormat(
                    JSON.parse(value)['PointPerPlay'] / 100,
                    0
                ).toString()
            );
        }
        return ['0', '0', '0'];
    };

    const clearBalanceEffect = () => {
        if (effectTimeout.current) {
            clearTimeout(effectTimeout.current);
            setBalanceEffect(false);
            effectTimeout.current = undefined;
        }
    };

    const prizeBtn = [
        {
            btnStyle: `${state.gold ? 'event_1st_selected' : 'event_1st_unselected'}`,
            text: t('promotion_tool.gold').toUpperCase(),
            textStyle: `${state.gold ? 'prize_selected_text' : 'gold_unselected_text'}`,
            onclick: () => handleClick(Prizes.Gold),
            gemPointStyle: `${state.gold ? 'selected_gem_point' : 'unselected_gem_point'}`,
        },
        {
            btnStyle: `${state.silver ? 'event_2nd_selected' : 'event_2nd_unselected'}`,
            text: t('promotion_tool.silver').toUpperCase(),
            textStyle: `${state.silver ? 'prize_selected_text' : 'silver_unselected_text'}`,
            onclick: () => handleClick(Prizes.Silver),
            gemPointStyle: `${state.silver ? 'selected_gem_point' : 'unselected_gem_point'}`,
        },
        {
            btnStyle: `${state.bronze ? 'event_3rd_selected' : 'event_3rd_unselected'}`,
            text: t('promotion_tool.bronze').toUpperCase(),
            textStyle: `${state.bronze ? 'prize_selected_text' : 'bronze_unselected_text'}`,
            onclick: () => handleClick(Prizes.Bronze),
            gemPointStyle: `${state.bronze ? 'selected_gem_point' : 'unselected_gem_point'}`,
        },
    ];

    const updateState = (s: string) => {
        switch (s) {
            case 'ready':
                setIsBlock(false);
                setState(prev => ({
                    ...prev,
                    scratchState: 'ready',
                    ...btnScratch.ready,
                }));
                break;
            case 'scratching':
                setIsBlock(true);
                playBalanceEffect(3000);
                setState(prev => ({
                    ...prev,
                    scratchState: 'scratching',
                    ...btnScratch.scratching,
                    remainDayLimit: RemainDays,
                }));
                setTimeout(() => {
                    updateState('scratched');
                }, animDuration);
                break;
            case 'scratched':
                setIsBlock(false);
                setState(prev => ({
                    ...prev,
                    scratchState: 'scratched',
                    ...btnScratch.scratched,
                }));
                break;
            case 'error':
                setIsBlock(false);
                setState(prev => ({ ...prev, scratchState: 'error' }));
                break;
            default:
                break;
        }
    };

    const onScratch = () => {
        if (state.curPrize !== Prizes.AllDisabled) {
            setIsBlock(true);
            dispatch(promotionToolSliceActions.clearRedeemResult());
            const cmd = new CMDPsPrizeRedeem();
            cmd.PrizeID = curPrizeID.current;
            sendCommand(cmd);
        }
    };

    const initialChecked = () => {
        dispatch(promotionToolSliceActions.isLoading(true));
        if (eventEnded()) {
            setEventEnd(true);
            handleResult(ScratchCardError.EventEnded);
            handleClick(Prizes.AllDisabled, true);
            return;
        }
        const cmd = new CMDPsQueryPromotionState();
        sendCommand(cmd);
        if (list !== undefined) {
            if (MaxDayLimit > 0 && state.remainDayLimit <= 0) {
                handleClick(Prizes.AllDisabled, true);
                setState(prev => ({ ...prev, remainDayLimit: 0 }));
            } else {
                handleClick(Prizes.Bronze, true);
            }
        }
    };

    useEffect(() => {
        initialChecked();
        const updateTimer = () =>
            setTime(dateToTimeLeftArray(EndTimestamp, displayTimezone));
        updateTimer();
        const timer = setInterval(() => {
            if (eventEnded()) {
                setEventEnd(true);
                handleResult(ScratchCardError.EventEnded);
                clearInterval(timer);
            } else updateTimer();
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (!eventEnded()) {
            if (IsOverBudget > 0) {
                setOverBudget(true);
                handleResult(ScratchCardError.OverBudget);
                handleClick(Prizes.AllDisabled, true);
            }
        }
        dispatch(promotionToolSliceActions.isLoading(false));
    }, [IsOverBudget]);

    useEffect(() => {
        if (MaxDayLimit > 0 && RemainDays > -1)
            setState(prev => ({ ...prev, remainDayLimit: RemainDays }));
    }, [RemainDays]);

    useEffect(() => {
        if (Result > 0) {
            handleResult(Result);
        }
    }, [Result]);

    const handleResult = (result: number) => {
        switch (result) {
            case SUCCESS_CODE:
                updateState('scratching');
                break;
            case ScratchCardError.InsufficientPoints:
                updateState('error');
                setState(prev => ({ ...prev, ...btnScratch.error.betNow }));
                break;
            case ScratchCardError.OverBudget:
                updateState('error');
                setState(prev => ({ ...prev, ...btnScratch.error.general }));
                break;
            case ScratchCardError.EventEnded:
                updateState('error');
                setState(prev => ({ ...prev, ...btnScratch.error.eventEnd }));
                break;
            case ScratchCardError.OverMax:
                updateState('error');
                setState(prev => ({
                    ...prev,
                    ...btnScratch.error.limitReached,
                }));
                break;
            default:
                updateState('error');
                setState(prev => ({ ...prev, ...btnScratch.error.general }));
                break;
        }
    };

    const playBalanceEffect = (duration: number) => {
        setBalanceEffect(true);
        const effect = setTimeout(() => {
            setBalanceEffect(false);
        }, duration);
        effectTimeout.current = effect;
    };

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Box className="event-title">
                    {/* <BaseImage
                        className={'event_title event_title'}
                        isMultiLang={true}
                        scale={1}
                    /> */}
                    <Box>
                        {getPromotionTool(
                            getPromotionTitle(
                                `event_title_${i18n.language.replace(/-/g, '_')}`
                            )
                        )}
                    </Box>
                </Box>
                <Box className="gacha-content">
                    <Box>
                        {isBlock ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    backgroundColor: '#000000',
                                    width: '540px',
                                    height: '900px',
                                    transform: `translate(-50%, -50%) `,
                                    zIndex: '10',
                                    opacity: '0',
                                }}
                            ></Box>
                        ) : (
                            <></>
                        )}

                        <Box
                            sx={{
                                backgroundColor: '#bea57b',
                                width: '100%',
                                height: '2px',
                            }}
                        ></Box>
                        <Box
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.15)',
                                width: '100%',
                                height: '540px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '100%' }}>
                                <Box className="gacha-total-point">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.45)',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '8px 12px',
                                            borderRadius: '100px',
                                            position: 'relative',
                                        }}
                                    >
                                        {/* <BaseImage
                                            className="promotion_tool event_gem"
                                            scale={1}
                                        /> */}
                                        <Box sx={{ display: 'flex' }}>
                                            {getPromotionTool(
                                                PromotionToolEnum.event_gem
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: '4px',
                                                fontSize: '18px',
                                                lineHeight: '100%',
                                            }}
                                        >
                                            {curPoint}
                                        </Box>
                                        <PromotionBubble
                                            direction="bottom"
                                            spacing={-3}
                                            color={'#000000'}
                                            zIndex={1}
                                        />
                                    </Box>
                                </Box>
                                <Stack
                                    sx={{ position: 'relative', top: '20px' }}
                                    direction={'row'}
                                    justifyContent={'center'}
                                    spacing={'15px'}
                                >
                                    {/* <Box>
                                        <BaseImage
                                            className="promotion_tool event_level_arrow"
                                            scale={1}
                                        />
                                    </Box> */}
                                    <Box>
                                        {getPromotionTool(
                                            PromotionToolEnum.event_level_arrow
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            color: '#C6C6C6',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('promotion_tool.choose_level')}
                                    </Box>
                                    {/* <Box>
                                        <BaseImage
                                            className="promotion_tool event_level_arrow"
                                            scale={1}
                                        />
                                    </Box> */}
                                    <Box>
                                        {getPromotionTool(
                                            PromotionToolEnum.event_level_arrow
                                        )}
                                    </Box>
                                </Stack>

                                <Stack
                                    sx={{ position: 'relative', top: '20px' }}
                                    direction={'row'}
                                    justifyContent={'center'}
                                    spacing={'25px'}
                                >
                                    {prizeBtn.map((value, index) => (
                                        <Box key={`prize-button-${index}`}>
                                            <PromotionButton
                                                // className={`promotion_tool ${value.btnStyle}`}
                                                sx={{
                                                    display: 'block',
                                                    maxHeight: 170,
                                                    padding: 0,
                                                }}
                                                onClick={
                                                    state.curPrize !==
                                                    Prizes.AllDisabled
                                                        ? value.onclick
                                                        : () => {}
                                                }
                                                disableRipple
                                            >
                                                <Box>
                                                    {getPromotionTool(
                                                        promotionPrizeGSB[
                                                            value.btnStyle
                                                        ]
                                                    )}
                                                </Box>
                                                <Box
                                                    className={`gacha_prize_text ${value.textStyle}`}
                                                >
                                                    {value.text}
                                                </Box>
                                                <Box
                                                    className={`gacha_prize_text ${value.gemPointStyle}`}
                                                >
                                                    {/* <BaseImage
                                                        className="promotion_tool event_gem"
                                                        scale={1}
                                                    /> */}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        {getPromotionTool(
                                                            PromotionToolEnum.event_gem
                                                        )}
                                                    </Box>
                                                    <BaseTypography
                                                        resize={{
                                                            direction:
                                                                'horizontal',
                                                            value: '91px',
                                                        }}
                                                    >
                                                        {prizeInfo()[index]}
                                                    </BaseTypography>
                                                </Box>
                                            </PromotionButton>
                                        </Box>
                                    ))}
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={0.5}
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        top: '23px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: '#BF9A5D',
                                            fontSize: '18px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        {`${t('promotion_tool.top_prize')}`}
                                    </Box>
                                    <Box
                                        sx={{
                                            color: '#BF9A5D',
                                            fontSize: '18px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        {`${numberFormat(state.list.TopPrize / 100, 2)} ${currency}`}
                                    </Box>
                                </Stack>

                                <PromotionScratchCard
                                    curPrize={state.curPrize}
                                    prizeList={state.list}
                                    dayLimit={state.remainDayLimit}
                                    scratchState={state.scratchState}
                                    animDuration={animDuration}
                                    eventEnded={eventEnd}
                                    overBudget={overBudget}
                                />

                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        top: isLandscape ? '290px' : '258px',
                                    }}
                                >
                                    {!eventEnd && !overBudget ? (
                                        <PromotionButton
                                            sx={{
                                                position: 'relative',
                                            }}
                                            onClick={() => state.onClick()}
                                            disableRipple
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    whiteSpace: 'nowrap',
                                                }}
                                                className={`${state.btn} ${state.btnTextStyle}`}
                                            >
                                                {state.btnText}
                                            </Box>
                                        </PromotionButton>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: '#bea57b',
                                width: '100%',
                                height: '2px',
                            }}
                        ></Box>
                    </Box>

                    <Box className="gacha-details">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: MaxDayLimit > 0 ? '2px' : '8px',
                            }}
                        >
                            {MaxDayLimit > 0 ? (
                                <Stack
                                    className="gacha-redeemed-times"
                                    direction={'row'}
                                >
                                    <Box sx={{ color: '#BF9A5D' }}>
                                        {t('promotion_tool.redeemed_times')}{' '}
                                    </Box>
                                    <Box
                                        sx={{
                                            paddingLeft: '4px',
                                            paddingRight: '4px',
                                        }}
                                    >
                                        {MaxDayLimit - state.remainDayLimit}
                                    </Box>
                                    <Box
                                        sx={{ color: '#BF9A5D' }}
                                    >{`/ ${MaxDayLimit}`}</Box>
                                </Stack>
                            ) : (
                                <></>
                            )}

                            <Stack
                                className="gacha-remain-times"
                                direction={'row'}
                                // height={MaxDayLimit > 0 ? '' : '42px'}
                            >
                                {/* <BaseImage
                                    className="promotion_tool event_icn_clock"
                                    scale={1}
                                /> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                    }}
                                >
                                    {getPromotionTool(
                                        PromotionToolEnum.event_icn_clock
                                    )}
                                </Box>
                                <Box
                                    sx={{ marginLeft: '4px' }}
                                >{`${time[0]}d ${time[1]}:${time[2]}:${time[3]}`}</Box>
                            </Stack>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box>
                                    <Stack
                                        className="gacha-total-balance"
                                        direction={'row'}
                                    >
                                        {/* <BaseImage
                                            className="commonUI ic_balance_brown"
                                            scale={1}
                                        /> */}
                                        <Box display={'flex'}>
                                            <IcoBalance
                                                color="bf9a5d"
                                                width={20}
                                                height={20}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: '6px',
                                                fontSize: '18px',
                                            }}
                                        >{`${numberFormat(balance, 2)} ${currency}`}</Box>
                                        {eventEnd ? (
                                            <></>
                                        ) : balanceEffect ? (
                                            <Box className="balance_effect"></Box>
                                        ) : (
                                            <></>
                                        )}
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Stack className="page-buttons" direction={'row'} spacing={'20px'}>
                <PromotionButton
                    className="button_black"
                    onClick={() => {
                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Record
                            )
                        );
                    }}
                >
                    {t('promotion_tool.records')}
                </PromotionButton>
                <PromotionButton
                    className="button_black"
                    onClick={() => {
                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Ranking
                            )
                        );
                    }}
                >
                    {t('promotion_tool.rankings')}
                </PromotionButton>
            </Stack>
        </>
    );
};
