import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpRedeemResult, ICommand } from "../../live";
import { AbstractHandler } from "../AbstractHandler";
import { PromotionState, promotionToolSliceActions } from '../../../../modules/main/slice/promotiontool/promotionTool';

export class CMDSpRedeemResultHandler extends AbstractHandler {
    _command: CMDSpRedeemResult;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpRedeemResult;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const cmd = this._command;
        // console.log('command::handler::spredeemresult', cmd);
        const data = {} as PromotionState;
        data.Result = cmd.Result;
        data.ResultAmount = cmd.ResultAmount;
        data.TotalRedeemAmount = cmd.TotalRedeemAmount;
        data.RemainDayLimit = cmd.RemainDayLimit;

        dispatch(promotionToolSliceActions.updateRedeemResult(data));
    }
}