import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { BetRoom, BetRoomSeatIndex } from '../../../models/games/BetRoom';
import { RootState } from '../../../store/store';
import { betRoomReducer } from './reducer';
// slice
export const BET_ROOM_KEY = 'app::bet::room';
export const betRoomAdapter = createEntityAdapter<BetRoom, number>({
    selectId: br => br.HostId,
    sortComparer: (a, b) => a.HostId - b.HostId,
});
const initialBetRoomState = betRoomAdapter.getInitialState();
const betRoomSlice = createSlice({
    name: BET_ROOM_KEY,
    initialState: initialBetRoomState,
    reducers: {
        init: betRoomAdapter.upsertOne,
        update: betRoomAdapter.updateOne,
        ...betRoomReducer,
    },
});
export const betRoomSliceReducer = betRoomSlice.reducer;
export const betRoomSliceActions = betRoomSlice.actions;
// selector
export const getBetRoomSelectors = betRoomAdapter.getSelectors<RootState>(
    state => state[BET_ROOM_KEY]
);
export const getBetRoom = getBetRoomSelectors.selectById;
export const getBetByHostPlayer = (
    state: RootState,
    hostId: number,
    seat: BetRoomSeatIndex
) => getBetRoomSelectors.selectById(state, hostId)?.Info[seat].Bet ?? [];
export const isPlayerSeated = (
    state: RootState,
    hostId: number,
    playerId: number
) => {
    const betRoom = getBetRoomSelectors.selectById(state, hostId);
    if (betRoom) {
        let seatPlayer = Object.values(betRoom.Info)
            .map(i => i.PlayerId)
            .slice(1);

        return seatPlayer.includes(playerId);
    }
    return false;
};
export const getPlayerSeatNo = (
    state: RootState,
    hostId: number,
    playerId: number
) => {
    const betRoom = getBetRoomSelectors.selectById(state, hostId);
    if (betRoom) {
        for (let i = 1; i <= 7; i++) {
            const sn = i as BetRoomSeatIndex;
            if (betRoom.Info[sn].PlayerId === playerId) return sn;
        }
        return undefined;
    }
};
