import { CMDSeatBet } from "./CMDSeatBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpRoomBet implements ICommand
{

	public GameID : number = 0;
	public SeatBet : Array<CMDSeatBet>;

	public constructor()
	{
		this.SeatBet = new Array<CMDSeatBet>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		let SeatBetSize:number = ba.readUint8();
		while (SeatBetSize-- > 0)
		{
			let SeatBetChild:CMDSeatBet = new CMDSeatBet();
			SeatBetChild.fromBytes(ba);
			this.SeatBet.push(SeatBetChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint8(this.SeatBet.length);
		for (const SeatBetChild of this.SeatBet)
		{
			let tempBuf = SeatBetChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpRoomBet();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'SeatBet_Size(B)='+this.SeatBet.length + ' ';
		str += 'SeatBet(CMDSeatBet)=[';
		for (let SeatBetChild in this.SeatBet)
		{
			if (<number><unknown>SeatBetChild > 0) str += ', ';
			str += this.SeatBet[SeatBetChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50039;
	}

	public getCmdName() : string
	{
		return 'SpRoomBet';
	}

}
