import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const PROGRESS_STATUS_KEY = 'app::progress::status';
export type ProgressStatusState = {
    end: boolean;
};
const initialProgressStatusState = {
    end: false,
} as ProgressStatusState;
const updateStatus = (
    state: ProgressStatusState,
    action: PayloadAction<boolean>
) => {
    state.end = action.payload;
};
const progressStatus = createSlice({
    name: PROGRESS_STATUS_KEY,
    initialState: initialProgressStatusState,
    reducers: {
        updateStatus,
    },
});
export const progressStatusReducer = progressStatus.reducer;
export const progressStatusActions = progressStatus.actions;
