export { ReactComponent as ChangeRoadAB } from './ChangeRoadAB.svg';
export { ReactComponent as ChangeRoadB } from './ChangeRoadB.svg';
export { ReactComponent as ChangeRoadNum } from './ChangeRoadNum.svg';
export { ReactComponent as ColdIcon } from './ColdIcon.svg';
export { ReactComponent as FavoriteBG } from './FavoriteBG.svg';
export { ReactComponent as HotIcon } from './HotIcon.svg';
export { ReactComponent as IcoBackBtn } from './IcoBackBtn.svg';
export { ReactComponent as IcoBalance } from './IcoBalance.svg';
export { ReactComponent as IcoTotalBet } from './IcoTotalBet.svg';
export { ReactComponent as ChangeRoadSeDie } from './ChangeRoadSeDie.svg';
