import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    BetStatisticsState,
    betStatisticsSliceActions,
} from '../../../modules/report/slice/betStatistics';
import { CMDSpPlayerReport, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpPlayerReportHandler extends AbstractHandler {
    _command: CMDSpPlayerReport;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPlayerReport;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const data = {} as BetStatisticsState;
        data.Turnover = this._command.TotalBet / 100;
        data.ValidBetAmount = this._command.TotalWash / 100;
        data.WinLossAmount = this._command.TotalResult / 100;
        data.TotalAmount = this._command.Total / 100;
        dispatch(betStatisticsSliceActions.updateStatistics(data));
    }
}
