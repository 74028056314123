import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBaccaratStatistics implements ICommand
{

	public TieCount : number = 0;
	public PlayerWinCount : number = 0;
	public BankerWinCount : number = 0;
	public PlayerPairCount : number = 0;
	public BankerPairCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.TieCount = ba.readUint8();
		this.PlayerWinCount = ba.readUint8();
		this.BankerWinCount = ba.readUint8();
		this.PlayerPairCount = ba.readUint8();
		this.BankerPairCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.TieCount);
		ba.writeUint8(this.PlayerWinCount);
		ba.writeUint8(this.BankerWinCount);
		ba.writeUint8(this.PlayerPairCount);
		ba.writeUint8(this.BankerPairCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBaccaratStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'TieCount(B)='+this.TieCount+' ';
		str += 'PlayerWinCount(B)='+this.PlayerWinCount+' ';
		str += 'BankerWinCount(B)='+this.BankerWinCount+' ';
		str += 'PlayerPairCount(B)='+this.PlayerPairCount+' ';
		str += 'BankerPairCount(B)='+this.BankerPairCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BaccaratStatistics';
	}

}
