import { CMDPromotionRank } from "./CMDPromotionRank"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPromotionRanking implements ICommand
{

	public UTCUpdateTime : number = 0;
	public RankCount : number = 0;
	public RankData : Array<CMDPromotionRank>;
	public PlayerRank : number = 0;
	public PlayerAmount : number = 0;

	public constructor()
	{
		this.RankData = new Array<CMDPromotionRank>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.UTCUpdateTime = ba.readUint53();
		this.RankCount = ba.readUint16();
		let RankDataSize:number = ba.readUint16();
		while (RankDataSize-- > 0)
		{
			let RankDataChild:CMDPromotionRank = new CMDPromotionRank();
			RankDataChild.fromBytes(ba);
			this.RankData.push(RankDataChild);
		}
		this.PlayerRank = ba.readUint16();
		this.PlayerAmount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.UTCUpdateTime);
		ba.writeUint16(this.RankCount);
		ba.writeUint16(this.RankData.length);
		for (const RankDataChild of this.RankData)
		{
			let tempBuf = RankDataChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint16(this.PlayerRank);
		ba.writeUint53(this.PlayerAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPromotionRanking();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'UTCUpdateTime(Q)='+this.UTCUpdateTime+' ';
		str += 'RankCount(W)='+this.RankCount+' ';
		str += 'RankData_size(W)='+this.RankData.length + ' ';
		str += 'RankData(CMDPromotionRank)=[';
		for (let RankDataChild in this.RankData)
		{
			if (<number><unknown>RankDataChild > 0) str += ', ';
			str += this.RankData[RankDataChild].toString();
		}
		str += '] ';
		str += 'PlayerRank(W)='+this.PlayerRank+' ';
		str += 'PlayerAmount(Q)='+this.PlayerAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50121;
	}

	public getCmdName() : string
	{
		return 'SpPromotionRanking';
	}

}
