import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { BaccaratStatistics } from '../../games/baccarat';
import { CMDSpBaccaratStatistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpBaccaratStatisticsHandler extends AbstractHandler {
    _command: CMDSpBaccaratStatistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBaccaratStatistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as BaccaratStatistics;
        const hostId = this._command.HostID;
        statistics.BankerPairCount = this._command.Statistics.BankerPairCount;
        statistics.BankerWinCount = this._command.Statistics.BankerWinCount;
        statistics.PlayerPairCount = this._command.Statistics.PlayerPairCount;
        statistics.PlayerWinCount = this._command.Statistics.PlayerWinCount;
        statistics.TieCount = this._command.Statistics.TieCount;
        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
