import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpTeenPatti2020Statistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { TeenPattiStatistics } from '../../games/teenpatti';
import { hostsSliceActions } from '../../../modules/host/slice';

export class CMDSpTeenPattiStatisticsHandler extends AbstractHandler {
    _command: CMDSpTeenPatti2020Statistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpTeenPatti2020Statistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as TeenPattiStatistics;
        const hostId = this._command.HostID;

        statistics.TieCount = this._command.Statistics.TieCount;
        statistics.PlayerAWinCount = this._command.Statistics.PlayerAWinCount;
        statistics.PlayerBWinCount = this._command.Statistics.PlayerBWinCount;

        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
