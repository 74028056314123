import { betGameSliceActions } from '@/modules/bet/game/slice';
import { routeSliceActions } from '@/modules/routeController/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { betRoomSliceActions } from '../../../modules/bet/room/slice';
import { SupportedRecord } from '../../../modules/host/models';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { convertResultStringToRecord as convertAndarBaharResultStringToRecord } from '../../../utils/games/andarBahar';
import { convertResultStringToRecord } from '../../../utils/games/baccarat';
import { convertResultToRecord as convertBlackjackResultToRecord } from '../../../utils/games/blackjack';
import { convertResultStringToRecord as DTConvertResultStringToRecord } from '../../../utils/games/dragonTiger';
import {
    convertPDResultStringToRecord,
    parseDeckData,
} from '../../../utils/games/pokdeng';
import { convertResultStringToRecord as convertRouletteResultStringToRecord } from '../../../utils/games/roulette';
import { convertSedieResultStringToRecord } from '../../../utils/games/sedie';
import { convertResultStringToRecord as convertSicBoResultStringToRecord } from '../../../utils/games/sicbo';
import { convertTeenPattiResultStringToRecord } from '../../../utils/games/teenpatti';
import { BlackjackResult } from '../../games/blackjack';
import { GameState } from '../../games/enums/GameState';
import { GameType as GameTypeEnum } from '../../games/enums/GameType';
import { PokDengResult } from '../../games/pokdeng';
import { RouletteResult } from '../../games/roulette';
import { CMDScGameResult, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
// import { gameSliceActions } from '../../../modules/games/slice';

export class CMDScGameResultHandler extends AbstractHandler {
    _command: CMDScGameResult;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScGameResult;
    }

    commandDelay() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        return host.DelayResult;
    }
    delayKey() {
        return this._command.GameID.toString();
    }
    forceRun() {
        return this._command.GameID.toString();
    }
    // forceRun() {
    //     const state = store.getState();
    //     const host = getHostByGameId(state, this._command.GameID);
    //     if (host.GameType == GameTypeEnum.PokDeng) {
    //         return this._command.GameID.toString() + '_2';
    //     }
    //     return '';
    // }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const gameId = this._command.GameID;
        const result = this._command.GameResult;
        const resultString = this._command.ResultString;
        const state = store.getState();

        const host = getHostByGameId(state, gameId);
        let isShuffing =
            host &&
            host.CurrentState === GameState.Shuffle &&
            host.GameType !== GameTypeEnum.TeenPatti2020 &&
            host.GameType !== GameTypeEnum.AndarBahar;

        if (host) {
            console.log(`hook::socket::run::${host.HostId}`, this._command);

            // Handle delay
            // TODO: Handle 'forceRun' case
            let records = new Array<SupportedRecord>();
            if (host.Record) records = records.concat(host.Record);
            if (host.CurrentResult) {
                const existRecord =
                    records.findIndex(r => r.gameID === gameId) >= 0;
                switch (host.GameType) {
                    case GameTypeEnum.Baccarat:
                        !existRecord &&
                            records.push(
                                convertResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    result
                                )
                            );
                        break;
                    case GameTypeEnum.Dragon:
                        !existRecord &&
                            records.push(
                                DTConvertResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    result
                                )
                            );
                        break;
                    case GameTypeEnum.PokDeng:
                        !existRecord &&
                            records.push(
                                convertPDResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    this._command.FGameResult.toString(),
                                    result
                                )
                            );
                        batch(() => {
                            dispatch(
                                hostsSliceActions.updateGameResultObject({
                                    hostId: host.HostId,
                                    result: Object.assign({
                                        GameID: this._command.GameID,
                                        GameCount:
                                            host.CurrentResult?.GameCount,
                                        Result: 0,
                                        ResultString:
                                            this._command.ResultString,
                                        fullDeck: parseDeckData(
                                            this._command.ResultString
                                        ),
                                    } as PokDengResult),
                                })
                            );
                            dispatch(
                                hostsSliceActions.updatePokerShow({
                                    hostId: host.HostId,
                                    results: [],
                                })
                            );
                        });
                        break;
                    case GameTypeEnum.SeDie:
                        !existRecord &&
                            records.push(
                                convertSedieResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    result
                                )
                            );
                        if (records.length > 200) {
                            records = records.slice(48);
                        }
                        break;
                    case GameTypeEnum.Blackjack:
                        !existRecord &&
                            records.push(
                                convertBlackjackResultToRecord(
                                    JSON.parse(resultString) as BlackjackResult,
                                    resultString,
                                    result
                                )
                            );
                        break;
                    case GameTypeEnum.Roulette:
                        !existRecord &&
                            records.push(
                                convertRouletteResultStringToRecord(
                                    host.CurrentResult as RouletteResult,
                                    resultString,
                                    result
                                )
                            );
                        break;
                    case GameTypeEnum.SicBo:
                        !existRecord &&
                            records.push(
                                convertSicBoResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    this._command.FGameResult,
                                    result
                                )
                            );
                        break;
                    case GameTypeEnum.TeenPatti2020:
                        !existRecord &&
                            records.push(
                                convertTeenPattiResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    this._command.FGameResult,
                                    result
                                )
                            );
                        if (records.length > 100) {
                            records = records.slice(48);
                        }
                        break;
                    case GameTypeEnum.AndarBahar:
                        !existRecord &&
                            records.push(
                                convertAndarBaharResultStringToRecord(
                                    host.CurrentResult,
                                    resultString,
                                    this._command.FGameResult,
                                    result
                                )
                            );
                        break;
                }
            }

            let shuffleTimer: NodeJS.Timeout;
            const defaultShuffleDelay = 5000;
            switch (host.GameType) {
                case GameTypeEnum.TeenPatti2020:
                case GameTypeEnum.AndarBahar:
                    shuffleTimer = setTimeout(
                        () => {
                            dispatch(
                                hostsSliceActions.updateGameState({
                                    hostId: host.HostId,
                                    state: GameState.Shuffle,
                                })
                            );
                        },
                        host.ShuffleDelay
                            ? host.ShuffleDelay
                            : defaultShuffleDelay
                    );
                    break;
            }

            batch(() => {
                switch (host.GameType) {
                    case GameTypeEnum.Blackjack:
                        dispatch(
                            hostsSliceActions.updateCountDown({
                                hostId: host.HostId,
                                countDown: 0,
                                canLeave: true,
                            })
                        );
                        break;
                }
                if (host.GameStateTimer && host.GameStateTimer.length > 0) {
                    host.GameStateTimer.map(d => {
                        clearTimeout(d[0]);
                    });
                    dispatch(
                        hostsSliceActions.updateGameState({
                            hostId: host.HostId,
                            state: host.GameStateTimer[
                                host.GameStateTimer.length - 1
                            ][1],
                        })
                    );
                }
                dispatch(
                    hostsSliceActions.updateGameResult({
                        hostId: host.HostId,
                        result,
                        resultString,
                        resultReleased: true,
                        shuffleTimer,
                    })
                );
                if (isShuffing) {
                    console.warn(
                        'hook::socket::warning::result::after::shuffle'
                    );
                    dispatch(routeSliceActions.toReconnect(host.HostId));
                } else {
                    dispatch(
                        hostsSliceActions.updateRecord({
                            hostId: host.HostId,
                            record: records,
                            gameType: host.GameType,
                        })
                    );
                }
                dispatch(
                    hostsSliceActions.updatePokerShow({
                        hostId: host.HostId,
                        results: [],
                    })
                );
                // dispatch(gameSliceActions.resetBets(this._command.GameID));
                dispatch(betRoomSliceActions.resetBet(host.HostId));
                dispatch(betGameSliceActions.clearBetGame(gameId));
            });
        } else {
            console.warn(
                'hook::socket::warning::cannot::find::host',
                this._command.GameID
            );
        }
    }
}
