import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const RESULT_KEY = 'app::main::result';
export type ResultState = {
    isSetChips?: boolean;
    isRuleChange?: boolean; //TODO: CMDSpRuleChange
    isPasswordChange?: boolean;
};

const initialResultState = {
    isSetChips: false,
    isRuleChange: false,
    isPasswordChange: false,
} as ResultState;

const updateData = (state: ResultState, action: PayloadAction<ResultState>) => {
    state.isSetChips = action.payload.isSetChips || false;
    state.isRuleChange = action.payload.isRuleChange || false;
    state.isPasswordChange = action.payload.isPasswordChange || false;
};
const reset = (state: ResultState) => {
    state.isSetChips = false;
    state.isRuleChange = false;
    state.isPasswordChange = false;
};

const resultSlice = createSlice({
    name: RESULT_KEY,
    initialState: initialResultState,
    reducers: {
        updateData,
        reset,
    },
});
export const resultSliceReducer = resultSlice.reducer;
export const resultSliceActions = resultSlice.actions;
