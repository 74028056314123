import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import 'moment/locale/ar';
// import 'moment/locale/bn';
// import 'moment/locale/es';
// import 'moment/locale/fa';
// import 'moment/locale/hi';
// import 'moment/locale/id';
// import 'moment/locale/ja';
// import 'moment/locale/ko';
// import 'moment/locale/ms';
// import 'moment/locale/pt';
// import 'moment/locale/pt-br';
// import 'moment/locale/te';
// import 'moment/locale/th';
// import 'moment/locale/vi';
// import 'moment/locale/zh-cn';
// import 'moment/locale/zh-tw';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocaleByCode } from '../models/Locale';
type DatePickerLocalizationProviderProps = {
    children: ReactNode;
};
export const DatePickerLocalizationProvider = ({
    children,
}: DatePickerLocalizationProviderProps) => {
    const { i18n } = useTranslation();
    const [currentLocale, setCurrentLocale] = useState('en');
    useEffect(() => {
        const locale = getLocaleByCode('long', i18n.language);
        const mc = locale.moment;
        setCurrentLocale(mc);
    }, [i18n.language]);
    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={currentLocale}
        >
            {children}
        </LocalizationProvider>
    );
};
