import { getProgressTimeState } from '@/modules/progress/selector';
import { getSettingState } from '@/modules/setting/selector';
import { SettingState } from '@/modules/setting/slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const SETTING_STORAGE_KEY = 'user.setting';
export const useLocalStorageSetting = () => {
    const settingState = useSelector(getSettingState);
    const [cachedSetting, setCachedSetting] = useState<SettingState>();
    const progressTime = useSelector(getProgressTimeState);
    useEffect(() => {
        if (progressTime.lobby > 0) {
            console.log('hook::sound::setting', settingState);
            const json = JSON.stringify(settingState);
            window.localStorage.setItem(SETTING_STORAGE_KEY, json);
            setCachedSetting(settingState);
        }
    }, [settingState]);
    return { cachedSetting };
};
