export enum SyncData {
    LOW_BALANCE = 7,
    HIGH_BALANCE = 8,
    LOW_HOLDING_MONEY = 9,
    HIGH_HOLDING_MONEY = 10,
    LOW_CURRENCY_TYPE = 11,
    HIGH_CURRENCY_TYPE = 12,
    BALANCE_VALID = 19,
    ALLOW_TIPS = 32,
    LOW_AUTO_BET_BALANCE = 33,
    HIGH_AUTO_BET_BALANCE = 34,
    LOW_GAME_FEATURE = 40,
    HIGH_GAME_FEATURE = 41,
    EVENT_ID = 44,
    LOW_EVENT_POINT = 45,
    HIGH_EVENT_POINT = 46
}
