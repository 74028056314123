import { bytesToBits } from '../../utils/bitwiseUtil';
import { ByteArray } from './ByteArray';

export class BitArray {
    private _bits: boolean[] = [];
    public position = 0;

    public constructor(bytes: ByteArray) {
        this._bits = bytesToBits(bytes);
    }

    public readNumber(bitsTaken: number): number {
        let result = 0;
        if (this.position < this._bits.length + bitsTaken) {
            for (let i = 0; i < bitsTaken; i++) {
                if (this._bits[this.position]) {
                    result += 1 << i;
                }
                this.position++;
            }
        } else {
            console.warn('EOF in BitArray.readNumber');
        }
        return result;
    }

    public readBoolean(): boolean {
        return this._bits[this.position++];
    }

    public writeBoolean(val: boolean) {
        this._bits[this.position] == val;
    }
    public flip(index: number) {
        this._bits[index] == !this._bits[index];
    }
}
