import { Epic } from 'redux-observable';
import { concat, filter, switchMap } from 'rxjs';
import { Roadmap, getEmptyRoadMap } from '../models/Roadmap';
import {
    BaccaratRecord,
    BaccaratRoadmapRecord,
} from '../models/games/baccarat';
import { DragonTigerRecord } from '../models/games/dragonTiger';
import { GameType as CommonGameType } from '../models/games/enums/GameType';
import { SupportedRecord } from '../modules/host/models';
import { hostsSliceActions } from '../modules/host/slice';
import { ROAD_MAP_TYPE } from '../modules/lobby/constants';
import {
    getSummaryList as getBaccaratSummaryList,
    getBeadRoadRecord,
    getMappedRoad,
    getNextRoad,
    getOtherRoad,
} from '../modules/roadmap/baccarat/util';
import { getDTSummaryList } from '../modules/roadmap/dragonTiger/util';
import { getRoadmap, roadmapSliceActions } from '../modules/roadmap/slice';
import { RootState } from '../store/store';
export const SupportedGameType =
    CommonGameType.Baccarat | CommonGameType.Dragon;
export const roadmapEpic: Epic = (action$, store$) =>
    action$.pipe(
        filter(hostsSliceActions.updateRecord.match),
        switchMap(action => {
            const outs = [];
            const hostId = action.payload.hostId;
            const gameType = action.payload.gameType;
            console.log('epic::roadmap', gameType, hostId);
            if (gameType !== undefined)
                if ((SupportedGameType & gameType) > 0) {
                    const rm: Roadmap<BaccaratRoadmapRecord> =
                        getEmptyRoadMap<BaccaratRoadmapRecord>(hostId);
                    let record = action.payload
                        .record as Array<SupportedRecord>;
                    // convert the dragon tiger result
                    if (gameType === CommonGameType.Dragon) {
                        record = record.map(r => {
                            const dtr = r as DragonTigerRecord;
                            const data = {} as BaccaratRecord;
                            data.gameID = dtr.gameID;
                            data.result = dtr.result;
                            data.shoes = dtr.shoes;
                            data.round = dtr.round;
                            data.tie = dtr.tie;
                            data.resultPoint = dtr.resultPoint;

                            data.playerWin = dtr.dragonWin;
                            data.playerPoint = dtr.dragonPoint;
                            data.bankerWin = dtr.tigerWin;
                            data.bankerPoint = dtr.tigerPoint;
                            return data;
                        });
                    }

                    const store = store$.value as unknown as RootState;
                    const storeRM = store
                        ? getRoadmap(store, hostId)
                        : undefined;
                    const {
                        BeadRoad,
                        Other,
                        BigRoad,
                        BigEyeRoad,
                        SmallRoad,
                        CockroachRoad,
                    } = storeRM ? storeRM : rm;
                    const lastGame =
                        BeadRoad.length > 0
                            ? BeadRoad[BeadRoad.length - 1].Detail?.GameID
                            : 0;
                    const lastIdx = record.findIndex(
                        r => r.gameID === lastGame
                    );
                    const start = lastIdx + 1;
                    let bead =
                        BeadRoad && lastIdx >= 0 ? BeadRoad.concat() : [];
                    let other = Other && lastIdx >= 0 ? Other.concat() : [];
                    let big = BigRoad && lastIdx >= 0 ? BigRoad.concat() : [];
                    let bigEye =
                        BigEyeRoad && lastIdx >= 0 ? BigEyeRoad.concat() : [];
                    let small =
                        SmallRoad && lastIdx >= 0 ? SmallRoad.concat() : [];
                    let cockroach =
                        CockroachRoad && lastIdx >= 0
                            ? CockroachRoad.concat()
                            : [];
                    for (let i = start; i < record.length; i++) {
                        const newBead = getBeadRoadRecord(record[i]);
                        bead.push(newBead);
                        let nextBigEye = getNextRoad(
                            other,
                            ROAD_MAP_TYPE.BIG_EYE_ROAD,
                            newBead.Win
                        );
                        let nextSmall = getNextRoad(
                            other,
                            ROAD_MAP_TYPE.SMALL_ROAD,
                            newBead.Win
                        );
                        let nextCockroach = getNextRoad(
                            other,
                            ROAD_MAP_TYPE.COCKROACH_ROAD,
                            newBead.Win
                        );
                        other = getOtherRoad(other, newBead);
                        big = getMappedRoad(
                            big,
                            newBead,
                            other[other.length - 1]
                        );
                        if (newBead.Win != 2) {
                            bigEye = getMappedRoad(bigEye, {
                                Win: nextBigEye,
                                SpaceNum: 0,
                                IsLast: true,
                                AskType: -1,
                            });
                            small = getMappedRoad(small, {
                                Win: nextSmall,
                                SpaceNum: 0,
                                IsLast: true,
                                AskType: -1,
                            });
                            cockroach = getMappedRoad(cockroach, {
                                Win: nextCockroach,
                                SpaceNum: 0,
                                IsLast: true,
                                AskType: -1,
                            });
                        }
                    }
                    rm.Other = other;
                    rm.BeadRoad = bead;
                    rm.BigRoad = big;
                    rm.SmallRoad = small;
                    rm.BigEyeRoad = bigEye;
                    rm.CockroachRoad = cockroach;

                    rm.NextBigEye = getNextRoad(
                        other,
                        ROAD_MAP_TYPE.BIG_EYE_ROAD,
                        1
                    );
                    rm.NextSmall = getNextRoad(
                        other,
                        ROAD_MAP_TYPE.SMALL_ROAD,
                        1
                    );
                    rm.NextCockroachRoad = getNextRoad(
                        other,
                        ROAD_MAP_TYPE.COCKROACH_ROAD,
                        1
                    );
                    switch (gameType) {
                        case CommonGameType.Baccarat:
                            rm.Summary = getBaccaratSummaryList(record);
                            break;
                        case CommonGameType.Dragon:
                            rm.Summary = getDTSummaryList(record);
                            break;
                    }
                    outs.push(roadmapSliceActions.update(rm));
                }
            return concat(outs);
        })
    );
