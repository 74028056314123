import { CMDHostPlayer } from "./CMDHostPlayer"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScPlayerCount implements ICommand
{

	public ServerPlayerCount : number = 0;
	public HostPlayerCount : Array<CMDHostPlayer>;

	public constructor()
	{
		this.HostPlayerCount = new Array<CMDHostPlayer>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ServerPlayerCount = ba.readUint32();
		let HostPlayerCountSize:number = ba.readUint8();
		while (HostPlayerCountSize-- > 0)
		{
			let HostPlayerCountChild:CMDHostPlayer = new CMDHostPlayer();
			HostPlayerCountChild.fromBytes(ba);
			this.HostPlayerCount.push(HostPlayerCountChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.ServerPlayerCount);
		ba.writeUint8(this.HostPlayerCount.length);
		for (const HostPlayerCountChild of this.HostPlayerCount)
		{
			let tempBuf = HostPlayerCountChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScPlayerCount();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ServerPlayerCount(D)='+this.ServerPlayerCount+' ';
		str += 'HostPlayerCount_Size(B)='+this.HostPlayerCount.length + ' ';
		str += 'HostPlayerCount(CMDHostPlayer)=[';
		for (let HostPlayerCountChild in this.HostPlayerCount)
		{
			if (<number><unknown>HostPlayerCountChild > 0) str += ', ';
			str += this.HostPlayerCount[HostPlayerCountChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30031;
	}

	public getCmdName() : string
	{
		return 'ScPlayerCount';
	}

}
