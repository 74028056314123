import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateMsgType } from '../../models/Popup';
import { GameType as GameTypeEnum } from '../../models/games/enums/GameType';
import { inGamePopupReducers } from './reducer';

export type InGamePopupState = {
    hostId: number;
    isOpen: boolean;
    key: string | Array<string>;
    gameType: GameTypeEnum;
    stateMsgType: StateMsgType;
    gameId: number;
    dismiss: number; //ms, set timeout close,
    // popupId: number;
    confirmFunc?: string; //() => void | undefined;
    params?: Record<string, string>;
};

export const inGamePopupAdapter = createEntityAdapter<InGamePopupState, number>(
    {
        selectId: p => p.hostId,
        sortComparer: (a, b) => a.hostId - b.hostId,
    }
);
const initialInGamePopupState = inGamePopupAdapter.getInitialState();

export const GAME_POPUP_KEY = 'app::game::popup';
const inGamePopupSlice = createSlice({
    name: GAME_POPUP_KEY,
    initialState: initialInGamePopupState,
    reducers: {
        setAll: inGamePopupAdapter.setAll,
        ...inGamePopupReducers,
    },
});
export const inGamePopupSliceReducer = inGamePopupSlice.reducer;
export const inGamePopupSliceActions = inGamePopupSlice.actions;
export const initialInGamePopup = {} as InGamePopupState;
