import { CMDHost } from "./CMDHost"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScReportHostState implements ICommand
{

	public HostInfo : Array<CMDHost>;

	public constructor()
	{
		this.HostInfo = new Array<CMDHost>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let HostInfoSize:number = ba.readUint8();
		while (HostInfoSize-- > 0)
		{
			let HostInfoChild:CMDHost = new CMDHost();
			HostInfoChild.fromBytes(ba);
			this.HostInfo.push(HostInfoChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.HostInfo.length);
		for (const HostInfoChild of this.HostInfo)
		{
			let tempBuf = HostInfoChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScReportHostState();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostInfo_size(B)='+this.HostInfo.length + ' ';
		str += 'HostInfo(CMDHost)=[';
		for (let HostInfoChild in this.HostInfo)
		{
			if (<number><unknown>HostInfoChild > 0) str += ', ';
			str += this.HostInfo[HostInfoChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30029;
	}

	public getCmdName() : string
	{
		return 'ScReportHostState';
	}

}
