import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RedeemProps } from './PromotionRecord';
import { useTranslation } from 'react-i18next';
import { dateToString, timeToString } from '../PromotionUtil';
import { numberFormat } from '../../../../utils/commonFunc';

enum EventRewardType {
    Gold = 1,
    Silver,
    Bronze,
}
type RewardsType = {
    [key: number]: string;
} & {
    [key in EventRewardType]: string;
};
const rewards: RewardsType = {
    [EventRewardType.Gold]: 'promotion_tool.gold',
    [EventRewardType.Silver]: 'promotion_tool.silver',
    [EventRewardType.Bronze]: 'promotion_tool.bronze',
};

type PromotionRecordCellProps = {
    record: RedeemProps;
};
export const PromotionRecordCell = (props: PromotionRecordCellProps) => {
    const record = props.record;
    const { t } = useTranslation();

    const getRewardTime = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        return `${dateToString(date, false, '-')} ${timeToString(date, false, ':')}`;
    };

    return (
        <Box className="record-list-item">
            <Box
                display={'flex'}
                flexDirection={'row'}
                width={'100%'}
                alignItems={'flex-end'}
            >
                <Typography className="record-item-date">
                    {getRewardTime(record.RedeemTime)}
                </Typography>
                <Typography className="record-item-prize-level">
                    {t(rewards[record.PrizeRank])}
                </Typography>
                <Typography className="record-item-win-amount">{`${numberFormat(record.ResultAmount / 100)}`}</Typography>
            </Box>
            <Typography className="record-item-redemption-id">{`${record.RedeemID.toString()}`}</Typography>
            {!record.LastRecord ? (
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#ab8b56',
                        width: '100%',
                        height: '1px',
                        opacity: '0.2',
                        top: '6px',
                    }}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};
