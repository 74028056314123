import { combineReducers } from 'redux';
import { SOUND_KEY, soundSliceReducer } from './sound';
import { VOLUME_KEY, volumeSliceReducer } from './volume';

const settingReducer = combineReducers({
    [VOLUME_KEY]: volumeSliceReducer,
    [SOUND_KEY]: soundSliceReducer,
});
export default settingReducer;
export type SettingState = ReturnType<typeof settingReducer>;
export const SETTING_KEY = 'app::setting';
