import { CMDHsPokerDrawByIndex } from "./CMDHsPokerDrawByIndex"
import { CMDBaccarat } from "./CMDBaccarat"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDLiveBaccaratData implements ICommand
{

	public BaseData : CMDBaccarat;
	public PokerData : Array<CMDHsPokerDrawByIndex>;

	public constructor()
	{
		this.PokerData = new Array<CMDHsPokerDrawByIndex>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseData = new CMDBaccarat();
		this.BaseData.fromBytes(ba);
		let PokerDataSize:number = ba.readUint8();
		while (PokerDataSize-- > 0)
		{
			let PokerDataChild:CMDHsPokerDrawByIndex = new CMDHsPokerDrawByIndex();
			PokerDataChild.fromBytes(ba);
			this.PokerData.push(PokerDataChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseData.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.PokerData.length);
		for (const PokerDataChild of this.PokerData)
		{
			let tempBuf = PokerDataChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDLiveBaccaratData();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseData(CMDBaccarat)='+this.BaseData+' ';
		str += 'PokerData_size(B)='+this.PokerData.length + ' ';
		str += 'PokerData(CMDHsPokerDrawByIndex)=[';
		for (let PokerDataChild in this.PokerData)
		{
			if (<number><unknown>PokerDataChild > 0) str += ', ';
			str += this.PokerData[PokerDataChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'LiveBaccaratData';
	}

}
