import { CMDConfirmedBet } from "./CMDConfirmedBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBetResult implements ICommand
{

	public GameID : number = 0;
	public Reason : number = 0;
	public Bet : Array<CMDConfirmedBet>;

	public constructor()
	{
		this.Bet = new Array<CMDConfirmedBet>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.Reason = ba.readUint8();
		let BetSize:number = ba.readUint8();
		while (BetSize-- > 0)
		{
			let BetChild:CMDConfirmedBet = new CMDConfirmedBet();
			BetChild.fromBytes(ba);
			this.Bet.push(BetChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint8(this.Reason);
		ba.writeUint8(this.Bet.length);
		for (const BetChild of this.Bet)
		{
			let tempBuf = BetChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBetResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'Reason(B)='+this.Reason+' ';
		str += 'Bet_size(B)='+this.Bet.length + ' ';
		str += 'Bet(CMDConfirmedBet)=[';
		for (let BetChild in this.Bet)
		{
			if (<number><unknown>BetChild > 0) str += ', ';
			str += this.Bet[BetChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50003;
	}

	public getCmdName() : string
	{
		return 'SpBetResult';
	}

}
