import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetTeenPatti2020 implements ICommand
{

	public Total : CMDTotalBet;
	public Tie : CMDBetSummary;
	public PlayerAWin : CMDBetSummary;
	public PlayerBWin : CMDBetSummary;
	public PlayerAPairPlus : CMDBetSummary;
	public PlayerBPairPlus : CMDBetSummary;
	public SixCardBonus : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Tie = new CMDBetSummary();
		this.Tie.fromBytes(ba);
		this.PlayerAWin = new CMDBetSummary();
		this.PlayerAWin.fromBytes(ba);
		this.PlayerBWin = new CMDBetSummary();
		this.PlayerBWin.fromBytes(ba);
		this.PlayerAPairPlus = new CMDBetSummary();
		this.PlayerAPairPlus.fromBytes(ba);
		this.PlayerBPairPlus = new CMDBetSummary();
		this.PlayerBPairPlus.fromBytes(ba);
		this.SixCardBonus = new CMDBetSummary();
		this.SixCardBonus.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Tie.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.PlayerAWin.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.PlayerBWin.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.PlayerAPairPlus.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.PlayerBPairPlus.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.SixCardBonus.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetTeenPatti2020();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Tie(CMDBetSummary)='+this.Tie+' ';
		str += 'PlayerAWin(CMDBetSummary)='+this.PlayerAWin+' ';
		str += 'PlayerBWin(CMDBetSummary)='+this.PlayerBWin+' ';
		str += 'PlayerAPairPlus(CMDBetSummary)='+this.PlayerAPairPlus+' ';
		str += 'PlayerBPairPlus(CMDBetSummary)='+this.PlayerBPairPlus+' ';
		str += 'SixCardBonus(CMDBetSummary)='+this.SixCardBonus+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30760;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetTeenPatti2020';
	}

}
