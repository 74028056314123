import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';

export const LIMIT_RED_KEY = 'app::main::limitRed';
export type LimitRedState = {
    BetRuleByGameType: Array<LimitRedByGame>;
    ChangePopup?: boolean;
};
export type LimitRedByGame = {
    BetRule: Array<LimitRed>;
    GameType: GameTypeEnum;
    RuleToSelect?: LimitRed;
};
export type LimitRed = {
    RuleID: number;
    MinBet: number;
    LimitRed: number;
    Selected: number;
    Token: Array<number>;
};
const initialLimitRedState = {} as LimitRedState;
const updateData = (
    state: LimitRedState,
    action: PayloadAction<Array<LimitRedByGame>>
) => {
    let newBetRuleByGameType = new Array<LimitRedByGame>();
    if (state.BetRuleByGameType) {
        state.ChangePopup = true;
    }
    for (let i = 0; i < action.payload.length; i++) {
        let item;
        const selectedRule = action.payload[i].BetRule.find(
            lr => lr.Selected === 1
        );
        if (selectedRule) {
            item = {
                GameType: action.payload[i].GameType,
                BetRule: action.payload[i].BetRule,
                RuleToSelect: selectedRule,
            };
        } else if (state.BetRuleByGameType) {
            const lastSelectedRule = state.BetRuleByGameType.find(
                lr => lr.GameType === action.payload[i].GameType
            )?.BetRule.find(lr => lr.Selected === 1);
            if (lastSelectedRule) {
                const list = action.payload[i].BetRule.concat(
                    lastSelectedRule
                ).sort((a, b) => {
                    if (a.MinBet < b.MinBet) return -1;
                    if (a.MinBet > b.MinBet) return 1;

                    if (a.LimitRed < b.LimitRed) return -1;
                    if (a.LimitRed > b.LimitRed) return 1;

                    return 0;
                });
                const index = list.indexOf(lastSelectedRule) - 1;
                const tmp =
                    action.payload[i].BetRule.length > 0
                        ? action.payload[i].BetRule[0]
                        : undefined;
                item = {
                    GameType: action.payload[i].GameType,
                    BetRule: action.payload[i].BetRule,
                    RuleToSelect: index >= 0 ? list[index] : tmp,
                };
            }
        }
        if (!item) {
            item = {
                GameType: action.payload[i].GameType,
                BetRule: action.payload[i].BetRule,
                RuleToSelect:
                    action.payload[i].BetRule.length > 0
                        ? action.payload[i].BetRule[0]
                        : undefined,
            };
        }
        newBetRuleByGameType.push(item);
    }
    state.BetRuleByGameType = newBetRuleByGameType;
};
const updateBetRuleSelected = (
    state: LimitRedState,
    action: PayloadAction<{
        GameType: number;
        RuleID: number;
    }>
) => {
    const { GameType, RuleID } = action.payload;
    const bri = state.BetRuleByGameType.findIndex(l => l.GameType === GameType);
    state.BetRuleByGameType[bri].RuleToSelect = undefined;
    state.BetRuleByGameType[bri].BetRule = state.BetRuleByGameType[
        bri
    ].BetRule.map(br => {
        br.Selected = br.RuleID == RuleID ? 1 : 0;
        return br;
    });
};
const updateSelectedToken = (
    state: LimitRedState,
    action: PayloadAction<{
        GameType: number;
        RuleID: number;
        Token: Array<number>;
    }>
) => {
    const { GameType, RuleID, Token } = action.payload;
    const bri = state.BetRuleByGameType.findIndex(l => l.GameType === GameType);
    const brsi = state.BetRuleByGameType[bri].BetRule.findIndex(
        b => b.RuleID === RuleID
    );
    state.BetRuleByGameType[bri].BetRule[brsi].Token = Token;
};
const logoutReset = (state: LimitRedState) => {
    state.BetRuleByGameType = initialLimitRedState.BetRuleByGameType;
};
const clearPopup = (state: LimitRedState) => {
    state.ChangePopup = false;
};
const limitRedSlice = createSlice({
    name: LIMIT_RED_KEY,
    initialState: initialLimitRedState,
    reducers: {
        updateData,
        updateBetRuleSelected,
        updateSelectedToken,
        clearPopup,
        logoutReset,
    },
});
export const limitRedSliceReducer = limitRedSlice.reducer;
export const limitRedSliceActions = limitRedSlice.actions;
