import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsEgameLogin implements ICommand
{

	public Token : string = "";
	public BetSource : number = 0;
	public GameCode : string = "";
	public AdditionalRequest : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Token = ba.readString();
		this.BetSource = ba.readUint32();
		this.GameCode = ba.readString();
		this.AdditionalRequest = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.Token);
		ba.writeUint32(this.BetSource);
		ba.writeString(this.GameCode);
		ba.writeString(this.AdditionalRequest);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsEgameLogin();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Token[33](C)='+this.Token+' ';
		str += 'BetSource(D)='+this.BetSource+' ';
		str += 'GameCode(S)='+this.GameCode+' ';
		str += 'AdditionalRequest(S)='+this.AdditionalRequest+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55018;
	}

	public getCmdName() : string
	{
		return 'PsEgameLogin';
	}

}
