import { CMDCurrency } from "./CMDCurrency"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLogin implements ICommand
{

	public PlayerID : number = 0;
	public LobbyCode : string = "";
	public CurrencyName : string = "";
	public DuplicateLogin : number = 0;
	public FPlayerPrefs : bigint = 0n; 
	public PlayerPrefs : number = 0;
	public CurrencyType : number = 0;
	public CurrencyList : Array<CMDCurrency>;
	public NoChanceJackpot : number = 0;
	public FPlayerPrefs2 : bigint = 0n; 
	public PlayerPrefs2 : number = 0;
	public AllowLoginSource : number = 0;
	public VideoToken : string = "";
	public ReconnectKey : string = "";
	public MinimumToken : number = 0;
	public Username : string = "";
	public ErrorMsgPack : string = "";
	public IsCommonWallet : number = 0;
	public FPlayerPrefs3 : bigint = 0n; 
	public PlayerPrefs3 : number = 0;
	public LastLogin : string = "";
	public FPlayerPrefs4 : bigint = 0n; 
	public PlayerPrefs4 : number = 0;
	public UNIXTimestamp : number = 0;
	public LoginLaucherUTCTime : number = 0;
	public LobbyType : number = 0;
	public IsTrial : number = 0;

	public constructor()
	{
		this.CurrencyList = new Array<CMDCurrency>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerID = ba.readInt53();
		this.LobbyCode = ba.readString();
		this.CurrencyName = ba.readString();
		this.DuplicateLogin = ba.readUint8();
		this.FPlayerPrefs = ba.readUint64();
		this.PlayerPrefs = Number(this.FPlayerPrefs);
		this.CurrencyType = ba.readInt53();
		let CurrencyListSize:number = ba.readUint8();
		while (CurrencyListSize-- > 0)
		{
			let CurrencyListChild:CMDCurrency = new CMDCurrency();
			CurrencyListChild.fromBytes(ba);
			this.CurrencyList.push(CurrencyListChild);
		}
		this.NoChanceJackpot = ba.readUint8();
		this.FPlayerPrefs2 = ba.readUint64();
		this.PlayerPrefs2 = Number(this.FPlayerPrefs2);
		this.AllowLoginSource = ba.readInt53();
		this.VideoToken = ba.readString();
		this.ReconnectKey = ba.readString();
		this.MinimumToken = ba.readUint32();
		this.Username = ba.readString();
		this.ErrorMsgPack = ba.readString();
		this.IsCommonWallet = ba.readUint8();
		this.FPlayerPrefs3 = ba.readUint64();
		this.PlayerPrefs3 = Number(this.FPlayerPrefs3);
		this.LastLogin = ba.readString();
		this.FPlayerPrefs4 = ba.readUint64();
		this.PlayerPrefs4 = Number(this.FPlayerPrefs4);
		this.UNIXTimestamp = ba.readInt53();
		this.LoginLaucherUTCTime = ba.readUint53();
		this.LobbyType = ba.readUint8();
		this.IsTrial = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.PlayerID);
		ba.writeString(this.LobbyCode);
		ba.writeString(this.CurrencyName);
		ba.writeUint8(this.DuplicateLogin);
		ba.writeUint64(this.FPlayerPrefs);
		ba.writeUint53(this.CurrencyType);
		ba.writeUint8(this.CurrencyList.length);
		for (const CurrencyListChild of this.CurrencyList)
		{
			let tempBuf = CurrencyListChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint8(this.NoChanceJackpot);
		ba.writeUint64(this.FPlayerPrefs2);
		ba.writeUint53(this.AllowLoginSource);
		ba.writeString(this.VideoToken);
		ba.writeString(this.ReconnectKey);
		ba.writeUint32(this.MinimumToken);
		ba.writeString(this.Username);
		ba.writeString(this.ErrorMsgPack);
		ba.writeUint8(this.IsCommonWallet);
		ba.writeUint64(this.FPlayerPrefs3);
		ba.writeString(this.LastLogin);
		ba.writeUint64(this.FPlayerPrefs4);
		ba.writeUint53(this.UNIXTimestamp);
		ba.writeUint53(this.LoginLaucherUTCTime);
		ba.writeUint8(this.LobbyType);
		ba.writeUint8(this.IsTrial);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLogin();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'LobbyCode(S)='+this.LobbyCode+' ';
		str += 'CurrencyName(S)='+this.CurrencyName+' ';
		str += 'DuplicateLogin(B)='+this.DuplicateLogin+' ';
		str += 'PlayerPrefs(F)='+this.PlayerPrefs+' ';
		str += 'CurrencyType(Q)='+this.CurrencyType+' ';
		str += 'CurrencyList_Size(B)='+this.CurrencyList.length + ' ';
		str += 'CurrencyList(CMDCurrency)=[';
		for (let CurrencyListChild in this.CurrencyList)
		{
			if (<number><unknown>CurrencyListChild > 0) str += ', ';
			str += this.CurrencyList[CurrencyListChild].toString();
		}
		str += '] ';
		str += 'NoChanceJackpot(B)='+this.NoChanceJackpot+' ';
		str += 'PlayerPrefs2(F)='+this.PlayerPrefs2+' ';
		str += 'AllowLoginSource(Q)='+this.AllowLoginSource+' ';
		str += 'VideoToken(S)='+this.VideoToken+' ';
		str += 'ReconnectKey(S)='+this.ReconnectKey+' ';
		str += 'MinimumToken(D)='+this.MinimumToken+' ';
		str += 'Username(S)='+this.Username+' ';
		str += 'ErrorMsgPack(S)='+this.ErrorMsgPack+' ';
		str += 'IsCommonWallet(B)='+this.IsCommonWallet+' ';
		str += 'PlayerPrefs3(F)='+this.PlayerPrefs3+' ';
		str += 'LastLogin(S)='+this.LastLogin+' ';
		str += 'PlayerPrefs4(F)='+this.PlayerPrefs4+' ';
		str += 'UNIXTimestamp(Q)='+this.UNIXTimestamp+' ';
		str += 'LoginLaucherUTCTime(Q)='+this.LoginLaucherUTCTime+' ';
		str += 'LobbyType(B)='+this.LobbyType+' ';
		str += 'IsTrial(B)='+this.IsTrial+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50001;
	}

	public getCmdName() : string
	{
		return 'SpLogin';
	}

}
