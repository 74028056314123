import { LocalStorageKey } from '@/hooks/storage/useLocalStorage';
import {
    getTableBetByGameId,
    tableBetSliceActions,
} from '@/modules/betTable/slice';
import { getMainUserState } from '@/modules/main/selector';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import { batch } from 'react-redux';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import { inGamePopupSliceActions } from '../../../modules/popup/inGameSlice';
import store from '../../../store/store';
import { StateMsgType } from '../../Popup';
import { Bet } from '../../host/BetAmount';
import { CMDSpBetResult, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDSpBetResultHandler extends AbstractHandler {
    _command: CMDSpBetResult;
    _specialMsg: string;
    _isMessageExist: (key: string) => boolean;

    constructor(
        command: ICommand,
        specialMsg: string,
        isMessageExist: (key: string) => boolean
    ) {
        super();
        this._command = command as CMDSpBetResult;
        this._specialMsg = specialMsg;
        this._isMessageExist = isMessageExist;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        // if (gameState.currentGameId != this._command.GameID) return;

        const { ConfirmedBets } = getTableBetByGameId(
            state,
            this._command.GameID
        );
        const host = getHostByGameId(state, this._command.GameID);
        if (!host) return;
        const { GameType, HostId, PendingTime, RemainTime } = host;
        const { Username } = getMainUserState(state);
        dispatch(
            tableBetSliceActions.setWaitingBetResult({
                hostId: HostId,
                gameId: this._command.GameID,
                value: false,
            })
        );

        console.log(`hook::socket::run::${HostId}`, this._command);
        if (this._command.Reason === this.SUCCESS_CODE) {
            const hostIds = localStorage.getItem(
                LocalStorageKey.RecentlyPlayed + Username
            );
            if (hostIds && hostIds.length > 0) {
                let recentlyList = JSON.parse(hostIds)['hostIds'];
                let recently = recentlyList.filter(
                    (h: { hostId: number }) => h.hostId != HostId
                );
                const newRecently = [{ hostId: HostId }]
                    .concat(recently)
                    .slice(0, 8);
                localStorage.setItem(
                    LocalStorageKey.RecentlyPlayed + Username,
                    JSON.stringify({ hostIds: newRecently })
                );
            } else {
                localStorage.setItem(
                    LocalStorageKey.RecentlyPlayed + Username,
                    JSON.stringify({ hostIds: [{ hostId: HostId }] })
                );
            }

            let Bets = this._command.Bet.map(
                b =>
                    ({
                        Type: b.Base.betType,
                        Amount: ConfirmedBets[b.Base.betType]
                            ? ConfirmedBets[b.Base.betType] + b.Base.betAmount
                            : b.Base.betAmount,
                        GameId: this._command.GameID,
                    }) as Bet
            );

            let newConfirmedBets = [...ConfirmedBets];
            Bets.forEach(bet => {
                newConfirmedBets[bet.Type] = bet.Amount;
            });

            batch(() => {
                if (PendingTime) {
                    const curTimer = Math.max(
                        0,
                        Math.round((RemainTime - moment().valueOf()) / 1000)
                    );
                    const time = PendingTime + curTimer * 1000;
                    dispatch(
                        hostsSliceActions.updateCountDown({
                            hostId: HostId,
                            countDown: time,
                            canLeave: false,
                        })
                    );
                }
                dispatch(
                    tableBetSliceActions.updateConfirmedBets({
                        hostId: HostId,
                        gameId: this._command.GameID,
                        bets: newConfirmedBets,
                    })
                );
                // dispatch(
                //     hostsSliceActions.updateConfirmedBets({
                //         hostId: HostId,
                //         gameId: this._command.GameID,
                //         bets: Bet,
                //     })
                // );
                // dispatch(
                //     gameSliceActions.onConfirmedBetResult({
                //         gameId: this._command.GameID,
                //         bet: Bet,
                //     })
                // );
            });

            // dispatch(
            //     inGamePopupSliceActions.open(
            //         'system.bet_success',
            //         GameType,
            //         StateMsgType.betInfo_Success,
            //         this._command.GameID
            //     )
            // );
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: HostId,
                        isOpen: true,
                    })
                );
                dispatch(
                    inGamePopupSliceActions.updateDataSet({
                        hostId: HostId,
                        key: 'system.bet_success',
                        gameType: GameType,
                        stateMsgType: StateMsgType.betInfo_Success,
                        gameId: this._command.GameID,
                    })
                );
            });
        } else {
            // dispatch(gameSliceActions.onCancelPendingBet());
            if (this._specialMsg !== '') {
                const key = `system.${this._specialMsg}.${this._command.Reason}`;
                if (this._isMessageExist(key)) {
                    batch(() => {
                        dispatch(
                            inGamePopupSliceActions.updateIsOpen({
                                hostId: HostId,
                                isOpen: true,
                            })
                        );
                        dispatch(
                            inGamePopupSliceActions.updateDataSet({
                                hostId: HostId,
                                key: key,
                                gameType: GameType,
                                stateMsgType: StateMsgType.betInfo_Fail,
                                gameId: this._command.GameID,
                            })
                        );
                    });
                    return;
                }
            }
            let errorMsg = `abc.error_code.${this._command.Reason}`;
            if (!this._isMessageExist(errorMsg)) {
                errorMsg = 'abc.error_common';
            }
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: HostId,
                        isOpen: true,
                    })
                );
                dispatch(
                    inGamePopupSliceActions.updateDataSet({
                        hostId: HostId,
                        key:
                            errorMsg != 'abc.error_common'
                                ? errorMsg
                                : [errorMsg, `(${this._command.Reason})`],
                        gameType: GameType,
                        stateMsgType: StateMsgType.betInfo_Fail,
                        gameId: this._command.GameID,
                    })
                );
            });
            return;
        }
    }
}
