// import { BitArray } from '../../models/cmd/BitArray';
import { BetType } from '@/models/games/sicbo/BetType';
import { ByteArray } from '../../models/cmd';
import { BitArray } from '../../models/cmd/BitArray';
import { GameState } from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { SicBoRecord, SicBoResult } from '../../models/games/sicbo';
import { GameState as SicBoState } from '../../models/games/sicbo/GameState';
import { SupportedGameResult } from '../../modules/host/models';

export const convertResultToRecord = (result: SicBoResult): SicBoRecord => {
    const [d1, d2, d3] = [result.Dice1, result.Dice2, result.Dice3].sort(
        (a, b) => a - b
    );
    const record: SicBoRecord = {
        gameID: result.GameID,
        result: result.Result,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        resultPoint: d1 + d2 + d3,
        Dice1: d1,
        Dice2: d2,
        Dice3: d3,
        SRBigSmall: result.SRBigSmall, // 0 = None, 1 = Big, 2 = Small
        SROddEven: result.SROddEven, // 0 = None, 1 = Odd, 2 = Even
        SRTripleArmyOne: result.SRTripleArmyOne,
        SRTripleArmyTwo: result.SRTripleArmyTwo,
        SRTripleArmyThree: result.SRTripleArmyThree,
        SRTripleArmyFour: result.SRTripleArmyFour,
        SRTripleArmyFive: result.SRTripleArmyFive,
        SRTripleArmySix: result.SRTripleArmySix,
        SRTriple: result.SRTriple, // 0 = None, 1 - 6
        SRAllTriple: result.SRAllTriple, // Must True When SRTriple Not Zero
        SRPoint: result.SRPoint, // 0 = None, 4 - 17
        SRLongOneTwo: result.SRLongOneTwo,
        SRLongOneThree: result.SRLongOneThree,
        SRLongOneFour: result.SRLongOneFour,
        SRLongOneFive: result.SRLongOneFive,
        SRLongOneSix: result.SRLongOneSix,
        SRLongTwoThree: result.SRLongTwoThree,
        SRLongTwoFour: result.SRLongTwoFour,
        SRLongTwoFive: result.SRLongTwoFive,
        SRLongTwoSix: result.SRLongTwoSix,
        SRLongThreeFour: result.SRLongThreeFour,
        SRLongThreeFive: result.SRLongThreeFive,
        SRLongThreeSix: result.SRLongThreeSix,
        SRLongFourFive: result.SRLongFourSix,
        SRLongFourSix: result.SRLongFourSix,
        SRLongFiveSix: result.SRLongFiveSix,
        SRShort: result.SRShort, // 0 = None, 1 - 6
        SROddEvenCombination: result.SROddEvenCombination, // 0 = AllDicesOdd, 1 = TwoDicesOddOneDiceEven, 2 = TwoDicesEvenOneDiceOdd, 3 = AllDicesEven
        SR_1_2_3_4: result.SR_1_2_3_4,
        SR_2_3_4_5: result.SR_2_3_4_5,
        SR_2_3_5_6: result.SR_2_3_5_6,
        SR_3_4_5_6: result.SR_3_4_5_6,
        SRCombination: result.SRCombination,
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    FResult: bigint,
    result?: number
): SicBoRecord => {
    const arr = resultString.split(',');
    const d1 = parseInt(arr[0]);
    const d2 = parseInt(arr[1]);
    const d3 = parseInt(arr[2]);
    const { GameID, Result, GameCount } = currentResult;
    const updateResult: SicBoResult = Object.assign(
        {
            Dice1: d1,
            Dice2: d2,
            Dice3: d3,
            FResult: FResult.toString(),
            GameCount: GameCount,
            GameID: GameID,
            Result: result ?? Result,
        },
        getResultForFResult(BigInt(FResult))
    );
    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getResultForFResult = (FResult: bigint) => {
    //SA -> SicBoResult.ts
    const ba: ByteArray = new ByteArray();
    ba.writeUint64(FResult);
    const bitArray = new BitArray(ba);
    return {
        SRBigSmall: bitArray.readNumber(2), // 0 = None, 1 = Big, 2 = Small
        SROddEven: bitArray.readNumber(2), // 0 = None, 1 = Odd, 2 = Even
        SRTripleArmyOne: bitArray.readBoolean(),
        SRTripleArmyTwo: bitArray.readBoolean(),
        SRTripleArmyThree: bitArray.readBoolean(),
        SRTripleArmyFour: bitArray.readBoolean(),
        SRTripleArmyFive: bitArray.readBoolean(),
        SRTripleArmySix: bitArray.readBoolean(),
        SRTriple: bitArray.readNumber(3), // 0 = None, 1 - 6
        SRAllTriple: bitArray.readBoolean(), // Must True When SRTriple Not Zero
        SRPoint: bitArray.readNumber(5), // 0 = None, 4 - 17
        SRLongOneTwo: bitArray.readBoolean(),
        SRLongOneThree: bitArray.readBoolean(),
        SRLongOneFour: bitArray.readBoolean(),
        SRLongOneFive: bitArray.readBoolean(),
        SRLongOneSix: bitArray.readBoolean(),
        SRLongTwoThree: bitArray.readBoolean(),
        SRLongTwoFour: bitArray.readBoolean(),
        SRLongTwoFive: bitArray.readBoolean(),
        SRLongTwoSix: bitArray.readBoolean(),
        SRLongThreeFour: bitArray.readBoolean(),
        SRLongThreeFive: bitArray.readBoolean(),
        SRLongThreeSix: bitArray.readBoolean(),
        SRLongFourFive: bitArray.readBoolean(),
        SRLongFourSix: bitArray.readBoolean(),
        SRLongFiveSix: bitArray.readBoolean(),
        SRShort: bitArray.readNumber(3), // 0 = None, 1 - 6
        SROddEvenCombination: bitArray.readNumber(2), // 0 = AllDicesOdd, 1 = TwoDicesOddOneDiceEven, 2 = TwoDicesEvenOneDiceOdd, 3 = AllDicesEven
        SR_1_2_3_4: bitArray.readBoolean(),
        SR_2_3_4_5: bitArray.readBoolean(),
        SR_2_3_5_6: bitArray.readBoolean(),
        SR_3_4_5_6: bitArray.readBoolean(),
        SRCombination: bitArray.readNumber(6),
    };
};

export const getSicBoWinSet = (FResult: bigint): Array<BetType> => {
    let betTypeWin: BetType[] = [];
    const sbResult = getResultForFResult(FResult);

    const isBig = () => sbResult.SRBigSmall == 1;
    const isSmall = () => sbResult.SRBigSmall == 2;
    const isOdd = () => sbResult.SROddEven == 1;
    const isEven = () => sbResult.SROddEven == 2;

    if (isBig()) betTypeWin.push(BetType.SBBBig);
    if (isSmall()) betTypeWin.push(BetType.SBBSmall);

    //OddEven
    if (isOdd()) betTypeWin.push(BetType.SBBOdd);
    if (isEven()) betTypeWin.push(BetType.SBBEven);

    //Point
    if (sbResult.SRPoint > 0) {
        betTypeWin.push(BetType.SBBPointFour + sbResult.SRPoint - 4);
    }

    //Any Triple
    if (sbResult.SRAllTriple) {
        betTypeWin.push(BetType.SBBAllTriple);
    }

    //Triple
    if (sbResult.SRTriple == 1) betTypeWin.push(BetType.SBBTripleOne);
    if (sbResult.SRTriple == 2) betTypeWin.push(BetType.SBBTripleTwo);
    if (sbResult.SRTriple == 3) betTypeWin.push(BetType.SBBTripleThree);
    if (sbResult.SRTriple == 4) betTypeWin.push(BetType.SBBTripleFour);
    if (sbResult.SRTriple == 5) betTypeWin.push(BetType.SBBTripleFive);
    if (sbResult.SRTriple == 6) betTypeWin.push(BetType.SBBTripleSix);

    //Triple Army
    if (sbResult.SRTripleArmyOne) betTypeWin.push(BetType.SBBTripleArmyOne);
    if (sbResult.SRTripleArmyTwo) betTypeWin.push(BetType.SBBTripleArmyTwo);
    if (sbResult.SRTripleArmyThree) betTypeWin.push(BetType.SBBTripleArmyThree);
    if (sbResult.SRTripleArmyFour) betTypeWin.push(BetType.SBBTripleArmyFour);
    if (sbResult.SRTripleArmyFive) betTypeWin.push(BetType.SBBTripleArmyFive);
    if (sbResult.SRTripleArmySix) betTypeWin.push(BetType.SBBTripleArmySix);

    //Short
    if (sbResult.SRShort == 1) betTypeWin.push(BetType.SBBShortOne);
    if (sbResult.SRShort == 2) betTypeWin.push(BetType.SBBShortTwo);
    if (sbResult.SRShort == 3) betTypeWin.push(BetType.SBBShortThree);
    if (sbResult.SRShort == 4) betTypeWin.push(BetType.SBBShortFour);
    if (sbResult.SRShort == 5) betTypeWin.push(BetType.SBBShortFive);
    if (sbResult.SRShort == 6) betTypeWin.push(BetType.SBBShortSix);

    //Long
    if (sbResult.SRLongOneTwo) betTypeWin.push(BetType.SBBLongOneTwo);
    if (sbResult.SRLongOneThree) betTypeWin.push(BetType.SBBLongOneThree);
    if (sbResult.SRLongOneFour) betTypeWin.push(BetType.SBBLongOneFour);
    if (sbResult.SRLongOneFive) betTypeWin.push(BetType.SBBLongOneFive);
    if (sbResult.SRLongOneSix) betTypeWin.push(BetType.SBBLongOneSix);

    if (sbResult.SRLongTwoThree) betTypeWin.push(BetType.SBBLongTwoThree);
    if (sbResult.SRLongTwoFour) betTypeWin.push(BetType.SBBLongTwoFour);
    if (sbResult.SRLongTwoFive) betTypeWin.push(BetType.SBBLongTwoFive);
    if (sbResult.SRLongTwoSix) betTypeWin.push(BetType.SBBLongTwoSix);

    if (sbResult.SRLongThreeFour) betTypeWin.push(BetType.SBBLongThreeFour);
    if (sbResult.SRLongThreeFive) betTypeWin.push(BetType.SBBLongThreeFive);
    if (sbResult.SRLongThreeSix) betTypeWin.push(BetType.SBBLongThreeSix);

    if (sbResult.SRLongFourFive) betTypeWin.push(BetType.SBBLongFourFive);
    if (sbResult.SRLongFourSix) betTypeWin.push(BetType.SBBLongFourSix);

    if (sbResult.SRLongFiveSix) betTypeWin.push(BetType.SBBLongFiveSix);

    //Combination
    for (let i: number = BetType.SB_112; i <= BetType.SB_345; i++) {
        if (sbResult.SRCombination == i - BetType.SB_112 + 1) {
            betTypeWin.push(i);
        }
    }

    //OddEvenCombination
    if (sbResult.SROddEvenCombination == 0)
        betTypeWin.push(BetType.SBAllDicesOdd);
    if (sbResult.SROddEvenCombination == 1)
        betTypeWin.push(BetType.SBTwoDicesOddOneDiceEven);
    if (sbResult.SROddEvenCombination == 2)
        betTypeWin.push(BetType.SBTwoDicesEvenOneDiceOdd);
    if (sbResult.SROddEvenCombination == 3)
        betTypeWin.push(BetType.SBAllDicesEven);

    ////Four Numbers
    if (sbResult.SR_1_2_3_4) betTypeWin.push(BetType.SB_1_2_3_4);
    if (sbResult.SR_2_3_4_5) betTypeWin.push(BetType.SB_2_3_4_5);
    if (sbResult.SR_2_3_5_6) betTypeWin.push(BetType.SB_2_3_5_6);
    if (sbResult.SR_3_4_5_6) betTypeWin.push(BetType.SB_3_4_5_6);

    return betTypeWin;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }
    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Restart:
            return GameState.Restart;
        case GameState.Idle:
        case GameState.Shuffle:
            return LocalState.IDLE;
        // return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case SicBoState.WaitResult:
            return LocalState.DEALING;
        case SicBoState.Small:
        case SicBoState.Big:
            return LocalState.RESULT;
    }
    if (isGameResultReleased) {
        return LocalState.RESULT;
    }

    return LocalState.IDLE;
};
