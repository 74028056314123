import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpPokdengStatistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { PokDengStatistics } from '../../games/pokdeng';
import { hostsSliceActions } from '../../../modules/host/slice';

export class CMDSpPokDengStatisticsHandler extends AbstractHandler {
    _command: CMDSpPokdengStatistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPokdengStatistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as PokDengStatistics;
        const hostId = this._command.HostID;
        statistics.player1WinCount = this._command.Statistics.Player1WinCount;
        statistics.player2WinCount = this._command.Statistics.Player2WinCount;
        statistics.player3WinCount = this._command.Statistics.Player3WinCount;
        statistics.player4WinCount = this._command.Statistics.Player4WinCount;
        statistics.player5WinCount = this._command.Statistics.Player5WinCount;

        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
