import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsLoginLaucherTime implements ICommand
{

	public PlayerID : number = 0;
	public LoginLaucherTime : number = 0;
	public TimeLogReq : number = 0;
	public TimeLaucher : number = 0;
	public TimeInit : number = 0;
	public TimeMaxmind : number = 0;
	public TimeJS : number = 0;
	public TimeEngine : number = 0;
	public TimeLS : number = 0;
	public TimeLobby : number = 0;
	public UserAgent : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerID = ba.readInt53();
		this.LoginLaucherTime = ba.readUint53();
		this.TimeLogReq = ba.readUint32();
		this.TimeLaucher = ba.readUint32();
		this.TimeInit = ba.readUint32();
		this.TimeMaxmind = ba.readUint32();
		this.TimeJS = ba.readUint32();
		this.TimeEngine = ba.readUint32();
		this.TimeLS = ba.readUint32();
		this.TimeLobby = ba.readUint32();
		this.UserAgent = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.PlayerID);
		ba.writeUint53(this.LoginLaucherTime);
		ba.writeUint32(this.TimeLogReq);
		ba.writeUint32(this.TimeLaucher);
		ba.writeUint32(this.TimeInit);
		ba.writeUint32(this.TimeMaxmind);
		ba.writeUint32(this.TimeJS);
		ba.writeUint32(this.TimeEngine);
		ba.writeUint32(this.TimeLS);
		ba.writeUint32(this.TimeLobby);
		ba.writeString(this.UserAgent);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsLoginLaucherTime();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'LoginLaucherTime(Q)='+this.LoginLaucherTime+' ';
		str += 'TimeLogReq(D)='+this.TimeLogReq+' ';
		str += 'TimeLaucher(D)='+this.TimeLaucher+' ';
		str += 'TimeInit(D)='+this.TimeInit+' ';
		str += 'TimeMaxmind(D)='+this.TimeMaxmind+' ';
		str += 'TimeJS(D)='+this.TimeJS+' ';
		str += 'TimeEngine(D)='+this.TimeEngine+' ';
		str += 'TimeLS(D)='+this.TimeLS+' ';
		str += 'TimeLobby(D)='+this.TimeLobby+' ';
		str += 'UserAgent(S)='+this.UserAgent+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55089;
	}

	public getCmdName() : string
	{
		return 'PsLoginLaucherTime';
	}

}
