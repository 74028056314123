import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameType as GameTypeEnum } from '../../models/games/enums/GameType';
export const LOBBY_KEY = 'app::lobby';
export type HallGameTypeMap = {
    hostId: number;
    hallId?: number;
    gameType: GameTypeEnum;
};
type LobbyState = {
    hallGameTypeMap: Array<HallGameTypeMap>;
    expandSideBar: boolean;
    goodRoadRefresh: boolean;
    recentlyPlayedList: Array<{ hostId: number }>;
};
const updateHallGameTypeMap = (
    state: LobbyState,
    action: PayloadAction<Array<HallGameTypeMap>>
) => {
    state.hallGameTypeMap = action.payload;
};

const updateSideBar = (state: LobbyState, action: PayloadAction<boolean>) => {
    state.expandSideBar = action.payload;
};

const updateGoodRoadRefresh = (
    state: LobbyState,
    action: PayloadAction<boolean>
) => {
    state.goodRoadRefresh = action.payload;
};
const updateRecentlyPlayed = (
    state: LobbyState,
    action: PayloadAction<Array<{ hostId: number }>>
) => {
    state.recentlyPlayedList = action.payload;
};
const lobbySlice = createSlice({
    name: LOBBY_KEY,
    initialState: {} as LobbyState,
    reducers: {
        updateHallGameTypeMap,
        updateSideBar,
        updateGoodRoadRefresh,
        updateRecentlyPlayed,
    },
});
export const lobbySliceReducer = lobbySlice.reducer;
export const lobbySliceActions = lobbySlice.actions;
