import { CMDSicboStatistics } from "./CMDSicboStatistics"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpSicboStatistics implements ICommand
{

	public HostID : number = 0;
	public Statistics : CMDSicboStatistics;
	public MaxRecord : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Statistics = new CMDSicboStatistics();
		this.Statistics.fromBytes(ba);
		this.MaxRecord = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		let tempBuf1 = this.Statistics.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		ba.writeUint53(this.MaxRecord);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpSicboStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Statistics(CMDSicboStatistics)='+this.Statistics+' ';
		str += 'MaxRecord(Q)='+this.MaxRecord+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50046;
	}

	public getCmdName() : string
	{
		return 'SpSicboStatistics';
	}

}
