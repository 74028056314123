import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScShuffle implements ICommand
{

	public HostID : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScShuffle();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30010;
	}

	public getCmdName() : string
	{
		return 'ScShuffle';
	}

}
