import { CMDPromotionPrize } from "./CMDPromotionPrize"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPromotionEvent implements ICommand
{

	public EventURL : string = "";
	public StartTimeUTC : number = 0;
	public EndTimeUTC : number = 0;
	public Timezone : number = 0;
	public BudgetAmount : number = 0;
	public PopUp : number = 0;
	public MaxDayLimit : number = 0;
	public RemainDayLimit : number = 0;
	public PromotionPrize : Array<CMDPromotionPrize>;

	public constructor()
	{
		this.PromotionPrize = new Array<CMDPromotionPrize>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.EventURL = ba.readString();
		this.StartTimeUTC = ba.readInt53();
		this.EndTimeUTC = ba.readInt53();
		this.Timezone = ba.readUint16();
		this.BudgetAmount = ba.readInt53();
		this.PopUp = ba.readUint8();
		this.MaxDayLimit = ba.readUint32();
		this.RemainDayLimit = ba.readUint32();
		let PromotionPrizeSize:number = ba.readUint16();
		while (PromotionPrizeSize-- > 0)
		{
			let PromotionPrizeChild:CMDPromotionPrize = new CMDPromotionPrize();
			PromotionPrizeChild.fromBytes(ba);
			this.PromotionPrize.push(PromotionPrizeChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.EventURL);
		ba.writeUint53(this.StartTimeUTC);
		ba.writeUint53(this.EndTimeUTC);
		ba.writeUint16(this.Timezone);
		ba.writeUint53(this.BudgetAmount);
		ba.writeUint8(this.PopUp);
		ba.writeUint32(this.MaxDayLimit);
		ba.writeUint32(this.RemainDayLimit);
		ba.writeUint16(this.PromotionPrize.length);
		for (const PromotionPrizeChild of this.PromotionPrize)
		{
			let tempBuf = PromotionPrizeChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPromotionEvent();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'EventURL(S)='+this.EventURL+' ';
		str += 'StartTimeUTC(Q)='+this.StartTimeUTC+' ';
		str += 'EndTimeUTC(Q)='+this.EndTimeUTC+' ';
		str += 'Timezone(W)='+this.Timezone+' ';
		str += 'BudgetAmount(Q)='+this.BudgetAmount+' ';
		str += 'PopUp(B)='+this.PopUp+' ';
		str += 'MaxDayLimit(D)='+this.MaxDayLimit+' ';
		str += 'RemainDayLimit(D)='+this.RemainDayLimit+' ';
		str += 'PromotionPrize_size(W)='+this.PromotionPrize.length + ' ';
		str += 'PromotionPrize(CMDPromotionPrize)=[';
		for (let PromotionPrizeChild in this.PromotionPrize)
		{
			if (<number><unknown>PromotionPrizeChild > 0) str += ', ';
			str += this.PromotionPrize[PromotionPrizeChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50119;
	}

	public getCmdName() : string
	{
		return 'SpPromotionEvent';
	}

}
