import { useEffect, useState } from 'react';

export const useAspectRatio = () => {
    const { innerWidth, innerHeight } = window;
    const [aspectRatio, setAspectRatio] = useState(0);

    useEffect(() => {
        const as = innerWidth / innerHeight;
        setAspectRatio(as);
    }, [innerWidth, innerHeight]);
    return { aspectRatio, innerWidth, innerHeight };
};
