import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDEgameConfig implements ICommand
{

	public GameCode : string = "";
	public Testing : number = 0;
	public Maintenance : number = 0;
	public Thumbnail : string = "";
	public Link : string = "";
	public CompanyName : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameCode = ba.readString();
		this.Testing = ba.readUint8();
		this.Maintenance = ba.readUint8();
		this.Thumbnail = ba.readString();
		this.Link = ba.readString();
		this.CompanyName = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.GameCode);
		ba.writeUint8(this.Testing);
		ba.writeUint8(this.Maintenance);
		ba.writeString(this.Thumbnail);
		ba.writeString(this.Link);
		ba.writeString(this.CompanyName);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDEgameConfig();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameCode(S)='+this.GameCode+' ';
		str += 'Testing(B)='+this.Testing+' ';
		str += 'Maintenance(B)='+this.Maintenance+' ';
		str += 'Thumbnail(S)='+this.Thumbnail+' ';
		str += 'Link(S)='+this.Link+' ';
		str += 'CompanyName(S)='+this.CompanyName+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'EgameConfig';
	}

}
