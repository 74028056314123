import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPlayerRecentlyPlay implements ICommand
{

	public PlayerRecentlyPlay1 : number = 0;
	public PlayerRecentlyPlay2 : number = 0;
	public PlayerRecentlyPlay3 : number = 0;
	public PlayerRecentlyPlay4 : number = 0;
	public PlayerRecentlyPlay5 : number = 0;
	public PlayerRecentlyPlay6 : number = 0;
	public PlayerRecentlyPlay7 : number = 0;
	public PlayerRecentlyPlay8 : number = 0;
	public PlayerRecentlyPlay9 : number = 0;
	public PlayerRecentlyPlay10 : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerRecentlyPlay1 = ba.readUint32();
		this.PlayerRecentlyPlay2 = ba.readUint32();
		this.PlayerRecentlyPlay3 = ba.readUint32();
		this.PlayerRecentlyPlay4 = ba.readUint32();
		this.PlayerRecentlyPlay5 = ba.readUint32();
		this.PlayerRecentlyPlay6 = ba.readUint32();
		this.PlayerRecentlyPlay7 = ba.readUint32();
		this.PlayerRecentlyPlay8 = ba.readUint32();
		this.PlayerRecentlyPlay9 = ba.readUint32();
		this.PlayerRecentlyPlay10 = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.PlayerRecentlyPlay1);
		ba.writeUint32(this.PlayerRecentlyPlay2);
		ba.writeUint32(this.PlayerRecentlyPlay3);
		ba.writeUint32(this.PlayerRecentlyPlay4);
		ba.writeUint32(this.PlayerRecentlyPlay5);
		ba.writeUint32(this.PlayerRecentlyPlay6);
		ba.writeUint32(this.PlayerRecentlyPlay7);
		ba.writeUint32(this.PlayerRecentlyPlay8);
		ba.writeUint32(this.PlayerRecentlyPlay9);
		ba.writeUint32(this.PlayerRecentlyPlay10);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPlayerRecentlyPlay();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerRecentlyPlay1(D)='+this.PlayerRecentlyPlay1+' ';
		str += 'PlayerRecentlyPlay2(D)='+this.PlayerRecentlyPlay2+' ';
		str += 'PlayerRecentlyPlay3(D)='+this.PlayerRecentlyPlay3+' ';
		str += 'PlayerRecentlyPlay4(D)='+this.PlayerRecentlyPlay4+' ';
		str += 'PlayerRecentlyPlay5(D)='+this.PlayerRecentlyPlay5+' ';
		str += 'PlayerRecentlyPlay6(D)='+this.PlayerRecentlyPlay6+' ';
		str += 'PlayerRecentlyPlay7(D)='+this.PlayerRecentlyPlay7+' ';
		str += 'PlayerRecentlyPlay8(D)='+this.PlayerRecentlyPlay8+' ';
		str += 'PlayerRecentlyPlay9(D)='+this.PlayerRecentlyPlay9+' ';
		str += 'PlayerRecentlyPlay10(D)='+this.PlayerRecentlyPlay10+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50126;
	}

	public getCmdName() : string
	{
		return 'SpPlayerRecentlyPlay';
	}

}
