import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
export type Count = {
    HostId: number;
    Count: number;
};
export const PCOUNT_KEY = 'app::count';
export const countsAdapter = createEntityAdapter<Count, number>({
    selectId: count => count.HostId,
    sortComparer: (a, b) => a.HostId - b.HostId,
});
const initialCountsState = countsAdapter.getInitialState();
const countsSlice = createSlice({
    name: PCOUNT_KEY,
    initialState: initialCountsState,
    reducers: {
        updateAll: countsAdapter.upsertMany,
        update: countsAdapter.upsertOne,
    },
});
export const countsSliceReducer = countsSlice.reducer;
export const countsSliceActions = countsSlice.actions;
// selector
export const getCountsSelectors = countsAdapter.getSelectors<RootState>(
    state => state[PCOUNT_KEY]
);
export const getPlayerCount = getCountsSelectors.selectById;
