import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    BetPlayer,
    betPlayersSliceActions,
    getNewPlayer,
} from '../../../modules/bet/players/slice';
import { CMDScInitSync, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitSyncHandler extends AbstractHandler {
    _command: CMDScInitSync;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitSync;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const payload: BetPlayer = getNewPlayer(this._command.Data.PlayerID);
        payload.name = this._command.Name;
        dispatch(betPlayersSliceActions.add(payload));
    }
}
