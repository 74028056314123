import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { PokDengTotalBet } from '../../games/pokdeng';
import { CMDScTotalBetPokdeng, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetPokdengHandler extends AbstractHandler {
    _command: CMDScTotalBetPokdeng;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetPokdeng;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as PokDengTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;
        TotalBet.Player1 = this.convertBetAmount(this._command.Player1Win);
        TotalBet.Player2 = this.convertBetAmount(this._command.Player2Win);
        TotalBet.Player3 = this.convertBetAmount(this._command.Player3Win);
        TotalBet.Player4 = this.convertBetAmount(this._command.Player4Win);
        TotalBet.Player5 = this.convertBetAmount(this._command.Player5Win);
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };

        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
