import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetRule implements ICommand
{

	public Selected : number = 0;
	public RuleID : number = 0;
	public MinBet : number = 0;
	public LimitRed : number = 0;
	public Token : Array<number>;

	public constructor()
	{
		this.Token = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Selected = ba.readUint8();
		this.RuleID = ba.readInt53();
		this.MinBet = ba.readUint32();
		this.LimitRed = ba.readInt53();
		let TokenSize:number = ba.readUint8();
		while (TokenSize-- > 0)
		{
			let TokenChild:number = ba.readUint32();
			this.Token.push(TokenChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Selected);
		ba.writeUint53(this.RuleID);
		ba.writeUint32(this.MinBet);
		ba.writeUint53(this.LimitRed);
		ba.writeUint8(this.Token.length);
		for (const TokenChild of this.Token)
		{
			ba.writeUint32(TokenChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetRule();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Selected(B)='+this.Selected+' ';
		str += 'RuleID(Q)='+this.RuleID+' ';
		str += 'MinBet(D)='+this.MinBet+' ';
		str += 'LimitRed(Q)='+this.LimitRed+' ';
		str += 'Token_Size(B)='+this.Token.length + ' ';
		str += 'Token(D)=[';
		for (let TokenChild in this.Token)
		{
			if (<number><unknown>TokenChild > 0) str += ', ';
			str += this.Token[TokenChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetRule';
	}

}
