import { ProgressLoadingState } from '../../models/games/Progress';
import { RootState } from '../../store/store';
import { PROGRESS_KEY, ProgressState } from './slice';
import { LOADING_PROGRESS_KEY } from './slice/loading';
import { RESULT_PROGRESS_KEY } from './slice/result';
import { PROGRESS_STATUS_KEY, ProgressStatusState } from './slice/status';
import { PROGRESS_TIME_KEY, ProgressTimeState } from './slice/time';

export const getProgressState = (state: RootState) =>
    state[PROGRESS_KEY] as ProgressState;
export const getLoadingProgressState = (state: RootState) =>
    getProgressState(state)[LOADING_PROGRESS_KEY] as ProgressLoadingState;
export const getResultProgressState = (state: RootState) =>
    getProgressState(state)[RESULT_PROGRESS_KEY] as ProgressLoadingState;
export const getProgressStatusState = (state: RootState) =>
    getProgressState(state)[PROGRESS_STATUS_KEY] as ProgressStatusState;
export const getProgressTimeState = (state: RootState) =>
    getProgressState(state)[PROGRESS_TIME_KEY] as ProgressTimeState;
