export enum BetType {
    BJB1_Split1,
    BJB2_Split1,
    BJB3_Split1,
    BJB4_Split1,
    BJB5_Split1,
    BJB6_Split1,
    BJB7_Split1,

    BJB1_Split2,
    BJB2_Split2,
    BJB3_Split2,
    BJB4_Split2,
    BJB5_Split2,
    BJB6_Split2,
    BJB7_Split2,

    BJB1_DoubleDown,
    BJB2_DoubleDown,
    BJB3_DoubleDown,
    BJB4_DoubleDown,
    BJB5_DoubleDown,
    BJB6_DoubleDown,
    BJB7_DoubleDown,

    BJB1_Pair,
    BJB2_Pair,
    BJB3_Pair,
    BJB4_Pair,
    BJB5_Pair,
    BJB6_Pair,
    BJB7_Pair,

    BJB_Insurance,

    BJBMaxBet,
}
