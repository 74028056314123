import {
    EntityState,
    PayloadAction,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { Range } from '../../main/slice/player';

export const BET_PLAYERS_KEY = 'app::bet::players';
export type BetPlayer = {
    id: number;
    name: string;
    balance: Range;
    holding: Range;
    currencyType: Range;
    disable: boolean;
};
export const getNewPlayer = (playerId: number): BetPlayer => ({
    id: playerId,
    name: '',
    balance: getNewRange(),
    holding: getNewRange(),
    currencyType: getNewRange(),
    disable: false,
});
export const getNewRange = () => ({ Low: 0, High: 0 });
export const betPlayersAdapter = createEntityAdapter<BetPlayer, number>({
    selectId: p => p.id,
    sortComparer: (a, b) => a.id - b.id,
});
const initialBetPlayersState = betPlayersAdapter.getInitialState();
type UpdateRangePayload = {
    playerId: number;
    data: 'balance' | 'holding' | 'currencyType';
    range: 'Low' | 'High';
    value: number;
};
type StatusPayload = {
    playerId: number;
    status: boolean;
};
const update = (
    state: EntityState<BetPlayer, number>,
    action: PayloadAction<UpdateRangePayload>
) => {
    const playerId = action.payload.playerId;
    const newPlayer = getNewPlayer(playerId);
    const oldPlayer = state.entities[playerId];
    if (oldPlayer) {
        newPlayer.name = oldPlayer.name;
        newPlayer.balance = oldPlayer.balance;
        newPlayer.holding = oldPlayer.holding;
        newPlayer.currencyType = oldPlayer.currencyType;
        newPlayer.disable = oldPlayer.disable;
    }
    newPlayer[action.payload.data][action.payload.range] = action.payload.value;
    return betPlayersAdapter.updateOne(state, {
        id: playerId,
        changes: newPlayer,
    });
};
const status = (
    state: EntityState<BetPlayer, number>,
    action: PayloadAction<StatusPayload>
) =>
    betPlayersAdapter.updateOne(state, {
        id: action.payload.playerId,
        changes: {
            disable: action.payload.status,
        },
    });
const betPlayersSlice = createSlice({
    name: BET_PLAYERS_KEY,
    initialState: initialBetPlayersState,
    reducers: {
        add: betPlayersAdapter.upsertOne,
        status,
        update,
    },
});
export const betPlayersSliceReducer = betPlayersSlice.reducer;
export const betPlayersSliceActions = betPlayersSlice.actions;

const getBetPlayerSelectors = betPlayersAdapter.getSelectors<RootState>(
    state => state[BET_PLAYERS_KEY]
);
export const getPlayers = getBetPlayerSelectors.selectAll;
export const getPlayer = (state: RootState, playerId: number | undefined) =>
    playerId ? getBetPlayerSelectors.selectById(state, playerId) : undefined;
