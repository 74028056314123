import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpPromotionRedeemLog, ICommand } from "../../live";
import { AbstractHandler } from "../AbstractHandler";
import { promotionToolSliceActions } from '../../../../modules/main/slice/promotiontool/promotionTool';

export class CMDSpPromotionRedeemLogHandler extends AbstractHandler {
    _command: CMDSpPromotionRedeemLog;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPromotionRedeemLog;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const cmd = this._command;
        // console.log(`command::handler::${cmd.getCmdName}::${cmd.toString()}`, cmd);
        const recs = cmd.Record.map(list => JSON.stringify(list));
        dispatch(promotionToolSliceActions.updateRedeemLog(recs));
    }
}