import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { PokerShow } from '../../games/PokerShow';
import { CMDScPokerShow, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDScPokerShowHandler extends AbstractHandler {
    _command: CMDScPokerShow;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScPokerShow;
    }
    commandDelay() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        return host.DelayDraw;
    }
    delayKey() {
        return this._command.GameID.toString();
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);

        if (host) {
            console.log(`hook::socket::run::${host.HostId}`, this._command);
            let results = new Array<PokerShow>();

            if (host.PokerShowResult) {
                const isEdit = host.PokerShowResult.find(
                    p => p.Slot === this._command.PokerData.SlotIndex
                );
                if (!isEdit) {
                    //reset if edit
                    results = results.concat(host.PokerShowResult);
                }
            }

            results.push({
                Slot: this._command.PokerData.SlotIndex,
                // card index from poker show starts with 1, not 0
                Card: this._command.PokerData.CardIndex - 1,
            });

            dispatch(
                hostsSliceActions.updatePokerShow({
                    hostId: host.HostId,
                    results: results,
                })
            );
        } else {
            console.warn(
                'hook::socket::warning::cannot::find::host',
                this._command.GameID
            );
        }
    }
}
