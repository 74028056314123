import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScRoundReset, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { GameType as GameTypeEnum } from '../../games/enums/GameType';
import { batch } from 'react-redux';

export class CMDScRoundResetHandler extends AbstractHandler {
    _command: CMDScRoundReset;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScRoundReset;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        if (host) {
            batch(() => {
                switch (host.GameType) {
                    case GameTypeEnum.Baccarat:
                    case GameTypeEnum.Dragon:
                        dispatch(
                            hostsSliceActions.updatePokerShow({
                                hostId: host.HostId,
                                results: [],
                            })
                        );
                        break;
                }

                dispatch(
                    hostsSliceActions.updateGameState({
                        hostId: host.HostId,
                        state: GameState.Started,
                    })
                );
            });
        }
    }
}
