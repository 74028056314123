import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { BaccaratTotalBet } from '../../games/baccarat';
import { CMDScTotalBetBaccarat, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetBaccaratHandler extends AbstractHandler {
    _command: CMDScTotalBetBaccarat;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetBaccarat;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as BaccaratTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;
        TotalBet.Banker = this.convertBetAmount(this._command.Banker);
        TotalBet.BankerNatural = this.convertBetAmount(
            this._command.BankerNatural
        );
        TotalBet.BankerPair = this.convertBetAmount(this._command.BankerPair);
        TotalBet.LuckySix = this.convertBetAmount(this._command.LuckySix);
        TotalBet.Player = this.convertBetAmount(this._command.Player);
        TotalBet.PlayerNatural = this.convertBetAmount(
            this._command.PlayerNatural
        );
        TotalBet.PlayerPair = this.convertBetAmount(this._command.PlayerPair);
        TotalBet.SuperSix = this.convertBetAmount(this._command.SuperSix);
        TotalBet.Tie = this.convertBetAmount(this._command.Tie);
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };

        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
