import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBetLogQueryTotal implements ICommand
{

	public TotalRow : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.TotalRow = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.TotalRow);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBetLogQueryTotal();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'TotalRow(D)='+this.TotalRow+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50025;
	}

	public getCmdName() : string
	{
		return 'SpBetLogQueryTotal';
	}

}
