import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDJackpot implements ICommand
{

	public MajorJP : number = 0;
	public MinorJP : number = 0;
	public MiniJP : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.MajorJP = ba.readInt53();
		this.MinorJP = ba.readInt53();
		this.MiniJP = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.MajorJP);
		ba.writeUint53(this.MinorJP);
		ba.writeUint53(this.MiniJP);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDJackpot();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'MajorJP(Q)='+this.MajorJP+' ';
		str += 'MinorJP(Q)='+this.MinorJP+' ';
		str += 'MiniJP(Q)='+this.MiniJP+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Jackpot';
	}

}
