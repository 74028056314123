import { RootState } from '@/store/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const MULTI_BET_KEY = 'app::roadmap::multibet';
export type MultiBetState = {
    tableIndex: number;
    displayCount: number;
};

const updateIndex = (state: MultiBetState, actions: PayloadAction<number>) => {
    state.tableIndex = actions.payload;
};
const updateDisplayCount = (
    state: MultiBetState,
    actions: PayloadAction<number>
) => {
    state.displayCount = actions.payload;
};

const initialMultiBetState = {} as MultiBetState;
const roadmapSlice = createSlice({
    name: MULTI_BET_KEY,
    initialState: initialMultiBetState,
    reducers: {
        updateIndex,
        updateDisplayCount,
    },
});
export const multiBetSliceReducer = roadmapSlice.reducer;
export const multiBetSliceActions = roadmapSlice.actions;

export const getMultiBetState = (state: RootState): MultiBetState =>
    state[MULTI_BET_KEY];
export const getMultiBetIndex = (state: RootState) =>
    getMultiBetState(state).tableIndex;
