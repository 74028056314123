import { useEffect, useState } from 'react';

const LOG_KEY = 'hook::full::screen';
export const useFullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const fullscreenchangeHandler = () => {
        const ifs = document.fullscreenElement !== null;
        setIsFullScreen(ifs);
    };
    /* View in fullscreen */
    function openFullscreen() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const elem = document.documentElement as HTMLElement | any;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    /* Close fullscreen */
    function closeFullscreen() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const elem = document as Document | any;
        try {
            if (elem.exitFullscreen) {
                elem.exitFullscreen();
            } else if (elem.webkitExitFullscreen) {
                /* Safari */
                elem.webkitExitFullscreen();
            } else if (elem.msExitFullscreen) {
                /* IE11 */
                elem.msExitFullscreen();
            }
        } catch {
            console.warn(LOG_KEY, 'cannot exit fullscreen');
        }
    }
    const toggleFullScreen = () => {
        if (isFullScreen) closeFullscreen();
        else openFullscreen();
    };

    useEffect(() => {
        fullscreenchangeHandler();
        document.addEventListener('fullscreenchange', fullscreenchangeHandler);
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                fullscreenchangeHandler
            );
        };
    }, []);
    return {
        isFullScreen,
        toggleFullScreen,
    };
};
export type useFullScreenState = ReturnType<typeof useFullScreen>;
