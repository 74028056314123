export enum Suit {
    SPADE,
    HEART,
    CLUB,
    DIAMOND,
    NONE,
}

export type PokerCard = {
    text: string;
    suit: Suit;
};
