import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { BlackjackTotalBet } from '../../games/blackjack';
import { CMDScTotalBetBlackjack, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetBlackjackHandler extends AbstractHandler {
    _command: CMDScTotalBetBlackjack;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetBlackjack;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as BlackjackTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;
        TotalBet.Seat1 = this.convertBetAmount(this._command.Seat1);
        TotalBet.Seat2 = this.convertBetAmount(this._command.Seat2);
        TotalBet.Seat3 = this.convertBetAmount(this._command.Seat3);
        TotalBet.Seat4 = this.convertBetAmount(this._command.Seat4);
        TotalBet.Seat5 = this.convertBetAmount(this._command.Seat5);
        TotalBet.Seat6 = this.convertBetAmount(this._command.Seat6);
        TotalBet.Seat7 = this.convertBetAmount(this._command.Seat7);
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };
        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
