import { RootState } from '@/store/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const CHIPS_KEY = 'app::chips';
export type ChipsState = {
    selectedIndex: number;
};

const updateSelectedIndex = (
    state: ChipsState,
    actions: PayloadAction<number>
) => {
    state.selectedIndex = actions.payload;
};

const initialChipsState = {} as ChipsState;
const chipsSlice = createSlice({
    name: CHIPS_KEY,
    initialState: initialChipsState,
    reducers: {
        updateSelectedIndex,
    },
});
export const chipsSliceReducer = chipsSlice.reducer;
export const chipsSliceActions = chipsSlice.actions;

export const getChipsState = (state: RootState): ChipsState => state[CHIPS_KEY];
export const getChipsIndex = (state: RootState) =>
    getChipsState(state).selectedIndex;
