import { CMDBallStatistics } from "./CMDBallStatistics"
import { CMDBaseStatistics } from "./CMDBaseStatistics"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLotteryStatistics implements ICommand
{

	public BaseData : CMDBaseStatistics;
	public Ball : Array<CMDBallStatistics>;

	public constructor()
	{
		this.Ball = new Array<CMDBallStatistics>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseData = new CMDBaseStatistics();
		this.BaseData.fromBytes(ba);
		let BallSize:number = ba.readUint8();
		while (BallSize-- > 0)
		{
			let BallChild:CMDBallStatistics = new CMDBallStatistics();
			BallChild.fromBytes(ba);
			this.Ball.push(BallChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseData.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.Ball.length);
		for (const BallChild of this.Ball)
		{
			let tempBuf = BallChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLotteryStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseData(CMDBaseStatistics)='+this.BaseData+' ';
		str += 'Ball_size(B)='+this.Ball.length + ' ';
		str += 'Ball(CMDBallStatistics)=[';
		for (let BallChild in this.Ball)
		{
			if (<number><unknown>BallChild > 0) str += ', ';
			str += this.Ball[BallChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50032;
	}

	public getCmdName() : string
	{
		return 'SpLotteryStatistics';
	}

}
