import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetSicbo implements ICommand
{

	public Total : CMDTotalBet;
	public Big : CMDBetSummary;
	public Small : CMDBetSummary;
	public Odd : CMDBetSummary;
	public Even : CMDBetSummary;
	public AllTriple : CMDBetSummary;
	public TripleOne : CMDBetSummary;
	public TripleTwo : CMDBetSummary;
	public TripleThree : CMDBetSummary;
	public TripleFour : CMDBetSummary;
	public TripleFive : CMDBetSummary;
	public TripleSix : CMDBetSummary;
	public ShortOne : CMDBetSummary;
	public ShortTwo : CMDBetSummary;
	public ShortThree : CMDBetSummary;
	public ShortFour : CMDBetSummary;
	public ShortFive : CMDBetSummary;
	public ShortSix : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Big = new CMDBetSummary();
		this.Big.fromBytes(ba);
		this.Small = new CMDBetSummary();
		this.Small.fromBytes(ba);
		this.Odd = new CMDBetSummary();
		this.Odd.fromBytes(ba);
		this.Even = new CMDBetSummary();
		this.Even.fromBytes(ba);
		this.AllTriple = new CMDBetSummary();
		this.AllTriple.fromBytes(ba);
		this.TripleOne = new CMDBetSummary();
		this.TripleOne.fromBytes(ba);
		this.TripleTwo = new CMDBetSummary();
		this.TripleTwo.fromBytes(ba);
		this.TripleThree = new CMDBetSummary();
		this.TripleThree.fromBytes(ba);
		this.TripleFour = new CMDBetSummary();
		this.TripleFour.fromBytes(ba);
		this.TripleFive = new CMDBetSummary();
		this.TripleFive.fromBytes(ba);
		this.TripleSix = new CMDBetSummary();
		this.TripleSix.fromBytes(ba);
		this.ShortOne = new CMDBetSummary();
		this.ShortOne.fromBytes(ba);
		this.ShortTwo = new CMDBetSummary();
		this.ShortTwo.fromBytes(ba);
		this.ShortThree = new CMDBetSummary();
		this.ShortThree.fromBytes(ba);
		this.ShortFour = new CMDBetSummary();
		this.ShortFour.fromBytes(ba);
		this.ShortFive = new CMDBetSummary();
		this.ShortFive.fromBytes(ba);
		this.ShortSix = new CMDBetSummary();
		this.ShortSix.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Big.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Small.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Odd.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Even.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.AllTriple.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.TripleOne.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.TripleTwo.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.TripleThree.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)
		let tempBuf9 = this.TripleFour.toBytes().toBuffer();
		ba.appendBuf(tempBuf9)
		let tempBuf10 = this.TripleFive.toBytes().toBuffer();
		ba.appendBuf(tempBuf10)
		let tempBuf11 = this.TripleSix.toBytes().toBuffer();
		ba.appendBuf(tempBuf11)
		let tempBuf12 = this.ShortOne.toBytes().toBuffer();
		ba.appendBuf(tempBuf12)
		let tempBuf13 = this.ShortTwo.toBytes().toBuffer();
		ba.appendBuf(tempBuf13)
		let tempBuf14 = this.ShortThree.toBytes().toBuffer();
		ba.appendBuf(tempBuf14)
		let tempBuf15 = this.ShortFour.toBytes().toBuffer();
		ba.appendBuf(tempBuf15)
		let tempBuf16 = this.ShortFive.toBytes().toBuffer();
		ba.appendBuf(tempBuf16)
		let tempBuf17 = this.ShortSix.toBytes().toBuffer();
		ba.appendBuf(tempBuf17)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetSicbo();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Big(CMDBetSummary)='+this.Big+' ';
		str += 'Small(CMDBetSummary)='+this.Small+' ';
		str += 'Odd(CMDBetSummary)='+this.Odd+' ';
		str += 'Even(CMDBetSummary)='+this.Even+' ';
		str += 'AllTriple(CMDBetSummary)='+this.AllTriple+' ';
		str += 'TripleOne(CMDBetSummary)='+this.TripleOne+' ';
		str += 'TripleTwo(CMDBetSummary)='+this.TripleTwo+' ';
		str += 'TripleThree(CMDBetSummary)='+this.TripleThree+' ';
		str += 'TripleFour(CMDBetSummary)='+this.TripleFour+' ';
		str += 'TripleFive(CMDBetSummary)='+this.TripleFive+' ';
		str += 'TripleSix(CMDBetSummary)='+this.TripleSix+' ';
		str += 'ShortOne(CMDBetSummary)='+this.ShortOne+' ';
		str += 'ShortTwo(CMDBetSummary)='+this.ShortTwo+' ';
		str += 'ShortThree(CMDBetSummary)='+this.ShortThree+' ';
		str += 'ShortFour(CMDBetSummary)='+this.ShortFour+' ';
		str += 'ShortFive(CMDBetSummary)='+this.ShortFive+' ';
		str += 'ShortSix(CMDBetSummary)='+this.ShortSix+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30753;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetSicbo';
	}

}
