import { Bet } from '@/models/host/BetAmount';
import { BetType as BetTypeEnum } from '../../../models/games/sedie/BetType';
import { getBetTypeGroupTotal } from '../baccarat/utils';

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    if (betsSummary === undefined) {
        return false;
    }
    let oppositeType = -1;
    switch (betType) {
        case BetTypeEnum.SDBOdd:
            oppositeType = BetTypeEnum.SDBEven;
            break;
        case BetTypeEnum.SDBEven:
            oppositeType = BetTypeEnum.SDBOdd;
            break;
        case BetTypeEnum.SDBSmall:
            oppositeType = BetTypeEnum.SDBBig;
            break;
        case BetTypeEnum.SDBBig:
            oppositeType = BetTypeEnum.SDBSmall;
            break;
    }

    if (oppositeType < 0) {
        return false;
    }
    return (
        betsSummary[oppositeType] != undefined && betsSummary[oppositeType] > 0
    );
};

export const getSeDieCurrentTotal = (
    betType: number,
    BetsSummary: Array<number>,
    ConfirmedBets: Array<number>
) => {
    switch (betType) {
        case BetTypeEnum.SDBOdd:
            return (
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBOdd],
                    BetsSummary,
                    ConfirmedBets
                ) -
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBEven],
                    BetsSummary,
                    ConfirmedBets
                )
            );
        case BetTypeEnum.SDBEven:
            return (
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBEven],
                    BetsSummary,
                    ConfirmedBets
                ) -
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBOdd],
                    BetsSummary,
                    ConfirmedBets
                )
            );
        case BetTypeEnum.SDBSmall:
            return (
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBSmall],
                    BetsSummary,
                    ConfirmedBets
                ) -
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBBig],
                    BetsSummary,
                    ConfirmedBets
                )
            );
        case BetTypeEnum.SDBBig:
            return (
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBBig],
                    BetsSummary,
                    ConfirmedBets
                ) -
                getBetTypeGroupTotal(
                    [BetTypeEnum.SDBSmall],
                    BetsSummary,
                    ConfirmedBets
                )
            );
        default:
            return getBetTypeGroupTotal([betType], BetsSummary, ConfirmedBets);
    }
};

export const getSeDieHedgeBettingBets = (bets: Array<Bet>) => {
    const newBets = new Array<Bet>();
    let bigTotal = 0;
    let smallTotal = 0;
    let oddTotal = 0;
    let evenTotal = 0;
    const latestGameId = bets.at(-1)?.GameId ?? 0;
    for (const bet of bets) {
        switch (bet.Type) {
            case BetTypeEnum.SDBBig:
                bigTotal += bet.Amount;
                break;
            case BetTypeEnum.SDBSmall:
                smallTotal += bet.Amount;
                break;
            case BetTypeEnum.SDBOdd:
                oddTotal += bet.Amount;
                break;
            case BetTypeEnum.SDBEven:
                evenTotal += bet.Amount;
                break;
            default:
                newBets.push(bet);
                break;
        }
    }
    const diff1 = bigTotal - smallTotal;
    if (diff1 !== 0) {
        const betType = diff1 > 0 ? BetTypeEnum.SDBBig : BetTypeEnum.SDBSmall;
        const betAmount = Math.abs(diff1);
        const newBet: Bet = {
            GameId: latestGameId,
            Type: betType,
            Amount: betAmount,
        };
        newBets.push(newBet);
    }
    const diff2 = oddTotal - evenTotal;
    if (diff2 !== 0) {
        const betType = diff2 > 0 ? BetTypeEnum.SDBOdd : BetTypeEnum.SDBEven;
        const betAmount = Math.abs(diff2);
        const newBet: Bet = {
            GameId: latestGameId,
            Type: betType,
            Amount: betAmount,
        };
        newBets.push(newBet);
    }

    return newBets;
};
