export enum PlayerPattern {
    PPHighCard,
    PPPair,
    PPFlush,
    PPStraight,
    PPStraightFlush,
    PPThreeOfKind,
    PPThreeOfKindAce,
}

export enum SixCardPattern {
    SCPNone,
    SCPThreeOfKind,
    SCPStraight,
    SCPFlush,
    SCPFullHouse,
    SCPFourOfKind,
    SCPStraightFlush,
    SCPRoyalFlush,
}
