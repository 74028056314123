import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AbstractHandler } from './AbstractHandler';
import { CMDSpPlayerRecentlyPlay, ICommand } from '../live';
import { lobbySliceActions } from '@/modules/lobby/slice';
import { LocalStorageKey } from '@/hooks/storage/useLocalStorage';

export class CMDSpPlayerRecentlyPlayHandler extends AbstractHandler {
    _command: CMDSpPlayerRecentlyPlay;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPlayerRecentlyPlay;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        let list = [
            this._command.PlayerRecentlyPlay1,
            this._command.PlayerRecentlyPlay2,
            this._command.PlayerRecentlyPlay3,
            this._command.PlayerRecentlyPlay4,
            this._command.PlayerRecentlyPlay5,
            this._command.PlayerRecentlyPlay6,
            this._command.PlayerRecentlyPlay7,
            this._command.PlayerRecentlyPlay8,
        ];
        const recentlyPlayList = list
            .filter(item => item != 0)
            .map(item => {
                return { hostId: item };
            });

        window.localStorage.setItem(
            LocalStorageKey.RecentlyPlayed,
            JSON.stringify({ hostIds: recentlyPlayList })
        );

        dispatch(lobbySliceActions.updateRecentlyPlayed(recentlyPlayList));
    }
}
