import { POPUP_TYPE } from '@/models/Popup';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { LocalStorageKey } from '../../../hooks/storage/useLocalStorage';
import {
    CountryMessage,
    popupSliceActions,
} from '../../../modules/popup/slice';
import { GameError } from '../../games/enums/GameError';
import { CMDSpLoginFail, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class LoginFailHandler extends AbstractHandler {
    _command: CMDSpLoginFail;
    _specialMsg: string;
    _isMessageExist: (key: string) => boolean;
    constructor(
        command: ICommand,
        specialMsg: string,
        isMessageExist: (key: string) => boolean
    ) {
        super();
        this._command = command as CMDSpLoginFail;
        this._specialMsg = specialMsg;
        this._isMessageExist = isMessageExist;
    }
    countryMessage(): CountryMessage | undefined {
        const reason = this._command.Reason;
        switch (reason) {
            case GameError.CurrencyNotAllowedInClientCountry:
                return {
                    messageKey: 'popup.RegionCurrencyError',
                    params: {
                        ip: this._command.IP,
                        countryCode: this._command.CountryCode,
                        currency: this._command.CurrencyName,
                    },
                };
            case GameError.IPBlocked:
                return {
                    messageKey: 'popup.RestrictedLocation',
                    params: {
                        ip: this._command.IP,
                        countryCode: this._command.CountryCode,
                        currency: this._command.CurrencyName,
                    },
                };
        }
        return undefined;
    }
    needRequestCountryName(): boolean {
        const reason = this._command.Reason;
        switch (reason) {
            case GameError.CurrencyNotAllowedInClientCountry:
            case GameError.IPBlocked:
                return true;
        }
        return false;
    }
    isMaxConnection(): boolean {
        return this._command.Reason === GameError.LobbyMaxConnection;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        console.log('hook::socket::closed', this._command.toString());
        const reason = this._command.Reason;
        let msg = '';
        if (this._specialMsg !== '') {
            const key = `system.${this._specialMsg}.${this._command.Reason}`;
            if (this._isMessageExist(key)) {
                msg = key;
            }
        }
        switch (reason) {
            case GameError.LobbyMaxConnection:
                break;
            case GameError.TokenError:
                window.localStorage.removeItem(LocalStorageKey.FullParameter);
                if (!window.localStorage.getItem(LocalStorageKey.ClearReason)) {
                    window.localStorage.setItem(
                        LocalStorageKey.ClearReason,
                        (3000 + GameError.TokenError).toString()
                    );
                }
                dispatch(
                    popupSliceActions.open(
                        msg !== '' ? msg : 'popup.error26',
                        POPUP_TYPE.LEAVE_ALERT
                    )
                );
                break;
            case GameError.TokenExpire:
                window.localStorage.removeItem(LocalStorageKey.FullParameter);
                if (!window.localStorage.getItem(LocalStorageKey.ClearReason)) {
                    window.localStorage.setItem(
                        LocalStorageKey.ClearReason,
                        (3000 + GameError.TokenExpire).toString()
                    );
                }
                dispatch(
                    popupSliceActions.open(
                        msg !== ''
                            ? msg
                            : [
                                  'popup.login_failed',
                                  '(',
                                  reason.toString(),
                                  ')',
                              ],
                        POPUP_TYPE.LEAVE_ALERT
                    )
                );
                break;
            case GameError.GamePlatformError:
                dispatch(
                    popupSliceActions.open(
                        msg !== '' ? msg : 'popup.game_platform',
                        POPUP_TYPE.LEAVE_ALERT
                    )
                );
                break;
            case GameError.HostListEmpty:
                dispatch(
                    popupSliceActions.open(
                        msg !== '' ? msg : 'popup.host_list_empty',
                        POPUP_TYPE.LEAVE_ALERT
                    )
                );
                break;
            case GameError.InMaintenance:
                dispatch(
                    popupSliceActions.open(
                        msg !== '' ? msg : 'popup.maintaining',
                        POPUP_TYPE.THANK_ALERT
                    )
                );
                break;
            case GameError.InActive:
                dispatch(
                    popupSliceActions.open(
                        msg !== ''
                            ? msg
                            : [
                                  'popup.login_failed',
                                  '(',
                                  'popup.account_locked',
                                  ')',
                              ],
                        POPUP_TYPE.LEAVE_ALERT
                    )
                );
                break;
            case GameError.IPBlocked:
            case GameError.CurrencyNotAllowedInClientCountry:
                //Handle outside handler
                break;
            // case GameError.TokenError:
            // case GameError.LoginTimeout:
            // case GameError.CASH_WITHOUT_AT:
            // case GameError.CREDIT_WITH_AT:
            // dispatch(
            //     popupSliceActions.open([
            //         'popup.login_failed',
            //         '(',
            //         reason.toString(),
            //         ')',
            //     ])
            // );
            //     break;
            default:
                //TODO: isReconnect error popup
                dispatch(
                    popupSliceActions.open(
                        msg !== ''
                            ? msg
                            : [
                                  'popup.login_failed',
                                  '(',
                                  reason.toString(),
                                  ')',
                              ],
                        POPUP_TYPE.LEAVE_ALERT
                    )
                );
        }
    }
}
