import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Bet } from '../../models/host/BetAmount';

const BACCARAT_HISTORY_LIMIT: number = 50;

const setNetworkState = (
    state: GameState,
    action: PayloadAction<{
        online?: boolean;
        notToReconnect?: boolean;
        needUserConfirm?: boolean;
    }>
) => {
    const { online, notToReconnect, needUserConfirm } = action.payload;

    if (online != undefined) state.online = online;
    if (notToReconnect != undefined) state.notToReconnect = notToReconnect;
    if (needUserConfirm != undefined) state.needUserConfirm = needUserConfirm;
};
const setCurrentProxyID = (
    state: GameState,
    action: PayloadAction<{ proxyID: number }>
) => {
    const { proxyID } = action.payload;

    state.proxyID = proxyID;
};

const setCurrentVideoIndex = (
    state: GameState,
    action: PayloadAction<{ currentVideoIndex: number }>
) => {
    const { currentVideoIndex } = action.payload;

    state.currentVideoIndex = currentVideoIndex;
};

const setInitBlock = (
    state: GameState,
    action: PayloadAction<{ block: boolean }>
) => {
    const { block } = action.payload;

    state.initBlock = block;
};
const setCurrentGame = (
    state: GameState,
    action: PayloadAction<{
        gameId: number;
        // bets: Array<Bet>;
    }>
) => {
    const { gameId } = action.payload;

    if (state.currentGameId !== gameId) {
        // state.confirmedBets = bets;
        state.waitingBetResult = false;
    }
    state.currentGameId = gameId;
};
const onConfirmedBetResult = (
    state: GameState,
    action: PayloadAction<{
        gameId: number;
        bet: Array<Bet>;
    }>
) => {
    const { gameId } = action.payload;
    if (gameId == state.currentGameId) {
        // state.prevBets = state.confirmedBets = action.payload.bet;

        // Reset pending bet
        state.waitingBetResult = false;
    }
};
const setCurrentGameState = (
    state: GameState,
    action: PayloadAction<{ gameId: number; gameState: number }>
) => {
    const { gameId, gameState } = action.payload;

    if (state.currentGameId === gameId) {
        state.currentGameState = gameState;
        state.waitingBetResult = false;
    }
};
const setOnceEvent = (
    state: GameState,
    action: PayloadAction<{ gameId: number; event: string }>
) => {
    const { gameId, event } = action.payload;
    if (state.currentGameId === gameId) {
        state.onceEvent = event;
    }
};
const onDoubleBet = (
    state: GameState,
    action: PayloadAction<{ onClick: boolean }>
) => {
    const { onClick } = action.payload;
    state.doubleBet = onClick;
};
const onReBet = (
    state: GameState,
    action: PayloadAction<{ onClick: boolean }>
) => {
    const { onClick } = action.payload;
    state.reBet = onClick;
};
const resetAll = (state: GameState) => {
    state.doubleBet = initialGameState.doubleBet;
    state.reBet = initialGameState.reBet;
    state.currentGameId = initialGameState.currentGameId;
    state.currentGameState = initialGameState.currentGameState;
    state.onceEvent = initialGameState.onceEvent;
    state.waitingBetResult = initialGameState.waitingBetResult;
    state.resetRoulSelectedTypes = initialGameState.resetRoulSelectedTypes;
};
const setLastVideo = (state: GameState, action: PayloadAction<string>) => {
    state.lastVideo = action.payload;
};
const setVideoLibraryVersion = (
    state: GameState,
    action: PayloadAction<string>
) => {
    state.videoLibraryVersion = action.payload;
};
const setWaitingBetResult = (
    state: GameState,
    action: PayloadAction<boolean>
) => {
    state.waitingBetResult = action.payload;
};
const onResetRoulSelectedTypes = (state: GameState) => {
    const count = state.resetRoulSelectedTypes;
    state.resetRoulSelectedTypes = count + 1;
};
const updateHostBetInfo = (
    state: GameState,
    action: PayloadAction<TableBetInfo>
) => {
    let total = {
        hostId: 0,
        totalBetAmount: 0,
        totalWithHoldAmount: 0,
        pendingBetAmount: 0,
        pendingWithHoldAmount: 0,
    } as TableBetInfo;
    let tmpList = state.hostsBetInfo ? [...state.hostsBetInfo] : [];
    const idx = tmpList.findIndex(
        item => item.hostId === action.payload.hostId
    );
    if (action.payload.totalBetAmount === 0) {
        if (idx >= 0) {
            tmpList.splice(idx, 1);
        }
    } else {
        if (idx < 0) {
            tmpList.push(action.payload);
        } else {
            tmpList[idx].totalBetAmount = action.payload.totalBetAmount;
            tmpList[idx].pendingBetAmount = action.payload.pendingBetAmount;
            tmpList[idx].totalWithHoldAmount =
                action.payload.totalWithHoldAmount;
            tmpList[idx].pendingWithHoldAmount =
                action.payload.pendingWithHoldAmount;
        }
    }
    tmpList.forEach(v => {
        total.totalBetAmount += v.totalBetAmount;
        total.pendingBetAmount += v.pendingBetAmount;
        total.totalWithHoldAmount += v.totalWithHoldAmount;
        total.pendingWithHoldAmount += v.pendingWithHoldAmount;
    });
    state.hostsBetInfo = tmpList;
    state.totalHostsBetInfo = total;
};
const updateBaccaratBetHistory = (
    state: GameState,
    action: PayloadAction<BaccaratBetsHistoryPayload>
) => {
    let tmpList = state.baccaratBetsHistory
        ? [...state.baccaratBetsHistory]
        : [];

    let hostBets = tmpList.filter(v => v.gameId === action.payload.gameId);
    let insertedBetType = new Array<number>();
    hostBets.forEach(v => {
        if (!v.isResultRelease) {
            if (!action.payload.betsSummary[v.betType]) {
                let idx = tmpList.indexOf(v);
                if (idx >= 0) {
                    tmpList.splice(idx, 1);
                }
            } else {
                v.betAmount = action.payload.betsSummary[v.betType];
                insertedBetType.push(v.betType);
            }
        } else {
            insertedBetType.push(v.betType);
        }
    });
    action.payload.betsSummary.forEach((amount, index) => {
        if (insertedBetType.indexOf(index) < 0 && amount) {
            tmpList.push({
                hostId: action.payload.hostId,
                gameId: action.payload.gameId,
                betType: index,
                betAmount: amount,
                isResultRelease: false,
                isCancel: false,
                winAmount: 0,
            });
        }
    });

    let total = 0;
    tmpList.forEach(v => {
        if (!v.isResultRelease) {
            total += v.betAmount;
        }
    });
    state.baccaratBetsHistory = tmpList.splice(
        Math.max(tmpList.length - BACCARAT_HISTORY_LIMIT, 0)
    );
    state.totalBaccaratBetAmount = total;
};

const updateBaccaratBetHistoryCancel = (
    state: GameState,
    action: PayloadAction<number>
) => {
    let tmpList = state.baccaratBetsHistory
        ? [...state.baccaratBetsHistory]
        : [];

    let hostBets = tmpList.filter(v => v.gameId === action.payload);
    hostBets.forEach(v => {
        v.isCancel = true;
        v.isResultRelease = true;
    });

    let total = 0;
    tmpList.forEach(v => {
        if (!v.isResultRelease) {
            total += v.betAmount;
        }
    });
    state.baccaratBetsHistory = tmpList.splice(
        Math.max(tmpList.length - BACCARAT_HISTORY_LIMIT, 0)
    );
    state.totalBaccaratBetAmount = total;
};

const updateBaccaratBetHistoryPayout = (
    state: GameState,
    action: PayloadAction<Array<BaccaratBetsHistory>>
) => {
    let tmpList = state.baccaratBetsHistory
        ? [...state.baccaratBetsHistory]
        : [];

    action.payload.forEach(his => {
        let idx = tmpList.findIndex(
            item => item.gameId === his.gameId && item.betType === his.betType
        );
        if (his.isResultRelease) {
            if (idx >= 0) {
                tmpList[idx].winAmount = his.winAmount;
                tmpList[idx].isResultRelease = his.isResultRelease;
            }
        }
    });
    let total = 0;
    tmpList.forEach(v => {
        if (!v.isResultRelease) {
            total += v.betAmount;
        }
    });
    state.baccaratBetsHistory = tmpList;
    state.totalBaccaratBetAmount = total;
};

type TableBetInfo = {
    hostId: number;
    totalBetAmount: number;
    totalWithHoldAmount: number;
    pendingBetAmount: number;
    pendingWithHoldAmount: number;
};

type BaccaratBetsHistoryPayload = {
    hostId: number;
    gameId: number;
    betsSummary: Array<number>;
};

export type BaccaratBetsHistory = {
    hostId: number;
    gameId: number;
    betType: number;
    betAmount: number;
    isResultRelease: boolean;
    isCancel: boolean;
    winAmount: number;
};

export type GameState = {
    proxyID: number;
    currentVideoIndex: number;
    doubleBet: boolean;
    reBet: boolean;
    currentGameId: number;
    currentGameState: number;
    online: boolean;
    notToReconnect: boolean;
    needUserConfirm: boolean;
    onceEvent: string;
    lastVideo: string;
    videoLibraryVersion: string;
    waitingBetResult: boolean;
    initBlock: boolean; //
    resetRoulSelectedTypes: number; // for roulette
    hostsBetInfo: Array<TableBetInfo>;
    totalHostsBetInfo: TableBetInfo;
    baccaratBetsHistory: Array<BaccaratBetsHistory>;
    totalBaccaratBetAmount: number; //For multibet total display
};
const initialGameState = {
    proxyID: 0,
    currentVideoIndex: 0,
    doubleBet: false,
    reBet: false,
    currentGameId: -1,
    currentGameState: -1,
    online: false,
    notToReconnect: false,
    needUserConfirm: false,
    onceEvent: '',
    lastVideo: '',
    videoLibraryVersion: '',
    waitingBetResult: false,
    initBlock: false,
    resetRoulSelectedTypes: 0,
} as GameState;
export const GAME_KEY = 'app::game';
const gameSlice = createSlice({
    name: GAME_KEY,
    initialState: initialGameState,
    reducers: {
        onConfirmedBetResult,
        setNetworkState,
        setCurrentProxyID,
        setCurrentVideoIndex,
        setCurrentGame,
        setCurrentGameState,
        setOnceEvent,
        onDoubleBet,
        onReBet,
        resetAll,
        setLastVideo,
        setVideoLibraryVersion,
        setWaitingBetResult,
        setInitBlock,
        onResetRoulSelectedTypes,
        updateHostBetInfo,
        updateBaccaratBetHistory,
        updateBaccaratBetHistoryPayout,
        updateBaccaratBetHistoryCancel,
    },
});
export const gameSliceReducer = gameSlice.reducer;
export const gameSliceActions = gameSlice.actions;
