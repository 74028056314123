import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScRoomSeat implements ICommand
{

	public HostID : number = 0;
	public Seat : number = 0;
	public PlayerID : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Seat = ba.readUint16();
		this.PlayerID = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint16(this.Seat);
		ba.writeUint53(this.PlayerID);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScRoomSeat();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Seat(W)='+this.Seat+' ';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30034;
	}

	public getCmdName() : string
	{
		return 'ScRoomSeat';
	}

}
