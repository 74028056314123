import { combineReducers } from 'redux';
import { LOADING_PROGRESS_KEY, loadingProgressSliceReducer } from './loading';
import { RESULT_PROGRESS_KEY, resultProgressSliceReducer } from './result';
import { PROGRESS_STATUS_KEY, progressStatusReducer } from './status';
import { PROGRESS_TIME_KEY, progressTimeReducer } from './time';
const progressReducer = combineReducers({
    [RESULT_PROGRESS_KEY]: resultProgressSliceReducer,
    [LOADING_PROGRESS_KEY]: loadingProgressSliceReducer,
    [PROGRESS_STATUS_KEY]: progressStatusReducer,
    [PROGRESS_TIME_KEY]: progressTimeReducer,
});
export default progressReducer;
export type ProgressState = ReturnType<typeof progressReducer>;
export const PROGRESS_KEY = 'app::progress';
