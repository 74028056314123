import { progressTimeActions } from '@/modules/progress/slice/time';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useStartTime = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        let timestamp = 0;
        let setType = '';
        try {
            const element = document.querySelector('meta[name="start"]');
            if (element) {
                const start = Number(element.getAttribute('content'));
                if (!isNaN(start)) {
                    timestamp = start;
                    setType = 'html';
                }
            }
        } catch (err) {
            console.warn('hook::time::set', 'cannot get start meta', err);
        }

        if (timestamp === 0) {
            timestamp = moment().valueOf();
            setType = 'js';
        }
        console.log('hook::time::set', setType, timestamp);
        dispatch(progressTimeActions.markStart(timestamp));
    }, []);
};
