import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScRoomLeft implements ICommand
{

	public HostID : number = 0;
	public Seat : Array<number>;

	public constructor()
	{
		this.Seat = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		let SeatSize:number = ba.readUint16();
		while (SeatSize-- > 0)
		{
			let SeatChild:number = ba.readUint16();
			this.Seat.push(SeatChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint16(this.Seat.length);
		for (const SeatChild of this.Seat)
		{
			ba.writeUint16(SeatChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScRoomLeft();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Seat_Size(W)='+this.Seat.length + ' ';
		str += 'Seat(W)=[';
		for (let SeatChild in this.Seat)
		{
			if (<number><unknown>SeatChild > 0) str += ', ';
			str += this.Seat[SeatChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30035;
	}

	public getCmdName() : string
	{
		return 'ScRoomLeft';
	}

}
