import { Box, Stack, Typography } from '@mui/material';
import PromotionAnimation from './PromotionAnimation';
import { ImageType, getImageJsonArray } from '../PromotionUtil';
import { useEffect, useState } from 'react';
import { PrizeListData, Prizes, ScratchCardError } from './PromotionGacha';
import { useTranslation } from 'react-i18next';
import { getPromotionToolState } from '../../../main/selector';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { numberFormat } from '../../../../utils/commonFunc';
import { useBalance } from '../../../../hooks/useBalance';
import { BaseTypography } from '../../../../components/common/baseText/BaseTypography';
import {
    getPromotionTool,
    PromotionToolEnum,
} from '@/components/images/base64/PromotionTool';
import {
    promotionMoneyFigure,
    promotionScrachCard,
} from '../PromotionObjectMapper';

type props = {
    curPrize: number;
    prizeList: PrizeListData;
    dayLimit: number;
    scratchState: string;
    animDuration: number;
    eventEnded: boolean;
    overBudget: boolean;
};

type scratchCardState = {
    scratchState: string;
    bg: string;
    errorColor: string;
    errorTitle: string;
    errorMessage: string;
};

// const scratchCardImageData = getPromotionTool(
//     PromotionToolEnum.event_scratch_card
// ).props['src'] as string;

export const PromotionScratchCard = (props: props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { currency } = useBalance();
    const { RemainDayLimit, Result, ResultAmount, MaxDayLimit, IsOverBudget } =
        useSelector((state: RootState) => getPromotionToolState(state));
    const SUCCESS_CODE = 0xff;
    const errorSetting = {
        general: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '#F06464',
            errorTitle: t('promotion_tool.redeem_error'),
            errorMessage: t('promotion_tool.retry'),
        },
        betNow: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.insufficient_points'),
            errorMessage: t('promotion_tool.accum_points'),
        },
        limitReached: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.redeem_limit_reached'),
            errorMessage: t('promotion_tool.redeem_await_tmr'),
        },
        overBudget: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.thanks'),
            errorMessage: t('promotion_tool.over_budget'),
        },
        eventEnded: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.event_ended'),
            errorMessage: t('promotion_tool.next_event'),
        },
    };

    const [state, setState] = useState<scratchCardState>({
        scratchState: props.scratchState,
        bg: 'event_3rd_scratch_card_bg',
        errorColor: '',
        errorTitle: '',
        errorMessage: '',
    });
    const winAmount: string[] = getImageJsonArray(
        ImageType.Prize,
        numberFormat(ResultAmount / 100, 2).toString()
    );
    const [isPlay, setIsPlay] = useState(false);
    const [hideMask, setHideMask] = useState(false);
    // const scratchCardRef = useRef<HTMLDivElement>(null);

    const onPrizeChange = () => {
        switch (props.curPrize) {
            case Prizes.Gold:
                setState(prev => ({
                    ...prev,
                    bg: 'event_1st_scratch_card_bg',
                }));
                setHideMask(false);
                setIsPlay(false);
                break;
            case Prizes.Silver:
                setState(prev => ({
                    ...prev,
                    bg: 'event_2nd_scratch_card_bg',
                }));
                setHideMask(false);
                setIsPlay(false);
                break;
            case Prizes.Bronze:
                setState(prev => ({
                    ...prev,
                    bg: 'event_3rd_scratch_card_bg',
                }));
                setHideMask(false);
                setIsPlay(false);
                break;
            case Prizes.AllDisabled:
                setState(prev => ({
                    ...prev,
                    bg: 'event_invalid_scratch_card_bg',
                }));
                setHideMask(true);
                setIsPlay(false);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        onPrizeChange();
    }, [props.curPrize]);

    useEffect(() => {
        if (!props.overBudget && !props.eventEnded) onLimitReached();
        return () => {
            dispatch(promotionToolSliceActions.clearRedeemResult());
        };
    }, []);

    useEffect(() => {
        if (Result > 0) {
            handleResult(Result);
        }
    }, [Result]);

    useEffect(() => {
        updateState(props.scratchState);
    }, [props.scratchState]);

    useEffect(() => {
        if (props.eventEnded)
            setState(prev => ({ ...prev, ...errorSetting.eventEnded }));
        else if (IsOverBudget > 0) {
            handleResult(ScratchCardError.OverBudget);
        }
    }, [props.eventEnded, IsOverBudget]);

    // useEffect(() => {
    //     if (scratchCardRef.current) {
    //         const img = scratchCardRef.current.querySelector('img');
    //         if (img) {
    //             if (isPlay) {
    //                 img.src = scratchCardImageData ? scratchCardImageData : '';
    //             } else {
    //                 img.src = '';
    //             }
    //         }
    //     }
    // }, [isPlay]);

    const onLimitReached = () => {
        if (MaxDayLimit > 0 && props.dayLimit <= 0) {
            updateState('error');
            setState(prev => ({ ...prev, ...errorSetting.limitReached }));
            setHideMask(true);
            setIsPlay(false);
        }
    };

    const handleResult = (result: number) => {
        switch (result) {
            case SUCCESS_CODE:
                dispatch(
                    promotionToolSliceActions.updateRemainDays(RemainDayLimit)
                );
                updateState('scratching');
                break;
            case ScratchCardError.InsufficientPoints:
                updateState('error');
                setState(prev => ({ ...prev, ...errorSetting.betNow }));
                setHideMask(true);
                setIsPlay(false);
                break;
            case ScratchCardError.OverBudget:
                updateState('error');
                setState(prev => ({ ...prev, ...errorSetting.overBudget }));
                setHideMask(true);
                setIsPlay(false);
                break;
            case ScratchCardError.EventEnded:
                updateState('error');
                setState(prev => ({ ...prev, ...errorSetting.eventEnded }));
                setHideMask(true);
                setIsPlay(false);
                break;
            case ScratchCardError.OverMax:
                onLimitReached();
                break;
            default:
                updateState('error');
                errorSetting.general.errorTitle += ` (${result})`;
                setState(prev => ({ ...prev, ...errorSetting.general }));
                setHideMask(true);
                setIsPlay(false);
                break;
        }
    };

    const updateState = (s: string) => {
        switch (s) {
            case 'ready':
                setHideMask(false);
                setIsPlay(false);
                onPrizeChange();
                break;
            case 'scratching':
                setIsPlay(true);
                setHideMask(false);
                setTimeout(() => {
                    updateState('scratched');
                }, props.animDuration);
                break;
            case 'scratched':
                break;
            case 'error':
                setState(prev => ({ ...prev, scratchState: 'error' }));
                break;
            default:
                break;
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                top: '30px',
            }}
        >
            <Box
                // className={`scratch_card ${state.bg}`}
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <Box>{getPromotionTool(promotionScrachCard[state.bg])}</Box>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '370px',
                        height: '165px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    {props.scratchState === 'scratching' ||
                    props.scratchState === 'scratched' ? (
                        <Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translate(-50%, 0)',
                                }}
                            >
                                {getPromotionTool(
                                    PromotionToolEnum.event_congratulation_bg
                                )}
                            </Box>
                            <Typography
                                sx={{
                                    position: 'relative',
                                    lineHeight: '34px',
                                    color: '#FFE09D',
                                    fontSize: '28px',
                                    fontWeight: '700',
                                }}
                                // className="promotion_tool event_congratulation_bg"
                            >
                                {t('promotion_tool.congratulation')}
                            </Typography>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={2}
                            >
                                {/* <BaseImage
                                    className="promotion_tool event_caption_arrow"
                                    scale={1}
                                /> */}
                                <Box display={'flex'}>
                                    {getPromotionTool(
                                        PromotionToolEnum.event_caption_arrow
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        color: '#BF9A5D',
                                        fontSize: '20px',
                                        fontWeight: '400',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {`${t('promotion_tool.event_win')} (${currency})`}
                                </Box>
                                {/* <BaseImage
                                    className="promotion_tool event_caption_arrow rotate180"
                                    scale={1}
                                /> */}
                                <Box display={'flex'} className="rotate180">
                                    {getPromotionTool(
                                        PromotionToolEnum.event_caption_arrow
                                    )}
                                </Box>
                            </Stack>
                            <Stack
                                sx={{ height: '36px', marginTop: '10px' }}
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                {winAmount.map((value, index) => (
                                    // <BaseImage
                                    //     className={value}
                                    //     scale={0.7}
                                    //     key={`scratch-card-prize-${index}`}
                                    // />
                                    <Box
                                        key={`scratch-card-prize-${index}`}
                                        sx={{ zoom: 0.7 }}
                                    >
                                        {getPromotionTool(
                                            promotionMoneyFigure[value]
                                        )}
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                    ) : props.scratchState === 'error' ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                                width: '310px',
                                alignItems: 'center',
                            }}
                        >
                            <BaseTypography
                                resize={{
                                    direction: 'horizontal',
                                    value:
                                        i18n.language === 'my-MM'
                                            ? '215px'
                                            : '310px',
                                }}
                                sx={{
                                    fontSize: '28px',
                                    color: state.errorColor,
                                    fontWeight: 600,
                                }}
                            >
                                {state.errorTitle}
                            </BaseTypography>
                            <Typography>{state.errorMessage}</Typography>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>

            <Box sx={{ position: 'absolute', display: 'contents' }}>
                {hideMask ? (
                    <></>
                ) : (
                    <>
                        {!isPlay && (
                            <Box position={'absolute'} display={'flex'}>
                                {getPromotionTool(
                                    PromotionToolEnum.event_scratch_card_scratch_1
                                )}
                            </Box>
                        )}
                        {isPlay && (
                            <Box position={'absolute'} display={'flex'}>
                                <PromotionAnimation
                                    source="event_scratch_card_scratch_1"
                                    endFrame={11}
                                    duration={props.animDuration}
                                    scale={1}
                                />
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};
