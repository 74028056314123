import { CdnContext } from '@/contexts/CdnContext';
import { getGameState } from '@/modules/games/selector';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
type NetStateOwnProps = {
    includeParentheses?: boolean;
};
type NetStateProps = TypographyProps & NetStateOwnProps;
export const NetState = (props: NetStateProps) => {
    const { includeParentheses, ...otherProps } = props;
    const { cdnId, myVideoChannel } = useContext(CdnContext);
    const { proxyID, currentVideoIndex } = useSelector(getGameState);

    const [cdn, setCdn] = useState(0);
    const [video, setVideo] = useState(0);
    const [proxy, setProxy] = useState(0);
    const [net, setNet] = useState('0.0.0');

    useEffect(() => {
        if (proxyID !== undefined) setProxy(proxyID);
    }, [proxyID]);
    useEffect(() => {
        if (cdnId !== undefined) setCdn(cdnId);
    }, [cdnId]);
    useEffect(() => {
        if (myVideoChannel && myVideoChannel.length > 0) {
            const v = Number(
                myVideoChannel[currentVideoIndex % myVideoChannel.length].id
            );
            if (!isNaN(v)) setVideo(v);
        }
    }, [myVideoChannel, currentVideoIndex]);

    useEffect(() => {
        let s = `${cdn}.${video}.${proxy}`;
        if (includeParentheses === true) s = `(${s})`;
        setNet(s);
    }, [proxy, cdn, video, includeParentheses]);

    return <Typography {...otherProps}>{net}</Typography>;
};
