import { BetType as BetTypeEnum } from '../../../models/games/sicbo/BetType';

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    if (betsSummary === undefined) {
        return false;
    }
    let oppositeType = -1;
    switch (betType) {
        case BetTypeEnum.SBBOdd:
            oppositeType = BetTypeEnum.SBBEven;
            break;
        case BetTypeEnum.SBBEven:
            oppositeType = BetTypeEnum.SBBOdd;
            break;
        case BetTypeEnum.SBBSmall:
            oppositeType = BetTypeEnum.SBBBig;
            break;
        case BetTypeEnum.SBBBig:
            oppositeType = BetTypeEnum.SBBSmall;
            break;
    }

    if (oppositeType < 0) {
        return false;
    }
    return (
        betsSummary[oppositeType] != undefined && betsSummary[oppositeType] > 0
    );
};
