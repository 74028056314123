export enum BetContent {
    SitInBet,
    SitInSurrender,
    SitInSplit,
    SitInDoubleDown,
    SitInPair,
    Insurance,

    BetBehind,
    BetBehindSurrender,
    BetBehindSplit,
    BetBehindDoubleDown,
    BetBehindPair,
}
