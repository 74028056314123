import {
    BetType,
    RouletteResultEnum,
} from '../../models/games/roulette/BetType';
import { SupportedGameResult } from '../../modules/host/models';
import { RouletteRecord, RouletteResult } from '../../models/games/roulette';
import { isBitActive } from '../bitwiseUtil';
import {
    RouletteColor,
    RouletteBigSmall,
    RouletteOddEven,
} from '../../models/games/roulette/Record';
import { GameResult as GameResultEnum } from '../../models/games/roulette/GameResult';
import { LocalState } from '../../models/games/enums/LocalState';
import { GameState } from '../../models/games/enums/GameState';
import { GameState as RouletteGameState } from '../../models/games/roulette/GameState';

export const getWinBetType = (gameResult: number): Array<number> => {
    let winSet: Array<number> = [];

    let winNumber: number = 255;
    for (
        let i = RouletteResultEnum.RRZero;
        i <= RouletteResultEnum.RRThirtySix;
        i++
    ) {
        if (isBitActive(gameResult, i)) {
            winNumber = i;
            break;
        }
    }

    const objValues = Object.keys(BetType).map((k, index) => {
        return BetType[index];
    });
    const names = objValues.filter(v => typeof v === 'string') as string[];
    let arr: Array<string>;
    for (let i = 0; i < names.length; i++) {
        arr = names[i].split('_');
        if (arr.indexOf(winNumber.toString()) != -1) {
            winSet.push(i);
        }
    }

    for (
        let i = RouletteResultEnum.RRZero;
        i <= RouletteResultEnum.RRBlack;
        i++
    ) {
        if (isBitActive(gameResult, i)) {
            switch (i) {
                case RouletteResultEnum.RRSet1:
                    winSet.push(BetType.RBSet1);
                    break;
                case RouletteResultEnum.RRSet2:
                    winSet.push(BetType.RBSet2);
                    break;
                case RouletteResultEnum.RRSet3:
                    winSet.push(BetType.RBSet3);
                    break;
                case RouletteResultEnum.RRRow1:
                    winSet.push(BetType.RBRow1);
                    break;
                case RouletteResultEnum.RRRow2:
                    winSet.push(BetType.RBRow2);
                    break;
                case RouletteResultEnum.RRRow3:
                    winSet.push(BetType.RBRow3);
                    break;
                case RouletteResultEnum.RR1To18:
                    winSet.push(BetType.RB1To18);
                    break;
                case RouletteResultEnum.RR19To36:
                    winSet.push(BetType.RB19To36);
                    break;
                case RouletteResultEnum.RROdd:
                    winSet.push(BetType.RBOdd);
                    break;
                case RouletteResultEnum.RREven:
                    winSet.push(BetType.RBEven);
                    break;
                case RouletteResultEnum.RRRed:
                    winSet.push(BetType.RBRed);
                    break;
                case RouletteResultEnum.RRBlack:
                    winSet.push(BetType.RBBlack);
                    break;
            }
        }
    }

    return winSet;
};
export const resultToColor = (resultVal: number, resultPoint: number) => {
    if (resultPoint === 0) {
        return RouletteColor.GREEN;
    } else if (isBitActive(resultVal, GameResultEnum.RRRed)) {
        return RouletteColor.RED;
    } else if (isBitActive(resultVal, GameResultEnum.RRBlack)) {
        return RouletteColor.BLACK;
    }
};

const resultToOddEven = (resultVal: number) => {
    if (isBitActive(resultVal, GameResultEnum.RROdd)) {
        return RouletteOddEven.ODD;
    } else if (isBitActive(resultVal, GameResultEnum.RREven)) {
        return RouletteOddEven.EVEN;
    }
};

const resultToBigSmall = (resultVal: number) => {
    if (isBitActive(resultVal, GameResultEnum.RR19To36)) {
        return RouletteBigSmall.BIG;
    } else if (isBitActive(resultVal, GameResultEnum.RR1To18)) {
        return RouletteBigSmall.SMALL;
    }
};

export const convertResultToRecord = (
    result: RouletteResult
): RouletteRecord => {
    const winBetList = getWinBetType(result.Result);
    const record: RouletteRecord = {
        gameID: result.GameID,
        result: result.Result,
        round: result.GameCount % 10000,
        shoes: Math.floor(result.GameCount),
        winBetType: winBetList,
        resultPoint: result.ResultPoint,
        color: resultToColor(result.Result, result.ResultPoint),
        oddEven: resultToOddEven(result.Result),
        bigSmall: resultToBigSmall(result.Result),
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    result?: number
): RouletteRecord => {
    const resultPoint = resultString.split(',').map(s => Number(s));
    const { FResult, GameID, Result, GameCount } = currentResult;
    const updateResult: RouletteResult = {
        FResult: FResult,
        GameCount: GameCount,
        GameID: GameID,
        Result: result ?? Result,
        ResultPoint: resultPoint[0],
    };

    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }

    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Restart:
            return GameState.Restart;
        case GameState.Idle:
        case GameState.Shuffle:
            return LocalState.IDLE;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case RouletteGameState.WaitResult:
            return LocalState.DEALING;
        default:
            if (
                state >= RouletteGameState.Zero &&
                state <= RouletteGameState.ThirtySix
            ) {
                return LocalState.RESULT;
            }
            break;
    }

    if (isGameResultReleased) {
        return LocalState.RESULT;
    }

    return LocalState.IDLE;
};
