export enum HistoryInfo {
    PUSH,
    WIN,
    LOSE,

    BJ,
    BUST,

    NO_BET,
    REFUND,
    POINT,

    SPLIT_WIN_WIN,
    SPLIT_WIN_LOSE,
    SPLIT_WIN_PUSH,
    SPLIT_WIN_REFUND,
    SPLIT_WIN_BUST,

    SPLIT_LOSE_WIN,
    SPLIT_LOSE_LOSE,
    SPLIT_LOSE_PUSH,
    SPLIT_LOSE_REFUND,
    SPLIT_LOSE_BUST,

    SPLIT_PUSH_WIN,
    SPLIT_PUSH_LOSE,
    SPLIT_PUSH_PUSH,
    SPLIT_PUSH_REFUND,
    SPLIT_PUSH_BUST,

    SPLIT_REFUND_WIN,
    SPLIT_REFUND_LOSE,
    SPLIT_REFUND_PUSH,
    SPLIT_REFUND_REFUND,
    SPLIT_REFUND_BUST,

    SPLIT_BUST_WIN,
    SPLIT_BUST_LOSE,
    SPLIT_BUST_PUSH,
    SPLIT_BUST_REFUND,
    SPLIT_BUST_BUST,
}
export type HistoryInfoKey = keyof typeof HistoryInfo;
