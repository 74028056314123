import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { CMDSpServerReset, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpServerResetHandler extends AbstractHandler {
    _command: CMDSpServerReset;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpServerReset;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        dispatch(
            hostsSliceActions.rest({
                hostIds: this._command.HostID,
            })
        );
    }
}
