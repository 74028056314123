import { CMDGift } from "./CMDGift"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpGiftSetting implements ICommand
{

	public Gift : Array<CMDGift>;

	public constructor()
	{
		this.Gift = new Array<CMDGift>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let GiftSize:number = ba.readUint8();
		while (GiftSize-- > 0)
		{
			let GiftChild:CMDGift = new CMDGift();
			GiftChild.fromBytes(ba);
			this.Gift.push(GiftChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Gift.length);
		for (const GiftChild of this.Gift)
		{
			let tempBuf = GiftChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpGiftSetting();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Gift_size(B)='+this.Gift.length + ' ';
		str += 'Gift(CMDGift)=[';
		for (let GiftChild in this.Gift)
		{
			if (<number><unknown>GiftChild > 0) str += ', ';
			str += this.Gift[GiftChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50094;
	}

	public getCmdName() : string
	{
		return 'SpGiftSetting';
	}

}
