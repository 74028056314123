import { CMDSpBetWinDetailList } from "./CMDSpBetWinDetailList"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBetWinDetail implements ICommand
{

	public GameID : number = 0;
	public betList : Array<CMDSpBetWinDetailList>;

	public constructor()
	{
		this.betList = new Array<CMDSpBetWinDetailList>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		let betListSize:number = ba.readUint53();
		while (betListSize-- > 0)
		{
			let betListChild:CMDSpBetWinDetailList = new CMDSpBetWinDetailList();
			betListChild.fromBytes(ba);
			this.betList.push(betListChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint53(this.betList.length);
		for (const betListChild of this.betList)
		{
			let tempBuf = betListChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBetWinDetail();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'betList_size(Q)='+this.betList.length + ' ';
		str += 'betList(CMDSpBetWinDetailList)=[';
		for (let betListChild in this.betList)
		{
			if (<number><unknown>betListChild > 0) str += ', ';
			str += this.betList[betListChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50131;
	}

	public getCmdName() : string
	{
		return 'SpBetWinDetail';
	}

}
