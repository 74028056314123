import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpRuleChange implements ICommand
{

	public Result : number = 0;
	public GameType : number = 0;
	public RuleIndex : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Result = ba.readUint8();
		this.GameType = ba.readInt53();
		this.RuleIndex = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Result);
		ba.writeUint53(this.GameType);
		ba.writeUint53(this.RuleIndex);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpRuleChange();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Result(B)='+this.Result+' ';
		str += 'GameType(Q)='+this.GameType+' ';
		str += 'RuleIndex(Q)='+this.RuleIndex+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50006;
	}

	public getCmdName() : string
	{
		return 'SpRuleChange';
	}

}
