import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDCsRequestServer implements ICommand
{

	public ClientKey : number = 0;
	public ID : number = 0;
	public Token : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ClientKey = ba.readUint32();
		this.ID = ba.readUint32();
		this.Token = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.ClientKey);
		ba.writeUint32(this.ID);
		ba.writeString(this.Token);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDCsRequestServer();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ClientKey(D)='+this.ClientKey+' ';
		str += 'ID(D)='+this.ID+' ';
		str += 'Token[33](C)='+this.Token+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 35001;
	}

	public getCmdName() : string
	{
		return 'CsRequestServer';
	}

}
