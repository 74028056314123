import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScGameState implements ICommand
{

	public ID : number = 0;
	public FGameID : bigint = 0n; 
	public GameID : number = 0;
	public State : number = 0;
	public ResultString : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ID = ba.readUint8();
		this.FGameID = ba.readUint64();
		this.GameID = Number(this.FGameID);
		this.State = ba.readUint16();
		this.ResultString = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.ID);
		ba.writeUint64(this.FGameID);
		ba.writeUint16(this.State);
		ba.writeString(this.ResultString);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScGameState();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ID(B)='+this.ID+' ';
		str += 'GameID(F)='+this.GameID+' ';
		str += 'State(W)='+this.State+' ';
		str += 'ResultString(S)='+this.ResultString+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30005;
	}

	public getCmdName() : string
	{
		return 'ScGameState';
	}

}
