import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import { getBetRecordReportState } from '../../../modules/report/selector';
import {
    Bet,
    BetRecordState,
    betRecordSliceActions,
} from '../../../modules/report/slice/betRecord';
import store from '../../../store/store';
import { GameType } from '../../games/enums/GameType';
import { CMDSpBetLogQuery, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpBetLogQueryHandler extends AbstractHandler {
    _command: CMDSpBetLogQuery;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBetLogQuery;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const { records, total } = getBetRecordReportState(state);

        const newRecords: BetRecordState['records'] = JSON.parse(
            JSON.stringify(records)
        );
        for (let b of this._command.Bet) {
            if (Object.keys(newRecords).length >= total) break;
            // the UTCSecond has been include the timezone (UTC+8), so do not do any change before server has change the timestamp to UTC
            const m = moment.unix(b.UTCSecond).utc(false);
            const d = m.format('yyyy-MM-DD');
            const t = m.format('HH:mm:ss');

            const bet = {} as Bet;
            bet.Balance = b.Balance;
            bet.BetAmount = b.BetAmount;
            bet.BetID = b.BetID;
            bet.BetType = b.BetType;
            const gameCount = b.GameCount;
            bet.GameCount = gameCount;
            bet.Shoes = Math.floor(gameCount / 10000);
            bet.Round = gameCount % 10000;
            bet.GameID = b.GameID;
            bet.GameType = b.GameType;
            bet.HostID = b.HostID;
            bet.IsAutoBet = b.IsAutoBet;
            bet.Remark = b.Remark;
            bet.Result = b.Result;

            bet.ResultPlayer = [];
            bet.ResultBanker = [];
            if (b.GameType !== GameType.Blackjack) {
                let tokens: Array<string> = bet.Result.split(',');
                let i: number;
                for (i = 1; i < 1 + parseInt(tokens[0]); i++) {
                    bet.ResultPlayer.push(parseInt(tokens[i]));
                }
                for (i = 2 + bet.ResultPlayer.length; i < tokens.length; i++) {
                    bet.ResultBanker.push(parseInt(tokens[i]));
                }
            }
            bet.ResultAmount = b.ResultAmount;
            bet.State = b.State;
            bet.Date = d;
            bet.Time = t;
            bet.WinSlot = b.WinSlot;
            bet.RollingAmount = b.RollingAmount;

            if (!newRecords[d])
                newRecords[d] = {
                    bets: new Array<Bet>(),
                    totalAmount: 0,
                    gameTypeLists: [0],
                };
            newRecords[d].bets.push(bet);
            newRecords[d].totalAmount += bet.ResultAmount;
            if (!newRecords[d].gameTypeLists.includes(bet.GameType)) {
                newRecords[d].gameTypeLists.push(bet.GameType);
                newRecords[d].gameTypeLists[0] += bet.GameType;
            }
        }
        dispatch(
            betRecordSliceActions.updateRecord({
                records: newRecords,
            })
        );
    }
}
