import { CMDTeenPatti2020Statistics } from "./CMDTeenPatti2020Statistics"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpTeenPatti2020Statistics implements ICommand
{

	public HostID : number = 0;
	public Statistics : CMDTeenPatti2020Statistics;
	public MaxRecord : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Statistics = new CMDTeenPatti2020Statistics();
		this.Statistics.fromBytes(ba);
		this.MaxRecord = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		let tempBuf1 = this.Statistics.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		ba.writeUint53(this.MaxRecord);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpTeenPatti2020Statistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Statistics(CMDTeenPatti2020Statistics)='+this.Statistics+' ';
		str += 'MaxRecord(Q)='+this.MaxRecord+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50116;
	}

	public getCmdName() : string
	{
		return 'SpTeenPatti2020Statistics';
	}

}
