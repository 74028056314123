import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import {
    convertFResult,
    convertResultToRecord,
} from '../../../utils/games/andarBahar';
import { AndarBaharResult } from '../../games/andarBahar';
import { CMDScInitAndarBahar, ICommand } from '../live';
import { CMDAndarBahar } from '../live/CMDAndarBahar';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitAndarBaharHandler extends AbstractHandler {
    _command: CMDScInitAndarBahar;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitAndarBahar;
    }

    override convertResult(result: CMDAndarBahar): AndarBaharResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            convertFResult(result.BaseResult.FResult),
            {
                AndarBahar: result.AndarBahar,
                Joker: result.Joker,
            }
        ) as AndarBaharResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.OldResult = this._command.OldResult.map(o =>
            this.convertResult(o)
        );
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );
        game.ResultReleased = false;
        game.IsRest = this._command.Information.Rest != 0;

        const convertedRecord = game.OldResult.map(r =>
            convertResultToRecord(r as AndarBaharResult)
        );

        batch(() => {
            dispatch(
                tableBetSliceActions.initTable({
                    hostId: hostId,
                    gameId: this._command.CurrentResult.BaseResult.GameID,
                })
            );
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                })
            );
        });
    }
}
