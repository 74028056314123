import { BetType as BetTypeEnum } from '../../../models/games/baccarat/BetType';
// // import { LimitRed } from '../../../../main/slice/limitRed';
// import { GAME_KEY } from '../../games/slice';
// import { PHOST_KEY } from '../../host/slice';

import { Bet } from '../../../models/host/BetAmount';
// import store from '../../../store/store';

const BankerBetType = [BetTypeEnum.BBBankerWin, BetTypeEnum.BBSSBankerWin];
const PlayerBetType = [BetTypeEnum.BBPlayerWin, BetTypeEnum.BBSSPlayerWin];
const ShareLimitType = [
    BankerBetType,
    PlayerBetType,
    [BetTypeEnum.BBTie, BetTypeEnum.BBSSTie],
    [BetTypeEnum.BBSTotalCardSmall, BetTypeEnum.BBSSSTotalCardSmall],
    [BetTypeEnum.BBSTotalCardBig, BetTypeEnum.BBSSSTotalCardBig],
    [BetTypeEnum.BBSPlayerDragon, BetTypeEnum.BBSSSPlayerDragon],
    [BetTypeEnum.BBSBankerDragon, BetTypeEnum.BBSSSBankerDragon],
    [BetTypeEnum.BBSLuckySix, BetTypeEnum.BBSSSLuckySix],
    [BetTypeEnum.BBPlayerPair, BetTypeEnum.BBSSPlayerPair],
    [BetTypeEnum.BBBankerPair, BetTypeEnum.BBSSBankerPair],
    [BetTypeEnum.BBPlayerNatural, BetTypeEnum.BBSSPlayerNatural],
    [BetTypeEnum.BBBankerNatural, BetTypeEnum.BBSSBankerNatural],
];

export const getShareLimitBetTypes = (betType: number) => {
    for (let i = 0; i < ShareLimitType.length; i++) {
        if (ShareLimitType[i].indexOf(betType) >= 0) {
            return ShareLimitType[i];
        }
    }
    return [betType];
};
export const getBetTypeGroupTotal = (
    betTypes: number[],
    BetsSummary: Array<number>,
    ConfirmedBets: Array<number>
) => {
    let amount = 0;
    for (let i = 0; i < betTypes.length; i++) {
        if (BetsSummary[betTypes[i]]) {
            amount += BetsSummary[betTypes[i]];
        }
        if (ConfirmedBets[betTypes[i]]) {
            amount += ConfirmedBets[betTypes[i]];
        }
    }
    return amount;
};
export const getBaccaratCurrentTotal = (
    betType: number,
    BetsSummary: Array<number>,
    ConfirmedBets: Array<number>
) => {
    if (BankerBetType.indexOf(betType) >= 0) {
        return (
            getBetTypeGroupTotal(BankerBetType, BetsSummary, ConfirmedBets) -
            getBetTypeGroupTotal(PlayerBetType, BetsSummary, ConfirmedBets)
        );
    } else if (PlayerBetType.indexOf(betType) >= 0) {
        return (
            getBetTypeGroupTotal(PlayerBetType, BetsSummary, ConfirmedBets) -
            getBetTypeGroupTotal(BankerBetType, BetsSummary, ConfirmedBets)
        );
    } else {
        const matchBetTypes = getShareLimitBetTypes(betType);
        return getBetTypeGroupTotal(matchBetTypes, BetsSummary, ConfirmedBets);
    }
    return 0;
};

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    let Opposite = [];
    switch (betType) {
        case BetTypeEnum.BBPlayerWin:
        case BetTypeEnum.BBSSPlayerWin:
        case BetTypeEnum.BBCowPlayerWin:
            Opposite.push(BetTypeEnum.BBBankerWin);
            Opposite.push(BetTypeEnum.BBSSBankerWin);
            Opposite.push(BetTypeEnum.BBCowBankerWin);
            break;
        case BetTypeEnum.BBBankerWin:
        case BetTypeEnum.BBSSBankerWin:
        case BetTypeEnum.BBCowBankerWin:
            Opposite.push(BetTypeEnum.BBPlayerWin);
            Opposite.push(BetTypeEnum.BBSSPlayerWin);
            Opposite.push(BetTypeEnum.BBCowPlayerWin);
            break;
    }
    if (betsSummary) {
        for (let i = 0; i < Opposite.length; i++) {
            if (betsSummary[Opposite[i]] && betsSummary[Opposite[i]] > 0) {
                return true;
            }
        }
    }
    return false;
};

export const getBaccaratHedgeBettingBets = (bets: Array<Bet>): Array<Bet> => {
    let availableBets = [];
    let playerBet = 0;
    let bankerBet = 0;
    let gameID;

    for (let bet of bets) {
        switch (bet.Type) {
            case BetTypeEnum.BBPlayerWin:
            case BetTypeEnum.BBSSPlayerWin:
                playerBet += bet.Amount;
                gameID = bet.GameId;
                break;
            case BetTypeEnum.BBBankerWin:
            case BetTypeEnum.BBSSBankerWin:
                bankerBet += bet.Amount;
                gameID = bet.GameId;
                break;
            default:
                availableBets.push(bet);
                gameID = bet.GameId;
                break;
        }
    }
    if (playerBet === bankerBet) {
        //no available Bets in player & banker
    }
    if (playerBet > bankerBet) {
        availableBets.push({
            Type: BetTypeEnum.BBPlayerWin,
            Amount: playerBet - bankerBet,
            GameId: gameID,
        } as Bet);
    } else {
        availableBets.push({
            Type: BetTypeEnum.BBBankerWin,
            Amount: bankerBet - playerBet,
            GameId: gameID,
        } as Bet);
    }
    return availableBets;
};
