import { TABLE_BET_KEY } from '@/hooks/useTableBet';
import { Bet, BetFormat, BetGroup } from '@/models/host/BetAmount';
import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { getHostByGameId } from '../../../modules/host/slice';
import store from '../../../store/store';
import { CMDPsNbsBet, CMDSpInitNbsBet, ICommand } from '../live';
import { CMDBet } from '../live/CMDBet';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpInitNbsBetHandler extends AbstractHandler {
    _command: CMDSpInitNbsBet;
    // _cmd: SyncCMDAction;
    _betSource: number;
    _sendCommand: (command: ICommand) => Promise<void>;
    constructor(
        command: ICommand,
        sendCommand: (command: ICommand) => Promise<void>,
        betSource: number
    ) {
        super();
        this._command = command as CMDSpInitNbsBet;
        this._sendCommand = sendCommand;
        this._betSource = betSource;
    }
    // syncCMD() {
    //     return this._cmd;
    // }

    override handle(dispatch: Dispatch<AnyAction>): void {
        //TBC
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        this._command.betSeqID;

        try {
            let localStorage = window.localStorage
                .getItem(`${TABLE_BET_KEY}_${host.HostId}`)
                ?.split(';');
            if (!localStorage) {
                localStorage = window.sessionStorage
                    .getItem(`${TABLE_BET_KEY}_${host.HostId}`)
                    ?.split(';');
            }
            const storageSeq =
                localStorage && localStorage.length > 0
                    ? Number(localStorage[0])
                    : 0;
            const storageGameId =
                localStorage && localStorage.length > 1
                    ? Number(localStorage[1])
                    : 0;
            const storageBets: Array<BetGroup> =
                localStorage && localStorage.length > 2
                    ? JSON.parse(localStorage[2])
                    : [];

            let isInited = false;
            const bets = this._command.Bet.map(v => {
                return {
                    Type: v.BetType,
                    Amount: v.BetAmount,
                } as Bet;
            });
            let needSync = 0;
            let syncBets: Array<Bet> = [];
            if (storageGameId === this._command.GameID) {
                if (storageSeq >= this._command.betSeqID) {
                    storageBets.forEach(betGroup => {
                        for (let i = 0; i < betGroup.bets.length; i++) {
                            let bet = syncBets.find(
                                v => v.Type == betGroup.bets[i].Type
                            );
                            if (bet) {
                                bet.Amount += betGroup.bets[i].Amount;
                            } else {
                                syncBets.push({
                                    Type: betGroup.bets[i].Type,
                                    Amount: betGroup.bets[i].Amount,
                                } as Bet);
                            }
                        }
                    });
                }
                if (storageSeq > this._command.betSeqID) {
                    dispatch(
                        tableBetSliceActions.initBets({
                            hostId: host.HostId,
                            gameId: this._command.GameID,
                            betSeqId: storageSeq,
                            bets: storageBets,
                        })
                    );
                    needSync = storageSeq;
                    isInited = true;
                } else if (storageSeq === this._command.betSeqID) {
                    if (JSON.stringify(syncBets) !== JSON.stringify(bets)) {
                        dispatch(
                            tableBetSliceActions.initBets({
                                hostId: host.HostId,
                                gameId: this._command.GameID,
                                betSeqId: storageSeq + 1, //sync to server
                                bets: storageBets,
                            })
                        );
                        needSync = storageSeq + 1;
                        isInited = true;
                    }
                }
            }
            if (needSync && syncBets.length > 0) {
                const cmd = new CMDPsNbsBet();
                cmd.gameID = this._command.GameID;
                cmd.betFormat = BetFormat.AUTO_BET;
                cmd.betSeqID = needSync;
                cmd.BetSource = this._betSource;
                cmd.bets = syncBets.map(bet => {
                    return {
                        betType: bet.Type,
                        betAmount: bet.Amount,
                    } as CMDBet;
                });
                this._sendCommand(cmd);
                // this._cmd = {
                //     type: 'CMDPsNbsBet',
                //     hostId: host.HostId,
                //     cmd: cmd,
                // };
            }

            if (!isInited) {
                let betGroup = {
                    index: 0,
                    bets: bets,
                } as BetGroup;
                dispatch(
                    tableBetSliceActions.initBets({
                        hostId: host.HostId,
                        gameId: this._command.GameID,
                        betSeqId: this._command.betSeqID,
                        bets: [betGroup],
                    })
                );
            }
        } catch (e) {
            console.warn(e);
        }
    }
}
