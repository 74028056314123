// import { BetType } from '../../models/games/teenpatti/BetType';
import { LocalState } from '../../models/games/enums/LocalState';
import { BitArray } from '../../models/cmd/BitArray';
import { ByteArray } from '../../models/cmd/ByteArray';
import { GameState } from '../../models/games/enums/GameState';
import { TeenPattiRecord, TeenPattiResult } from '../../models/games/teenpatti';
import {
    PlayerPattern as TeenPattiPlayerPattern,
    SixCardPattern as TeenPattiSixCardPattern,
} from '../../models/games/teenpatti/GameResult';
import { GameState as TeenPattiGameState } from '../../models/games/teenpatti/GameState';
import { SupportedGameResult } from '../../modules/host/models';
import { BetType } from '@/models/games/teenpatti/BetType';

export const convertTPResult = (gameResult: bigint) => {
    const { winResult, playerAPattern, playerBPattern, sixCardPattern } =
        getPattern(gameResult);
    let betTypeWin: boolean[] = [];
    for (let i = 0; i < 3; i++) {
        betTypeWin[i] = winResult === i;
    }
    betTypeWin[BetType.TPBPlayerAPairPlus] = playerAPattern >= 1;
    betTypeWin[BetType.TPBPlayerBPairPlus] = playerBPattern >= 1;
    betTypeWin[BetType.TPB6CardBonus] = sixCardPattern >= 1;

    return {
        BetTypeWin: betTypeWin,
    };
};

export const getPattern = (fResult: bigint) => {
    let byteArr: ByteArray = new ByteArray();
    byteArr.writeUint64(fResult);
    let bitArr: BitArray = new BitArray(byteArr);
    let winResult = bitArr.readNumber(2);
    let playerAPattern = bitArr.readNumber(3);
    let playerBPattern = bitArr.readNumber(3);
    let sixCardPattern = bitArr.readNumber(3);
    let sixCardPokers = [];
    for (let i = 0; i < 5; i++) {
        let tmp = bitArr.readNumber(6);
        sixCardPokers.push(tmp);
    }

    return {
        winResult,
        playerAPattern,
        playerBPattern,
        sixCardPattern,
        sixCardPokers,
    };
};

export const convertResultToRecord = (
    result: TeenPattiResult
): TeenPattiRecord => {
    const { winResult } = getPattern(BigInt(result.FResult));

    const record: TeenPattiRecord = {
        gameID: result.GameID,
        result: winResult,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        resultPoint: -1,
        winBetType: [],
        playerACards: result.PlayerACard,
        playerBCards: result.PlayerBCard,
    };

    return record;
};

export const convertTeenPattiResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    fResult: bigint,
    result?: number
): TeenPattiRecord => {
    const { GameID, Result, GameCount } = currentResult as TeenPattiResult;

    const loadCardData = () => {
        const resultStr = resultString ?? '';
        let arr = resultStr.split(',');
        let pCount: number = -1;
        let bCount: number = -1;
        let aCards = [];
        let bCards = [];
        for (let i = 0; i < arr.length; i++) {
            let num = Number(arr[i]);
            if (i == 0) {
                pCount = num;
            } else {
                if (pCount == 0) {
                    if (bCount == -1) {
                        bCount = num;
                    } else {
                        bCards.push(num + 1);
                        bCount--;
                    }
                } else {
                    aCards.push(num + 1);
                    pCount--;
                }
            }
        }
        return { aCards, bCards };
    };

    const { aCards, bCards } = loadCardData();

    const updateResult: TeenPattiResult = {
        FResult: fResult.toString(),
        GameCount: GameCount,
        GameID: GameID,
        Result: result ?? Result,
        PlayerACard: aCards,
        PlayerBCard: bCards,
    };

    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean,
    isMsgPopup: boolean = false
) => {
    if (isRest) {
        return LocalState.REST;
    }

    const getResultState = () => {
        return isMsgPopup ? -1 : LocalState.DEALING;
    };

    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case TeenPattiGameState.Idle:
            return LocalState.SHUFFLING;
        case TeenPattiGameState.Shuffle:
            return LocalState.SHUFFLING;
        case TeenPattiGameState.Betting:
            return LocalState.BETTING;
        case TeenPattiGameState.WaitPlayerAFirstCard:
            return LocalState.DEALING;
        case TeenPattiGameState.WaitPlayerBFirstCard:
        case TeenPattiGameState.WaitPlayerASecondCard:
        case TeenPattiGameState.WaitPlayerBSecondCard:
        case TeenPattiGameState.WaitPlayerAThirdCard:
        case TeenPattiGameState.WaitPlayerBThirdCard:
            return getResultState();
        case TeenPattiGameState.PlayerAWin:
            return isGameResultReleased
                ? LocalState.PLAYER_A
                : getResultState();
        case TeenPattiGameState.PlayerBWin:
            return isGameResultReleased
                ? LocalState.PLAYER_B
                : getResultState();
        case TeenPattiGameState.Tie:
            return isGameResultReleased ? LocalState.TIE : getResultState();
    }
    return LocalState.DEALING;
};

export const updatePattern = (
    pokers: Array<number>,
    pattern: number,
    isSixCard = false
) => {
    let lightArr: Array<number> = [];
    if (isSixCard) {
        switch (pattern) {
            case TeenPattiSixCardPattern.SCPNone:
                break;
            case TeenPattiSixCardPattern.SCPThreeOfKind:
                lightArr = [0, 1, 2];
                break;
            default:
                lightArr = [0, 1, 2, 3, 4];
                break;
        }
    } else {
        let pair = [];
        switch (pattern) {
            case TeenPattiPlayerPattern.PPHighCard:
                lightArr.push(pokers.indexOf(findHighest(pokers)));
                break;
            case TeenPattiPlayerPattern.PPPair:
                pair = findPairIn3(pokers);
                for (let i = 0; i < pokers.length; i++) {
                    if (pair.indexOf(pokers[i]) >= 0) {
                        lightArr.push(i);
                    }
                }
                break;
            default:
                lightArr = [0, 1, 2];
                break;
        }
    }
    return lightArr;
};

const findHighest = (pokers: Array<number>) => {
    let card = pokers[0];
    for (let i = 0; i < pokers.length; i++) {
        if (pokers[i] % 13 == 0) {
            return pokers[i];
        } else if (pokers[i] % 13 > card % 13) {
            card = pokers[i];
        }
    }
    return card;
};

const findPairIn3 = (pokers: Array<number>): Array<number> => {
    if (pokers[0] % 13 == pokers[1] % 13) {
        return [pokers[0], pokers[1]];
    }
    if (pokers[0] % 13 == pokers[2] % 13) {
        return [pokers[0], pokers[2]];
    }
    if (pokers[1] % 13 == pokers[2] % 13) {
        return [pokers[1], pokers[2]];
    }
    return [];
};

export const loadCardData = (resultString: string) => {
    const resultStr = resultString ?? '';
    let arr = resultStr.split(',');
    let pCount: number = -1;
    let bCount: number = -1;
    let aCards = [];
    let bCards = [];
    for (let i = 0; i < arr.length; i++) {
        let num = Number(arr[i]);
        if (i == 0) {
            pCount = num;
        } else {
            if (pCount == 0) {
                if (bCount == -1) {
                    bCount = num;
                } else {
                    bCards.push(num);
                    bCount--;
                }
            } else {
                aCards.push(num);
                pCount--;
            }
        }
    }
    return { aCards, bCards };
};
