import { BaseRecord } from '../BaseRecord';

export type Record = BaseRecord & OwnRecord;

export type OwnRecord = {
    color?: RouletteColor;
    oddEven?: RouletteOddEven;
    bigSmall?: RouletteBigSmall;
};

export enum RouletteColor {
    RED,
    BLACK,
    GREEN,
}

export enum RouletteOddEven {
    ODD,
    EVEN,
}

export enum RouletteBigSmall {
    BIG,
    SMALL,
}
