import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDField implements ICommand
{

	public Index : number = 0;
	public Value : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Index = ba.readUint16();
		this.Value = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.Index);
		ba.writeUint32(this.Value);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDField();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Index(W)='+this.Index+' ';
		str += 'Value(D)='+this.Value+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Field';
	}

}
