export enum GameState {
    Idle,
    Betting,
    WaitPlayerAFirstCard,
    WaitPlayerBFirstCard,
    WaitPlayerASecondCard,
    WaitPlayerBSecondCard,
    WaitPlayerAThirdCard,
    WaitPlayerBThirdCard,

    PlayerAWin = 0x7000,
    PlayerBWin,
    Tie,
    Shuffle = 0x7fff,
    ShuffleEnd = 0x8000,
    Login = -1,
    WaitingNewGame = -2,
}
