import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    LimitRed,
    LimitRedByGame,
    limitRedSliceActions,
} from '../../../modules/main/slice/limitRed';
import { resultProgressSliceActions } from '../../../modules/progress/slice/result';
import { Progress } from '../../games/Progress';
import { CMDSpLimitRedUpdate, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpLimitRedUpdateHandler extends AbstractHandler {
    _command: CMDSpLimitRedUpdate;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpLimitRedUpdate;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const limitRedByGames = this._command.BetRuleByGameType.map(
            lr =>
                ({
                    GameType: lr.GameType,
                    BetRule: lr.BetRule.sort((a, b) => {
                        if (a.MinBet < b.MinBet) return -1;
                        if (a.MinBet > b.MinBet) return 1;

                        if (a.LimitRed < b.LimitRed) return -1;
                        if (a.LimitRed > b.LimitRed) return 1;

                        return 0;
                    }).map(
                        br =>
                            ({
                                RuleID: br.RuleID,
                                MinBet: br.MinBet,
                                LimitRed: br.LimitRed,
                                Selected: br.Selected,
                                Token: br.Token.filter((v, i) => {
                                    // if (v > br.LimitRed) {
                                    //     return false;
                                    // } else
                                    if (i >= 5) {
                                        //need 5 chips
                                        return false;
                                    }
                                    return true;
                                }),
                            }) as LimitRed
                    ),
                }) as LimitRedByGame
        );
        dispatch(limitRedSliceActions.updateData(limitRedByGames));
        dispatch(
            resultProgressSliceActions.endLoad(Progress.SERVER_USER_BET_LIMIT)
        );
    }
}
