import { GameState } from '@/models/games/enums/GameState';
import { getHostById } from '@/modules/host/slice';
import { RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useIsBettingInfo = (hostId: number) => {
    const { IsRest, CurrentState, ResultReleased, MaxBet, CurrentResult } =
        useSelector((state: RootState) => getHostById(state, hostId));
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const [isShuffling, setIsShuffling] = useState<boolean>(false);
    useEffect(() => {
        if (IsRest) {
            setIsBetting(false);
            return;
        }
        // handleRoundDisable();
        setIsBetting(CurrentState === GameState.Betting);
        setIsShuffling(CurrentResult?.GameCount === 0);
    }, [IsRest, CurrentState, ResultReleased]);
    return { isBetting, isShuffling, MaxBet, CurrentResult };
};
