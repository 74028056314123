export enum GameCategory {
    GCAll = (1 << 15) - 1,
    GCJackpotAmount = 1 << 0,
    GCLive = 1 << 1,
    GCEGame = 1 << 2,
    GCLottery = 1 << 3,
    GCJackpotSource = 1 << 4,
    GCMultiPlayer = 1 << 5,
    GCPvP = 1 << 6,
    GCRTGSlot = 1 << 7,
    GCAutoBaccarat = 1 << 9,
}

export enum MainCategoryTab {
    Featured,
    Favorite,
    LiveGames,
    MultiBet,
}
