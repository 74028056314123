import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDXocdiaStatistics implements ICommand
{

	public FourRedCount : number = 0;
	public ThreeRedCount : number = 0;
	public TwoRedTwoWhiteCount : number = 0;
	public ThreeWhiteCount : number = 0;
	public FourWhiteCount : number = 0;
	public FourRedOrWhiteCount : number = 0;
	public BigCount : number = 0;
	public SmallCount : number = 0;
	public OddCount : number = 0;
	public EvenCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.FourRedCount = ba.readUint8();
		this.ThreeRedCount = ba.readUint8();
		this.TwoRedTwoWhiteCount = ba.readUint8();
		this.ThreeWhiteCount = ba.readUint8();
		this.FourWhiteCount = ba.readUint8();
		this.FourRedOrWhiteCount = ba.readUint8();
		this.BigCount = ba.readUint8();
		this.SmallCount = ba.readUint8();
		this.OddCount = ba.readUint8();
		this.EvenCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.FourRedCount);
		ba.writeUint8(this.ThreeRedCount);
		ba.writeUint8(this.TwoRedTwoWhiteCount);
		ba.writeUint8(this.ThreeWhiteCount);
		ba.writeUint8(this.FourWhiteCount);
		ba.writeUint8(this.FourRedOrWhiteCount);
		ba.writeUint8(this.BigCount);
		ba.writeUint8(this.SmallCount);
		ba.writeUint8(this.OddCount);
		ba.writeUint8(this.EvenCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDXocdiaStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'FourRedCount(B)='+this.FourRedCount+' ';
		str += 'ThreeRedCount(B)='+this.ThreeRedCount+' ';
		str += 'TwoRedTwoWhiteCount(B)='+this.TwoRedTwoWhiteCount+' ';
		str += 'ThreeWhiteCount(B)='+this.ThreeWhiteCount+' ';
		str += 'FourWhiteCount(B)='+this.FourWhiteCount+' ';
		str += 'FourRedOrWhiteCount(B)='+this.FourRedOrWhiteCount+' ';
		str += 'BigCount(B)='+this.BigCount+' ';
		str += 'SmallCount(B)='+this.SmallCount+' ';
		str += 'OddCount(B)='+this.OddCount+' ';
		str += 'EvenCount(B)='+this.EvenCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'XocdiaStatistics';
	}

}
