import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetSummary implements ICommand
{

	public Amount : number = 0;
	public BetPlayerCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Amount = ba.readInt53();
		this.BetPlayerCount = ba.readUint16();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.Amount);
		ba.writeUint16(this.BetPlayerCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetSummary();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Amount(Q)='+this.Amount+' ';
		str += 'BetPlayerCount(W)='+this.BetPlayerCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetSummary';
	}

}
