import { tableBetSliceActions } from '@/modules/betTable/slice';
import { Dispatch } from 'react';
import { batch } from 'react-redux';
import { AnyAction } from 'redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import {
    convertResultToRecord,
    getResultForFResult,
} from '../../../utils/games/sicbo';
import { SicBoResult } from '../../games/sicbo';
import { CMDScInitSicbo, ICommand } from '../live';
import { CMDSicbo } from '../live/CMDSicbo';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitSicboHandler extends AbstractHandler {
    _command: CMDScInitSicbo;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitSicbo;
    }
    override convertResult(result: CMDSicbo): SicBoResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                Dice1: result.Detail.Dice1,
                Dice2: result.Detail.Dice2,
                Dice3: result.Detail.Dice3,
            },
            getResultForFResult(result.BaseResult.FResult)
        ) as SicBoResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.OldResult = this._command.OldResult.filter(o => {
            if ((o as CMDSicbo).BaseResult.Result === 0) return false;
            return true;
        }).map(o => this.convertResult(o));
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );
        game.ResultReleased = false;
        game.IsRest = this._command.Information.Rest != 0;
        const convertedRecord = game.OldResult.map(r =>
            convertResultToRecord(r as SicBoResult)
        );
        batch(() => {
            dispatch(
                tableBetSliceActions.initTable({
                    hostId: hostId,
                    gameId: this._command.CurrentResult.BaseResult.GameID,
                })
            );
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                })
            );
        });
    }
}

// const getResultForFResult = (FResult: bigint) => {
//     //SA -> SicBoResult.ts
//     const ba: ByteArray = new ByteArray();
//     ba.writeUint64(FResult);
//     const bitArray = new BitArray(ba);
//     return {
//         SRBigSmall: bitArray.readNumber(2), // 0 = None, 1 = Big, 2 = Small
//         SROddEven: bitArray.readNumber(2), // 0 = None, 1 = Odd, 2 = Even
//         SRTripleArmyOne: bitArray.readBoolean(),
//         SRTripleArmyTwo: bitArray.readBoolean(),
//         SRTripleArmyThree: bitArray.readBoolean(),
//         SRTripleArmyFour: bitArray.readBoolean(),
//         SRTripleArmyFive: bitArray.readBoolean(),
//         SRTripleArmySix: bitArray.readBoolean(),
//         SRTriple: bitArray.readNumber(3), // 0 = None, 1 - 6
//         SRAllTriple: bitArray.readBoolean(), // Must True When SRTriple Not Zero
//         SRPoint: bitArray.readNumber(5), // 0 = None, 4 - 17
//         SRLongOneTwo: bitArray.readBoolean(),
//         SRLongOneThree: bitArray.readBoolean(),
//         SRLongOneFour: bitArray.readBoolean(),
//         SRLongOneFive: bitArray.readBoolean(),
//         SRLongOneSix: bitArray.readBoolean(),
//         SRLongTwoThree: bitArray.readBoolean(),
//         SRLongTwoFour: bitArray.readBoolean(),
//         SRLongTwoFive: bitArray.readBoolean(),
//         SRLongTwoSix: bitArray.readBoolean(),
//         SRLongThreeFour: bitArray.readBoolean(),
//         SRLongThreeFive: bitArray.readBoolean(),
//         SRLongThreeSix: bitArray.readBoolean(),
//         SRLongFourFive: bitArray.readBoolean(),
//         SRLongFourSix: bitArray.readBoolean(),
//         SRLongFiveSix: bitArray.readBoolean(),
//         SRShort: bitArray.readNumber(3), // 0 = None, 1 - 6
//         SROddEvenCombination: bitArray.readNumber(2), // 0 = AllDicesOdd, 1 = TwoDicesOddOneDiceEven, 2 = TwoDicesEvenOneDiceOdd, 3 = AllDicesEven
//         SR_1_2_3_4: bitArray.readBoolean(),
//         SR_2_3_4_5: bitArray.readBoolean(),
//         SR_2_3_5_6: bitArray.readBoolean(),
//         SR_3_4_5_6: bitArray.readBoolean(),
//         SRCombination: bitArray.readNumber(6),
//     };
// };
