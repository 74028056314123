import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from './PromotionDialog';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { RootState } from '../../../../store/store';
import { getPromotionToolState } from '../../../main/selector';
import { startQuery, stopQuery } from '../PromotionUtil';
import { popupSliceActions } from '../../slice';
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from '../../../../models/Popup';
import { CMDPsQueryPromotionRedeemLog } from '../../../../models/cmd/live';
import { getPopupState } from '../../selector';
import { PromotionRecordCell } from './PromotionRecordCell';
import { PromotionButton } from './PromotionButton';
// import { ScreenContext } from '@/contexts/ScreenContext';
import { getPromotionTitle } from '../PromotionObjectMapper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper/modules';
import { BaseTypography } from '@/components/common/baseText/BaseTypography';
import { getPromotionTool } from '@/components/images/base64/PromotionTool';
import { usePromotionTool } from '@/hooks/popup/promotiontool/usePromotionTool';

export type RedeemProps = {
    RedeemID: number;
    RedeemTime: number;
    PrizeRank: number;
    ResultAmount: number;
    LastRecord: boolean;
};
export const PromotionRecord = () => {
    const { eventEnded } = usePromotionTool();
    // const { isLandscape } = useContext(ScreenContext);
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const { Record } = useSelector((state: RootState) =>
        getPromotionToolState(state)
    );
    const confirmFunc = useSelector(getPopupState).confirmValue;
    const [record, setRecord] = useState<RedeemProps[]>([]);
    const { t, i18n } = useTranslation();

    const onTimeout = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.isLoading(false));
        dispatch(
            popupSliceActions.open(
                'promotion_tool.query_timeout',
                POPUP_TYPE.CONFIRM,
                POPUP_CONFIRM_ACTION.RETRY,
                undefined
            )
        );
    };

    const doQuery = () => {
        dispatch(promotionToolSliceActions.isLoading(true));
        const cmd = new CMDPsQueryPromotionRedeemLog();
        sendCommand(cmd);
        startQuery(onTimeout);

        if (eventEnded()) {
            //get last data when event ended
            updateRecordData();
        }
    };

    useEffect(() => {
        doQuery();
    }, []);

    useEffect(() => {
        updateRecordData();
    }, [Record]);

    useEffect(() => {
        if (confirmFunc === POPUP_CONFIRM_ACTION.RETRY) {
            doQuery();
        }
    }, [confirmFunc]);

    const updateRecordData = () => {
        const isEmptyData = !Record || Record.length == 0;
        if (Record) {
            dispatch(promotionToolSliceActions.isLoading(false));
            stopQuery();
        }

        let rec: RedeemProps[] = [];
        if (!isEmptyData) {
            //update
            Record.forEach((str, index) => {
                const data: RedeemProps = JSON.parse(str);
                data.LastRecord = index === Record.length - 1;
                rec.push(data);
            });
        }
        setRecord(rec);
    };

    const destroy = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.clearRedeemLog());
    };

    return (
        <Box>
            <Box className="event-title">
                {/* <BaseImage
                        className={'event_title event_title'}
                        isMultiLang={true}
                        scale={1}
                    /> */}
                <Box>
                    {getPromotionTool(
                        getPromotionTitle(
                            `event_title_${i18n.language.replace(/-/g, '_')}`
                        )
                    )}
                </Box>
            </Box>
            <Box className="event-detail-list-content">
                <Box
                    sx={{
                        backgroundColor: '#bea57b',
                        width: '100%',
                        height: '2px',
                    }}
                ></Box>
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        width: '100%',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#bf9a5d',
                            textAlign: 'center',
                            fontSize: '18px',
                        }}
                    >
                        {t('promotion_tool.records')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#bea57b',
                        width: '100%',
                        height: '2px',
                    }}
                ></Box>
                <Box className="record-title-container">
                    <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                        <Typography className="record-item-date">
                            {t('promotion_tool.records_date')}
                        </Typography>

                        <Typography className="record-item-prize-level">
                            {/* {`${t('promotion_tool.rankings_win_amount')} ${'(' + currency + ')'}`} */}
                            {`${t('promotion_tool.records_level')}`}
                        </Typography>

                        <Typography className="record-item-win-amount">
                            {/* {`${t('promotion_tool.rankings_win_amount')} ${'(' + currency + ')'}`} */}
                            {`${t('promotion_tool.records_win_amount')}`}
                        </Typography>
                    </Box>
                    <Typography className="record-item-redemption-id">
                        {t('promotion_tool.redeem_id')}
                    </Typography>
                </Box>
                {/* <Box className="record-list">
                    {record.map((rec, index) => (
                        <PromotionRecordCell
                            record={rec}
                            key={`promotiontool-record-${index}`}
                        />
                    ))}
                </Box> */}
                <Swiper
                    className="record-list"
                    slidesPerView={'auto'}
                    direction={'vertical'}
                    freeMode
                    mousewheel
                    modules={[FreeMode, Mousewheel]}
                    touchStartForcePreventDefault={true}
                >
                    {record.map((rec, index) => (
                        <SwiperSlide
                            key={`promotiontool-record-slide-${index}`}
                        >
                            <PromotionRecordCell
                                record={rec}
                                key={`promotiontool-record-${index}`}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
            <Stack className="page-buttons" direction={'row'} spacing={'20px'}>
                <PromotionButton
                    className="button_gold"
                    onClick={() => {
                        destroy();

                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Gacha
                            )
                        );
                    }}
                >
                    <BaseTypography
                        resize={{
                            direction: 'horizontal',
                            value: '200px',
                        }}
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        {t('promotion_tool.explore_rewards')}
                    </BaseTypography>
                </PromotionButton>
                <PromotionButton
                    className="button_black"
                    onClick={() => {
                        destroy();

                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Ranking
                            )
                        );
                    }}
                >
                    {t('promotion_tool.rankings')}
                </PromotionButton>
            </Stack>
            {Record && record.length < 1 ? (
                <Typography className="no-promotion-records">
                    {t('promotion_tool.no_records')}
                </Typography>
            ) : (
                <></>
            )}
        </Box>
    );
};
