import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBaseResult implements ICommand
{

	public GameID : number = 0;
	public GameCount : number = 0;
	public FResult : bigint = 0n; 
	public Result : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.GameCount = ba.readUint32();
		this.FResult = ba.readUint64();
		this.Result = Number(this.FResult);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint32(this.GameCount);
		ba.writeUint64(this.FResult);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBaseResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'GameCount(D)='+this.GameCount+' ';
		str += 'Result(F)='+this.Result+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BaseResult';
	}

}
