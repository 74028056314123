import {
    DEFAULT_FLASH_ANIMATION_CHANGES,
    DEFAULT_FLASH_ANIMATION_DURATION,
} from '@/hooks/useWinningAnim';
import { BetType as BetTypeEnum } from '@/models/games/roulette/BetType';
import { getWinBetType } from '@/utils/games/roulette';
import { AnimationControls } from 'framer-motion';

export const setTapAreaOpacity = (
    checkOpacityTapAreaIds: Array<string>,
    onOverBetTypes: Array<number>,
    isBetting: boolean
): void => {
    for (let i = 0; i < checkOpacityTapAreaIds.length; i++) {
        const svg = document.getElementById(checkOpacityTapAreaIds[i]);
        if (svg) {
            const id = svg.getAttribute('id');
            if (id) {
                const ownType = Number(id.split('_')[2]);
                let opacity = '0';
                if (isBetting) {
                    opacity =
                        onOverBetTypes.find(overType => overType === ownType) !=
                        undefined
                            ? '0.5'
                            : '0';
                }
                svg.setAttribute('fill-opacity', opacity);
            }
        }
    }
};

export interface Controllers {
    [key: string]: AnimationControls;
}

export const setResultFlash = (
    checkFlashAreaIds: Array<string>,
    controllers: Controllers,
    result: number | undefined
): void => {
    if (result) {
        const winBetType = getWinBetType(result);
        for (let i = 0; i < checkFlashAreaIds.length; i++) {
            const svg = document.getElementById(checkFlashAreaIds[i]);

            if (svg) {
                const id = svg.getAttribute('id');
                if (id) {
                    const ownType = Number(id.split('_')[2]);
                    if (winBetType.includes(ownType)) {
                        console.log('Roulette::flash', ownType);
                        const controller =
                            controllers[
                                `controller_${ownType}` as keyof Controllers
                            ];

                        if (controller) {
                            controller.start({
                                transition: DEFAULT_FLASH_ANIMATION_DURATION,
                                fillOpacity: DEFAULT_FLASH_ANIMATION_CHANGES,
                            });
                        }
                    }
                }
            }
        }
    }
};

export const disableResultFlash = (
    checkFlashAreaIds: Array<string>,
    controllers: Controllers
) => {
    for (let i = 0; i < checkFlashAreaIds.length; i++) {
        const svg = document.getElementById(checkFlashAreaIds[i]);

        if (svg) {
            const id = svg.getAttribute('id');
            if (id) {
                const ownType = Number(id.split('_')[2]);
                // console.log('Roulette::flash::disable', ownType);
                const controller =
                    controllers[`controller_${ownType}` as keyof Controllers];

                if (controller) {
                    controller.start({
                        fillOpacity: 0,
                        transition: { duration: 0 },
                    });
                }
            }
        }
    }
};

export const setRacetrackTapAreaOpacity = (
    checkOpacityTapAreaIds: Array<string>,
    onOverBetTypes: Array<number>,
    isBetting: boolean
): void => {
    const otherTypes: Array<number> = [1500, 1501, 1502, 1503];
    let otherContainNum: Array<number> = [];
    let isOtherTypes: boolean = false;
    if (otherTypes.find(otherType => otherType === onOverBetTypes[0])) {
        isOtherTypes = true;
        switch (onOverBetTypes[0]) {
            case 1503:
                otherContainNum = [
                    5, 8, 10, 11, 13, 16, 23, 24, 27, 30, 33, 36,
                ];
                break;
            case 1502:
                otherContainNum = [1, 6, 9, 14, 17, 20, 31, 34];
                break;
            case 1501:
                otherContainNum = [
                    0, 2, 3, 4, 7, 12, 15, 18, 21, 19, 22, 25, 26, 28, 29, 32,
                    35,
                ];
                break;
            case 1500:
                otherContainNum = [0, 3, 12, 15, 26, 32, 35];
                break;
        }

        for (let i = 0; i < checkOpacityTapAreaIds.length; i++) {
            const svg = document.getElementById(checkOpacityTapAreaIds[i]);
            if (svg) {
                const id = svg.getAttribute('id');
                if (id) {
                    const ownType = Number(id.split('_')[2]);
                    let opacity = '0';
                    if (isBetting) {
                        opacity =
                            otherContainNum.find(
                                overType => overType === ownType
                            ) != undefined
                                ? '0.5'
                                : ownType == onOverBetTypes[0]
                                  ? '0.5'
                                  : '0';
                    }
                    svg.setAttribute('fill-opacity', opacity);
                }
            }
        }
    }

    if (!isOtherTypes) {
        const convertBetTypeBase: number =
            onOverBetTypes[0] >= 1000 && onOverBetTypes[0] < 1500 ? 1000 : 0;

        for (let i = 0; i < checkOpacityTapAreaIds.length; i++) {
            const svg = document.getElementById(checkOpacityTapAreaIds[i]);
            if (svg) {
                const id = svg.getAttribute('id');
                if (id) {
                    const ownType = Number(id.split('_')[2]);
                    let opacity = '0';
                    if (isBetting) {
                        opacity =
                            onOverBetTypes.find(
                                overType =>
                                    overType + convertBetTypeBase === ownType
                            ) != undefined
                                ? '0.5'
                                : '0';
                    }
                    svg.setAttribute('fill-opacity', opacity);
                }
            }
        }
    }
};

export const onOver = (
    event: React.TouchEvent,
    isBetting: boolean,
    isWaitingBetResult: boolean
) => {
    if (!isBetting || isWaitingBetResult) {
        return null;
    }

    const touch = event.touches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);

    if (target && target.hasAttribute('name')) {
        return target.getAttribute('name');
    } else {
        return null;
    }
};

export const onMouseTapOver = (
    event: React.MouseEvent,
    isBetting: boolean,
    isWaitingBetResult: boolean
) => {
    if (!isBetting || isWaitingBetResult) {
        return null;
    }

    const target = document.elementFromPoint(event.clientX, event.clientY);

    if (target && target.hasAttribute('name')) {
        return target.getAttribute('name');
    } else {
        return null;
    }
};

const RB19To36_OPPOSITE_BET_TYPE = [BetTypeEnum.RB1To18];
const RB1To18_OPPOSITE_BET_TYPE = [BetTypeEnum.RB19To36];
const RBRed_OPPOSITE_BET_TYPE = [BetTypeEnum.RBBlack];
const RBBlack_OPPOSITE_BET_TYPE = [BetTypeEnum.RBRed];
const RBOdd_OPPOSITE_BET_TYPE = [BetTypeEnum.RBEven];
const RBEven_OPPOSITE_BET_TYPE = [BetTypeEnum.RBOdd];
const RBRow1_OPPOSITE_BET_TYPE = [BetTypeEnum.RBRow2, BetTypeEnum.RBRow3];
const RBRow2_OPPOSITE_BET_TYPE = [BetTypeEnum.RBRow1, BetTypeEnum.RBRow3];
const RBRow3_OPPOSITE_BET_TYPE = [BetTypeEnum.RBRow1, BetTypeEnum.RBRow2];
const RBSet1_OPPOSITE_BET_TYPE = [BetTypeEnum.RBSet2, BetTypeEnum.RBSet3];
const RBSet2_OPPOSITE_BET_TYPE = [BetTypeEnum.RBSet1, BetTypeEnum.RBSet3];
const RBSet3_OPPOSITE_BET_TYPE = [BetTypeEnum.RBSet1, BetTypeEnum.RBSet2];

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    let opposite: Array<number>;
    switch (betType) {
        case BetTypeEnum.RB19To36:
            opposite = RB19To36_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RB1To18:
            opposite = RB1To18_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBRed:
            opposite = RBRed_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBBlack:
            opposite = RBBlack_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBOdd:
            opposite = RBOdd_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBEven:
            opposite = RBEven_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBRow1:
            opposite = RBRow1_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBRow2:
            opposite = RBRow2_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBRow3:
            opposite = RBRow3_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBSet1:
            opposite = RBSet1_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBSet2:
            opposite = RBSet2_OPPOSITE_BET_TYPE;
            break;
        case BetTypeEnum.RBSet3:
            opposite = RBSet3_OPPOSITE_BET_TYPE;
            break;
        default:
            opposite = [];
            break;
    }
    if (opposite.length == 0) {
        return false;
    } else {
        let haveBet = 0;
        for (let i = 0; i < opposite.length; i++) {
            if (
                betsSummary[opposite[i]] != undefined &&
                betsSummary[opposite[i]] > 0
            ) {
                haveBet++;
            }
        }
        return haveBet === opposite.length;
    }
};

export const GetNeighbors = (
    array: Array<number>,
    target: number,
    numOfNeighbors: number
): Array<number> => {
    const index = array.indexOf(target);

    if (index === -1) {
        return [];
    }

    const result = [];
    const length = array.length;

    for (let i = index - numOfNeighbors; i <= index + numOfNeighbors; i++) {
        let neighborIndex = i;
        if (neighborIndex < 0) {
            neighborIndex = length + neighborIndex;
        } else if (neighborIndex >= length) {
            neighborIndex = neighborIndex - length;
        }
        result.push(array[neighborIndex]);
    }

    return result;
};
