import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDHsPokerDrawByIndex implements ICommand
{

	public SlotIndex : number = 0;
	public CardIndex : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.SlotIndex = ba.readUint8();
		this.CardIndex = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.SlotIndex);
		ba.writeUint8(this.CardIndex);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDHsPokerDrawByIndex();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'SlotIndex(B)='+this.SlotIndex+' ';
		str += 'CardIndex(B)='+this.CardIndex+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'HsPokerDrawByIndex';
	}

}
