import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsMinigameAction implements ICommand
{

	public BetSource : number = 0;
	public UnknownData : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BetSource = ba.readUint32();
		this.UnknownData = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.BetSource);
		ba.writeString(this.UnknownData);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsMinigameAction();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetSource(D)='+this.BetSource+' ';
		str += 'UnknownData(S)='+this.UnknownData+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55032;
	}

	public getCmdName() : string
	{
		return 'PsMinigameAction';
	}

}
