import { Summary, betSummarySliceActions } from '@/modules/report/slice/betSummary';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpBetLogSummaryQuery, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpBetLogSummaryQueryHandler extends AbstractHandler {
    _command: CMDSpBetLogSummaryQuery;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBetLogSummaryQuery;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const summaries:Summary[] = [];
        for (let b of this._command.Bet) {
            const summary = {} as Summary;
            summary.ResultAmount = b.ResultAmount;
            summary.ReportDate = b.ReportDate;
            summary.RollingAmount = b.RollingAmount;
            summary.TurnOverAmount = b.TurnOverAmount;
            summaries.push(summary);
        }
        
        dispatch(
            betSummarySliceActions.updateSummary({
                summaries:summaries.reverse()
            })
        );
    }
}
