import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { Dealer } from '../../host/Dealer';
import { CMDScAnchorLogin, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScAnchorLoginHandler extends AbstractHandler {
    _command: CMDScAnchorLogin;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScAnchorLogin;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const hostId = this._command.HostID;
        const dealer = this._command.Anchor.map(
            a =>
                ({
                    DealerId: a.DealerID,
                    DealerName: a.DealerName,
                }) as Dealer
        );
        dispatch(hostsSliceActions.dealerLogin({ hostId, dealer }));
    }
}
