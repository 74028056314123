import { BaccaratBetsHistory, gameSliceActions } from '@/modules/games/slice';
import { getHostByGameId } from '@/modules/host/slice';
import store from '@/store/store';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { CMDSpBetWinDetail, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpBetWinDetailHandler extends AbstractHandler {
    _command: CMDSpBetWinDetail;
    _hostId: number;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBetWinDetail;
    }
    commandDelay() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        this._hostId = host.HostId;
        return host.DelayResult;
    }
    delayKey() {
        return this._command.GameID.toString();
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        let list = new Array<BaccaratBetsHistory>();
        list = this._command.betList.map(v => {
            return {
                hostId: this._hostId,
                gameId: this._command.GameID,
                betType: v.betType,
                isResultRelease: true,
                winAmount: v.SignedSignal == 1 ? v.WinAmount : v.WinAmount * -1,
            } as BaccaratBetsHistory;
        });

        dispatch(gameSliceActions.updateBaccaratBetHistoryPayout(list));
    }
}
