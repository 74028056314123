import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { DragonTigerTotalBet } from '../../games/dragonTiger';
import { CMDScTotalBetDragontiger, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetDragontigerHandler extends AbstractHandler {
    _command: CMDScTotalBetDragontiger;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetDragontiger;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as DragonTigerTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;
        TotalBet.Dragon = this.convertBetAmount(this._command.Dragon);
        TotalBet.Tiger = this.convertBetAmount(this._command.Tiger);
        TotalBet.Tie = this.convertBetAmount(this._command.Tie);
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };
        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
