import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsMakeDecision implements ICommand
{

	public GameID : number = 0;
	public Decision : number = 0;
	public BetSource : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.Decision = ba.readUint8();
		this.BetSource = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint8(this.Decision);
		ba.writeUint32(this.BetSource);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsMakeDecision();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'Decision(B)='+this.Decision+' ';
		str += 'BetSource(D)='+this.BetSource+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55034;
	}

	public getCmdName() : string
	{
		return 'PsMakeDecision';
	}

}
