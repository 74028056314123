import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSicboStatistics implements ICommand
{

	public Number1Count : number = 0;
	public Number2Count : number = 0;
	public Number3Count : number = 0;
	public Number4Count : number = 0;
	public Number5Count : number = 0;
	public Number6Count : number = 0;
	public BigCount : number = 0;
	public SmallCount : number = 0;
	public TripleCount : number = 0;
	public EvenCount : number = 0;
	public OddCount : number = 0;
	public Point4Count : number = 0;
	public Point5Count : number = 0;
	public Point6Count : number = 0;
	public Point7Count : number = 0;
	public Point8Count : number = 0;
	public Point9Count : number = 0;
	public Point10Count : number = 0;
	public Point11Count : number = 0;
	public Point12Count : number = 0;
	public Point13Count : number = 0;
	public Point14Count : number = 0;
	public Point15Count : number = 0;
	public Point16Count : number = 0;
	public Point17Count : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Number1Count = ba.readUint8();
		this.Number2Count = ba.readUint8();
		this.Number3Count = ba.readUint8();
		this.Number4Count = ba.readUint8();
		this.Number5Count = ba.readUint8();
		this.Number6Count = ba.readUint8();
		this.BigCount = ba.readUint8();
		this.SmallCount = ba.readUint8();
		this.TripleCount = ba.readUint8();
		this.EvenCount = ba.readUint8();
		this.OddCount = ba.readUint8();
		this.Point4Count = ba.readUint8();
		this.Point5Count = ba.readUint8();
		this.Point6Count = ba.readUint8();
		this.Point7Count = ba.readUint8();
		this.Point8Count = ba.readUint8();
		this.Point9Count = ba.readUint8();
		this.Point10Count = ba.readUint8();
		this.Point11Count = ba.readUint8();
		this.Point12Count = ba.readUint8();
		this.Point13Count = ba.readUint8();
		this.Point14Count = ba.readUint8();
		this.Point15Count = ba.readUint8();
		this.Point16Count = ba.readUint8();
		this.Point17Count = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Number1Count);
		ba.writeUint8(this.Number2Count);
		ba.writeUint8(this.Number3Count);
		ba.writeUint8(this.Number4Count);
		ba.writeUint8(this.Number5Count);
		ba.writeUint8(this.Number6Count);
		ba.writeUint8(this.BigCount);
		ba.writeUint8(this.SmallCount);
		ba.writeUint8(this.TripleCount);
		ba.writeUint8(this.EvenCount);
		ba.writeUint8(this.OddCount);
		ba.writeUint8(this.Point4Count);
		ba.writeUint8(this.Point5Count);
		ba.writeUint8(this.Point6Count);
		ba.writeUint8(this.Point7Count);
		ba.writeUint8(this.Point8Count);
		ba.writeUint8(this.Point9Count);
		ba.writeUint8(this.Point10Count);
		ba.writeUint8(this.Point11Count);
		ba.writeUint8(this.Point12Count);
		ba.writeUint8(this.Point13Count);
		ba.writeUint8(this.Point14Count);
		ba.writeUint8(this.Point15Count);
		ba.writeUint8(this.Point16Count);
		ba.writeUint8(this.Point17Count);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSicboStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Number1Count(B)='+this.Number1Count+' ';
		str += 'Number2Count(B)='+this.Number2Count+' ';
		str += 'Number3Count(B)='+this.Number3Count+' ';
		str += 'Number4Count(B)='+this.Number4Count+' ';
		str += 'Number5Count(B)='+this.Number5Count+' ';
		str += 'Number6Count(B)='+this.Number6Count+' ';
		str += 'BigCount(B)='+this.BigCount+' ';
		str += 'SmallCount(B)='+this.SmallCount+' ';
		str += 'TripleCount(B)='+this.TripleCount+' ';
		str += 'EvenCount(B)='+this.EvenCount+' ';
		str += 'OddCount(B)='+this.OddCount+' ';
		str += 'Point4Count(B)='+this.Point4Count+' ';
		str += 'Point5Count(B)='+this.Point5Count+' ';
		str += 'Point6Count(B)='+this.Point6Count+' ';
		str += 'Point7Count(B)='+this.Point7Count+' ';
		str += 'Point8Count(B)='+this.Point8Count+' ';
		str += 'Point9Count(B)='+this.Point9Count+' ';
		str += 'Point10Count(B)='+this.Point10Count+' ';
		str += 'Point11Count(B)='+this.Point11Count+' ';
		str += 'Point12Count(B)='+this.Point12Count+' ';
		str += 'Point13Count(B)='+this.Point13Count+' ';
		str += 'Point14Count(B)='+this.Point14Count+' ';
		str += 'Point15Count(B)='+this.Point15Count+' ';
		str += 'Point16Count(B)='+this.Point16Count+' ';
		str += 'Point17Count(B)='+this.Point17Count+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'SicboStatistics';
	}

}
