import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpGameResultInvalid implements ICommand
{

	public GameID : number = 0;
	public PlusFlag : number = 0;
	public Amount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.PlusFlag = ba.readUint8();
		this.Amount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint8(this.PlusFlag);
		ba.writeUint53(this.Amount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpGameResultInvalid();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'PlusFlag(B)='+this.PlusFlag+' ';
		str += 'Amount(Q)='+this.Amount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50005;
	}

	public getCmdName() : string
	{
		return 'SpGameResultInvalid';
	}

}
