import { CMDBaseResult } from "./CMDBaseResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBlackjack implements ICommand
{

	public BaseResult : CMDBaseResult;
	public JSON : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseResult = new CMDBaseResult();
		this.BaseResult.fromBytes(ba);
		this.JSON = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeString(this.JSON);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBlackjack();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseResult(CMDBaseResult)='+this.BaseResult+' ';
		str += 'JSON(S)='+this.JSON+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Blackjack';
	}

}
