import { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { InGamePopupState, inGamePopupAdapter } from './inGameSlice';
import { InGamePopupDataPayload, InGamePopupOpenPayload } from './payload';

const updateDataSet = (
    state: EntityState<InGamePopupState, number>,
    action: PayloadAction<InGamePopupDataPayload>
) => {
    return inGamePopupAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            gameId: action.payload.gameId,
            key: action.payload.key,
            gameType: action.payload.gameType,
            stateMsgType: action.payload.stateMsgType,
            params: action.payload.params,
        },
    });
};

const updateIsOpen = (
    state: EntityState<InGamePopupState, number>,
    action: PayloadAction<InGamePopupOpenPayload>
) => {
    return inGamePopupAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: { isOpen: action.payload.isOpen },
    });
};

export const inGamePopupReducers = {
    updateIsOpen,
    updateDataSet,
};
