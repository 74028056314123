import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AndarBaharTotalBet } from '../../../models/games/andarBahar';
import { BaccaratTotalBet } from '../../../models/games/baccarat';
import { BlackjackTotalBet } from '../../../models/games/blackjack';
import { DragonTigerTotalBet } from '../../../models/games/dragonTiger';
import { PokDengTotalBet } from '../../../models/games/pokdeng';
import { RouletteTotalBet } from '../../../models/games/roulette';
import { SedieTotalBet } from '../../../models/games/sedie';
import { SicboTotalBet } from '../../../models/games/sicbo';
import { TeenPattiTotalBet } from '../../../models/games/teenpatti';
import { RootState } from '../../../store/store';

export type CommonTotalBet =
    | BaccaratTotalBet
    | DragonTigerTotalBet
    | PokDengTotalBet
    | SicboTotalBet
    | SedieTotalBet
    | TeenPattiTotalBet
    | AndarBaharTotalBet
    | RouletteTotalBet
    | BlackjackTotalBet;
export type TotalBet = {
    HostId: number;
    TotalBet: CommonTotalBet;
};
export const TOTAL_BET_KEY = 'app::total::bet';
export const totalBetsAdapter = createEntityAdapter<TotalBet, number>({
    selectId: tb => tb.HostId,
    sortComparer: (a, b) => a.HostId - b.HostId,
});
const initialTotalBetsState = totalBetsAdapter.getInitialState();
const totalBetsSlice = createSlice({
    name: TOTAL_BET_KEY,
    initialState: initialTotalBetsState,
    reducers: {
        update: totalBetsAdapter.upsertOne,
        updateAll: totalBetsAdapter.upsertMany,
    },
});
export const totalBetsSliceReducer = totalBetsSlice.reducer;
export const totalBetsSliceActions = totalBetsSlice.actions;
export const getTotalBetsSelectors = totalBetsAdapter.getSelectors<RootState>(
    state => state[TOTAL_BET_KEY]
);
export const getTotalBets = getTotalBetsSelectors.selectById;
