import { useEffect, useRef, useState } from 'react';

export const useObserveElementRect = <T extends HTMLElement>() => {
    const [rect, setRect] = useState<DOMRectReadOnly>();
    const [bounding, setBounding] = useState<DOMRect>();
    const ref = useRef<T>(null);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                const entry = entries[0];
                const b = entry.target.getBoundingClientRect();

                setRect(entry.contentRect);
                setBounding(b);
            });
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            ref.current && observer.unobserve(ref.current);
        };
    }, []);

    return {
        ref,
        // self width and height
        rect,
        // width and height and the position of the body
        bounding,
    };
};
