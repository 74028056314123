import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetTypeMaxBet implements ICommand
{

	public BetType : number = 0;
	public MaxBet : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BetType = ba.readUint8();
		this.MaxBet = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.BetType);
		ba.writeUint53(this.MaxBet);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetTypeMaxBet();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetType(B)='+this.BetType+' ';
		str += 'MaxBet(Q)='+this.MaxBet+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetTypeMaxBet';
	}

}
