import {
    BaccaratRecord,
    BaccaratRoadmapRecord,
} from '../../../models/games/baccarat';
import { RoadmapRecord } from '../../../models/games/baccarat/Roadmap';
import { SupportedRecord } from '../../host/models';
import { ROAD_MAP_TYPE } from '../../lobby/constants';

export const getUnion = (list: Array<BaccaratRoadmapRecord>) => {
    let tmp: BaccaratRoadmapRecord = {
        LastWin: list[0].LastWin,
        Win: list[0].Win,
        WinPoint: list[0].WinPoint,
        BankerPair: list[0].BankerPair,
        PlayerPair: list[0].PlayerPair,
        SuperSix: list[0].SuperSix,
        // type: list[0]["type"],
        LastDraw: list[0].LastDraw,
        SpaceNum: list.length - 1,
        IsLast: false,
        Detail: list[0].Detail,
        AskType: list[0].AskType,
    };
    for (let i = 0; i < list.length; i++) {
        if (list[i].BankerPair) tmp.BankerPair = true;
        if (list[i].PlayerPair) tmp.PlayerPair = true;
        if (list[i].LastDraw) tmp.LastDraw = true;
        if (list[i].SuperSix) tmp.SuperSix = true;
        if (list[i].Detail) tmp.Detail = list[i].Detail;
        if (list[i].AskType > -1) tmp.AskType = list[i].AskType;
        if (list[i].IsLast) tmp.IsLast = true;
    }
    return tmp;
};
export const getBeadRoadRecord = (
    record: SupportedRecord
): BaccaratRoadmapRecord => {
    const result = record as BaccaratRecord;
    let win = -1; //-1 empty, 0 banker, 1 player, 2 tie
    if (result.bankerWin == result.playerWin) {
        win = result.tie ? 2 : -1;
    } else {
        win = result.bankerWin ? 0 : 1;
    }

    let winPoint: number = 0;
    winPoint = win == 0 ? result.bankerPoint : result.playerPoint;

    let tmp: BaccaratRoadmapRecord = {
        LastWin: -1,
        Win: win,
        WinPoint: winPoint,
        BankerPair: result.bankerPair,
        PlayerPair: result.playerPair,
        SuperSix: result.superSix,
        // Idx: idx,
        // TODO: still need type?
        LastDraw: false,
        SpaceNum: 0,
        IsLast: true,
        AskType: -1,
        Detail: {
            GameID: result.gameID,
            Result: result.result,
            Shoes: result.shoes,
            Round: result.round,
            Player1: result.player1,
            Player2: result.player2,
            Player3: result.player3,
            Banker1: result.banker1,
            Banker2: result.banker2,
            Banker3: result.banker3,
            PlayerWin: result.playerWin,
            BankerWin: result.bankerWin,
            Tie: result.tie,
            PlayerPair: result.playerPair,
            BankerPair: result.bankerPair,
            PlayerNatural: result.playerNatural,
            BankerNatural: result.bankerNatural,
            SuperSix: result.superSix,
            SuperSixPlayer: result.superSixPlayer,
            SuperSixBanker: result.superSixBanker,
            SuperSixTie: result.superSixTie,
            SuperSixPlayerPair: result.superSixPlayerPair,
            SuperSixBankerPair: result.superSixBankerPair,
            SuperSixPlayerNatural: result.superSixPlayerNatural,
            SuperSixBankerNatural: result.superSixBankerNatural,
            PlayerCowWin: result.playerCowWin,
            BankerCowWin: result.bankerCowWin,
            CowTie: result.cowTie,
            PlayerPoint: result.playerPoint,
            BankerPoint: result.bankerPoint,
        },
    };

    return tmp;
};
export const fillEmptyBead = (
    record: Array<Array<BaccaratRoadmapRecord>>,
    x: number,
    y: number
) => {
    while (record.length <= x) {
        let tmp: Array<BaccaratRoadmapRecord> = [];
        tmp.push({
            LastWin: 0,
            Win: -1,
            WinPoint: 0,
            BankerPair: false,
            PlayerPair: false,
            SuperSix: false,
            LastDraw: false,
            SpaceNum: 0,
            IsLast: false,
            AskType: -1,
        });
        record.push(tmp);
    }

    while (record[x].length <= y) {
        record[x].push({
            LastWin: 0,
            Win: -1,
            WinPoint: 0,
            BankerPair: false,
            PlayerPair: false,
            SuperSix: false,
            LastDraw: false,
            SpaceNum: 0,
            IsLast: false,
            AskType: -1,
        });
    }
};

export const getOtherRoad = (
    other: Array<Array<BaccaratRoadmapRecord>> | undefined,
    record: BaccaratRoadmapRecord
): Array<Array<BaccaratRoadmapRecord>> => {
    if (other && other.length > 0) {
        let arr = other[other.length - 1];
        if (record.Win == 2) {
            other[other.length - 1] = arr.map((r, i) => {
                return {
                    ...r,
                    LastDraw: i === other.length - 1 ? true : r.LastDraw,
                };
            });
        } else if (arr && arr[arr.length - 1].Win == record.Win) {
            other[other.length - 1] = [...arr, record];
        } else {
            other.push([record]);
        }
    } else {
        return [[record]];
    }
    return other;
};

export const getMappedRoad = (
    prevRoad: Array<BaccaratRoadmapRecord>,
    record: BaccaratRoadmapRecord,
    lastOther?: Array<BaccaratRoadmapRecord> //for space Union
): Array<BaccaratRoadmapRecord> => {
    let newRoad = new Array<BaccaratRoadmapRecord>();
    newRoad = newRoad.concat(prevRoad);

    let lastIdx = newRoad.findIndex(r => r.IsLast === true);
    if (record.Win == 2) {
        //tie
        if (newRoad[lastIdx])
            newRoad[lastIdx] = {
                ...newRoad[lastIdx],
                LastDraw: true,
                IsLast: true,
            };
    } else {
        const nextIdx = getNextIdx(newRoad, record.Win);
        if (nextIdx >= newRoad.length) {
            const toFill = nextIdx - newRoad.length + 1;
            for (let i = 0; i < toFill; i++) {
                newRoad.push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    BankerPair: false,
                    PlayerPair: false,
                    SuperSix: false,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: false,
                    AskType: -1,
                });
            }
        }
        if (nextIdx == lastIdx) {
            //if space one
            if (lastOther) {
                newRoad[nextIdx] = {
                    ...getUnion(lastOther),
                    IsLast: true,
                    SpaceNum: lastOther.length - 1,
                };
            }
        } else {
            newRoad[nextIdx] = { ...record, IsLast: true };
            if (newRoad[lastIdx])
                newRoad[lastIdx] = {
                    ...newRoad[lastIdx],
                    IsLast: false,
                };
        }
    }
    return newRoad;
};
export const getViewBeadRoadRecord = (
    road: Array<RoadmapRecord>,
    max: number
): Array<RoadmapRecord> => {
    if (!road) return [];
    let list = road;
    const over = Math.ceil((road.length - (max * 6 - 1)) / 6);
    if (over > 0) {
        list = road.slice(over * 6);
    }
    return list;
};
export const getViewRoadRecord = (
    road: Array<RoadmapRecord>,
    max: number
): Array<RoadmapRecord> => {
    if (!road) return [];
    let list = road;
    const over = Math.ceil((road.length - max * 6) / 6);
    if (over > 0) {
        let last = road.findIndex(r => r.IsLast === true);
        if (last < over * 6) {
            let lastPos = Math.floor(last / 6);
            list = road.slice(lastPos * 6, max * 6);
        } else {
            list = road.slice(over * 6);
        }
    }
    return list;
};

export const getNextIdx = (
    list: Array<BaccaratRoadmapRecord>,
    nextWinType: number
) => {
    if (nextWinType == -1 || nextWinType == undefined) return -1;
    let nextIdx = -1;
    const lastIdx = list.findIndex(r => r.IsLast);
    if (lastIdx == -1) {
        //empty list
        return 0;
    } else {
        if (list[lastIdx].Win === nextWinType) {
            if (lastIdx % 6 === 5) {
                nextIdx = lastIdx + 6;
            } else {
                if (lastIdx >= 6) {
                    if (list[lastIdx - 6].Win === nextWinType) {
                        if (list[lastIdx - 1].Win !== nextWinType) {
                            nextIdx = lastIdx + 6;
                        } else {
                            nextIdx = lastIdx + 1;
                        }
                    } else {
                        if (list[lastIdx + 1] && list[lastIdx + 1].Win === -1) {
                            //have long
                            if (
                                list[lastIdx + 2] &&
                                list[lastIdx + 2].Win === nextWinType
                            ) {
                                nextIdx = lastIdx + 6;
                            } else {
                                nextIdx = lastIdx + 1;
                            }
                        } else {
                            if (!list[lastIdx + 1] && (lastIdx + 1) % 6 !== 0) {
                                nextIdx = lastIdx + 1;
                            } else {
                                nextIdx = lastIdx + 6;
                            }
                        }
                        if (nextIdx % 6 === 0) {
                            //space blocked
                            nextIdx = lastIdx;
                        }
                    }
                } else {
                    nextIdx = lastIdx + 1;
                }
            }
        } else {
            //find next new line
            const nextNew = list.findIndex(
                (r, idx) => idx % 6 === 0 && r.Win === -1
            );
            if (nextNew === -1) {
                //no long road
                nextIdx = Math.ceil(list.length / 6) * 6;
            } else {
                nextIdx = nextNew;
            }
        }
    }
    return nextIdx;
};
export const getNextRoad = (
    otherRoad: Array<Array<BaccaratRoadmapRecord>>,
    typeID: number,
    askType: number
) => {
    let offset: number = 0;
    if (typeID == ROAD_MAP_TYPE.BIG_EYE_ROAD) {
        offset = 1;
    } else if (typeID == ROAD_MAP_TYPE.SMALL_ROAD) {
        offset = 2;
    } else if (typeID == ROAD_MAP_TYPE.COCKROACH_ROAD) {
        offset = 3;
    }
    if (otherRoad.length <= offset) {
        return -1; //no road
    }
    let last = otherRoad.length - 1;
    if (askType !== otherRoad[last][0].Win) {
        if (otherRoad[last].length == otherRoad[last - offset].length) {
            return 0; //red
        } else {
            return 1;
        }
    } else {
        if (otherRoad[last].length == otherRoad[last - offset].length) {
            return 1; //blue
        } else {
            return 0;
        }
    }
};

export const getSummaryList = (records: SupportedRecord[] | undefined) => {
    let bankerTotal = 0;
    let playerTotal = 0;
    let tieTotal = 0;
    let bankerPairTotal = 0;
    let playerPairTotal = 0;
    records &&
        records.forEach(r => {
            const record = r as BaccaratRecord;
            if (record.playerWin) playerTotal++;
            if (record.bankerWin) bankerTotal++;
            if (record.tie) tieTotal++;
            if (record.bankerPair) bankerPairTotal++;
            if (record.playerPair) playerPairTotal++;
        });
    return {
        bankerTotal,
        playerTotal,
        tieTotal,
        bankerPairTotal,
        playerPairTotal,
    };
};
