import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetLog implements ICommand
{

	public BetID : number = 0;
	public UTCSecond : number = 0;
	public HostID : number = 0;
	public GameType : number = 0;
	public GameCount : number = 0;
	public Result : string = "";
	public WinSlot : number = 0;
	public BetType : number = 0;
	public BetAmount : number = 0;
	public ResultAmount : number = 0;
	public Balance : number = 0;
	public State : number = 0;
	public Remark : string = "";
	public GameID : number = 0;
	public IsAutoBet : number = 0;
	public RollingAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BetID = ba.readInt53();
		this.UTCSecond = ba.readInt53();
		this.HostID = ba.readUint16();
		this.GameType = ba.readInt53();
		this.GameCount = ba.readUint32();
		this.Result = ba.readString();
		this.WinSlot = ba.readInt53();
		this.BetType = ba.readUint8();
		this.BetAmount = ba.readInt53();
		this.ResultAmount = ba.readInt53();
		this.Balance = ba.readInt53();
		this.State = ba.readUint8();
		this.Remark = ba.readString();
		this.GameID = ba.readInt53();
		this.IsAutoBet = ba.readUint8();
		this.RollingAmount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.BetID);
		ba.writeUint53(this.UTCSecond);
		ba.writeUint16(this.HostID);
		ba.writeUint53(this.GameType);
		ba.writeUint32(this.GameCount);
		ba.writeString(this.Result);
		ba.writeUint53(this.WinSlot);
		ba.writeUint8(this.BetType);
		ba.writeUint53(this.BetAmount);
		ba.writeUint53(this.ResultAmount);
		ba.writeUint53(this.Balance);
		ba.writeUint8(this.State);
		ba.writeString(this.Remark);
		ba.writeUint53(this.GameID);
		ba.writeUint8(this.IsAutoBet);
		ba.writeUint53(this.RollingAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetLog();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetID(Q)='+this.BetID+' ';
		str += 'UTCSecond(Q)='+this.UTCSecond+' ';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'GameType(Q)='+this.GameType+' ';
		str += 'GameCount(D)='+this.GameCount+' ';
		str += 'Result(S)='+this.Result+' ';
		str += 'WinSlot(Q)='+this.WinSlot+' ';
		str += 'BetType(B)='+this.BetType+' ';
		str += 'BetAmount(Q)='+this.BetAmount+' ';
		str += 'ResultAmount(Q)='+this.ResultAmount+' ';
		str += 'Balance(Q)='+this.Balance+' ';
		str += 'State(B)='+this.State+' ';
		str += 'Remark(S)='+this.Remark+' ';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'IsAutoBet(B)='+this.IsAutoBet+' ';
		str += 'RollingAmount(Q)='+this.RollingAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetLog';
	}

}
