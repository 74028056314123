import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import {
    convertResultToRecord,
    parseDeckData,
} from '../../../utils/games/pokdeng';
import { PokerShow } from '../../games/PokerShow';
import { GameState } from '../../games/enums/GameState';
import { PokDengResult } from '../../games/pokdeng';
import { Poker } from '../../host/Poker';
import { CMDScInitPokdeng, ICommand } from '../live';
import { CMDLivePokdengData } from '../live/CMDLivePokdengData';
import { CMDPokdeng } from '../live/CMDPokdeng';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitPokDengHandler extends AbstractHandler {
    _command: CMDScInitPokdeng;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitPokdeng;
    }
    override convertResult(result: CMDLivePokdengData): PokDengResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseData.BaseResult),
            {
                fullDeck:
                    result.PokerData.length == 0 ||
                    result.PokerData.length == 12
                        ? parseDeckData(result.BaseData.JSON)
                        : undefined,
                PokerData: result.PokerData.map(
                    p =>
                        ({
                            SlotIndex: p.SlotIndex,
                            CardIndex: p.CardIndex - 1,
                        }) as Poker
                ),
            }
        ) as PokDengResult;
        return newResult;
    }
    convertPokerResult(result: CMDPokdeng): PokDengResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                fullDeck: parseDeckData(result.JSON),
            }
        ) as PokDengResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        let results: Array<PokerShow>;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        if (this._command.CurrentState !== GameState.Idle) {
            const pokerDate = (game.CurrentResult as PokDengResult).PokerData;
            if (pokerDate) {
                results = new Array<PokerShow>();
                for (let i = 0; i < pokerDate?.length; i++) {
                    results.push({
                        Slot: pokerDate[i].SlotIndex,
                        Card: pokerDate[i].CardIndex,
                    });
                }
            }
        }
        game.OldResult = this._command.OldResult.map(o =>
            this.convertPokerResult(o)
        );
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );
        game.ResultReleased = false;
        game.IsRest = this._command.Information.Rest != 0;

        const convertedRecord =
            game.CurrentState === GameState.Shuffle
                ? []
                : game.OldResult.map(r =>
                      convertResultToRecord(r as PokDengResult)
                  );

        batch(() => {
            dispatch(
                tableBetSliceActions.initTable({
                    hostId: hostId,
                    gameId: this._command.CurrentResult.BaseData.BaseResult
                        .GameID,
                })
            );
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                })
            );
            if (results) {
                dispatch(
                    hostsSliceActions.updatePokerShow({
                        hostId: hostId,
                        results: results,
                    })
                );
            }
        });
    }
}
