import { RootState } from '../../store/store';
import { GAME_POPUP_KEY, inGamePopupAdapter, initialInGamePopup } from './inGameSlice';
import { POPUP_KEY, PopupState } from './slice';

export const getPopupState = (state: RootState) =>
    state[POPUP_KEY] as PopupState;
// export const getInGamePopupSate = (state: RootState) =>
//     state[GAME_POPUP_KEY] as InGamePopupState;

// selector
// export const getInGamePopupSelectors = inGamePopupAdapter.getSelectors<RootState>(
//     state => state[GAME_POPUP_KEY]
// );
export const getInGamePopupSelectors = inGamePopupAdapter.getSelectors<RootState>(
    state => state[GAME_POPUP_KEY]
);

export const getInGamePopupByHostId = (state: RootState, hostId: number) => {
    return getInGamePopupSelectors.selectById(state, hostId) ?? initialInGamePopup;
};