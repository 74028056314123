import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { popupSliceActions } from '../../../modules/popup/slice';
import { CMDSpSetLimitRedTokenResult, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { ResultState, resultSliceActions } from '../../../modules/main/slice/result';
export class CMDSpResultHandler extends AbstractHandler {
    _command: CMDSpSetLimitRedTokenResult;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpSetLimitRedTokenResult;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        if (this._command.Result === this.SUCCESS_CODE) {
            const data = {} as ResultState;
            data.isSetChips = true;
            dispatch(resultSliceActions.updateData(data));
        } else {
            dispatch(popupSliceActions.open('baccarat.submit_chips_failed'));
            return;
        }
    }
}
