import { CMDBetRule } from "./CMDBetRule"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetRuleByGameType implements ICommand
{

	public GameType : number = 0;
	public BetRule : Array<CMDBetRule>;

	public constructor()
	{
		this.BetRule = new Array<CMDBetRule>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameType = ba.readInt53();
		let BetRuleSize:number = ba.readUint8();
		while (BetRuleSize-- > 0)
		{
			let BetRuleChild:CMDBetRule = new CMDBetRule();
			BetRuleChild.fromBytes(ba);
			this.BetRule.push(BetRuleChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameType);
		ba.writeUint8(this.BetRule.length);
		for (const BetRuleChild of this.BetRule)
		{
			let tempBuf = BetRuleChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetRuleByGameType();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameType(Q)='+this.GameType+' ';
		str += 'BetRule_Size(B)='+this.BetRule.length + ' ';
		str += 'BetRule(CMDBetRule)=[';
		for (let BetRuleChild in this.BetRule)
		{
			if (<number><unknown>BetRuleChild > 0) str += ', ';
			str += this.BetRule[BetRuleChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetRuleByGameType';
	}

}
