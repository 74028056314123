import { GameType as CommonGameType } from '@/models/games/enums/GameType';
import { getBetGame } from '@/modules/bet/game/slice';
import { betRoomSliceActions } from '@/modules/bet/room/slice';
import { tableBetSliceActions } from '@/modules/betTable/slice';
import { batch } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScStartGame, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScStartGameHandler extends AbstractHandler {
    _command: CMDScStartGame;
    _isAutoBet: boolean;

    constructor(command: ICommand, isAutoBet: boolean | undefined) {
        super();
        this._command = command as CMDScStartGame;
        this._isAutoBet = isAutoBet === undefined ? false : isAutoBet;
    }

    override handle(dispatch: Dispatch<Action>): void {
        const gameId = this._command.GameID;
        const state = store.getState();
        const host = getHostByGameId(state, gameId);
        if (host) {
            if (host.GameType === CommonGameType.Blackjack) {
                this._isAutoBet = false;
            }
            console.log(`hook::socket::run::${host.HostId}`, this._command);
            if (host.GameType === CommonGameType.Blackjack) {
                const betGame = getBetGame(state, gameId);
                if (betGame !== undefined) {
                    const bets = betGame.bets;
                    for (const b of bets) {
                        dispatch(betRoomSliceActions.bet(b));
                    }
                }
            }
            batch(() => {
                dispatch(
                    hostsSliceActions.updateGameState({
                        hostId: host.HostId,
                        state: GameState.Started,
                    })
                );
                if (!this._isAutoBet) {
                    dispatch(
                        tableBetSliceActions.clearPendingBets({
                            hostId: host.HostId,
                            gameId: this._command.GameID,
                            startGame: true,
                        })
                    );
                }
            });
        } else {
            console.warn('hook::socket::warning::cannot::find::host', gameId);
        }
    }
}
