export const overrideFunctionArrayAt = () => {
    // https://github.com/tc39/proposal-relative-indexing-method?tab=readme-ov-file#proposal-for-an-at-method-on-all-the-built-in-indexables
    const functionArrayAt = Array.prototype.at;
    if (typeof functionArrayAt !== 'function') {
        Array.prototype.at = function at(index: number) {
            console.log('override Array.prototype.at');
            let n = Math.trunc(index) || 0;
            if (n < 0) n += this.length;
            if (n < 0 || n >= this.length) return undefined;
            return this[n];
        };
    }
};
