import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDAndarBaharStatistics implements ICommand
{

	public TotalAndarCount : number = 0;
	public TotalBaharCount : number = 0;
	public Andar1Count : number = 0;
	public Bahar1Count : number = 0;
	public Andar2Count : number = 0;
	public Bahar2Count : number = 0;
	public Andar3Count : number = 0;
	public Bahar3Count : number = 0;
	public Andar4Count : number = 0;
	public Bahar4Count : number = 0;
	public Andar5Count : number = 0;
	public Bahar5Count : number = 0;
	public Andar6Count : number = 0;
	public Bahar6Count : number = 0;
	public Andar7Count : number = 0;
	public Bahar7Count : number = 0;
	public Andar8Count : number = 0;
	public Bahar8Count : number = 0;
	public Andar9Count : number = 0;
	public Bahar9Count : number = 0;
	public Andar10Count : number = 0;
	public Bahar10Count : number = 0;
	public AndarJCount : number = 0;
	public BaharJCount : number = 0;
	public AndarQCount : number = 0;
	public BaharQCount : number = 0;
	public AndarKCount : number = 0;
	public BaharKCount : number = 0;
	public PokerCount1To5 : number = 0;
	public PokerCount6To10 : number = 0;
	public PokerCount11To15 : number = 0;
	public PokerCount16To20 : number = 0;
	public PokerCount21To25 : number = 0;
	public PokerCount26To30 : number = 0;
	public PokerCount31To35 : number = 0;
	public PokerCount36To40 : number = 0;
	public PokerCount41To45 : number = 0;
	public PokerCount46To49 : number = 0;
	public PokerCountFirst3 : number = 0;
	public PokerCountFirstAndar : number = 0;
	public PokerCountFirstBahar : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.TotalAndarCount = ba.readUint8();
		this.TotalBaharCount = ba.readUint8();
		this.Andar1Count = ba.readUint8();
		this.Bahar1Count = ba.readUint8();
		this.Andar2Count = ba.readUint8();
		this.Bahar2Count = ba.readUint8();
		this.Andar3Count = ba.readUint8();
		this.Bahar3Count = ba.readUint8();
		this.Andar4Count = ba.readUint8();
		this.Bahar4Count = ba.readUint8();
		this.Andar5Count = ba.readUint8();
		this.Bahar5Count = ba.readUint8();
		this.Andar6Count = ba.readUint8();
		this.Bahar6Count = ba.readUint8();
		this.Andar7Count = ba.readUint8();
		this.Bahar7Count = ba.readUint8();
		this.Andar8Count = ba.readUint8();
		this.Bahar8Count = ba.readUint8();
		this.Andar9Count = ba.readUint8();
		this.Bahar9Count = ba.readUint8();
		this.Andar10Count = ba.readUint8();
		this.Bahar10Count = ba.readUint8();
		this.AndarJCount = ba.readUint8();
		this.BaharJCount = ba.readUint8();
		this.AndarQCount = ba.readUint8();
		this.BaharQCount = ba.readUint8();
		this.AndarKCount = ba.readUint8();
		this.BaharKCount = ba.readUint8();
		this.PokerCount1To5 = ba.readUint8();
		this.PokerCount6To10 = ba.readUint8();
		this.PokerCount11To15 = ba.readUint8();
		this.PokerCount16To20 = ba.readUint8();
		this.PokerCount21To25 = ba.readUint8();
		this.PokerCount26To30 = ba.readUint8();
		this.PokerCount31To35 = ba.readUint8();
		this.PokerCount36To40 = ba.readUint8();
		this.PokerCount41To45 = ba.readUint8();
		this.PokerCount46To49 = ba.readUint8();
		this.PokerCountFirst3 = ba.readUint8();
		this.PokerCountFirstAndar = ba.readUint8();
		this.PokerCountFirstBahar = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.TotalAndarCount);
		ba.writeUint8(this.TotalBaharCount);
		ba.writeUint8(this.Andar1Count);
		ba.writeUint8(this.Bahar1Count);
		ba.writeUint8(this.Andar2Count);
		ba.writeUint8(this.Bahar2Count);
		ba.writeUint8(this.Andar3Count);
		ba.writeUint8(this.Bahar3Count);
		ba.writeUint8(this.Andar4Count);
		ba.writeUint8(this.Bahar4Count);
		ba.writeUint8(this.Andar5Count);
		ba.writeUint8(this.Bahar5Count);
		ba.writeUint8(this.Andar6Count);
		ba.writeUint8(this.Bahar6Count);
		ba.writeUint8(this.Andar7Count);
		ba.writeUint8(this.Bahar7Count);
		ba.writeUint8(this.Andar8Count);
		ba.writeUint8(this.Bahar8Count);
		ba.writeUint8(this.Andar9Count);
		ba.writeUint8(this.Bahar9Count);
		ba.writeUint8(this.Andar10Count);
		ba.writeUint8(this.Bahar10Count);
		ba.writeUint8(this.AndarJCount);
		ba.writeUint8(this.BaharJCount);
		ba.writeUint8(this.AndarQCount);
		ba.writeUint8(this.BaharQCount);
		ba.writeUint8(this.AndarKCount);
		ba.writeUint8(this.BaharKCount);
		ba.writeUint8(this.PokerCount1To5);
		ba.writeUint8(this.PokerCount6To10);
		ba.writeUint8(this.PokerCount11To15);
		ba.writeUint8(this.PokerCount16To20);
		ba.writeUint8(this.PokerCount21To25);
		ba.writeUint8(this.PokerCount26To30);
		ba.writeUint8(this.PokerCount31To35);
		ba.writeUint8(this.PokerCount36To40);
		ba.writeUint8(this.PokerCount41To45);
		ba.writeUint8(this.PokerCount46To49);
		ba.writeUint8(this.PokerCountFirst3);
		ba.writeUint8(this.PokerCountFirstAndar);
		ba.writeUint8(this.PokerCountFirstBahar);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDAndarBaharStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'TotalAndarCount(B)='+this.TotalAndarCount+' ';
		str += 'TotalBaharCount(B)='+this.TotalBaharCount+' ';
		str += 'Andar1Count(B)='+this.Andar1Count+' ';
		str += 'Bahar1Count(B)='+this.Bahar1Count+' ';
		str += 'Andar2Count(B)='+this.Andar2Count+' ';
		str += 'Bahar2Count(B)='+this.Bahar2Count+' ';
		str += 'Andar3Count(B)='+this.Andar3Count+' ';
		str += 'Bahar3Count(B)='+this.Bahar3Count+' ';
		str += 'Andar4Count(B)='+this.Andar4Count+' ';
		str += 'Bahar4Count(B)='+this.Bahar4Count+' ';
		str += 'Andar5Count(B)='+this.Andar5Count+' ';
		str += 'Bahar5Count(B)='+this.Bahar5Count+' ';
		str += 'Andar6Count(B)='+this.Andar6Count+' ';
		str += 'Bahar6Count(B)='+this.Bahar6Count+' ';
		str += 'Andar7Count(B)='+this.Andar7Count+' ';
		str += 'Bahar7Count(B)='+this.Bahar7Count+' ';
		str += 'Andar8Count(B)='+this.Andar8Count+' ';
		str += 'Bahar8Count(B)='+this.Bahar8Count+' ';
		str += 'Andar9Count(B)='+this.Andar9Count+' ';
		str += 'Bahar9Count(B)='+this.Bahar9Count+' ';
		str += 'Andar10Count(B)='+this.Andar10Count+' ';
		str += 'Bahar10Count(B)='+this.Bahar10Count+' ';
		str += 'AndarJCount(B)='+this.AndarJCount+' ';
		str += 'BaharJCount(B)='+this.BaharJCount+' ';
		str += 'AndarQCount(B)='+this.AndarQCount+' ';
		str += 'BaharQCount(B)='+this.BaharQCount+' ';
		str += 'AndarKCount(B)='+this.AndarKCount+' ';
		str += 'BaharKCount(B)='+this.BaharKCount+' ';
		str += 'PokerCount1To5(B)='+this.PokerCount1To5+' ';
		str += 'PokerCount6To10(B)='+this.PokerCount6To10+' ';
		str += 'PokerCount11To15(B)='+this.PokerCount11To15+' ';
		str += 'PokerCount16To20(B)='+this.PokerCount16To20+' ';
		str += 'PokerCount21To25(B)='+this.PokerCount21To25+' ';
		str += 'PokerCount26To30(B)='+this.PokerCount26To30+' ';
		str += 'PokerCount31To35(B)='+this.PokerCount31To35+' ';
		str += 'PokerCount36To40(B)='+this.PokerCount36To40+' ';
		str += 'PokerCount41To45(B)='+this.PokerCount41To45+' ';
		str += 'PokerCount46To49(B)='+this.PokerCount46To49+' ';
		str += 'PokerCountFirst3(B)='+this.PokerCountFirst3+' ';
		str += 'PokerCountFirstAndar(B)='+this.PokerCountFirstAndar+' ';
		str += 'PokerCountFirstBahar(B)='+this.PokerCountFirstBahar+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'AndarBaharStatistics';
	}

}
