import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { betPlayersSliceActions } from '../../../modules/bet/players/slice';
import { CMDScRemoveSync, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDScRemoveSyncHandler extends AbstractHandler {
    _command: CMDScRemoveSync;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScRemoveSync;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const playerId = this._command.DBID;
        dispatch(
            betPlayersSliceActions.status({
                playerId,
                status: false,
            })
        );
    }
}
