import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsRequestLoginToken implements ICommand
{

	public GameCode : string = "";
	public Language : string = "";
	public GenFor : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameCode = ba.readString();
		this.Language = ba.readString();
		this.GenFor = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.GameCode);
		ba.writeString(this.Language);
		ba.writeUint8(this.GenFor);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsRequestLoginToken();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameCode(S)='+this.GameCode+' ';
		str += 'Language(S)='+this.Language+' ';
		str += 'GenFor(B)='+this.GenFor+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55028;
	}

	public getCmdName() : string
	{
		return 'PsRequestLoginToken';
	}

}
