import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetAndarBahar implements ICommand
{

	public Total : CMDTotalBet;
	public Andar : CMDBetSummary;
	public Bahar : CMDBetSummary;
	public Win1To5 : CMDBetSummary;
	public Win6To10 : CMDBetSummary;
	public Win11To15 : CMDBetSummary;
	public Win16To20 : CMDBetSummary;
	public Win21To25 : CMDBetSummary;
	public Win26To30 : CMDBetSummary;
	public Win31To35 : CMDBetSummary;
	public Win36To40 : CMDBetSummary;
	public Win41To45 : CMDBetSummary;
	public Win46To49 : CMDBetSummary;
	public WinFirst3 : CMDBetSummary;
	public WinFirstAndar : CMDBetSummary;
	public WinFirstBahar : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Andar = new CMDBetSummary();
		this.Andar.fromBytes(ba);
		this.Bahar = new CMDBetSummary();
		this.Bahar.fromBytes(ba);
		this.Win1To5 = new CMDBetSummary();
		this.Win1To5.fromBytes(ba);
		this.Win6To10 = new CMDBetSummary();
		this.Win6To10.fromBytes(ba);
		this.Win11To15 = new CMDBetSummary();
		this.Win11To15.fromBytes(ba);
		this.Win16To20 = new CMDBetSummary();
		this.Win16To20.fromBytes(ba);
		this.Win21To25 = new CMDBetSummary();
		this.Win21To25.fromBytes(ba);
		this.Win26To30 = new CMDBetSummary();
		this.Win26To30.fromBytes(ba);
		this.Win31To35 = new CMDBetSummary();
		this.Win31To35.fromBytes(ba);
		this.Win36To40 = new CMDBetSummary();
		this.Win36To40.fromBytes(ba);
		this.Win41To45 = new CMDBetSummary();
		this.Win41To45.fromBytes(ba);
		this.Win46To49 = new CMDBetSummary();
		this.Win46To49.fromBytes(ba);
		this.WinFirst3 = new CMDBetSummary();
		this.WinFirst3.fromBytes(ba);
		this.WinFirstAndar = new CMDBetSummary();
		this.WinFirstAndar.fromBytes(ba);
		this.WinFirstBahar = new CMDBetSummary();
		this.WinFirstBahar.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Andar.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Bahar.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Win1To5.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Win6To10.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Win11To15.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Win16To20.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Win21To25.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.Win26To30.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)
		let tempBuf9 = this.Win31To35.toBytes().toBuffer();
		ba.appendBuf(tempBuf9)
		let tempBuf10 = this.Win36To40.toBytes().toBuffer();
		ba.appendBuf(tempBuf10)
		let tempBuf11 = this.Win41To45.toBytes().toBuffer();
		ba.appendBuf(tempBuf11)
		let tempBuf12 = this.Win46To49.toBytes().toBuffer();
		ba.appendBuf(tempBuf12)
		let tempBuf13 = this.WinFirst3.toBytes().toBuffer();
		ba.appendBuf(tempBuf13)
		let tempBuf14 = this.WinFirstAndar.toBytes().toBuffer();
		ba.appendBuf(tempBuf14)
		let tempBuf15 = this.WinFirstBahar.toBytes().toBuffer();
		ba.appendBuf(tempBuf15)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetAndarBahar();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Andar(CMDBetSummary)='+this.Andar+' ';
		str += 'Bahar(CMDBetSummary)='+this.Bahar+' ';
		str += 'Win1To5(CMDBetSummary)='+this.Win1To5+' ';
		str += 'Win6To10(CMDBetSummary)='+this.Win6To10+' ';
		str += 'Win11To15(CMDBetSummary)='+this.Win11To15+' ';
		str += 'Win16To20(CMDBetSummary)='+this.Win16To20+' ';
		str += 'Win21To25(CMDBetSummary)='+this.Win21To25+' ';
		str += 'Win26To30(CMDBetSummary)='+this.Win26To30+' ';
		str += 'Win31To35(CMDBetSummary)='+this.Win31To35+' ';
		str += 'Win36To40(CMDBetSummary)='+this.Win36To40+' ';
		str += 'Win41To45(CMDBetSummary)='+this.Win41To45+' ';
		str += 'Win46To49(CMDBetSummary)='+this.Win46To49+' ';
		str += 'WinFirst3(CMDBetSummary)='+this.WinFirst3+' ';
		str += 'WinFirstAndar(CMDBetSummary)='+this.WinFirstAndar+' ';
		str += 'WinFirstBahar(CMDBetSummary)='+this.WinFirstBahar+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30759;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetAndarBahar';
	}

}
