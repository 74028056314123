import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsLottery implements ICommand
{

	public GameID : number = 0;
	public BetSource : number = 0;
	public BetRound : number = 0;
	public Format : Array<string>;

	public constructor()
	{
		this.Format = new Array<string>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.BetSource = ba.readUint32();
		this.BetRound = ba.readUint8();
		let FormatSize:number = ba.readUint8();
		while (FormatSize-- > 0)
		{
			let FormatChild:string = ba.readString();
			this.Format.push(FormatChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint32(this.BetSource);
		ba.writeUint8(this.BetRound);
		ba.writeUint8(this.Format.length);
		for (const FormatChild of this.Format)
		{
			ba.writeString(FormatChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsLottery();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'BetSource(D)='+this.BetSource+' ';
		str += 'BetRound(B)='+this.BetRound+' ';
		str += 'Format_size(B)='+this.Format.length + ' ';
		str += 'Format(S)=[';
		for (let FormatChild in this.Format)
		{
			if (<number><unknown>FormatChild > 0) str += ', ';
			str += this.Format[FormatChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 55024;
	}

	public getCmdName() : string
	{
		return 'PsLottery';
	}

}
