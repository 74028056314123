import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpChatroomAuth implements ICommand
{

	public UserJid : string = "";
	public ChatRoomAccessToken : string = "";
	public ChatRoomDomain : string = "";
	public XMPPPort : number = 0;
	public BOSHURL : string = "";
	public WSURL : string = "";
	public SendMsgCoolDownMilliSec : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.UserJid = ba.readString();
		this.ChatRoomAccessToken = ba.readString();
		this.ChatRoomDomain = ba.readString();
		this.XMPPPort = ba.readUint32();
		this.BOSHURL = ba.readString();
		this.WSURL = ba.readString();
		this.SendMsgCoolDownMilliSec = ba.readUint16();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.UserJid);
		ba.writeString(this.ChatRoomAccessToken);
		ba.writeString(this.ChatRoomDomain);
		ba.writeUint32(this.XMPPPort);
		ba.writeString(this.BOSHURL);
		ba.writeString(this.WSURL);
		ba.writeUint16(this.SendMsgCoolDownMilliSec);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpChatroomAuth();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'UserJid(S)='+this.UserJid+' ';
		str += 'ChatRoomAccessToken(S)='+this.ChatRoomAccessToken+' ';
		str += 'ChatRoomDomain(S)='+this.ChatRoomDomain+' ';
		str += 'XMPPPort(D)='+this.XMPPPort+' ';
		str += 'BOSHURL(S)='+this.BOSHURL+' ';
		str += 'WSURL(S)='+this.WSURL+' ';
		str += 'SendMsgCoolDownMilliSec(W)='+this.SendMsgCoolDownMilliSec+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 52002;
	}

	public getCmdName() : string
	{
		return 'SpChatroomAuth';
	}

}
