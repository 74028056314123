import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const BET_STATISTICS_KEY = 'app::report::statistics';
export type BetStatisticsState = {
    Turnover: number;
    ValidBetAmount: number;
    WinLossAmount: number;
    TotalAmount: number;
};
const initialBetStatisticsState = {} as BetStatisticsState;
const updateStatistics = (
    state: BetStatisticsState,
    action: PayloadAction<BetStatisticsState>
) => {
    state.Turnover = action.payload.Turnover;
    state.ValidBetAmount = action.payload.ValidBetAmount;
    state.WinLossAmount = action.payload.WinLossAmount;
    state.TotalAmount = action.payload.TotalAmount;
};
const reset = (state: BetStatisticsState) => {
    state.Turnover = initialBetStatisticsState.Turnover;
    state.ValidBetAmount = initialBetStatisticsState.ValidBetAmount;
    state.WinLossAmount = initialBetStatisticsState.WinLossAmount;
    state.TotalAmount = initialBetStatisticsState.TotalAmount;
};
const betStatisticsSlice = createSlice({
    name: BET_STATISTICS_KEY,
    initialState: initialBetStatisticsState,
    reducers: {
        updateStatistics,
        reset,
    },
});
export const betStatisticsSliceReducer = betStatisticsSlice.reducer;
export const betStatisticsSliceActions = betStatisticsSlice.actions;
