import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDTeenPatti2020Statistics implements ICommand
{

	public TieCount : number = 0;
	public PlayerAWinCount : number = 0;
	public PlayerBWinCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.TieCount = ba.readUint8();
		this.PlayerAWinCount = ba.readUint8();
		this.PlayerBWinCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.TieCount);
		ba.writeUint8(this.PlayerAWinCount);
		ba.writeUint8(this.PlayerBWinCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDTeenPatti2020Statistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'TieCount(B)='+this.TieCount+' ';
		str += 'PlayerAWinCount(B)='+this.PlayerAWinCount+' ';
		str += 'PlayerBWinCount(B)='+this.PlayerBWinCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'TeenPatti2020Statistics';
	}

}
