export enum Decision {
    Hit,
    Stand,
    Surrender,
    TakeWin,
    BBBankerPair,

    MaxNoBetDecision = 16,
    Insurance,

    OnlySeatPlayerDecision = 32,
    DoubleDown,
    Split,
    MaxDecision,
}
