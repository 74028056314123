import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Roadmap } from '../../../models/Roadmap';
import { BaccaratRoadmapRecord } from '../../../models/games/baccarat';
import { RootState } from '../../../store/store';

export const ROADMAP_KEY = 'app::roadmap';
export const roadmapAdapter = createEntityAdapter<
    Roadmap<BaccaratRoadmapRecord>,
    number
>({
    selectId: rm => rm.HostId,
    sortComparer: (a, b) => a.HostId - b.HostId,
});
const initialRoadmapState = roadmapAdapter.getInitialState();
const roadmapSlice = createSlice({
    name: ROADMAP_KEY,
    initialState: initialRoadmapState,
    reducers: {
        updateAll: roadmapAdapter.upsertMany,
        update: roadmapAdapter.upsertOne,
    },
});
export const roadmapSliceReducer = roadmapSlice.reducer;
export const roadmapSliceActions = roadmapSlice.actions;
export const getRoadmapSelectors = roadmapAdapter.getSelectors<RootState>(
    state => state[ROADMAP_KEY]
);
export const getRoadmap = getRoadmapSelectors.selectById;
