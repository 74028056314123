import { useContext, useEffect, useState, Dispatch } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import './index.scss';
import { PromotionSplash } from './PromotionSplash';
import { PromotionGacha } from './PromotionGacha';
import { PromotionRanking } from './PromotionRanking';
import { PromotionRecord } from './PromotionRecord';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotionToolState } from '../../../main/selector';
import { RootState } from '../../../../store/store';
import { AnyAction } from 'redux';
import { popupSliceActions } from '../../slice';
import { POPUP_CONFIRM_ACTION } from '../../../../models/Popup';
import { checkPromotionUrl } from '../PromotionUtil';
import i18n from '../../../locales/i18n';
import { getLocaleByCode } from '../../../../models/Locale';
import { CdnContext } from '../../../../contexts/CdnContext';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { useTranslation } from 'react-i18next';
import { PromotionDetail } from '../../../setting/components/promotionDetail';
import { PromotionButton } from './PromotionButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    getPromotionTool,
    PromotionTool,
    PromotionToolEnum,
} from '@/components/images/base64/PromotionTool';
import { useAspectRatio } from '@/hooks/useAspectRatio';
import { ScreenContext } from '@/contexts/ScreenContext';

export enum PromotionPage {
    Splash,
    Gacha,
    Ranking,
    Record,
}

export const PromotionDialog = () => {
    const { isLandscape } = useContext(ScreenContext);
    const [screenRatio, setScreenRatio] = useState<number>(1);
    const { innerWidth, innerHeight } = useAspectRatio();
    const { playButtonAudio } = useContext(AudioPlayContext);
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { t } = useTranslation();
    const {
        Popup,
        Page,
        IsLoading,
        Id,
        IsOpenDialog,
        MoreDetailUrl,
        Language: PromotionLanguage,
    } = useSelector((state: RootState) => getPromotionToolState(state));
    const [banner, setbanner] = useState(
        `url(${PromotionTool[PromotionToolEnum.event_splash_preload_bg].props.src})`
    );
    const [wrapper, setWrapper] = useState<JSX.Element>(<></>);

    const handleClose = () => {
        dispatch(promotionToolSliceActions.resetPage());
        dispatch(promotionToolSliceActions.isOpenDialog(false));
    };

    useEffect(() => {
        if (!isLandscape) {
            if (innerWidth != 540 || innerHeight != 960) {
                setScreenRatio(Math.min(innerWidth / 540, innerHeight / 960));
            } else {
                setScreenRatio(1);
            }
        } else {
            if (innerWidth != 1920 || innerHeight != 1080) {
                setScreenRatio(Math.min(innerWidth / 1920, innerHeight / 1080));
            } else {
                setScreenRatio(1);
            }
        }
    }, [isLandscape, innerWidth, innerHeight]);

    const { promotionCdn } = useContext(CdnContext);
    useEffect(() => {
        if (IsLoading)
            dispatch(
                popupSliceActions.triggerConfirm(POPUP_CONFIRM_ACTION.NONE)
            );
    }, [IsLoading]);

    useEffect(() => {
        if (Page == PromotionPage.Splash) {
            if (!promotionCdn || Id <= 0) return;
            const bannerURL = checkPromotionUrl(promotionCdn, Id, 'JSON');
            if (bannerURL) {
                const currentCode = i18n.language;
                const curLang = getLocaleByCode('long', currentCode).short;
                fetch(bannerURL).then(response => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            let langExist = Object.hasOwnProperty.call(
                                data,
                                curLang
                            );
                            const languageKey = langExist ? curLang : 'default';
                            if (langExist)
                                dispatch(
                                    promotionToolSliceActions.updateEventLanguage(
                                        curLang
                                    )
                                );
                            if (data[languageKey]['mobile'])
                                setbanner(
                                    `url('${promotionCdn + data[languageKey]['mobile']}')`
                                );
                        });
                    }
                });
            }
        } else
            setbanner(
                'linear-gradient(180deg, #062021 0%, #063734 54.5%, #0E4C4B 88%, #0F5150 100%)'
            );
    }, [Page, promotionCdn, Id]);

    useEffect(() => {
        setWrapper(renderCurPage(Page));
    }, [Page]);

    useEffect(() => {
        dispatch(promotionToolSliceActions.isOpenDialog(Popup > 0 && Id > 0));
    }, [Popup, Id]);

    const [openMoreDetail, setOpenMoreDetail] = useState<boolean>(false);
    const [moreDetailUrl, setMoreDetailUrl] = useState<string>('');
    const handleMoreDetail = () => {
        let url = MoreDetailUrl;
        let replacement = url.indexOf('{lang}') >= 0;
        if (replacement) url = url.replace('{lang}', `_${PromotionLanguage}`);
        else url += `_${PromotionLanguage}`;

        // if (os.name?.toLowerCase() === 'ios' && webView) {
        //     url += '&parenturl=' + encodeURIComponent(window.location.href);
        // }

        setMoreDetailUrl(url);
        setOpenMoreDetail(true);
    };

    const renderCurPage = (page: PromotionPage) => {
        switch (page) {
            case PromotionPage.Splash:
                return <PromotionSplash />;
            case PromotionPage.Gacha:
                return <PromotionGacha />;
            case PromotionPage.Ranking:
                return <PromotionRanking />;
            case PromotionPage.Record:
                return <PromotionRecord />;
            default:
                return <></>;
        }
    };
    return (
        <Dialog
            // container={() => document.getElementsByClassName('container')[0]}
            fullScreen
            open={IsOpenDialog}
            //onClose={}
            hideBackdrop={true}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    boxShadow: 'none',
                    // zIndex: theme.zIndex.drawer + 100,
                },
            }}
        >
            <DialogContent
                sx={{
                    // overflowY: 'visible',
                    padding: '0px',
                }}
            >
                <Box
                    className="event-container"
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        padding: '0',
                        margin: '0',
                        transform: `translate(-50%, -50%) scale(${screenRatio})`,
                    }}
                >
                    <PromotionButton
                        className="btn-close"
                        onClick={handleClose}
                        sx={{ zIndex: 1 }}
                    >
                        <CloseIcon
                            sx={{
                                width: isLandscape ? 40 : 30,
                                height: isLandscape ? 40 : 30,
                            }}
                        />
                    </PromotionButton>
                    <Box className="panel">
                        <Box
                            sx={{
                                position: 'absolute',
                                overflow: 'hidden',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: `translate(-50%, ${isLandscape ? '-12%' : '0'})`,
                                    background: banner,
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                    width: isLandscape ? 1300 : 500,
                                    height: isLandscape ? 936 : 825,
                                    zoom: isLandscape ? `${1300 / 900}` : `1`,
                                    // pointerEvents: 'none',
                                }}
                            />
                        </Box>
                        {wrapper}
                        {MoreDetailUrl !== '' && (
                            <PromotionButton
                                className="more-details"
                                onClick={handleMoreDetail}
                            >
                                <Box display={'flex'}>
                                    {getPromotionTool(
                                        PromotionToolEnum.event_ic_details
                                    )}
                                </Box>
                                {/* <BaseImage
                                        className="promotion_tool event_ic_details"
                                        scale={1}
                                    /> */}
                                <Typography
                                    sx={{
                                        marginLeft: '2px',
                                        color: '#DCDCDC',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {t('promotion_tool.details')}
                                </Typography>
                            </PromotionButton>
                        )}
                    </Box>
                </Box>
                {openMoreDetail && (
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0,0,0,0.85)',
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            top: '0px',
                        }}
                    >
                        <PromotionDetail
                            setIsOpen={setOpenMoreDetail}
                            url={moreDetailUrl}
                            playButtonAudio={playButtonAudio}
                        />
                    </Box>
                )}
                {IsLoading ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            backgroundColor: 'rgba(0, 0, 0, 0.45)',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size="6rem"
                            sx={{ color: '#98805d' }}
                        />
                    </Box>
                ) : (
                    <></>
                )}
            </DialogContent>
        </Dialog>
    );
};
