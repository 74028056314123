import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDRouletteStatistics implements ICommand
{

	public BigCount : number = 0;
	public SmallCount : number = 0;
	public EvenCount : number = 0;
	public OddCount : number = 0;
	public RedCount : number = 0;
	public BlackCount : number = 0;
	public Point0Count : number = 0;
	public Point1Count : number = 0;
	public Point2Count : number = 0;
	public Point3Count : number = 0;
	public Point4Count : number = 0;
	public Point5Count : number = 0;
	public Point6Count : number = 0;
	public Point7Count : number = 0;
	public Point8Count : number = 0;
	public Point9Count : number = 0;
	public Point10Count : number = 0;
	public Point11Count : number = 0;
	public Point12Count : number = 0;
	public Point13Count : number = 0;
	public Point14Count : number = 0;
	public Point15Count : number = 0;
	public Point16Count : number = 0;
	public Point17Count : number = 0;
	public Point18Count : number = 0;
	public Point19Count : number = 0;
	public Point20Count : number = 0;
	public Point21Count : number = 0;
	public Point22Count : number = 0;
	public Point23Count : number = 0;
	public Point24Count : number = 0;
	public Point25Count : number = 0;
	public Point26Count : number = 0;
	public Point27Count : number = 0;
	public Point28Count : number = 0;
	public Point29Count : number = 0;
	public Point30Count : number = 0;
	public Point31Count : number = 0;
	public Point32Count : number = 0;
	public Point33Count : number = 0;
	public Point34Count : number = 0;
	public Point35Count : number = 0;
	public Point36Count : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BigCount = ba.readUint8();
		this.SmallCount = ba.readUint8();
		this.EvenCount = ba.readUint8();
		this.OddCount = ba.readUint8();
		this.RedCount = ba.readUint8();
		this.BlackCount = ba.readUint8();
		this.Point0Count = ba.readUint8();
		this.Point1Count = ba.readUint8();
		this.Point2Count = ba.readUint8();
		this.Point3Count = ba.readUint8();
		this.Point4Count = ba.readUint8();
		this.Point5Count = ba.readUint8();
		this.Point6Count = ba.readUint8();
		this.Point7Count = ba.readUint8();
		this.Point8Count = ba.readUint8();
		this.Point9Count = ba.readUint8();
		this.Point10Count = ba.readUint8();
		this.Point11Count = ba.readUint8();
		this.Point12Count = ba.readUint8();
		this.Point13Count = ba.readUint8();
		this.Point14Count = ba.readUint8();
		this.Point15Count = ba.readUint8();
		this.Point16Count = ba.readUint8();
		this.Point17Count = ba.readUint8();
		this.Point18Count = ba.readUint8();
		this.Point19Count = ba.readUint8();
		this.Point20Count = ba.readUint8();
		this.Point21Count = ba.readUint8();
		this.Point22Count = ba.readUint8();
		this.Point23Count = ba.readUint8();
		this.Point24Count = ba.readUint8();
		this.Point25Count = ba.readUint8();
		this.Point26Count = ba.readUint8();
		this.Point27Count = ba.readUint8();
		this.Point28Count = ba.readUint8();
		this.Point29Count = ba.readUint8();
		this.Point30Count = ba.readUint8();
		this.Point31Count = ba.readUint8();
		this.Point32Count = ba.readUint8();
		this.Point33Count = ba.readUint8();
		this.Point34Count = ba.readUint8();
		this.Point35Count = ba.readUint8();
		this.Point36Count = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.BigCount);
		ba.writeUint8(this.SmallCount);
		ba.writeUint8(this.EvenCount);
		ba.writeUint8(this.OddCount);
		ba.writeUint8(this.RedCount);
		ba.writeUint8(this.BlackCount);
		ba.writeUint8(this.Point0Count);
		ba.writeUint8(this.Point1Count);
		ba.writeUint8(this.Point2Count);
		ba.writeUint8(this.Point3Count);
		ba.writeUint8(this.Point4Count);
		ba.writeUint8(this.Point5Count);
		ba.writeUint8(this.Point6Count);
		ba.writeUint8(this.Point7Count);
		ba.writeUint8(this.Point8Count);
		ba.writeUint8(this.Point9Count);
		ba.writeUint8(this.Point10Count);
		ba.writeUint8(this.Point11Count);
		ba.writeUint8(this.Point12Count);
		ba.writeUint8(this.Point13Count);
		ba.writeUint8(this.Point14Count);
		ba.writeUint8(this.Point15Count);
		ba.writeUint8(this.Point16Count);
		ba.writeUint8(this.Point17Count);
		ba.writeUint8(this.Point18Count);
		ba.writeUint8(this.Point19Count);
		ba.writeUint8(this.Point20Count);
		ba.writeUint8(this.Point21Count);
		ba.writeUint8(this.Point22Count);
		ba.writeUint8(this.Point23Count);
		ba.writeUint8(this.Point24Count);
		ba.writeUint8(this.Point25Count);
		ba.writeUint8(this.Point26Count);
		ba.writeUint8(this.Point27Count);
		ba.writeUint8(this.Point28Count);
		ba.writeUint8(this.Point29Count);
		ba.writeUint8(this.Point30Count);
		ba.writeUint8(this.Point31Count);
		ba.writeUint8(this.Point32Count);
		ba.writeUint8(this.Point33Count);
		ba.writeUint8(this.Point34Count);
		ba.writeUint8(this.Point35Count);
		ba.writeUint8(this.Point36Count);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDRouletteStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BigCount(B)='+this.BigCount+' ';
		str += 'SmallCount(B)='+this.SmallCount+' ';
		str += 'EvenCount(B)='+this.EvenCount+' ';
		str += 'OddCount(B)='+this.OddCount+' ';
		str += 'RedCount(B)='+this.RedCount+' ';
		str += 'BlackCount(B)='+this.BlackCount+' ';
		str += 'Point0Count(B)='+this.Point0Count+' ';
		str += 'Point1Count(B)='+this.Point1Count+' ';
		str += 'Point2Count(B)='+this.Point2Count+' ';
		str += 'Point3Count(B)='+this.Point3Count+' ';
		str += 'Point4Count(B)='+this.Point4Count+' ';
		str += 'Point5Count(B)='+this.Point5Count+' ';
		str += 'Point6Count(B)='+this.Point6Count+' ';
		str += 'Point7Count(B)='+this.Point7Count+' ';
		str += 'Point8Count(B)='+this.Point8Count+' ';
		str += 'Point9Count(B)='+this.Point9Count+' ';
		str += 'Point10Count(B)='+this.Point10Count+' ';
		str += 'Point11Count(B)='+this.Point11Count+' ';
		str += 'Point12Count(B)='+this.Point12Count+' ';
		str += 'Point13Count(B)='+this.Point13Count+' ';
		str += 'Point14Count(B)='+this.Point14Count+' ';
		str += 'Point15Count(B)='+this.Point15Count+' ';
		str += 'Point16Count(B)='+this.Point16Count+' ';
		str += 'Point17Count(B)='+this.Point17Count+' ';
		str += 'Point18Count(B)='+this.Point18Count+' ';
		str += 'Point19Count(B)='+this.Point19Count+' ';
		str += 'Point20Count(B)='+this.Point20Count+' ';
		str += 'Point21Count(B)='+this.Point21Count+' ';
		str += 'Point22Count(B)='+this.Point22Count+' ';
		str += 'Point23Count(B)='+this.Point23Count+' ';
		str += 'Point24Count(B)='+this.Point24Count+' ';
		str += 'Point25Count(B)='+this.Point25Count+' ';
		str += 'Point26Count(B)='+this.Point26Count+' ';
		str += 'Point27Count(B)='+this.Point27Count+' ';
		str += 'Point28Count(B)='+this.Point28Count+' ';
		str += 'Point29Count(B)='+this.Point29Count+' ';
		str += 'Point30Count(B)='+this.Point30Count+' ';
		str += 'Point31Count(B)='+this.Point31Count+' ';
		str += 'Point32Count(B)='+this.Point32Count+' ';
		str += 'Point33Count(B)='+this.Point33Count+' ';
		str += 'Point34Count(B)='+this.Point34Count+' ';
		str += 'Point35Count(B)='+this.Point35Count+' ';
		str += 'Point36Count(B)='+this.Point36Count+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'RouletteStatistics';
	}

}
