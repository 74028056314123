import { AutoTypography } from '@/components/common/autoText';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { LimitRed } from '../modules/main/slice/limitRed';

type AmountTextOwnProps = {
    amount: number;
    withColor?: boolean;
    withSign?: boolean;
    disableAuto?: boolean;
};
type AmountTextProps = AmountTextOwnProps & TypographyProps;
export const AmountText = (props: AmountTextProps) => {
    const {
        amount,
        withColor = true,
        withSign = false,
        disableAuto = false,
        fontSize: rawFontSize,
    } = props;
    const [color, setColor] = useState('white');
    const [display, setDisplay] = useState('');
    useEffect(() => {
        let c = 'white';
        let s = '';
        if (withColor) {
            if (amount > 0)
                c = '#45B46A'; //green
            else if (amount < 0)
                c = '#FF4F4F'; //red
            else if (amount === 0) c = 'yellow';
        } else {
            if (amount < 0)
                c = '#FF4F4F'; //red
            else if (amount === 0) c = 'yellow';
        }

        if (withSign) {
            if (amount >= 0) s = '+';
        }
        setColor(c);
        const displayAmount = amount?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        setDisplay(s + displayAmount);
    }, [withColor, withSign, amount]);
    return disableAuto ? (
        <Typography color={color} fontSize={rawFontSize} {...props}>
            {display}
        </Typography>
    ) : (
        <AutoTypography color={color} {...props}>
            {display}
        </AutoTypography>
    );
};

export const limitRedFormatter = (lr: LimitRed, CurrencyName: string) => {
    return `${shortLimitRed(lr.MinBet / 100, CurrencyName)} - ${shortLimitRed(
        lr.LimitRed / 100,
        CurrencyName
    )}`;
};
// `${(lr.MinBet / 100).toLocaleString('en-US', {
//     maximumFractionDigits: 2,
// })} - ${(lr.LimitRed / 100).toLocaleString('en-US', {
//     maximumFractionDigits: 2,
// })}`;

export const shortLimitRed = (n: number, CurrencyName: string) => {
    if (n >= 1000 && n % 1000 > 0) {
        return n.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        });
    }

    if (CurrencyName.toUpperCase() != 'KRW' && n < 10000) {
        return n.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        });
    }

    if (n >= 1000 && n <= 900 * 1000) {
        return n / 1000 + 'K';
    }

    if (n >= 1000 * 1000) {
        return n / 1000000 + 'M';
    }

    return n.toString();
};

export const shortAmountToString = (n: number, digit: number = 2): string => {
    if (n >= 1000 * 1000) {
        return (
            Math.round(n / (1000000 / Math.pow(10, digit))) /
                Math.pow(10, digit) +
            'M'
        );
    } else if (n >= 1000) {
        return (
            Math.round(n / (1000 / Math.pow(10, digit))) / Math.pow(10, digit) +
            'K'
        );
    }

    return n.toString();
};
export const mask = (s: string) => {
    // remove the lobby name
    const pattern = /@.*/;
    const tmp = s.replace(pattern, '');
    // get last 3 char
    return '***' + tmp.slice(-3);
};

export const vhToPx = (vh: number) => {
    const windowHeight = window.innerHeight;
    return (vh / 100) * windowHeight;
};

export const vwToPx = (vw: number) => {
    const windowWidth = window.innerWidth;
    return (vw / 100) * windowWidth;
};
export const percentageFormatter = (v?: number) => {
    if (v === undefined) return 0;
    else if (isNaN(v)) return 0;
    else return Math.round(v);
};
