export enum GameError {
    NoGame = 1,
    NoDealer,
    LoginFail,
    UnknownPoker,
    BetTimeOut,
    NotEnoughMoney,
    ManagerServerDisconnected,
    NoMoneyServer,
    DBDisconnected,
    SqlError,
    AccountMaxBalance,
    OutOfLimitRed,
    ErrorRuleIndex,
    BelowMinBet,
    NotYetStarted,
    DuplicateLogin,
    NoLimitRedRule,
    BetError,
    PlayerNotFound,
    UnknownError,
    NoGameStandBy,
    ShoeCountZero,
    InMaintenance,
    NoProxyFound,
    LobbyMaxConnection,
    TokenError,
    IPBlocked,
    Kicked,
    BetBan,
    NoGameID,
    NoSelectRule,
    InActive,
    PlayerNotAChildOrGrandChild,
    WrongNetwork,
    ErrorCreateTable,
    TableHasNotOpened,
    NoHost,
    RollBackError,
    InsertError,
    UpdateError,
    ServerBusy,
    AlreadyPayback,
    DBDataMismatch,
    LastRound,
    DuplicateName,
    RoomFull,
    AccountBan,
    GameCodeNotFound,
    SlotHasCard,
    OutOfTableLimitRed,
    NoServerAvaliable,
    PokerCountError,
    NoSlot,
    SlotActionInvalid,
    SlotActionNotFound,
    SlotActionProcessError,
    NewGameError,
    TokenExpire,
    OperatorNotFound,
    PasswordIncorrect,
    PlayerBetHostProcessing,
    GameTypeMismatch,
    SignatureUnMatch,
    DecryptionError,
    ParentNotFound,
    LobbyNotExisted,
    NotDirectParent,
    AmountZero,
    DuplicateOrderID,
    UsernameInCorrectFormat,
    CallbackURLError,
    CheckKeyError,
    CurrencyNotExisted,
    LimitRedNotMatch,
    CreateError,
    DataParseError,
    TimeRangeError,
    OrderIDFormatError,
    KickFail,
    ConnectionError,
    CallTooFrequent,
    NotCashLobby,
    BallOutRange,
    NotInIdle,
    TableNotAllowBet,
    ResultAlreadyConfirm,
    ExceptionThrow,
    BallNumberAlreadyExisted,
    LotteryTicketError,
    BallNumberNotMatch,
    DenominationError,
    SlotSessionProcessing,
    CaptchaError,
    EGameProcessingWaitAction,
    EGameProcessingWaitActionDone,
    NoSuitableProxy,
    GameProhibited,
    SeatError,
    GamePlatformError,
    DuplicateLoginAccountBan,
    SideBetForbidden,
    BetCountError,
    BetRoundError,
    LotteryBlocked,
    MaxSlot,
    SlotInvalidParam,
    SlotParamNotFound,
    QueryTypeError,
    DecimalPointerGreaterThan2,
    UnknownPacket,
    IncorrectKey,
    LogError,
    DeleteError,
    CWTimeout,
    RechargeError,
    PlayerNotInTable,
    NotAllowRepeatBet,
    DecisionError,
    KeepSeatTimeout,
    ErrorStep,
    OutOfChoose,
    NotBetBehind,
    HitError,
    SlotInMaintenance,
    SeatPlayerDecisionStepMismatch,
    NotYetFinished,
    UnderMin,
    OverMax,
    ConfigError,
    NoOrder,
    InvalidInput,
    InvalidTotalWithdraw,
    OldTokenStillActivate,
    ThirdPartyError,
    Deprecated,
    NegativeBalance,
    NegativeBetPending,
    CWPlayerNotFound,
    CWInvalidCurrency,
    CWInvalidAmount,
    CWLockedAccount,
    CWInsufficientBalance,
    CWGeneralError,
    CWDecryptionError,
    CWSystemError,
    AlreadyMatch,
    NoGroup,
    DllMissing,
    GameServerDisconnected,
    CWSessionExpired,
    Playing,
    InCountDown,
    ExecutingAccNotFound,
    SessionNotInSameHall,
    SessionTooMuch,
    Reject,
    BetNotFound,
    RequestProcessing,
    CannotBetOpposite,
    InvalidBetAmount,
    RequestFormatError,
    AlreadyCreated,
    OnMonopolyMode,
    NoAnchor,
    AccountBanByPasswordIncorrect,
    AccountMaxWin,
    NoParentSetting,
    SettingValueBiggerThanParent,
    ChatRoomNotFound,
    ChatRoomError,
    UpdateRoadError,
    TipsNotAllowedInHost,
    PlayerNotAllowToGiveTips,
    AutoBetNotFound,
    JSONError,
    SettingValueSmallerThanChild,
    SizeError,
    GiftNotFound,
    SameIDWithDifferentDetails,
    AccountMaxLoss,
    NotInCorrectState,
    InvalidPrize,
    PrizeProcessingError,
    DuplicatedEventPlayer,
    EventPlayerNotFound,
    APINotExisted,
    UsernameBeUsed,
    CurrencyNotAllowedInClientCountry,
    WaitingSignal,
    TrialPlayerLimitFunction,
    ResumeError,
    CWServerSendFail,
    CWTransactionNotFound,
    CWStillInTry,
    HTTPCodeNot200,
    InvalidCardHash,
    GameSessionExpire,
    TransformError,
    DuplicatePoker,

    //no use
    // LoadTokenNotYetStart = 1000,
    // LoadTokenError = 1001,
    // ERROR_DESCRYPTION_ERROR = 1003,
    // ERROR_EMPTY_PARAMETER,
    // ERROR_WRONG_PARAMTERS,
    // ERROR_MEMBERSHIP_CHECK_FAILED,
    // ERROR_ACCOUNT_NOT_FOUND,
    // ERROR_ACCOUNT_IN_CASH_NETWORK,
    // ERROR_ACCOUNT_ERROR,
    // ERROR_MACCOUNT_NOT_FOUND,
    // ERROR_AUTH_ERROR,
    // ERROR_AUTH_RESPONSE_EMPTY,
    // ERROR_AUTH_RESPONSE_ERROR,
    // ERROR_AUTH_RESPONSE_INCORRECT,
    // ERROR_AUTH_USERNAME_PASSWORD_ERROR,
    // ERROR_AUTH_NETWORK_ERROR,
    // ERROR_AUTH_INTERNAL_ERROR,
    // ERROR_AUTH_API_CLOSED,
    // ERROR_AUTH_URL_EMPTY,
    // ERROR_AUTH_XML_ERROR,
    // ERROR_ACCOUNT_WRONGPW_LOCK = 1023,
    // ConnectContorlFailed = 2000,

    ConnectLobbyFailed = 2001,
    LoginTimeout = 2003,
    HostListEmpty = 2004,
    CREDIT_WITH_AT = 2005,
    CASH_WITHOUT_AT = 2006,

    //CR200328
    REENTER_TABLE = 2008,
    RECONNECT_TO_GAME_CLIENT = 2009,
    SEAT_TABLE_TIMEOUT = 2010,

    NO_AVAILABLE_VIDEO_CHANNEL = 2011,
    SEND_BACK_LOBBY = 2012,

    PLAYER_BE_DISABLED = 2013,
    LOGOUTTED = 2014,

    LOBBY_NOT_ALLOW = 9999,
    SeatFailStand = 10000,
}
