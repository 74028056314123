import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { updateRemainTime } from '../../../utils/commonFunc';
import { BaseInformation } from '../../games/BaseInformation';
import { BaseResult } from '../../games/BaseResult';
import { BetPlayerAmount } from '../../host/BetAmount';
import { ICommand } from '../live';
import { CMDBaseInformation } from '../live/CMDBaseInformation';
import { CMDBaseResult } from '../live/CMDBaseResult';
import { CMDBetSummary } from '../live/CMDBetSummary';

export type SyncCMDAction = {
    type: string;
    hostId: number;
    cmd: unknown;
};
export abstract class AbstractHandler {
    _command: ICommand;
    SUCCESS_CODE = 0xff;
    abstract handle(dispatch: Dispatch<AnyAction>): void;
    handle2(dispatch: Dispatch<AnyAction>): void {
        if (this.commandDelay2() > 0) {
            console.log(dispatch);
        }
    }
    commandDelay() {
        return 0;
    }
    commandDelay2() {
        return 0;
    }
    delayKey() {
        return '';
    }
    forceRun() {
        return '';
    }
    syncCMD() {
        return {} as SyncCMDAction;
    }
    convertResult(result: unknown): unknown {
        return result;
    }
    convertInformation(information: CMDBaseInformation): BaseInformation {
        const newInformation = {} as BaseInformation;
        newInformation.AllowSideBet = information.AllowSideBet === 1;
        newInformation.CountDown = information.CountDown;
        newInformation.HallId = information.HallID;
        newInformation.HostId = information.HostID;
        newInformation.RemainTime = updateRemainTime(information.RemainTime);
        newInformation.Rest = information.Rest;
        newInformation.Seat = information.Seat;
        newInformation.TableLimit = information.TableLimit;
        return newInformation;
    }
    convertBaseResult(baseResult: CMDBaseResult): BaseResult {
        const newResult = {} as BaseResult;
        newResult.FResult = baseResult.FResult.toString();
        newResult.GameCount = baseResult.GameCount;
        newResult.GameID = baseResult.GameID;
        newResult.Result = baseResult.Result;
        return newResult;
    }
    convertBetAmount(bet: CMDBetSummary): BetPlayerAmount {
        return {
            Amount: bet.Amount ?? 0,
            BetPlayerCount: bet.BetPlayerCount ?? 0,
        };
    }
}
