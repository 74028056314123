import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetDragontiger implements ICommand
{

	public Total : CMDTotalBet;
	public Dragon : CMDBetSummary;
	public Tiger : CMDBetSummary;
	public Tie : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Dragon = new CMDBetSummary();
		this.Dragon.fromBytes(ba);
		this.Tiger = new CMDBetSummary();
		this.Tiger.fromBytes(ba);
		this.Tie = new CMDBetSummary();
		this.Tie.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Dragon.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Tiger.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Tie.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetDragontiger();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Dragon(CMDBetSummary)='+this.Dragon+' ';
		str += 'Tiger(CMDBetSummary)='+this.Tiger+' ';
		str += 'Tie(CMDBetSummary)='+this.Tie+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30752;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetDragontiger';
	}

}
