import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ProgressLoadingState,
    isAssetDone,
    isCommandDone,
    isConfigDone,
} from '../models/games/Progress';
import {
    getLoadingProgressState,
    getResultProgressState,
} from '../modules/progress/selector';
import { progressStatusActions } from '../modules/progress/slice/status';
import { progressTimeActions } from '../modules/progress/slice/time';
const getHexCount = (state: ProgressLoadingState) => {
    let count = 0;
    const array = Object.entries(state);
    for (const kv of array) {
        if (kv[1]) {
            count += Number(kv[0]);
        }
    }
    return count.toString(16);
};
export const useProgress = () => {
    const dispatch = useDispatch();
    const [percentage, setPercentage] = useState(0);
    const loadingState = useSelector(getLoadingProgressState);
    const resultState = useSelector(getResultProgressState);
    const [loadingCount, setLoadingCount] = useState('');
    const [resultCount, setResultCount] = useState('');

    const [isMarkedCommand, setIsMarkedCommand] = useState(false);
    const [isMarkedConfig, setIsMarkedConfig] = useState(false);
    const [isMarkedAsset, setIsMarkedAsset] = useState(false);

    useEffect(() => {
        let p = 0;
        const loadingProgress = new Map<string, boolean>();
        const resultProgress = new Map<string, boolean>();
        Object.entries(loadingState).map(v => {
            loadingProgress.set(`loading.${v[0]}`, v[1]);
        });
        Object.entries(resultState).map(v => {
            resultProgress.set(`result.${v[0]}`, v[1]);
        });
        const arr = Array.from(resultProgress.values());
        const chunk = 100 / arr.length;
        arr.map(b => {
            if (b) p += chunk;
        });
        setPercentage(p);
        console.log('app::hook::progress', resultState);
    }, [loadingState, resultState]);
    useEffect(() => {
        const count = getHexCount(loadingState);
        setLoadingCount(count);
    }, [loadingState]);
    useEffect(() => {
        if (isCommandDone(resultState) && !isMarkedCommand) {
            dispatch(progressTimeActions.mark('command'));
            setIsMarkedCommand(true);
        }
        if (isConfigDone(resultState) && !isMarkedConfig) {
            dispatch(progressTimeActions.mark('config'));
            setIsMarkedConfig(true);
        }
        if (isAssetDone(resultState) && !isMarkedAsset) {
            dispatch(progressTimeActions.mark('asset'));
            setIsMarkedAsset(true);
        }
        const count = getHexCount(resultState);
        setResultCount(count);
    }, [resultState]);
    useEffect(() => {
        if (percentage === 100) {
            dispatch(progressStatusActions.updateStatus(true));
        }
    }, [percentage]);
    return { percentage: Math.min(percentage, 100), loadingCount, resultCount };
};
