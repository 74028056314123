import { Bet } from '@/models/host/BetAmount';
import { BetType as BetTypeEnum } from '../../../models/games/andarBahar/BetType';
import { getBetTypeGroupTotal } from '../baccarat/utils';

/**
 * Bet limit sharing
 */
const AndarBetType = [BetTypeEnum.ABBAndarWin, BetTypeEnum.ABBNCAndarWin];
const BaharBetType = [BetTypeEnum.ABBBaharWin, BetTypeEnum.ABBNCBaharWin];
const ShareLimitType = [
    AndarBetType,
    BaharBetType,
    [BetTypeEnum.ABB1To5, BetTypeEnum.ABBNC1To5],
    [BetTypeEnum.ABB6To10, BetTypeEnum.ABBNC6To10],
    [BetTypeEnum.ABB11To15, BetTypeEnum.ABBNC11To15],
    [BetTypeEnum.ABB16To20, BetTypeEnum.ABBNC16To20],
    [BetTypeEnum.ABB21To25, BetTypeEnum.ABBNC21To25],
    [BetTypeEnum.ABB26To30, BetTypeEnum.ABBNC26To30],
    [BetTypeEnum.ABB31To35, BetTypeEnum.ABBNC31To35],
    [BetTypeEnum.ABB36To40, BetTypeEnum.ABBNC36To40],
    [BetTypeEnum.ABB41To45, BetTypeEnum.ABBNC41To45],
    [BetTypeEnum.ABB46To49, BetTypeEnum.ABBNC46To49],
];

export const getShareLimitBetTypes = (betType: number) => {
    for (let i = 0; i < ShareLimitType.length; i++) {
        if (ShareLimitType[i].indexOf(betType) >= 0) {
            return ShareLimitType[i];
        }
    }
    return [betType];
};

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    let Opposite = [];
    switch (betType) {
        case BetTypeEnum.ABBAndarWin:
        case BetTypeEnum.ABBNCAndarWin:
            Opposite.push(BetTypeEnum.ABBBaharWin);
            Opposite.push(BetTypeEnum.ABBNCBaharWin);
            break;
        case BetTypeEnum.ABBBaharWin:
        case BetTypeEnum.ABBNCBaharWin:
            Opposite.push(BetTypeEnum.ABBAndarWin);
            Opposite.push(BetTypeEnum.ABBNCAndarWin);
            break;
    }
    if (betsSummary) {
        for (let i = 0; i < Opposite.length; i++) {
            if (betsSummary[Opposite[i]] && betsSummary[Opposite[i]] > 0) {
                return true;
            }
        }
    }
    return false;
};

export const getAndarBaharCurrentTotal = (
    betType: number,
    BetsSummary: Array<number>,
    ConfirmedBets: Array<number>
) => {
    if (AndarBetType.indexOf(betType) >= 0) {
        return (
            getBetTypeGroupTotal(AndarBetType, BetsSummary, ConfirmedBets) -
            getBetTypeGroupTotal(BaharBetType, BetsSummary, ConfirmedBets)
        );
    } else if (BaharBetType.indexOf(betType) >= 0) {
        return (
            getBetTypeGroupTotal(BaharBetType, BetsSummary, ConfirmedBets) -
            getBetTypeGroupTotal(AndarBetType, BetsSummary, ConfirmedBets)
        );
    } else {
        const matchBetTypes = getShareLimitBetTypes(betType);
        return getBetTypeGroupTotal(matchBetTypes, BetsSummary, ConfirmedBets);
    }
};

export const getAndarBaharHedgeBettingBets = (bets: Array<Bet>) => {
    const newBets = new Array<Bet>();
    let andarTotal = 0;
    let baharTotal = 0;
    const latestGameId = bets.at(-1)?.GameId ?? 0;
    for (const bet of bets) {
        switch (bet.Type) {
            case BetTypeEnum.ABBAndarWin:
            case BetTypeEnum.ABBNCAndarWin:
                andarTotal += bet.Amount;
                break;
            case BetTypeEnum.ABBBaharWin:
            case BetTypeEnum.ABBNCBaharWin:
                baharTotal += bet.Amount;
                break;
            default:
                newBets.push(bet);
                break;
        }
    }
    const diff = andarTotal - baharTotal;
    if (diff !== 0) {
        const betType =
            diff > 0 ? BetTypeEnum.ABBAndarWin : BetTypeEnum.ABBBaharWin;
        const betAmount = Math.abs(diff);
        const newBet: Bet = {
            GameId: latestGameId,
            Type: betType,
            Amount: betAmount,
        };
        newBets.push(newBet);
    }

    return newBets;
};
