import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBaseInformation implements ICommand
{

	public HallID : number = 0;
	public HostID : number = 0;
	public CountDown : number = 0;
	public RemainTime : number = 0;
	public Rest : number = 0;
	public AllowSideBet : number = 0;
	public Seat : number = 0;
	public TableLimit : number = 0;
	public LastRound : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HallID = ba.readUint32();
		this.HostID = ba.readUint16();
		this.CountDown = ba.readUint32();
		this.RemainTime = ba.readUint32();
		this.Rest = ba.readUint8();
		this.AllowSideBet = ba.readUint8();
		this.Seat = ba.readUint8();
		this.TableLimit = ba.readInt53();
		this.LastRound = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.HallID);
		ba.writeUint16(this.HostID);
		ba.writeUint32(this.CountDown);
		ba.writeUint32(this.RemainTime);
		ba.writeUint8(this.Rest);
		ba.writeUint8(this.AllowSideBet);
		ba.writeUint8(this.Seat);
		ba.writeUint53(this.TableLimit);
		ba.writeUint8(this.LastRound);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBaseInformation();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HallID(D)='+this.HallID+' ';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'CountDown(D)='+this.CountDown+' ';
		str += 'RemainTime(D)='+this.RemainTime+' ';
		str += 'Rest(B)='+this.Rest+' ';
		str += 'AllowSideBet(B)='+this.AllowSideBet+' ';
		str += 'Seat(B)='+this.Seat+' ';
		str += 'TableLimit(Q)='+this.TableLimit+' ';
		str += 'LastRound(B)='+this.LastRound+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BaseInformation';
	}

}
