import { MAX_PLAYER } from './Constant';

export enum Init {
    WaitPlayer1FirstCard = 0,
    WaitBankerFirstCard = WaitPlayer1FirstCard + MAX_PLAYER,
    WaitPlayer1SecondCard = WaitBankerFirstCard + 1,
    WaitSeatPlayerInsurance = WaitPlayer1SecondCard + MAX_PLAYER,
    LastInitState = WaitSeatPlayerInsurance + 1,
}
export const isWaitPlayerFirstCard = (n: number) =>
    n >= Init.WaitPlayer1FirstCard && n <= Init.WaitBankerFirstCard;
export const isWaitPlayerSecondCard = (n: number) =>
    n >= Init.WaitPlayer1SecondCard && n <= Init.WaitSeatPlayerInsurance;
export const getWaitPlayerFirstCard = (sn: number) =>
    Init.WaitPlayer1FirstCard + sn - 1;
export const getWaitPlayerSecondCard = (sn: number) =>
    Init.WaitPlayer1SecondCard + sn - 1;
export const getSeatNo = (s: number) => {
    let sn = 0;
    if (isWaitPlayerFirstCard(s)) sn = s - Init.WaitPlayer1FirstCard + 1;
    else if (isWaitPlayerSecondCard(s)) sn = s - Init.WaitPlayer1SecondCard + 1;
    return sn;
};
