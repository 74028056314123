import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDRedeemRecord implements ICommand
{

	public RedeemID : number = 0;
	public RedeemTime : number = 0;
	public PrizeRank : number = 0;
	public ResultAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.RedeemID = ba.readInt53();
		this.RedeemTime = ba.readInt53();
		this.PrizeRank = ba.readUint8();
		this.ResultAmount = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.RedeemID);
		ba.writeUint53(this.RedeemTime);
		ba.writeUint8(this.PrizeRank);
		ba.writeUint53(this.ResultAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDRedeemRecord();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'RedeemID(Q)='+this.RedeemID+' ';
		str += 'RedeemTime(Q)='+this.RedeemTime+' ';
		str += 'PrizeRank(B)='+this.PrizeRank+' ';
		str += 'ResultAmount(Q)='+this.ResultAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'RedeemRecord';
	}

}
