import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpChatroomInfo implements ICommand
{

	public ChatRoomJid : string = "";
	public UserJid : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ChatRoomJid = ba.readString();
		this.UserJid = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.ChatRoomJid);
		ba.writeString(this.UserJid);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpChatroomInfo();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ChatRoomJid(S)='+this.ChatRoomJid+' ';
		str += 'UserJid(S)='+this.UserJid+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 52003;
	}

	public getCmdName() : string
	{
		return 'SpChatroomInfo';
	}

}
