export type LobbyRoom = {
    HostId: number;
    Info: LobbyRoomInfo;
};
export type LobbyRoomInfo = {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    6: number;
    7: number;
};
export const getEmptyLobbyRoomInfo = (hostId: number): LobbyRoom => ({
    HostId: hostId,
    Info: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
    },
});
