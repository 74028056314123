// export enum Hall {
//     Euro = 1 << 8,
//     Sexy = 1 << 10,
//     Diamond = 1 << 11,
//     Ruby = 1 << 12,
// }
export enum Hall {
    Asia,
    Euro,
    Sexy, // group "c"
    Diamond, // group "a", "d",
    Ruby, // "group": "m"
}
