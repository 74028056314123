export enum BetType {
    SBBSmall,
    SBBBig,
    SBBOdd,
    SBBEven,
    
    SBBTripleArmyOne, //double 1
    SBBTripleArmyTwo,
    SBBTripleArmyThree,
    SBBTripleArmyFour,
    SBBTripleArmyFive,
    SBBTripleArmySix,
    
    SBBTripleOne,   // index 12
    SBBTripleTwo,
    SBBTripleThree,
    SBBTripleFour,
    SBBTripleFive,
    SBBTripleSix,
    
    SBBAllTriple,
    
    SBBPointFour,
    SBBPointFive,
    SBBPointSix,
    SBBPointSeven,
    SBBPointEight,
    SBBPointNine,
    SBBPointTen,
    SBBPointEleven,
    SBBPointTwelve,
    SBBPointThirteen,
    SBBPointForteen,
    SBBPointFiveteen,
    SBBPointSixteen,
    SBBPointSeventeen,
    
    SBBLongOneTwo,
    SBBLongOneThree,
    SBBLongOneFour,
    SBBLongOneFive,
    SBBLongOneSix,
    SBBLongTwoThree,
    SBBLongTwoFour,
    SBBLongTwoFive,
    SBBLongTwoSix,
    SBBLongThreeFour,
    SBBLongThreeFive,
    SBBLongThreeSix,
    SBBLongFourFive,
    SBBLongFourSix,
    SBBLongFiveSix,
    
    SBBShortOne,
    SBBShortTwo,
    SBBShortThree,
    SBBShortFour,
    SBBShortFive,
    SBBShortSix,
    
    SBAllDicesOdd,
    SBTwoDicesOddOneDiceEven,
    SBTwoDicesEvenOneDiceOdd,
    SBAllDicesEven,
    
    SB_1_2_3_4,
    SB_2_3_4_5,
    SB_2_3_5_6,
    SB_3_4_5_6,
    
    SB_112,
    SB_113,
    SB_114,
    SB_115,
    SB_116,
    SB_221,
    SB_223,
    SB_224,
    SB_225,
    SB_226,
    SB_331,
    SB_332,
    SB_334,
    SB_335,
    SB_336,
    SB_441,
    SB_442,
    SB_443,
    SB_445,
    SB_446,
    SB_551,
    SB_552,
    SB_553,
    SB_554,
    SB_556,
    SB_661,
    SB_662,
    SB_663,
    SB_664,
    SB_665,
    
    SB_126,
    SB_135,
    SB_234,
    SB_256,
    SB_346,
    SB_123,
    SB_136,
    SB_145,
    SB_235,
    SB_356,
    SB_124,
    SB_146,
    SB_236,
    SB_245,
    SB_456,
    SB_125,
    SB_134,
    SB_156,
    SB_246,
    SB_345,
}