import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpBaccaratGoodRoad, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { convertGoodRoad } from '../../../utils/games/baccarat';
import { hostsSliceActions } from '../../../modules/host/slice';

export class CMDSpBaccaratGoodRoadHandler extends AbstractHandler {
    _command: CMDSpBaccaratGoodRoad;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBaccaratGoodRoad;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const goodRoad = convertGoodRoad(this._command);

        dispatch(
            hostsSliceActions.updateGoodRoad({
                hostId: goodRoad.HostID,
                goodRoad: goodRoad,
            })
        );
    }
}
