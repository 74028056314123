import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// import { Music as MusicEnum } from '../../../models/Music';
import { Voice as VoiceEnum } from '../../../models/Voice';

export const SOUND_KEY = 'app::setting::sound';
export type SoundState = {
    Voice2: VoiceEnum;
    // Music: MusicEnum;
};
const initialSoundState = {
    Voice2: VoiceEnum.English1,
    // Music: MusicEnum.Shuffle,
} as SoundState;
const updateVoice = (state: SoundState, action: PayloadAction<VoiceEnum>) => {
    state.Voice2 = action.payload;
};
// const updateMusic = (state: SoundState, action: PayloadAction<MusicEnum>) => {
//     state.Music = action.payload;
// };
const updateAll = (state: SoundState, action: PayloadAction<SoundState>) => {
    state.Voice2 = action.payload.Voice2;
    // state.Music = action.payload.Music;
};
const soundSlice = createSlice({
    name: SOUND_KEY,
    initialState: initialSoundState,
    reducers: {
        // updateMusic,
        updateVoice,
        updateAll,
    },
});
export const soundSliceReducer = soundSlice.reducer;
export const soundSliceActions = soundSlice.actions;
