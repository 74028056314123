import { CMDRedeemRecord } from "./CMDRedeemRecord"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPromotionRedeemLog implements ICommand
{

	public Record : Array<CMDRedeemRecord>;

	public constructor()
	{
		this.Record = new Array<CMDRedeemRecord>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let RecordSize:number = ba.readUint16();
		while (RecordSize-- > 0)
		{
			let RecordChild:CMDRedeemRecord = new CMDRedeemRecord();
			RecordChild.fromBytes(ba);
			this.Record.push(RecordChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.Record.length);
		for (const RecordChild of this.Record)
		{
			let tempBuf = RecordChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPromotionRedeemLog();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Record_size(W)='+this.Record.length + ' ';
		str += 'Record(CMDRedeemRecord)=[';
		for (let RecordChild in this.Record)
		{
			if (<number><unknown>RecordChild > 0) str += ', ';
			str += this.Record[RecordChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50122;
	}

	public getCmdName() : string
	{
		return 'SpPromotionRedeemLog';
	}

}
