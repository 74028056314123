import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { countsSliceActions } from '../../../modules/count/slice';
import { resultProgressSliceActions } from '../../../modules/progress/slice/result';
import { Progress } from '../../games/Progress';
import { CMDSpLivePlayerCount, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpLivePlayerCountHandler extends AbstractHandler {
    _command: CMDSpLivePlayerCount;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpLivePlayerCount;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        dispatch(
            countsSliceActions.update({
                HostId: -1,
                Count: this._command.PlayerCount,
            })
        );
        dispatch(resultProgressSliceActions.endLoad(Progress.SERVER_COUNT));
    }
}
