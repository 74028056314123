import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDTopResultPlayer implements ICommand
{

	public PlayerID : number = 0;
	public CurrencyType : number = 0;
	public ResultAmount : number = 0;
	public PlayerName : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerID = ba.readInt53();
		this.CurrencyType = ba.readInt53();
		this.ResultAmount = ba.readInt53();
		this.PlayerName = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.PlayerID);
		ba.writeUint53(this.CurrencyType);
		ba.writeUint53(this.ResultAmount);
		ba.writeString(this.PlayerName);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDTopResultPlayer();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'CurrencyType(Q)='+this.CurrencyType+' ';
		str += 'ResultAmount(Q)='+this.ResultAmount+' ';
		str += 'PlayerName(S)='+this.PlayerName+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'TopResultPlayer';
	}

}
