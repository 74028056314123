import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from './PromotionDialog';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    RefObject,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { getPromotionToolState } from '../../../main/selector';
import { RootState } from '../../../../store/store';
import { useDispatch } from 'react-redux';
import {
    ImageType,
    dateToString,
    dateToTimeLeftArray,
    getDisplayTimeZone,
    getImageJsonArray,
    timeToString,
} from '../PromotionUtil';
import { numberFormat } from '../../../../utils/commonFunc';
import { useTranslation } from 'react-i18next';
import { CMDPsSuspendPromotionPopup } from '../../../../models/cmd/live';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { PromotionButton } from './PromotionButton';
import { useBalance } from '@/hooks/useBalance';
import {
    getPromotionTool,
    PromotionToolEnum,
} from '@/components/images/base64/PromotionTool';
import {
    promotionMoneyFigure,
    promotionTimeFigure,
} from '../PromotionObjectMapper';
import { BaseTypography } from '@/components/common/baseText/BaseTypography';
import { usePromotionTool } from '@/hooks/popup/promotiontool/usePromotionTool';
import { popupSliceActions } from '../../slice';

export const PromotionSplash = () => {
    const { eventEnded } = usePromotionTool();
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const [showToday, setshowToday] = useState(false);
    const showTodayRef = useRef(false);
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [adjScale, setAdjScale] = useState(1);
    const [time, setTime] = useState<string[]>(['']);
    const { StartTimestamp, EndTimestamp, Timezone, BudgetAmount } =
        useSelector((state: RootState) => getPromotionToolState(state));
    //Currency handling
    const { currency } = useBalance();
    const displayTimezone = getDisplayTimeZone(Timezone);

    useEffect(() => {
        const updateTimer = () =>
            setTime(
                getImageJsonArray(
                    ImageType.Countdown,
                    dateToTimeLeftArray(EndTimestamp, displayTimezone).join(':')
                )
            );
        updateTimer();
        const timer = setInterval(() => {
            updateTimer();
        }, 1000);
        dispatch(promotionToolSliceActions.isLoading(false));
        adjustScale(ref, 443);
        return () => {
            clearInterval(timer);
            if (showTodayRef.current === true) {
                let cmd: CMDPsSuspendPromotionPopup =
                    new CMDPsSuspendPromotionPopup();
                sendCommand(cmd);
            }
        };
    }, []);

    const numbers: string[] = getImageJsonArray(
        ImageType.Prize,
        `${numberFormat(BudgetAmount, 0)}`
    );
    const periodText = useMemo<string>(() => {
        const startDate = new Date(StartTimestamp * 1000);
        const startPeriod = `${dateToString(startDate, false, '-')} ${timeToString(startDate, false, ':')}`;
        const endDate = new Date(EndTimestamp * 1000);
        const endPeriod = `${dateToString(endDate, false, '-')} ${timeToString(endDate, false, ':')}`;
        return `${startPeriod} - ${endPeriod}`;
    }, [StartTimestamp, EndTimestamp]);

    const show = () => {
        setshowToday(prev => !prev);
        showTodayRef.current = !showTodayRef.current;
    };

    const adjustScale = (ref: RefObject<HTMLDivElement>, maxWidth: number) => {
        if (ref && ref.current) {
            if (ref.current.clientWidth > maxWidth) {
                setAdjScale(maxWidth / ref.current.clientWidth);
            }
        }
    };

    return (
        <>
            <Box className="total-prize-container">
                {/* <BaseImage
                    className="promotion_tool event_total_prize_bg"
                    scale={1}
                /> */}
                <Box className="event-total-prize-bg">
                    {getPromotionTool(PromotionToolEnum.event_total_prize_bg)}
                </Box>
                <Box className="total-prize-title-container">
                    <Typography className="total-prize-title-text">
                        {`${t('promotion_tool.total_prize')} (${currency})`}
                    </Typography>
                </Box>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className="total-prize"
                >
                    <Box ref={ref} sx={{ display: 'inline-flex' }}>
                        {numbers.map((value, index) => (
                            // <BaseImage
                            //     className={value}
                            //     scale={adjScale}
                            //     key={`event-total-prize-${index}`}
                            // />
                            <Box
                                key={`event-total-prize-${index}`}
                                sx={{ scale: adjScale }}
                            >
                                {getPromotionTool(promotionMoneyFigure[value])}
                            </Box>
                        ))}
                    </Box>
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={2}
                    className="event-date"
                >
                    {/* <BaseImage
                        className="promotion_tool event_caption_arrow"
                        scale={1}
                    /> */}
                    <Box display={'flex'}>
                        {getPromotionTool(
                            PromotionToolEnum.event_caption_arrow
                        )}
                    </Box>
                    <Typography>{`${t('promotion_tool.event_date')} (UTC${displayTimezone >= 0 ? '+' + displayTimezone.toString() : displayTimezone.toString()})`}</Typography>
                    {/* <BaseImage
                        className="promotion_tool event_caption_arrow rotate180"
                        scale={1}
                    /> */}
                    <Box display={'flex'} className="rotate180">
                        {getPromotionTool(
                            PromotionToolEnum.event_caption_arrow
                        )}
                    </Box>
                </Stack>
                <Box className="event-period">{periodText}</Box>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className="event-remain-time"
                    spacing={1}
                >
                    {time.map((value, index) => (
                        // <BaseImage
                        //     className={value}
                        //     scale={1}
                        //     key={`event-remain-time-${index}`}
                        // />
                        <Box key={`event-remain-time-${index}`}>
                            {getPromotionTool(promotionTimeFigure[value])}
                        </Box>
                    ))}
                </Stack>

                <Stack
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        top: '44px',
                        color: '#C6C6C6',
                    }}
                    justifyContent={'center'}
                    direction={'row'}
                    spacing={1}
                >
                    <Typography className="time-text">
                        {t('promotion_tool.days')}
                    </Typography>
                    <Typography className="time-text">
                        {t('promotion_tool.hours')}
                    </Typography>
                    <Typography className="time-text">
                        {t('promotion_tool.minutes')}
                    </Typography>
                    <Typography className="time-text">
                        {t('promotion_tool.seconds')}
                    </Typography>
                </Stack>
            </Box>
            <Stack className="page-buttons" direction={'row'} spacing={'20px'}>
                <PromotionButton
                    className="button_gold"
                    onClick={() => {
                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Gacha
                            )
                        );
                    }}
                >
                    <BaseTypography
                        resize={{
                            direction: 'horizontal',
                            value: '200px',
                        }}
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        {t('promotion_tool.explore_rewards')}
                    </BaseTypography>
                </PromotionButton>
                <PromotionButton
                    className="button_black"
                    onClick={() => {
                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Ranking
                            )
                        );
                    }}
                >
                    {t('promotion_tool.rankings')}
                </PromotionButton>
            </Stack>
            <PromotionButton className="snooze-button" onClick={() => show()}>
                {/* <BaseImage
                        className={`promotion_tool ${showToday ? 'event_checkbox_selected' : 'event_checkbox'}`}
                        scale={1}
                    /> */}
                <Box display={'flex'}>
                    {getPromotionTool(
                        showToday
                            ? PromotionToolEnum.event_checkbox_selected
                            : PromotionToolEnum.event_checkbox
                    )}
                </Box>
                <Typography
                    sx={{
                        marginLeft: '4px',
                        color: '#DCDCDC',
                        fontSize: '16px',
                    }}
                >
                    {t('promotion_tool.ignore')}
                </Typography>
            </PromotionButton>
        </>
    );
};
