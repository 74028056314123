import { Buffer } from 'buffer';
export class ByteArray {
    private _pos: number = 0;
    private _buf: Buffer;

    public set position(val:number) {
        this._pos = val;
    } 

    public get position(): number {
        return this._pos;
    }

    public get byteLength(): number {
        return this._buf.byteLength;
    }

    constructor(buf?: Buffer) {
        if (buf === undefined) {
            this._buf = Buffer.alloc(0);
        } else {
            this._buf = buf;
        }
    }

    public toBuffer(): Buffer {
        return this._buf;
    }

    public readUint8(): number {
        const data = this._buf.readUInt8(this._pos);
        this._pos++;
        return data;
    }

    public readUint16(): number {
        const data = this._buf.readUInt16LE(this._pos);
        this._pos = this._pos + 2;
        return data;
    }

    public readUint32(): number {
        const data = this._buf.readUInt32LE(this._pos);
        this._pos = this._pos + 4;
        return data;
    }

    public readUint53(): number {
        const data = this.readUint64();
        return Number(data);
    }

    public readUint64(): bigint {
        const data = this._buf.readBigUInt64LE(this._pos);
        this._pos = this._pos + 8;
        return data;
    }

    public readInt8(): number {
        const data = this._buf.readInt8(this._pos);
        this._pos++;
        return data;
    }

    public readInt16(): number {
        const data = this._buf.readInt16LE(this._pos);
        this._pos = this._pos + 2;
        return data;
    }

    public readInt32(): number {
        const data = this._buf.readInt32LE(this._pos);
        this._pos = this._pos + 4;
        return data;
    }

    public readInt53(): number {
        const data = this.readInt64();
        return Number(data);
    }

    public readInt64(): bigint {
        const data = this._buf.readBigInt64LE(this._pos);
        this._pos = this._pos + 8;
        return data;
    }

    public readString(): string {
        const data = this._buf.subarray(this._pos);
        const idx = data.indexOf(0x00);
        const result = data.subarray(0, idx);
        this._pos = this._pos + idx + 1;
        return result.toString('utf8');
    }

    // public toBitString(radix: number = 16): string {
    // 	return this._buf.toString("hex").match(/../g).join(" ");
    // }

    public writeString(val: string) {
        this._buf = Buffer.concat([
            this._buf,
            Buffer.from(val),
            Buffer.alloc(1),
        ]);
    }

    public appendBuf(buf: Buffer) {
        this._buf = Buffer.concat([this._buf, buf]);
    }

    public writeUint64(val: bigint) {
        const b = Buffer.alloc(8);
        b.writeBigUInt64LE(val);
        this._buf = Buffer.concat([this._buf, b]);
    }

    public writeUint53(val: number) {
        const b = Buffer.alloc(8);
        b.writeBigUInt64LE(BigInt(val));
        this._buf = Buffer.concat([this._buf, b]);
    }

    public writeUint8(val: number) {
        const b = Buffer.alloc(1);
        b.writeUint8(val);
        this._buf = Buffer.concat([this._buf, b]);
    }

    public writeUint32(val: number) {
        const b = Buffer.alloc(4);
        b.writeUint32LE(val);
        this._buf = Buffer.concat([this._buf, b]);
    }

    public writeUint16(val: number) {
        const b = Buffer.alloc(2);
        b.writeUInt16LE(val);
        this._buf = Buffer.concat([this._buf, b]);
    }
}
