export enum BetType {
	PDBPlayer1Win,
	PDBPlayer2Win,
	PDBPlayer3Win,
	PDBPlayer4Win,
	PDBPlayer5Win,

	PDBPlayer1Pair,
	PDBPlayer2Pair,
	PDBPlayer3Pair,
	PDBPlayer4Pair,
	PDBPlayer5Pair,
	PDBMaxBet,
}
