import { createTheme, ThemeOptions } from '@mui/material';

// default theme value
// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette

const themeJson: ThemeOptions = {
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Noto Sans',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
    },
    palette: {
        mode: 'dark',
        // global main color
        primary: {
            light: '#978365',
            main: '#8B7554',
            dark: '#7d694c',
        },
        secondary: {
            light: '#1c9606',
            main: '#196260',
            dark: '#009303',
        },
        // components color
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
        },
        // button
        action: {
            active: '#fff',
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
        },
        // default background color
        background: {
            default: '#000000',
            paper: '#000000',
        },
        // divider
        divider: 'rgba(255, 255, 255, 0.12)',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'unset',
                },
            },
        },
    },
};

export const theme = createTheme({
    ...themeJson,
    breakpoints: {
        values: {
            xs: 0,
            sm: 430,
            md: 836,
            lg: 1280,
            xl: 1600,
        },
    },
});

export const theme2 = createTheme({
    ...themeJson,
    breakpoints: {
        values: {
            xs: 0,
            sm: 590,
            md: 996,
            lg: 1440,
            xl: 1760,
        },
    },
});

export const theme3 = createTheme({
    ...themeJson,
    breakpoints: {
        values: {
            xs: 0,
            sm: 430 + 430 * 0.25,
            md: 836 + 836 * 0.25,
            lg: 1280 + 1280 * 0.25,
            xl: 1600 + 1600 * 0.25,
        },
    },
});
