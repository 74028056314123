import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetBaccarat implements ICommand
{

	public Total : CMDTotalBet;
	public Banker : CMDBetSummary;
	public Player : CMDBetSummary;
	public Tie : CMDBetSummary;
	public BankerPair : CMDBetSummary;
	public PlayerPair : CMDBetSummary;
	public BankerNatural : CMDBetSummary;
	public PlayerNatural : CMDBetSummary;
	public SuperSix : CMDBetSummary;
	public LuckySix : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Banker = new CMDBetSummary();
		this.Banker.fromBytes(ba);
		this.Player = new CMDBetSummary();
		this.Player.fromBytes(ba);
		this.Tie = new CMDBetSummary();
		this.Tie.fromBytes(ba);
		this.BankerPair = new CMDBetSummary();
		this.BankerPair.fromBytes(ba);
		this.PlayerPair = new CMDBetSummary();
		this.PlayerPair.fromBytes(ba);
		this.BankerNatural = new CMDBetSummary();
		this.BankerNatural.fromBytes(ba);
		this.PlayerNatural = new CMDBetSummary();
		this.PlayerNatural.fromBytes(ba);
		this.SuperSix = new CMDBetSummary();
		this.SuperSix.fromBytes(ba);
		this.LuckySix = new CMDBetSummary();
		this.LuckySix.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Banker.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Player.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Tie.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.BankerPair.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.PlayerPair.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.BankerNatural.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.PlayerNatural.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.SuperSix.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)
		let tempBuf9 = this.LuckySix.toBytes().toBuffer();
		ba.appendBuf(tempBuf9)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetBaccarat();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Banker(CMDBetSummary)='+this.Banker+' ';
		str += 'Player(CMDBetSummary)='+this.Player+' ';
		str += 'Tie(CMDBetSummary)='+this.Tie+' ';
		str += 'BankerPair(CMDBetSummary)='+this.BankerPair+' ';
		str += 'PlayerPair(CMDBetSummary)='+this.PlayerPair+' ';
		str += 'BankerNatural(CMDBetSummary)='+this.BankerNatural+' ';
		str += 'PlayerNatural(CMDBetSummary)='+this.PlayerNatural+' ';
		str += 'SuperSix(CMDBetSummary)='+this.SuperSix+' ';
		str += 'LuckySix(CMDBetSummary)='+this.LuckySix+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30751;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetBaccarat';
	}

}
