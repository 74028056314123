import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { CMDSpRuleChange, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { limitRedSliceActions } from '../../../modules/main/slice/limitRed';
export class CMDSpRuleChangeHandler extends AbstractHandler {
    _command: CMDSpRuleChange;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpRuleChange;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        if (this._command.Result === this.SUCCESS_CODE) {
            dispatch(
                limitRedSliceActions.updateBetRuleSelected({
                    RuleID: this._command.RuleIndex,
                    GameType: this._command.GameType,
                })
            );
        }
    }
}
