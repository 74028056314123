import { AnimationDefinition, useAnimationControls } from 'framer-motion';

export const DEFAULT_FLASH_ANIMATION_CHANGES = [
    0, 0.2, 0, 0.2, 0, 0.2, 0, 0.2, 0, 0.2, 0, 0.2, 0,
];
export const DEFAULT_FLASH_ANIMATION_DURATION = { duration: 6 };
export const DEFAULT_FLASH_ANIMATION: AnimationDefinition = {
    transition: DEFAULT_FLASH_ANIMATION_DURATION,
    opacity: DEFAULT_FLASH_ANIMATION_CHANGES,
};

export const useWinningAnim = () => {
    const controller = useAnimationControls();
    const startAnim = () => {
        controller.start(DEFAULT_FLASH_ANIMATION);
    };

    const stopAnim = () => {
        controller.start({
            fillOpacity: 0,
            transition: { duration: 0 },
        });
    };

    return {
        controller,
        startAnim,
        stopAnim,
    };
};
