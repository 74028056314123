import { CMDLotteryTicket } from "./CMDLotteryTicket"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLotteryTicketDetail implements ICommand
{

	public Ticket : Array<CMDLotteryTicket>;

	public constructor()
	{
		this.Ticket = new Array<CMDLotteryTicket>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let TicketSize:number = ba.readUint8();
		while (TicketSize-- > 0)
		{
			let TicketChild:CMDLotteryTicket = new CMDLotteryTicket();
			TicketChild.fromBytes(ba);
			this.Ticket.push(TicketChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Ticket.length);
		for (const TicketChild of this.Ticket)
		{
			let tempBuf = TicketChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLotteryTicketDetail();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Ticket_size(B)='+this.Ticket.length + ' ';
		str += 'Ticket(CMDLotteryTicket)=[';
		for (let TicketChild in this.Ticket)
		{
			if (<number><unknown>TicketChild > 0) str += ', ';
			str += this.Ticket[TicketChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50045;
	}

	public getCmdName() : string
	{
		return 'SpLotteryTicketDetail';
	}

}
