import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDThaihiloStatistics implements ICommand
{

	public Number1Count : number = 0;
	public Number2Count : number = 0;
	public Number3Count : number = 0;
	public Number4Count : number = 0;
	public Number5Count : number = 0;
	public Number6Count : number = 0;
	public HiCount : number = 0;
	public LoCount : number = 0;
	public ElevenHiLoCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Number1Count = ba.readUint8();
		this.Number2Count = ba.readUint8();
		this.Number3Count = ba.readUint8();
		this.Number4Count = ba.readUint8();
		this.Number5Count = ba.readUint8();
		this.Number6Count = ba.readUint8();
		this.HiCount = ba.readUint8();
		this.LoCount = ba.readUint8();
		this.ElevenHiLoCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Number1Count);
		ba.writeUint8(this.Number2Count);
		ba.writeUint8(this.Number3Count);
		ba.writeUint8(this.Number4Count);
		ba.writeUint8(this.Number5Count);
		ba.writeUint8(this.Number6Count);
		ba.writeUint8(this.HiCount);
		ba.writeUint8(this.LoCount);
		ba.writeUint8(this.ElevenHiLoCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDThaihiloStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Number1Count(B)='+this.Number1Count+' ';
		str += 'Number2Count(B)='+this.Number2Count+' ';
		str += 'Number3Count(B)='+this.Number3Count+' ';
		str += 'Number4Count(B)='+this.Number4Count+' ';
		str += 'Number5Count(B)='+this.Number5Count+' ';
		str += 'Number6Count(B)='+this.Number6Count+' ';
		str += 'HiCount(B)='+this.HiCount+' ';
		str += 'LoCount(B)='+this.LoCount+' ';
		str += 'ElevenHiLoCount(B)='+this.ElevenHiLoCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'ThaihiloStatistics';
	}

}
