import { RootState } from '../../store/store';
import { GAME_KEY, GameState } from './slice';

export const getGameState = (state: RootState) => state[GAME_KEY] as GameState;

// export const getConfirmBets = (state: RootState): Array<Bet> | undefined => {
//     const gameState = getGameState(state);
//     return gameState.confirmedBets;
// };

// export const getPreBets = (state: RootState): Array<Bet> | undefined => {
//     const gameState = getGameState(state);
//     return gameState.prevBets;
// };
// export const getPrevBetByBetType = (
//     state: RootState,
//     betType: number
// ): Bet | undefined => {
//     const gameState = getGameState(state);
//     const index = gameState.prevBets.findIndex(b => b.Type === betType);
//     return index !== -1 ? gameState.prevBets[index] : undefined;
// };

export const getCurrentGameId = (state: RootState): number => {
    const gameState = getGameState(state);
    return gameState.currentGameId;
};
// export const getAllConfirmedBetAmount = (state: RootState): number => {
//     const gameState = getGameState(state);
//     const amount =
//         gameState.confirmedBets
//             .map(b => b.Amount)
//             .reduce((partialSum, a) => partialSum + a, 0) || 0;
//     return amount;
// };
export const getOnDoubleBet = (state: RootState): boolean => {
    const gameState = getGameState(state);
    return gameState.doubleBet;
};
export const getOnReBet = (state: RootState): boolean => {
    const gameState = getGameState(state);
    return gameState.reBet;
};
export const getWaitingBetResult = (state: RootState): boolean => {
    const gameState = getGameState(state);
    return gameState.waitingBetResult;
};
