import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDFantanStatistics implements ICommand
{

	public Point1Count : number = 0;
	public Point2Count : number = 0;
	public Point3Count : number = 0;
	public Point4Count : number = 0;
	public OddCount : number = 0;
	public EvenCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Point1Count = ba.readUint8();
		this.Point2Count = ba.readUint8();
		this.Point3Count = ba.readUint8();
		this.Point4Count = ba.readUint8();
		this.OddCount = ba.readUint8();
		this.EvenCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Point1Count);
		ba.writeUint8(this.Point2Count);
		ba.writeUint8(this.Point3Count);
		ba.writeUint8(this.Point4Count);
		ba.writeUint8(this.OddCount);
		ba.writeUint8(this.EvenCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDFantanStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Point1Count(B)='+this.Point1Count+' ';
		str += 'Point2Count(B)='+this.Point2Count+' ';
		str += 'Point3Count(B)='+this.Point3Count+' ';
		str += 'Point4Count(B)='+this.Point4Count+' ';
		str += 'OddCount(B)='+this.OddCount+' ';
		str += 'EvenCount(B)='+this.EvenCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'FantanStatistics';
	}

}
