import { createContext } from 'react';
import { useBetChip, useBetChipState } from '../hooks/useBetChip';
import { GameType } from '../models/games/enums/GameType';

//暫定放冇save server & local storage, re-login game 就會 del data
export const BetChipContext = createContext({} as useBetChipState);

type BetChipProviderProps = {
    gameType: GameType;
    children: JSX.Element;
};

export const BetChipProvider = (props: BetChipProviderProps) => {
    const { children, gameType } = props;
    const BetChip = useBetChip(gameType);
    return (
        <BetChipContext.Provider value={BetChip}>
            {children}
        </BetChipContext.Provider>
    );
};
