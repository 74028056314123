import { BetType } from '../../models/games/sedie/BetType';
import { GameState } from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { isBitActive } from '../bitwiseUtil';
import { SedieRecord, SedieResult } from '../../models/games/sedie';
import { SupportedGameResult } from '../../modules/host/models';

const getWinBetType = (result: number): Array<number> => {
    let arr = [];
    for (let i = 0; i < BetType.SDBMaxBet; i++) {
        if (isBitActive(result, i)) {
            arr.push(i);
        }
    }
    return arr;
};

export const convertResultToRecord = (result: SedieResult): SedieRecord => {
    const winBetList = getWinBetType(result.Result);
    const NoOfRed = 4 - winBetList[0];
    const record: SedieRecord = {
        gameID: result.GameID,
        result: result.Result,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        resultPoint: NoOfRed,
        NoOfRed: NoOfRed,
        winBetType: winBetList,
    };

    return record;
};

export const convertSedieResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    result?: number
): SedieRecord => {
    const { FResult, GameID, Result, GameCount } = currentResult;
    const updateResult: SedieResult = {
        NoOfRed: Number(resultString),
        FResult: FResult,
        GameCount: GameCount,
        GameID: GameID,
        Result: result ?? Result,
    };

    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean,
    isMsgPopup: boolean = false
) => {
    if (isRest) {
        return LocalState.REST;
    }

    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Restart:
            return GameState.Restart;
        case GameState.Idle:
            return LocalState.IDLE;
        case GameState.Shuffle:
            return LocalState.IDLE;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
            return LocalState.DEALING;
        case GameState.GameResult:
            if (isGameResultReleased) {
                return LocalState.RESULT;
            } else {
                return isMsgPopup ? -1 : LocalState.DEALING;
            }
    }
    return LocalState.IDLE;
};
