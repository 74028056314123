import { ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class ObsoleteHandler extends AbstractHandler {
    _command: ICommand;
    constructor(command: ICommand) {
        super();
        this._command = command;
    }
    handle(): void {
        console.log('hook::socket::obsolete', this._command);
    }
}
