import { CMDOdd } from "./CMDOdd"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpMergeOdd implements ICommand
{

	public GameID : number = 0;
	public Odds : Array<CMDOdd>;

	public constructor()
	{
		this.Odds = new Array<CMDOdd>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		let OddsSize:number = ba.readUint8();
		while (OddsSize-- > 0)
		{
			let OddsChild:CMDOdd = new CMDOdd();
			OddsChild.fromBytes(ba);
			this.Odds.push(OddsChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint8(this.Odds.length);
		for (const OddsChild of this.Odds)
		{
			let tempBuf = OddsChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpMergeOdd();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'Odds_size(B)='+this.Odds.length + ' ';
		str += 'Odds(CMDOdd)=[';
		for (let OddsChild in this.Odds)
		{
			if (<number><unknown>OddsChild > 0) str += ', ';
			str += this.Odds[OddsChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50048;
	}

	public getCmdName() : string
	{
		return 'SpMergeOdd';
	}

}
