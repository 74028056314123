import { CMDJackpot } from "./CMDJackpot"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpInitEgame implements ICommand
{

	public PlayerID : number = 0;
	public BetID : number = 0;
	public Jackpot : CMDJackpot;
	public UnknownData : string = "";
	public NoChanceJackpot : number = 0;
	public LobbyCode : string = "";
	public ReconnectKey : string = "";
	public NotificationCount : number = 0;
	public IsCommonWallet : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PlayerID = ba.readInt53();
		this.BetID = ba.readInt53();
		this.Jackpot = new CMDJackpot();
		this.Jackpot.fromBytes(ba);
		this.UnknownData = ba.readString();
		this.NoChanceJackpot = ba.readUint8();
		this.LobbyCode = ba.readString();
		this.ReconnectKey = ba.readString();
		this.NotificationCount = ba.readUint16();
		this.IsCommonWallet = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.PlayerID);
		ba.writeUint53(this.BetID);
		let tempBuf2 = this.Jackpot.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		ba.writeString(this.UnknownData);
		ba.writeUint8(this.NoChanceJackpot);
		ba.writeString(this.LobbyCode);
		ba.writeString(this.ReconnectKey);
		ba.writeUint16(this.NotificationCount);
		ba.writeUint8(this.IsCommonWallet);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpInitEgame();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'BetID(Q)='+this.BetID+' ';
		str += 'Jackpot(CMDJackpot)='+this.Jackpot+' ';
		str += 'UnknownData(S)='+this.UnknownData+' ';
		str += 'NoChanceJackpot(B)='+this.NoChanceJackpot+' ';
		str += 'LobbyCode(S)='+this.LobbyCode+' ';
		str += 'ReconnectKey(S)='+this.ReconnectKey+' ';
		str += 'NotificationCount(W)='+this.NotificationCount+' ';
		str += 'IsCommonWallet(B)='+this.IsCommonWallet+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50027;
	}

	public getCmdName() : string
	{
		return 'SpInitEgame';
	}

}
