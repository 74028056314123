import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPartnerCompany implements ICommand
{

	public CompanyID : number = 0;
	public PartnerGameID : Array<number>;

	public constructor()
	{
		this.PartnerGameID = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.CompanyID = ba.readInt53();
		let PartnerGameIDSize:number = ba.readUint8();
		while (PartnerGameIDSize-- > 0)
		{
			let PartnerGameIDChild:number = ba.readInt53();
			this.PartnerGameID.push(PartnerGameIDChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.CompanyID);
		ba.writeUint8(this.PartnerGameID.length);
		for (const PartnerGameIDChild of this.PartnerGameID)
		{
			ba.writeUint53(PartnerGameIDChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPartnerCompany();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'CompanyID(Q)='+this.CompanyID+' ';
		str += 'PartnerGameID_size(B)='+this.PartnerGameID.length + ' ';
		str += 'PartnerGameID(Q)=[';
		for (let PartnerGameIDChild in this.PartnerGameID)
		{
			if (<number><unknown>PartnerGameIDChild > 0) str += ', ';
			str += this.PartnerGameID[PartnerGameIDChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'PartnerCompany';
	}

}
