import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDHost implements ICommand
{

	public HostID : number = 0;
	public GameID : number = 0;
	public GameCount : number = 0;
	public FLastGameResult : bigint = 0n; 
	public LastGameResult : number = 0;
	public State : number = 0;
	public LastGameResultDetail : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.GameID = ba.readInt53();
		this.GameCount = ba.readUint32();
		this.FLastGameResult = ba.readUint64();
		this.LastGameResult = Number(this.FLastGameResult);
		this.State = ba.readUint8();
		this.LastGameResultDetail = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint53(this.GameID);
		ba.writeUint32(this.GameCount);
		ba.writeUint64(this.FLastGameResult);
		ba.writeUint8(this.State);
		ba.writeString(this.LastGameResultDetail);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDHost();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'GameCount(D)='+this.GameCount+' ';
		str += 'LastGameResult(F)='+this.LastGameResult+' ';
		str += 'State(B)='+this.State+' ';
		str += 'LastGameResultDetail(S)='+this.LastGameResultDetail+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Host';
	}

}
