import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBaseStatistics implements ICommand
{

	public FromDate : number = 0;
	public ToDate : number = 0;
	public FromDrawNumber : number = 0;
	public ToDrawNumber : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.FromDate = ba.readInt53();
		this.ToDate = ba.readInt53();
		this.FromDrawNumber = ba.readUint32();
		this.ToDrawNumber = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.FromDate);
		ba.writeUint53(this.ToDate);
		ba.writeUint32(this.FromDrawNumber);
		ba.writeUint32(this.ToDrawNumber);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBaseStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'FromDate(Q)='+this.FromDate+' ';
		str += 'ToDate(Q)='+this.ToDate+' ';
		str += 'FromDrawNumber(D)='+this.FromDrawNumber+' ';
		str += 'ToDrawNumber(D)='+this.ToDrawNumber+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BaseStatistics';
	}

}
