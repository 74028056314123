import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBaccaratGoodRoad implements ICommand
{

	public HostID : number = 0;
	public LongBanker : number = 0;
	public LongPlayer : number = 0;
	public ClapClapRoad : number = 0;
	public BigRoadSingleBanker : number = 0;
	public OneLoungeTwoRoomsBanker : number = 0;
	public OneLoungeTwoRoomsPlayer : number = 0;
	public TwoRoomsOneLoungeBanker : number = 0;
	public TwoRoomsOneLoungePlayer : number = 0;
	public ConsecutiveBankers : number = 0;
	public ConsecutivePlayers : number = 0;
	public NonConsecutiveBankers : number = 0;
	public NonConsecutivePlayers : number = 0;
	public BigRoadSinglePlayer : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.LongBanker = ba.readUint8();
		this.LongPlayer = ba.readUint8();
		this.ClapClapRoad = ba.readUint8();
		this.BigRoadSingleBanker = ba.readUint8();
		this.OneLoungeTwoRoomsBanker = ba.readUint8();
		this.OneLoungeTwoRoomsPlayer = ba.readUint8();
		this.TwoRoomsOneLoungeBanker = ba.readUint8();
		this.TwoRoomsOneLoungePlayer = ba.readUint8();
		this.ConsecutiveBankers = ba.readUint8();
		this.ConsecutivePlayers = ba.readUint8();
		this.NonConsecutiveBankers = ba.readUint8();
		this.NonConsecutivePlayers = ba.readUint8();
		this.BigRoadSinglePlayer = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint8(this.LongBanker);
		ba.writeUint8(this.LongPlayer);
		ba.writeUint8(this.ClapClapRoad);
		ba.writeUint8(this.BigRoadSingleBanker);
		ba.writeUint8(this.OneLoungeTwoRoomsBanker);
		ba.writeUint8(this.OneLoungeTwoRoomsPlayer);
		ba.writeUint8(this.TwoRoomsOneLoungeBanker);
		ba.writeUint8(this.TwoRoomsOneLoungePlayer);
		ba.writeUint8(this.ConsecutiveBankers);
		ba.writeUint8(this.ConsecutivePlayers);
		ba.writeUint8(this.NonConsecutiveBankers);
		ba.writeUint8(this.NonConsecutivePlayers);
		ba.writeUint8(this.BigRoadSinglePlayer);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBaccaratGoodRoad();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'LongBanker(B)='+this.LongBanker+' ';
		str += 'LongPlayer(B)='+this.LongPlayer+' ';
		str += 'ClapClapRoad(B)='+this.ClapClapRoad+' ';
		str += 'BigRoadSingleBanker(B)='+this.BigRoadSingleBanker+' ';
		str += 'OneLoungeTwoRoomsBanker(B)='+this.OneLoungeTwoRoomsBanker+' ';
		str += 'OneLoungeTwoRoomsPlayer(B)='+this.OneLoungeTwoRoomsPlayer+' ';
		str += 'TwoRoomsOneLoungeBanker(B)='+this.TwoRoomsOneLoungeBanker+' ';
		str += 'TwoRoomsOneLoungePlayer(B)='+this.TwoRoomsOneLoungePlayer+' ';
		str += 'ConsecutiveBankers(B)='+this.ConsecutiveBankers+' ';
		str += 'ConsecutivePlayers(B)='+this.ConsecutivePlayers+' ';
		str += 'NonConsecutiveBankers(B)='+this.NonConsecutiveBankers+' ';
		str += 'NonConsecutivePlayers(B)='+this.NonConsecutivePlayers+' ';
		str += 'BigRoadSinglePlayer(B)='+this.BigRoadSinglePlayer+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50090;
	}

	public getCmdName() : string
	{
		return 'SpBaccaratGoodRoad';
	}

}
