import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { SedieTotalBet } from '../../games/sedie';
import { CMDScTotalBetXocdia, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetSedieHandler extends AbstractHandler {
    _command: CMDScTotalBetXocdia;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetXocdia;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as SedieTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;

        TotalBet.FourRed = this.convertBetAmount(this._command.FourRed);
        TotalBet.ThreeRed = this.convertBetAmount(this._command.ThreeRed);
        TotalBet.TwoRedTwoWhite = this.convertBetAmount(
            this._command.TwoRedTwoWhite
        );
        TotalBet.ThreeWhite = this.convertBetAmount(this._command.ThreeWhite);
        TotalBet.FourWhite = this.convertBetAmount(this._command.FourWhite);
        TotalBet.FourRedOrWhite = this.convertBetAmount(
            this._command.FourRedOrWhite
        );
        TotalBet.Big = this.convertBetAmount(this._command.Big);
        TotalBet.Small = this.convertBetAmount(this._command.Small);
        TotalBet.Odd = this.convertBetAmount(this._command.Odd);
        TotalBet.Even = this.convertBetAmount(this._command.Even);

        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };
        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
