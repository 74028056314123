import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { getHostById, hostsSliceActions } from '../../../modules/host/slice';
import store from '../../../store/store';
import { convertResultToRecord } from '../../../utils/games/dragonTiger';
import { DragonTigerResult } from '../../games/dragonTiger';
import { GameState } from '../../games/enums/GameState';
import { GameType } from '../../games/enums/GameType';
import { CMDScInitDragontiger, ICommand } from '../live';
import { CMDDragontiger } from '../live/CMDDragontiger';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitDragontigerHandler extends AbstractHandler {
    _command: CMDScInitDragontiger;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitDragontiger;
    }
    override convertResult(result: CMDDragontiger): DragonTigerResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                DragonCard: result.DragonCard - 1,
                TigerCard: result.TigerCard - 1,
            }
        ) as DragonTigerResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.OldResult = this._command.OldResult.map(o =>
            this.convertResult(o)
        );
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );
        game.ResultReleased = false;
        game.IsRest = this._command.Information.Rest != 0;

        const convertedRecord =
            game.CurrentState === GameState.Shuffle
                ? []
                : game.OldResult.map(r =>
                      convertResultToRecord(r as DragonTigerResult)
                  );

        const state = store.getState();
        const host = getHostById(state, hostId);
        if (host) {
            info.CountDown -= host.DelayResult;
        }

        batch(() => {
            dispatch(
                tableBetSliceActions.initTable({
                    hostId: hostId,
                    gameId: this._command.CurrentResult.BaseResult.GameID,
                })
            );
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                    gameType: GameType.Dragon,
                })
            );
        });
    }
}
