import {
    ROUTE_FAVORITE,
    ROUTE_FEATURED,
    ROUTE_LOBBY,
    ROUTE_MULTIBET,
} from '@/models/Route';
import {
    GA_EVENT_CATEGORY,
    GA_EVENT_LABEL,
    GA_LOBBY_EVENT_NAME,
    GA_SETTING_EVENT_NAME,
} from './enum';

declare function gtag(...args: unknown[]): void;
export const sendGA = (
    en: GA_LOBBY_EVENT_NAME | GA_SETTING_EVENT_NAME | string,
    ec: GA_EVENT_CATEGORY,
    el: GA_EVENT_LABEL
) => {
    try {
        gtag('event', en, {
            category: ec,
            label: el,
        });
    } catch (e) {
        console.warn('google::ga', e, { en, ec, el });
    }
};
export const getNavTab = (to?: string): GA_EVENT_LABEL => {
    const path = to ?? window.location.href;
    if (path.includes(ROUTE_LOBBY)) {
        return GA_EVENT_LABEL.LOBBY_PAGE;
    } else if (path.includes(ROUTE_FEATURED)) {
        return GA_EVENT_LABEL.FEATURED_PAGE;
    } else if (path.includes(ROUTE_FAVORITE)) {
        return GA_EVENT_LABEL.FAVORITE_PAGE;
    } else if (path.includes(ROUTE_MULTIBET)) {
        return GA_EVENT_LABEL.MULTIBET_PAGE;
    } else {
        return GA_EVENT_LABEL.UNKNOWN_PAGE;
    }
};
