import { CMDHsFantanResult } from "./CMDHsFantanResult"
import { CMDBaseResult } from "./CMDBaseResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDFantan implements ICommand
{

	public BaseResult : CMDBaseResult;
	public Detail : CMDHsFantanResult;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseResult = new CMDBaseResult();
		this.BaseResult.fromBytes(ba);
		this.Detail = new CMDHsFantanResult();
		this.Detail.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Detail.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDFantan();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseResult(CMDBaseResult)='+this.BaseResult+' ';
		str += 'Detail(CMDHsFantanResult)='+this.Detail+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Fantan';
	}

}
