import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpServerReset implements ICommand
{

	public HostID : Array<number>;

	public constructor()
	{
		this.HostID = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let HostIDSize:number = ba.readUint8();
		while (HostIDSize-- > 0)
		{
			let HostIDChild:number = ba.readUint16();
			this.HostID.push(HostIDChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.HostID.length);
		for (const HostIDChild of this.HostID)
		{
			ba.writeUint16(HostIDChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpServerReset();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID_size(B)='+this.HostID.length + ' ';
		str += 'HostID(W)=[';
		for (let HostIDChild in this.HostID)
		{
			if (<number><unknown>HostIDChild > 0) str += ', ';
			str += this.HostID[HostIDChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50014;
	}

	public getCmdName() : string
	{
		return 'SpServerReset';
	}

}
