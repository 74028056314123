import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScStartGame implements ICommand
{

	public FGameID : bigint = 0n; 
	public GameID : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.FGameID = ba.readUint64();
		this.GameID = Number(this.FGameID);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint64(this.FGameID);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScStartGame();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(F)='+this.GameID+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30004;
	}

	public getCmdName() : string
	{
		return 'ScStartGame';
	}

}
