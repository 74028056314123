import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
export const PROGRESS_TIME_KEY = 'app::progress::time';
export type ProgressTimeState = {
    // index.html init
    start: number;
    // main.js init
    main: number;
    // send login command
    server: number;
    // enter lobby
    lobby: number;
    // from query parameter ts
    launcher: number;
    // required data from lobby server
    command: number;
    // config from cdn
    config: number;
    // local preload asset (css, audio)
    asset: number;
    // benchmark (ops/sec)
    benchmark: number;
};
export type ProgressTimeKey = keyof ProgressTimeState;
type SetProgressTimePayload = {
    key: ProgressTimeKey;
    time: number;
};
const initialProgressTimeState: ProgressTimeState = {
    start: 0,
    main: 0,
    lobby: 0,
    server: 0,
    launcher: 0,
    command: 0,
    config: 0,
    asset: 0,
    benchmark: 0,
};
const benchmark = (state: ProgressTimeState, action: PayloadAction<number>) => {
    state.benchmark = action.payload;
};
const markStart = (state: ProgressTimeState, action: PayloadAction<number>) => {
    state.start = action.payload;
    state.main = moment().valueOf() - action.payload;
};
const mark = (
    state: ProgressTimeState,
    action: PayloadAction<ProgressTimeKey>
) => {
    state[action.payload] = moment().valueOf() - state.start;
};
const reset = (
    state: ProgressTimeState,
    action: PayloadAction<ProgressTimeKey>
) => {
    state[action.payload] = 0;
};
const set = (
    state: ProgressTimeState,
    action: PayloadAction<SetProgressTimePayload>
) => {
    state[action.payload.key] = action.payload.time;
};
const progressTime = createSlice({
    name: PROGRESS_TIME_KEY,
    initialState: initialProgressTimeState,
    reducers: {
        mark,
        markStart,
        reset,
        set,
        benchmark,
    },
});
export const progressTimeReducer = progressTime.reducer;
export const progressTimeActions = progressTime.actions;
