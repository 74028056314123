import { ByteArray } from '../../models/cmd';
import { BitArray } from '../../models/cmd/BitArray';
import {
    AndarBaharRecord,
    AndarBaharResult,
} from '../../models/games/andarBahar';
import { BetType } from '../../models/games/andarBahar/BetType';
import { GameResult } from '../../models/games/andarBahar/GameResult';
import { GameState as AndarBaharGameState } from '../../models/games/andarBahar/GameState';
import { GameState } from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { SupportedGameResult } from '../../modules/host/models';
import { isBitActive } from '../bitwiseUtil';

const getWinBetType = (result: number): Array<number> => {
    let arr = [];
    for (let i = 0; i < GameResult.ABBMaxBet; i++) {
        if (isBitActive(result, i)) {
            arr.push(i);
        }
    }
    return arr;
};

export const enum AndarBaharFirst3Pattern {
    ABF3None,
    ABF3Flush,
    ABF3Straight,
    ABF3StraightFlush,
}

export const convertResultToRecord = (
    result: AndarBaharResult
): AndarBaharRecord => {
    const cards = result.AndarBahar;
    const andarCards = [];
    const baharCards = [];

    for (let i = 0; i < cards.length; i++) {
        if (i % 2 == 0) {
            andarCards.push(cards[i] + 1);
        } else {
            baharCards.push(cards[i] + 1);
        }
    }

    const record: AndarBaharRecord = {
        gameID: result.GameID,
        result: result.Result,
        round: result.GameCount % 10000,
        gameCard: result.GameCard,
        andarCards: andarCards,
        baharCards: baharCards,
        cardCount: cards.length,
        andarWin: isBitActive(result.Result, BetType.ABBAndarWin),
        baharWin: isBitActive(result.Result, BetType.ABBBaharWin),
        winBetType: getWinBetType(result.Result),
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    FResult: bigint,
    result?: number
): AndarBaharRecord => {
    const { GameID, Result, GameCount } = currentResult;
    const results = resultString.split(',').map(s => Number(s));
    const updateResult: AndarBaharResult = Object.assign(
        {
            FResult: FResult.toString(),
            GameCount: GameCount,
            GameID: GameID,
            AndarBahar: results.slice(1),
            Result: result ?? Result,
            Joker: -1,
        },
        convertFResult(BigInt(FResult)),
        //override game card by using result string instead of FResult
        { GameCard: results[0] }
    );

    const resultRecord = convertResultToRecord(updateResult);

    return resultRecord;
};

export const convertFResult = (gameResult: bigint) => {
    let gameCard: number = 0;
    let betTypeWin: boolean[] = [];
    let first3Result: number = 0;
    let ncAndarResult: number = 0;

    let byteArray = new ByteArray();
    byteArray.writeUint64(gameResult);
    let bitArray = new BitArray(byteArray);
    bitArray.position = 0;
    for (let i = 0; i <= BetType.ABB36To49; i++) {
        betTypeWin[i] = bitArray.readBoolean();
    }
    gameCard = bitArray.readNumber(6);

    let start = BetType.ABB36To40;
    for (let i = 0; i <= BetType.ABB46To49 - start; i++) {
        betTypeWin[start + i] = bitArray.readBoolean();
    }
    first3Result = bitArray.readNumber(2);
    betTypeWin[BetType.ABBFirst3] = first3Result > 0;

    start = BetType.ABBFirstAndar;
    for (let i = 0; i <= BetType.ABBFirstBahar - start; i++) {
        betTypeWin[start + i] = bitArray.readBoolean();
    }
    ncAndarResult = bitArray.readNumber(2);
    betTypeWin[BetType.ABBNCAndarWin] = ncAndarResult > 0;
    start = BetType.ABBNCBaharWin;
    for (let i = 0; i <= BetType.ABBMaxBet - start; i++) {
        betTypeWin[start + i] = bitArray.readBoolean();
    }

    return {
        GameCard: gameCard,
        BetTypeWin: betTypeWin,
        First3Result: first3Result,
        NcAndarResult: ncAndarResult,
    };
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }
    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Idle:
            return LocalState.SHUFFLING;
        case GameState.Shuffle:
            return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
            return LocalState.DEALING;
        case AndarBaharGameState.AndarWin:
            return isGameResultReleased
                ? LocalState.PLAYER
                : LocalState.DEALING;
        case AndarBaharGameState.BaharWin:
            return isGameResultReleased
                ? LocalState.BANKER
                : LocalState.DEALING;
    }
    return LocalState.DEALING;
};
