import { CMDBaseResult } from "./CMDBaseResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDLottery implements ICommand
{

	public BaseResult : CMDBaseResult;
	public StartBetTime : number = 0;
	public EndBetTime : number = 0;
	public DrawTime : number = 0;
	public Ball : Array<number>;

	public constructor()
	{
		this.Ball = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseResult = new CMDBaseResult();
		this.BaseResult.fromBytes(ba);
		this.StartBetTime = ba.readInt53();
		this.EndBetTime = ba.readInt53();
		this.DrawTime = ba.readInt53();
		let BallSize:number = ba.readUint8();
		while (BallSize-- > 0)
		{
			let BallChild:number = ba.readUint8();
			this.Ball.push(BallChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint53(this.StartBetTime);
		ba.writeUint53(this.EndBetTime);
		ba.writeUint53(this.DrawTime);
		ba.writeUint8(this.Ball.length);
		for (const BallChild of this.Ball)
		{
			ba.writeUint8(BallChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDLottery();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseResult(CMDBaseResult)='+this.BaseResult+' ';
		str += 'StartBetTime(Q)='+this.StartBetTime+' ';
		str += 'EndBetTime(Q)='+this.EndBetTime+' ';
		str += 'DrawTime(Q)='+this.DrawTime+' ';
		str += 'Ball_size(B)='+this.Ball.length + ' ';
		str += 'Ball(B)=[';
		for (let BallChild in this.Ball)
		{
			if (<number><unknown>BallChild > 0) str += ', ';
			str += this.Ball[BallChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Lottery';
	}

}
