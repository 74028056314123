import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RootState } from '../../../../store/store';
import { getPromotionToolState } from '../../../main/selector';
import { calcLowHigh, numberFormat } from '../../../../utils/commonFunc';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useUserAgent } from '../../../../hooks/useUserAgent';

export type PromotionBubbleProps = {
    direction: 'top' | 'right' | 'bottom' | 'left';
    spacing: number;
    color?: string;
    ratio?: number;
    zIndex?: number;
};
export const PromotionBubble = (props: PromotionBubbleProps) => {
    const promptDuration = 4 * 1000;
    const fixedHeight = 20;
    // const { os } = useUserAgent();
    const { Point } = useSelector((state: RootState) =>
        getPromotionToolState(state)
    );
    const [initialized, setInitialized] = useState<boolean>(false);
    const [originalEventPoint, setOriginalEventPoint] = useState<number>(0);
    const [eventPoint, setEventPoint] = useState<string>(numberFormat(0));
    const [direction, setDirection] = useState<string>('left');
    const [spacing, setSpacing] = useState<number>(0);
    const [bubbleZIndex, setBubbleZIndex] = useState<number | undefined>(
        undefined
    );
    const [customStyle, setCustomStyle] = useState<CSSProperties>({});
    const [isShow, setIsShow] = useState<boolean>(false);
    const bubbleRef = useRef<HTMLDivElement>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [renderRatio, setRenderRatio] = useState<number>(1);

    // const setTextMarginTop = (): string => {
    //     return os.name?.toLowerCase() === 'ios' ? '-2.5px' : '-1px';
    // };

    useEffect(() => {
        if (isShow) {
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setIsShow(false);
            }, promptDuration);

            return () => {
                if (timerRef.current) clearTimeout(timerRef.current);
            };
        }
    }, [isShow]);

    //setting initialization
    useEffect(() => {
        setDirection(props.direction);
        setSpacing(props.spacing);
        if (props.zIndex) setBubbleZIndex(props.zIndex);
    }, []);

    useEffect(() => {
        if (props.ratio) setRenderRatio(props.ratio * 0.9);
    }, [props.ratio]);

    useEffect(() => {
        if (bubbleRef.current && isShow) {
            let bubbleOffset: number = 0;
            switch (direction) {
                case 'left':
                case 'right':
                    bubbleOffset = bubbleRef.current.offsetWidth;
                    break;
                case 'top':
                case 'bottom':
                    bubbleOffset = bubbleRef.current.offsetHeight;
                    break;
            }

            let extraSx = {
                [direction]: `-${spacing + bubbleOffset + 5}px `,
                zIndex: bubbleZIndex ? bubbleZIndex : 'unset',
            };
            setCustomStyle(extraSx);
        }
    }, [isShow, Point]);

    //Preventing invalid bubble popup
    useEffect(() => {
        if (!initialized) setInitialized(true);
    }, [originalEventPoint]);

    useEffect(() => {
        const pts = calcLowHigh(Point);
        let difference = 0;
        if (pts > 0 && pts !== originalEventPoint) {
            difference = pts - originalEventPoint;
            if (difference !== 0 && initialized) setIsShow(true);
            setOriginalEventPoint(pts);
        }
        const sign = difference < 0 ? '' : '+';
        setEventPoint(`${sign}${numberFormat(difference / 100, 2)}`);
    }, [Point]);

    return (
        <>
            {isShow && (
                <Box
                    ref={bubbleRef}
                    sx={{
                        ...customStyle,
                        position: 'absolute',
                        minHeight: `${fixedHeight}px`,
                        maxHeight: `${fixedHeight}px`,
                        backgroundColor: 'rgba(191, 154, 93, 1)',
                        borderRadius: '2px',
                        padding: '0 5px',
                        zoom: renderRatio,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {direction === 'top' && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '0',
                                height: '0',
                                left: '52%',
                                transform: 'translateX(-50%)',
                                bottom: '-5px',
                                borderLeft: '5px solid transparent',
                                borderRight: '5px solid transparent',
                                borderTop: '6px solid rgba(191, 154, 93, 1)',
                            }}
                        ></Box>
                    )}
                    {direction === 'bottom' && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '0',
                                height: '0',
                                left: '52%',
                                transform: 'translateX(-50%)',
                                top: '-5px',
                                borderLeft: '5px solid transparent',
                                borderRight: '5px solid transparent',
                                borderBottom: '6px solid rgba(191, 154, 93, 1)',
                            }}
                        ></Box>
                    )}
                    {direction === 'left' && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '0',
                                height: '0',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                right: '-5px',
                                borderTop: '5px solid transparent',
                                borderBottom: '5px solid transparent',
                                borderLeft: '6px solid rgba(191, 154, 93, 1)',
                            }}
                        ></Box>
                    )}
                    {direction === 'right' && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '0',
                                height: '0',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                left: '-5px',
                                borderTop: '5px solid transparent',
                                borderBottom: '5px solid transparent',
                                borderRight: '6px solid rgba(191, 154, 93, 1)',
                            }}
                        ></Box>
                    )}
                    <Typography
                        fontSize={Math.min(20 * renderRatio, 20)}
                        sx={{
                            // marginTop: setTextMarginTop(),
                            color: props.color ? props.color : '#ffffff',
                            // transform: `translateY(-${2 * renderRatio}%)`,
                        }}
                    >{`${eventPoint}`}</Typography>
                </Box>
            )}
        </>
    );
};
