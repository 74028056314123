import { LobbyRoom } from '@/models/lobby/LobbyRoom';
import { RootState } from '@/store/store';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const LOBBY_ROOM_KEY = 'app::lobby::room';
export const lobbyRoomAdapter = createEntityAdapter<LobbyRoom, number>({
    selectId: lr => lr.HostId,
    sortComparer: (a, b) => a.HostId - b.HostId,
});
const initialLobbyRoomState = lobbyRoomAdapter.getInitialState();
const lobbyRoomSlice = createSlice({
    name: LOBBY_ROOM_KEY,
    initialState: initialLobbyRoomState,
    reducers: {
        init: lobbyRoomAdapter.upsertOne,
        update: lobbyRoomAdapter.updateOne,
    },
});
export const lobbyRoomSliceReducer = lobbyRoomSlice.reducer;
export const lobbyRoomSliceActions = lobbyRoomSlice.actions;
// selector
export const getLobbyRoomSelectors = lobbyRoomAdapter.getSelectors<RootState>(
    state => state[LOBBY_ROOM_KEY]
);
export const getLobbyRoom = getLobbyRoomSelectors.selectById;
