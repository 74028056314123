import { Box, Typography } from '@mui/material';
import { RankingProps } from './PromotionRanking';
import { numberFormat } from '../../../../utils/commonFunc';
import { useTranslation } from 'react-i18next';
import { getPromotionTool } from '@/components/images/base64/PromotionTool';
import { promotionRanking } from '../PromotionObjectMapper';

type PromotionRankingCellProps = {
    ranking: RankingProps;
};
export const PromotionRankingCell = (props: PromotionRankingCellProps) => {
    const { t } = useTranslation();
    const ranking = props.ranking;
    const first3 = ranking.Rank > 0 && ranking.Rank <= 3;
    const isSelf = ranking.Self;

    const getFontColor = () => {
        return isSelf || ranking.Rank == 1 ? '#ffffff' : '#979797';
    };

    const getBackgroundColor = () => {
        return isSelf ? 'rgba(10, 89, 88, 1)' : 'rgba(0, 0, 0, 0)';
    };

    return (
        <>
            <Box
                className="ranking-list-item"
                sx={{
                    backgroundColor: getBackgroundColor(),
                }}
            >
                <Box className="ranking-item-rank">
                    {first3 ? (
                        // <BaseImage
                        //     className={`promotion_tool event_no_${ranking.Rank}`}
                        //     scale={1}
                        // />
                        <Box>
                            {getPromotionTool(
                                promotionRanking[`event_no_${ranking.Rank}`]
                            )}
                        </Box>
                    ) : (
                        <Typography
                            sx={{
                                minWidth: '48px',
                                minHeight: '48px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '20px',
                                color: getFontColor(),
                            }}
                        >
                            {ranking.Rank.toString()}
                        </Typography>
                    )}
                </Box>
                <Typography
                    className="ranking-item-player"
                    sx={{
                        color: getFontColor(),
                    }}
                >
                    {isSelf
                        ? t('promotion_tool.rankings_you')
                        : ranking.Username}
                </Typography>
                <Typography
                    className="ranking-item-amount"
                    sx={{
                        color: getFontColor(),
                    }}
                >
                    {numberFormat(ranking.WinAmount / 100)}
                </Typography>
            </Box>
            {!ranking.LastRank ? (
                <Box
                    sx={{
                        backgroundColor: '#ab8b56',
                        // width: isLandscape ? '1276px' : '476px',
                        width: '100%',
                        height: '1px',
                        opacity: '0.2',
                        // marginLeft: '12px',
                        // marginRight: '12px',
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
};
