import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { getHostByGameId, hostsSliceActions } from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { GameType as GameTypeEnum } from '../../games/enums/GameType';
import { CMDScRestart, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScRestartHandler extends AbstractHandler {
    _command: CMDScRestart;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScRestart;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const gameId = this._command.GameID;
        const state = store.getState();
        const host = getHostByGameId(state, gameId);
        const restartTimes:number = host.IsRestart? host.IsRestart+1: 1;

        if (host) {
            switch (host.GameType) {
                case GameTypeEnum.SeDie:
                case GameTypeEnum.SicBo:
                case GameTypeEnum.Roulette:
                    batch(() => {
                        dispatch(
                            hostsSliceActions.updateGameState({
                                hostId: host.HostId,
                                state: GameState.Restart
                            })
                        );
                        dispatch(
                            hostsSliceActions.updateIsRestart({
                                hostId: host.HostId,
                                isRestart: restartTimes
                            })
                        );
                    });
                    // dispatch(
                    //     popupSliceActions.open(
                    //         'sedie.reshake',
                    //         undefined,
                    //         POPUP_TYPE.POPUP,
                    //         2000
                    //     )
                    // );
                    //     break;
                    // case GameTypeEnum.Roulette:
                    // dispatch(
                    //     popupSliceActions.open(
                    //         'roulette.respin',
                    //         undefined,
                    //         POPUP_TYPE.POPUP,
                    //         2000
                    //     )
                    // );
                    break;
            }
        }
    }
}
