import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPromotionRank implements ICommand
{

	public Rank : number = 0;
	public PlayerID : number = 0;
	public Username : string = "";
	public WinAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Rank = ba.readUint16();
		this.PlayerID = ba.readInt53();
		this.Username = ba.readString();
		this.WinAmount = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.Rank);
		ba.writeUint53(this.PlayerID);
		ba.writeString(this.Username);
		ba.writeUint53(this.WinAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPromotionRank();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Rank(W)='+this.Rank+' ';
		str += 'PlayerID(Q)='+this.PlayerID+' ';
		str += 'Username(S)='+this.Username+' ';
		str += 'WinAmount(Q)='+this.WinAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'PromotionRank';
	}

}
