import { Init } from './Init';

export enum DealtTurn {
    WaitSeatPlayerFirstDecision = Number(Init.LastInitState),
    WaitSideBetPlayerFollow = WaitSeatPlayerFirstDecision + 1,
    WaitSplitFirstCard = WaitSideBetPlayerFollow + 1,
    WaitSplitSecondCard = WaitSplitFirstCard + 1,
    WaitBankerSecondCard = WaitSplitSecondCard + 1,
    WaitThirdCard = WaitBankerSecondCard + 1,
    LoopStart = WaitThirdCard + 1,
}
