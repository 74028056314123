import { CommonConfigContext } from '@/contexts/ConfigContext';
import { WebSocketContext } from '@/contexts/WebSocketContext';
import { CMDPsTokenUpdate } from '@/models/cmd/live';
import { getGameState } from '@/modules/games/selector';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMainPlayerState, getMainUserState } from '../modules/main/selector';
import { calcLowHigh, numberFormat } from '../utils/commonFunc';
import { useGameFeature } from './useGameFeature';

export const useBalance = () => {
    const { Balance, HoldingMoney, BalanceValid } =
        useSelector(getMainPlayerState);
    const { showCurrencyName: currencyConfig } =
        useContext(CommonConfigContext);
    const { CurrencyName: playerCurrencyName, LobbyCode: parentLobbyCode } =
        useSelector(getMainUserState);
    const [displayBalance, setDisplayBalance] = useState('-');
    const [balance, setBalance] = useState(0);
    const [currency, setCurrency] = useState(playerCurrencyName);
    const { sendCommand } = useContext(WebSocketContext);

    //for ABC pending amount
    const { isAutoConfirmEnabled } = useGameFeature(false);
    const { totalHostsBetInfo } = useSelector(getGameState);
    useEffect(() => {
        const currentBalance = calcLowHigh(Balance);
        const currentHoldingMoney = calcLowHigh(HoldingMoney);

        // init the display balance before reading common config
        const b = BalanceValid
            ? (currentBalance - currentHoldingMoney) / 100
            : 0;
        // fix negative balance issue
        if (b < 0) {
            const tokenUpdate = new CMDPsTokenUpdate();
            sendCommand(tokenUpdate);
            setDisplayBalance('-');
        }
        setBalance(b);
    }, [Balance, HoldingMoney]);
    useEffect(() => {
        // remove '2' in the currency name
        const currencyName = playerCurrencyName?.replace(/[2]/g, '') ?? '';
        setCurrency(currencyName);
        // show currency name logic
        try {
            if (currencyConfig) {
                const lobbyCode = parentLobbyCode.toLocaleLowerCase();
                for (const cc of currencyConfig) {
                    const excludeCurrency = cc['exCurrency'] ?? [];
                    const includedLobby = cc['include'] ?? [];
                    const excludeLobby = cc['exclude'] ?? [];

                    const isMatched = excludeCurrency.includes(currencyName);

                    if (isMatched) {
                        const isMatchedAll = excludeCurrency[0] === 'all';
                        const isMatchedInclude = !includedLobby
                            .map(l => l.toLocaleLowerCase())
                            .includes(lobbyCode);
                        const isMatchedExclude = excludeLobby
                            .map(l => l.toLocaleLowerCase())
                            .includes(lobbyCode);
                        if (
                            isMatchedAll ||
                            isMatchedInclude ||
                            isMatchedExclude
                        ) {
                            // remove the currency name
                            setCurrency('');
                        }
                    }
                }
            }
        } catch (err) {
            console.error(
                'hook::use::balance',
                'cannot parse common config',
                err
            );
        }
    }, [playerCurrencyName, parentLobbyCode, currencyConfig]);
    useEffect(() => {
        let db = numberFormat(balance);
        if (isAutoConfirmEnabled && totalHostsBetInfo) {
            const pending =
                (totalHostsBetInfo.pendingBetAmount +
                    totalHostsBetInfo.pendingWithHoldAmount) /
                100;
            db = numberFormat(Math.max(balance - pending), 0);
        }
        // if (currency !== '') db += ` ${currency}`;
        if (db.length > 30) db = db.replace(' ', '\n');
        setDisplayBalance(BalanceValid ? db : '-');
    }, [balance, currency, isAutoConfirmEnabled, totalHostsBetInfo]);
    return { displayBalance, currency, balance };
};
