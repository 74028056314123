import { Bet } from '@/models/host/BetAmount';
import { BetType as BetTypeEnum } from '../../../models/games/teenpatti/BetType';
import { getBetTypeGroupTotal } from '../baccarat/utils';

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    if (betsSummary === undefined) {
        return false;
    }
    let oppositeType = -1;
    switch (betType) {
        case BetTypeEnum.TPBPlayerAWin:
            oppositeType = BetTypeEnum.TPBPlayerBWin;
            break;
        case BetTypeEnum.TPBPlayerBWin:
            oppositeType = BetTypeEnum.TPBPlayerAWin;
            break;
    }

    if (oppositeType < 0) {
        return false;
    }
    return (
        betsSummary[oppositeType] != undefined && betsSummary[oppositeType] > 0
    );
};

export const getTeenPattiCurrentTotal = (
    betType: number,
    BetsSummary: Array<number>,
    ConfirmedBets: Array<number>
) => {
    switch (betType) {
        case BetTypeEnum.TPBPlayerAWin:
            return (
                getBetTypeGroupTotal(
                    [BetTypeEnum.TPBPlayerAWin],
                    BetsSummary,
                    ConfirmedBets
                ) -
                getBetTypeGroupTotal(
                    [BetTypeEnum.TPBPlayerBWin],
                    BetsSummary,
                    ConfirmedBets
                )
            );
        case BetTypeEnum.TPBPlayerBWin:
            return (
                getBetTypeGroupTotal(
                    [BetTypeEnum.TPBPlayerBWin],
                    BetsSummary,
                    ConfirmedBets
                ) -
                getBetTypeGroupTotal(
                    [BetTypeEnum.TPBPlayerAWin],
                    BetsSummary,
                    ConfirmedBets
                )
            );
        default:
            return getBetTypeGroupTotal([betType], BetsSummary, ConfirmedBets);
    }
};

export const getTeenPatti2020HedgeBettingBets = (bets: Array<Bet>) => {
    const newBets = new Array<Bet>();
    let playerATotal = 0;
    let playerBTotal = 0;
    const latestGameId = bets.at(-1)?.GameId ?? 0;
    for (const bet of bets) {
        switch (bet.Type) {
            case BetTypeEnum.TPBPlayerAWin:
                playerATotal += bet.Amount;
                break;
            case BetTypeEnum.TPBPlayerBWin:
                playerBTotal += bet.Amount;
                break;
            default:
                newBets.push(bet);
                break;
        }
    }
    const diff = playerATotal - playerBTotal;
    if (diff !== 0) {
        const betType =
            diff > 0 ? BetTypeEnum.TPBPlayerAWin : BetTypeEnum.TPBPlayerBWin;
        const betAmount = Math.abs(diff);
        const newBet: Bet = {
            GameId: latestGameId,
            Type: betType,
            Amount: betAmount,
        };
        newBets.push(newBet);
    }

    return newBets;
};
