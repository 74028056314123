import { Dispatch, AnyAction } from 'redux';
import { CMDSpRouletteStatistics, ICommand } from '../live';
import { RouletteStatistics } from '../../games/roulette';
import { hostsSliceActions } from '../../../modules/host/slice';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpRouletteStatisticsHandler extends AbstractHandler {
    _command: CMDSpRouletteStatistics;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpRouletteStatistics;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as RouletteStatistics;
        const hostId = this._command.HostID;
        statistics.BigCount = this._command.Statistics.BigCount;
        statistics.SmallCount = this._command.Statistics.SmallCount;
        statistics.EvenCount = this._command.Statistics.EvenCount;
        statistics.OddCount = this._command.Statistics.OddCount;
        statistics.RedCount = this._command.Statistics.RedCount;
        statistics.BlackCount = this._command.Statistics.BlackCount;
        statistics.Point0Count = this._command.Statistics.Point0Count;
        statistics.Point1Count = this._command.Statistics.Point1Count;
        statistics.Point2Count = this._command.Statistics.Point2Count;
        statistics.Point3Count = this._command.Statistics.Point3Count;
        statistics.Point4Count = this._command.Statistics.Point4Count;
        statistics.Point5Count = this._command.Statistics.Point5Count;
        statistics.Point6Count = this._command.Statistics.Point6Count;
        statistics.Point7Count = this._command.Statistics.Point7Count;
        statistics.Point8Count = this._command.Statistics.Point8Count;
        statistics.Point9Count = this._command.Statistics.Point9Count;
        statistics.Point10Count = this._command.Statistics.Point10Count;
        statistics.Point11Count = this._command.Statistics.Point11Count;
        statistics.Point12Count = this._command.Statistics.Point12Count;
        statistics.Point13Count = this._command.Statistics.Point13Count;
        statistics.Point14Count = this._command.Statistics.Point14Count;
        statistics.Point15Count = this._command.Statistics.Point15Count;
        statistics.Point16Count = this._command.Statistics.Point16Count;
        statistics.Point17Count = this._command.Statistics.Point17Count;
        statistics.Point18Count = this._command.Statistics.Point18Count;
        statistics.Point19Count = this._command.Statistics.Point19Count;
        statistics.Point20Count = this._command.Statistics.Point20Count;
        statistics.Point21Count = this._command.Statistics.Point21Count;
        statistics.Point22Count = this._command.Statistics.Point22Count;
        statistics.Point23Count = this._command.Statistics.Point23Count;
        statistics.Point24Count = this._command.Statistics.Point24Count;
        statistics.Point25Count = this._command.Statistics.Point25Count;
        statistics.Point26Count = this._command.Statistics.Point26Count;
        statistics.Point27Count = this._command.Statistics.Point27Count;
        statistics.Point28Count = this._command.Statistics.Point28Count;
        statistics.Point29Count = this._command.Statistics.Point29Count;
        statistics.Point30Count = this._command.Statistics.Point30Count;
        statistics.Point31Count = this._command.Statistics.Point31Count;
        statistics.Point32Count = this._command.Statistics.Point32Count;
        statistics.Point33Count = this._command.Statistics.Point33Count;
        statistics.Point34Count = this._command.Statistics.Point34Count;
        statistics.Point35Count = this._command.Statistics.Point35Count;
        statistics.Point36Count = this._command.Statistics.Point36Count;

        const vals = [
            statistics.Point0Count,
            statistics.Point1Count,
            statistics.Point2Count,
            statistics.Point3Count,
            statistics.Point4Count,
            statistics.Point5Count,
            statistics.Point6Count,
            statistics.Point7Count,
            statistics.Point8Count,
            statistics.Point9Count,
            statistics.Point10Count,
            statistics.Point11Count,
            statistics.Point12Count,
            statistics.Point13Count,
            statistics.Point14Count,
            statistics.Point15Count,
            statistics.Point16Count,
            statistics.Point17Count,
            statistics.Point18Count,
            statistics.Point19Count,
            statistics.Point20Count,
            statistics.Point21Count,
            statistics.Point22Count,
            statistics.Point23Count,
            statistics.Point24Count,
            statistics.Point25Count,
            statistics.Point26Count,
            statistics.Point27Count,
            statistics.Point28Count,
            statistics.Point29Count,
            statistics.Point30Count,
            statistics.Point31Count,
            statistics.Point32Count,
            statistics.Point33Count,
            statistics.Point34Count,
            statistics.Point35Count,
            statistics.Point36Count,
        ];
        const keys = [...'-'.repeat(vals.length)].map((_, num) => num);
        const asc = (a: number, b: number) => vals[a] - vals[b];
        const desc = (a: number, b: number) => vals[b] - vals[a];
        statistics.HotNums = keys.sort(desc).slice(0, 5);
        statistics.ColdNums = keys.sort(asc).slice(0, 5);

        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
