import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpPromotionRanking, ICommand } from "../../live";
import { AbstractHandler } from "../AbstractHandler";
import { PromotionState, promotionToolSliceActions } from '../../../../modules/main/slice/promotiontool/promotionTool';
import { batch } from 'react-redux';

export class CMDSpPromotionRankingHandler extends AbstractHandler {
    _command: CMDSpPromotionRanking;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPromotionRanking;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const cmd = this._command;
        // console.log(`command::handler::${cmd.getCmdName()}::${cmd.toString()}`, cmd);
        const data = {} as PromotionState;
        data.RankingUTCUpdateTime = cmd.UTCUpdateTime;
        data.RankCount = cmd.RankCount;
        data.PlayerRank = cmd.PlayerRank;
        data.PlayerAmount = cmd.PlayerAmount;
        data.RankData = cmd.RankData.map(list => JSON.stringify(list));
        
        batch(() => {
            dispatch(promotionToolSliceActions.updateRanking(data));
        });
    }
}