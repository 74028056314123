export enum GameState {
	WaitBurn = 2,
	WaitPlayer1Card1,
	WaitPlayer2Card1,
	WaitPlayer3Card1,
	WaitPlayer4Card1,
	WaitPlayer5Card1,
	WaitBankerCard1,

	WaitPlayer1Card2,
	WaitPlayer2Card2,
	WaitPlayer3Card2,
	WaitPlayer4Card2,
	WaitPlayer5Card2,
	WaitBankerCard2
}

export enum GameResultState {
	PDRS_NoHand,
	PDRS_PlayerWin,
	PDRS_BankerWin,
	PDRS_Tie,
}
