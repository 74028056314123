import { CMDBaseInformation } from "./CMDBaseInformation"
import { CMDBaccarat } from "./CMDBaccarat"
import { CMDLiveBaccaratData } from "./CMDLiveBaccaratData"
import { CMDSpBaccaratGoodRoad } from "./CMDSpBaccaratGoodRoad"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScInitBaccarat implements ICommand
{

	public Information : CMDBaseInformation;
	public OldResult : Array<CMDBaccarat>;
	public CurrentState : number = 0;
	public CurrentResult : CMDLiveBaccaratData;
	public GoodRoad : CMDSpBaccaratGoodRoad;

	public constructor()
	{
		this.OldResult = new Array<CMDBaccarat>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Information = new CMDBaseInformation();
		this.Information.fromBytes(ba);
		let OldResultSize:number = ba.readUint8();
		while (OldResultSize-- > 0)
		{
			let OldResultChild:CMDBaccarat = new CMDBaccarat();
			OldResultChild.fromBytes(ba);
			this.OldResult.push(OldResultChild);
		}
		this.CurrentState = ba.readUint16();
		this.CurrentResult = new CMDLiveBaccaratData();
		this.CurrentResult.fromBytes(ba);
		this.GoodRoad = new CMDSpBaccaratGoodRoad();
		this.GoodRoad.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Information.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.OldResult.length);
		for (const OldResultChild of this.OldResult)
		{
			let tempBuf = OldResultChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint16(this.CurrentState);
		let tempBuf4 = this.CurrentResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.GoodRoad.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScInitBaccarat();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Information(CMDBaseInformation)='+this.Information+' ';
		str += 'OldResult_size(B)='+this.OldResult.length + ' ';
		str += 'OldResult(CMDBaccarat)=[';
		for (let OldResultChild in this.OldResult)
		{
			if (<number><unknown>OldResultChild > 0) str += ', ';
			str += this.OldResult[OldResultChild].toString();
		}
		str += '] ';
		str += 'CurrentState(W)='+this.CurrentState+' ';
		str += 'CurrentResult(CMDLiveBaccaratData)='+this.CurrentResult+' ';
		str += 'GoodRoad(CMDSpBaccaratGoodRoad)='+this.GoodRoad+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30501;
	}

	public getCmdName() : string
	{
		return 'ScInitBaccarat';
	}

}
