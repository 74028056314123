import { ROUTE_MULTIBET } from '@/models/Route';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsMultiBet = () => {
    const location = useLocation();
    const isMultiBet = useMemo(
        () => location.pathname === ROUTE_MULTIBET,
        [location.pathname]
    );
    return isMultiBet;
};
