import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDTicketPrice implements ICommand
{

	public CurrencyName : string = "";
	public Price : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.CurrencyName = ba.readString();
		this.Price = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.CurrencyName);
		ba.writeUint53(this.Price);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDTicketPrice();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'CurrencyName(S)='+this.CurrencyName+' ';
		str += 'Price(Q)='+this.Price+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'TicketPrice';
	}

}
