import { GlobalDataContext } from '@/contexts/GlobalDataContext';
import { POPUP_TYPE } from '@/models/Popup';
import { SyncCMDAction } from '@/models/cmd/handler/AbstractHandler';
import { CMDScRoomLeftHandler } from '@/models/cmd/handler/CMDScRoomLeftHandler';
import { CMDSpBetLogSummaryQueryHandler } from '@/models/cmd/handler/CMDSpBetLogSummaryQueryHandler';
import { CMDSpBetWinDetailHandler } from '@/models/cmd/handler/CMDSpBetWinDetailHandler';
import { CMDSpInitNbsBetHandler } from '@/models/cmd/handler/CMDSpInitNbsBetHandler';
import { CMDSpNbsBetResultHandler } from '@/models/cmd/handler/CMDSpNbsBetResultHandler';
import { Buffer } from 'buffer';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CdnContext } from '../contexts/CdnContext';
import {
    CommonConfigContext,
    GameConfigContext,
} from '../contexts/ConfigContext';
import { Locale } from '../models/Locale';
import { ROUTE_LOBBY, ROUTE_RELOAD_LOBBY } from '../models/Route';
import { ByteArray } from '../models/cmd';
import {
    AbstractHandler,
    CMDScAckHandler,
    CMDScAnchorLoginHandler,
    CMDScBlackjackSeatInfoHandler,
    CMDScGameRestHandler,
    CMDScGameResultHandler,
    CMDScGameStateHandler,
    CMDScInitBaccaratHandler,
    CMDScInitBlackjackHandler,
    CMDScInitDragontigerHandler,
    CMDScInitPokDengHandler,
    CMDScInitRouletteHandler,
    CMDScPlayerCountHandler,
    CMDScPokerShowHandler,
    CMDScRoundCancelHandler,
    CMDScRoundResetHandler,
    CMDScShuffleEndHandler,
    CMDScShuffleHandler,
    CMDScStartBetHandler,
    CMDScStartGameHandler,
    CMDScSyncDataHandler,
    CMDScTotalBetBaccaratHandler,
    CMDScTotalBetBlackjackHandler,
    CMDScTotalBetDragontigerHandler,
    CMDScTotalBetPokdengHandler,
    CMDSpBaccaratGoodRoadHandler,
    CMDSpBaccaratStatisticsHandler,
    CMDSpBetLogQueryHandler,
    CMDSpBetLogQueryTotalHandler,
    CMDSpBetResultHandler,
    CMDSpBetWinHandler,
    CMDSpChangePasswordReturnHandler,
    CMDSpDragontigerStatisticsHandler,
    CMDSpForceStandHandler,
    CMDSpGiftSettingHandler,
    CMDSpHostListHandler,
    CMDSpInitBetHandler,
    CMDSpInitMaxBetHandler,
    CMDSpKeyRefreshHandler,
    CMDSpLimitRedUpdateHandler,
    CMDSpLivePlayerCountHandler,
    CMDSpLoginHandler,
    CMDSpPlayerRecentlyPlayHandler,
    CMDSpPlayerReportHandler,
    CMDSpPokDengStatisticsHandler,
    CMDSpResultHandler,
    CMDSpRoomBetHandler,
    CMDSpRouletteStatisticsHandler,
    CMDSpServerResetHandler,
    LoginFailHandler,
    NotImplementHandler,
    ObsoleteHandler,
} from '../models/cmd/handler';
import { CMDScInitAndarBaharHandler } from '../models/cmd/handler/CMDScInitAndarBaharHandler';
import { CMDScInitRoomHandler } from '../models/cmd/handler/CMDScInitRoomHandler';
import { CMDScInitSedieHandler } from '../models/cmd/handler/CMDScInitSedieHandler';
import { CMDScInitSicboHandler } from '../models/cmd/handler/CMDScInitSicboHandler';
import { CMDScInitSyncHandler } from '../models/cmd/handler/CMDScInitSyncHandler';
import { CMDScInitTeenPattiHandler } from '../models/cmd/handler/CMDScInitTeenPattiHandler';
import { CMDScPlayerBetHandler } from '../models/cmd/handler/CMDScPlayerBetHandler';
import { CMDScRemoveSyncHandler } from '../models/cmd/handler/CMDScRemoveSyncHandler';
import { CMDScRestartHandler } from '../models/cmd/handler/CMDScRestartHandler';
import { CMDScRoomSeatHandler } from '../models/cmd/handler/CMDScRoomSeatHandler';
import { CMDScTotalBetAndarBaharHandler } from '../models/cmd/handler/CMDScTotalBetAndarBaharHandler';
import { CMDScTotalBetRouletteHandler } from '../models/cmd/handler/CMDScTotalBetRouletteHandler';
import { CMDScTotalBetSedieHandler } from '../models/cmd/handler/CMDScTotalBetSedieHandler';
import { CMDScTotalBetSicboHandler } from '../models/cmd/handler/CMDScTotalBetSicboHandler';
import { CMDScTotalBetTeenPattiHandler } from '../models/cmd/handler/CMDScTotalBetTeenPattiHandler';
import { CMDSpAndarBaharStatisticsHandler } from '../models/cmd/handler/CMDSpAndarBaharStatisticsHandler';
import { CMDSpRuleChangeHandler } from '../models/cmd/handler/CMDSpRuleChangeHandler';
import { CMDSpSedieStatisticsHandler } from '../models/cmd/handler/CMDSpSedieStatisticsHandler';
import { CMDSpSicboStatisticsHandler } from '../models/cmd/handler/CMDSpSicboStatisticsHandler';
import { CMDSpTeenPattiStatisticsHandler } from '../models/cmd/handler/CMDSpTeenPattiStatisticsHandler';
import { CMDSpPromotionEventHandler } from '../models/cmd/handler/promotiontool/CMDSpPromotionEventHandler';
import { CMDSpPromotionRankingHandler } from '../models/cmd/handler/promotiontool/CMDSpPromotionRankingHandler';
import { CMDSpPromotionRedeemLogHandler } from '../models/cmd/handler/promotiontool/CMDSpPromotionRedeemLogHandler';
import { CMDSpPromotionStateHandler } from '../models/cmd/handler/promotiontool/CMDSpPromotionStateHandler';
import { CMDSpRedeemResultHandler } from '../models/cmd/handler/promotiontool/CMDSpRedeemResultHandler';
import {
    CMDCsAck,
    CMDPsDisconnect,
    CMDPsLogin,
    CMDPsNbsBet,
    CMDPsRequestInitClient,
    Command,
    ICommand,
    getCommand,
} from '../models/cmd/live';
import { GameError } from '../models/games/enums/GameError';
import { getGameState } from '../modules/games/selector';
import { gameSliceActions } from '../modules/games/slice';
import { getLandingState, getMainUserState } from '../modules/main/selector';
import { limitRedSliceActions } from '../modules/main/slice/limitRed';
import { CountryMessage, popupSliceActions } from '../modules/popup/slice';
import { loadingProgressSliceActions } from '../modules/progress/slice/loading';
import { resultProgressSliceActions } from '../modules/progress/slice/result';
import { progressTimeActions } from '../modules/progress/slice/time';
import { routeSliceActions } from '../modules/routeController/slice';
import { LocalStorageKey } from './storage/useLocalStorage';
import { useBetSource } from './useBetSource';
import { useDocumentVisible } from './useDocumentVisible';
import { useGameFeature } from './useGameFeature';
import { useNavigatorOnLine } from './useNavigatorOnLine';
const PackageSize = {
    HEAD: 1,
    BODY: 4,
    CMD: 2,
    PAYLOAD: 4,
};
const enum BufferProcess {
    Waiting = 0,
    Error = 1,
    Success = 2,
}
type ParseState = [BufferProcess, number | undefined, Buffer | undefined];

type delayRunCMD = {
    timeout: NodeJS.Timeout;
    handler: AbstractHandler;
};
const parseCommand = (ba: Buffer): ParseState => {
    if (ba.length < PackageSize.HEAD) {
        return [BufferProcess.Waiting, undefined, undefined];
    }

    let pos = 0;
    let head = ba.readUint8();
    pos = pos + PackageSize.HEAD;

    if (head !== 0xaa) {
        return [BufferProcess.Error, undefined, undefined];
    }

    if (ba.length < PackageSize.HEAD + PackageSize.BODY) {
        return [BufferProcess.Waiting, undefined, undefined];
    }

    let pkgSize = ba.readUint32LE(pos);
    pos = pos + PackageSize.BODY;

    if (ba.length < pkgSize + PackageSize.HEAD + PackageSize.BODY) {
        return [BufferProcess.Waiting, undefined, undefined];
    }
    let cmd = ba.readUint16LE(pos);
    pos = pos + PackageSize.CMD;

    let payloadSize = ba.readUint32LE(pos);
    pos = pos + PackageSize.PAYLOAD;

    let data = ba.subarray(pos, payloadSize + pos);

    ba = ba.subarray(pos + payloadSize);

    return [BufferProcess.Success, cmd, data];
};

const getRandomIndex = (length: number) => Math.floor(Math.random() * length);

const RETRY_MAX_COUNT = 10;
const SOCKET_TIMEOUT = 10000;
const useLiveGameSocket = () => {
    const { i18n } = useTranslation();
    const betSource = useBetSource();
    const socket = useRef<WebSocket>();
    let firstHostList = useRef<boolean>(true);
    let hasLogin = useRef<boolean>(false);
    let loginState = useRef<number>(0);
    let connectedUrl = useRef<string>();
    let lastRecv = useRef<number>();
    let checkRecvTimer = useRef<NodeJS.Timeout>();
    const { ts, lobby, referer } = useSelector(getLandingState);
    const dispatch = useDispatch();
    const { lobbyServer, lobbyPort } = useContext(CdnContext);
    const { hosts } = useContext(GameConfigContext);
    const { productionTestPermission, dafaBetPermission } =
        useContext(CommonConfigContext);
    const [online, setOnline] = useState(false);
    const [checkNetwork, setCheckNetwork] = useState(0);
    const [notToReconnect, setNotToReconnect] = useState(false);
    const [retry, setRetry] = useState(0);
    const [lastRetry, setLastRetry] = useState(0);
    const [retryToken, setRetryToken] = useState<string>();
    const [pendingRetryToken, setPendingRetryToken] = useState<string>();
    const [handleSocketClose, setHandleSocketClose] = useState<number>(0);
    const isBrowserActive = useDocumentVisible();
    const { LoginToken, LobbyCode } = useSelector(getMainUserState);
    const { msg } = useContext(CommonConfigContext);
    const { setSyncCMDAction } = useContext(GlobalDataContext);
    const [specialMsg, setSpecialMsg] = useState<string>('');
    let haveToken = useRef<boolean>(false); //for socket close call by other loginToken data missing
    const gameState = useSelector(getGameState);
    const onlineState = useNavigatorOnLine();
    const { isAutoConfirmEnabled } = useGameFeature(false);
    let _delayStore: Map<string, Array<delayRunCMD>> = new Map<
        string,
        Array<delayRunCMD>
    >();
    const checkTimeout = () => {
        const now = moment().valueOf();
        if (lastRecv.current) {
            if (now - lastRecv.current > SOCKET_TIMEOUT) {
                console.log(
                    `hook::socket::timeout::${now - lastRecv.current}ms not recv cmd`
                );
                //server slow response in sp_login cause error 26 with 10s
                socketClose();
            }
        }
    };
    const isMessageExist = (key: string) => {
        return i18n.exists(key);
    };
    const startTimer = () => {
        console.log('hook::socket::timeout::start');
        if (checkRecvTimer.current) clearInterval(checkRecvTimer.current);
        const id = setInterval(() => {
            checkTimeout();
        }, SOCKET_TIMEOUT);
        checkRecvTimer.current = id;
    };
    const clearTimer = () => {
        console.log('hook::socket::timeout::clear');
        lastRecv.current = 0;
        if (checkRecvTimer.current) clearInterval(checkRecvTimer.current);
    };
    const showCountryMessage = (data: CountryMessage) => {
        dispatch(
            popupSliceActions.open(
                data.messageKey,
                POPUP_TYPE.LEAVE_ALERT,
                undefined,
                undefined,
                data.params
            )
        );
    };
    const requestCountryName = (data: CountryMessage | undefined) => {
        if (data && data.params) {
            const url = process.env.PUBLIC_URL;
            const controller = new AbortController();
            const timeoutID = setTimeout(() => controller.abort(), 10000);
            fetch(`${url}/countryCodeMap.json`, {
                signal: controller.signal,
            })
                .then(response => {
                    if (response.ok) {
                        clearTimeout(timeoutID);
                        response.text().then(raw => {
                            if (
                                data &&
                                data.params &&
                                data.params.countryCode
                            ) {
                                const json = JSON.parse(raw);
                                const lang =
                                    Locale[i18n.language.replace('-', '_')];
                                let countryName =
                                    json[lang.map_code][
                                        data.params.countryCode
                                    ];
                                data.params = {
                                    ip: data.params?.ip,
                                    currency: data.params?.currency,
                                    country: countryName
                                        ? countryName
                                        : data.params.countryCode,
                                };
                                showCountryMessage(data);
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log('cannot get country name', err);
                    data.params = {
                        ip: data.params?.ip ?? '',
                        currency: data.params?.currency ?? '',
                        country: data.params?.countryCode ?? '',
                    };
                    showCountryMessage(data);
                });
        }
    };
    // socket
    const login = (token: string, isReconnect: boolean = false) => {
        console.log(
            isReconnect,
            token,
            hasLogin.current,
            socket.current?.readyState,
            checkNetwork,
            online
        );
        socketInit(token, isReconnect);
    };
    useEffect(() => {
        if (msg && msg.lobby) {
            if (LobbyCode) {
                if (msg.lobby.indexOf(LobbyCode) >= 0) {
                    setSpecialMsg(msg.sub);
                }
            } else if (lobby) {
                if (msg.lobby.indexOf(lobby) >= 0) {
                    setSpecialMsg(msg.sub);
                }
            }
        }
    }, [LobbyCode, msg, lobby]);
    useEffect(() => {
        if (LoginToken != undefined) {
            haveToken.current = true;
            loginState.current = 3;
        }
        setPendingRetryToken(undefined);
        setRetryToken(undefined);
    }, [LoginToken]);
    useEffect(() => {
        if (onlineState && isBrowserActive) {
            if (pendingRetryToken) {
                setPendingRetryToken(undefined);
                setRetryToken(pendingRetryToken);
            } else {
                setPendingRetryToken(undefined);
                setRetryToken(LoginToken);
            }
        }
    }, [onlineState]);
    useEffect(() => {
        if (checkNetwork) {
            if (socket.current !== undefined) {
                if (socket.current?.readyState == WebSocket.CLOSED) {
                    if (LoginToken && onlineState && isBrowserActive) {
                        setPendingRetryToken(undefined);
                        setRetryToken(LoginToken);
                    }
                    setOnline(false);
                } else if (socket.current?.readyState == WebSocket.CLOSING) {
                    setOnline(false);
                }
            } else {
                console.log('hook::socket::current::null::checkNetwork');
                if (retryToken && haveToken.current) {
                    socketCreate(retryToken, true);
                }
            }
        }
    }, [checkNetwork, pendingRetryToken]);
    useEffect(() => {
        setNotToReconnect(gameState.notToReconnect);
    }, [gameState.notToReconnect]);
    useEffect(() => {
        console.log(
            'hook::socket::isBrowserActive',
            retryToken,
            onlineState,
            isBrowserActive,
            pendingRetryToken
        );
        if (isBrowserActive) {
            setCheckNetwork(v => v + 1);
        }
    }, [isBrowserActive]);
    useEffect(() => {
        console.log(
            'hook::socket::retryToken',
            retryToken,
            onlineState,
            isBrowserActive,
            pendingRetryToken
        );
        if (onlineState && retryToken && isBrowserActive) {
            const offset = moment().valueOf() - lastRetry;
            const count = retry + 1;
            if (offset < 1000) {
                const timer = setTimeout(() => {
                    clearTimeout(timer);
                    setRetry(count);
                }, 1000 - offset);
            } else {
                setRetry(count);
            }
        }
    }, [retryToken]);
    useEffect(() => {
        console.log(
            'hook::socket::close::handler',
            notToReconnect,
            checkNetwork,
            retryToken,
            LoginToken,
            pendingRetryToken
        );
        if (!notToReconnect && onlineState && isBrowserActive) {
            if (pendingRetryToken) {
                setRetryToken(pendingRetryToken);
                setPendingRetryToken(undefined);
            } else {
                if (LoginToken) {
                    if (LoginToken === retryToken) {
                        setRetryToken(undefined);
                        setPendingRetryToken(LoginToken);
                        setTimeout(() => {
                            setCheckNetwork(v => v + 1);
                        }, 1000);
                    } else {
                        setPendingRetryToken(undefined);
                        setRetryToken(LoginToken);
                    }
                }
            }
        }
    }, [handleSocketClose]);

    const disconnect = (notReconnect: boolean) => {
        if (!notReconnect) {
            batch(() => {
                dispatch(loadingProgressSliceActions.loginReset());
                dispatch(resultProgressSliceActions.loginReset());
            });
            if (LoginToken) {
                setPendingRetryToken(undefined);
                setRetryToken(LoginToken);
            }
        } else {
            dispatch(
                gameSliceActions.setNetworkState({
                    notToReconnect: true,
                })
            );
            const disconnectCommand = new CMDPsDisconnect();
            sendCommand(disconnectCommand);
            window.localStorage.removeItem(LocalStorageKey.FullParameter);
        }
        setOnline(false);
    };
    const runHandler = (handler: AbstractHandler) => {
        const forceRun = handler.forceRun();
        const delayKey = handler.delayKey();
        if (delayKey != '') {
            let arr = _delayStore.get(delayKey);
            if (arr) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].handler == handler) {
                        arr.splice(i, 1);
                        break;
                    }
                }
            }
        }
        batch(() => {
            if (forceRun != '') {
                let arr = _delayStore.get(forceRun);
                if (arr) {
                    for (let item of arr) {
                        clearTimeout(item.timeout);
                        // runHandler(item.handler);
                        item.handler.handle(dispatch);
                    }
                    _delayStore.delete(forceRun);
                }
            }
            handler.handle(dispatch);
            let syncCMD = handler.syncCMD();
            if (syncCMD && syncCMD.type === 'CMDPsNbsBet') {
                //For ABC sync data lost
                (syncCMD.cmd as CMDPsNbsBet).BetSource = betSource;
            }
            if (syncCMD && syncCMD.type) {
                syncCMDToServer(syncCMD);
            }
        });
    };
    const syncCMDToServer = (action: SyncCMDAction) => {
        console.log('hook::socket::sync::action', action.type);
        setSyncCMDAction(action);
    };
    const sendLogin = (token: string, isReconnect: boolean) => {
        console.log('hook::socket::login', token);
        dispatch(limitRedSliceActions.logoutReset());
        loginState.current = 2;
        const loginCommand = new CMDPsLogin();
        loginCommand.Token = token;
        loginCommand.Domain =
            haveToken.current || isReconnect
                ? 'Reconnect'
                : referer
                  ? referer
                  : '';
        loginCommand.BetSource = betSource;
        if (!haveToken.current && !isReconnect) {
            loginCommand.LoginLaucherUTCTime = ts ? Number(ts) : 0;
        }
        sendCommand(loginCommand);
        batch(() => {
            dispatch(loadingProgressSliceActions.loginLoad());
            dispatch(progressTimeActions.mark('server'));
        });
    };
    const socketCreate = (token: string, isReconnect: boolean) => {
        if (!lobbyServer) {
            console.log('hook::socket::server::list:empty');
            return;
        }
        const di = getRandomIndex(lobbyServer.length);
        const pi = getRandomIndex(lobbyPort.length);
        const server = lobbyServer[di].split(',');
        // let url = `wss://scs01.connect2explorer.com:${lobbyPort[pi]}`;
        const debug = window.localStorage.getItem('debug_server');
        const url = debug ? debug : `wss://${server[0]}:${lobbyPort[pi]}`;

        if (server.length > 1) {
            dispatch(
                gameSliceActions.setCurrentProxyID({
                    proxyID: Number(server[1]),
                })
            );
        }
        let _buffer: ByteArray = new ByteArray();
        if (socket.current) {
            socket.current.onmessage = () => {};
        }
        socket.current = new WebSocket(url);
        socket.current.binaryType = 'arraybuffer';
        socket.current.onopen = () => {
            loginState.current = 1;
            lastRecv.current = moment().valueOf();
            console.log('hook::socket::open', url, token);
            connectedUrl.current = url;
            // hasLogin.current = false;
            // if (!hasLogin.current)
            sendLogin(token, isReconnect);
        };
        socket.current.onclose = (evt: CloseEvent) => {
            console.log('hook::socket::close', token);
            loginState.current = 0;
            clearTimer();
            _delayStore.clear();
            setOnline(false);
            if (evt.code === 4000) {
                dispatch(
                    gameSliceActions.setNetworkState({
                        notToReconnect: true,
                    })
                );
            } else {
                if (!haveToken.current) {
                    if (retryToken) {
                        setRetryToken(undefined);
                        setPendingRetryToken(retryToken);
                    } else {
                        setRetryToken(undefined);
                        setPendingRetryToken(token);
                    }
                }

                setHandleSocketClose(v => v + 1);
            }
        };
        socket.current.onerror = (event: Event) => {
            console.error('hook::socket::error', event, token);
            setOnline(false);
            socketClose();
            // setRetryToken(token);
        };
        socket.current.onmessage = (event: MessageEvent<ArrayBuffer>) =>
            new Promise<void>((resolve, reject) => {
                // const t0 = performance.now();
                const buffer = Buffer.from(event.data);
                _buffer.appendBuf(buffer);
                const [state, cmdID, buf] = parseCommand(_buffer.toBuffer());
                if (state === BufferProcess.Success && cmdID) {
                    // const t1 = performance.now() - t0;
                    // console.log('buffer::process (ms)', t1);
                    // parse respond command
                    const respCommand = getCommand(cmdID);
                    const respBytes = new ByteArray(buf);
                    _buffer = new ByteArray();
                    try {
                        respCommand?.fromBytes(respBytes);
                    } catch (error) {
                        console.warn('hook::socket::packet::error', error);
                        reject(error);
                    }
                    console.log('hook::socket::received', respCommand);
                    if (respCommand) {
                        let handler: AbstractHandler = new NotImplementHandler(
                            respCommand
                        );
                        lastRecv.current = moment().valueOf();
                        switch (cmdID) {
                            case Command.SP_LOGIN_FAIL:
                                handler = new LoginFailHandler(
                                    respCommand,
                                    specialMsg,
                                    isMessageExist
                                );
                                if (
                                    (<LoginFailHandler>(
                                        handler
                                    )).isMaxConnection()
                                ) {
                                    console.log('hook::socket::full');
                                    setRetryToken(undefined);
                                    setPendingRetryToken(token);
                                    setTimeout(() => {
                                        setCheckNetwork(v => v + 1);
                                    }, 1000);
                                    // setRetryToken(token);
                                } else if (
                                    (<LoginFailHandler>(
                                        handler
                                    )).needRequestCountryName()
                                ) {
                                    requestCountryName(
                                        (<LoginFailHandler>(
                                            handler
                                        )).countryMessage()
                                    );
                                } else {
                                    dispatch(
                                        gameSliceActions.setNetworkState({
                                            notToReconnect: true,
                                            needUserConfirm: true,
                                        })
                                    );
                                    socketClose();
                                }
                                break;
                            case Command.SP_DUPLICATE_LOGIN:
                                dispatch(
                                    gameSliceActions.setNetworkState({
                                        notToReconnect: true,
                                    })
                                );
                                //not clear login data for same browser duplicate login
                                dispatch(
                                    popupSliceActions.open(
                                        'popup.duplicate_login',
                                        POPUP_TYPE.LEAVE_ALERT
                                    )
                                );
                                socketClose();
                                break;
                            case Command.SC_MAINTENANCE_START:
                                dispatch(
                                    gameSliceActions.setNetworkState({
                                        notToReconnect: true,
                                    })
                                );
                                window.localStorage.removeItem(
                                    LocalStorageKey.FullParameter
                                );
                                if (
                                    !window.localStorage.getItem(
                                        LocalStorageKey.ClearReason
                                    )
                                ) {
                                    window.localStorage.setItem(
                                        LocalStorageKey.ClearReason,
                                        (
                                            3000 + GameError.InMaintenance
                                        ).toString()
                                    );
                                }
                                dispatch(
                                    popupSliceActions.open(
                                        'popup.maintaining',
                                        POPUP_TYPE.THANK_ALERT
                                    )
                                );
                                socketClose();
                                break;
                            case Command.SP_PLAYER_BE_DISABLED:
                                dispatch(
                                    gameSliceActions.setNetworkState({
                                        notToReconnect: true,
                                    })
                                );
                                window.localStorage.removeItem(
                                    LocalStorageKey.FullParameter
                                );
                                if (
                                    !window.localStorage.getItem(
                                        LocalStorageKey.ClearReason
                                    )
                                ) {
                                    window.localStorage.setItem(
                                        LocalStorageKey.ClearReason,
                                        GameError.PLAYER_BE_DISABLED.toString()
                                    );
                                }
                                dispatch(
                                    popupSliceActions.open(
                                        'popup.player_disable',
                                        POPUP_TYPE.LEAVE_ALERT
                                    )
                                );
                                socketClose();
                                break;
                            case Command.SP_SEND_BACK_LOBBY:
                                dispatch(routeSliceActions.goto(ROUTE_LOBBY));
                                break;
                            case Command.SP_KICK_TRIAL_TIMEOUT:
                                dispatch(
                                    gameSliceActions.setNetworkState({
                                        notToReconnect: true,
                                    })
                                );
                                if (
                                    !window.localStorage.getItem(
                                        LocalStorageKey.ClearReason
                                    )
                                ) {
                                    window.localStorage.setItem(
                                        LocalStorageKey.ClearReason,
                                        (
                                            3000 +
                                            GameError.TrialPlayerLimitFunction
                                        ).toString()
                                    );
                                }
                                dispatch(
                                    popupSliceActions.open(
                                        'popup.trialTimeout',
                                        POPUP_TYPE.LEAVE_ALERT
                                    )
                                );
                                socketClose();
                                break;
                            case Command.SP_LOGIN:
                                //reset
                                firstHostList.current = true;
                                startTimer();
                                setRetry(0);
                                handler = new CMDSpLoginHandler(
                                    respCommand,
                                    token
                                );
                                hasLogin.current = true;
                                setOnline(true);
                                window.localStorage.removeItem(
                                    LocalStorageKey.ClearReason
                                );
                                break;
                            case Command.SC_ACK:
                                handler = new CMDScAckHandler(respCommand);
                                sendCommand(new CMDCsAck());
                                break;
                            case Command.SC_PLAYER_COUNT: {
                                handler = new CMDScPlayerCountHandler(
                                    respCommand
                                );
                                break;
                            }
                            case Command.SP_PARTNER_GAME:
                            case Command.SP_PVP_GAME:
                                handler = new ObsoleteHandler(respCommand);
                                break;
                            case Command.SP_LIVE_PLAYER_COUNT: {
                                handler = new CMDSpLivePlayerCountHandler(
                                    respCommand
                                );
                                break;
                            }
                            case Command.SP_LIMIT_RED_UPDATE: {
                                handler = new CMDSpLimitRedUpdateHandler(
                                    respCommand
                                );
                                break;
                            }
                            case Command.SP_HOST_LIST: {
                                handler = new CMDSpHostListHandler(
                                    respCommand,
                                    hosts.hostList,
                                    [],
                                    productionTestPermission,
                                    dafaBetPermission
                                );
                                if (firstHostList.current) {
                                    firstHostList.current = false;
                                } else {
                                    if (
                                        window.location.href.indexOf(
                                            ROUTE_LOBBY
                                        ) >= 0
                                    ) {
                                        dispatch(
                                            popupSliceActions.open(
                                                'system.host_list_update_lobby'
                                            )
                                        );
                                    } else {
                                        dispatch(
                                            popupSliceActions.open(
                                                'system.host_list_update_ingame'
                                            )
                                        );
                                    }
                                    dispatch(
                                        routeSliceActions.goto(
                                            ROUTE_RELOAD_LOBBY
                                        )
                                    );
                                    const cmd = new CMDPsRequestInitClient();
                                    cmd.Host = (<CMDSpHostListHandler>handler)
                                        .hostList()
                                        .map(h => h.HostId);
                                    sendCommand(cmd);
                                }

                                break;
                            }
                            case Command.SP_PLAYER_RECENTLY_PLAY: {
                                handler = new CMDSpPlayerRecentlyPlayHandler(
                                    respCommand
                                );
                                break;
                            }
                            case Command.SC_SYNC_DATA: {
                                handler = new CMDScSyncDataHandler(respCommand);
                                break;
                            }
                            case Command.SP_GIFT_SETTING:
                                handler = new CMDSpGiftSettingHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_ANCHOR_LOGIN:
                                handler = new CMDScAnchorLoginHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_GAME_RESULT:
                                handler = new CMDScGameResultHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_START_BET:
                                handler = new CMDScStartBetHandler(respCommand);
                                break;
                            case Command.SC_GAME_STATE:
                                handler = new CMDScGameStateHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_SHUFFLE:
                                handler = new CMDScShuffleHandler(respCommand);
                                break;
                            case Command.SC_SHUFFLE_END:
                                handler = new CMDScShuffleEndHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_GAME_REST:
                                handler = new CMDScGameRestHandler(respCommand);
                                break;
                            case Command.SC_ROUND_RESET:
                                handler = new CMDScRoundResetHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_SERVER_RESET:
                                handler = new CMDSpServerResetHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_ROUND_CANCEL:
                                handler = new CMDScRoundCancelHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_START_GAME:
                                handler = new CMDScStartGameHandler(
                                    respCommand,
                                    isAutoConfirmEnabled
                                );
                                break;
                            case Command.SP_INIT_MAX_BET:
                                handler = new CMDSpInitMaxBetHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_BET_RESULT:
                                handler = new CMDSpBetResultHandler(
                                    respCommand,
                                    specialMsg,
                                    isMessageExist
                                );
                                break;
                            case Command.SP_INIT_BET:
                                handler = new CMDSpInitBetHandler(respCommand);
                                break;
                            case Command.SP_INIT_NBS_BET:
                                handler = new CMDSpInitNbsBetHandler(
                                    respCommand,
                                    sendCommand,
                                    betSource
                                );
                                break;
                            // baccarat
                            case Command.SC_INIT_BACCARAT:
                                handler = new CMDScInitBaccaratHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_BACCARAT_STATISTICS:
                                handler = new CMDSpBaccaratStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_BACCARAT:
                                handler = new CMDScTotalBetBaccaratHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_BACCARAT_GOOD_ROAD:
                                handler = new CMDSpBaccaratGoodRoadHandler(
                                    respCommand
                                );
                                break;
                            // dragon tiger
                            case Command.SC_INIT_DRAGONTIGER:
                                handler = new CMDScInitDragontigerHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_DRAGONTIGER_STATISTICS:
                                handler = new CMDSpDragontigerStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_DRAGONTIGER:
                                handler = new CMDScTotalBetDragontigerHandler(
                                    respCommand
                                );
                                break;
                            // andar bahar
                            case Command.SC_INIT_ANDAR_BAHAR:
                                handler = new CMDScInitAndarBaharHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_ANDAR_BAHAR_STATISTICS:
                                handler = new CMDSpAndarBaharStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_ANDAR_BAHAR:
                                handler = new CMDScTotalBetAndarBaharHandler(
                                    respCommand
                                );
                                break;
                            // pok deng
                            case Command.SC_INIT_POKDENG:
                                handler = new CMDScInitPokDengHandler(
                                    respCommand
                                );
                                break;

                            case Command.SP_POKDENG_STATISTICS:
                                handler = new CMDSpPokDengStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_POKDENG:
                                handler = new CMDScTotalBetPokdengHandler(
                                    respCommand
                                );
                                break;
                            // Sedie
                            case Command.SC_INIT_XOCDIA:
                                handler = new CMDScInitSedieHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_XOCDIA_STATISTICS:
                                handler = new CMDSpSedieStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_XOCDIA:
                                handler = new CMDScTotalBetSedieHandler(
                                    respCommand
                                );
                                break;
                            // teenpatti
                            case Command.SC_INIT_TEEN_PATTI_20_20:
                                handler = new CMDScInitTeenPattiHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_TEEN_PATTI_20_20_STATISTICS:
                                handler = new CMDSpTeenPattiStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_TEEN_PATTI_20_20:
                                handler = new CMDScTotalBetTeenPattiHandler(
                                    respCommand
                                );
                                break;
                            // blackjack:
                            case Command.SC_INIT_BLACKJACK:
                                handler = new CMDScInitBlackjackHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_BLACKJACK:
                                handler = new CMDScTotalBetBlackjackHandler(
                                    respCommand
                                );
                                break;
                            // blackjack room
                            case Command.SC_INIT_ROOM:
                                handler = new CMDScInitRoomHandler(respCommand);
                                break;
                            case Command.SC_ROOM_SEAT:
                                handler = new CMDScRoomSeatHandler(respCommand);
                                break;
                            case Command.SC_PLAYER_BET:
                                handler = new CMDScPlayerBetHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_INIT_SYNC:
                                handler = new CMDScInitSyncHandler(respCommand);
                                break;
                            case Command.SC_REMOVE_SYNC:
                                handler = new CMDScRemoveSyncHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_ROOM_BET:
                                handler = new CMDSpRoomBetHandler(respCommand);
                                break;
                            case Command.SC_ROOM_LEFT:
                                handler = new CMDScRoomLeftHandler(respCommand);
                                break;
                            // roulette
                            case Command.SC_INIT_ROULETTE:
                                handler = new CMDScInitRouletteHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_ROULETTE_STATISTICS:
                                handler = new CMDSpRouletteStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_ROULETTE:
                                handler = new CMDScTotalBetRouletteHandler(
                                    respCommand
                                );
                                break;
                            // Sic Bo
                            case Command.SC_INIT_SICBO:
                                handler = new CMDScInitSicboHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_SICBO_STATISTICS:
                                handler = new CMDSpSicboStatisticsHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_TOTAL_BET_SICBO:
                                handler = new CMDScTotalBetSicboHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_RULE_CHANGE:
                                handler = new CMDSpRuleChangeHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_BET_LOG_SUMMARY_QUERY:
                                handler = new CMDSpBetLogSummaryQueryHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_BET_LOG_QUERY:
                                handler = new CMDSpBetLogQueryHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_BET_LOG_QUERY_TOTAL:
                                handler = new CMDSpBetLogQueryTotalHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_PLAYER_REPORT:
                                handler = new CMDSpPlayerReportHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_KEY_REFRESH:
                                handler = new CMDSpKeyRefreshHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_SET_LIMIT_RED_TOKEN_RESULT:
                                //Handle result = 255
                                handler = new CMDSpResultHandler(respCommand);
                                break;
                            case Command.SC_POKER_SHOW:
                                handler = new CMDScPokerShowHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_RESTART:
                                handler = new CMDScRestartHandler(respCommand);
                                break;
                            case Command.SP_BET_WIN:
                                handler = new CMDSpBetWinHandler(respCommand);
                                break;
                            case Command.SP_BET_WIN_DETAIL:
                                handler = new CMDSpBetWinDetailHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_FORCE_STAND:
                                handler = new CMDSpForceStandHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_CHANGE_PASSWORD_RETURN:
                                handler = new CMDSpChangePasswordReturnHandler(
                                    respCommand
                                );
                                break;
                            //Promotion tool
                            case Command.SP_PROMOTION_EVENT:
                                handler = new CMDSpPromotionEventHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_PROMOTION_STATE:
                                handler = new CMDSpPromotionStateHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_REDEEM_RESULT:
                                handler = new CMDSpRedeemResultHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_PROMOTION_RANKING:
                                handler = new CMDSpPromotionRankingHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_PROMOTION_REDEEM_LOG:
                                handler = new CMDSpPromotionRedeemLogHandler(
                                    respCommand
                                );
                                break;
                            case Command.SC_BLACKJACK_SEAT_INFO:
                                handler = new CMDScBlackjackSeatInfoHandler(
                                    respCommand
                                );
                                break;
                            case Command.SP_NBS_BET_RESULT:
                                handler = new CMDSpNbsBetResultHandler(
                                    respCommand,
                                    specialMsg,
                                    isMessageExist
                                );
                                break;
                        }
                        const commandDelay2 = handler.commandDelay2();
                        if (commandDelay2 > 0) {
                            setTimeout(() => {
                                handler.handle2(dispatch);
                            }, commandDelay2);
                        }
                        const commandDelay = handler.commandDelay();
                        const delayKey = handler.delayKey();
                        if (commandDelay > 0) {
                            if (delayKey != '') {
                                let arr = _delayStore.get(delayKey);
                                if (!arr) {
                                    arr = new Array<delayRunCMD>();
                                    _delayStore.set(delayKey, arr);
                                }
                                arr.push({
                                    handler: handler,
                                    timeout: setTimeout(() => {
                                        runHandler(handler);
                                    }, commandDelay),
                                });
                            } else {
                                setTimeout(() => {
                                    runHandler(handler);
                                }, commandDelay);
                            }
                        } else {
                            runHandler(handler);
                        }
                    }
                }
                resolve();
            });
    };
    // socket state
    const socketInit = (token: string, isReconnect: boolean) => {
        if (!socket.current || socket.current.readyState === WebSocket.CLOSED) {
            console.log('hook::socket::init');
            socketCreate(token, isReconnect);
        }
    };
    const socketClose = () => {
        socket.current?.close();
    };
    // command
    const sendCommand = async (command: ICommand) => {
        if (socket.current?.readyState === WebSocket.OPEN) {
            console.log('hook::socket::send', command);
            let bytes = command.toBytes().toBuffer();
            let ba = new ByteArray();
            ba.writeUint8(0xaa);
            ba.writeUint32(2 + 4 + bytes.length);
            ba.writeUint16(command.getCmdID());
            ba.writeUint32(bytes.length);
            ba.appendBuf(bytes);
            const buffer = ba.toBuffer();
            socket.current?.send(buffer);
        } else {
            console.warn('hook::socket::send::error', command);
        }
    };
    // main call
    useEffect(() => {
        return () => {
            socketClose();
        };
    }, []);
    useEffect(() => {
        console.warn(
            'hook::socket::retry',
            retry,
            retry < RETRY_MAX_COUNT,
            onlineState,
            isBrowserActive,
            notToReconnect,
            retryToken
        );
        if (notToReconnect) {
            return;
        }
        if (
            onlineState &&
            isBrowserActive &&
            retry != 0 &&
            retryToken != undefined
        ) {
            if (retry < RETRY_MAX_COUNT) {
                socketClose();
                if (retryToken) socketInit(retryToken, true);
            } else {
                batch(() => {
                    dispatch(
                        popupSliceActions.open(
                            [
                                'popup.login_failed',
                                '(',
                                GameError.ConnectLobbyFailed.toString(),
                                ')',
                            ],
                            POPUP_TYPE.THANK_ALERT
                        )
                    );
                });
            }
        }
        return () => {
            setLastRetry(moment().valueOf());
            setRetryToken(undefined);
            setPendingRetryToken(undefined);
        };
    }, [retry]);
    useEffect(() => {
        dispatch(
            gameSliceActions.setNetworkState({
                online: online,
            })
        );
    }, [online]);

    return {
        socket,
        sendCommand,
        login,
        disconnect,
        socketClose,
        connectedUrl,
        hasLogin,
        setCheckNetwork,
    };
};
export default useLiveGameSocket;
export type useLiveGameSocketState = ReturnType<typeof useLiveGameSocket>;
