import { SyncCMDAction } from '@/models/cmd/handler/AbstractHandler';
import { useEffect, useRef, useState } from 'react';
import { ROAD_MAP_TYPE } from '../modules/lobby/constants';
import { useLocalStorage } from './storage/useLocalStorage';
import { useClientIp } from './useClientIp';
import { ResolutionType } from './useVideoPlayer';

//Feedback data
type FeedbackData = {
    blockTimestamp: number;
    sends: number;
};
//Disable Emoji Button
type DisableEmojiButtonData = {
    duration: number;
    hostId: number;
};
type DisableEmojiButton = {
    hostId: number;
    disable: boolean;
};
type TimerType = {
    hostId: number;
    timer: NodeJS.Timeout | null;
};

export enum BetViewMode {
    Original,
    Reverse,
}

export enum MultiBetViewMode {
    FullRoad,
    BigRoad,
    OtherRoad,
}

export type useGlobalDataState = ReturnType<typeof useGlobalData>;
export const useGlobalData = () => {
    const [data, setData] = useState<DisableEmojiButtonData[]>([]);
    const [disableEmoji, setDisableEmoji] = useState<DisableEmojiButton[]>([]);
    const [timer, setTimer] = useState<TimerType[]>([]);
    const [betView, setBetView] = useLocalStorage(
        'bet-view',
        BetViewMode.Original
    );
    const externalWindowRef = useRef<Window | null>(null);

    const setDisableEmojiButtonData = (hostId: number, dur: number) => {
        const dd = {} as DisableEmojiButtonData;
        dd.hostId = hostId;
        dd.duration = dur;
        const ddIdx = data.findIndex(d => d.hostId === hostId);
        let newData: DisableEmojiButtonData[];
        if (ddIdx === -1) {
            newData = data.concat([dd]);
        } else {
            data[ddIdx].duration = dur;
            newData = data;
        }
        setData(newData);

        const d = {} as DisableEmojiButton;
        d.hostId = hostId;
        d.disable = true;
        const dIdx = disableEmoji.findIndex(d => d.hostId === hostId);
        let newDisable: DisableEmojiButton[];
        if (dIdx === -1) {
            newDisable = disableEmoji.concat([d]);
        } else {
            disableEmoji[dIdx].disable = true;
            newDisable = disableEmoji;
        }
        setDisableEmoji(newDisable);
    };
    useEffect(() => {
        if (data.length > 0) {
            data.map(dd => {
                const { hostId, duration } = dd;

                const dIdx = disableEmoji.findIndex(d => d.hostId === hostId);
                const tIdx = timer.findIndex(
                    t => t.hostId === hostId && t.timer === null
                );
                if (dIdx >= 0 && tIdx === -1) {
                    let dur = duration * 3000;
                    if (dur < 60000) dur = 60000;

                    const t = {} as TimerType;
                    t.hostId = hostId;
                    t.timer = setTimeout(() => {
                        const newData = data.filter(d => d.hostId !== hostId);
                        setData(newData);
                        const newDisable = disableEmoji.filter(
                            d => d.hostId !== hostId
                        );
                        setDisableEmoji(newDisable);
                        const newTimer = timer.filter(d => d.hostId !== hostId);
                        setTimer(newTimer);
                    }, dur);

                    setTimer(timer.concat([t]));
                }
            });
        }
    }, [data]);

    const [syncCMDAction, setSyncCMDAction] = useState<SyncCMDAction>();
    const [resolutionIdx, setResolutionIdx] = useState<number>(0);
    const [resolution, setResolution] = useState<ResolutionType>('HD');
    const [videoOff, setVideoOff] = useState<boolean>(false);
    const [videoMBOff, setMultiBetVideoOff] = useState<boolean>(true);

    const [multiBetHostID, setMultiBetHostID] = useState<number>(0); //for bet limit
    //Video On/Off
    // const [videoOffList, setVideoOffList] = useState<Array<GameTypeEnum>>([]);
    // const saveVideoOffList = (g: GameTypeEnum) => {
    //     let newList = Array.from(videoOffList);
    //     newList.push(g);
    //     setVideoOffList(newList);
    // };
    // const removeVideoOffList = (g: GameTypeEnum) => {
    //     let newList = Array.from(videoOffList);
    //     newList = newList.filter(l => l !== g);
    //     setVideoOffList(newList);
    // };
    const [baccaratRoadMapType, setBaccaratRoadMapType] =
        useState<ROAD_MAP_TYPE>(ROAD_MAP_TYPE.BEAD_ROAD);
    const [isDefaultTable, setIsDefaultTable] = useState<boolean>(true);
    const [feedbackData, setFeedbackData] = useState<FeedbackData>();

    const { address, countryCode, ip } = useClientIp();

    useEffect(() => {
        console.log('video::resolution', resolution);
    }, [resolution]);

    useEffect(() => {
        // local storage may return a invalid value
        setBetView(
            betView > BetViewMode.Reverse ? BetViewMode.Original : betView
        );
    }, [betView]);
    return {
        setDisableEmojiButtonData,
        disableEmoji,

        resolution,
        setResolution,

        resolutionIdx,
        setResolutionIdx,

        // videoOffList,
        // saveVideoOffList,
        // removeVideoOffList,
        videoOff,
        setVideoOff,
        videoMBOff,
        setMultiBetVideoOff,

        baccaratRoadMapType,
        setBaccaratRoadMapType,

        isDefaultTable,
        setIsDefaultTable,

        multiBetHostID,
        setMultiBetHostID,

        feedbackData,
        setFeedbackData,

        address,
        countryCode,
        ip,

        betView,
        setBetView,

        //for resume client sync bet in ABC
        syncCMDAction,
        setSyncCMDAction,

        externalWindowRef,
    };
};
