import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpRequestVideoToken implements ICommand
{

	public Token : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Token = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.Token);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpRequestVideoToken();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Token(S)='+this.Token+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50093;
	}

	public getCmdName() : string
	{
		return 'SpRequestVideoToken';
	}

}
