import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { DragonTigerStatistics } from '../../games/dragonTiger';
import { CMDSpDragontigerStatistics, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpDragontigerStatisticsHandler extends AbstractHandler {
    _command: CMDSpDragontigerStatistics;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpDragontigerStatistics;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const statistics = {} as DragonTigerStatistics;
        const hostId = this._command.HostID;
        statistics.DragonWinCount = this._command.Statistics.DragonWinCount;
        statistics.TieCount = this._command.Statistics.TieCount;
        statistics.TigerWinCount = this._command.Statistics.TigerWinCount;
        dispatch(hostsSliceActions.updateStatistic({ hostId, statistics }));
    }
}
