import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { CMDSpKeyRefresh, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { userSliceActions } from '../../../modules/main/slice/user';

export class CMDSpKeyRefreshHandler extends AbstractHandler {
    _command: CMDSpKeyRefresh;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpKeyRefresh;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        dispatch(userSliceActions.updateReconnectKey(this._command.Key));
    }
}
