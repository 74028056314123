import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpPlayerReport implements ICommand
{

	public CurrentRefundRatio : number = 0;
	public ToBeRefundRatio : number = 0;
	public TotalBet : number = 0;
	public TotalWash : number = 0;
	public TotalResult : number = 0;
	public Refund : number = 0;
	public Total : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.CurrentRefundRatio = ba.readUint16();
		this.ToBeRefundRatio = ba.readUint16();
		this.TotalBet = ba.readInt53();
		this.TotalWash = ba.readInt53();
		this.TotalResult = ba.readInt53();
		this.Refund = ba.readInt53();
		this.Total = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.CurrentRefundRatio);
		ba.writeUint16(this.ToBeRefundRatio);
		ba.writeUint53(this.TotalBet);
		ba.writeUint53(this.TotalWash);
		ba.writeUint53(this.TotalResult);
		ba.writeUint53(this.Refund);
		ba.writeUint53(this.Total);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpPlayerReport();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'CurrentRefundRatio(W)='+this.CurrentRefundRatio+' ';
		str += 'ToBeRefundRatio(W)='+this.ToBeRefundRatio+' ';
		str += 'TotalBet(Q)='+this.TotalBet+' ';
		str += 'TotalWash(Q)='+this.TotalWash+' ';
		str += 'TotalResult(Q)='+this.TotalResult+' ';
		str += 'Refund(Q)='+this.Refund+' ';
		str += 'Total(Q)='+this.Total+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50019;
	}

	public getCmdName() : string
	{
		return 'SpPlayerReport';
	}

}
