import { useContext } from 'react';
import { GameConfigContext } from '../contexts/ConfigContext';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';

export type CommonConfigType = {
    site: [
        {
            title: string;
            icon: string;
            lang?: string; //TODO: 未做 LinkHelper -> 88行
            url: string[];
        },
    ];
    productionTestPermission: boolean;
    dafaBetPermission: boolean;
    isCowCowShow: boolean;
    isShowGiftLobby: boolean; //SceneMoneyWheel, 冇money wheel
    disableLogoutLobby: boolean;
    //credit site 用，e+冇用
    iconRoller: {
        lobby: string;
        case: string;
        item: {
            image: string;
            url: string;
        };
        '#text': string;
    };
    hideFeedback: boolean;
    hideFullScreen: boolean;
    hideBackToLobby: boolean;
    hideMultiBet: boolean;
    hideSwitchTable: boolean;
    langOnly: { [langCode: string]: string[] }; // 只有特定lobby會show的language, 現時只針對 megacasino -> kr
    langExclude: { [langCode: string]: { default: string; lobby: string[] } }; //{langCode: {lobbyCode : defaultLang}} 現時只針對 cn, zh
    msg: { lobby: string[]; sub: string }; //問過elno, 特別為M266做，出唔同error message, 唔肯定仲有冇M266
    showCurrencyName: [
        {
            exCurrency: string[];
            exclude: string[];
            include: string[] | undefined;
        },
    ];
    headerBackground: {
        source: string;
        startDate: number;
        endDate: number;
    };
    permission: {
        pTest: string[];
        dafa: string[];
        cd: string[];
        giftHide: string[];
        logoutHide: string[];
    };
    hide: {
        feedback: string[];
        fullScreen: string[];
        backToLobby: string[];
        multiBet: string[];
        switchTable: string[];
    };
};

export type GameConfigHostsType = {
    sai?: number;
    type: GameTypeEnum;
    hostId: number;
    group: string;
    video?: string;
    audio?: boolean;
    hls?: string;
    nano?: string;
    hostName?: string;
    delayDraw?: number;
    delayResult?: number;
    delayZoom?: number;
    enabled?: boolean;
    tableLimit?: number;
    time?: number; // 懷疑已經冇用
    hall?: number;
    shuffleDelay?: number;
    new?: number;
    test?: boolean;
};

export type GameConfigType = {
    hosts: {
        hostList: Array<GameConfigHostsType>;
    };
    chipSet: chipSetType[];
};

export type BannerType = {
    lang: Array<string>;
    url: string;
    action?: string;
    data?: {
        hostId: number;
    };
    startDate?: number;
    endDate?: number;
};

export type BannerConfigType = {
    banners: BannerType[];
};

export const productionTestPermission = (
    pTest: string[] | undefined,
    lobbyCode: string | undefined
) => {
    if (window.location.host.indexOf('localhost') >= 0) return true;

    if (!lobbyCode) return false;

    if (pTest?.map(s => s.toLowerCase()).includes(lobbyCode)) return true;

    return false;
};

export const dafaBetPermission = (
    dafa: string[] | undefined,
    lobbyCode: string | undefined
) => {
    if (!lobbyCode) return true;

    if (dafa?.map(s => s.toLowerCase()).includes(lobbyCode)) return true;

    return false;
};

export const isCowCowShow = (
    ccd: string[] | undefined,
    lobbyCode: string | undefined
) => {
    //Cow Cow Disable Lobby
    if (!lobbyCode) return true;
    if (ccd?.map(s => s.toLowerCase()).includes(lobbyCode)) return false;
    return true;
};

export const isShowGiftLobby = (
    giftHide: string[] | undefined,
    lobbyCode: string | undefined
) => {
    // giftHiddenLobby, only use money wheel (SA -> SceneMoneyWheel -> giftHiddenLobby)
    if (!lobbyCode) return true;
    if (giftHide?.map(s => s.toLowerCase()).includes(lobbyCode)) return false;
    return true;
};

export const disableLogoutLobby = (
    logoutHide: string[] | undefined,
    lobbyCode: string | undefined
) => {
    if (!lobbyCode) return false;
    if (logoutHide?.map(s => s.toLowerCase()).includes(lobbyCode)) return true;
    return false;
};

export const hideItem = (
    hideLists: string[] | undefined,
    lobbyCode: string | undefined
) => {
    if (!lobbyCode) return false;
    if (hideLists?.map(s => s.toLowerCase()).includes(lobbyCode)) return true;
    return false;
};

export type chipSetType = {
    value: number;
    displayText: string;
    color1: string;
    color2: string;
    color3: string;
};

const useConfig = () => {
    const { chipSet } = useContext(GameConfigContext);

    return { chipSet };
};

export default useConfig;
