import {
    getPromotionTool,
    PromotionToolEnum,
} from '@/components/images/base64/PromotionTool';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { promotionScratchAnimation } from '../PromotionObjectMapper';

type Props = {
    source: string;
    endFrame: number;
    duration?: number;
    scale?: number;
};

const PromotionAnimation = (props: Props) => {
    const { source, endFrame, duration = 1000, scale = 0.5 } = props;
    const [imageName, setImageName] = useState<string>(source);

    useEffect(() => {
        let num = 0;
        const secFrame = duration / endFrame;
        const interval = setInterval(() => {
            num++;
            if (num < endFrame) {
                const newSource = source.slice(0, -1) + num;
                setImageName(newSource);
            } else {
                const newSource = source.slice(0, -1) + num;
                setImageName(newSource);
                clearInterval(interval);
            }
        }, secFrame);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {/* Force preload tricks for iOS */}
            <Box
                sx={{
                    position: 'absolute',
                    visibility: 'hidden',
                    width: 10,
                    height: 10,
                    overflow: 'hidden',
                }}
            >
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_1
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_2
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_3
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_4
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_5
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_6
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_7
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_8
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_9
                )}
                {getPromotionTool(
                    PromotionToolEnum.event_scratch_card_scratch_10
                )}
            </Box>
            {/* <BaseImage className={imageName} scale={scale} /> */}
            <Box sx={{ scale: `${scale}` }}>
                {getPromotionTool(promotionScratchAnimation[imageName])}
            </Box>
        </>
    );
};

export default PromotionAnimation;
