import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpMinigameAction implements ICommand
{

	public BetID : number = 0;
	public UnknownData : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BetID = ba.readInt53();
		this.UnknownData = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.BetID);
		ba.writeString(this.UnknownData);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpMinigameAction();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetID(Q)='+this.BetID+' ';
		str += 'UnknownData(S)='+this.UnknownData+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50031;
	}

	public getCmdName() : string
	{
		return 'SpMinigameAction';
	}

}
