export enum GameState {
    WaitPlayerFirstCard = 2,
    WaitBankerFirstCard,
    WaitPlayerSecondCard,
    WaitBankerSecondCard,
    AdditionStart,
    WaitPlayerAdditionCard,
    WaitBankerAdditionCard,

    SqueezeCard,
    SqueezeCardEnd,
    AdditionSqueezeCard,

    PlayerWin = 0x7000,
    BankerWin,
    Draw,
}
