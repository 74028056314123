import { useLobbyUI, useLobbyUIState } from '@/hooks/useLobbyUI';
import { createContext } from 'react';

export const LobbyUiContext = createContext({} as useLobbyUIState);
const { Provider } = LobbyUiContext;
type LobbyUiProviderOwnProps = {
    children: JSX.Element;
};
type LobbyUiProviderProps = LobbyUiProviderOwnProps;
export const enum GridType {
    GridMode,
    MiniMode,
    ListMode,
} //Grid Mode Order

export const LobbyUiProvider = (props: LobbyUiProviderProps) => {
    const { children } = props;
    const state = useLobbyUI();
    return <Provider value={state}>{children}</Provider>;
};
