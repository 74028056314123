import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    Progress,
    ProgressLoadingState,
    getInitialProgressState,
} from '../../../models/games/Progress';

export const LOADING_PROGRESS_KEY = 'app::progress::loading';
const startLoad = (
    state: ProgressLoadingState,
    action: PayloadAction<Progress>
) => {
    state[action.payload] = true;
};
const loginLoad = (state: ProgressLoadingState) => {
    state[Progress.SERVER_BALANCE] = true;
    state[Progress.SERVER_PLAYER_COUNT] = true;
    state[Progress.SERVER_COUNT] = true;
    state[Progress.SERVER_USER_BET_LIMIT] = true;
    state[Progress.SERVER_USER_INFO_RESULT] = true;
    state[Progress.SERVER_HOST_LIST] = true;
};
const loginReset = (state: ProgressLoadingState) => {
    state[Progress.SERVER_BALANCE] = false;
    state[Progress.SERVER_PLAYER_COUNT] = false;
    state[Progress.SERVER_COUNT] = false;
    state[Progress.SERVER_USER_BET_LIMIT] = false;
    state[Progress.SERVER_USER_INFO_RESULT] = false;
    state[Progress.SERVER_HOST_LIST] = false;
};
const reset = (state: ProgressLoadingState): void => {
    Object.keys(Progress)
        .filter(p => isNaN(Number(p)))
        .forEach(k => {
            const key = Number(k) as Progress;
            state[key] = false;
        });
};
const initialProgressLoadingState: ProgressLoadingState =
    getInitialProgressState();
const loadingProgressSlice = createSlice({
    name: LOADING_PROGRESS_KEY,
    initialState: initialProgressLoadingState,
    reducers: {
        startLoad,
        reset,
        loginLoad,
        loginReset,
    },
});
export const loadingProgressSliceReducer = loadingProgressSlice.reducer;
export const loadingProgressSliceActions = loadingProgressSlice.actions;
