import { CMDScSyncData } from "./CMDScSyncData"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScInitSync implements ICommand
{

	public Name : string = "";
	public Data : CMDScSyncData;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Name = ba.readString();
		this.Data = new CMDScSyncData();
		this.Data.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.Name);
		let tempBuf1 = this.Data.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScInitSync();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Name(S)='+this.Name+' ';
		str += 'Data(CMDScSyncData)='+this.Data+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30007;
	}

	public getCmdName() : string
	{
		return 'ScInitSync';
	}

}
