import { CMDBaseResult } from "./CMDBaseResult"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBaccarat implements ICommand
{

	public BaseResult : CMDBaseResult;
	public playerCards : Array<number>;
	public bankerCards : Array<number>;

	public constructor()
	{
		this.playerCards = new Array<number>();
		this.bankerCards = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BaseResult = new CMDBaseResult();
		this.BaseResult.fromBytes(ba);
		let playerCardsSize:number = ba.readUint8();
		while (playerCardsSize-- > 0)
		{
			let playerCardsChild:number = ba.readUint8();
			this.playerCards.push(playerCardsChild);
		}
		let bankerCardsSize:number = ba.readUint8();
		while (bankerCardsSize-- > 0)
		{
			let bankerCardsChild:number = ba.readUint8();
			this.bankerCards.push(bankerCardsChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.BaseResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.playerCards.length);
		for (const playerCardsChild of this.playerCards)
		{
			ba.writeUint8(playerCardsChild);
		}
		ba.writeUint8(this.bankerCards.length);
		for (const bankerCardsChild of this.bankerCards)
		{
			ba.writeUint8(bankerCardsChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBaccarat();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BaseResult(CMDBaseResult)='+this.BaseResult+' ';
		str += 'playerCards_size(B)='+this.playerCards.length + ' ';
		str += 'playerCards(B)=[';
		for (let playerCardsChild in this.playerCards)
		{
			if (<number><unknown>playerCardsChild > 0) str += ', ';
			str += this.playerCards[playerCardsChild];
		}
		str += '] ';
		str += 'bankerCards_size(B)='+this.bankerCards.length + ' ';
		str += 'bankerCards(B)=[';
		for (let bankerCardsChild in this.bankerCards)
		{
			if (<number><unknown>bankerCardsChild > 0) str += ', ';
			str += this.bankerCards[bankerCardsChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Baccarat';
	}

}
