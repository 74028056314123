import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDOdd implements ICommand
{

	public BetType : number = 0;
	public Odd : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.BetType = ba.readUint8();
		this.Odd = ba.readUint16();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.BetType);
		ba.writeUint16(this.Odd);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDOdd();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetType(B)='+this.BetType+' ';
		str += 'Odd(W)='+this.Odd+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Odd';
	}

}
