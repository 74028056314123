import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpPromotionEvent, ICommand } from "../../live";
import { AbstractHandler } from "../AbstractHandler";
import { PromotionState, promotionToolSliceActions } from '../../../../modules/main/slice/promotiontool/promotionTool';
import { batch } from 'react-redux';

export class CMDSpPromotionEventHandler extends AbstractHandler {
    _command: CMDSpPromotionEvent;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPromotionEvent;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const cmd = this._command;
        // console.log(`command::handler::${cmd.getCmdName}::${cmd.toString()}`, cmd);
        const data = {} as PromotionState;
        data.StartTimestamp = cmd.StartTimeUTC;
        data.EndTimestamp = cmd.EndTimeUTC;
        data.Timezone = cmd.Timezone;
        data.BudgetAmount = cmd.BudgetAmount;
        data.MoreDetailUrl = cmd.EventURL;
        data.Popup = cmd.PopUp;
        data.PrizeList = cmd.PromotionPrize.map(list => JSON.stringify(list));
        data.MaxDayLimit = cmd.MaxDayLimit;
        data.RemainDayLimit = cmd.RemainDayLimit;
        batch(() => {
            dispatch(promotionToolSliceActions.updateSpPromotionEventData(data));
            dispatch(promotionToolSliceActions.updateRemainDays(cmd.RemainDayLimit));
        });
    }
}