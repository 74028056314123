import { CMDBaseInformation } from "./CMDBaseInformation"
import { CMDFantan } from "./CMDFantan"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScInitFantan implements ICommand
{

	public Information : CMDBaseInformation;
	public OldResult : Array<CMDFantan>;
	public CurrentState : number = 0;
	public CurrentResult : CMDFantan;

	public constructor()
	{
		this.OldResult = new Array<CMDFantan>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Information = new CMDBaseInformation();
		this.Information.fromBytes(ba);
		let OldResultSize:number = ba.readUint8();
		while (OldResultSize-- > 0)
		{
			let OldResultChild:CMDFantan = new CMDFantan();
			OldResultChild.fromBytes(ba);
			this.OldResult.push(OldResultChild);
		}
		this.CurrentState = ba.readUint16();
		this.CurrentResult = new CMDFantan();
		this.CurrentResult.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Information.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint8(this.OldResult.length);
		for (const OldResultChild of this.OldResult)
		{
			let tempBuf = OldResultChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}
		ba.writeUint16(this.CurrentState);
		let tempBuf4 = this.CurrentResult.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScInitFantan();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Information(CMDBaseInformation)='+this.Information+' ';
		str += 'OldResult_size(B)='+this.OldResult.length + ' ';
		str += 'OldResult(CMDFantan)=[';
		for (let OldResultChild in this.OldResult)
		{
			if (<number><unknown>OldResultChild > 0) str += ', ';
			str += this.OldResult[OldResultChild].toString();
		}
		str += '] ';
		str += 'CurrentState(W)='+this.CurrentState+' ';
		str += 'CurrentResult(CMDFantan)='+this.CurrentResult+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30504;
	}

	public getCmdName() : string
	{
		return 'ScInitFantan';
	}

}
