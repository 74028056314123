import { Buffer } from 'buffer';
import { BetRoomSeatIndex } from '../../models/games/BetRoom';
import {
    BlackjackBetResult,
    BlackjackGameState,
    BlackjackHandInfo,
    BlackjackHandResult,
    BlackjackHistory,
    BlackjackHistoryInfo,
    BlackjackHistoryInfoKey,
    BlackjackPlayerResult,
    BlackjackRecord,
    BlackjackResult,
    BlackjackSeatInfo,
    createBlackjackBetResult,
} from '../../models/games/blackjack';
import {
    BlackjackDealtTurnState,
    BlackjackInitState,
    BlackjackPhaseState,
} from '../../models/games/blackjack/states';
import { MAX_SPLIT } from '../../models/games/blackjack/states/Constant';
import {
    isWaitCard,
    isWaitDecision,
} from '../../models/games/blackjack/states/DealtLoop';
import {
    Init,
    getSeatNo as getInitSeatNo,
    isWaitPlayerFirstCard,
    isWaitPlayerSecondCard,
} from '../../models/games/blackjack/states/Init';
import {
    Phase,
    getSeatNo as getPhaseSeatNo,
    isHand,
    isPlayerHand,
} from '../../models/games/blackjack/states/Phase';
import {
    GameState as CommonGameState,
    GameState,
} from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { getShoeRoundByGameCount } from '../commonFunc';
import { GetBaccaratPoint } from '../pokerCount';

export const convertResultToRecord = (
    currentResult: BlackjackResult,
    resultString: string,
    result?: number
): BlackjackRecord => {
    const r = {} as BlackjackRecord;
    const { Shoe, Round } = getShoeRoundByGameCount(currentResult.GameCount);
    const newResult = { ...currentResult };
    newResult.Result = result ?? currentResult.Result;
    newResult.ResultString = resultString;
    r.gameID = currentResult.GameID;
    r.shoes = Shoe;
    r.round = Round;
    r.gameResult = newResult;
    r.bankerHand = convertOldHandResultToInfo(currentResult.B);
    r.seatInfos = convertOldHandResultToSeatInfos(currentResult.P);
    return r;
};
// banker record only have point1 and disable splitted card
export const convertOldHandResultToInfo = (
    hr: BlackjackHandResult | undefined
): BlackjackHandInfo | undefined => {
    let hi: BlackjackHandInfo | undefined = undefined;
    if (hr) {
        hi = {
            pokers: hr.PK,
            point: hr.R,
        };
    }
    return hi;
};
export const convertOldHandResultToSeatInfos = (
    p?: Array<BlackjackPlayerResult>
): Array<BlackjackSeatInfo> | undefined => {
    const seatInfos = new Array<BlackjackSeatInfo>();
    if (p)
        for (const pr of p) {
            const si = {} as BlackjackSeatInfo;
            si.seatNo = pr.SN;
            si.decision = pr.D;
            si.playerId = Number(pr.I);
            si.hand1 = convertOldHandResultToInfo(pr.H?.at(0));
            si.hand2 = convertOldHandResultToInfo(pr.H?.at(1));
            seatInfos.push(si);
        }
    return seatInfos.length > 0 ? seatInfos : undefined;
};
export const getDealerHistory = (point: number): BlackjackHistoryInfo => {
    if (point === 255) return BlackjackHistoryInfo.BJ;
    // server is return 22 when the banker is busted
    else if (point === 22) return BlackjackHistoryInfo.BUST;
    else return BlackjackHistoryInfo.POINT;
};
export const getPlayerHistory = (
    result: BlackjackBetResult,
    seat: number,
    seatInfos?: Array<BlackjackSeatInfo>
): BlackjackHistoryInfo => {
    let result1 = 0;
    let result2 = 0;
    let bj1 = false;
    let bj2 = false;
    let refund1 = false;
    let refund2 = false;
    let isBust1 = false;
    let isBust2 = false;

    const seatInfo = seatInfos?.find(si => si.seatNo === seat);
    if (seatInfos && seatInfo) {
        if (seatInfo.hand1) {
            bj1 = seatInfo.hand1.point === 255;
            isBust1 = seatInfo.hand1.point > 21 && !bj1;
        }
        if (seatInfo.hand2) {
            bj2 = seatInfo.hand2.point === 255;
            isBust2 = seatInfo.hand2.point > 21 && !bj2;
        }
    } else {
        return BlackjackHistoryInfo.NO_BET;
    }
    switch (seat) {
        case 1:
            result1 = result.BJR1_S1;
            result2 = result.BJR1_S2;
            bj1 = result.BJR1_S1_BJ;
            bj2 = result.BJR1_S2_BJ;
            refund1 = result.BJR1_S1_Refund;
            refund2 = result.BJR1_S2_Refund;
            break;
        case 2:
            result1 = result.BJR2_S1;
            result2 = result.BJR2_S2;
            bj1 = result.BJR2_S1_BJ;
            bj2 = result.BJR2_S2_BJ;
            refund1 = result.BJR2_S1_Refund;
            refund2 = result.BJR2_S2_Refund;
            break;
        case 3:
            result1 = result.BJR3_S1;
            result2 = result.BJR3_S2;
            bj1 = result.BJR3_S1_BJ;
            bj2 = result.BJR3_S2_BJ;
            refund1 = result.BJR3_S1_Refund;
            refund2 = result.BJR3_S2_Refund;
            break;
        case 4:
            result1 = result.BJR4_S1;
            result2 = result.BJR4_S2;
            bj1 = result.BJR4_S1_BJ;
            bj2 = result.BJR4_S2_BJ;
            refund1 = result.BJR4_S1_Refund;
            refund2 = result.BJR4_S2_Refund;
            break;
        case 5:
            result1 = result.BJR5_S1;
            result2 = result.BJR5_S2;
            bj1 = result.BJR5_S1_BJ;
            bj2 = result.BJR5_S2_BJ;
            refund1 = result.BJR5_S1_Refund;
            refund2 = result.BJR5_S2_Refund;
            break;
        case 6:
            result1 = result.BJR6_S1;
            result2 = result.BJR6_S2;
            bj1 = result.BJR6_S1_BJ;
            bj2 = result.BJR6_S2_BJ;
            refund1 = result.BJR6_S1_Refund;
            refund2 = result.BJR6_S2_Refund;
            break;
        case 7:
            result1 = result.BJR7_S1;
            result2 = result.BJR7_S2;
            bj1 = result.BJR7_S1_BJ;
            bj2 = result.BJR7_S2_BJ;
            refund1 = result.BJR7_S1_Refund;
            refund2 = result.BJR7_S2_Refund;
            break;
    }
    return getAllPlayerResult(
        result1,
        result2,
        bj1,
        bj2,
        refund1,
        refund2,
        isBust1,
        isBust2
    );
};
export const getBetResultByWinSlot = (winSlot: string): BlackjackBetResult => {
    const n = BigInt(winSlot ?? '0');
    const result = createBlackjackBetResult();
    // 8 bytes  64 bits
    const buffer = Buffer.alloc(8);
    buffer.writeBigInt64LE(n);
    const bytes = new Array<boolean>();
    buffer.forEach(b => {
        for (let i = 1; i < 256; i <<= 1) bytes.push((b & i) > 0);
    });
    // first bytes
    for (let i = 0; i < 8; i++) result.BJR_BankerPoint += bytes.shift() ? 1 : 0;
    for (let i = 0; i < 2; i++) result.BJR1_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR1_S1_BJ = bytes.shift() ?? false;
    result.BJR1_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR1_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR1_S2_BJ = bytes.shift() ?? false;
    result.BJR1_S2_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR2_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR2_S1_BJ = bytes.shift() ?? false;
    result.BJR2_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR2_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR2_S2_BJ = bytes.shift() ?? false;
    result.BJR2_S2_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR3_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR3_S1_BJ = bytes.shift() ?? false;
    result.BJR3_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR3_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR3_S2_BJ = bytes.shift() ?? false;
    result.BJR3_S2_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR4_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR4_S1_BJ = bytes.shift() ?? false;
    result.BJR4_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR4_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR4_S2_BJ = bytes.shift() ?? false;
    result.BJR4_S2_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR5_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR5_S1_BJ = bytes.shift() ?? false;
    result.BJR5_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR5_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR5_S2_BJ = bytes.shift() ?? false;
    result.BJR5_S2_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR6_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR6_S1_BJ = bytes.shift() ?? false;
    result.BJR6_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR6_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR6_S2_BJ = bytes.shift() ?? false;
    result.BJR6_S2_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR7_S1 += bytes.shift() ? 1 << i : 0;
    result.BJR7_S1_BJ = bytes.shift() ?? false;
    result.BJR7_S1_Refund = bytes.shift() ?? false;
    for (let i = 0; i < 2; i++) result.BJR7_S2 += bytes.shift() ? 1 << i : 0;
    result.BJR7_S2_BJ = bytes.shift() ?? false;
    result.BJR7_S2_Refund = bytes.shift() ?? false;
    return result;
};
export const getHistoryByResult = (
    result: BlackjackBetResult,
    seatInfos?: Array<BlackjackSeatInfo>
): BlackjackHistory => {
    return {
        Dealer: getDealerHistory(result.BJR_BankerPoint),
        Player1: getPlayerHistory(result, 1, seatInfos),
        Player2: getPlayerHistory(result, 2, seatInfos),
        Player3: getPlayerHistory(result, 3, seatInfos),
        Player5: getPlayerHistory(result, 4, seatInfos),
        Player6: getPlayerHistory(result, 5, seatInfos),
        Player7: getPlayerHistory(result, 6, seatInfos),
        Player8: getPlayerHistory(result, 7, seatInfos),
    } as BlackjackHistory;
};
const getAllPlayerResult = (
    result1: number,
    result2: number,
    bj1: boolean,
    bj2: boolean,
    refund1: boolean,
    refund2: boolean,
    isBust1: boolean,
    isBust2: boolean
): BlackjackHistoryInfo => {
    let winloss = BlackjackHistoryInfo.NO_BET;
    if (result2 !== 0) {
        if (!refund1 && !refund2)
            if (!isBust1 && !isBust2)
                winloss = getSplittedWinLossPush(result1, result2);
            else if (isBust1 && !isBust2)
                winloss = getSplittedWinLossPush(5, result2);
            else if (!isBust1 && isBust2)
                winloss = getSplittedWinLossPush(result1, 5);
            else winloss = BlackjackHistoryInfo.BUST;
        else if (refund1 && !refund2)
            winloss = getSplittedWinLossPush(4, result2);
        else if (!refund1 && refund2)
            winloss = getSplittedWinLossPush(result1, 4);
        else winloss = BlackjackHistoryInfo.SPLIT_REFUND_REFUND;
    } else {
        if (refund1) winloss = BlackjackHistoryInfo.REFUND;
        else {
            if (isBust1) winloss = BlackjackHistoryInfo.BUST;
            else {
                if (bj1) winloss = BlackjackHistoryInfo.BJ;
                else winloss = getWinLossPush(result1);
            }
        }
    }
    return winloss;
};
const getWinLossPush = (index: number): BlackjackHistoryInfo => {
    switch (index) {
        case 1:
            return BlackjackHistoryInfo.WIN;
        case 2:
            return BlackjackHistoryInfo.LOSE;
        case 3:
            return BlackjackHistoryInfo.PUSH;
        case 4:
            return BlackjackHistoryInfo.REFUND;
        case 5:
            return BlackjackHistoryInfo.BUST;
        case 0:
        default:
            return BlackjackHistoryInfo.NO_BET;
    }
};
const getSplittedWinLossPush = (hand1: number, hand2: number) => {
    const key1 = getWinLossPushKey(hand1);
    const key2 = getWinLossPushKey(hand2);
    const key = `SPLIT_${key1}_${key2}` as BlackjackHistoryInfoKey;
    return BlackjackHistoryInfo[key];
};
const getWinLossPushKey = (i: number): string => {
    switch (i) {
        case 1:
            return 'WIN';
        case 2:
            return 'LOSE';
        case 3:
            return 'PUSH';
        case 4:
            return 'REFUND';
        case 5:
            return 'BUST';
        default:
            return '';
    }
};
export const getCurrentSeatNo = (
    state: number | BlackjackGameState
): BetRoomSeatIndex | undefined => {
    const gameState =
        typeof state === 'number' ? getBlackjackGameState(state) : state;
    const { mainState, subState } = gameState;
    if (
        mainState === BlackjackPhaseState.InitCard &&
        (isWaitPlayerFirstCard(subState) || isWaitPlayerSecondCard(subState))
    ) {
        return getInitSeatNo(subState) as BetRoomSeatIndex;
    } else if (isPlayerHand(mainState)) {
        return getPhaseSeatNo(mainState) as BetRoomSeatIndex;
    } else return undefined;
};

export const isBeforeGameResult = (s: number) =>
    s >= CommonGameState.GameResult;
export const getMainState = (s: number) => {
    if (isBeforeGameResult(s)) return s;
    return s % 100;
};
export const getSubState = (s: number) => {
    if (isBeforeGameResult(s)) return 0;
    return Math.floor(s / 100);
};

export const isWaitingDecision = (gs: BlackjackGameState) => {
    const { mainState, subState } = gs;
    return (
        isPlayerHand(mainState) &&
        (subState === BlackjackDealtTurnState.WaitSeatPlayerFirstDecision ||
            isWaitDecision(subState))
    );
};

export const isWaitingFollow = ({ subState }: BlackjackGameState) =>
    subState === BlackjackDealtTurnState.WaitSideBetPlayerFollow;

export const isWaitingInsurance = ({ subState }: BlackjackGameState) =>
    subState === BlackjackInitState.WaitSeatPlayerInsurance;

export const isWaitingCard = (gs: BlackjackGameState) => {
    let result: boolean = false;
    if (gs.mainState == Phase.InitCard) {
        if (
            gs.subState == Init.WaitBankerFirstCard ||
            isWaitPlayerFirstCard(gs.subState) ||
            isWaitPlayerSecondCard(gs.subState)
        ) {
            result = true;
        }
    } else if (isHand(gs.mainState)) {
        if (
            gs.subState == BlackjackDealtTurnState.WaitSplitFirstCard ||
            gs.subState == BlackjackDealtTurnState.WaitSplitSecondCard ||
            gs.subState == BlackjackDealtTurnState.WaitBankerSecondCard ||
            gs.subState == BlackjackDealtTurnState.WaitThirdCard ||
            isWaitCard(gs.subState)
        ) {
            result = true;
        }
    }
    return result;
};
export const getBlackjackGameState = (s: number): BlackjackGameState => {
    const mainState = getMainState(s);
    const subState = getSubState(s);
    return { mainState, subState };
};
export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }
    switch (getBlackjackGameState(state).mainState) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Idle:
            return LocalState.IDLE;
        case GameState.Shuffle:
            return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
            return LocalState.DEALING;
    }
    if (isGameResultReleased) {
        return LocalState.RESULT;
    }

    return LocalState.IDLE;
};
export const getDisplaySeat = (sn?: BetRoomSeatIndex) =>
    sn !== undefined ? (sn >= 4 ? sn + 1 : sn) : 0;
export const getBlackjackPoints = (
    hand: Array<number>,
    includeBJ?: boolean
) => {
    const points = new Array<number>();
    const calculatePoints = hand.map(p => GetBaccaratPoint(p));
    const aceCount = calculatePoints.reduce((a, c) => (c === 1 ? a + c : a), 0);

    const point = calculatePoints.reduce((a, c) => a + c, 0);
    if (aceCount > 0 && point + 10 <= 21) {
        points.push(point + 10);
    }
    points.push(point);

    if (points.includes(21)) {
        if (hand.length === 2 && includeBJ) return [255];
        return [21];
    }
    const descPoints = points.sort((a, b) => b - a);
    return descPoints.length > 2 ? descPoints.filter(p => p <= 21) : descPoints;
};
export const getBlackjackLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }
    switch (state) {
        case GameState.Idle:
            return LocalState.IDLE;
        case GameState.Shuffle:
            return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case GameState.GameResult:
            if (isGameResultReleased) {
                return LocalState.RESULT;
            }
            return LocalState.DEALING;
    }
    return LocalState.DEALING;
};
export const getSplit = (mainState: number) => {
    let split = 1;
    if (isPlayerHand(mainState))
        split = ((mainState - BlackjackPhaseState.PlayerHand) % MAX_SPLIT) + 1;
    return split;
};
export const getHandNumber = (gs: BlackjackGameState) => {
    const { mainState } = gs;
    let split = 1;
    if (mainState === BlackjackPhaseState.InitCard) return split;
    else if (isPlayerHand(mainState)) split = getSplit(mainState);
    return split;
};
