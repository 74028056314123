import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
// import { ROAD_MAP_TYPE } from '@/modules/lobby/constants';
import { RootState } from '@/store/store';
export const ROADMAP_TYPE_KEY = 'app::roadmap::roadType';
export type RoadMapTypeMap = {
    gameId: number;
    roadType: number;
};

export const roadmapTypeAdapter = createEntityAdapter<RoadMapTypeMap, number>({
    selectId: rm => rm.gameId,
    sortComparer: (a, b) => a.gameId - b.gameId,
});
const initialRoadmapState = roadmapTypeAdapter.getInitialState();
const roadmapSlice = createSlice({
    name: ROADMAP_TYPE_KEY,
    initialState: initialRoadmapState,
    reducers: {
        updateAll: roadmapTypeAdapter.upsertMany,
        update: roadmapTypeAdapter.upsertOne,
    },
});
export const roadmapTypeSliceReducer = roadmapSlice.reducer;
export const roadmapTypeSliceActions = roadmapSlice.actions;
export const getRoadmapTypeSelectors =
    roadmapTypeAdapter.getSelectors<RootState>(
        state => state[ROADMAP_TYPE_KEY]
    );
export const getRoadmapType = getRoadmapTypeSelectors.selectById;
