import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Voice as VoiceEnum, VoiceFile } from '../models/Voice';
import { Progress } from '../models/games/Progress';
// import { GameCategoryTab } from '../models/lobby/GameCategoryTab';
import { useLocalStorageSetting } from '@/hooks/useLocalStorageSetting';
import { getMainUserState } from '../modules/main/selector';
import { loadingProgressSliceActions } from '../modules/progress/slice/loading';
import { resultProgressSliceActions } from '../modules/progress/slice/result';
import { getSettingSoundState } from '../modules/setting/selector';
import { SOUND_KEY, soundSliceActions } from '../modules/setting/slice/sound';
import { RootState } from '../store/store';
import { CdnContext } from './CdnContext';

type ResourceContextType = {
    banner_1: [];
    banner_2: [];
    logo: {
        '70h': string;
        '200x200': string;
        '200x200loading': string; // rwd
    };
};

type FeatureJsonType = {
    name: string;
    type: number;
    landing: number;
    newTables: [] | undefined;
    section: string[] | undefined;
};

export type ResourceDataType = {
    Banner1: []; //desktop 1
    Banner2: []; //desktop 2 = LobbyType.Credit //可能會有返
    logo70h: string; // desktop banner loading page & mobile
    logo200x200: string; //desktop
    featureJson: [];
    featureSection: string[];
    featureLanding: number;
    featureNewTables: { hostId: number; ts: number }[];
    audioLists: Map<string, string>; //{ [key: string]: HTMLAudioElement };
};

type LogoDataType = {
    Banner1: []; //desktop 1
    Banner2: []; //desktop 2 = LobbyType.Credit //可能會有返
    logo70h: string; // desktop banner loading page & mobile
    logo200x200: string; //desktop
};

type FeatureDataType = {
    featureJson: [];
    featureSection: string[];
    featureLanding: number;
    featureNewTables: { hostId: number; ts: number }[];
};

type AudioDataType = {
    audioLists: Map<string, string>; //{ [key: string]: HTMLAudioElement };
};

const cssLists: string[] = [];

//TODO: audio 未做
const audioFileLists: string[] = [
    //button
    'resource/assets/sound/button/button_click.mp3',
    //chip
    'resource/assets/sound/chips/all_in_sfx.mp3',
    //'resource/assets/sound/chips/bet_area_over.mp3',//no use
    //'resource/assets/sound/chips/Chip_selector_click.mp3',//no use
    //'resource/assets/sound/chips/Chip_selector_over.mp3',//no use
    //'resource/assets/sound/chips/Chips1.mp3',//no use
    //'resource/assets/sound/chips/Chips2.mp3',//no use
    //'resource/assets/sound/chips/Chips3.mp3',//no use
    'resource/assets/sound/chips/ChipsBet.mp3', //TODO
    //bet
    'resource/assets/sound/Beep_1.mp3',
    'resource/assets/sound/{voice}/bet/BettingSuccess.mp3',
    'resource/assets/sound/{voice}/bet/NotEnoughCredit.mp3',
    'resource/assets/sound/{voice}/bet/StartBetting.mp3',
    'resource/assets/sound/{voice}/bet/StopBetting.mp3',
    'resource/assets/sound/{voice}/bet/TieGame.mp3',
    //baccarat
    'resource/assets/sound/{voice}/bacc/BankerWin.mp3',
    'resource/assets/sound/{voice}/bacc/PlayerWin.mp3',
    'resource/assets/sound/{voice}/bacc/PlayerWin.mp3',
    'resource/assets/sound/{voice}/bacc/Banker0.mp3',
    'resource/assets/sound/{voice}/bacc/Banker1.mp3',
    'resource/assets/sound/{voice}/bacc/Banker2.mp3',
    'resource/assets/sound/{voice}/bacc/Banker3.mp3',
    'resource/assets/sound/{voice}/bacc/Banker4.mp3',
    'resource/assets/sound/{voice}/bacc/Banker5.mp3',
    'resource/assets/sound/{voice}/bacc/Banker6.mp3',
    'resource/assets/sound/{voice}/bacc/Banker7.mp3',
    'resource/assets/sound/{voice}/bacc/Banker8.mp3',
    'resource/assets/sound/{voice}/bacc/Banker9.mp3',
    'resource/assets/sound/{voice}/bacc/Player0.mp3',
    'resource/assets/sound/{voice}/bacc/Player1.mp3',
    'resource/assets/sound/{voice}/bacc/Player2.mp3',
    'resource/assets/sound/{voice}/bacc/Player3.mp3',
    'resource/assets/sound/{voice}/bacc/Player4.mp3',
    'resource/assets/sound/{voice}/bacc/Player5.mp3',
    'resource/assets/sound/{voice}/bacc/Player6.mp3',
    'resource/assets/sound/{voice}/bacc/Player7.mp3',
    'resource/assets/sound/{voice}/bacc/Player8.mp3',
    'resource/assets/sound/{voice}/bacc/Player9.mp3',
    //andarbahar
    'resource/assets/sound/{voice}/andarbahar/AndarWin.mp3',
    'resource/assets/sound/{voice}/andarbahar/BaharWin.mp3',
    // //dt
    'resource/assets/sound/{voice}/dt/DragonWin.mp3',
    'resource/assets/sound/{voice}/dt/TigerWin.mp3',
    'resource/assets/sound/{voice}/dt/Dragon1.mp3',
    'resource/assets/sound/{voice}/dt/Dragon2.mp3',
    'resource/assets/sound/{voice}/dt/Dragon3.mp3',
    'resource/assets/sound/{voice}/dt/Dragon4.mp3',
    'resource/assets/sound/{voice}/dt/Dragon5.mp3',
    'resource/assets/sound/{voice}/dt/Dragon6.mp3',
    'resource/assets/sound/{voice}/dt/Dragon7.mp3',
    'resource/assets/sound/{voice}/dt/Dragon8.mp3',
    'resource/assets/sound/{voice}/dt/Dragon9.mp3',
    'resource/assets/sound/{voice}/dt/Dragon10.mp3',
    'resource/assets/sound/{voice}/dt/Dragon11.mp3',
    'resource/assets/sound/{voice}/dt/Dragon12.mp3',
    'resource/assets/sound/{voice}/dt/Dragon13.mp3',
    'resource/assets/sound/{voice}/dt/Tiger1.mp3',
    'resource/assets/sound/{voice}/dt/Tiger2.mp3',
    'resource/assets/sound/{voice}/dt/Tiger3.mp3',
    'resource/assets/sound/{voice}/dt/Tiger4.mp3',
    'resource/assets/sound/{voice}/dt/Tiger5.mp3',
    'resource/assets/sound/{voice}/dt/Tiger6.mp3',
    'resource/assets/sound/{voice}/dt/Tiger7.mp3',
    'resource/assets/sound/{voice}/dt/Tiger8.mp3',
    'resource/assets/sound/{voice}/dt/Tiger9.mp3',
    'resource/assets/sound/{voice}/dt/Tiger10.mp3',
    'resource/assets/sound/{voice}/dt/Tiger11.mp3',
    'resource/assets/sound/{voice}/dt/Tiger12.mp3',
    'resource/assets/sound/{voice}/dt/Tiger13.mp3',
    //roulette
    'resource/assets/sound/{voice}/roulette/0.mp3',
    'resource/assets/sound/{voice}/roulette/1.mp3',
    'resource/assets/sound/{voice}/roulette/2.mp3',
    'resource/assets/sound/{voice}/roulette/3.mp3',
    'resource/assets/sound/{voice}/roulette/4.mp3',
    'resource/assets/sound/{voice}/roulette/5.mp3',
    'resource/assets/sound/{voice}/roulette/6.mp3',
    'resource/assets/sound/{voice}/roulette/7.mp3',
    'resource/assets/sound/{voice}/roulette/8.mp3',
    'resource/assets/sound/{voice}/roulette/9.mp3',
    'resource/assets/sound/{voice}/roulette/10.mp3',
    'resource/assets/sound/{voice}/roulette/11.mp3',
    'resource/assets/sound/{voice}/roulette/12.mp3',
    'resource/assets/sound/{voice}/roulette/13.mp3',
    'resource/assets/sound/{voice}/roulette/14.mp3',
    'resource/assets/sound/{voice}/roulette/15.mp3',
    'resource/assets/sound/{voice}/roulette/16.mp3',
    'resource/assets/sound/{voice}/roulette/17.mp3',
    'resource/assets/sound/{voice}/roulette/18.mp3',
    'resource/assets/sound/{voice}/roulette/19.mp3',
    'resource/assets/sound/{voice}/roulette/20.mp3',
    'resource/assets/sound/{voice}/roulette/21.mp3',
    'resource/assets/sound/{voice}/roulette/22.mp3',
    'resource/assets/sound/{voice}/roulette/23.mp3',
    'resource/assets/sound/{voice}/roulette/24.mp3',
    'resource/assets/sound/{voice}/roulette/25.mp3',
    'resource/assets/sound/{voice}/roulette/26.mp3',
    'resource/assets/sound/{voice}/roulette/27.mp3',
    'resource/assets/sound/{voice}/roulette/28.mp3',
    'resource/assets/sound/{voice}/roulette/29.mp3',
    'resource/assets/sound/{voice}/roulette/30.mp3',
    'resource/assets/sound/{voice}/roulette/31.mp3',
    'resource/assets/sound/{voice}/roulette/32.mp3',
    'resource/assets/sound/{voice}/roulette/33.mp3',
    'resource/assets/sound/{voice}/roulette/34.mp3',
    'resource/assets/sound/{voice}/roulette/35.mp3',
    'resource/assets/sound/{voice}/roulette/36.mp3',
    //sedie
    'resource/assets/sound/{voice}/sedie/0.mp3',
    'resource/assets/sound/{voice}/sedie/1.mp3',
    'resource/assets/sound/{voice}/sedie/2.mp3',
    'resource/assets/sound/{voice}/sedie/3.mp3',
    'resource/assets/sound/{voice}/sedie/4.mp3',
    'resource/assets/sound/{voice}/sedie/Big.mp3',
    'resource/assets/sound/{voice}/sedie/Even.mp3',
    'resource/assets/sound/{voice}/sedie/Odd.mp3',
    'resource/assets/sound/{voice}/sedie/Small.mp3',
    //sicbo
    'resource/assets/sound/{voice}/sicbo/1.mp3',
    'resource/assets/sound/{voice}/sicbo/2.mp3',
    'resource/assets/sound/{voice}/sicbo/3.mp3',
    'resource/assets/sound/{voice}/sicbo/4.mp3',
    'resource/assets/sound/{voice}/sicbo/5.mp3',
    'resource/assets/sound/{voice}/sicbo/6.mp3',
    'resource/assets/sound/{voice}/sicbo/Triple_1.mp3',
    'resource/assets/sound/{voice}/sicbo/Triple_2.mp3',
    'resource/assets/sound/{voice}/sicbo/Triple_3.mp3',
    'resource/assets/sound/{voice}/sicbo/Triple_4.mp3',
    'resource/assets/sound/{voice}/sicbo/Triple_5.mp3',
    'resource/assets/sound/{voice}/sicbo/Triple_6.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_4.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_5.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_6.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_7.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_8.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_9.mp3',
    'resource/assets/sound/{voice}/sicbo/Small_10.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_11.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_12.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_13.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_14.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_15.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_16.mp3',
    'resource/assets/sound/{voice}/sicbo/Big_17.mp3',
    // //teenpatti
    'resource/assets/sound/{voice}/teenpatti/PlayerAWin.mp3',
    'resource/assets/sound/{voice}/teenpatti/PlayerBWin.mp3',
];

export const ResourceContext = createContext({} as ResourceDataType);
const { Provider } = ResourceContext;
type ResourceProviderProps = {
    children: JSX.Element;
};
export const ResourceProvider = (props: ResourceProviderProps) => {
    const { children } = props;
    const [resNum, setResNum] = useState<number>(0);
    const [isAudio, setIsAudio] = useState<boolean>(false);
    // const [isLoadingAudio, setIsLoadingAudio]= useState<boolean>(false);
    const [isLogo, setIsLogo] = useState<boolean>(false);
    const [isFeature, setIsFeature] = useState<boolean>(false);
    const [data, setData] = useState({} as ResourceDataType);

    const [logoData, setLogoData] = useState({} as LogoDataType);
    const [featureData, setFeatureData] = useState({} as FeatureDataType);
    const [audioData, setAudioData] = useState({} as AudioDataType);
    // const [loading, setLoading] = useState(false);
    // console.log(loading);
    const { bannerCdn, feature, videoPlayerUrl } = useContext(CdnContext);
    const { LobbyCode, CurrencyName } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const { Voice2 } = useSelector(getSettingSoundState);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const audioInit = useRef<number>(0);

    const { cachedSetting } = useLocalStorageSetting();

    useEffect(() => {
        if (videoPlayerUrl) {
            dispatch(
                loadingProgressSliceActions.startLoad(
                    Progress.STATIC_PRELOAD_VIDEO
                )
            );
            let tmp = document.createElement('script');
            tmp.setAttribute('src', videoPlayerUrl);
            tmp.onload = () => {
                dispatch(
                    resultProgressSliceActions.endLoad(
                        Progress.STATIC_PRELOAD_VIDEO
                    )
                );
            };
            document.head.appendChild(tmp);
        }
    }, [videoPlayerUrl]);
    useEffect(() => {
        const loadCss = async () => {
            dispatch(
                loadingProgressSliceActions.startLoad(
                    Progress.STATIC_PRELOAD_CSS
                )
            );
            setResNum(0);
            let n = 0;
            for (let path of cssLists) {
                if (path.includes('{lang')) {
                    path = path.replace('{lang}', i18n.language);
                }

                const imagePath = path.replace('.scss', `.png`);
                import(`../${path}`).then(() => {
                    import(`../${imagePath}`).then(bg => {
                        if (bg && bg.default) {
                            const img = new Image();
                            img.onload = () => {
                                import(`../${path}`).then(css => {
                                    if (css) {
                                        n++;
                                        setResNum(n);
                                    }
                                });
                            };
                            img.src = bg.default;
                        }
                    });
                });
                // await sleep(1000);
            }
        };

        if (i18n.language) {
            loadCss();
        }
    }, [i18n.language]);
    const voiceRef = useRef(false);
    useEffect(() => {
        dispatch(
            loadingProgressSliceActions.startLoad(Progress.STATIC_PRELOAD_AUDIO)
        );
        const loadAudioLists = async () => {
            if (audioFileLists) {
                const filePath = VoiceFile[Voice2] ?? VoiceFile[4]; //4 = english 1
                // const newData: ResourceDataType = Object.create(data ?? null);
                const audioMaps = new Map<string, string>();
                // let a = 0;
                audioFileLists.map(f => {
                    let fullPath = `${process.env.PUBLIC_URL}/${f}`;
                    if (fullPath.includes('{voice')) {
                        fullPath = fullPath.replace('{voice}', filePath);
                    }
                    const fps = fullPath.split('/');
                    const k = (fps[fps.length - 2] +
                        '.' +
                        fps.pop()?.split('.')[0]) as string;
                    // const audio = new Audio(fullPath);
                    // audio.preload = 'auto';
                    // audio.load();
                    // audio.addEventListener('canplaythrough', () => {
                    //     a++;
                    //     if (audioFileLists.length === a) {
                    //         setIsAudio(true);
                    //     }
                    // });
                    // audioMaps.set(k, audio);
                    audioMaps.set(k, fullPath);
                });
                // newData.audioLists = audioMaps;
                const newData = Object.create(audioData);
                newData.audioLists = audioMaps;
                setIsAudio(true);
                setAudioData(newData);
                dispatch(
                    resultProgressSliceActions.endLoad(
                        Progress.STATIC_PRELOAD_AUDIO
                    )
                );
            }
        };
        if (cachedSetting !== undefined) {
            const lsVoice = cachedSetting['app::setting::sound'].Voice2;
            if (lsVoice === Voice2) {
                // if (!voiceRef.current) {
                //     voiceRef.current = true;
                //     loadAudioLists();
                // }
                loadAudioLists();
            } else {
                audioInit.current++;
                if (audioInit.current >= 3) {
                    console.warn(
                        '!!user.setting data not match local storage!!'
                    );
                }
            }
        } else {
            // if (!voiceRef.current) {
            //     voiceRef.current = true;
            //     loadAudioLists();
            // }
            loadAudioLists();
        }
    }, [Voice2, cachedSetting]);

    useEffect(() => {
        const updateCmsData = (cmsData: ResourceContextType) => {
            const logo70h = cmsData.logo['70h'];
            const logo200x200 = cmsData.logo['200x200loading'];
            //desktop version 用：
            if (!isLogo) {
                setIsLogo(true);
                setLogoData(d => {
                    d.Banner1 = []; //desktop 1
                    d.Banner2 = [];
                    d.logo200x200 = bannerCdn + logo200x200; //`https://cdn.stag2.slgaming.net/cmsstag/${logo200x200}`;
                    d.logo70h = bannerCdn + logo70h;
                    return d;
                });
            }
            // setLoading(false);
        };

        const loadDefaultData = async () => {
            const url = `${bannerCdn}images/cmsData/default.json`;
            fetch(url)
                .then(response => {
                    response
                        .json()
                        .then(data => {
                            updateCmsData(data);
                        })
                        .catch(err => {
                            console.error(
                                'app::context::resource',
                                'parse data',
                                err
                            );
                        });
                })
                .catch(err => {
                    console.error(
                        'app::context::resource',
                        'cms - can not load default.json',
                        err
                    );
                });
        };

        const loadCmsData = async () => {
            if (bannerCdn) {
                const lc = LobbyCode ? LobbyCode.toLowerCase() : '';
                const url = `${bannerCdn}images/cmsData/cms_data.json`;
                fetch(url)
                    .then(response => {
                        if (response.status === 200) {
                            response.json().then(cmsData => {
                                if (cmsData.includes(lc)) {
                                    loadCmsDataByLanguageCode(lc);
                                } else {
                                    loadDefaultData();
                                }
                            });
                        } else {
                            setIsLogo(true);
                        }
                    })
                    .catch(err => {
                        setIsLogo(true);
                        console.error(
                            'app::context::resource',
                            `cms - can not load cms_data.json`,
                            err
                        );
                    });
            }
        };

        const loadCmsDataByLanguageCode = (languageCode: string) => {
            const url = `${bannerCdn}images/cmsData/${languageCode}.json`;
            fetch(url)
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            updateCmsData(data);
                        });
                    }
                })
                .catch(err => {
                    loadDefaultData();
                    console.error(
                        'app::context::resource',
                        `cms - can not load ${languageCode}.json`,
                        err
                    );
                });
        };

        const loadFeature = async () => {
            if (feature && CurrencyName && LobbyCode) {
                try {
                    const response = await fetch(feature);
                    const featureJson = await response.json();
                    let sectionTmp: FeatureJsonType['section'];
                    let landingTmp: number = 10;
                    let newTablesTmp: FeatureJsonType['newTables'] = [];
                    featureJson.profiles?.map((v: FeatureJsonType) => {
                        const { name, landing, newTables, section, type } = v;
                        if (
                            (!sectionTmp && name === 'Default' && type === 0) ||
                            (name === CurrencyName && type === 1) ||
                            (name === LobbyCode && type === 2)
                        ) {
                            sectionTmp = section;
                            landingTmp = Number(landing);
                            newTablesTmp = newTables;
                        }
                    });
                    let featureNewTables: { hostId: number; ts: number }[] = [];
                    if (newTablesTmp) {
                        newTablesTmp?.forEach(
                            (v: { hostId: string; validDate: string }) => {
                                const nHostId = v.hostId;
                                const validDate = v.validDate;
                                featureNewTables.push({
                                    hostId: Number(nHostId),
                                    ts:
                                        (Number(validDate) + 8 * 60 * 60) *
                                        1000,
                                });
                            }
                        );
                    }

                    let section: string[] = [];
                    sectionTmp?.map(v => {
                        if (v === 'newTables') {
                            section.push(v);
                        } else if (featureJson[v]) {
                            section = section.concat(featureJson[v]);
                        } else {
                            section.push(v);
                        }
                    });
                    // data.featureJson = featureJson;

                    if (!isFeature) {
                        setIsFeature(true);
                        setFeatureData(d => {
                            d.featureSection = section;
                            d.featureLanding = landingTmp;
                            d.featureNewTables = featureNewTables;
                            return d;
                        });
                        // if (localStorage.getItem('lobby_ui_tab') == undefined) {
                        //     switch (landingTmp) {
                        //         case 8: //Bacc all
                        //             localStorage.setItem(
                        //                 'lobby_ui_tab',
                        //                 GameCategoryTab.Baccarat.toString()
                        //             );
                        //             break;
                        //         case 10: //feature
                        //             localStorage.setItem(
                        //                 'lobby_ui_tab',
                        //                 GameCategoryTab.Featured.toString()
                        //             );
                        //             break;
                        //         case 4: //other
                        //             localStorage.setItem(
                        //                 'lobby_ui_tab',
                        //                 GameCategoryTab.OtherGames.toString()
                        //             );
                        //             break;
                        //     }
                        // }
                    }
                } catch (e) {
                    setIsFeature(true);
                    console.error('Feature.json error');
                }
            }
        };
        if (LobbyCode && bannerCdn && feature && CurrencyName && data) {
            loadCmsData();
            loadFeature();
        }
    }, [LobbyCode, bannerCdn, feature, CurrencyName, data]);

    useEffect(() => {
        if (CurrencyName) {
            const settingJson = window.localStorage.getItem('user.setting');
            if (settingJson) {
                const userSetting = JSON.parse(settingJson);
                const settingSound = userSetting[SOUND_KEY];
                if (settingSound.Voice2 == undefined) {
                    if (CurrencyName == 'BRL') {
                        dispatch(
                            soundSliceActions.updateAll({
                                Voice2: VoiceEnum.BrazilianPortuguese,
                            })
                        );
                    } else if (settingSound['Voice'] != undefined) {
                        dispatch(
                            soundSliceActions.updateAll({
                                Voice2: settingSound['Voice'],
                            })
                        );
                    } else if (Object.keys(settingSound).length == 0) {
                        dispatch(
                            soundSliceActions.updateAll({
                                Voice2: VoiceEnum.English1,
                            })
                        );
                    }
                }
            }
        }
    }, [CurrencyName]);

    useEffect(() => {
        if (resNum === cssLists.length) {
            dispatch(
                resultProgressSliceActions.endLoad(Progress.STATIC_PRELOAD_CSS)
            );

            setResNum(0);
        }
    }, [resNum]);

    useEffect(() => {
        let newData = JSON.parse(JSON.stringify(data));
        newData.audioLists = audioData.audioLists;
        setData(newData);
    }, [audioData]);

    useEffect(() => {
        setData(d => {
            d.featureSection = featureData.featureSection;
            d.featureLanding = featureData.featureLanding;
            d.featureNewTables = featureData.featureNewTables;
            return d;
        });
    }, [featureData]);

    useEffect(() => {
        if (isAudio && isLogo && isFeature) {
            const newData = Object.create(data);
            newData.audioLists = audioData.audioLists;
            newData.Banner1 = logoData.Banner1;
            newData.Banner2 = logoData.Banner2;
            newData.logo200x200 = logoData.logo200x200;
            newData.logo70h = logoData.logo70h;
            newData.featureSection = featureData.featureSection;
            newData.featureLanding = featureData.featureLanding;
            newData.featureNewTables = featureData.featureNewTables;
            setData(newData);
            if (voiceRef.current) voiceRef.current = false;
        }
    }, [isAudio, isLogo, isFeature]);

    return <Provider value={data}>{children}</Provider>;
};
