export enum GameResult {
    RRZero,
    RROne,
    RRTwo,
    RRThree,
    RRFour,
    RRFive,
    RRSix,
    RRSeven,
    RREight,
    RRNine,
    RRTen,
    RREleven,
    RRTwelve,
    RRThirteen,
    RRFourteen,
    RRFifteen,
    RRSixteen,
    RRSeventeen,
    RREighteen,
    RRNineteen,
    RRTwenty,
    RRTwentyOne,
    RRTwentyTwo,
    RRTwentyThree,
    RRTwentyFour,
    RRTwentyFive,
    RRTwentySix,
    RRTwentySeven,
    RRTwentyEight,
    RRTwentyNine,
    RRThirty,
    RRThirtyOne,
    RRThirtyTwo,
    RRThirtyThree,
    RRThirtyFour,
    RRThirtyFive,
    RRThirtySix,

    //Special
    RRSet1,
    RRSet2,
    RRSet3,
    RRRow1,
    RRRow2,
    RRRow3,
    RR1To18,
    RR19To36,
    RROdd,
    RREven,
    RRRed,
    RRBlack,
}
