import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const VOLUME_KEY = 'app::setting::volume';
export type VolumeState = {
    Studio: number;
    Game: number;
    Mute: boolean;
    // Music: number;
};
const initialVolumeState = {
    Studio: 50,
    Game: 0,
    Mute: false,
    // Music: 50,
} as VolumeState;
const updateStudio = (state: VolumeState, action: PayloadAction<number>) => {
    state.Studio = action.payload;
};
const updateGame = (state: VolumeState, action: PayloadAction<number>) => {
    state.Game = action.payload;
};
const updateMute = (state: VolumeState, action: PayloadAction<boolean>) => {
    state.Mute = action.payload;
};
// const updateMusic = (state: VolumeState, action: PayloadAction<number>) => {
//     state.Music = action.payload;
// };
const updateAll = (state: VolumeState, action: PayloadAction<VolumeState>) => {
    state.Studio = action.payload.Studio;
    state.Game = action.payload.Game;
    // state.Music = action.payload.Music;
};
const volumeSlice = createSlice({
    name: VOLUME_KEY,
    initialState: initialVolumeState,
    reducers: {
        updateStudio,
        updateGame,
        updateMute,
        // updateMusic,
        updateAll,
    },
});
export const volumeSliceReducer = volumeSlice.reducer;
export const volumeSliceActions = volumeSlice.actions;
