import { CMDTopResultPlayer } from "./CMDTopResultPlayer"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpMoneywheelTopResultPlayer implements ICommand
{

	public HostID : number = 0;
	public GameID : number = 0;
	public TopResultPlayer : Array<CMDTopResultPlayer>;

	public constructor()
	{
		this.TopResultPlayer = new Array<CMDTopResultPlayer>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.GameID = ba.readInt53();
		let TopResultPlayerSize:number = ba.readUint8();
		while (TopResultPlayerSize-- > 0)
		{
			let TopResultPlayerChild:CMDTopResultPlayer = new CMDTopResultPlayer();
			TopResultPlayerChild.fromBytes(ba);
			this.TopResultPlayer.push(TopResultPlayerChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint53(this.GameID);
		ba.writeUint8(this.TopResultPlayer.length);
		for (const TopResultPlayerChild of this.TopResultPlayer)
		{
			let tempBuf = TopResultPlayerChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpMoneywheelTopResultPlayer();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'TopResultPlayer_size(B)='+this.TopResultPlayer.length + ' ';
		str += 'TopResultPlayer(CMDTopResultPlayer)=[';
		for (let TopResultPlayerChild in this.TopResultPlayer)
		{
			if (<number><unknown>TopResultPlayerChild > 0) str += ', ';
			str += this.TopResultPlayer[TopResultPlayerChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50083;
	}

	public getCmdName() : string
	{
		return 'SpMoneywheelTopResultPlayer';
	}

}
