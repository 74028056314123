import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScBlackjackSeatInfo implements ICommand
{

	public HostID : number = 0;
	public Seat1PlayerID : number = 0;
	public Seat2PlayerID : number = 0;
	public Seat3PlayerID : number = 0;
	public Seat4PlayerID : number = 0;
	public Seat5PlayerID : number = 0;
	public Seat6PlayerID : number = 0;
	public Seat7PlayerID : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Seat1PlayerID = ba.readUint53();
		this.Seat2PlayerID = ba.readUint53();
		this.Seat3PlayerID = ba.readUint53();
		this.Seat4PlayerID = ba.readUint53();
		this.Seat5PlayerID = ba.readUint53();
		this.Seat6PlayerID = ba.readUint53();
		this.Seat7PlayerID = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint53(this.Seat1PlayerID);
		ba.writeUint53(this.Seat2PlayerID);
		ba.writeUint53(this.Seat3PlayerID);
		ba.writeUint53(this.Seat4PlayerID);
		ba.writeUint53(this.Seat5PlayerID);
		ba.writeUint53(this.Seat6PlayerID);
		ba.writeUint53(this.Seat7PlayerID);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScBlackjackSeatInfo();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Seat1PlayerID(Q)='+this.Seat1PlayerID+' ';
		str += 'Seat2PlayerID(Q)='+this.Seat2PlayerID+' ';
		str += 'Seat3PlayerID(Q)='+this.Seat3PlayerID+' ';
		str += 'Seat4PlayerID(Q)='+this.Seat4PlayerID+' ';
		str += 'Seat5PlayerID(Q)='+this.Seat5PlayerID+' ';
		str += 'Seat6PlayerID(Q)='+this.Seat6PlayerID+' ';
		str += 'Seat7PlayerID(Q)='+this.Seat7PlayerID+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30772;
	}

	public getCmdName() : string
	{
		return 'ScBlackjackSeatInfo';
	}

}
