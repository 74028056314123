import { StateMsgType } from '@/models/Popup';
import { GameType as GameTypeEnum } from '@/models/games/enums/GameType';
import { Bet } from '@/models/host/BetAmount';
import {
    getTableBetByHostId,
    tableBetSliceActions,
} from '@/modules/betTable/slice';
import { getGameState } from '@/modules/games/selector';
import { getHostById } from '@/modules/host/slice';
import { inGamePopupSliceActions } from '@/modules/popup/inGameSlice';
import { RootState } from '@/store/store';
import { checkBetsAvailed } from '@/utils/commonFunc';
import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useBalance } from './useBalance';
import { useGameFeature } from './useGameFeature';

const OPPOSITE_BETTING_VALIDATION_GAME_TYPE =
    GameTypeEnum.Baccarat |
    GameTypeEnum.SeDie |
    GameTypeEnum.AndarBahar |
    GameTypeEnum.TeenPatti2020;
type Props = {
    hostId: number;
    amountOverBetLimit?: (betType: number, chipAmount: number) => number;
    getHedgeBettingBets: (bets: Array<Bet>) => Array<Bet>;
    currentPage?: number;
    betTypesByPage?: Array<Array<number>>;
    disableBetTypes?: Array<number>;
};
export const useNBSBetAction = ({
    hostId,
    amountOverBetLimit,
    getHedgeBettingBets,
    currentPage, // no need if 1 page
    betTypesByPage, // no need if 1 page
    disableBetTypes,
}: Props) => {
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    // const { doubleBet } = useSelector(getGameState);
    const { GameId } = useSelector((state: RootState) =>
        getTableBetByHostId(state, hostId)
    );
    const addingBet = useRef(false);
    const { isAutoConfirmEnabled } = useGameFeature(
        GameType === GameTypeEnum.Blackjack
    );
    const { totalHostsBetInfo } = useSelector(getGameState);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const { balance } = useBalance();
    const withAllin = useRef<boolean>(false);
    const [lastBetPage, setLastBetPage] = useState<number>(-1);
    const dispatch = useDispatch();
    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            dispatch(
                tableBetSliceActions.addBet({
                    hostId: hostId,
                    gameId: GameId,
                    bets: bets,
                })
            );
        });
    };
    useEffect(() => {
        if (toAddBets) {
            let unconfirmedTotalBet = 0;
            let unconfirmedTotalBetWithHold = 0;
            if (totalHostsBetInfo) {
                if (isAutoConfirmEnabled) {
                    unconfirmedTotalBet = totalHostsBetInfo.totalBetAmount;
                    unconfirmedTotalBetWithHold =
                        totalHostsBetInfo.totalWithHoldAmount;
                } else {
                    unconfirmedTotalBet = totalHostsBetInfo.pendingBetAmount;
                    unconfirmedTotalBetWithHold =
                        totalHostsBetInfo.pendingWithHoldAmount;
                }
            }
            let availableAfterHedgeBetting = false;
            if ((OPPOSITE_BETTING_VALIDATION_GAME_TYPE & GameType) > 0) {
                const newToAddBets = getHedgeBettingBets(toAddBets);
                const specialCheck = checkBetsAvailed({
                    hostId: hostId,
                    gameType: GameType,
                    bets: newToAddBets,
                    amountOverBetLimit: amountOverBetLimit,
                    totalPendingBetAmount: unconfirmedTotalBet,
                    totalPendingWithHoldAmount: unconfirmedTotalBetWithHold,
                    availableBalance: balance,
                    disableBetTypes: disableBetTypes,
                });
                if (
                    !specialCheck.isOutOfBetLimit &&
                    !specialCheck.haveBetLimitAllIn
                ) {
                    availableAfterHedgeBetting = true;
                }
                //
            }
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                gameType: GameType,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: unconfirmedTotalBet,
                totalPendingWithHoldAmount: unconfirmedTotalBetWithHold,
                availableBalance: balance,
                disableBetTypes: disableBetTypes,
                availableAfterHedgeBetting: availableAfterHedgeBetting,
            });
            if (availableBets.isNotEnoughMoney && withAllin.current) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.not_enough_money',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Fail,
                            gameId: GameId,
                        })
                    );
                });
            } else if (availableBets.isOutOfBetLimit && withAllin.current) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.bet_out_of_limit_red',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Fail,
                            gameId: GameId,
                        })
                    );
                });
            } else if (
                availableBets.isNotEnoughMoney ||
                availableBets.isAllIn
            ) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.not_enough_money',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Fail,
                            gameId: GameId,
                        })
                    );
                });
            } else if (
                !availableAfterHedgeBetting &&
                !withAllin.current &&
                (availableBets.isOutOfBetLimit ||
                    availableBets.haveBetLimitAllIn)
            ) {
                batch(() => {
                    dispatch(
                        inGamePopupSliceActions.updateIsOpen({
                            hostId: hostId,
                            isOpen: true,
                        })
                    );
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.bet_out_of_limit_red',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Fail,
                            gameId: GameId,
                        })
                    );
                });
            } else {
                if (withAllin.current) {
                    if (availableBets.haveDisabledBetType) {
                        batch(() => {
                            dispatch(
                                inGamePopupSliceActions.updateIsOpen({
                                    hostId: hostId,
                                    isOpen: true,
                                })
                            );
                            dispatch(
                                inGamePopupSliceActions.updateDataSet({
                                    hostId: hostId,
                                    key: 'system.have_cannot_rebet_zone',
                                    gameType: GameType,
                                    stateMsgType: StateMsgType.betInfo_Warning,
                                    gameId: GameId,
                                })
                            );
                        });
                    } else if (
                        availableBets.haveBetLimitAllIn &&
                        !availableBets.isAllIn
                    ) {
                        batch(() => {
                            dispatch(
                                inGamePopupSliceActions.updateIsOpen({
                                    hostId: hostId,
                                    isOpen: true,
                                })
                            );
                            dispatch(
                                inGamePopupSliceActions.updateDataSet({
                                    hostId: hostId,
                                    key: 'system.bet_exceed_limit_allin',
                                    gameType: GameType,
                                    stateMsgType: StateMsgType.betInfo_Warning,
                                    gameId: GameId,
                                })
                            );
                        });
                    } else if (availableBets.isAllIn) {
                        batch(() => {
                            dispatch(
                                inGamePopupSliceActions.updateIsOpen({
                                    hostId: hostId,
                                    isOpen: true,
                                })
                            );
                            dispatch(
                                inGamePopupSliceActions.updateDataSet({
                                    hostId: hostId,
                                    key: 'system.all_in',
                                    gameType: GameType,
                                    stateMsgType: StateMsgType.betInfo_Warning,
                                    gameId: GameId,
                                })
                            );
                        });
                    }
                }
                let toBets = availableBets.bets;
                let _page = -1;
                if (betTypesByPage) {
                    if (
                        currentPage != undefined &&
                        betTypesByPage.length > currentPage &&
                        currentPage >= 0
                    ) {
                        toBets.forEach(v => {
                            if (
                                betTypesByPage[currentPage].findIndex(type => {
                                    type === v.Type;
                                }) >= 0
                            ) {
                                _page = currentPage;
                            }
                        });
                    }

                    if (_page < 0) {
                        //not find
                        toBets.forEach(v => {
                            for (let i = 0; i < betTypesByPage.length; i++) {
                                if (
                                    betTypesByPage[i].findIndex(
                                        type => type === v.Type
                                    ) >= 0
                                ) {
                                    _page = i;
                                    break;
                                }
                            }
                        });
                    }
                    setLastBetPage(_page);
                }
                doPreBet(toBets);
                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.updateDataSet({
                            hostId: hostId,
                            key: 'system.have_cannot_rebet_zone',
                            gameType: GameType,
                            stateMsgType: StateMsgType.betInfo_Warning,
                            gameId: GameId,
                        })
                    );
                }
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);

    const _doPendingBets = (bets: Array<Bet>) => {
        withAllin.current = false;
        if (bets.length > 0 && !toAddBets && !addingBet.current) {
            addingBet.current = true;
            setToAddBets(bets);
        }
    };

    const _doPendingBetsWithAllin = (bets: Array<Bet>) => {
        withAllin.current = true;
        if (bets.length > 0 && !toAddBets && !addingBet.current) {
            addingBet.current = true;
            setToAddBets(bets);
        }
    };

    return {
        lastBetPage,
        setLastBetPage,
        _doPendingBets,
        _doPendingBetsWithAllin,
    };
};
