import { CMDBet } from "./CMDBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsNbsBet implements ICommand
{

	public betFormat : number = 0;
	public gameID : number = 0;
	public BetSource : number = 0;
	public betSeqID : number = 0;
	public bets : Array<CMDBet>;

	public constructor()
	{
		this.bets = new Array<CMDBet>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.betFormat = ba.readUint16();
		this.gameID = ba.readInt53();
		this.BetSource = ba.readUint32();
		this.betSeqID = ba.readUint53();
		let betsSize:number = ba.readUint8();
		while (betsSize-- > 0)
		{
			let betsChild:CMDBet = new CMDBet();
			betsChild.fromBytes(ba);
			this.bets.push(betsChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.betFormat);
		ba.writeUint53(this.gameID);
		ba.writeUint32(this.BetSource);
		ba.writeUint53(this.betSeqID);
		ba.writeUint8(this.bets.length);
		for (const betsChild of this.bets)
		{
			let tempBuf = betsChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsNbsBet();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'betFormat(W)='+this.betFormat+' ';
		str += 'gameID(Q)='+this.gameID+' ';
		str += 'BetSource(D)='+this.BetSource+' ';
		str += 'betSeqID(Q)='+this.betSeqID+' ';
		str += 'bets_size(B)='+this.bets.length + ' ';
		str += 'bets(CMDBet)=[';
		for (let betsChild in this.bets)
		{
			if (<number><unknown>betsChild > 0) str += ', ';
			str += this.bets[betsChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 55098;
	}

	public getCmdName() : string
	{
		return 'PsNbsBet';
	}

}
