import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const BET_RECORD_KEY = 'app::report::record';
export type Bet = {
    Balance: number;
    BetAmount: number;
    BetID: number;
    BetType: number;
    GameCount: number;
    Shoes: number;
    Round: number;
    GameID: number;
    GameType: number;
    HostID: number;
    IsAutoBet: number;
    Remark: string;
    Result: string;
    ResultPlayer: number[];
    ResultBanker: number[];
    ResultAmount: number;
    State: number;
    Date: string;
    Time: string;
    WinSlot: number;
    RollingAmount : number;
};
export type BetRecordTotalResult = {
    bets: Array<Bet>;
    totalAmount: number;
    gameTypeLists: number[];
};
export type BetRecordState = {
    records: Record<string, BetRecordTotalResult>;
    total: number;
};
const updateTotal = (state: BetRecordState, actions: PayloadAction<number>) => {
    state.total = actions.payload;
    state.records = {};
};
const updateRecord = (
    state: BetRecordState,
    actions: PayloadAction<{
        records: BetRecordState['records'];
    }>
) => {
    state.records = actions.payload.records;
};
const reset = (state: BetRecordState) => {
    state.records = initialBetRecordState.records;
    state.total = initialBetRecordState.total;
};
const initialBetRecordState = {} as BetRecordState;
const betRecordSlice = createSlice({
    name: BET_RECORD_KEY,
    initialState: initialBetRecordState,
    reducers: {
        updateTotal,
        updateRecord,
        reset,
    },
});
export const betRecordSliceReducer = betRecordSlice.reducer;
export const betRecordSliceActions = betRecordSlice.actions;
