import { Dispatch } from 'react';
import { ICommand, CMDSpChangePasswordReturn } from '../live';
import { AbstractHandler } from './AbstractHandler';
import {
    ResultState,
    resultSliceActions,
} from '../../../modules/main/slice/result';
import { AnyAction } from 'redux';
import { popupSliceActions } from '../../../modules/popup/slice';

export class CMDSpChangePasswordReturnHandler extends AbstractHandler {
    _command: CMDSpChangePasswordReturn;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpChangePasswordReturn;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        if (this._command.ReturnCode === this.SUCCESS_CODE) {
            const data = {} as ResultState;
            data.isPasswordChange = true;
            dispatch(resultSliceActions.updateData(data));
        } else {
            dispatch(popupSliceActions.open('password.password_update_fail'));
            return;
        }
    }
}
