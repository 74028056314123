import { BetType as BetTypeEnum } from '@/models/games/dragonTiger/BetType';

export const isOppositeBetting = (
    betType: number,
    betsSummary: Array<number>
): boolean => {
    if (betsSummary === undefined) {
        return false;
    }
    switch (betType) {
        case BetTypeEnum.DTBDragonWin:
            return (
                betsSummary[BetTypeEnum.DTBTigerWin] != undefined &&
                betsSummary[BetTypeEnum.DTBTigerWin] > 0
            );
        case BetTypeEnum.DTBTigerWin:
            return (
                betsSummary[BetTypeEnum.DTBDragonWin] != undefined &&
                betsSummary[BetTypeEnum.DTBDragonWin] > 0
            );
        default:
            return false;
    }
};
