import { Button, ButtonProps } from "@mui/material";
import { useContext } from "react";
import { AudioPlayContext } from "../../../../contexts/AudioPlayContext";

export const PromotionButton = (props?: ButtonProps) => {
    const { playButtonAudio } = useContext(AudioPlayContext);
    const {
        ...rest
    } = props || {};

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (rest.onClick) {
            rest.onClick(event);
        }
        playButtonAudio();
    };

    return (
        <Button
            onClick={handleClick}
            {...rest}
        >
            {rest.children}
        </Button>
    );
};