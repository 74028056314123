import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsPlayerPattern implements ICommand
{

	public Pattern : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Pattern = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.Pattern);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsPlayerPattern();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Pattern[50](C)='+this.Pattern+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55067;
	}

	public getCmdName() : string
	{
		return 'PsPlayerPattern';
	}

}
