import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { betRoomSliceActions } from '../../../modules/bet/room/slice';
import { gameSliceActions } from '../../../modules/games/slice';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScRoundCancel, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
// import { gameSliceActions } from '../../../modules/games/slice';

export class CMDScRoundCancelHandler extends AbstractHandler {
    _command: CMDScRoundCancel;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScRoundCancel;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        // const currentGame = getGameState(state);
        const host = getHostByGameId(state, this._command.GameID);
        if (host) {
            batch(() => {
                dispatch(
                    hostsSliceActions.updateGameState({
                        hostId: host.HostId,
                        state: GameState.RoundCancel,
                    })
                );
                dispatch(
                    hostsSliceActions.updateIsRoundCancel({
                        hostId: host.HostId,
                        isRoundCancel: true,
                    })
                );
                // if (currentGame.currentGameId === this._command.GameID) {
                //     dispatch(gameSliceActions.resetAll());
                // }
                dispatch(
                    tableBetSliceActions.clearBets({
                        hostId: host.HostId,
                        gameId: this._command.GameID,
                    })
                );
                dispatch(
                    gameSliceActions.updateHostBetInfo({
                        hostId: host.HostId,
                        totalBetAmount: 0,
                        totalWithHoldAmount: 0,
                        pendingBetAmount: 0,
                        pendingWithHoldAmount: 0,
                    })
                );
                // dispatch(
                //     gameSliceActions.setOnceEvent({
                //         gameId: this._command.GameID,
                //         event: 'round_cancel',
                //     })
                // );
                dispatch(betRoomSliceActions.resetBet(host.HostId));
                dispatch(
                    gameSliceActions.updateBaccaratBetHistoryCancel(
                        this._command.GameID
                    )
                );
            });
        }
    }
}
