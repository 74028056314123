import { CMDSpBaccaratGoodRoad } from '../../models/cmd/live';
import {
    BaccaratGoodRoad,
    BaccaratRecord,
    BaccaratResult,
} from '../../models/games/baccarat';
import { BetType } from '../../models/games/baccarat/BetType';
import { GameState as BaccaratState } from '../../models/games/baccarat/GameState';
import { GameState } from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { SupportedGameResult } from '../../modules/host/models';
import { isBitActive } from '../bitwiseUtil';
import { GetTotalBaccaratPoint } from '../pokerCount';

const getWinBetType = (result: number): Array<number> => {
    let arr = [];
    let activeType = [
        BetType.BBPlayerWin,
        BetType.BBBankerWin,
        BetType.BBTie,
        BetType.BBPlayerPair,
        BetType.BBBankerPair,
        BetType.BBPlayerNatural,
        BetType.BBBankerNatural,
        BetType.BBSSSuperSix,
        BetType.BBSSPlayerWin,
        BetType.BBSSBankerWin,
        BetType.BBSSTie,
        BetType.BBSSPlayerPair,
        BetType.BBSSBankerPair,
        BetType.BBSSPlayerNatural,
        BetType.BBSSBankerNatural,
        BetType.BBCowPlayerWin,
        BetType.BBCowBankerWin,
        BetType.BBCowTie,
    ];
    for (let i = 0; i < activeType.length; i++) {
        if (isBitActive(result, activeType[i])) {
            arr.push(activeType[i]);
        }
    }
    return arr;
};

export const convertResultToRecord = (
    result: BaccaratResult,
    isInit: boolean = false
): BaccaratRecord => {
    if (isInit) {
        result.PlayerCards = result.PlayerCards.map(c => c - 1);
        result.BankerCards = result.BankerCards.map(c => c - 1);
    }
    const playerPoint = GetTotalBaccaratPoint(result.PlayerCards);
    const bankerPoint = GetTotalBaccaratPoint(result.BankerCards);
    const record: BaccaratRecord = {
        gameID: result.GameID,
        result: result.Result,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        resultPoint: Math.max(playerPoint, bankerPoint),
        diffPoint: Math.abs(playerPoint - bankerPoint),
        player1: result.PlayerCards[0],
        player2: result.PlayerCards[1],
        player3: result.PlayerCards[2],
        banker1: result.BankerCards[0],
        banker2: result.BankerCards[1],
        banker3: result.BankerCards[2],
        playerWin: isBitActive(result.Result, BetType.BBPlayerWin),
        bankerWin: isBitActive(result.Result, BetType.BBBankerWin),
        tie: isBitActive(result.Result, BetType.BBTie),
        playerPair: isBitActive(result.Result, BetType.BBPlayerPair),
        bankerPair: isBitActive(result.Result, BetType.BBBankerPair),
        playerNatural: isBitActive(result.Result, BetType.BBPlayerNatural),
        bankerNatural: isBitActive(result.Result, BetType.BBBankerNatural),
        superSix: isBitActive(result.Result, BetType.BBSSSuperSix),
        superSixPlayer: isBitActive(result.Result, BetType.BBSSPlayerWin),
        superSixBanker: isBitActive(result.Result, BetType.BBSSBankerWin),
        superSixTie: isBitActive(result.Result, BetType.BBSSTie),
        superSixPlayerPair: isBitActive(result.Result, BetType.BBSSPlayerPair),
        superSixBankerPair: isBitActive(result.Result, BetType.BBSSBankerPair),
        superSixPlayerNatural: isBitActive(
            result.Result,
            BetType.BBSSPlayerNatural
        ),
        superSixBankerNatural: isBitActive(
            result.Result,
            BetType.BBSSBankerNatural
        ),
        playerCowWin: isBitActive(result.Result, BetType.BBCowPlayerWin),
        bankerCowWin: isBitActive(result.Result, BetType.BBCowBankerWin),
        cowTie: isBitActive(result.Result, BetType.BBCowTie),
        playerPoint: playerPoint,
        bankerPoint: bankerPoint,
        winBetType: getWinBetType(result.Result),
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    result?: number
): BaccaratRecord => {
    const results = resultString.split(',').map(s => Number(s));

    const pIndex = results[0];
    const playerCards = results.slice(1, pIndex + 1);
    const bankerCards = results.slice(pIndex + 2, results.length);

    const { FResult, GameID, Result, GameCount } = currentResult;
    const updateResult: BaccaratResult = {
        BankerCards: bankerCards,
        PlayerCards: playerCards,
        FResult: FResult,
        GameCount: GameCount,
        GameID: GameID,
        Result: result ?? Result,
    };

    const resultRecord = convertResultToRecord(updateResult);

    return resultRecord;
};

export const convertGoodRoad = (goodRoad: CMDSpBaccaratGoodRoad) => {
    const newRoad = {} as BaccaratGoodRoad;
    newRoad.HostID = goodRoad.HostID;
    newRoad.BigRoadSingleBanker = goodRoad.BigRoadSingleBanker === 1;
    newRoad.BigRoadSinglePlayer = goodRoad.BigRoadSinglePlayer === 1;
    newRoad.ClapClapRoad = goodRoad.ClapClapRoad === 1;
    newRoad.ConsecutiveBankers = goodRoad.ConsecutiveBankers === 1;
    newRoad.ConsecutivePlayers = goodRoad.ConsecutivePlayers === 1;
    newRoad.LongBanker = goodRoad.LongBanker === 1;
    newRoad.LongPlayer = goodRoad.LongPlayer === 1;
    newRoad.NonConsecutiveBankers = goodRoad.NonConsecutiveBankers === 1;
    newRoad.NonConsecutivePlayers = goodRoad.NonConsecutivePlayers === 1;
    newRoad.OneLoungeTwoRoomsBanker = goodRoad.OneLoungeTwoRoomsBanker === 1;
    newRoad.OneLoungeTwoRoomsPlayer = goodRoad.OneLoungeTwoRoomsPlayer === 1;
    newRoad.TwoRoomsOneLoungeBanker = goodRoad.TwoRoomsOneLoungeBanker === 1;
    newRoad.TwoRoomsOneLoungePlayer = goodRoad.TwoRoomsOneLoungePlayer === 1;
    return newRoad;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean,
    isRoundCancel: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }

    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Idle:
            return LocalState.IDLE;
        case GameState.Shuffle:
            if (isRoundCancel) return LocalState.ROUND_CANCEL;
            return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case BaccaratState.WaitPlayerFirstCard:
        case BaccaratState.WaitBankerFirstCard:
        case BaccaratState.WaitPlayerSecondCard:
        case BaccaratState.WaitBankerSecondCard:
        case BaccaratState.AdditionStart:
        case BaccaratState.WaitPlayerAdditionCard:
        case BaccaratState.WaitBankerAdditionCard:
        case BaccaratState.AdditionSqueezeCard:
        case BaccaratState.SqueezeCardEnd:
        case BaccaratState.PlayerWin:
            return isGameResultReleased
                ? LocalState.PLAYER
                : LocalState.DEALING;
        case BaccaratState.BankerWin:
            return isGameResultReleased
                ? LocalState.BANKER
                : LocalState.DEALING;
        case BaccaratState.Draw:
            return isGameResultReleased ? LocalState.TIE : LocalState.DEALING;
    }
    return LocalState.IDLE;
};
