import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { betRoomSliceActions } from '../../../modules/bet/room/slice';
import { getHostByGameId } from '../../../modules/host/slice';
import { getMainPlayerState } from '../../../modules/main/selector';
import store from '../../../store/store';
import { GameType } from '../../games/enums/GameType';
import { Bet } from '../../host/BetAmount';
import { CMDSpInitBet, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDSpInitBetHandler extends AbstractHandler {
    _command: CMDSpInitBet;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpInitBet;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        const player = getMainPlayerState(state);
        const Bets = this._command.Bet.map(
            b =>
                ({
                    Type: b.BetType,
                    Amount: b.BetAmount,
                    GameId: this._command.GameID,
                }) as Bet
        );
        if (host?.GameType === GameType.Blackjack) {
            dispatch(
                betRoomSliceActions.bet({
                    HostId: host.HostId,
                    PlayerId: player.Id,
                    Bets: Bets,
                    ClearBehind: false,
                    Seat: 0,
                })
            );
        }
        let newConfirmedBets = new Array<number>();
        Bets.forEach(bet => {
            newConfirmedBets[bet.Type] = bet.Amount;
        });

        batch(() => {
            if (host) {
                console.log(`hook::socket::run::${host.HostId}`, this._command);
                // dispatch(
                //     hostsSliceActions.updateConfirmedBets({
                //         hostId: host.HostId,
                //         gameId: this._command.GameID,
                //         bets: Bet,
                //     })
                // );
                dispatch(
                    tableBetSliceActions.updateConfirmedBets({
                        hostId: host.HostId,
                        gameId: this._command.GameID,
                        bets: newConfirmedBets,
                    })
                );
            } else {
                console.warn(
                    'hook::socket::warning::cannot::find::host',
                    this._command.GameID
                );
            }
            // dispatch(
            //     gameSliceActions.onConfirmedBetResult({
            //         gameId: this._command.GameID,
            //         bet: Bet,
            //     })
            // );
        });
    }
}
