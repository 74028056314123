import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsLotteryTicketDetail implements ICommand
{

	public BetID : Array<number>;

	public constructor()
	{
		this.BetID = new Array<number>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let BetIDSize:number = ba.readUint8();
		while (BetIDSize-- > 0)
		{
			let BetIDChild:number = ba.readInt53();
			this.BetID.push(BetIDChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.BetID.length);
		for (const BetIDChild of this.BetID)
		{
			ba.writeUint53(BetIDChild);
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsLotteryTicketDetail();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetID_size(B)='+this.BetID.length + ' ';
		str += 'BetID(Q)=[';
		for (let BetIDChild in this.BetID)
		{
			if (<number><unknown>BetIDChild > 0) str += ', ';
			str += this.BetID[BetIDChild];
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 55036;
	}

	public getCmdName() : string
	{
		return 'PsLotteryTicketDetail';
	}

}
