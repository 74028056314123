import { CMDHostWithSetting } from "./CMDHostWithSetting"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpHostList implements ICommand
{

	public Host : Array<CMDHostWithSetting>;

	public constructor()
	{
		this.Host = new Array<CMDHostWithSetting>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let HostSize:number = ba.readUint8();
		while (HostSize-- > 0)
		{
			let HostChild:CMDHostWithSetting = new CMDHostWithSetting();
			HostChild.fromBytes(ba);
			this.Host.push(HostChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Host.length);
		for (const HostChild of this.Host)
		{
			let tempBuf = HostChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpHostList();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Host_size(B)='+this.Host.length + ' ';
		str += 'Host(CMDHostWithSetting)=[';
		for (let HostChild in this.Host)
		{
			if (<number><unknown>HostChild > 0) str += ', ';
			str += this.Host[HostChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50012;
	}

	public getCmdName() : string
	{
		return 'SpHostList';
	}

}
