import { ByteArray } from "../models/cmd";

export const isBitActive = (val: number, bitIndex: number): boolean => {
    let active: boolean = false;
    if (bitIndex < 32) {
        active = ((1 << bitIndex) & val) != 0;
    } else {
        let high: number = Math.floor(val / 0x100000000);
        active = ((1 << (bitIndex - 32)) & high) != 0;
    }
    return active;
};

export const bytesToBits = (bytes:ByteArray):boolean[] => {
    const result:boolean[] = [];
    let byte:number, i:number;
    // Ensure that the bytes array is not null and has data
    if (!bytes || bytes.byteLength == 0) {
        return result;
    }

    // Store the current position and reset the position to 0
    const currentPosition = bytes.position;
    bytes.position = 0;
    // Read bytes and convert them to bits
    while (bytes.position < bytes.byteLength) {
        byte = bytes.readUint8();
        for (i = 0; i < 8; i++) {
        result.push((byte & (1 << i)) !== 0);
        }
    }

    // Restore the original position
    bytes.position = currentPosition;

    return result;
}