import { BetChipContext } from '@/contexts/BetChipContext';
import { WebSocketContext } from '@/contexts/WebSocketContext';
import { CMDPsNbsBet } from '@/models/cmd/live';
import { CMDBet } from '@/models/cmd/live/CMDBet';
import { GameState } from '@/models/games/enums/GameState';
import { Bet, BetFormat } from '@/models/host/BetAmount';
import { ROUTE_LOBBY } from '@/models/Route';
import {
    getTableBetByHostId,
    tableBetSliceActions,
} from '@/modules/betTable/slice';
import { getGameState } from '@/modules/games/selector';
import { gameSliceActions } from '@/modules/games/slice';
import { getHostById } from '@/modules/host/slice';
import { getLandingState, getMainUserState } from '@/modules/main/selector';
import { inGamePopupSliceActions } from '@/modules/popup/inGameSlice';
import { popupSliceActions } from '@/modules/popup/slice';
import { routeSliceActions } from '@/modules/routeController/slice';
import { RootState } from '@/store/store';
import { useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useBetSource } from './useBetSource';
import { useIsBettingInfo } from './useIsBettingInfo';
import { useIsMultiBet } from './useIsMultiBet';
import { useNBSBet } from './useNBSBet';
import { useNBSBetAction } from './useNBSBetAction';

const AUTO_BET_RESULT_TIMEOUT: number = 3000;
export const TABLE_BET_KEY = 'table_bet';
export const useTableBet = (hostId: number) => {
    const {
        // GameType,
        MaxBet,
        ConfirmedBets,
        _doPendingBet,
        _doCancelBet,
        _doConfirmBet,
        _doUndoBet,
        amountOverBetLimit,
        getHedgeBettingBets,
        isAutoConfirmEnabled,
    } = useNBSBet(hostId);
    const { reBet, doubleBet } = useSelector(getGameState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [betTypesByPage, setBetTypesByPage] =
        useState<Array<Array<number>>>();
    const [disableBetTypes, setDisableBetTypes] = useState<Array<number>>([]);
    const { selectedBetAmount, selectedBetRule, setPendingBetState } =
        useContext(BetChipContext);
    const isMultiBet = useIsMultiBet();
    const betSource = useBetSource(isMultiBet);
    const { sendCommand } = useContext(WebSocketContext);
    const [PrevBets, setPrevBets] = useState<Array<number>>();
    const dispatch = useDispatch();

    const [noBetCount, setNoBetCount] = useState(0);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { IsDemo } = useSelector(getMainUserState);

    const { isSupportStorage } = useSelector((state: RootState) =>
        getLandingState(state)
    );
    const lastTable = useRef<number>();
    const {
        lastBetPage,
        setLastBetPage,
        _doPendingBets,
        _doPendingBetsWithAllin,
    } = useNBSBetAction({
        hostId,
        amountOverBetLimit,
        getHedgeBettingBets,
        currentPage, // no need if 1 page
        betTypesByPage, // no need if 1 page
        disableBetTypes,
    });
    const {
        GameId,
        BetsSummary,
        PendingBets,
        TableTotalBet,
        PendingTotalBet,
        isWaitingBet,
        BetSeqId,
        PlayerBets,
        // minAmount,
    } = useSelector((state: RootState) => getTableBetByHostId(state, hostId));
    const { isBetting } = useIsBettingInfo(hostId);
    const timerRef = useRef<NodeJS.Timeout>();

    const onAutoBetResultNotReceive = () => {
        if (isAutoConfirmEnabled) {
            postMessage({ type: 3 });
        }
    };
    const leaveTable = () => {
        //cancel pending with last table
        if (lastTable.current && isAutoConfirmEnabled === false) {
            dispatch(
                tableBetSliceActions.clearPendingBets({
                    hostId: lastTable.current,
                    gameId: GameId,
                    startGame: false,
                })
            );
            setPendingBetState(lastTable.current, false);
        }
    };
    useEffect(() => {
        if (doubleBet) {
            let bets = new Array<Bet>();
            for (let i = 0; i < BetsSummary.length; i++) {
                if (BetsSummary[i]) {
                    let bet = {
                        GameId: GameId,
                        Type: i,
                        Amount: BetsSummary[i],
                    } as Bet;
                    bets.push(bet);
                }
            }
            for (let i = 0; i < ConfirmedBets.length; i++) {
                if (ConfirmedBets[i]) {
                    let bet = bets.find(v => v.Type == i);
                    if (bet) {
                        bet.Amount += ConfirmedBets[i];
                    } else {
                        bet = {
                            GameId: GameId,
                            Type: i,
                            Amount: ConfirmedBets[i],
                        } as Bet;
                        bets.push(bet);
                    }
                }
            }
            if (bets && bets.length > 0) {
                _doPendingBets(bets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet]);
    useEffect(() => {
        if (reBet) {
            if (PrevBets) {
                let bets = new Array<Bet>();
                for (let i = 0; i < PrevBets.length; i++) {
                    if (PrevBets[i]) {
                        let bet = {
                            GameId: GameId,
                            Type: i,
                            Amount: PrevBets[i],
                        } as Bet;
                        bets.push(bet);
                    }
                }
                if (bets && bets.length > 0) {
                    _doPendingBets(bets);
                }
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet]);
    useEffect(() => {
        return () => {
            lastTable.current = hostId;
            leaveTable();
        };
    }, [isAutoConfirmEnabled, GameId]);
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, []);
    useEffect(() => {
        //ABC only
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }
        if (ConfirmedBets && ConfirmedBets.length) {
            setPrevBets([...ConfirmedBets]);
            setNoBetCount(0);
        }
    }, [ConfirmedBets]);
    useEffect(() => {
        if (isAutoConfirmEnabled) {
            //ABC only
            if (!isBetting) {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = undefined;
                }
                if (
                    TableTotalBet //&&
                    // selectedBetRule &&
                    // minAmount &&
                    // minAmount > selectedBetRule.MinBet
                ) {
                    //have bet
                    timerRef.current = setTimeout(() => {
                        onAutoBetResultNotReceive();
                    }, AUTO_BET_RESULT_TIMEOUT);
                    // } else if (TableTotalBet) {
                    //     //below min bet
                    //     batch(() => {
                    //         dispatch(
                    //             tableBetSliceActions.clearPendingBets({
                    //                 hostId: hostId,
                    //                 gameId: GameId,
                    //             })
                    //         );
                    //         dispatch(
                    //             inGamePopupSliceActions.updateIsOpen({
                    //                 hostId: hostId,
                    //                 isOpen: true,
                    //             })
                    //         );
                    //         dispatch(
                    //             inGamePopupSliceActions.updateDataSet({
                    //                 hostId: hostId,
                    //                 key: 'system.bet_below_win_bet',
                    //                 gameType: GameType,
                    //                 stateMsgType: StateMsgType.betInfo_Fail,
                    //                 gameId: GameId,
                    //             })
                    //         );
                    //     });
                }
            }
        }
    }, [isBetting]);
    useEffect(() => {
        if (isAutoConfirmEnabled && selectedBetRule && BetSeqId) {
            const cmd = new CMDPsNbsBet();
            cmd.gameID = GameId;
            cmd.BetSource = betSource;
            cmd.betFormat = BetFormat.AUTO_BET;
            cmd.betSeqID = BetSeqId;
            // let belowBetLimit = false;
            for (let i = 0; i < BetsSummary.length; i++) {
                if (BetsSummary[i]) {
                    let cmdBet = new CMDBet();
                    cmdBet.betType = i;
                    cmdBet.betAmount = BetsSummary[i];
                    // if (cmdBet.betAmount < selectedBetRule.MinBet) {
                    //     belowBetLimit = true;
                    // }
                    cmd.bets.push(cmdBet);
                }
            }

            if (isSupportStorage) {
                window.localStorage.setItem(
                    `${TABLE_BET_KEY}_${hostId}`,
                    `${BetSeqId};${GameId};${JSON.stringify(PlayerBets)}`
                );
            } else {
                window.sessionStorage.setItem(
                    `${TABLE_BET_KEY}_${hostId}`,
                    `${BetSeqId};${GameId};${JSON.stringify(PlayerBets)}`
                );
            }

            //not check, need send if undo
            // if (!belowBetLimit) {
            //     sendCommand(cmd);
            // }
            sendCommand(cmd);
        }
    }, [BetSeqId]);

    useEffect(() => {
        leaveTable();
        lastTable.current = hostId;
        setNoBetCount(0);
    }, [hostId, GameId]);

    useEffect(() => {
        const isBetting = host.CurrentState === GameState.Betting;
        if (isBetting && !IsDemo && !isMultiBet) {
            setNoBetCount(noBetCount + 1);
        }
    }, [host.CurrentState]);

    useEffect(() => {
        if (noBetCount >= 6) {
            dispatch(routeSliceActions.goto(ROUTE_LOBBY));
            dispatch(popupSliceActions.open('system.back_to_lobby'));
        }
    }, [noBetCount]);

    const _doRouletteBet = (name: string | null) => {
        if (!selectedBetRule) {
            batch(() => {
                dispatch(
                    inGamePopupSliceActions.updateIsOpen({
                        hostId: hostId,
                        isOpen: true,
                    })
                );
                dispatch(gameSliceActions.onResetRoulSelectedTypes());
            });
            return;
        }
        const betTypeVal = name ? name.split('_')[0] : '';
        const containNumVal = name ? name.split('_')[1] : '';
        const betTypes =
            Number(betTypeVal) >= 1000
                ? containNumVal.split(',')
                : [Number(betTypeVal)];
        let bets = new Array<Bet>();
        betTypes.forEach(type => {
            let bet = bets.find(v => v.Type == type);
            if (bet) {
                bet.Amount += selectedBetAmount;
            } else {
                bet = {
                    GameId: GameId,
                    Type: type,
                    Amount: selectedBetAmount,
                } as Bet;
                bets.push(bet);
            }
        });
        if (bets && bets.length > 0) {
            _doPendingBetsWithAllin(bets);
            // if (bets.length === 1) {
            //     _doPendingBet(bets[0]);
            // } else {
            //     _doPendingBets(bets);
            // }
        }
    };

    return {
        hostId,
        isAutoConfirmEnabled,
        _doPendingBet,
        _doRouletteBet, //For Roulette string input bets
        _doPendingBets, //For multi bet type, any bet type not available will cancel all bets
        _doCancelBet,
        _doConfirmBet,
        _doUndoBet,
        lastBetPage,
        setLastBetPage,
        setCurrentPage,
        setBetTypesByPage,
        disableBetTypes,
        setDisableBetTypes,
        GameId,
        MaxBet,
        PrevBets,
        ConfirmedBets,
        PendingBets,
        BetsSummary,
        TableTotalBet,
        PendingTotalBet,
        isWaitingBet,
    };
};
export type useTableBetState = ReturnType<typeof useTableBet>;
