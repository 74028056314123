export enum Progress {
    SERVER_BALANCE = 1 << 0,
    SERVER_PLAYER_COUNT = 1 << 1,
    SERVER_COUNT = 1 << 2,
    SERVER_USER_BET_LIMIT = 1 << 3,
    SERVER_USER_INFO_RESULT = 1 << 4,
    SERVER_HOST_LIST = 1 << 5,
    CDN_CONFIG_DATA = 1 << 6,
    CDN_COMMON_CONFIG_DATA = 1 << 7,
    CDN_GAME_CONFIG_DATA = 1 << 8,
    STATIC_PRELOAD_CSS = 1 << 9,
    STATIC_PRELOAD_AUDIO = 1 << 10,
    STATIC_PRELOAD_VIDEO = 1 << 11,
}
export type ProgressLoadingState = Record<Progress, boolean>;
export const getInitialProgressState = (): ProgressLoadingState => ({
    [Progress.SERVER_BALANCE]: false,
    [Progress.SERVER_PLAYER_COUNT]: false,
    [Progress.SERVER_COUNT]: false,
    [Progress.SERVER_USER_BET_LIMIT]: false,
    [Progress.SERVER_USER_INFO_RESULT]: false,
    [Progress.SERVER_HOST_LIST]: false,
    [Progress.CDN_CONFIG_DATA]: false,
    [Progress.CDN_COMMON_CONFIG_DATA]: false,
    [Progress.CDN_GAME_CONFIG_DATA]: false,
    [Progress.STATIC_PRELOAD_CSS]: false,
    [Progress.STATIC_PRELOAD_AUDIO]: false,
    [Progress.STATIC_PRELOAD_VIDEO]: false,
});
export const isCommandDone = (state: ProgressLoadingState) =>
    state[Progress.SERVER_BALANCE] &&
    state[Progress.SERVER_COUNT] &&
    state[Progress.SERVER_HOST_LIST] &&
    state[Progress.SERVER_PLAYER_COUNT] &&
    state[Progress.SERVER_USER_BET_LIMIT] &&
    state[Progress.SERVER_USER_INFO_RESULT];
export const isConfigDone = (state: ProgressLoadingState) =>
    state[Progress.CDN_COMMON_CONFIG_DATA] &&
    state[Progress.CDN_CONFIG_DATA] &&
    state[Progress.CDN_GAME_CONFIG_DATA];
export const isAssetDone = (state: ProgressLoadingState) =>
    state[Progress.STATIC_PRELOAD_AUDIO] &&
    state[Progress.STATIC_PRELOAD_CSS] &&
    state[Progress.STATIC_PRELOAD_VIDEO];
