import { tableBetSliceActions } from '@/modules/betTable/slice';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { gameSliceActions } from '../../../modules/games/slice';
import { getHostById, hostsSliceActions } from '../../../modules/host/slice';
import { popupSliceActions } from '../../../modules/popup/slice';
import store from '../../../store/store';
import { CMDSpForceStand, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpForceStandHandler extends AbstractHandler {
    _command: CMDSpForceStand;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpForceStand;
    }
    handle(dispatch: Dispatch<Action>): void {
        const state = store.getState();
        const host = getHostById(state, this._command.HostID);
        if (host) {
            batch(() => {
                dispatch(
                    hostsSliceActions.updateCountDown({
                        hostId: host.HostId,
                        countDown: host.GameDelay
                            ? host.GameDelay.kickNoBetPlayer
                            : 0,
                        canLeave: false,
                    })
                );
                if (host.CurrentResult) {
                    dispatch(popupSliceActions.open('blackjack.force_stand'));
                    dispatch(
                        tableBetSliceActions.clearBets({
                            hostId: host.HostId,
                            gameId: host.CurrentResult.GameID,
                        })
                    );
                    dispatch(
                        gameSliceActions.updateHostBetInfo({
                            hostId: host.HostId,
                            totalBetAmount: 0,
                            totalWithHoldAmount: 0,
                            pendingBetAmount: 0,
                            pendingWithHoldAmount: 0,
                        })
                    );
                }
            });
        }
    }
}
