import moment from 'moment';
import { createContext, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

// import { IOS } from 'ua-parser-js';
import { useUserAgent } from '../hooks/useUserAgent';
import { FlvChannel, IvsChannel } from '../models/Video';
import { DefaultConfig } from '../models/config/CdnRawConfig';
import { Progress } from '../models/games/Progress';
import { gameSliceActions } from '../modules/games/slice';
import { loadingProgressSliceActions } from '../modules/progress/slice/loading';
import { resultProgressSliceActions } from '../modules/progress/slice/result';

export const CdnContext = createContext({} as CdnConfig);
const { Provider } = CdnContext;
type CdnProviderProps = {
    children: JSX.Element;
};
type VideoInfo = {
    url: string;
    type: string;
    id: string;
};
export type CdnConfig = {
    lobbyServer: Array<string>;
    lobbyPort: Array<number>;
    controlServer: Array<string>;
    controlPort: Array<number>;
    ipServer: string;
    dealerImage: string;
    bannerCdn: string;
    cdnId: number;
    feature: string;
    playbackUrl: string;
    gameRuleUrl: string;
    ivsChannel: Record<string, IvsChannel>;
    ivsUrl: string;
    flvChannel: Array<FlvChannel>;
    flvUrl: string;
    hlsChannel: Array<FlvChannel>;
    hlsUrl: string;
    // videoID: string;
    feedbackApi: string;
    lvsAPI: string;
    //Promotion tool
    promotionCdn: string;
    videoPlayerUrl: string;
    // videoType: string;
    myVideoChannel: Array<VideoInfo>;
};
// const isOverIOS15 = (os: IOS) => {
//     if (os.name?.toLowerCase() === 'ios') {
//         const major = os.version?.split('.')[0];
//         return Number(major) >= 15;
//     }
//     return false;
// };

const getLobbyPortConfig = (portConfig: string) => {
    const ports = new Array<number>();
    const RANGE_SPLITTER = '-';
    const CONFIG_SPLITTER = ',';
    if (
        portConfig.includes(RANGE_SPLITTER) ||
        portConfig.includes(CONFIG_SPLITTER)
    )
        for (let config of portConfig.split(CONFIG_SPLITTER)) {
            if (config.includes(RANGE_SPLITTER)) {
                const splitted = config.split(RANGE_SPLITTER);
                const from = Number(splitted[0]);
                const to = Number(splitted[1]);
                for (let i = from; i <= to; i++) ports.push(i);
            } else {
                ports.push(Number(config));
            }
        }
    else {
        ports.push(Number(portConfig));
    }
    if (ports.length === 0)
        console.log(
            'app::provider::cdn',
            'cdn does not config lobby server port'
        );
    return ports;
};
export const CdnProvider = (props: CdnProviderProps) => {
    const {
        device: { vendor },
    } = useUserAgent();
    const { children } = props;
    const [config, setConfig] = useState<CdnConfig>({} as CdnConfig);
    // const { os } = useUserAgent();
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [jsonStr, setJsonStr] = useState<string>();
    const [country, setCountry] = useState<string>();
    const requestIpWithUrl = (url: string) => {
        const timestamp = moment().valueOf();
        if (url)
            fetch(`${url}?v=${timestamp}`)
                .then(response => {
                    if (response.ok) {
                        response.text().then(raw => {
                            const json = JSON.parse(raw);
                            setCountry(json.CountryCode);
                        });
                    }
                })
                .catch(err => {
                    setCountry('default');
                    console.log(
                        'app::context::cdn',
                        'cannot get request ip',
                        err
                    );
                });
    };
    useEffect(() => {
        dispatch(
            loadingProgressSliceActions.startLoad(Progress.CDN_CONFIG_DATA)
        );
        const timestamp = moment().unix() * 1000;
        const url = process.env.REACT_APP_CDN_API;
        if (url)
            fetch(`${url}/cdn.dat?v=${timestamp}`)
                .then(response => {
                    if (response.ok) {
                        response.text().then(raw => {
                            const j = atob(raw);
                            // const o = JSON.parse(j);
                            setJsonStr(j);
                        });
                    }
                })
                .catch(err => {
                    console.log('app::context::cdn', 'cannot get cdn.dat', err);
                });
    }, []);
    useEffect(() => {
        if (jsonStr) {
            const json = JSON.parse(jsonStr);
            // cdn config assumption
            // 0: requestIp
            // 1: lobby
            // 2: default
            // >2: override config

            if (!country) {
                requestIpWithUrl(json.requestIp);
                return;
            }
            const keys = Object.keys(json);
            const index = keys.indexOf('default');
            const key = keys.slice(index).findIndex(k => k.includes(country));
            const values = Object.values(json).slice(index);
            // merged default and override config
            let target = {} as unknown;
            try {
                if (key !== -1) target = values.at(key);
            } catch {
                console.warn('app::provider::cdn', target);
            }

            const mergedCdn: DefaultConfig = Object.assign(
                { ...json.default },
                target
            );
            console.log('app::provider::cdn', country, mergedCdn);
            const cdn = {} as CdnConfig;
            cdn.ipServer = json.requestIp;
            cdn.dealerImage =
                mergedCdn.server.dealerUrl.slice(-1) !== '/'
                    ? mergedCdn.server.dealerUrl + '/'
                    : mergedCdn.server.dealerUrl;
            // config lobby server
            const lobbyServers = mergedCdn.server.ls;
            cdn.lobbyServer = lobbyServers; //.map(d => d.split(',')[0]);
            cdn.lobbyPort = getLobbyPortConfig(mergedCdn.server.port);
            // config control server
            // cdn.controlServer = mergedCdn.server.cs.map(cs => cs.host);
            // cdn.controlPort = mergedCdn.server.cs.map(cs => cs.port);
            let bannerCdn = mergedCdn.banner.cdn;
            bannerCdn =
                bannerCdn.slice(-1) === '/' ? bannerCdn : bannerCdn + '/';
            cdn.bannerCdn = bannerCdn;
            cdn.cdnId = Number(mergedCdn.cdn_id);
            cdn.feature = mergedCdn.feature;
            cdn.playbackUrl = mergedCdn.playback;
            cdn.gameRuleUrl = mergedCdn.gameRule.url;
            cdn.ivsChannel = mergedCdn.ivs;
            cdn.feedbackApi = mergedCdn.feedback;
            cdn.lvsAPI = mergedCdn.lvsAPI;

            let lastStream = '';
            const ivsConfig = mergedCdn.video.AWS_IVS;
            const flvConfig = mergedCdn.video.FlvJs;
            const hlsConfig = mergedCdn.video.CMAFHlsjs;

            // if (ivsConfig) {
            //     cdn.ivsUrl = ivsConfig.url;
            // }
            if (flvConfig) {
                cdn.flvChannel = mergedCdn.video.appName.FlvJs;
                // cdn.flvUrl = flvConfig.url;
            }
            if (hlsConfig) {
                cdn.hlsChannel = mergedCdn.video.appName.CMAFHlsjs;
                // cdn.hlsUrl = hlsConfig.url;
            }
            cdn.videoPlayerUrl = `${mergedCdn.player.url}/${mergedCdn.player.version}/MultiPlayer.min.js`;
            const list =
                vendor === 'Apple' && mergedCdn.video.iOS
                    ? mergedCdn.video.iOS.split(',')
                    : mergedCdn.video.default.split(',');

            let channelList = new Array<VideoInfo>();
            list.forEach(c => {
                switch (c) {
                    case 'FlvJs':
                        if (flvConfig) {
                            channelList = channelList.concat(
                                flvConfig.map(v => {
                                    return {
                                        type: c,
                                        url: v.url,
                                        id: v.id,
                                    };
                                })
                            );
                        }
                        break;
                    case 'CMAFHlsjs':
                        if (hlsConfig) {
                            channelList = channelList.concat(
                                hlsConfig.map(v => {
                                    return {
                                        type: c,
                                        url: v.url,
                                        id: v.id,
                                    };
                                })
                            );
                        }
                        break;
                    case 'AWS_IVS':
                        if (ivsConfig) {
                            channelList = channelList.concat(
                                ivsConfig.map(v => {
                                    return {
                                        type: c,
                                        url: v.url,
                                        id: v.id,
                                    };
                                })
                            );
                        }
                        break;
                }
            });
            cdn.myVideoChannel = channelList;

            if (channelList.length > 0) {
                lastStream = channelList[0].url;
            }
            // cdn.videoType =
            //     vendor === 'Apple' && mergedCdn.video.iOS
            //         ? mergedCdn.video.iOS.split(',')[0]
            //         : mergedCdn.video.default.split(',')[0];
            // switch (cdn.videoType) {
            //     case 'FlvJs':
            //         if (flvConfig) {
            //             lastStream = flvConfig.url;
            //             cdn.videoID = flvConfig.id;
            //         }
            //         break;
            //     case 'CMAFHlsjs':
            //         if (hlsConfig) {
            //             lastStream = hlsConfig.url;
            //             cdn.videoID = hlsConfig.id;
            //         }
            //         break;
            //     case 'AWS_IVS':
            //         if (ivsConfig) {
            //             lastStream = ivsConfig.url;
            //             cdn.videoID = ivsConfig.id;
            //         }
            //         break;
            // }
            batch(() => {
                dispatch(
                    gameSliceActions.setVideoLibraryVersion(
                        mergedCdn.player.version
                    )
                );
                dispatch(gameSliceActions.setLastVideo(lastStream));
            });

            //Promotion tool
            let promotionCdn = mergedCdn.promotion.cdn;
            promotionCdn =
                promotionCdn.slice(-1) === '/'
                    ? promotionCdn
                    : promotionCdn + '/';
            cdn.promotionCdn = promotionCdn;
            console.log('app::provider::cdn', country, cdn, mergedCdn);
            setConfig(cdn);
            dispatch(
                resultProgressSliceActions.endLoad(Progress.CDN_CONFIG_DATA)
            );
        }
    }, [jsonStr, country]);

    return <Provider value={config}>{jsonStr && children}</Provider>;
};
