import {
    useGameInfoButtonController,
    useGameInfoButtonControllerState,
} from '@/hooks/useGameInfoButton';
import { createContext } from 'react';

export const GameInfoButtonControllerContext = createContext(
    {} as useGameInfoButtonControllerState
);
const { Provider } = GameInfoButtonControllerContext;
type GameInfoButtonControllerProps = {
    children: JSX.Element | Array<JSX.Element>;
};
export const GameInfoButtonControllerProvider = (
    props: GameInfoButtonControllerProps
) => {
    const { children } = props;
    const controller = useGameInfoButtonController();
    return <Provider value={controller}>{children}</Provider>;
};
