import { useFullScreen, useFullScreenState } from '@/hooks/useFullScreen';
import { createContext } from 'react';

export const FullScreenContext = createContext({} as useFullScreenState);
const { Provider } = FullScreenContext;
type FullScreenProps = {
    children: JSX.Element | Array<JSX.Element>;
};
export const FullScreenProvider = ({ children }: FullScreenProps) => {
    const fs = useFullScreen();
    return <Provider value={fs}>{children}</Provider>;
};
