import { CMDScPlayerCount, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class NotImplementHandler extends AbstractHandler {
    _command: ICommand;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScPlayerCount;
    }
    handle(): void {
        console.log('hook::socket::not::handle', this._command);
    }
}
