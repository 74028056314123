import { VoiceList } from './Voice';
import { GameState as CommonGameState } from './games/enums/GameState';
import { GameType as GameTypeEnum } from './games/enums/GameType';
import { LocalState as LocalStateEnum } from './games/enums/LocalState';

export enum POPUP_TYPE {
    ALERT, // only confirm button
    CONFIRM, // confirm & cancel button
    LEAVE_ALERT, // only confirm button & Route to Thanks Page after Confirm
    THANK_ALERT, // only confirm button & Route to Thanks Page after Confirm & Not clear data e.g. LS connection fail
    LOGOUT_CONFIRM, // confirm & cancel button
    POPUP, // no button
}

export enum POPUP_CONFIRM_ACTION {
    NONE,
    RETRY,
}

export enum StateMsgType {
    betInfo_Normal = 0,
    betInfo_Success = 1,
    betInfo_Fail = 2,
    betResult_Player = 3,
    betResult_Banker = 4,
    betResult_Tie = 5,
    betResult_Dragon = 6,
    betResult_Tiger = 7,
    betInfo_Warning = 8,
    betResult = 9,
    betResult_Andar,
    betResult_Bahar,
    betResult_BJ_win,
    betResult_BJ_lose,
    betResult_SmallOdd,
    betResult_SmallEven,
    betResult_BigOdd,
    betResult_BigEven,
    betResult_Even,
}

export const MESSAGE_TIME = 4 * 1000;
export const BETTING_TIME = 2 * 1000;

export type BaseResultPopupProps = {
    message: string;
    bg: InGamePopupProps;
    audioKey: string;
    gameType: GameTypeEnum;
    // top: string;
    localState?: number;
    playSound?: boolean;
};
export type BetResultPopupProps = BaseResultPopupProps & {
    betStatusMsg: StateMsgType;
    params?: Record<string, string>;
};
export type GameResultPopupProps = BaseResultPopupProps & {
    amount: string;
};

export type InGamePopupProps = {
    background: string;
    backdropFilter: string;
};
const defaultBackground =
    'linear-gradient(90deg, rgba(28, 28, 28, 0.00) 0%, rgba(28, 28, 28, 0.90) 25%, rgba(28, 28, 28, 0.90) 75.5%, rgba(28, 28, 28, 0.00) 100%)';
const commonBackground = {
    background: defaultBackground,
    backdropFilter: 'blur(2px)',
} as InGamePopupProps;

export const getBaccaratKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.SHUFFLING:
            message = 'game.shuffling';
            break;
        case LocalStateEnum.PLAYER:
            message = 'game.player';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(79, 111, 166, 0.00) 0%, rgba(79, 111, 166, 0.90) 25%, rgba(79, 111, 166, 0.90) 75.5%, rgba(79, 111, 166, 0.00) 100%)';
            audioKey = VoiceList.PlayerWin;
            break;
        case LocalStateEnum.BANKER:
            message = 'game.banker';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(201, 77, 84, 0.00) 0%, rgba(201, 77, 84, 0.90) 23%, rgba(201, 77, 84, 0.90) 75.5%, rgba(201, 77, 84, 0.00) 100%)';
            audioKey = VoiceList.BankerWin;
            break;
        case LocalStateEnum.TIE:
            message = 'game.tie';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(97, 175, 123, 0.00) 0%, rgba(97, 175, 123, 0.90) 25%, rgba(97, 175, 123, 0.90) 75.5%, rgba(97, 175, 123, 0.00) 100%)';
            audioKey = VoiceList.TieGame;
            break;
        // case LocalStateEnum.ROUND_CANCEL:
        //     message = 'popup.round_cancel';
        //     audioKey = '';
        //     break;
    }

    return {
        gameType: GameTypeEnum.Baccarat,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
    };
};

export const getSeDieKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case CommonGameState.Restart:
            message = 'sedie.reshake';
            audioKey = '';
            break;
        // case LocalStateEnum.ROUND_CANCEL:
        //     message = 'popup.round_cancel';
        //     audioKey = '';
        //     break;
        default:
            message = 'game.result';
            audioKey = '';
    }
    return {
        gameType: GameTypeEnum.SeDie,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
    };
};

export const getTeenPattiKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.PLAYER_A:
            message = 'game.playerA';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(79, 111, 166, 0.00) 0%, rgba(79, 111, 166, 0.90) 25%, rgba(79, 111, 166, 0.90) 75.5%, rgba(79, 111, 166, 0.00) 100%)';
            audioKey = VoiceList.PlayerAWin;
            break;
        case LocalStateEnum.PLAYER_B:
            message = 'game.playerB';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(201, 77, 84, 0.00) 0%, rgba(201, 77, 84, 0.90) 23%, rgba(201, 77, 84, 0.90) 75.5%, rgba(201, 77, 84, 0.00) 100%)';
            audioKey = VoiceList.PlayerBWin;
            break;
        case LocalStateEnum.TIE:
            message = 'game.tie';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(97, 175, 123, 0.00) 0%, rgba(97, 175, 123, 0.90) 25%, rgba(97, 175, 123, 0.90) 75.5%, rgba(97, 175, 123, 0.00) 100%)';
            audioKey = VoiceList.TieGame;
            break;
        case LocalStateEnum.IDLE:
        case LocalStateEnum.SHUFFLING:
            message = 'andar_bahar.shuffle';
            // backgroundStyle.background =
            //     'linear-gradient(90deg, rgba(190, 154, 96, 0.00) 0%, rgba(190, 154, 96, 0.90) 25%, rgba(190, 154, 96, 0.90) 75.5%, rgba(190, 154, 96, 0.00) 100%)';
            audioKey = '';
            break;
        // case LocalStateEnum.ROUND_CANCEL:
        //     message = 'popup.round_cancel';
        //     audioKey = '';
        //     break;
    }
    return {
        gameType: GameTypeEnum.TeenPatti2020,
        bg: backgroundStyle,
        message: message,
        localState: localState,
        audioKey: audioKey,
    };
};

export const getDragonTigerKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.SHUFFLING:
            message = 'game.shuffling';
            break;
        case LocalStateEnum.PLAYER:
            message = 'game.dragon';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(79, 111, 166, 0.00) 0%, rgba(79, 111, 166, 0.90) 25%, rgba(79, 111, 166, 0.90) 75.5%, rgba(79, 111, 166, 0.00) 100%)';
            audioKey = VoiceList.DragonWin;
            break;
        case LocalStateEnum.BANKER:
            message = 'game.tiger';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(201, 77, 84, 0.00) 0%, rgba(201, 77, 84, 0.90) 23%, rgba(201, 77, 84, 0.90) 75.5%, rgba(201, 77, 84, 0.00) 100%)';
            audioKey = VoiceList.TigerWin;
            break;
        case LocalStateEnum.TIE:
            message = 'game.tie';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(97, 175, 123, 0.00) 0%, rgba(97, 175, 123, 0.90) 25%, rgba(97, 175, 123, 0.90) 75.5%, rgba(97, 175, 123, 0.00) 100%)';
            audioKey = VoiceList.TieGame;
            break;
        // case LocalStateEnum.ROUND_CANCEL:
        //     message = 'popup.round_cancel';
        //     audioKey = '';
        //     break;
    }
    return {
        gameType: GameTypeEnum.Dragon,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
    };
};

export const getSicBoKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case CommonGameState.Restart:
            message = 'sicbo.reshake';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            // message = 'popup.round_cancel';
            // audioKey = '';
            break;
        default:
            message = 'game.result';
            audioKey = '';
            break;
    }

    return {
        gameType: GameTypeEnum.SicBo,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
    };
};

export const getRouletteKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case CommonGameState.Restart:
            message = 'roulette.respin';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            // message = 'popup.round_cancel';
            // audioKey = '';
            break;
        default:
            audioKey = '';
            message = 'game.result';
            break;
    }
    return {
        gameType: GameTypeEnum.Roulette,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
        localState: localState,
    };
};

export const getAndarBaharKey = (localState: number): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.PLAYER:
            // case AndarBaharGameState.AndarWin:
            message = 'game.andar';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(79, 111, 166, 0.00) 0%, rgba(79, 111, 166, 0.90) 25%, rgba(79, 111, 166, 0.90) 75.5%, rgba(79, 111, 166, 0.00) 100%)';
            audioKey = VoiceList.AndarWin;
            break;
        case LocalStateEnum.BANKER:
            // case AndarBaharGameState.BaharWin:
            message = 'game.bahar';
            backgroundStyle.background =
                'linear-gradient(90deg, rgba(201, 77, 84, 0.00) 0%, rgba(201, 77, 84, 0.90) 23%, rgba(201, 77, 84, 0.90) 75.5%, rgba(201, 77, 84, 0.00) 100%)';
            audioKey = VoiceList.BaharWin;
            break;
        case LocalStateEnum.IDLE:
        case LocalStateEnum.SHUFFLING:
            message = 'andar_bahar.shuffle';
            // backgroundStyle.background =
            //     'linear-gradient(90deg, rgba(190, 154, 96, 0.00) 0%, rgba(190, 154, 96, 0.90) 25%, rgba(190, 154, 96, 0.90) 75.5%, rgba(190, 154, 96, 0.00) 100%)';
            audioKey = '';
            break;
        // case LocalStateEnum.ROUND_CANCEL:
        //     message = 'popup.round_cancel';
        //     audioKey = '';
        //     break;
    }
    return {
        gameType: GameTypeEnum.AndarBahar,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
        localState: localState,
    };
};

export const getPokDengKey = (
    localState: number,
    WinAmount?: number
): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.SHUFFLING:
            message = 'game.shuffling';
            break;
        // case LocalStateEnum.ROUND_CANCEL:
        //     message = 'popup.round_cancel';
        //     audioKey = '';
        //     break;
    }
    if (WinAmount != undefined) {
        if (WinAmount >= 0) {
            message = 'pokdeng.game_result_win';
        } else {
            message = 'pokdeng.game_result_lose';
        }
    }
    return {
        gameType: GameTypeEnum.PokDeng,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
    };
};
export const getBlackjackKey = (
    localState: number,
    WinAmount?: number
): BaseResultPopupProps => {
    let backgroundStyle = { ...commonBackground };
    let message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            // audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.stop_bet';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.SHUFFLING:
            message = 'game.shuffling';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'blackjack.round_cancel';
            audioKey = '';
            break;
    }
    if (WinAmount != undefined) {
        if (WinAmount >= 0) {
            message = 'pokdeng.game_result_win';
        } else {
            message = 'pokdeng.game_result_lose';
        }
    }
    return {
        gameType: GameTypeEnum.Blackjack,
        bg: backgroundStyle,
        message: message,
        audioKey: audioKey,
    };
};
