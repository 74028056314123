import { CMDPsBet } from "./CMDPsBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsDealerTips implements ICommand
{

	public Tips : CMDPsBet;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Tips = new CMDPsBet();
		this.Tips.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Tips.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsDealerTips();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Tips(CMDPsBet)='+this.Tips+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55066;
	}

	public getCmdName() : string
	{
		return 'PsDealerTips';
	}

}
