import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDTicket implements ICommand
{

	public GameID : number = 0;
	public AgentName : string = "";
	public PlayerName : string = "";
	public HostID : number = 0;
	public GameCount : number = 0;
	public BetType : number = 0;
	public BetAmount : number = 0;
	public TimeInSecond : number = 0;
	public IP : string = "";
	public BetSource : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.AgentName = ba.readString();
		this.PlayerName = ba.readString();
		this.HostID = ba.readUint16();
		this.GameCount = ba.readUint32();
		this.BetType = ba.readUint8();
		this.BetAmount = ba.readInt53();
		this.TimeInSecond = ba.readInt53();
		this.IP = ba.readString();
		this.BetSource = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeString(this.AgentName);
		ba.writeString(this.PlayerName);
		ba.writeUint16(this.HostID);
		ba.writeUint32(this.GameCount);
		ba.writeUint8(this.BetType);
		ba.writeUint53(this.BetAmount);
		ba.writeUint53(this.TimeInSecond);
		ba.writeString(this.IP);
		ba.writeUint32(this.BetSource);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDTicket();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'AgentName(S)='+this.AgentName+' ';
		str += 'PlayerName(S)='+this.PlayerName+' ';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'GameCount(D)='+this.GameCount+' ';
		str += 'BetType(B)='+this.BetType+' ';
		str += 'BetAmount(Q)='+this.BetAmount+' ';
		str += 'TimeInSecond(Q)='+this.TimeInSecond+' ';
		str += 'IP(S)='+this.IP+' ';
		str += 'BetSource(D)='+this.BetSource+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'Ticket';
	}

}
