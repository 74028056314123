export enum ROAD_MAP_TYPE {
    BEAD_ROAD, // 珠盤
    BIG_ROAD, // 大路
    BIG_EYE_ROAD, // 大眼仔
    SMALL_ROAD, // 小路
    COCKROACH_ROAD, // 曱甴路
    POINT_ROAD, // 點數路
    EVEN_ODD_ROAD, // 單雙路
    BIG_SMALL_ROAD, // 大小路
    DICE = 10,
    RED_BLACK, // roulette red black
}

export enum TIME_BAR_COLORS {
    GREEN = '#8cff8a',
    YELLOW = '#fffa6d',
    RED = '#fe4e4e',
}

export enum ROAD_MAP_COLORS {
    RED = '#FF4F4F',
    BLUE = '#4F80FF',
    GREEN = '#45B46A',
    WHITE = '#ffffff',
    DARK_GREEN = '#196260',
    GRAY = '#a2a2a2',
    LIGHT_BLUE = '#005CCA',
    ORANGE = '#F0812C',
    BLACK = '#000',
    TRANSPARENT = '#fff0',
}

export enum REPORT_COLORS {
    TEXT = '#fff',
    BORDER = '#4d4d4d',
    ARROW = '#bf9a5d',
}
export enum REPORT_SIZE {
    TEXT = '0.75rem',
}
