import { useEnterGame, useEnterGameState } from '@/hooks/useEnterGame';
import { createContext } from 'react';

export const EnterGameContext = createContext({} as useEnterGameState);
const { Provider } = EnterGameContext;
type EnterGameContextProps = {
    children: JSX.Element | Array<JSX.Element>;
};
export const EnterGameProvider = ({ children }: EnterGameContextProps) => {
    const egc = useEnterGame();
    return <Provider value={egc}>{children}</Provider>;
};
