import { createContext } from 'react';
import { useEnterCount, useEnterCountState } from '../hooks/useEnterCount';

export const EnterCountContext = createContext({} as useEnterCountState);
const { Provider } = EnterCountContext;
type EnterCountProviderOwnProps = {
    children: JSX.Element;
};
type EnterCountProviderProps = EnterCountProviderOwnProps;

export const EnterCountProvider = (props: EnterCountProviderProps) => {
    const { children } = props;
    const vp = useEnterCount();
    return <Provider value={vp}>{children}</Provider>;
};
