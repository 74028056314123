const currencyMap: CurrencyType = {
    '€': 'EUR',
    '£': 'GBP',
    Rp: 'IDR',
    '₹': 'INR',
    '¥': 'JPY',
    '₩': 'KRW',
    RM: 'MYR',
    '฿': 'THB',
    $: 'USD',
    '₫': 'VND',
    ',': 'comma',
    ':': 'colon',
    '.': 'dot',
};
type CurrencyType = {
    [key in string]: string;
};
const charTypeCheck = (str: string) => {
    if (!isNaN(parseInt(str))) return str;
    if (currencyMap.hasOwnProperty.call(currencyMap, str)) {
        return currencyMap[str];
    }
    return '';
};

export const parseDollarSign = (currencyName: string) => {
    const dollarSign: { [key: string]: string } = {};

    for (const key in currencyMap) {
        const value = currencyMap[key];
        dollarSign[value] = key;
    }

    if (dollarSign.hasOwnProperty.call(dollarSign, currencyName))
        return dollarSign[currencyName];
    return currencyName;
};

export enum ImageType {
    Prize,
    Countdown,
}

export const getImageJsonArray = (
    imagetype: ImageType,
    str: string
): string[] => {
    let tempArr: string[] = [];
    const seperatorIndex = str.indexOf(' ');
    if (seperatorIndex > -1) {
        const currency = str.substring(0, seperatorIndex);
        const amount = str.substring(seperatorIndex, str.length);
        tempArr.push(currency);
        tempArr.push(...amount.split(''));
    } else {
        tempArr = str.split('');
    }
    // const jsonFlie = 'promotion_tool_figures';
    return tempArr.map(val => {
        let returnVal = '';
        let checkedVal = charTypeCheck(val);
        switch (imagetype) {
            case ImageType.Prize:
                // returnVal = `${jsonFlie} event_${checkedVal}`;
                returnVal = `event_${checkedVal}`;
                break;
            case ImageType.Countdown:
                // returnVal = `${jsonFlie} event_clock_${checkedVal}`;
                returnVal = `event_clock_${checkedVal}`;
                break;
        }
        return returnVal;
    });
};

export const dateToTimeLeftArray = (
    timestamp: number,
    timezone: number
): string[] => {
    timezone = -timezone;
    const timeDifference =
        timestamp * 1000 + timezone * 60 * 60 * 1000 - Date.now();
    if (timeDifference >= 0) {
        let remainingTime = timeDifference;
        const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        remainingTime -= daysLeft * (1000 * 60 * 60 * 24);
        const hoursLeft = Math.floor(remainingTime / (1000 * 60 * 60));
        remainingTime -= hoursLeft * (1000 * 60 * 60);
        const minutesLeft = Math.floor(remainingTime / (1000 * 60));
        remainingTime -= minutesLeft * (1000 * 60);
        const secondsLeft = Math.floor(remainingTime / 1000);
        return [
            fillZero(daysLeft),
            fillZero(hoursLeft),
            fillZero(minutesLeft),
            fillZero(secondsLeft),
        ];
        //[days,hours,minutes,seconds]
    }
    return ['00', '00', '00', '00'];
};

export const dateToDateString = (
    date: Date,
    local: boolean = false,
    operator: string = '-'
): string => {
    let m: number = 0;
    let d: number = 0;
    if (local) {
        m = date.getMonth();
        d = date.getDate();
    } else {
        m = date.getUTCMonth();
        d = date.getUTCDate();
    }
    return fillZero(m + 1) + operator + fillZero(d);
};

export const dateToString = (
    date: Date,
    local: boolean = false,
    operator: string = '/'
): string => {
    let y: number = 0;
    let m: number = 0;
    let d: number = 0;
    if (local) {
        y = date.getFullYear();
        m = date.getMonth();
        d = date.getDate();
    } else {
        y = date.getUTCFullYear();
        m = date.getUTCMonth();
        d = date.getUTCDate();
    }
    return y + operator + fillZero(m + 1) + operator + fillZero(d);
};

export const timeToString = (
    date: Date,
    local: boolean = false,
    operator: string = ':'
): string => {
    let h: number = 0;
    let m: number = 0;
    let s: number = 0;
    if (local) {
        h = date.getHours();
        m = date.getMinutes();
        s = date.getSeconds();
    } else {
        h = date.getUTCHours();
        m = date.getUTCMinutes();
        s = date.getUTCSeconds();
    }
    return fillZero(h) + operator + fillZero(m) + operator + fillZero(s);
};

export const fillZero = (value: number, digit: number = 2): string => {
    let result: string = value.toString();
    while (result.length < digit) {
        result = '0' + result;
    }
    return result;
};

export const fillZeroWithComma = (value: number, digit: number = 2): string => {
    let result: string = value.toString();
    //let numOfComma: number = Math.floor(digit/3);
    //digit += numOfComma;
    while (result.length < digit) {
        result = '0' + result;
    }
    return result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

let queryTimer: NodeJS.Timeout | null = null;
let queryTimeoutCallback: (() => void) | null = null;
export function startQuery(callback: () => void, timeout: number = 5000) {
    stopQuery();
    queryTimeoutCallback = callback;
    queryTimer = setTimeout(() => {
        if (queryTimeoutCallback) {
            queryTimeoutCallback();
        }
    }, timeout);
}
export function stopQuery() {
    if (queryTimer) {
        clearTimeout(queryTimer);
        queryTimer = null;
        queryTimeoutCallback = null;
    }
}

export const checkPromotionUrl = (
    cdnUrl: string,
    eventId: number,
    type: string
): string => {
    let url = '';
    if (cdnUrl) {
        const cdn = cdnUrl;
        switch (type) {
            case 'JSON':
                url = `${cdn}IMGSS_E${eventId}.json`;
                break;
            case 'JPEG':
                url = cdn;
                break;
        }
    }

    return url;
};

export const getDisplayTimeZone = (serverTimezone: number): number => {
    return serverTimezone > 12 ? serverTimezone - (65535 + 1) : serverTimezone;
};
