import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { TeenPattiTotalBet } from '../../games/teenpatti';
import { CMDScTotalBetTeenPatti2020, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetTeenPattiHandler extends AbstractHandler {
    _command: CMDScTotalBetTeenPatti2020;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetTeenPatti2020;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as TeenPattiTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;

        TotalBet.Tie = this.convertBetAmount(this._command.Tie);
        TotalBet.PlayerAWin = this.convertBetAmount(this._command.PlayerAWin);
        TotalBet.PlayerBWin = this.convertBetAmount(this._command.PlayerBWin);
        TotalBet.PlayerAPairPlus = this.convertBetAmount(
            this._command.PlayerAPairPlus
        );
        TotalBet.PlayerBPairPlus = this.convertBetAmount(
            this._command.PlayerBPairPlus
        );
        TotalBet.SixCardBonus = this.convertBetAmount(
            this._command.SixCardBonus
        );
        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };
        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
