import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBetWinDetailList implements ICommand
{

	public betType : number = 0;
	public SignedSignal : number = 0;
	public WinAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.betType = ba.readUint8();
		this.SignedSignal = ba.readUint8();
		this.WinAmount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.betType);
		ba.writeUint8(this.SignedSignal);
		ba.writeUint53(this.WinAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBetWinDetailList();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'betType(B)='+this.betType+' ';
		str += 'SignedSignal(B)='+this.SignedSignal+' ';
		str += 'WinAmount(Q)='+this.WinAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'SpBetWinDetailList';
	}

}
