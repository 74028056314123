import { AudioPlayContext } from '@/contexts/AudioPlayContext';
import { useContext, useState } from 'react';
import { useLocalStorage } from './storage/useLocalStorage';
import { MultiBetViewMode } from './useGlobalData';
export enum GAME_INFO_BUTTON {
    SWITCH_TABLE,
    TABLE_INFO,
    BET_LIMIT,
    BET_STATISTICS,
    NO_COMMISSION,
    FAVORITE,
    GOOD_ROAD,
    ROUL_SWITCH_BET_MODE,
    MULTI_BET_FILTER,
    BET_INFO,
}
export const useGameInfoButtonController = () => {
    const [isSwitchTableOpen, setIsSwitchTableOpen] = useState(false);
    const [isTableInfoOpen, setIsTableInfoOpen] = useState(false);
    const [isBetInfoOpen, setIsBetInfoOpen] = useState(false);
    const [isBetLimitOpen, setIsBetLimitOpen] = useState(false);
    const [isBetStatisticsOpen, setIsBetStatisticsOpen] = useState(false);
    const [isNoCommissionOpen, setIsNoCommissionOpen] = useState(false);
    const [isFavoriteOpen, setIsFavoriteOpen] = useState(false);
    const [isGoodRoadOpen, setIsGoodRoadOpen] = useState(false);
    const [isRoulRacetrackBetMode, setIsRoulRacetrackBetMode] = useState(false);
    const [isMultiBetViewOpen, setIsMultiBetViewOpen] = useState(true);
    const [multiBetView, setMultiBetView] = useLocalStorage(
        'multi-bet-view',
        MultiBetViewMode.FullRoad
    );
    const { playButtonAudio } = useContext(AudioPlayContext);
    const toggleNC = () => {
        playButtonAudio();
        setIsNoCommissionOpen(!isNoCommissionOpen);
    };
    const closeAll = () => {
        setIsSwitchTableOpen(false);
        setIsTableInfoOpen(false);
        setIsBetInfoOpen(false);
        setIsBetLimitOpen(false);
        setIsBetStatisticsOpen(false);
    };
    const open = (buttonType: GAME_INFO_BUTTON) => {
        playButtonAudio();
        closeAll();
        switch (buttonType) {
            case GAME_INFO_BUTTON.SWITCH_TABLE:
                setIsSwitchTableOpen(true);
                break;
            case GAME_INFO_BUTTON.TABLE_INFO:
                setIsTableInfoOpen(true);
                break;
            case GAME_INFO_BUTTON.BET_INFO:
                setIsBetInfoOpen(true);
                break;
            case GAME_INFO_BUTTON.BET_LIMIT:
                setIsBetLimitOpen(true);
                break;
            case GAME_INFO_BUTTON.BET_STATISTICS:
                setIsBetStatisticsOpen(true);
                break;
            case GAME_INFO_BUTTON.ROUL_SWITCH_BET_MODE:
                setIsRoulRacetrackBetMode(!isRoulRacetrackBetMode);
                break;
        }
    };
    return {
        open,
        closeAll,
        isSwitchTableOpen,
        isTableInfoOpen,
        isBetInfoOpen,
        isBetLimitOpen,
        isBetStatisticsOpen,
        // NC
        toggleNC,
        isNoCommissionOpen,
        setIsFavoriteOpen,
        isFavoriteOpen,
        setIsGoodRoadOpen,
        isGoodRoadOpen,
        // Roulette
        isRoulRacetrackBetMode,
        setIsRoulRacetrackBetMode,
        //multibet
        isMultiBetViewOpen,
        setIsMultiBetViewOpen,
        multiBetView,
        setMultiBetView,
        setIsBetInfoOpen,
    };
};
export type useGameInfoButtonControllerState = ReturnType<
    typeof useGameInfoButtonController
>;
