import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const ROUTE_KEY = 'app::route';
type pageType = {
    goto: boolean;
};
type otherType = {
    toPage: string;
};
type reconnectType = {
    reconnectHostId: number;
};
export type RouteState = pageType & otherType & reconnectType;
const initialRouteState = {
    goto: false,
    toPage: '',
    reconnectHostId: 0,
} as RouteState;
const toReconnect = {
    reducer(state: RouteState, action: PayloadAction<reconnectType>) {
        state.reconnectHostId = action.payload.reconnectHostId;
    },
    prepare(hostId: number) {
        return {
            payload: {
                reconnectHostId: hostId,
            },
        };
    },
};
const goto = {
    reducer(state: RouteState, action: PayloadAction<otherType>) {
        state.goto = true;
        state.toPage = action.payload.toPage;
    },
    prepare(payload: string) {
        return {
            payload: {
                toPage: payload,
            },
        };
    },
};
const close = (state: RouteState) => {
    state.goto = false;
    state.reconnectHostId = 0;
};
const routeSlice = createSlice({
    name: ROUTE_KEY,
    initialState: initialRouteState,
    reducers: {
        toReconnect,
        goto,
        close,
    },
});
export const routeSliceReducer = routeSlice.reducer;
export const routeSliceActions = routeSlice.actions;
