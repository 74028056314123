export enum BetType {
    SDBFourRed,
	SDBThreeRed,
	SDBTwoRedTwoWhite,
	SDBThreeWhite,
	SDBFourWhite,
	SDBFourRedOrWhite,
	SDBBig,
	SDBSmall,
	SDBOdd,
	SDBEven,
	SDBMaxBet,
}
