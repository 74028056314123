export enum HandResult
{
	NoResult,
	Point0,
	Point1,
	Point2,
	Point3,
	Point4,
	Point5,
	Point6,
	Point7,
	Point7Dot1,
	Point7Dot2,
	Point7Dot3,
	Point7Dot4,
	Point7Dot5,
	Point8,
	Point9,
}