import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpPromotionState, ICommand } from "../../live";
import { AbstractHandler } from "../AbstractHandler";
import { promotionToolSliceActions } from '../../../../modules/main/slice/promotiontool/promotionTool';

export class CMDSpPromotionStateHandler extends AbstractHandler {
    _command: CMDSpPromotionState;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpPromotionState;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const cmd = this._command;
        // console.log('command::handler::sppromotionstate', cmd);
        dispatch(promotionToolSliceActions.isOverBudget(cmd.IsOverBudget));
    }
}