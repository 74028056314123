import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { totalBetsSliceActions } from '../../../modules/totalBet/slice';
import { RouletteTotalBet } from '../../games/roulette';
import { CMDScTotalBetRoulette, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScTotalBetRouletteHandler extends AbstractHandler {
    _command: CMDScTotalBetRoulette;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScTotalBetRoulette;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const TotalBet = {} as RouletteTotalBet;
        const HostId = this._command.Total.HostID;
        let totalPlayerCount = 0;

        TotalBet.Red = this.convertBetAmount(this._command.Red);
        TotalBet.Black = this.convertBetAmount(this._command.Black);
        TotalBet.Big = this.convertBetAmount(this._command.Big);
        TotalBet.Small = this.convertBetAmount(this._command.Small);
        TotalBet.Odd = this.convertBetAmount(this._command.Odd);
        TotalBet.Even = this.convertBetAmount(this._command.Even);
        TotalBet.Zero = this.convertBetAmount(this._command.Zero);

        for (const ba of Object.values(TotalBet)) {
            totalPlayerCount += ba.BetPlayerCount;
        }
        TotalBet.Total = {
            Amount: this._command.Total.Amount,
            BetPlayerCount: totalPlayerCount,
        };
        dispatch(totalBetsSliceActions.update({ HostId, TotalBet }));
    }
}
