import { RootState } from '../../store/store';
import { MAIN_KEY, MainState } from './slice';
import { GIFT_KEY, GiftState } from './slice/gift';
import { LANDING_KEY, LandingState } from './slice/landing';
import { LIMIT_RED_KEY, LimitRedState } from './slice/limitRed';
import { PLAYER_KEY, PlayerState } from './slice/player';
import { PROMOTION_TOOL_KEY, PromotionState } from './slice/promotiontool/promotionTool';
import { RESULT_KEY, ResultState } from './slice/result';
import { USER_KEY, UserState } from './slice/user';

export const getMainState = (state: RootState) => state[MAIN_KEY] as MainState;
export const getMainGiftState = (state: RootState): GiftState =>
    getMainState(state)[GIFT_KEY];
export const getMainLimitRedState = (state: RootState): LimitRedState =>
    getMainState(state)[LIMIT_RED_KEY];
export const getMainPlayerState = (state: RootState): PlayerState =>
    getMainState(state)[PLAYER_KEY];
export const getMainUserState = (state: RootState): UserState =>
    getMainState(state)[USER_KEY];
export const getLandingState = (state: RootState): LandingState =>
    getMainState(state)[LANDING_KEY];
export const getResultState = (state: RootState): ResultState =>
    getMainState(state)[RESULT_KEY];
export const getPromotionToolState = (state: RootState): PromotionState =>
    getMainState(state)[PROMOTION_TOOL_KEY];