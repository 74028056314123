import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetPokdeng implements ICommand
{

	public Total : CMDTotalBet;
	public Player1Win : CMDBetSummary;
	public Player2Win : CMDBetSummary;
	public Player3Win : CMDBetSummary;
	public Player4Win : CMDBetSummary;
	public Player5Win : CMDBetSummary;
	public Player1Pair : CMDBetSummary;
	public Player2Pair : CMDBetSummary;
	public Player3Pair : CMDBetSummary;
	public Player4Pair : CMDBetSummary;
	public Player5Pair : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Player1Win = new CMDBetSummary();
		this.Player1Win.fromBytes(ba);
		this.Player2Win = new CMDBetSummary();
		this.Player2Win.fromBytes(ba);
		this.Player3Win = new CMDBetSummary();
		this.Player3Win.fromBytes(ba);
		this.Player4Win = new CMDBetSummary();
		this.Player4Win.fromBytes(ba);
		this.Player5Win = new CMDBetSummary();
		this.Player5Win.fromBytes(ba);
		this.Player1Pair = new CMDBetSummary();
		this.Player1Pair.fromBytes(ba);
		this.Player2Pair = new CMDBetSummary();
		this.Player2Pair.fromBytes(ba);
		this.Player3Pair = new CMDBetSummary();
		this.Player3Pair.fromBytes(ba);
		this.Player4Pair = new CMDBetSummary();
		this.Player4Pair.fromBytes(ba);
		this.Player5Pair = new CMDBetSummary();
		this.Player5Pair.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Player1Win.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Player2Win.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Player3Win.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Player4Win.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Player5Win.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Player1Pair.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.Player2Pair.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.Player3Pair.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)
		let tempBuf9 = this.Player4Pair.toBytes().toBuffer();
		ba.appendBuf(tempBuf9)
		let tempBuf10 = this.Player5Pair.toBytes().toBuffer();
		ba.appendBuf(tempBuf10)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetPokdeng();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Player1Win(CMDBetSummary)='+this.Player1Win+' ';
		str += 'Player2Win(CMDBetSummary)='+this.Player2Win+' ';
		str += 'Player3Win(CMDBetSummary)='+this.Player3Win+' ';
		str += 'Player4Win(CMDBetSummary)='+this.Player4Win+' ';
		str += 'Player5Win(CMDBetSummary)='+this.Player5Win+' ';
		str += 'Player1Pair(CMDBetSummary)='+this.Player1Pair+' ';
		str += 'Player2Pair(CMDBetSummary)='+this.Player2Pair+' ';
		str += 'Player3Pair(CMDBetSummary)='+this.Player3Pair+' ';
		str += 'Player4Pair(CMDBetSummary)='+this.Player4Pair+' ';
		str += 'Player5Pair(CMDBetSummary)='+this.Player5Pair+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30758;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetPokdeng';
	}

}
