import { RootState } from '../../store/store';
import { SETTING_KEY, SettingState } from './slice';
import { SOUND_KEY } from './slice/sound';
import { VOLUME_KEY } from './slice/volume';

export const getSettingState = (state: RootState) =>
    state[SETTING_KEY] as SettingState;
export const getSettingVolumeState = (state: RootState) =>
    getSettingState(state)[VOLUME_KEY];
export const getSettingSoundState = (state: RootState) =>
    getSettingState(state)[SOUND_KEY];
