import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Gift, giftSliceActions } from '../../../modules/main/slice/gift';
import { CMDSpGiftSetting, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpGiftSettingHandler extends AbstractHandler {
    _command: CMDSpGiftSetting;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpGiftSetting;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const gifts = this._command.Gift.map(
            g =>
                ({
                    ID: g.ID,
                    Price: g.Price,
                }) as Gift
        );
        dispatch(giftSliceActions.updateData(gifts));
    }
}
