import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsRoomSeat implements ICommand
{

	public IsMultiBet : number = 0;
	public HostID : number = 0;
	public Seat : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.IsMultiBet = ba.readUint8();
		this.HostID = ba.readUint16();
		this.Seat = ba.readUint16();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.IsMultiBet);
		ba.writeUint16(this.HostID);
		ba.writeUint16(this.Seat);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsRoomSeat();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'IsMultiBet(B)='+this.IsMultiBet+' ';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Seat(W)='+this.Seat+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55023;
	}

	public getCmdName() : string
	{
		return 'PsRoomSeat';
	}

}
