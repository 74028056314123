import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PromotionPage } from '../../../popup/promotiontool/components/PromotionDialog';

export const PROMOTION_TOOL_KEY = 'app::main::promotionTool';
export type PromotionState = {
    Popup:number,
    Id:number,
    Point:Range,
    StartTimestamp:number,
    EndTimestamp:number,
    Timezone:number,
    BudgetAmount:number,
    MoreDetailUrl:string,
    Language:string,
    PrizeList:string[]|undefined, //serializedObject - CMDPromotionPrize
    MaxDayLimit:number,
    RemainDayLimit:number,
    IsOverBudget:number,
    Result:number,
    ResultAmount:number,
    TotalRedeemAmount:number,
    RankingUTCUpdateTime:number,
    RankCount:number,
    RankData:string[]|undefined, //serializedObject - CMDPromotionRank
    PlayerRank:number,
    PlayerAmount:number,
    Record:string[]|undefined //serializedObject - CMDRedeemRecord,
    Page:PromotionPage,
    //local display control
    IsLoading:boolean,
    IsOpenDialog:boolean,
    RemainDays:number
};
export type Range = {
    Low: number;
    High: number;
};

const initialPromotionState = {
    Popup: 0,
    Id: 0,
    Point: {Low: 0, High: 0},
    StartTimestamp: 0,
    EndTimestamp: 0,
    Timezone: 0,
    BudgetAmount: 0,
    MoreDetailUrl: '',
    Language: 'en',
    PrizeList: undefined, //serializedObject 
    MaxDayLimit: 0,
    RemainDayLimit: 0,
    IsOverBudget: 0,
    Result: 0,
    ResultAmount: 0,
    TotalRedeemAmount: 0,
    RankingUTCUpdateTime: 0,
    RankCount: 0,
    RankData: undefined, //serializedObject 
    PlayerRank: 0,
    PlayerAmount: 0,
    Record: undefined, //serializedObject 
    Page: PromotionPage.Splash,
    IsLoading: true,
    IsOpenDialog: false,
    RemainDays: 0
} as unknown as PromotionState;

const updateEventId = (state: PromotionState, action: PayloadAction<number>) => {
    state.Id = action.payload;
};
const updateEventPointLow = (state: PromotionState, action: PayloadAction<number>) => {
    if (!state.Point) state.Point = {} as Range;
    state.Point.Low = action.payload;
};
const updateEventPointHigh = (state: PromotionState, action: PayloadAction<number>) => {
    if (!state.Point) state.Point = {} as Range;
    state.Point.High = action.payload;
};
const updateSpPromotionEventData = (state: PromotionState, action: PayloadAction<PromotionState>) => {
    state.Popup = action.payload.Popup;
    state.StartTimestamp = action.payload.StartTimestamp;
    state.EndTimestamp = action.payload.EndTimestamp;
    state.Timezone = action.payload.Timezone;
    state.BudgetAmount = action.payload.BudgetAmount;
    state.MoreDetailUrl = action.payload.MoreDetailUrl;
    state.PrizeList = action.payload.PrizeList;
    state.MaxDayLimit = action.payload.MaxDayLimit;
    state.RemainDayLimit = action.payload.RemainDayLimit;
};
const updateRedeemResult = (state: PromotionState, action: PayloadAction<PromotionState>) => {
    state.Result = action.payload.Result;
    state.ResultAmount = action.payload.ResultAmount;
    state.TotalRedeemAmount = action.payload.TotalRedeemAmount;
    state.RemainDayLimit = action.payload.RemainDayLimit;
}
const clearRedeemResult = (state: PromotionState) => {
    state.Result = 0;
    state.ResultAmount = 0;
    state.TotalRedeemAmount = 0;
}
const updateRedeemLog = (state: PromotionState, action: PayloadAction<string[]>) => {
    state.Record = action.payload;
}
const clearRedeemLog = (state: PromotionState) => {
    state.Record = undefined;
}
const updateRanking = (state: PromotionState, action: PayloadAction<PromotionState>) => {
    state.RankingUTCUpdateTime = action.payload.RankingUTCUpdateTime;
    state.RankCount = action.payload.RankCount;
    state.RankData = action.payload.RankData;
    state.PlayerRank = action.payload.PlayerRank;
    state.PlayerAmount = action.payload.PlayerAmount;
}
const clearRanking = (state: PromotionState) => {
    state.RankingUTCUpdateTime = 0;
    state.RankCount = 0;
    state.RankData = undefined;
    state.PlayerRank = 0;
    state.PlayerAmount = 0;
}
const updateEventLanguage = (state: PromotionState, action: PayloadAction<string>) => {
    state.Language = action.payload;
}
const updatePage = (state: PromotionState, action: PayloadAction<PromotionPage>) => {
    state.Page = action.payload;
}
const resetPage = (state: PromotionState) => {
    state.Page = PromotionPage.Gacha;
}
const isOverBudget = (state: PromotionState, action: PayloadAction<number>) => {
    state.IsOverBudget = action.payload;
}
const isLoading = (state: PromotionState, action: PayloadAction<boolean>) => {
    state.IsLoading = action.payload;
}
const isOpenDialog = (state: PromotionState, action: PayloadAction<boolean>) => {
    state.IsOpenDialog = action.payload;
}
const updateRemainDays = (state: PromotionState, action: PayloadAction<number>) => {
    state.RemainDays = action.payload;
}

const promotionToolSlice = createSlice({
    name: PROMOTION_TOOL_KEY,
    initialState: initialPromotionState,
    reducers: {
        updateEventId,
        updateEventPointLow,
        updateEventPointHigh,
        updateSpPromotionEventData,
        updateRedeemResult,
        clearRedeemResult,
        updateRanking,
        clearRanking,
        updateRedeemLog,
        clearRedeemLog,
        updateEventLanguage,
        updatePage,
        resetPage,
        isOverBudget,
        isLoading,
        isOpenDialog,
        updateRemainDays
    },
});
export const promotionToolSliceReducer = promotionToolSlice.reducer;
export const promotionToolSliceActions = promotionToolSlice.actions;