import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLotteryFirstPrize implements ICommand
{

	public DrawNumber : number = 0;
	public FirstPrize : number = 0;
	public SecondPrize : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.DrawNumber = ba.readUint32();
		this.FirstPrize = ba.readUint32();
		this.SecondPrize = ba.readUint32();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.DrawNumber);
		ba.writeUint32(this.FirstPrize);
		ba.writeUint32(this.SecondPrize);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLotteryFirstPrize();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'DrawNumber(D)='+this.DrawNumber+' ';
		str += 'FirstPrize(D)='+this.FirstPrize+' ';
		str += 'SecondPrize(D)='+this.SecondPrize+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50033;
	}

	public getCmdName() : string
	{
		return 'SpLotteryFirstPrize';
	}

}
