import { CMDBet } from "./CMDBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDConfirmedBet implements ICommand
{

	public Base : CMDBet;
	public BetID : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Base = new CMDBet();
		this.Base.fromBytes(ba);
		this.BetID = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Base.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		ba.writeUint53(this.BetID);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDConfirmedBet();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Base(CMDBet)='+this.Base+' ';
		str += 'BetID(Q)='+this.BetID+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'ConfirmedBet';
	}

}
