import { CMDScAck, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDScAckHandler extends AbstractHandler {
    _command: CMDScAck;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScAck;
    }
    handle(): void {}
}
