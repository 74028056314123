import { tableBetSliceActions } from '@/modules/betTable/slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { gameSliceActions } from '../../../modules/games/slice';
import { getHostById, hostsSliceActions } from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScShuffle, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScShuffleHandler extends AbstractHandler {
    _command: CMDScShuffle;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScShuffle;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const hostId = this._command.HostID;
        const state = store.getState();
        const host = getHostById(state, this._command.HostID); //getHostByGameId(state, gameState.currentGameId);
        batch(() => {
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: [],
                    gameType: host.GameType,
                })
            );
            dispatch(
                hostsSliceActions.updateGameCount({
                    hostId,
                    gameCount: 0,
                })
            );
            dispatch(
                tableBetSliceActions.clearBets({
                    hostId: this._command.HostID,
                    gameId: 0,
                })
            );
            dispatch(
                gameSliceActions.updateHostBetInfo({
                    hostId: host.HostId,
                    totalBetAmount: 0,
                    totalWithHoldAmount: 0,
                    pendingBetAmount: 0,
                    pendingWithHoldAmount: 0,
                })
            );
            dispatch(
                hostsSliceActions.updateGameState({
                    hostId,
                    state: GameState.Shuffle,
                })
            );
        });
    }
}
