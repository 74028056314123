import { combineReducers } from 'redux';
import { GIFT_KEY, giftSliceReducer } from './gift';
import { LANDING_KEY, landingSliceReducer } from './landing';
import { LIMIT_RED_KEY, limitRedSliceReducer } from './limitRed';
import { PLAYER_KEY, playerSliceReducer } from './player';
import { RESULT_KEY, resultSliceReducer } from './result';
import { USER_KEY, userSliceReducer } from './user';
import { PROMOTION_TOOL_KEY, promotionToolSliceReducer } from './promotiontool/promotionTool';

const mainReducer = combineReducers({
    [LIMIT_RED_KEY]: limitRedSliceReducer,
    [GIFT_KEY]: giftSliceReducer,
    [PLAYER_KEY]: playerSliceReducer,
    [USER_KEY]: userSliceReducer,
    [LANDING_KEY]: landingSliceReducer,
    [RESULT_KEY]: resultSliceReducer,
    [PROMOTION_TOOL_KEY]: promotionToolSliceReducer
});
export default mainReducer;
export type MainState = ReturnType<typeof mainReducer>;
export const MAIN_KEY = 'app::main';
