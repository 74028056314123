import {
    VideoImageEnum,
    getVideoImage,
} from '@/components/images/base64/VideoImage';
import { Hall as HallEnum } from '@/models/Hall';
import { ROUTE_ROOT } from '@/models/Route';
import { GameState } from '@/models/games/enums/GameState';
import { GameType as GameTypeEnum } from '@/models/games/enums/GameType';
import { BetType as PokDengBetType } from '@/models/games/pokdeng/BetType';
import { BetTypeMaxBet } from '@/models/host/MaxBet';
import md5 from 'md5';
import moment from 'moment';
import { LocalStorageKey } from '../hooks/storage/useLocalStorage';
import { PokerCard, Suit } from '../models/games/PokerCard';
import { ShoeRound } from '../models/games/ShoeRound';
import { Bet, BetPlayerAmount } from '../models/host/BetAmount';
import { Range } from '../modules/main/slice/player';
import { getLocalState as getAndarBaharLocalState } from './games/andarBahar';
import { getLocalState as getBaccaratLocalState } from './games/baccarat';
import { getBlackjackLocalState } from './games/blackjack';
import { getLocalState as getDTLocalState } from './games/dragonTiger';
import { getLocalState as getPokDengLocalState } from './games/pokdeng';
import { getLocalState as getRouletteLocalState } from './games/roulette';
import { getLocalState as getSeDieLocalState } from './games/sedie';
import { getLocalState as getSicBoLocalState } from './games/sicbo';
import { getLocalState as getTeenPattiLocalState } from './games/teenpatti';

export const gotoOldWeb = (username: string | null): void => {
    try {
        window.localStorage.setItem(username + '_ui_version2', 'oldWeb');
        const ui = window.localStorage.getItem('fullParameter');
        const old_ui_domain =
            window.location.origin.replace('://ws2.', '://ws.') +
            '/h5web/index.html';
        window.location.href = old_ui_domain + ui + '&backToOld=true';
    } catch (e) {
        console.error(e);
    }
};

export const gotoOldUI = (): void => {
    try {
        const ui = window.localStorage.getItem('fullParameter');
        const old_ui_domain =
            window.location.origin.replace('://ws2.', '://ws.') + ROUTE_ROOT;
        if (window.location.href.indexOf('://ws.') > 0) {
            window.location.href = old_ui_domain + ui + '&backToOld=true';
        } else {
            window.location.href =
                old_ui_domain.replace('1235', '1234') + ui + '&backToOld=true';
        }
    } catch (e) {
        console.error(e);
    }
};

export const getWithHold = (GameType: GameTypeEnum): Array<Bet> | undefined => {
    let withHold = new Array<Bet>();
    switch (GameType) {
        case GameTypeEnum.PokDeng:
            withHold.push({
                Type: PokDengBetType.PDBPlayer1Win,
                Amount: 1,
                GameId: 0,
            });
            withHold.push({
                Type: PokDengBetType.PDBPlayer2Win,
                Amount: 1,
                GameId: 0,
            });
            withHold.push({
                Type: PokDengBetType.PDBPlayer3Win,
                Amount: 1,
                GameId: 0,
            });
            withHold.push({
                Type: PokDengBetType.PDBPlayer4Win,
                Amount: 1,
                GameId: 0,
            });
            withHold.push({
                Type: PokDengBetType.PDBPlayer5Win,
                Amount: 1,
                GameId: 0,
            });
    }
    return withHold.length === 0 ? undefined : withHold;
};

export const mergeCurrencyName = (
    serverCurrencyName: string | undefined
): string => {
    return serverCurrencyName?.replace(/[2]/g, '') ?? '';
};

export const numberFormat = (
    input: number,
    cc: number = 2,
    dd?: string,
    tt?: string
): string => {
    //let n = input;
    let n: string;
    let c = isNaN((cc = Math.abs(cc))) ? 2 : cc;
    let d = dd == undefined ? '.' : dd;
    let t = tt == undefined ? ',' : tt;
    let s = input < 0 ? '-' : '';
    let i: string = parseInt((n = Math.abs(+input || 0).toFixed(c))) + '';
    let jj = i.length;
    let j = jj > 3 ? jj % 3 : 0;
    return (
        s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
        (c
            ? d +
              Math.abs(Number(n) - Number(i))
                  .toFixed(c)
                  .slice(2)
            : '')
    );
};

export const calcLowHigh = (range: Range): number => {
    if (!range) return 0;
    const { Low, High } = range;
    let low = Low ?? 0,
        high = High ?? 0;
    let negative: boolean = false;
    let sign: number = 1 << 31;
    if ((high & sign) != 0) {
        negative = true;
        //low = ~low;
        low = ~low;
        high = ~high;
    }
    let result: number = high * 0x100000000 + low;
    if (negative) result = -(result + 1);
    return result;
};

export const getLoginToken = (
    oldToken: string,
    reconnectKey: string
): string => {
    if (!reconnectKey || reconnectKey == '') {
        return oldToken;
    }
    return md5(oldToken + reconnectKey).toUpperCase();
};

export const genNewToken = (
    oldToken: string,
    reconnectKey: string = ''
): string => {
    let newToken: string = '';
    let _reconnectKey: string = '';
    if (reconnectKey) {
        _reconnectKey = reconnectKey;
    } else {
        const key = window.localStorage.getItem(
            LocalStorageKey.ReconnectKey
        ) as string;
        if (key) {
            _reconnectKey = window.atob(key);
        }
    }
    if (_reconnectKey && _reconnectKey != '' && oldToken && oldToken != '') {
        newToken = md5(oldToken + _reconnectKey).toUpperCase();
    } else {
        newToken = oldToken;
    }
    return newToken;
};

export const updateLocalStorageLanguage = (lang: string): void => {
    const pattern: RegExp = new RegExp('lang=([^&]*)', 'i');
    let loginParam = window.localStorage.getItem(LocalStorageKey.FullParameter);
    if (loginParam) {
        loginParam = loginParam.replace(pattern, 'lang=' + lang);
        window.localStorage.setItem(LocalStorageKey.FullParameter, loginParam);
    }

    console.log('app::login::save', lang);
};

export const updateLocalStorageReconnectKey = (
    newToken: string,
    username: string
): void => {
    const pattern: RegExp = new RegExp('token=([^&]*)', 'i');
    const pattern2: RegExp = new RegExp('username=([^&]*)', 'i');
    let loginParam = window.localStorage.getItem(LocalStorageKey.FullParameter);
    if (loginParam) {
        loginParam = loginParam.replace(pattern, 'token=' + newToken);
        loginParam = loginParam.replace(pattern2, 'username=' + username);
        window.localStorage.setItem(LocalStorageKey.FullParameter, loginParam);
    }
    console.log('app::login::save', newToken);
};
export const ExchangeBetAmountByCurrency = (
    betAmount: number,
    rate: number,
    exchangeRate: number
) => {
    if (rate === exchangeRate) {
        return betAmount;
    }
    return Math.ceil((betAmount * rate) / exchangeRate / 100) * 100;
};

export const ExchangeBetAmount = (
    betAmount: number | undefined,
    exchangeRate: number
): number => {
    if (!betAmount) betAmount = 0;
    if (exchangeRate != 100000000) {
        if (exchangeRate < 999999 && exchangeRate >= 100) {
            exchangeRate = Math.floor(exchangeRate / 100) * 100;
        }
        betAmount =
            Math.ceil((betAmount * 100000000) / exchangeRate / 100) * 100;
    } else {
        betAmount = Math.ceil(betAmount / 100) * 100;
    }
    return betAmount;
};

export const getBetSummary = (
    BetAmount: BetPlayerAmount | undefined,
    exchangeRate: number,
    isRest: boolean = false
): string => {
    let b = '0',
        bpc = 0;
    if (BetAmount && isRest === false) {
        b = numberFormat(
            ExchangeBetAmount(BetAmount.Amount, exchangeRate) / 100,
            0
        );
        bpc = BetAmount.BetPlayerCount;
    }
    return `${b}/${bpc}`;
};

export const sleep = async (ms: number) => {
    return new Promise(resolve => {
        const sleepId = setTimeout(() => {
            resolve(sleepId);
        }, ms);
    });
};

export const updateRemainTime = (value: number) => {
    let _countDownFinishTime: number = moment().valueOf();
    if (value !== 0) {
        _countDownFinishTime = moment().valueOf() + (value - 1) * 1000;
    }
    return _countDownFinishTime;
};

export const windowClose = () => {
    window.close();
};

export const getShoeRoundByGameCount = (gameCount: number): ShoeRound => ({
    Shoe: Math.floor(gameCount / 10000),
    Round: gameCount % 10000,
});

export const getPokerByIndex = (i?: number): PokerCard | undefined => {
    if (i === undefined) {
        return undefined;
    }
    let text = getPokerText(i);
    let suit = getPokerSuit(i);

    return { text, suit };
};
const getPokerText = (i: number): string => {
    const n = i % 13;
    switch (n) {
        case 0:
            return 'A';
        case 10:
            return 'J';
        case 11:
            return 'Q';
        case 12:
            return 'K';
        default:
            return (n + 1).toString();
    }
};
const getPokerSuit = (i: number): Suit => {
    switch (true) {
        case i < 13:
            return Suit.SPADE;
        case i < 26:
            return Suit.HEART;
        case i < 39:
            return Suit.CLUB;
        case i < 52:
            return Suit.DIAMOND;
        default:
            return Suit.NONE;
    }
};

export type HostProps = {
    hostId: number;
    gameType: GameTypeEnum;
};
export type HostRoadmapProps = {
    hostId: number;
    column: number;
    row?: number;
    className?: string;
};
export type CheckBetsProp = {
    hostId: number;
    gameType: number;
    bets: Array<Bet>;
    amountOverBetLimit?: (betType: number, chipAmount: number) => number;
    totalPendingBetAmount: number;
    totalPendingWithHoldAmount: number;
    availableBalance: number;
    disableBetTypes?: Array<number>;
    confirmedBets?: Array<Bet>;
    availableAfterHedgeBetting?: boolean;
};
export type AvailableBetsProp = {
    bets: Array<Bet>;
    isAllIn: boolean;
    haveBetLimitAllIn: boolean;
    isOutOfBetLimit: boolean;
    isNotEnoughMoney: boolean;
    haveDisabledBetType: boolean;
};
export const checkBetsAvailed = (props: CheckBetsProp): AvailableBetsProp => {
    let isAllIn: boolean = false;
    let haveBetLimitAllIn = false;
    let isOutOfBetLimit = false;
    let isNotEnoughMoney = false;
    let haveDisabledBetType = false;
    let newBets = new Array<Bet>();

    let currentPendingBetAmount = 0;
    let currentWithHoldAmount = 0;
    const withHold = getWithHold(props.gameType);
    for (let bet of props.bets) {
        if (props.disableBetTypes) {
            if (props.disableBetTypes.indexOf(bet.Type) >= 0) {
                haveDisabledBetType = true;
                continue;
            }
        }

        // check over bet limit
        let amount = bet.Amount;
        if (props.amountOverBetLimit) {
            const overLimit =
                props.availableAfterHedgeBetting === true
                    ? 0
                    : props.amountOverBetLimit(bet.Type, amount);
            if (overLimit > 0) {
                if (amount <= overLimit) {
                    isOutOfBetLimit = true;
                    break;
                } else {
                    amount -= overLimit;
                    haveBetLimitAllIn = true;
                }
            }

            let pendingBetAmount = amount;
            let withHoldRate = 0;
            if (withHold) {
                const tmp = withHold.find(lr => lr.Type === bet.Type);
                if (tmp) {
                    withHoldRate = tmp.Amount;
                }
            }
            if (
                props.totalPendingBetAmount +
                    props.totalPendingWithHoldAmount +
                    currentPendingBetAmount +
                    currentWithHoldAmount +
                    pendingBetAmount +
                    pendingBetAmount * withHoldRate >
                props.availableBalance * 100
            ) {
                pendingBetAmount =
                    Math.floor(props.availableBalance) * 100 -
                    props.totalPendingBetAmount -
                    props.totalPendingWithHoldAmount -
                    currentPendingBetAmount -
                    currentWithHoldAmount;
                if (withHoldRate > 0 && pendingBetAmount > 0) {
                    pendingBetAmount =
                        Math.floor(
                            pendingBetAmount / (withHoldRate + 1) / 100
                        ) * 100;
                }
                if (pendingBetAmount <= 0) {
                    isNotEnoughMoney = true;
                    break;
                }
                isAllIn = true;
            }
            let newBet = { ...bet };
            newBet.Amount = pendingBetAmount;
            newBets.push(newBet);
            currentPendingBetAmount += pendingBetAmount;
            currentWithHoldAmount += pendingBetAmount * withHoldRate;
        }
    }
    return {
        bets: newBets,
        isAllIn: isAllIn,
        haveBetLimitAllIn: haveBetLimitAllIn,
        isOutOfBetLimit: isOutOfBetLimit,
        isNotEnoughMoney: isNotEnoughMoney,
        haveDisabledBetType: haveDisabledBetType,
    };
};

export const getWithHoldBetAmount = (
    gameType: GameTypeEnum,
    betSummary: Array<number>
) => {
    const withHold = getWithHold(gameType);
    let total = 0;
    if (withHold) {
        withHold.forEach(b => {
            total += betSummary[b.Type] ? betSummary[b.Type] * b.Amount : 0;
        });
    }
    return total;
};

export const getLocalState = (
    gameType: GameTypeEnum,
    isRest: boolean,
    state: number,
    resultReleased: boolean,
    isRoundCancel: boolean,
    isMsgPopup: boolean
) => {
    switch (gameType) {
        case GameTypeEnum.Baccarat:
            return getBaccaratLocalState(
                isRest,
                state,
                resultReleased,
                isRoundCancel ? isRoundCancel : false
            );
        case GameTypeEnum.Dragon:
            return getDTLocalState(
                isRest,
                state,
                resultReleased
                // isRoundCancel ? isRoundCancel : false
            );
        case GameTypeEnum.SeDie:
            return getSeDieLocalState(
                isRest,
                state,
                resultReleased,
                isMsgPopup
            );
        case GameTypeEnum.SicBo:
            return getSicBoLocalState(isRest, state, resultReleased);
        case GameTypeEnum.AndarBahar:
            return getAndarBaharLocalState(isRest, state, resultReleased);
        case GameTypeEnum.TeenPatti2020:
            return getTeenPattiLocalState(
                isRest,
                state,
                resultReleased,
                isMsgPopup
            );
        case GameTypeEnum.Roulette:
            return getRouletteLocalState(isRest, state, resultReleased);
        case GameTypeEnum.PokDeng:
            return getPokDengLocalState(
                isRest,
                state,
                resultReleased
                // isRoundCancel ? isRoundCancel : false
            );
        case GameTypeEnum.Blackjack:
            return getBlackjackLocalState(isRest, state, resultReleased);
    }
    return GameState.Idle;
};

export const getRandomId = (length: number) => {
    const randomId = new Uint8Array(length);
    window.crypto.getRandomValues(randomId);
    return convertToHex(randomId);
};
export const convertToHex = (array: Uint8Array) => {
    const result = new Array<string>();
    for (const a of array) {
        const s = a.toString(16);
        result.push(s.length === 2 ? s : '0' + s);
    }
    return result.join('');
};

export const formatBetLimit = (n: number) =>
    n.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const getMaxBetByBetType = (
    betType: number,
    MaxBet: BetTypeMaxBet[]
): string => {
    if (!MaxBet) return '0';
    const index = MaxBet.findIndex(d => {
        return d.BetType === betType;
    });
    return index !== -1 ? formatBetLimit(MaxBet[index].MaxBet / 100) : '0';
};

export const getDefaultImage = (gameType: GameTypeEnum, hallId: HallEnum) => {
    switch (gameType) {
        case GameTypeEnum.AndarBahar:
            return getVideoImage(VideoImageEnum.bg_andar_bahar_mtable);
        case GameTypeEnum.Baccarat:
            if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_baccarat_atable);
            } else {
                return getVideoImage(VideoImageEnum.bg_baccarat_ctable);
            }
        case GameTypeEnum.Blackjack:
            if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_blackjack_atable);
            }
            return getVideoImage(VideoImageEnum.bg_blackjack_mtable);
        case GameTypeEnum.Dragon:
            if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_dragontiger_atable);
            }
            return getVideoImage(VideoImageEnum.bg_dragontiger_mtable);
        case GameTypeEnum.PokDeng:
            if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_pokdeng_atable);
            }
            return getVideoImage(VideoImageEnum.bg_pokdeng_mtable);
        case GameTypeEnum.Roulette:
            if (hallId == HallEnum.Sexy) {
                return getVideoImage(VideoImageEnum.bg_roulette_ctable);
            } else if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_roulette_atable);
            } else {
                return getVideoImage(VideoImageEnum.bg_roulette_mtable);
            }
        case GameTypeEnum.SeDie:
            if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_sedie_atable);
            }
            return getVideoImage(VideoImageEnum.bg_sedie_mtable);
        case GameTypeEnum.SicBo:
            if (hallId == HallEnum.Diamond) {
                return getVideoImage(VideoImageEnum.bg_sicbo_atable);
            }
            return getVideoImage(VideoImageEnum.bg_sicbo_mtable);
        case GameTypeEnum.TeenPatti2020:
            return getVideoImage(VideoImageEnum.bg_teen_patti_mtable);
    }
    return <></>;
};

export const getVHByRem = (rem: number) => {
    return `max(calc(var(--vh) * ${rem * 3}), ${rem}rem)`;
};

export const getVHByRemToNumber = (vh: number, rem: number) => {
    return Math.max(vh * rem * 3, rem * 16); //`max(calc(var(--vh) * ${rem * 3}), ${rem}rem)`;
};

export const getVWByRem = (rem: number) => {
    return `max(calc(var(--vw) * ${rem * 3}), ${rem}rem)`;
};

export const getEnumValues = (customEnum: object) =>
    Object.values(customEnum)
        .map(bte => Number(bte))
        .filter(bte => !isNaN(bte)) ?? new Array<number>();
