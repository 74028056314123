import { LobbyUiContext } from '@/contexts/LobbyUiContext';
import { WebSocketContext } from '@/contexts/WebSocketContext';
import { CMDPsPlayerRecentlyPlay, CMDPsSetPrefs } from '@/models/cmd/live';
import { useContext, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageKey } from '../../hooks/storage/useLocalStorage';
import {
    ROUTE_FAVORITE,
    ROUTE_LOBBY,
    ROUTE_THANKS4PLAY,
} from '../../models/Route';
import { getLandingState, getMainUserState } from '../main/selector';
import { userSliceActions } from '../main/slice/user';
import { getRouteState } from './selector';
import { routeSliceActions } from './slice';

export const RouteController = () => {
    const {
        FPlayerPrefs,
        FPlayerPrefs2,
        FPlayerPrefs3,
        NeedPrefsUpdate,
        isUpdateRecentlyPlayed,
    } = useSelector(getMainUserState);
    const { sendCommand } = useContext(WebSocketContext);
    const { setIsShowGridToggle, setIsShowGameFilter } =
        useContext(LobbyUiContext);
    const routeData = useSelector(getRouteState);
    const { goto, toPage, reconnectHostId } = routeData;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { returnUrl } = useSelector(getLandingState);
    const location = useLocation();
    const { socketClose } = useContext(WebSocketContext);

    useEffect(() => {
        if (reconnectHostId > 0) {
            if (location.pathname.indexOf(reconnectHostId.toString()) > 0) {
                socketClose();
            }
            dispatch(routeSliceActions.close());
        }
    }, [reconnectHostId]);
    useEffect(() => {
        const path = location.pathname;
        setIsShowGridToggle(
            path.includes(ROUTE_FAVORITE) || path.includes(ROUTE_LOBBY)
        );
        setIsShowGameFilter(path.includes(ROUTE_LOBBY));
    }, [location.pathname]);
    useEffect(() => {
        //Update Favorite setting when change
        if (NeedPrefsUpdate) {
            let cmd = new CMDPsSetPrefs();
            cmd.FPrefs = BigInt(FPlayerPrefs);
            cmd.FPrefs2 = BigInt(FPlayerPrefs2);
            cmd.FPrefs3 = BigInt(FPlayerPrefs3);
            sendCommand(cmd);
            dispatch(userSliceActions.finishPrefUpdate());
        }
    }, [NeedPrefsUpdate]);
    useEffect(() => {
        if (isUpdateRecentlyPlayed) {
            let cmd = new CMDPsPlayerRecentlyPlay();
            sendCommand(cmd);
            dispatch(userSliceActions.finishRecentlyPlayedUpdate());
        }
    }, [isUpdateRecentlyPlayed]);

    useEffect(() => {
        if (goto && toPage) {
            if (toPage == ROUTE_THANKS4PLAY) {
                if (returnUrl) {
                    if (returnUrl.indexOf('/') == 0) {
                        window.location.href =
                            window.location.origin + returnUrl;
                    } else if (
                        returnUrl.toLowerCase().indexOf('http://') > -1 ||
                        returnUrl.toLowerCase().indexOf('https://') > -1
                    ) {
                        window.location.href = returnUrl;
                    } else {
                        window.location.href = 'http://' + returnUrl;
                    }
                    window.localStorage.removeItem(
                        LocalStorageKey.FullParameter
                    );
                } else {
                    window.localStorage.removeItem(
                        LocalStorageKey.FullParameter
                    );
                    window.close();
                }
            }
            batch(() => {
                // if (
                //     location.pathname.indexOf(ROUTE_GAME) == 0 ||
                //     location.pathname == ROUTE_MULTIBET
                // ) {
                //     dispatch(gameSliceActions.onCancelPendingBet());
                // }
                navigate(toPage);
                dispatch(routeSliceActions.close());
            });
        }
        return () => {};
    }, [goto]);
    return <></>;
};
