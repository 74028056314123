export enum Voice {
    Mandarin1,
    Mandarin2,
    Cantonese1,
    Cantonese2,
    English1,
    English2,
    Thai1,
    Korean1,
    Indonesian1,
    Hindi,
    Japanese,
    Arabic,
    BrazilianPortuguese,
}
export const VoiceCode = {
    [Voice.Mandarin1]: 'sc',
    [Voice.Mandarin2]: 'sc',
    [Voice.Cantonese1]: 'tc',
    [Voice.Cantonese2]: 'tc',
    [Voice.English1]: 'en',
    [Voice.English2]: 'en',
    [Voice.Thai1]: 'th',
    [Voice.Korean1]: 'kr',
    [Voice.Indonesian1]: 'id',
    [Voice.Hindi]: 'hi',
    [Voice.Japanese]: 'jp',
    [Voice.BrazilianPortuguese]: 'pt_BR',
    [Voice.Arabic]: 'ar',
};
export const VoiceFile = {
    [Voice.Mandarin1]: 'zh_CN/0',
    [Voice.Mandarin2]: 'zh_CN/1',
    [Voice.Cantonese1]: 'zh_TW/0',
    [Voice.Cantonese2]: 'zh_TW/1',
    [Voice.English1]: 'en_US/0',
    [Voice.English2]: 'en_US/1',
    [Voice.Thai1]: 'th/0',
    [Voice.Korean1]: 'kr/0',
    [Voice.Indonesian1]: 'id/0',
    [Voice.Hindi]: 'hi/0',
    [Voice.Japanese]: 'jp/0',
    [Voice.BrazilianPortuguese]: 'pt_BR/0',
    [Voice.Arabic]: 'ar/0',
};

//audio file name
//'resource/assets/sound/button/button_click.mp3',
// button/button_click => button.button_click
export const SoundList = {
    btnSound: 'button.button_click',
    Beep: 'sound.Beep_1',
    ChipsBet: 'chips.ChipsBet',
};
export const VoiceList = {
    AllIn: 'chips.all_in_sfx',
    BettingSuccess: 'bet.BettingSuccess',
    NotEnoughCredit: 'bet.NotEnoughCredit',
    StartBetting: 'bet.StartBetting',
    StopBetting: 'bet.StopBetting',
    TieGame: 'bet.TieGame',
    //baccarat
    PlayerWin: 'bacc.PlayerWin',
    BankerWin: 'bacc.BankerWin',
    PlayerPoint: 'bacc.Player{point}',
    BankerPoint: 'bacc.Banker{point}',
    //teenpatti
    PlayerAWin: 'teenpatti.PlayerAWin',
    PlayerBWin: 'teenpatti.PlayerBWin',
    //dragon & tiger
    DragonWin: 'dt.DragonWin',
    TigerWin: 'dt.TigerWin',
    DragonPoint: 'dt.Dragon{point}',
    TigerPoint: 'dt.Tiger{point}',
    //AndarBahar
    AndarWin: 'andarbahar.AndarWin',
    BaharWin: 'andarbahar.BaharWin',
    //roulette
    RoulettePoint: 'roulette.{point}',
    //sedie
    SeDiePoint: 'sedie.{point}',
    SeDieBig: 'sedie.Big',
    SeDieEven: 'sedie.Even',
    SeDieOdd: 'sedie.Odd',
    SeDieSmall: 'sedie.Small',
    //sicbo
    SicBoPoint: 'sicbo.{point}',
    SicBoBigPoint: 'sicbo.Big_{point}',
    SicBoSmallPoint: 'sicbo.Small_{point}',
    SicBoTriplePoint: 'sicbo.Triple_{point}',
};
