import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import App from './App';
import { WebSocketProvider } from './contexts/WebSocketContext';
import './index.scss';
// config i18n
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { AudioPlayProvider } from './contexts/AudioPlayContext';
import { BetLimitProvider } from './contexts/BetLimitContext';
import { CdnProvider } from './contexts/CdnContext';
import { ConfigProvider } from './contexts/ConfigContext';
import { DatePickerLocalizationProvider } from './contexts/DatePickerLocalizationContext';
import { FullScreenProvider } from './contexts/FullscreenContext';
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import { LiteUIProvider } from './contexts/LiteUIContext';
import { LobbyUiProvider } from './contexts/LobbyUiContext';
import { ResourceProvider } from './contexts/ResourceContext';
import { ScreenProvider } from './contexts/ScreenContext';
import i18n from './modules/locales/i18n';
import { overrideFunctionArrayAt } from './override';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store/store';
import { theme } from './theme';

overrideFunctionArrayAt();
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <I18nextProvider i18n={i18n}>
                <DatePickerLocalizationProvider>
                    <CdnProvider>
                        <ConfigProvider>
                            <GlobalDataProvider>
                                <ResourceProvider>
                                    <AudioPlayProvider>
                                        <WebSocketProvider>
                                            <BetLimitProvider>
                                                <FullScreenProvider>
                                                    <ScreenProvider>
                                                        <LiteUIProvider>
                                                            <LobbyUiProvider>
                                                                <ThemeProvider
                                                                    theme={
                                                                        theme
                                                                    }
                                                                >
                                                                    <CssBaseline />
                                                                    <Suspense>
                                                                        <App />
                                                                    </Suspense>
                                                                </ThemeProvider>
                                                            </LobbyUiProvider>
                                                        </LiteUIProvider>
                                                    </ScreenProvider>
                                                </FullScreenProvider>
                                            </BetLimitProvider>
                                        </WebSocketProvider>
                                    </AudioPlayProvider>
                                </ResourceProvider>
                            </GlobalDataProvider>
                        </ConfigProvider>
                    </CdnProvider>
                </DatePickerLocalizationProvider>
            </I18nextProvider>
        </StoreProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
