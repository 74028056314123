import { ScreenContext } from '@/contexts/ScreenContext';
import { useObserveElementRect } from '@/hooks/useObserveElementRect';
import { Box, Typography, TypographyProps } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
const STANDARD_FONT_SIZE = 16;
export const AutoTypography = (props: TypographyProps) => {
    const { children, textAlign, ...otherProps } = props;
    const { ref: containerRef, rect: containerRect } =
        useObserveElementRect<HTMLDivElement>();
    const { ref: textRef } = useObserveElementRect<HTMLSpanElement>();
    const [fontSize, setFontSize] = useState(STANDARD_FONT_SIZE);
    const { isLandscape } = useContext(ScreenContext);

    useEffect(() => {
        const isString = typeof children === 'string';
        if (isString && containerRect !== undefined) {
            const calculationWidth =
                (containerRect.width / children.length) * 1.65;
            let fontWidth = STANDARD_FONT_SIZE;

            if (isLandscape) {
                const vh = parseFloat(
                    document.documentElement.style.getPropertyValue('--vh')
                );
                fontWidth = Math.max(vh * 1 * 3, 16);
            } else {
                fontWidth = Math.min(STANDARD_FONT_SIZE, calculationWidth);
            }

            setFontSize(fontWidth);
        }
    }, [children, containerRect]);
    useEffect(() => {
        console.log('auto::typography', children, containerRect?.width);
    }, [containerRect]);
    return (
        <Box
            width={'100%'}
            ref={containerRef}
            textAlign={textAlign ?? 'center'}
            className={'auto-text-container'}
        >
            <Typography
                component={'span'}
                fontSize={`${fontSize}px`}
                {...otherProps}
                ref={textRef}
            >
                {children}
            </Typography>
        </Box>
    );
};
