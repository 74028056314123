import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { getHostById, hostsSliceActions } from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScShuffleEnd, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScShuffleEndHandler extends AbstractHandler {
    _command: CMDScShuffleEnd;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScShuffleEnd;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const hostId = this._command.HostID;
        const state = store.getState();

        const host = getHostById(state, hostId);
        batch(() => {
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: [],
                    gameType: host.GameType,
                })
            );
            dispatch(
                hostsSliceActions.updateGameState({
                    hostId,
                    state: GameState.Idle,
                })
            );
            dispatch(
                hostsSliceActions.updateGameCount({
                    hostId,
                    gameCount: this._command.GameCount,
                })
            );
        });
    }
}
