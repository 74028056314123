import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPromotionPrize implements ICommand
{

	public PrizeID : number = 0;
	public PrizeRank : number = 0;
	public PointPerPlay : number = 0;
	public TopPrize : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.PrizeID = ba.readUint32();
		this.PrizeRank = ba.readUint8();
		this.PointPerPlay = ba.readUint53();
		this.TopPrize = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.PrizeID);
		ba.writeUint8(this.PrizeRank);
		ba.writeUint53(this.PointPerPlay);
		ba.writeUint53(this.TopPrize);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPromotionPrize();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'PrizeID(D)='+this.PrizeID+' ';
		str += 'PrizeRank(B)='+this.PrizeRank+' ';
		str += 'PointPerPlay(Q)='+this.PointPerPlay+' ';
		str += 'TopPrize(Q)='+this.TopPrize+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'PromotionPrize';
	}

}
