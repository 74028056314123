import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from './PromotionDialog';
import { useContext, useEffect, useState } from 'react';
import { CMDPsQueryPromotionRanking } from '../../../../models/cmd/live';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { RootState } from '../../../../store/store';
import {
    getMainUserState,
    getPromotionToolState,
} from '../../../main/selector';
import { useTranslation } from 'react-i18next';
import {
    dateToString,
    getDisplayTimeZone,
    startQuery,
    stopQuery,
    timeToString,
} from '../PromotionUtil';
import { popupSliceActions } from '../../slice';
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from '../../../../models/Popup';
import { getPopupState } from '../../selector';
import { numberFormat } from '../../../../utils/commonFunc';
import { PromotionButton } from './PromotionButton';
import { PromotionRankingCell } from './PromotionRankingCell';
import { getPromotionTool } from '@/components/images/base64/PromotionTool';
import { promotionRanking, getPromotionTitle } from '../PromotionObjectMapper';
import { FreeMode, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BaseTypography } from '@/components/common/baseText/BaseTypography';
import { usePromotionTool } from '@/hooks/popup/promotiontool/usePromotionTool';

export type RankingProps = {
    Rank: number;
    PlayerID: number;
    Username: string;
    WinAmount: number;
    LastRank: boolean;
    Self: boolean;
};
export const PromotionRanking = () => {
    const { eventEnded } = usePromotionTool();
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const {
        RankData,
        PlayerRank,
        PlayerAmount,
        RankingUTCUpdateTime,
        RankCount,
        Timezone,
    } = useSelector((state: RootState) => getPromotionToolState(state));
    const confirmFunc = useSelector(getPopupState).confirmValue;
    const { IsTrial, PlayerID } = useSelector((state: RootState) =>
        getMainUserState(state)
    );

    const [ranking, setRanking] = useState<RankingProps[]>([]);
    const { t, i18n } = useTranslation();

    const [showPlayerRank, setShowPlayerRank] = useState(false);
    const [isPlayerFirst3, setPlayerFirst3] = useState(false);
    const [displayPlayerRank, setDisplayPlayerRank] = useState('-');

    const [showPlayerName, setShowPlayerName] = useState(false);
    const [displayPlayerName, setDisplayPlayerName] = useState(
        t('promotion_tool.rankings_you')
    );

    const [showPlayerAmount, setShowPlayerAmount] = useState(false);
    const [displayPlayerAmount, setDisplayPlayerAmount] = useState('-');

    const displayTimezone = getDisplayTimeZone(Timezone);

    const doQuery = () => {
        dispatch(promotionToolSliceActions.isLoading(true));
        const cmd = new CMDPsQueryPromotionRanking();
        sendCommand(cmd);
        startQuery(onTimeout);

        if (eventEnded()) {
            //get last data when event ended
            updateRankData();
        }
    };

    const onTimeout = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.isLoading(false));
        dispatch(
            popupSliceActions.open(
                'promotion_tool.query_timeout',
                POPUP_TYPE.CONFIRM,
                POPUP_CONFIRM_ACTION.RETRY,
                undefined
            )
        );
    };

    useEffect(() => {
        doQuery();
    }, []);

    useEffect(() => {
        updateRankData();
    }, [RankData, PlayerAmount, PlayerRank, RankingUTCUpdateTime, RankCount]);

    useEffect(() => {
        if (confirmFunc === POPUP_CONFIRM_ACTION.RETRY) {
            doQuery();
        }
    }, [confirmFunc]);

    const updateRankData = () => {
        const isEmptyData = !RankData || RankData.length == 0;
        if (RankData) {
            dispatch(promotionToolSliceActions.isLoading(false));
            stopQuery();
            setShowPlayerName(true);
            setShowPlayerRank(true);
            setShowPlayerAmount(true);
        }

        //Ranking
        let ranking: RankingProps[] = [];
        if (!isEmptyData) {
            //update
            RankData.forEach((str, index) => {
                const data: RankingProps = JSON.parse(str);
                data.LastRank = index === RankData.length - 1;
                data.Self = data.PlayerID === PlayerID;
                ranking.push(data);
            });
        }
        setRanking(ranking);

        //Player
        setPlayerFirst3(PlayerRank > 0 && PlayerRank <= 3);

        if (!isEmptyData) {
            setDisplayPlayerRank(
                getRankString(PlayerRank, PlayerAmount, RankCount, ranking)
            );
            setDisplayPlayerAmount(
                PlayerAmount > 0 ? numberFormat(PlayerAmount / 100) : '-'
            );
        }

        // Trial player account handling
        if (IsTrial && !isEmptyData) {
            setDisplayPlayerRank('-');
            setDisplayPlayerName('-');
            setDisplayPlayerAmount('-');
        }
    };

    const getRankString = (
        rank: number,
        winAmount: number,
        rankCount: number,
        rankData: Array<RankingProps>
    ): string => {
        if (rank > 0) {
            if (winAmount > 0) {
                if (rank <= rankData.length) return rank.toString();
                else if (rank < rankCount) return `${rankData.length}+`;
            }
        } else if (rank <= 0 && winAmount > 0) {
            return `${rankCount}+`;
        }
        return '-';
    };

    const destroy = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.clearRanking());
    };

    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            <Box className="event-title">
                {/* <BaseImage
                        className={'event_title event_title'}
                        isMultiLang={true}
                        scale={1}
                    /> */}
                <Box>
                    {getPromotionTool(
                        getPromotionTitle(
                            `event_title_${i18n.language.replace(/-/g, '_')}`
                        )
                    )}
                </Box>
            </Box>
            <Box className="event-detail-list-content">
                <Box
                    sx={{
                        backgroundColor: '#bea57b',
                        width: '100%',
                        height: '2px',
                    }}
                ></Box>
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        width: '100%',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#bf9a5d',
                            textAlign: 'center',
                            fontSize: '18px',
                        }}
                    >
                        {t('promotion_tool.rankings')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#bea57b',
                        width: '100%',
                        height: '2px',
                    }}
                ></Box>
                <Box className="ranking-title-container">
                    <Typography className="ranking-title-no">
                        {t('promotion_tool.rankings_num')}
                    </Typography>
                    <Typography className="ranking-title-player">
                        {t('promotion_tool.rankings_player')}
                    </Typography>
                    <Typography className="ranking-title-win-amount">
                        {/* {`${t('promotion_tool.rankings_win_amount')} ${'(' + currency + ')'}`} */}
                        {`${t('promotion_tool.rankings_win_amount')}`}
                    </Typography>
                </Box>
                {/* <Box className="ranking-list">
                    {ranking.map((rank, index) => (
                        <PromotionRankingCell
                            ranking={rank}
                            key={`promotiontool-ranking-${index}`}
                        />
                    ))}
                </Box> */}
                <Swiper
                    className="ranking-list"
                    slidesPerView={'auto'}
                    direction={'vertical'}
                    freeMode
                    mousewheel
                    modules={[FreeMode, Mousewheel]}
                    touchStartForcePreventDefault={true}
                >
                    {ranking.map((rank, index) => (
                        <SwiperSlide
                            key={`promotiontool-ranking-slide-${index}`}
                        >
                            <PromotionRankingCell
                                ranking={rank}
                                key={`promotiontool-ranking-${index}`}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Box className="ranking-current-player">
                    <Box className="ranking-item-rank">
                        {showPlayerRank ? (
                            isPlayerFirst3 ? (
                                // <BaseImage
                                //     className={`promotion_tool event_no_${PlayerRank}`}
                                //     scale={1}
                                // />
                                <Box>
                                    {getPromotionTool(
                                        promotionRanking[
                                            `event_no_${PlayerRank}`
                                        ]
                                    )}
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        minWidth: '48px',
                                        minHeight: '48px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '20px',
                                    }}
                                >
                                    {displayPlayerRank}
                                </Typography>
                            )
                        ) : null}
                    </Box>
                    {showPlayerName ? (
                        <Typography className="ranking-item-player">
                            {displayPlayerName}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    {showPlayerAmount ? (
                        <Typography className="ranking-item-amount">
                            {displayPlayerAmount}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Box>
                <Stack
                    className="ranking-last-update"
                    direction={'row'}
                    spacing={0.5}
                >
                    <Typography
                        sx={{
                            color: '#979797',
                            textAlign: 'right',
                            fontSize: '14px',
                        }}
                    >{`${t('promotion_tool.last_update')}`}</Typography>
                    <Typography
                        sx={{
                            color: '#979797',
                            textAlign: 'right',
                            fontSize: '14px',
                        }}
                    >
                        {RankingUTCUpdateTime > 0
                            ? `${dateToString(new Date((RankingUTCUpdateTime + displayTimezone * 60 * 60) * 1000), false, '-')} ${timeToString(new Date((RankingUTCUpdateTime + displayTimezone * 60 * 60) * 1000), false, ':')} (UTC${displayTimezone >= 0 ? '+' + displayTimezone : displayTimezone})`
                            : `-`}
                    </Typography>
                </Stack>
            </Box>
            <Stack className="page-buttons" direction={'row'} spacing={'20px'}>
                <PromotionButton
                    className="button_gold"
                    onClick={() => {
                        destroy();

                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Gacha
                            )
                        );
                    }}
                >
                    <BaseTypography
                        resize={{
                            direction: 'horizontal',
                            value: '200px',
                        }}
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        {t('promotion_tool.explore_rewards')}
                    </BaseTypography>
                </PromotionButton>
                <PromotionButton
                    className="button_black"
                    onClick={() => {
                        destroy();

                        if (eventEnded()) {
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(false)
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'promotion_tool.popup_event_ended'
                                )
                            );
                            return;
                        }

                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Record
                            )
                        );
                    }}
                >
                    {t('promotion_tool.records')}
                </PromotionButton>
            </Stack>
            {RankData && ranking.length < 1 ? (
                <Typography className="no-promotion-records">
                    {t('promotion_tool.no_records')}
                </Typography>
            ) : (
                <></>
            )}
        </Box>
    );
};
