import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { CMDScGameRest, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScGameRestHandler extends AbstractHandler {
    _command: CMDScGameRest;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScGameRest;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        dispatch(
            hostsSliceActions.updateGameRest({
                hostId: this._command.HostID,
                onOrOff: this._command.OnOrOff,
            })
        );
    }
}
