import { TABLE_BET_KEY } from '@/hooks/useTableBet';
import { StateMsgType } from '@/models/Popup';
import { GameError } from '@/models/games/enums/GameError';
import { BetFormat } from '@/models/host/BetAmount';
import { tableBetSliceActions } from '@/modules/betTable/slice';
import { getGameState } from '@/modules/games/selector';
import { gameSliceActions } from '@/modules/games/slice';
import { getHostByGameId } from '@/modules/host/slice';
import { inGamePopupSliceActions } from '@/modules/popup/inGameSlice';
import store from '@/store/store';
import { getWithHold } from '@/utils/commonFunc';
import { Dispatch } from 'react';
import { batch } from 'react-redux';
import { AnyAction } from 'redux';
import { CMDSpNbsBetResult, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpNbsBetResultHandler extends AbstractHandler {
    _command: CMDSpNbsBetResult;
    _specialMsg: string;
    _isMessageExist: (key: string) => boolean;

    constructor(
        command: ICommand,
        specialMsg: string,
        isMessageExist: (key: string) => boolean
    ) {
        super();
        this._command = command as CMDSpNbsBetResult;
        this._specialMsg = specialMsg;
        this._isMessageExist = isMessageExist;
    }

    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        if (host) {
            if (this._command.betFormat === BetFormat.AUTO_BET_CONFIRMED) {
                window.localStorage.removeItem(
                    `${TABLE_BET_KEY}_${host.HostId}`
                );
                if (this._command.Reason == this.SUCCESS_CODE) {
                    const withHold = host
                        ? getWithHold(host.GameType)
                        : undefined;
                    let _tableTotalBet = 0;
                    let _totalWithHoldAmount = 0;
                    let Bets = new Array<number>();
                    this._command.Bet.forEach(bet => {
                        Bets[bet.Base.betType] = bet.Base.betAmount;
                        _tableTotalBet += bet.Base.betAmount;
                        if (withHold) {
                            const tmp = withHold.find(
                                v => v.Type == bet.Base.betType
                            );
                            if (tmp) {
                                _totalWithHoldAmount +=
                                    bet.Base.betAmount * tmp.Amount;
                            }
                        }
                    });
                    batch(() => {
                        const state = store.getState();
                        const { hostsBetInfo } = getGameState(state);
                        const item = hostsBetInfo.find(
                            v => v.hostId === host.HostId
                        );
                        if (this._command.Bet.length > 0) {
                            dispatch(
                                inGamePopupSliceActions.updateIsOpen({
                                    hostId: host.HostId,
                                    isOpen: true,
                                })
                            );
                            if (item && item.totalBetAmount > _tableTotalBet) {
                                dispatch(
                                    inGamePopupSliceActions.updateDataSet({
                                        hostId: host.HostId,
                                        key: 'abc.partial_bet_successful',
                                        gameType: host.GameType,
                                        stateMsgType:
                                            StateMsgType.betInfo_Warning,
                                        gameId: this._command.GameID,
                                    })
                                );
                            } else {
                                dispatch(
                                    inGamePopupSliceActions.updateDataSet({
                                        hostId: host.HostId,
                                        key: 'system.bet_success',
                                        gameType: host.GameType,
                                        stateMsgType:
                                            StateMsgType.betInfo_Success,
                                        gameId: this._command.GameID,
                                    })
                                );
                            }
                        } else if (item && item.totalBetAmount > 0) {
                            //have bet but no success
                            dispatch(
                                inGamePopupSliceActions.updateIsOpen({
                                    hostId: host.HostId,
                                    isOpen: true,
                                })
                            );
                            dispatch(
                                inGamePopupSliceActions.updateDataSet({
                                    hostId: host.HostId,
                                    key: 'abc.error_code.5',
                                    gameType: host.GameType,
                                    stateMsgType: StateMsgType.betInfo_Fail,
                                    gameId: this._command.GameID,
                                })
                            );
                            dispatch(
                                tableBetSliceActions.clearPendingBets({
                                    hostId: host.HostId,
                                    gameId: host.GameType,
                                    startGame: false,
                                })
                            );
                        }
                        dispatch(
                            tableBetSliceActions.updateConfirmedBets({
                                hostId: host.HostId,
                                gameId: this._command.GameID,
                                bets: Bets,
                            })
                        );
                        dispatch(
                            gameSliceActions.updateHostBetInfo({
                                hostId: host.HostId,
                                totalBetAmount: _tableTotalBet,
                                pendingBetAmount: 0,
                                totalWithHoldAmount: _totalWithHoldAmount,
                                pendingWithHoldAmount: 0,
                            })
                        );
                    });
                } else {
                    //Bet Fail
                    if (this._specialMsg !== '') {
                        const key = `system.${this._specialMsg}.${this._command.Reason}`;
                        if (this._isMessageExist(key)) {
                            batch(() => {
                                dispatch(
                                    inGamePopupSliceActions.updateIsOpen({
                                        hostId: host.HostId,
                                        isOpen: true,
                                    })
                                );
                                dispatch(
                                    inGamePopupSliceActions.updateDataSet({
                                        hostId: host.HostId,
                                        key: key,
                                        gameType: host.GameType,
                                        stateMsgType: StateMsgType.betInfo_Fail,
                                        gameId: this._command.GameID,
                                    })
                                );
                                dispatch(
                                    tableBetSliceActions.clearPendingBets({
                                        hostId: host.HostId,
                                        gameId: host.GameType,
                                        startGame: false,
                                    })
                                );
                            });
                            return;
                        }
                    }
                    let errorMsg = 'system.bet_failed';
                    switch (this._command.Reason) {
                        case GameError.BelowMinBet:
                            errorMsg = 'system.bet_below_win_bet';
                            break;
                        case GameError.BetTimeOut:
                            errorMsg = 'system.bet_timeout';
                            break;
                        case GameError.OutOfLimitRed:
                            errorMsg = 'system.bet_out_of_limit_red';
                            break;
                        case GameError.NotEnoughMoney:
                            errorMsg = 'system.not_enough_money';
                            break;
                        case GameError.CannotBetOpposite:
                            errorMsg = 'system.bet_opposite';
                            break;
                        case 138:
                        case 139:
                        case 140:
                        case 141:
                        case 142:
                        case 143:
                        case 144:
                        case 145:
                        case 150:
                            errorMsg =
                                'system.commonWalletError.' +
                                this._command.Reason;
                            break;
                    }
                    batch(() => {
                        dispatch(
                            inGamePopupSliceActions.updateIsOpen({
                                hostId: host.HostId,
                                isOpen: true,
                            })
                        );
                        dispatch(
                            inGamePopupSliceActions.updateDataSet({
                                hostId: host.HostId,
                                key:
                                    errorMsg != 'system.bet_failed'
                                        ? errorMsg
                                        : [
                                              errorMsg,
                                              `(${this._command.Reason})`,
                                          ],
                                gameType: host.GameType,
                                stateMsgType: StateMsgType.betInfo_Fail,
                                gameId: this._command.GameID,
                            })
                        );
                        dispatch(
                            tableBetSliceActions.clearPendingBets({
                                hostId: host.HostId,
                                gameId: host.GameType,
                                startGame: false,
                            })
                        );
                    });
                }
            }
            if (this._command.betFormat === BetFormat.AUTO_BET) {
                // response per bet
            }
        }
    }
}
