import { CMDTicket } from "./CMDTicket"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScReportHostTicket implements ICommand
{

	public Ticket : Array<CMDTicket>;

	public constructor()
	{
		this.Ticket = new Array<CMDTicket>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let TicketSize:number = ba.readUint16();
		while (TicketSize-- > 0)
		{
			let TicketChild:CMDTicket = new CMDTicket();
			TicketChild.fromBytes(ba);
			this.Ticket.push(TicketChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.Ticket.length);
		for (const TicketChild of this.Ticket)
		{
			let tempBuf = TicketChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScReportHostTicket();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Ticket_size(W)='+this.Ticket.length + ' ';
		str += 'Ticket(CMDTicket)=[';
		for (let TicketChild in this.Ticket)
		{
			if (<number><unknown>TicketChild > 0) str += ', ';
			str += this.Ticket[TicketChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 30030;
	}

	public getCmdName() : string
	{
		return 'ScReportHostTicket';
	}

}
