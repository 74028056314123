import { CMDBetRuleByGameType } from "./CMDBetRuleByGameType"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpLimitRedUpdate implements ICommand
{

	public BetRuleByGameType : Array<CMDBetRuleByGameType>;

	public constructor()
	{
		this.BetRuleByGameType = new Array<CMDBetRuleByGameType>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let BetRuleByGameTypeSize:number = ba.readUint8();
		while (BetRuleByGameTypeSize-- > 0)
		{
			let BetRuleByGameTypeChild:CMDBetRuleByGameType = new CMDBetRuleByGameType();
			BetRuleByGameTypeChild.fromBytes(ba);
			this.BetRuleByGameType.push(BetRuleByGameTypeChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.BetRuleByGameType.length);
		for (const BetRuleByGameTypeChild of this.BetRuleByGameType)
		{
			let tempBuf = BetRuleByGameTypeChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpLimitRedUpdate();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'BetRuleByGameType_Size(B)='+this.BetRuleByGameType.length + ' ';
		str += 'BetRuleByGameType(CMDBetRuleByGameType)=[';
		for (let BetRuleByGameTypeChild in this.BetRuleByGameType)
		{
			if (<number><unknown>BetRuleByGameTypeChild > 0) str += ', ';
			str += this.BetRuleByGameType[BetRuleByGameTypeChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50018;
	}

	public getCmdName() : string
	{
		return 'SpLimitRedUpdate';
	}

}
