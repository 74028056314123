import { CMDJackpot } from "./CMDJackpot"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpSlotJackpot implements ICommand
{

	public Jackpot : CMDJackpot;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Jackpot = new CMDJackpot();
		this.Jackpot.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Jackpot.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpSlotJackpot();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Jackpot(CMDJackpot)='+this.Jackpot+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50035;
	}

	public getCmdName() : string
	{
		return 'SpSlotJackpot';
	}

}
