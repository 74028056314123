export enum BetType {
    // BBMinBet = 0,

    //Normal
    BBTie = 0,
    BBPlayerWin = 1,
    BBBankerWin = 2,
    BBPlayerPair = 3,
    BBBankerPair = 4,

    //Super
    BBSPlayerPointOdd = 5,
    BBSBankerPointOdd = 6,
    BBSTotalPointOdd = 7,
    BBSPlayerPointEven = 8,
    BBSBankerPointEven = 9,
    BBSTotalPointEven = 10,
    BBSPlayerPointSmall = 11,
    BBSBankerPointSmall = 12,
    BBSTotalPointSmall = 13,
    BBSPlayerPointBig = 14,
    BBSBankerPointBig = 15,
    BBSTotalPointBig = 16,
    BBSPlayerCardSmall = 17,
    BBSBankerCardSmall = 18,
    BBSTotalCardSmall = 19,
    BBSPlayerCardBig = 20,
    BBSBankerCardBig = 21,
    BBSTotalCardBig = 22,
    BBSPlayerDragon = 23,
    BBSBankerDragon = 24,

    //Super Six
    BBSSTie = 25,
    BBSSPlayerWin = 26,
    BBSSBankerWin = 27,
    BBSSPlayerPair = 28,
    BBSSBankerPair = 29,
    BBSSSuperSix = 30,

    //Super Added
    BBSTie = 31,
    BBSPlayerWin = 32,
    BBSBankerWin = 33,
    BBSPlayerPair = 34,
    BBSBankerPair = 35,

    //Natural Added
    BBPlayerNatural = 36,
    BBBankerNatural = 37,
    BBSPlayerNatural = 38,
    BBSBankerNatural = 39,
    BBSSPlayerNatural = 40,
    BBSSBankerNatural = 41,

    BBCowPlayerWin = 42,
    BBCowBankerWin = 43,
    BBCowTie = 44,
    BBCowPlayerPair = 45,
    BBCowBankerPair = 46,
    BBCowPlayerNatural = 47,
    BBCowBankerNatural = 48,

    //Super Six Side Added
    BBSSSTotalCardSmall = 49,
    BBSSSTotalCardBig = 50,
    BBSSSPlayerDragon = 51,
    BBSSSBankerDragon = 52,
    BBSSSLuckySix = 53,

    //Side
    BBSLuckySix = 54,

    BBMaxBet = BBSLuckySix,
}
