import { Dispatch } from 'react';
import { batch } from 'react-redux';
import { AnyAction } from 'redux';
// import { gameSliceActions } from '../../../modules/games/slice';
import { gameSliceActions } from '@/modules/games/slice';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { CMDSpBetWin, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDSpBetWinHandler extends AbstractHandler {
    _command: CMDSpBetWin;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBetWin;
    }
    commandDelay() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        return host.DelayResult;
    }
    delayKey() {
        return this._command.GameID.toString();
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        if (host) {
            console.log(`hook::socket::run::${host.HostId}`, this._command);
            const win: boolean = this._command.SignedSignal == 1 ? true : false;
            const amount = this._command.WinAmount;
            const winAmount = win || amount === 0 ? amount : -amount;

            batch(() => {
                dispatch(
                    hostsSliceActions.updateWinAmount({
                        hostId: host.HostId,
                        amount: winAmount,
                    })
                );
                dispatch(
                    gameSliceActions.updateHostBetInfo({
                        hostId: host.HostId,
                        totalBetAmount: 0,
                        totalWithHoldAmount: 0,
                        pendingBetAmount: 0,
                        pendingWithHoldAmount: 0,
                    })
                );

                // hostId: number;
                // gameId: number;
                // betType: number;
                // betAmount: number;
                // isResultRelease: boolean;
                // winAmount: number;
                // clear confirmed and pending bets
                //Remove 01/03/2024 bet win laster then new game after bet case wrong reset
                // dispatch(gameSliceActions.resetBets());

                // dispatch(
                //     hostsSliceActions.updateConfirmedBets({
                //         hostId: host.HostId,
                //         gameId: 0,
                //         bets: [],
                //     })
                // );
            });
        } else {
            console.warn(
                'hook::socket::warning::cannot::find::host',
                this._command.GameID
            );
        }
    }
}
