import { RootState } from '../../store/store';
import { LOBBY_KEY } from './slice';

export const getLobbyState = (state: RootState) => state[LOBBY_KEY];
export const getLobbyStateHallGameTypeMap = (state: RootState) =>
    getLobbyState(state).hallGameTypeMap;

export const getExpandSideBar = (state: RootState) =>
    getLobbyState(state).expandSideBar;

export const getGoodRoadRefresh = (state: RootState) =>
    getLobbyState(state).goodRoadRefresh;

export const getRecentlyPlayedList = (state: RootState) =>
    getLobbyState(state).recentlyPlayedList;
