import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDLotteryResult implements ICommand
{

	public GameID : number = 0;
	public DrawNumber : number = 0;
	public DrawTime : number = 0;
	public BallCount : number = 0;
	public StartSellTime : number = 0;
	public EndSellTime : number = 0;
	public Num1 : number = 0;
	public Num2 : number = 0;
	public Num3 : number = 0;
	public Num4 : number = 0;
	public Num5 : number = 0;
	public Num6 : number = 0;
	public NumExtra : number = 0;
	public Prize1 : number = 0;
	public Prize2 : number = 0;
	public Prize3 : number = 0;
	public Prize4 : number = 0;
	public Prize5 : number = 0;
	public Prize6 : number = 0;
	public Prize7 : number = 0;
	public LineWon1 : number = 0;
	public LineWon2 : number = 0;
	public LineWon3 : number = 0;
	public LineWon4 : number = 0;
	public LineWon5 : number = 0;
	public LineWon6 : number = 0;
	public LineWon7 : number = 0;
	public TotalBetAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.GameID = ba.readInt53();
		this.DrawNumber = ba.readUint32();
		this.DrawTime = ba.readInt53();
		this.BallCount = ba.readUint8();
		this.StartSellTime = ba.readInt53();
		this.EndSellTime = ba.readInt53();
		this.Num1 = ba.readUint8();
		this.Num2 = ba.readUint8();
		this.Num3 = ba.readUint8();
		this.Num4 = ba.readUint8();
		this.Num5 = ba.readUint8();
		this.Num6 = ba.readUint8();
		this.NumExtra = ba.readUint8();
		this.Prize1 = ba.readUint32();
		this.Prize2 = ba.readUint32();
		this.Prize3 = ba.readUint32();
		this.Prize4 = ba.readUint32();
		this.Prize5 = ba.readUint32();
		this.Prize6 = ba.readUint32();
		this.Prize7 = ba.readUint32();
		this.LineWon1 = ba.readUint32();
		this.LineWon2 = ba.readUint32();
		this.LineWon3 = ba.readUint32();
		this.LineWon4 = ba.readUint32();
		this.LineWon5 = ba.readUint32();
		this.LineWon6 = ba.readUint32();
		this.LineWon7 = ba.readUint32();
		this.TotalBetAmount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.GameID);
		ba.writeUint32(this.DrawNumber);
		ba.writeUint53(this.DrawTime);
		ba.writeUint8(this.BallCount);
		ba.writeUint53(this.StartSellTime);
		ba.writeUint53(this.EndSellTime);
		ba.writeUint8(this.Num1);
		ba.writeUint8(this.Num2);
		ba.writeUint8(this.Num3);
		ba.writeUint8(this.Num4);
		ba.writeUint8(this.Num5);
		ba.writeUint8(this.Num6);
		ba.writeUint8(this.NumExtra);
		ba.writeUint32(this.Prize1);
		ba.writeUint32(this.Prize2);
		ba.writeUint32(this.Prize3);
		ba.writeUint32(this.Prize4);
		ba.writeUint32(this.Prize5);
		ba.writeUint32(this.Prize6);
		ba.writeUint32(this.Prize7);
		ba.writeUint32(this.LineWon1);
		ba.writeUint32(this.LineWon2);
		ba.writeUint32(this.LineWon3);
		ba.writeUint32(this.LineWon4);
		ba.writeUint32(this.LineWon5);
		ba.writeUint32(this.LineWon6);
		ba.writeUint32(this.LineWon7);
		ba.writeUint53(this.TotalBetAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDLotteryResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'DrawNumber(D)='+this.DrawNumber+' ';
		str += 'DrawTime(Q)='+this.DrawTime+' ';
		str += 'BallCount(B)='+this.BallCount+' ';
		str += 'StartSellTime(Q)='+this.StartSellTime+' ';
		str += 'EndSellTime(Q)='+this.EndSellTime+' ';
		str += 'Num1(B)='+this.Num1+' ';
		str += 'Num2(B)='+this.Num2+' ';
		str += 'Num3(B)='+this.Num3+' ';
		str += 'Num4(B)='+this.Num4+' ';
		str += 'Num5(B)='+this.Num5+' ';
		str += 'Num6(B)='+this.Num6+' ';
		str += 'NumExtra(B)='+this.NumExtra+' ';
		str += 'Prize1(D)='+this.Prize1+' ';
		str += 'Prize2(D)='+this.Prize2+' ';
		str += 'Prize3(D)='+this.Prize3+' ';
		str += 'Prize4(D)='+this.Prize4+' ';
		str += 'Prize5(D)='+this.Prize5+' ';
		str += 'Prize6(D)='+this.Prize6+' ';
		str += 'Prize7(D)='+this.Prize7+' ';
		str += 'LineWon1(D)='+this.LineWon1+' ';
		str += 'LineWon2(D)='+this.LineWon2+' ';
		str += 'LineWon3(D)='+this.LineWon3+' ';
		str += 'LineWon4(D)='+this.LineWon4+' ';
		str += 'LineWon5(D)='+this.LineWon5+' ';
		str += 'LineWon6(D)='+this.LineWon6+' ';
		str += 'LineWon7(D)='+this.LineWon7+' ';
		str += 'TotalBetAmount(Q)='+this.TotalBetAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'LotteryResult';
	}

}
