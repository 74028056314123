import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDHsMoneywheelResult implements ICommand
{

	public Main : number = 0;
	public Side : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Main = ba.readUint8();
		this.Side = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Main);
		ba.writeUint8(this.Side);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDHsMoneywheelResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Main(B)='+this.Main+' ';
		str += 'Side(B)='+this.Side+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'HsMoneywheelResult';
	}

}
