import { useIsMobile } from '@/hooks/useIsMobile';
import { createContext, useEffect, useState } from 'react';

export const ScreenContext = createContext({} as ScreenState);
const { Provider } = ScreenContext;

type ScreenProviderOwnProps = {
    children: JSX.Element;
};

type ScreenState = {
    isLandscape: boolean;
    isMobile: boolean;
};

export const ScreenProvider = (props: ScreenProviderOwnProps) => {
    // const [width, height] = useWindowSize();
    const { children } = props;

    const [isLandscape, setIsLandscape] = useState(false);

    const isMobile = useIsMobile();

    // useEffect(() => {
    //     console.log('???', width / height, 4 / 3);
    //     setIsLandscape(width / height > 4 / 3);
    // }, [width, height]);
    useEffect(() => {
        const handleOrientationChange = (event: MediaQueryListEvent) => {
            setIsLandscape(event.matches);

            // if (width / height < 4 / 3) {
            //     setIsLandscape(false);
            // }
        };

        const mediaQuery = window.matchMedia('(min-aspect-ratio: 4/3)');
        setIsLandscape(mediaQuery.matches);

        mediaQuery.addEventListener('change', handleOrientationChange);

        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
        };
    }, []);

    const state: ScreenState = {
        isLandscape,
        isMobile,
    };

    return <Provider value={state}>{children}</Provider>;
};
