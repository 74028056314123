import { createContext } from 'react';
import useLiveGameSocket, {
    useLiveGameSocketState,
} from '../hooks/useLiveGameSocket';

export const WebSocketContext = createContext({} as useLiveGameSocketState);
const { Provider } = WebSocketContext;
type WebSocketProviderProps = {
    children: JSX.Element;
};
export const WebSocketProvider = (props: WebSocketProviderProps) => {
    const { children } = props;
    const ws = useLiveGameSocket();
    return <Provider value={ws}>{children}</Provider>;
};
