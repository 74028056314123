export enum GameResult
{
    ABBAndarWin,
    ABBBaharWin,
    
	ABB1To5,
	ABB6To10,
	ABB11To15,
	ABB16To20,
	ABB21To25,
	ABB26To30,
	ABB31To35,
	ABB36To49,

	ABB36To40 = 16,
	ABB41To45,
	ABB46To49,

	ABBFirst3,
	ABBFirst3_2ndBit,
	ABBFirstAndar = 21,
	ABBFirstBahar,

	ABBNCAndarWin,
	ABBNCBaharWin,
	ABBNC1To5,
	ABBNC6To10,
	ABBNC11To15,
	ABBNC16To20,
	ABBNC21To25,
	ABBNC26To30,
	ABBNC31To35,
	ABBNC36To40,
	ABBNC41To45,
	ABBNC46To49,

	ABBMaxBet,
}