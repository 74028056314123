import { GameFeature as GameFeatureEnum } from '@/models/games/enums/GameFeature';
import { getMainPlayerState } from '@/modules/main/selector';
import { isBitActive } from '@/utils/bitwiseUtil';
import { calcLowHigh } from '@/utils/commonFunc';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useGameFeature = (isBlackjack: boolean) => {
    const [isHedgeBettingEnabled, setIsHedgeBettingEnabled] =
        useState<boolean>();
    const [isAutoConfirmEnabled, setIsAutoConfirmEnabled] = useState<boolean>();
    const { GameFeature } = useSelector(getMainPlayerState);

    useEffect(() => {
        const curGameFeature = calcLowHigh(GameFeature);

        setIsHedgeBettingEnabled(
            isBitActive(curGameFeature, GameFeatureEnum.HedgeBetting)
        );
        setIsAutoConfirmEnabled(
            isBitActive(curGameFeature, GameFeatureEnum.AutoBetConfirmation) &&
                !isBlackjack
        );
    }, [GameFeature]);

    return { isHedgeBettingEnabled, isAutoConfirmEnabled };
};
