import { useContext, useEffect, useState } from 'react';
import { CdnContext } from '../contexts/CdnContext';
type ClientIp = {
    Address: string;
    CountryCode: string;
    IP: string;
};

export const useClientIp = () => {
    const [address, setAddress] = useState<string>();
    const [countryCode, setCountryCode] = useState<string>();
    const [ip, setIp] = useState<string>();
    const { ipServer } = useContext(CdnContext);
    useEffect(() => {
        if (ipServer)
            fetch(ipServer)
                .then(response => {
                    response.json().then(data => {
                        const clientIp = <ClientIp>data;
                        setAddress(clientIp.Address);
                        setCountryCode(clientIp.CountryCode);
                        setIp(clientIp.IP);
                    });
                })
                .catch(err => {
                    console.error('app::hook::useClientIp', err);
                });
    }, [ipServer]);
    return { address, countryCode, ip };
};
